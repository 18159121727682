/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-20T19:56:10.864
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHouseAirCheck,
	getRentHouseAirCheckCreateInit,
	getRentHouseAirCheckEditInit,
	getRentHouseAirCheckView,
	deleteRentHouseAirCheck
} from '@api/rentmanage/RentHouseAirCheck';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentHouseAirCheckItemInit,
listRentHouseAirCheckItemByAuthorizations,
deleteRentHouseAirCheckItem
} from '@api/rentmanage/RentHouseAirCheckItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentHouseAirCheckCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseAirCheckCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseAirCheckEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseAirCheckEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseAirCheck({}, RentHouseAirCheck) {
			return new Promise((resolve, reject) => {
				saveRentHouseAirCheck(RentHouseAirCheck)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseAirCheckView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseAirCheckView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseAirCheck({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseAirCheck({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
