<template>
  <Input ref="input"
         :size="size" :icon="icon" :border="border" :prefix="prefix"
         :suffix="suffix" :disabled="disabled"
         :readonly="readonly" :clearable="clearable" :autofocus="autofocus"
         :elementId="elementId" :spellcheck="spellcheck" :placeholder="placeholder"
         :autocomplete="autocomplete"
         v-model="currency"
         @on-blur="onBlurHandle"
         @on-change="event => $emit('on-change', event)"
         @on-enter="$emit('on-enter')"
         @on-click="$emit('on-click')"
         @on-focus="$emit('on-focus')"
         @on-clear="$emit('on-clear')">
    <template slot="prepend"><slot name="prepend" /></template>
    <template slot="prefix"><slot name="prefix" /></template>
    <template slot="suffix"><slot name="suffix" /></template>
    <template slot="append"><slot name="append" /></template>
  </Input>
</template>
<script>
  import numeral from 'numeral'
  import {toNumber, isNumber} from 'lodash'

  export default {
    name: 'CurrencyInput',
    props: {
      value: {
        type: [String, Number],
        default: ''
      },
      size: {
        type: String,
        default: 'default'
      },
      icon: {
        type: String,
        default: ''
      },
      border: {
        type: Boolean,
        default: true
      },
      prefix: {
        type: String,
        default: ''
      },
      suffix: {
        type: String,
        default: ''
      },
      readonly: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: false
      },
      autofocus: {
        type: Boolean,
        default: false
      },
      elementId: {
        type: String,
        default: ''
      },
      spellcheck: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      autocomplete: {
        type: String,
        default: ''
      },
      // 整数
      integer: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      currency (value) {
        this.$emit('input', value)
      },
      value: {
        handler (value) {
          this.currency = value
        },
        immediate: true
      }
    },
    methods: {
      onBlurHandle () {
        if (!this.integer && this.currency) this.currency = numeral(this.currency).format('0[.]00')
        this.$emit('on-blur')
      },
      focus () {
        this.$refs.input.focus()
      }
    },
    mounted () {
      const input = this.$refs.input

      input.handleInput = (event) => {
        const {target} = event

        if (input.isOnComposition) return
        if (this.integer) {
          // 清除“数字”以外的字符
          target.value = `${target.value}`.replace(/\D/g, '')
        } else {
          target.value = `${target.value}`
              // 清除“数字”和“.”以外的字符
              .replace(/[^\d.]/g, '')
              // 只保留第一个. 清除多余的
              .replace(/\.{2,}/g,'.')
              .replace('.','$#$')
              .replace(/\./g,'')
              .replace('$#$','.')
              // 只能输入两个小数
              .replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
        }

        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (target.value.indexOf('.') < 0 && target.value !== '') target.value = toNumber(target.value)
        if (input.number && target.value !== '') target.value = isNumber(target.value) ? target.value : toNumber(target.value)

        input.$emit('input', target.value)
        input.setCurrentValue(target.value)
        input.$emit('on-change', event)
      }
    },
    data () {
      return {
        currency: ''
      }
    }
  }
</script>
<style scoped lang="less">
  /deep/ .ivu-input[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
       -webkit-appearance: none;
       margin: 0;
     }
  }
</style>
