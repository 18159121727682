/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-06T11:28:50.092
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialRentContractItemInit(data){return request({
url: '/report/api/reportFinancialRentContractItem/listReportFinancialRentContractItemInit',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractItemByAuthorizations(data){return request({
url: '/report/api/reportFinancialRentContractItem/listReportFinancialRentContractItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractItem(data){return request({
url: '/report/api/reportFinancialRentContractItem/listReportFinancialRentContractItem',
method: 'post',
data:data,
});
}


export function getReportFinancialRentContractItemCreateInit(){return request({
url: '/report/api/reportFinancialRentContractItem/getReportFinancialRentContractItemCreateInit',
method: 'post',
});
}


export function getReportFinancialRentContractItemEditInit(params){return request({
url: '/report/api/reportFinancialRentContractItem/getReportFinancialRentContractItemEditInit',
method: 'post',
params:params,
});
}


export function getReportFinancialRentContractItemView(params){return request({
url: '/report/api/reportFinancialRentContractItem/getReportFinancialRentContractItemView',
method: 'post',
params:params,
});
}


export function saveReportFinancialRentContractItem(data){return request({
url: '/report/api/reportFinancialRentContractItem/saveReportFinancialRentContractItem',
method: 'post',
data:data,
});
}


export function deleteReportFinancialRentContractItem(params){return request({
url: '/report/api/reportFinancialRentContractItem/deleteReportFinancialRentContractItem',
method: 'post',
params:params,
});
}


