/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-14T15:25:43.706
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentLeaseContract,
	listRentLeaseContractInit,
	listRentLeaseContractByAuthorizations,
	deleteRentLeaseContract,
    sendSign,
    confirmSigning,
    downloadLeasePlan,
	cancellation,
	regenerateContract,
	changeSignature,
	restoreChangeSignature
	
} from '@api/rentmanage/RentLeaseContract';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentLeaseContract({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentLeaseContract(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentLeaseContractByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentLeaseContractByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentLeaseContractInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentLeaseContractInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
                        state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentLeaseContract({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentLeaseContract({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
        
        sendSign({
        	dispatch,
        	state,
        }, billId) {
        	return new Promise((resolve, reject) => {
        		sendSign({
        				billId: billId
        			})
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
        
        confirmSigning({
        	dispatch,
        	state,
        }, billId) {
        	return new Promise((resolve, reject) => {
        		confirmSigning({
        				billId: billId
        			})
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
		
        downloadLeasePlan({
        	dispatch,
        	state,
        }, data) {
        	return new Promise((resolve, reject) => {
        		downloadLeasePlan(data)
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
		
		cancellation({
			dispatch,
			state,
		}, billId) {
			return new Promise((resolve, reject) => {
				cancellation({
						billId: billId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		regenerateContract({
			dispatch,
			state,
		}, billId) {
			return new Promise((resolve, reject) => {
				regenerateContract({
						billId: billId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		changeSignature({
			state
		}, data) {
			return new Promise((resolve, reject) => {
				changeSignature(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		restoreChangeSignature({
			state
		}, billId) {
			return new Promise((resolve, reject) => {
				restoreChangeSignature({billId:billId})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
        
	}
};
