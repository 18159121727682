import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-area-'

export default 
{
path: '/storagemanage/area',
name: 'storagemanage-area',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}area-index',
meta:{
...meta,
title: '地区管理'
},
component: () => import('@/pages/storagemanage/area/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}area-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '地区管理编辑'
},
component: () => import('@/pages/storagemanage/area/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}areadetail-create',
auth: [
'hidden'
],
meta:{
...meta,
title: '地区管理新增'
},

component: () => import('@/pages/storagemanage/area/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}areaView',
auth: [
'hidden'
],

meta:{
...meta,
title: '地区管理详情查看'
},

component: () => import('@/pages/storagemanage/area/detail-view') ,
},
]
};
