/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T19:13:41.195
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentRepairFeedbackDetails,
	getRentRepairFeedbackDetailsCreateInit,
	getRentRepairFeedbackDetailsEditInit,
	getRentRepairFeedbackDetailsView,
	deleteRentRepairFeedbackDetails,
	startWorkflow,
	getRentRepairFeedbackDetailsByOrderAndAssign
} from '@api/rentmanage/RentRepairFeedbackDetails';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentConsumeMaterialCompanyInit,
listRentConsumeMaterialCompanyByAuthorizations,
deleteRentConsumeMaterialCompany
} from '@api/rentmanage/RentConsumeMaterialCompany';

import {
listRentConsumeMaterialUserInit,
listRentConsumeMaterialUserByAuthorizations,
deleteRentConsumeMaterialUser
} from '@api/rentmanage/RentConsumeMaterialUser';

import {
listRentRepairFeedbackTypeInit,
listRentRepairFeedbackTypeByAuthorizations,
deleteRentRepairFeedbackType
} from '@api/rentmanage/RentRepairFeedbackType';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentRepairFeedbackDetailsCreateInit({
			state
		}, {
			referId
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairFeedbackDetailsCreateInit({referId})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentRepairFeedbackDetailsEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairFeedbackDetailsEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentRepairFeedbackDetailsByOrderAndAssign({}, date) {
			return new Promise((resolve, reject) => {
				getRentRepairFeedbackDetailsByOrderAndAssign(date)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		startWorkflow({}, RentRepairFeedbackDetails) {
			return new Promise((resolve, reject) => {
				startWorkflow(RentRepairFeedbackDetails)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		saveRentRepairFeedbackDetails({}, RentRepairFeedbackDetails) {
			return new Promise((resolve, reject) => {
				saveRentRepairFeedbackDetails(RentRepairFeedbackDetails)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentRepairFeedbackDetailsView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairFeedbackDetailsView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentRepairFeedbackDetails({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentRepairFeedbackDetails({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
