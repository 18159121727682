/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-07-15T10:05:28.346
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listEnergyApplicationInit(data) {
    return request({
        url: '/rentmanage/api/energyApplication/listEnergyApplicationInit',
        method: 'post',
        data: data,
    });
}


export function listEnergyApplicationByAuthorizations(data) {
    return request({
        url: '/rentmanage/api/energyApplication/listEnergyApplicationByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listEnergyApplication(data) {
    return request({
        url: '/rentmanage/api/energyApplication/listEnergyApplication',
        method: 'post',
        data: data,
    });
}


export function getEnergyApplicationCreateInit(params) {
  
    return request({
        url: '/rentmanage/api/energyApplication/getEnergyApplicationCreateInit',
        method: 'post',
        data: params
    });
}


export function getEnergyApplicationEditInit(params) {
    return request({
        url: '/rentmanage/api/energyApplication/getEnergyApplicationEditInit',
        method: 'get',
        params: params,
    });
}


export function getEnergyApplicationView(params) {
    return request({
        url: '/rentmanage/api/energyApplication/getEnergyApplicationView',
        method: 'get',
        params: params,
    });
}


export function saveEnergyApplication(data) {
    return request({
        url: '/rentmanage/api/energyApplication/saveEnergyApplication',
        method: 'post',
        data: data,
    });
}


export function deleteEnergyApplication(params) {
    return request({
        url: '/rentmanage/api/energyApplication/deleteEnergyApplication',
        method: 'post',
        params: params,
    });
}
export function getEnergyApplicationInit() {
    return request({
        url: '/rentmanage/api/energyApplication/getEnergyApplicationInit',
        method: 'get'
    });
}
export function downloadRentTemplate(data) {
    return request({
		url: '/rentmanage/api/energyApplication/downloadEnergyApplicationItem',
		method: 'post',
		data: data,
		responseType: 'blob'
	});
}
export function financialPaymentStateChange(params) {
    return request({
		url: `/financial/api/financialPayment/financialPaymentStateChange`,
		method: 'post',
        params: params,
	});
}


