/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-08-16T18:06:35.513
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancialPreOrder,
	getFinancialPreOrderCreateInit,
	getFinancialPreOrderEditInit,
	getFinancialPreOrderView,
	deleteFinancialPreOrder
} from '@api/financial/FinancialPreOrder';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getFinancialPreOrderCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPreOrderCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialPreOrderEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPreOrderEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialPreOrder({}, FinancialPreOrder) {
			return new Promise((resolve, reject) => {
				saveFinancialPreOrder(FinancialPreOrder)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getFinancialPreOrderView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPreOrderView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteFinancialPreOrder({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialPreOrder({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
