/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-11-09T18:33:19.412
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'report-reportrentcontractrequestcheckoutlease-'

export default {
	path: '/report/reportrentcontractrequestcheckoutlease',
	name: 'report-reportrentcontractrequestcheckoutlease',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [
		{
			path: 'index',
			name: '${pre}reportrentcontractrequestcheckoutlease-index',

			meta: {
				...meta,
				title: '运营管理-退租'
			},

			component: () => import('@/pages/report/reportrentcontractrequestcheckoutlease/index'),
		},
		{
			path: 'finindex',
			name: '${pre}reportrentcontractrequestcheckoutlease-finindex',

			meta: {
				...meta,
				title: '退租'
			},

			component: () => import('@/pages/report/reportrentcontractrequestcheckoutlease/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}reportrentcontractrequestcheckoutlease-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '运营管理-退租编辑'
			},

			component: () => import('@/pages/report/reportrentcontractrequestcheckoutlease/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}reportrentcontractrequestcheckoutleasedetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '运营管理-退租新增'
			},

			component: () => import('@/pages/report/reportrentcontractrequestcheckoutlease/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}reportrentcontractrequestcheckoutleaseView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '运营管理-退租详情查看'
			},

			component: () => import('@/pages/report/reportrentcontractrequestcheckoutlease/detail-view'),
		},
	]
};
