/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T15:03:08.642
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveAssignedBill,
	getAssignedBillCreateInit,
	getAssignedBillEditInit,
	getAssignedBillView,
	deleteAssignedBill
} from '@api/storagemanage/AssignedBill';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listAssignedBillItemInit,
listAssignedBillItemByAuthorizations,
deleteAssignedBillItem
} from '@api/storagemanage/AssignedBillItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getAssignedBillCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getAssignedBillCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getAssignedBillEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getAssignedBillEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveAssignedBill({}, AssignedBill) {
			return new Promise((resolve, reject) => {
				saveAssignedBill(AssignedBill)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getAssignedBillView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getAssignedBillView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteAssignedBill({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteAssignedBill({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
