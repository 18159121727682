/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-12T10:12:57.971
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listReportFinancialTransfer,
	listReportFinancialTransferInit,
	listReportFinancialTransferByAuthorizations,
	deleteReportFinancialTransfer
	
} from '@api/report/ReportFinancialTransfer';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listReportFinancialTransfer({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialTransfer(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportFinancialTransferByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialTransferByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportFinancialTransferInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialTransferInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteReportFinancialTransfer({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportFinancialTransfer({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
