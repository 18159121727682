const pre = '/remould/';

export default {
    path: '/remould',
    title: '改造管理',
    header: 'home',
    custom: 'i-icon-demo i-icon-demo-list',
	icon: 'md-speedometer',
    children: [
        {
            path: `#18`,
            title: '微改管理'
        }
    ]
}
