/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-18T19:58:30.536
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getConDeputeContractBackEditInit,
	getConDeputeContractBackView
} from '@api/storagemanage/ConDeputeContractBack';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		getConDeputeContractBackEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getConDeputeContractBackEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getConDeputeContractBackView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getConDeputeContractBackView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
