/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-04-07 14:30:16
 * @LastEditors: co
 * @LastEditTime: 2021-04-25 10:25:13
 * @FilePath: \giant_find\iview-admin-pro-template\src\store\modules\storagemanage\modules\area\detail-view.js
 */
import util from '@/libs/util';
import router from '@/router';
import {
	getAreaEditInit,
	getAreaView,
	findHouseGetArea
} from '@api/storagemanage/Area';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		getAreaEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getAreaEditInit({
					id
				})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getAreaView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getAreaView({
					id
				})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		findHouseGetArea({
			state
		}, {
			parentId
		}) {
			return new Promise((resolve, reject) => {
				findHouseGetArea({
					parentId
				})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
