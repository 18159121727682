/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-10T17:00:05.664
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getFinancialSettlementEditInit,
	getFinancialSettlementView,
	financialSettlementReceipt,
	financialSettlementPayment,
	financialSettlementEffect,
	generatePreFinancialSettlement,
	generateFinancialSettlement
} from '@api/financial/FinancialSettlement';

import {
listFinancialSettlementItemInit,
listFinancialSettlementItemByAuthorizations,
deleteFinancialSettlementItem
} from '@api/financial/FinancialSettlementItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getFinancialSettlementEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialSettlementEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialSettlementView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialSettlementView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		financialSettlementPayment({}, FinancialSettlement) {
			return new Promise((resolve, reject) => {
				financialSettlementPayment(FinancialSettlement)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		financialSettlementReceipt({}, FinancialSettlement) {
			return new Promise((resolve, reject) => {
				financialSettlementReceipt(FinancialSettlement)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		financialSettlementEffect({
			state
		}, {
			id,effectState
		}) {
			return new Promise((resolve, reject) => {
				financialSettlementEffect({
						id,effectState
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		generatePreFinancialSettlement({
			state
		}, {
			contractId,  contractCode, newContractId,  newContractCode , bizId,  bizCode,  checkOutDate, bizType
		}) {
			return new Promise((resolve, reject) => {
				generatePreFinancialSettlement({
						contractId,  contractCode, newContractId,  newContractCode , bizId,  bizCode,  checkOutDate, bizType
					})
					.then(async res => {
						// state.CompoundEntityDictionary = res;
						resolve(res.entity);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		generateFinancialSettlement({
			state
		}, FinancialSettlement) {
			return new Promise((resolve, reject) => {
				generateFinancialSettlement(FinancialSettlement)
					.then(async res => {
						// state.CompoundEntityDictionary = res;
						resolve(res.entity);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
	}
};
