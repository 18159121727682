/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-14T15:25:43.713
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentLeaseContract,
	getRentLeaseContractCreateInit,
	getRentLeaseContractEditInit,
	getRentLeaseContractView,
	deleteRentLeaseContract,
    calculationEndDate,
    createTenantList,
	createMakeDecisionTenantList,
	createAgreementList
} from '@api/rentmanage/RentLeaseContract';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentLeaseContractReceiptAgreementInit,
listRentLeaseContractReceiptAgreementByAuthorizations,
deleteRentLeaseContractReceiptAgreement
} from '@api/rentmanage/RentLeaseContractReceiptAgreement';

import {
listRentLeaseContractReceiptPlanInit,
listRentLeaseContractReceiptPlanByAuthorizations,
deleteRentLeaseContractReceiptPlan
} from '@api/rentmanage/RentLeaseContractReceiptPlan';

import {
listRentLeaseContractSaleInit,
listRentLeaseContractSaleByAuthorizations,
deleteRentLeaseContractSale
} from '@api/rentmanage/RentLeaseContractSale';

import {
listRentLeaseContractTenantInit,
listRentLeaseContractTenantByAuthorizations,
deleteRentLeaseContractTenant
} from '@api/rentmanage/RentLeaseContractTenant';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentLeaseContractCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentLeaseContractEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentLeaseContract({}, RentLeaseContract) {
			return new Promise((resolve, reject) => {
				saveRentLeaseContract(RentLeaseContract)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentLeaseContractView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentLeaseContract({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentLeaseContract({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
        
        calculationEndDate({
        	state
        }, calculationParms) {
        	return new Promise((resolve, reject) => {
        		calculationEndDate(calculationParms)
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
        
        createTenantList({
        	state
        }, tenantParms) {
        	return new Promise((resolve, reject) => {
        		createTenantList(tenantParms)
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
		
		createMakeDecisionTenantList({
			state
		}, makeDecisionTenantParms) {
			return new Promise((resolve, reject) => {
				createMakeDecisionTenantList(makeDecisionTenantParms)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		createAgreementList({
			state
		}, tenantParms) {
			return new Promise((resolve, reject) => {
				createAgreementList(tenantParms)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
