/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-05-18T12:09:52.311
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getStockpileIntentionAttachmentEditInit,
	getStockpileIntentionAttachmentView
} from '@api/storagemanage/StockpileIntentionAttachment';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		getStockpileIntentionAttachmentEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getStockpileIntentionAttachmentEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getStockpileIntentionAttachmentView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getStockpileIntentionAttachmentView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
