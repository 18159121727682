/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-01T17:31:37.29
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinancialAdjustmentApplyCollection,
	listFinancialAdjustmentApplyCollectionInit,
	listFinancialAdjustmentApplyCollectionByAuthorizations,
	deleteFinancialAdjustmentApplyCollection
} from '@api/financial/FinancialAdjustmentApplyCollection';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFinancialAdjustmentApplyCollection({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialAdjustmentApplyCollection(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialAdjustmentApplyCollectionByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialAdjustmentApplyCollectionByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialAdjustmentApplyCollectionInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialAdjustmentApplyCollectionInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialAdjustmentApplyCollection({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialAdjustmentApplyCollection({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
