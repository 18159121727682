// 菜单，侧边栏
import dashboard from './modules/dashboard';
import khm from './modules/khm';
import lcf from './modules/lcf/lcf';//老存房
import rent from './modules/rent';
import rentservice from './modules/rentservice';
import remould from './modules/remould';
import tool from './modules/tool';

export default [
	dashboard,
	lcf,
	khm,
	rent,
	rentservice,
	remould,
	tool,
];
