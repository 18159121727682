/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-10-23T09:57:30.896
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listHrProjectParamsInit(data){return request({
url: '/rentmanage/api/hrProjectParams/listHrProjectParamsInit',
method: 'post',
data:data,
});
}


export function listHrProjectParamsByAuthorizations(data){return request({
url: '/rentmanage/api/hrProjectParams/listHrProjectParamsByAuthorizations',
method: 'post',
data:data,
});
}


export function listHrProjectParams(data){return request({
url: '/rentmanage/api/hrProjectParams/listHrProjectParams',
method: 'post',
data:data,
});
}


export function getHrProjectParamsCreateInit(){return request({
url: '/rentmanage/api/hrProjectParams/getHrProjectParamsCreateInit',
method: 'post',
});
}


export function getHrProjectParamsEditInit(params){return request({
url: '/rentmanage/api/hrProjectParams/getHrProjectParamsEditInit',
method: 'post',
params:params,
});
}


export function getHrProjectParamsView(params){return request({
url: '/rentmanage/api/hrProjectParams/getHrProjectParamsView',
method: 'post',
params:params,
});
}


export function saveHrProjectParams(data){return request({
url: '/rentmanage/api/hrProjectParams/saveHrProjectParams',
method: 'post',
data:data,
});
}


export function deleteHrProjectParams(params){return request({
url: '/rentmanage/api/hrProjectParams/deleteHrProjectParams',
method: 'post',
params:params,
});
}


