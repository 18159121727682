/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-09T14:42:50.435
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHouseSalesPrice,
	getRentHouseSalesPriceCreateInit,
	getRentHouseSalesPriceEditInit,
	getRentHouseSalesPriceView,
	deleteRentHouseSalesPrice,
	submitRentHouseSalesPrice,
	startWorkflow
} from '@api/rentmanage/RentHouseSalesPrice';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentHouseSalesPriceItemInit,
listRentHouseSalesPriceItemByAuthorizations,
deleteRentHouseSalesPriceItem
} from '@api/rentmanage/RentHouseSalesPriceItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentHouseSalesPriceCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseSalesPriceCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseSalesPriceEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseSalesPriceEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseSalesPrice({}, RentHouseSalesPrice) {
			return new Promise((resolve, reject) => {
				saveRentHouseSalesPrice(RentHouseSalesPrice)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseSalesPriceView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseSalesPriceView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		submitRentHouseSalesPrice({}, RentHouseSalesPrice) {
			return new Promise((resolve, reject) => {
				submitRentHouseSalesPrice(RentHouseSalesPrice)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		startWorkflow({}, RentHouseSalesPrice) {
			return new Promise((resolve, reject) => {
				startWorkflow(RentHouseSalesPrice)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentHouseSalesPrice({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseSalesPrice({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
