/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-01T14:27:19.674
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentTag,
	getRentTagCreateInit,
	getRentTagEditInit,
	getRentTagView,
	deleteRentTag
} from '@api/rentmanage/RentTag';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentTagCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentTagCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentTagEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentTagEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentTag({}, RentTag) {
			return new Promise((resolve, reject) => {
				saveRentTag(RentTag)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentTagView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentTagView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentTag({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentTag({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
