/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-16T15:24:16.811
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentTakeLookRecord,
	getRentTakeLookRecordCreateInit,
	getRentTakeLookRecordEditInit,
	getRentTakeLookRecordView,
	startWorkflow,
	deleteRentTakeLookRecord
} from '@api/rentmanage/RentTakeLookRecord';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentTakeLookHouseInit,
listRentTakeLookHouseByAuthorizations,
deleteRentTakeLookHouse
} from '@api/rentmanage/RentTakeLookHouse';

import {
listRentTakeLookEvaluateInit,
listRentTakeLookEvaluateByAuthorizations,
deleteRentTakeLookEvaluate
} from '@api/rentmanage/RentTakeLookEvaluate';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentTakeLookRecordCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentTakeLookRecordCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		startWorkflow({}, RentTakeLookRecord) {
			return new Promise((resolve, reject) => {
				startWorkflow(RentTakeLookRecord)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentTakeLookRecordEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentTakeLookRecordEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentTakeLookRecord({}, RentTakeLookRecord) {
			return new Promise((resolve, reject) => {
				saveRentTakeLookRecord(RentTakeLookRecord)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentTakeLookRecordView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentTakeLookRecordView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentTakeLookRecord({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentTakeLookRecord({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
