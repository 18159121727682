/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-21T17:44:56.191
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listReportWorkflowCount,
	listReportWorkflowCountInit,
	listReportWorkflowCountByAuthorizations,
	deleteReportWorkflowCount
	
} from '@api/report/ReportWorkflowCount';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listReportWorkflowCount({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportWorkflowCount(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportWorkflowCountByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportWorkflowCountByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportWorkflowCountInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportWorkflowCountInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteReportWorkflowCount({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportWorkflowCount({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
