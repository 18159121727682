/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-07T10:19:45.941
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveCCBFinBill,
	getCCBFinBillCreateInit,
	getCCBFinBillEditInit,
	getCCBFinBillView,
	deleteCCBFinBill
} from '@api/house/CCBFinBill';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listCCBFinBillItemInit,
listCCBFinBillItemByAuthorizations,
deleteCCBFinBillItem
} from '@api/house/CCBFinBillItem';

import {
listCCBSyncLogInit,
listCCBSyncLogByAuthorizations,
deleteCCBSyncLog
} from '@api/house/CCBSyncLog';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getCCBFinBillCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getCCBFinBillCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getCCBFinBillEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getCCBFinBillEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveCCBFinBill({}, CCBFinBill) {
			return new Promise((resolve, reject) => {
				saveCCBFinBill(CCBFinBill)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getCCBFinBillView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getCCBFinBillView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteCCBFinBill({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteCCBFinBill({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
