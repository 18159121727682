import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: ['ManagerOutShow']
};

const pre = 'workplatform-';

export default {
    path: '/workplatform',
    name: 'workplatform',
    redirect: {
        name: `${pre}outGoingReport`
    },
    meta,
    component: BasicLayout,
    children: [
        {
            path: 'outGoingReport',
            name: `${pre}outGoingReport`,
            meta: {
                ...meta,
                title: '行程报备',
                // closable: false
            },
            component: () => import('@/pages/workplatform/outGoingReport')
        },
		{
		    path: 'outGoingReportEdit',
		    name: `${pre}outGoingReportEdit`,
			auth: ['hidden'],  // 不存在的鉴权，所以不会显示该菜单
		    meta: {
		        ...meta,
		        title: '行程报备编辑'
		    },
		    component: () => import('@/pages/workplatform/outGoingReport/edit')
		}
    ]
};
