/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T15:54:15.637
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentChannelApplyInit(data){return request({
url: '/rentmanage/api/rentChannelApply/listRentChannelApplyInit',
method: 'post',
data:data,
});
}


export function listRentChannelApplyByAuthorizations(data){return request({
url: '/rentmanage/api/rentChannelApply/listRentChannelApplyByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentChannelApply(data){return request({
url: '/rentmanage/api/rentChannelApply/listRentChannelApply',
method: 'post',
data:data,
});
}


export function getRentChannelApplyCreateInit(){return request({
url: '/rentmanage/api/rentChannelApply/getRentChannelApplyCreateInit',
method: 'post',
});
}


export function getRentChannelApplyEditInit(params){return request({
url: '/rentmanage/api/rentChannelApply/getRentChannelApplyEditInit',
method: 'post',
params:params,
});
}


export function getRentChannelApplyView(params){return request({
url: '/rentmanage/api/rentChannelApply/getRentChannelApplyView',
method: 'post',
params:params,
});
}


export function saveRentChannelApply(data){return request({
url: '/rentmanage/api/rentChannelApply/saveRentChannelApply',
method: 'post',
data:data,
});
}


export function deleteRentChannelApply(params){return request({
url: '/rentmanage/api/rentChannelApply/deleteRentChannelApply',
method: 'post',
params:params,
});
}


export function startWorkflow(data){return request({
	url: '/rentmanage/api/rentChannelApply/startWorkflow',
	method: 'post',
	data:data,
	});
	}