/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-25T11:06:36.021
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportRentContractUndueInit(data){return request({
url: '/report/api/reportRentContractUndue/listReportRentContractUndueInit',
method: 'post',
data:data,
});
}


export function listReportRentContractUndueByAuthorizations(data){return request({
url: '/report/api/reportRentContractUndue/listReportRentContractUndueByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportRentContractUndue(data){return request({
url: '/report/api/reportRentContractUndue/listReportRentContractUndue',
method: 'post',
data:data,
});
}


export function getReportRentContractUndueCreateInit(){return request({
url: '/report/api/reportRentContractUndue/getReportRentContractUndueCreateInit',
method: 'post',
});
}


export function getReportRentContractUndueEditInit(params){return request({
url: '/report/api/reportRentContractUndue/getReportRentContractUndueEditInit',
method: 'post',
params:params,
});
}


export function getReportRentContractUndueView(params){return request({
url: '/report/api/reportRentContractUndue/getReportRentContractUndueView',
method: 'post',
params:params,
});
}


export function saveReportRentContractUndue(data){return request({
url: '/report/api/reportRentContractUndue/saveReportRentContractUndue',
method: 'post',
data:data,
});
}


export function deleteReportRentContractUndue(params){return request({
url: '/report/api/reportRentContractUndue/deleteReportRentContractUndue',
method: 'post',
params:params,
});
}


