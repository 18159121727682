/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T09:30:03.313
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getFinancialPayablesEditInit,
	getFinancialPayablesView
} from '@api/financial/FinancialPayables';

import {
listFinancialPayablesItemInit,
listFinancialPayablesItemByAuthorizations,
deleteFinancialPayablesItem
} from '@api/financial/FinancialPayablesItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getFinancialPayablesEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayablesEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialPayablesView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayablesView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
