import BasicLayout from '@/layouts/basic-layout';
////第一步：配置菜单-->第二步：配置存房委托指派商家页面相关的路由-->第三步：注入路由-->第四步：做页面
const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	name: '受理工单',//存房委托指派专员
	redirect: {
		name: `${pre}houseSaveDelegationCommissioner-list`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'houseSaveDelegationCommissioner-list',
		name: `${pre}houseSaveDelegationCommissioner-list`,
		meta: {
			...meta,
			title: '受理工单',
			closable: true
		},
		component: () => import('@/pages/storagemanage/houseSaveDelegationCommissioner/index')
	}]
};
