/*
 * @Author: co
 * @Date: 2021-04-07 14:30:15
 */
/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-17T18:26:38.211
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialRentContractDetailCollectionInit(data) {
    return request({
        url: '/report/api/reportFinancialRentContractDetailCollection/listReportFinancialRentContractDetailCollectionInit',
        method: 'post',
        data: data,
    });
}


export function listReportFinancialRentContractDetailCollectionByAuthorizations(data) {
    return request({
        url: '/report/api/reportFinancialRentContractDetailCollection/listReportFinancialRentContractDetailCollectionByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listReportFinancialRentContractDetailCollection(data) {
    return request({
        url: '/report/api/reportFinancialRentContractDetailCollection/listReportFinancialRentContractDetailCollection',
        method: 'post',
        data: data,
    });
}


export function getReportFinancialRentContractDetailCollectionCreateInit() {
    return request({
        url: '/report/api/reportFinancialRentContractDetailCollection/getReportFinancialRentContractDetailCollectionCreateInit',
        method: 'post',
    });
}


export function getReportFinancialRentContractDetailCollectionEditInit(params) {
    return request({
        url: '/report/api/reportFinancialRentContractDetailCollection/getReportFinancialRentContractDetailCollectionEditInit',
        method: 'post',
        params: params,
    });
}


export function getReportFinancialRentContractDetailCollectionView(params) {
    return request({
        url: '/report/api/reportFinancialRentContractDetailCollection/getReportFinancialRentContractDetailCollectionView',
        method: 'post',
        params: params,
    });
}


export function saveReportFinancialRentContractDetailCollection(data) {
    return request({
        url: '/report/api/reportFinancialRentContractDetailCollection/saveReportFinancialRentContractDetailCollection',
        method: 'post',
        data: data,
    });
}


export function deleteReportFinancialRentContractDetailCollection(params) {
    return request({
        url: '/report/api/reportFinancialRentContractDetailCollection/deleteReportFinancialRentContractDetailCollection',
        method: 'post',
        params: params,
    });
}

// 实收报表统计
export function countReportFinancialRentContractDetailCollection(data) {
    return request({
        url: '/report/api/reportFinancialRentContractDetailCollection/countReportFinancialRentContractDetailCollection',
        method: 'post',
        data: data,
    });
}

