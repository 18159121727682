/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-08-24T17:05:26.885
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseLockLogInit(data){return request({
url: '/rentmanage/api/rentHouseLockLog/listRentHouseLockLogInit',
method: 'post',
data:data,
});
}


export function listRentHouseLockLogByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseLockLog/listRentHouseLockLogByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseLockLog(data){return request({
url: '/rentmanage/api/rentHouseLockLog/listRentHouseLockLog',
method: 'post',
data:data,
});
}


export function getRentHouseLockLogCreateInit(){return request({
url: '/rentmanage/api/rentHouseLockLog/getRentHouseLockLogCreateInit',
method: 'post',
});
}


export function getRentHouseLockLogEditInit(params){return request({
url: '/rentmanage/api/rentHouseLockLog/getRentHouseLockLogEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseLockLogView(params){return request({
url: '/rentmanage/api/rentHouseLockLog/getRentHouseLockLogView',
method: 'post',
params:params,
});
}


export function saveRentHouseLockLog(data){return request({
url: '/rentmanage/api/rentHouseLockLog/saveRentHouseLockLog',
method: 'post',
data:data,
});
}


export function deleteRentHouseLockLog(params){return request({
url: '/rentmanage/api/rentHouseLockLog/deleteRentHouseLockLog',
method: 'post',
params:params,
});
}


