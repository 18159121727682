import util from '@/libs/util';
import router from '@/router';
import {
	listStockpileIntentionHouse,
	listStockpileIntentionHouseInit,
	listStockpileIntentionHouseByAuthorizations,
	deleteStockpileIntentionHouse
} from '@api/storagemanage/StockpileIntentionHouse';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listStockpileIntentionHouse({
			state
		}) {
			return new Promise((resolve, reject) => {
				listStockpileIntentionHouse(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listStockpileIntentionHouseByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listStockpileIntentionHouseByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listStockpileIntentionHouseInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listStockpileIntentionHouseInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteStockpileIntentionHouse({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteStockpileIntentionHouse({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
