/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-06T11:28:50.129
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportfinancialrentcontractitem-'

export default 
{
path: '/report/reportfinancialrentcontractitem',
name: 'report-reportfinancialrentcontractitem',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportfinancialrentcontractitem-index',

meta:{
...meta,
title: '业务营收报表'
},

component: () => import('@/pages/report/reportfinancialrentcontractitem/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportfinancialrentcontractitem-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '业务营收报表编辑'
},

component: () => import('@/pages/report/reportfinancialrentcontractitem/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportfinancialrentcontractitemdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '业务营收报表新增'
},

component: () => import('@/pages/report/reportfinancialrentcontractitem/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportfinancialrentcontractitemView',
auth: [
'hidden'
],

meta:{
...meta,
title: '业务营收报表详情查看'
},

component: () => import('@/pages/report/reportfinancialrentcontractitem/detail-view') ,
},
]
};
