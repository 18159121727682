/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-16T15:24:16.907
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentTakeLookHouse,
	listRentTakeLookHouseInit,
	listRentTakeLookHouseByAuthorizations,
	deleteRentTakeLookHouse
} from '@api/rentmanage/RentTakeLookHouse';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentTakeLookHouse({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTakeLookHouse(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentTakeLookHouseByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTakeLookHouseByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentTakeLookHouseInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTakeLookHouseInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentTakeLookHouse({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentTakeLookHouse({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
