/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-02T18:37:11.522
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listInvoiceToolsInvoiceInit(data){return request({
url: '/smallapplication/api/invoiceToolsInvoice/listInvoiceToolsInvoiceInit',
method: 'post',
data:data,
});
}


export function listInvoiceToolsInvoiceByAuthorizations(data){return request({
url: '/smallapplication/api/invoiceToolsInvoice/listInvoiceToolsInvoiceByAuthorizations',
method: 'post',
data:data,
});
}


export function listInvoiceToolsInvoice(data){return request({
url: '/smallapplication/api/invoiceToolsInvoice/listInvoiceToolsInvoice',
method: 'post',
data:data,
});
}


export function getInvoiceToolsInvoiceCreateInit(){return request({
url: '/smallapplication/api/invoiceToolsInvoice/getInvoiceToolsInvoiceCreateInit',
method: 'post',
});
}


export function getInvoiceToolsInvoiceEditInit(params){return request({
url: '/smallapplication/api/invoiceToolsInvoice/getInvoiceToolsInvoiceEditInit',
method: 'post',
params:params,
});
}


export function getInvoiceToolsInvoiceView(params){return request({
url: '/smallapplication/api/invoiceToolsInvoice/getInvoiceToolsInvoiceView',
method: 'post',
params:params,
});
}


export function saveInvoiceToolsInvoice(data){return request({
url: '/smallapplication/api/invoiceToolsInvoice/saveInvoiceToolsInvoice',
method: 'post',
data:data,
});
}


export function deleteInvoiceToolsInvoice(params){return request({
url: '/smallapplication/api/invoiceToolsInvoice/deleteInvoiceToolsInvoice',
method: 'post',
params:params,
});
}

export function changeInvoiceToolsInvoiceStatus(params){return request({
url: '/smallapplication/api/invoiceToolsInvoice/changeInvoiceToolsInvoiceStatus',
method: 'post',
params:params,
});
}


