/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-07-08T19:32:01.8
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: true
};

const pre = 'rentmanage-termschangerent-'

export default {
    path: '/rentmanage/termschangerent',
    name: 'rentmanage-termschangerent',
    redirect: {
        name: '${pre}-index'
    },

    meta: {
        ...meta,
    },

    component: BasicLayout,
    children: [{
            path: 'index',
            name: '${pre}termschangerent-index',

            meta: {
                ...meta,
                title: '合同变更'
            },

            component: () => import('@/pages/rentmanage/termschangerent/index'),
        },
        {
            path: 'detail-edit/:id',
            name: '${pre}termschangerent-detail-edit',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '条款变更编辑'
            },

            component: () => import('@/pages/rentmanage/rentleasecontractchangerequest/detail-edit'),
        },
        {
            path: 'detail-edit',
            name: '${pre}termschangerentdetail-create',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '条款变更新增'
            },

            component: () => import('@/pages/rentmanage/rentleasecontractchangerequest/detail-edit'),
        },
        {
            path: 'detail-view/:id',
            name: '${pre}termschangerentView',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '条款变更查看'
            },

            component: () => import('@/pages/rentmanage/rentleasecontractchangerequest/detail-view'),
        },
    ]
};
