/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-23T14:56:33.698
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getRentGoodsSortEditInit,
	getRentGoodsSortView
} from '@api/rentmanage/RentGoodsSort';

import {
listRentGoodsInit,
listRentGoodsByAuthorizations,
deleteRentGoods
} from '@api/rentmanage/RentGoods';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getRentGoodsSortEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentGoodsSortEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentGoodsSortView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentGoodsSortView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
