/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-04-29T08:55:39.23
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveHouseCycleDetail,
	getHouseCycleDetailCreateInit,
	getHouseCycleDetailEditInit,
	getHouseCycleDetailView,
	deleteHouseCycleDetail
} from '@api/house/HouseCycleDetail';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getHouseCycleDetailCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getHouseCycleDetailCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseCycleDetailEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseCycleDetailEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveHouseCycleDetail({}, HouseCycleDetail) {
			return new Promise((resolve, reject) => {
				saveHouseCycleDetail(HouseCycleDetail)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseCycleDetailView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseCycleDetailView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteHouseCycleDetail({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteHouseCycleDetail({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
