<template>
  <div>
    <!-- 自动完成组件 -->
    <AutoComplete placement="top" placeholder="请选择"
                  :disabled="disabled"
                  v-model="currentModel"
                  @on-search="getData" @on-change="getData" @on-focus="getData" @on-select="selectData">
      <div style="max-height: 500px;overflow-y: auto;">
        <Option :key="index" :value="item[propsValue]"
                :label="item[propsValue]"
                v-for="(item, index) in data" />
      </div>
    </AutoComplete>
  </div>
</template>
<script>
  import {isNull} from 'lodash'
  import request from '@/plugins/request'

  export default {
    name: 'AutoCompleteCommon',
    props: {
      //外部model(name)
      model: {
        default: '',
        required: true,
      },
      //外部model(id)
      label: {
        default: '',
        required: false,
      },
      //输入时影响的查询参数
      inputParam: {
        required: false,
      },
      //请求的完整参数
      params: {
        type: Object,
        required: true,
        default: (() => {
          return {
            entity: {},
            outerWhereSubjoin: {
              fuzzyMatching: '',
            },
            isPage: false,
          };
        }),
      },
      //返回数组的name字段名
      propsValue: {
        type: String,
        default: ''
      },
      //返回数组的id字段名
      propsLabel: {
        type: String,
        default: ''
      },
      //请求url
      getDataUrl: {
        type: String,
        default: '',
        required: true,
      },
      //是否禁用
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      //是否初始就发送请求
      searchInit: {
        type: Object,
        default: function () {
          return {
            flag: true,
            data: '',
          }
        },
      },
      depthEntities: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    watch: {
      model: {
        handler (value) {
          this.currentModel = isNull(value) ? '' : value
        },
        immediate: true
      },
      params: {
        handler (value) {
          this.currentParams = value
        },
        immediate: true
      },
      currentModel (val) {
        //输入时自动匹配值(全匹配)
        this.$emit('update:model', val)
        this.$emit('update:inputParam', val)
        let item = this.setValue(val)
        if (item) {
          this.$emit('update:label', item[this.propsLabel])
          this.$emit('setData', item)
        } else {
          this.$emit('update:label', '')
          this.$emit('setData', {})
        }
      },
    },
    computed: {
      user () {
        return this.$store.state.admin.user.info
      },
    },
    methods: {
      setValue (val) {
        //文字匹配选项
        for (let i = 0; i < this.data.length; i++) {
          if (val == this.data[i][this.propsValue]) {
            return this.data[i];
            break
          }
        }
        return null;
      },
      getData () {
        //获取下拉数据
        return new Promise((resolve, reject) => {
          request({
            url: this.getDataUrl,
            method: 'post',
            data: this.params,
          }).then(async res => {
            if (this.depthEntities) {
              this.data = res.entities.entities;
            } else {
              this.data = res.entities;
            }
            console.log(this.data, 'this.data')
            resolve();
          })
              .catch(err => {
                reject(err);
              });
        })
      },
      selectData (value) {
        //选择时触发
        let item = this.getItem(value)
        this.$emit('update:model', item[this.propsValue])
        this.$emit('update:label', item[this.propsLabel])
      },
      getItem (value) {
        //根据value获取具体item
        let item = this.data.find(item => item[this.propsValue] == value);
        return item
      },
    },
    data () {
      return {
        data: [],
        currentModel: '',
        currentParams: {},
      }
    },
  }
</script>
