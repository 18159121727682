/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-06-29T16:17:47.582
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialRentContractItemPaymentCollectionInit(data){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollection/listReportFinancialRentContractItemPaymentCollectionInit',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractItemPaymentCollectionByAuthorizations(data){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollection/listReportFinancialRentContractItemPaymentCollectionByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractItemPaymentCollection(data){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollection/listReportFinancialRentContractItemPaymentCollection',
method: 'post',
data:data,
});
}


export function getReportFinancialRentContractItemPaymentCollectionCreateInit(){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollection/getReportFinancialRentContractItemPaymentCollectionCreateInit',
method: 'post',
});
}


export function getReportFinancialRentContractItemPaymentCollectionEditInit(params){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollection/getReportFinancialRentContractItemPaymentCollectionEditInit',
method: 'post',
params:params,
});
}


export function getReportFinancialRentContractItemPaymentCollectionView(params){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollection/getReportFinancialRentContractItemPaymentCollectionView',
method: 'post',
params:params,
});
}


export function saveReportFinancialRentContractItemPaymentCollection(data){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollection/saveReportFinancialRentContractItemPaymentCollection',
method: 'post',
data:data,
});
}


export function deleteReportFinancialRentContractItemPaymentCollection(params){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollection/deleteReportFinancialRentContractItemPaymentCollection',
method: 'post',
params:params,
});
}


