/*
 * @Author: co
 * @Date: 2021-04-07 14:30:15
 */
/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-11T09:45:26.392
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialRentContractItemPaymentInit(data) {
    return request({
        url: '/report/api/reportFinancialRentContractItemPayment/listReportFinancialRentContractItemPaymentInit',
        method: 'post',
        data: data,
    });
}


export function listReportFinancialRentContractItemPaymentByAuthorizations(data) {
    return request({
        url: '/report/api/reportFinancialRentContractItemPayment/listReportFinancialRentContractItemPaymentByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listReportFinancialRentContractItemPayment(data) {
    return request({
        url: '/report/api/reportFinancialRentContractItemPayment/listReportFinancialRentContractItemPayment',
        method: 'post',
        data: data,
    });
}


export function getReportFinancialRentContractItemPaymentCreateInit() {
    return request({
        url: '/report/api/reportFinancialRentContractItemPayment/getReportFinancialRentContractItemPaymentCreateInit',
        method: 'post',
    });
}


export function getReportFinancialRentContractItemPaymentEditInit(params) {
    return request({
        url: '/report/api/reportFinancialRentContractItemPayment/getReportFinancialRentContractItemPaymentEditInit',
        method: 'post',
        params: params,
    });
}


export function getReportFinancialRentContractItemPaymentView(params) {
    return request({
        url: '/report/api/reportFinancialRentContractItemPayment/getReportFinancialRentContractItemPaymentView',
        method: 'post',
        params: params,
    });
}


export function saveReportFinancialRentContractItemPayment(data) {
    return request({
        url: '/report/api/reportFinancialRentContractItemPayment/saveReportFinancialRentContractItemPayment',
        method: 'post',
        data: data,
    });
}


export function deleteReportFinancialRentContractItemPayment(params) {
    return request({
        url: '/report/api/reportFinancialRentContractItemPayment/deleteReportFinancialRentContractItemPayment',
        method: 'post',
        params: params,
    });
}

// 应收报表统计
export function countReportFinancialRentContractItemPayment(data) {
    return request({
        url: '/report/api/reportFinancialRentContractItemPayment/countReportFinancialRentContractItemPayment',
        method: 'post',
        data: data,
    });
}


