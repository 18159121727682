import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'storagemanage-stockpileintentionSelf-'

export default {
	path: '/storagemanage/stockpileintentionSelf',
	name: 'storagemanage-stockpileintentionSelf',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}stockpileintentionSelf-index',

			meta: {
				...meta,
				title: '新建委托'
			},

			component: () => import('@/pages/storagemanage/stockpileintentionSelf/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}stockpileintentionSelf-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '新建委托编辑'
			},

			component: () => import('@/pages/storagemanage/stockpileintentionSelf/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}stockpileintentionSelfdetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '新建委托新增'
			},

			component: () => import('@/pages/storagemanage/stockpileintentionSelf/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}stockpileintentionSelfView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '新建委托详情查看'
			},

			component: () => import('@/pages/storagemanage/stockpileintentionSelf/detail-view'),
		},
	]
};
