/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T11:12:57.639
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-storemanage-'

export default 
{
path: '/rentmanage/storemanage',
name: 'rentmanage-storemanage',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}storemanage-index',

meta:{
...meta,
title: '门店房源管理'
},

component: () => import('@/pages/rentmanage/storemanage/index') ,
} 
]
};
