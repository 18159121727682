/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-10-13T17:19:55.486
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentSerComplaint,
	listRentSerComplaintInit,
	listRentSerComplaintByAuthorizations,
	deleteRentSerComplaint
	
} from '@api/rentmanage/RentSerComplaint';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentSerComplaint({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentSerComplaint(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentSerComplaintByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentSerComplaintByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentSerComplaintInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentSerComplaintInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentSerComplaint({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentSerComplaint({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
