/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-03T09:53:25.089
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentRepairWorkOrderInit(data){return request({
url: '/rentmanage/api/rentRepairWorkOrder/listRentRepairWorkOrderInit',
method: 'post',
data:data,
});
}

export function listRentRepairWorkOrderInitCount(data){return request({
	url: '/rentmanage/api/rentRepairWorkOrder/listRentRepairWorkOrderInitCount',
	method: 'post',
	data:data,
	});
}

export function listRentRepairWorkOrderByAuthorizations(data){return request({
url: '/rentmanage/api/rentRepairWorkOrder/listRentRepairWorkOrderByAuthorizations',
method: 'post',
data:data,
});
}
 

	export function listRentRepairWorkOrderByAuthorizationsNeedDealt(data){return request({
	url: '/rentmanage/api/rentRepairWorkOrder/listRentRepairWorkOrderByAuthorizationsNeedDealt',
	method: 'post',
	data:data,
	});
	}

	export function listRentRepairWorkOrderByAuthorizationsParticipation(data){return request({
		url: '/rentmanage/api/rentRepairWorkOrder/listRentRepairWorkOrderByAuthorizationsParticipation',
		method: 'post',
		data:data,
		});
		}
	
	export function listRentRepairWorkOrderByAuthorizationsUserAll(data){return request({
		url: '/rentmanage/api/rentRepairWorkOrder/listRentRepairWorkOrderByAuthorizationsUserAll',
		method: 'post',
		data:data,
		});
		}
	
export function listRentRepairWorkOrder(data){return request({
url: '/rentmanage/api/rentRepairWorkOrder/listRentRepairWorkOrder',
method: 'post',
data:data,
});
}


export function getRentRepairWorkOrderCreateInit(){return request({
url: '/rentmanage/api/rentRepairWorkOrder/getRentRepairWorkOrderCreateInit',
method: 'post',
});
}


export function getRentRepairWorkOrderEditInit(params){return request({
url: '/rentmanage/api/rentRepairWorkOrder/getRentRepairWorkOrderEditInit',
method: 'post',
params:params,
});
}


export function getRentRepairWorkOrderView(params){return request({
url: '/rentmanage/api/rentRepairWorkOrder/getRentRepairWorkOrderView',
method: 'post',
params:params,
});
}


export function saveRentRepairWorkOrder(data){return request({
url: '/rentmanage/api/rentRepairWorkOrder/saveRentRepairWorkOrder',
method: 'post',
data:data,
});
}

export function assignmentSubmit(data){return request({
	url: '/rentmanage/api/rentRepairWorkOrder/assignmentSubmit',
	method: 'post',
	data:data,
	});
	}

export function deleteRentRepairWorkOrder(params){return request({
url: '/rentmanage/api/rentRepairWorkOrder/deleteRentRepairWorkOrder',
method: 'post',
params:params,
});
}

export function cancelRepairWorkOrder(data){return request({
	url: '/rentmanage/api/rentRepairWorkOrder/cancelRepairWorkOrder',
	method: 'post',
	data:data,
	});
	}
export function startWorkflow(data){return request({
	url: '/rentmanage/api/rentRepairWorkOrder/startWorkflow',
	method: 'post',
	data:data,
	});
	}

export function exportDetails(data) {
	return request({
		url: '/rentmanage/api/rentRepairWorkOrder/exportDetails',
		method: 'post',
		data: data,
		responseType: 'blob'
	});
}
export function listRentRepairWorkOrderingByHouseCode(data){
	return request({
		url: '/rentmanage/api/rentRepairWorkOrder/listRentRepairWorkOrderingByHouseCode',
		method: 'post',
		data:data,
		});
}