/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-06-07 18:58:24
 * @LastEditors: co
 * @LastEditTime: 2021-06-17 11:10:25
 * @FilePath: \iview-admin-pro-template\src\store\modules\report\modules\reporthousecount\detail-view.js
 */
import util from '@/libs/util';
import router from '@/router';
import {
	findHomeReportDisplayInfoDetails,
	downloadFindHomeReportDisplayInfo,
	findSingleHomeReportDisplayInfo,
	downloadFindSingleHomeReportDisplayInfo
} from '@api/report/ReportHouseCount';
import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		downloadFindHomeReportDisplayInfo({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				downloadFindHomeReportDisplayInfo(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		downloadFindSingleHomeReportDisplayInfo({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				downloadFindSingleHomeReportDisplayInfo(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		findHomeReportDisplayInfoDetails({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				findHomeReportDisplayInfoDetails(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		findSingleHomeReportDisplayInfo({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				findSingleHomeReportDisplayInfo(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
