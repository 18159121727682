/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-30T21:02:57.583
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinancialClaimItem,
	listFinancialClaimItemInit,
	listFinancialClaimItemByAuthorizations,
	deleteFinancialClaimItem,
	listFinancialClaimItemInfo
} from '@api/financial/FinancialClaimItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFinancialClaimItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialClaimItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialClaimItemByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialClaimItemByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialClaimItemInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialClaimItemInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		listFinancialClaimItemInfo({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialClaimItemInfo(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialClaimItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialClaimItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
