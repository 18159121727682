/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-03T10:29:11.527
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-RentPublishChannel-'

export default 
{
path: '/rentmanage/rentpublishchannel',
name: 'rentmanage-RentPublishChannel',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}RentPublishChannel-index',

meta:{
...meta,
title: '发布渠道表'
},

component: () => import('@/pages/rentmanage/rentpublishchannel/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}RentPublishChannel-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '发布渠道表编辑'
},

component: () => import('@/pages/rentmanage/rentpublishchannel/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}RentPublishChanneldetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '发布渠道表新增'
},

component: () => import('@/pages/rentmanage/rentpublishchannel/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}RentPublishChannelView',
auth: [
'hidden'
],

meta:{
...meta,
title: '发布渠道表详情查看'
},

component: () => import('@/pages/rentmanage/rentpublishchannel/detail-view') ,
},
]
};
