/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-16T15:24:16.806
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentTakeLookRecord,
	listRentTakeLookRecordInit,
	listRentTakeLookRecordByAuthorizations,
	deleteRentTakeLookRecord,
	cancelRentTakeLookRecord,
	sendTempPwd,
	listRentTakeLookRecordStatistics
	
} from '@api/rentmanage/RentTakeLookRecord';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentTakeLookRecord({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTakeLookRecord(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentTakeLookRecordStatistics({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTakeLookRecordStatistics(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentTakeLookRecordByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTakeLookRecordByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentTakeLookRecordInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTakeLookRecordInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		sendTempPwd({}, TenantPwdRequestVo) {
			return new Promise((resolve, reject) => {
				sendTempPwd(TenantPwdRequestVo)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentTakeLookRecord({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentTakeLookRecord({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		cancelRentTakeLookRecord({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				cancelRentTakeLookRecord({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
	}
};
