/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-29T19:23:13.377
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportDayAllTargetedValueInit(data){return request({
url: '/dashboard/api/reportDayAllTargetedValue/listReportDayAllTargetedValueInit',
method: 'post',
data:data,
});
}


export function listReportDayAllTargetedValueByAuthorizations(data){return request({
url: '/dashboard/api/reportDayAllTargetedValue/listReportDayAllTargetedValueByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportDayAllTargetedValue(data){return request({
url: '/dashboard/api/reportDayAllTargetedValue/listReportDayAllTargetedValue',
method: 'post',
data:data,
});
}


export function getReportDayAllTargetedValueCreateInit(){return request({
url: '/dashboard/api/reportDayAllTargetedValue/getReportDayAllTargetedValueCreateInit',
method: 'post',
});
}


export function getReportDayAllTargetedValueEditInit(params){return request({
url: '/dashboard/api/reportDayAllTargetedValue/getReportDayAllTargetedValueEditInit',
method: 'post',
params:params,
});
}


export function getReportDayAllTargetedValueView(params){return request({
url: '/dashboard/api/reportDayAllTargetedValue/getReportDayAllTargetedValueView',
method: 'post',
params:params,
});
}


export function saveReportDayAllTargetedValue(data){return request({
url: '/dashboard/api/reportDayAllTargetedValue/saveReportDayAllTargetedValue',
method: 'post',
data:data,
});
}


export function deleteReportDayAllTargetedValue(params){return request({
url: '/dashboard/api/reportDayAllTargetedValue/deleteReportDayAllTargetedValue',
method: 'post',
params:params,
});
}

export function queryReportDayAllTargetedValue(data){return request({
url: '/dashboard/api/reportDayAllTargetedValue/queryReportDayAllTargetedValue',
method: 'post',
data:data,
});
}


