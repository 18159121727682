/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:57.246
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveBusiTrackInfo,
	getBusiTrackInfoCreateInit,
	getBusiTrackInfoEditInit,
	getBusiTrackInfoView,
	deleteBusiTrackInfo
} from '@api/lcf/BusiTrackInfo';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getBusiTrackInfoCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getBusiTrackInfoCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getBusiTrackInfoEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getBusiTrackInfoEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveBusiTrackInfo({}, BusiTrackInfo) {
			return new Promise((resolve, reject) => {
				saveBusiTrackInfo(BusiTrackInfo)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getBusiTrackInfoView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getBusiTrackInfoView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteBusiTrackInfo({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteBusiTrackInfo({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
