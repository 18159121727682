const pre = '/rentservice/';

export default {
    path: '/rentservice',
    title: '租后运营',
    header: 'home',
    custom: 'i-icon-demo i-icon-demo-list',
	icon: 'md-speedometer',
    children: [
        {
            path: `#53`,
            title: '保洁维修'
        },
        {
            path: `#54`,
            title: '投诉管理'
        }
    ]
}
