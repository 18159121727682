import util from '@/libs/util';
import router from '@/router';
import {
	listEvaluateBillHouse,
	listEvaluateBillHouseInit,
	listEvaluateBillHouseByAuthorizations,
	deleteEvaluateBillHouse
} from '@api/storagemanage/EvaluateBillHouse';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listEvaluateBillHouse({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillHouse(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateBillHouseByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillHouseByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateBillHouseInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillHouseInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateBillHouse({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateBillHouse({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
