/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-07-08T19:32:01.8
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: true
};

const pre = 'rentmanage-renewrent-'

export default {
    path: '/rentmanage/renewrent',
    name: 'rentmanage-renewrent',
    redirect: {
        name: '${pre}-index'
    },

    meta: {
        ...meta,
    },

    component: BasicLayout,
    children: [{
            path: 'index',
            name: '${pre}renewrent-index',

            meta: {
                ...meta,
                title: '续租管理'
            },

            component: () => import('@/pages/rentmanage/renewrent/index'),
        },
        {
            path: 'detail-edit/:id',
            name: '${pre}renewrent-detail-edit',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '续租管理编辑'
            },

            component: () => import('@/pages/rentmanage/rentleasecontractchangerequest/detail-edit'),
        },
        {
            path: 'detail-edit',
            name: '${pre}renewrentdetail-create',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '续租管理新增'
            },

            component: () => import('@/pages/rentmanage/rentleasecontractchangerequest/detail-edit'),
        },
        {
            path: 'detail-view/:id',
            name: '${pre}renewrentView',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '续租管理详情查看'
            },

            component: () => import('@/pages/rentmanage/rentleasecontractchangerequest/detail-view'),
        },
    ]
};
