/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-01T18:25:53.646
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveOrgPositionManage,
	getOrgPositionManageCreateInit,
	getOrgPositionManageEditInit,
	getOrgPositionManageView,
	deleteOrgPositionManage
} from '@api/systemmanage/OrgPositionManage';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getOrgPositionManageCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getOrgPositionManageCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getOrgPositionManageEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getOrgPositionManageEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveOrgPositionManage({}, OrgPositionManage) {
			return new Promise((resolve, reject) => {
				saveOrgPositionManage(OrgPositionManage)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getOrgPositionManageView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getOrgPositionManageView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteOrgPositionManage({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteOrgPositionManage({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
