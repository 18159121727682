<template>
  <div>
    <JFDrawer destroy-on-close title="加签流程" v-model="startModal">
      <Form ref="formData" label-position="top"
            :model="submitData" :rules="rules">
        <FormItem label="加签描述：" prop="approveContent">
          <Input show-word-limit type="textarea" placeholder="请填写加签描述"
                 :maxlength="500" :autosize="{minRows: 3, maxRows: 5}"
                 v-model="submitData.approveContent"/>
        </FormItem>
      </Form>
      <orgusermanagelistF7 ref="orgusermanagelistF7Ref" />

      <Card dis-hover title="流程痕迹" :bordered="false">
        <ProcessTrace ref="processTrace" :list="list" />
      </Card>

      <Spin fix v-if="loading" />
      <template slot="footer" v-if="!loading">
        <Button type="primary"
                :long="isMobile"
                :loading="submitting"
                @click="submitFlow">提交流程</Button>
      </template>
    </JFDrawer>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import ProcessTrace from './processTrace'
  import orgusermanagelistF7 from '@/pages/systemmanage/orgusermanage/indexF7'
  import request from '@/plugins/request'

  export default {
    name: 'ApostilleFlow',
    components: {
      orgusermanagelistF7,
      ProcessTrace
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
    },
    methods: {
      async init (data, url, billData) {
        try {
          this.startModal = true
          this.data = data
          this.flowUrl = url
          this.loading = true
          //审核结论设置初始值
          this.submitData.approveEffect =
            this.data.currentWorkitem.approveEffectRange &&
            this.data.currentWorkitem.approveEffectRange.length > 0
              ? this.data.currentWorkitem.approveEffectRange[0].code
              : ''
          //审核意见设置初始值
          this.submitData.approveContent =
            this.data.currentWorkitem.approveEffectRange &&
            this.data.currentWorkitem.approveEffectRange.length > 0
              ? this.data.currentWorkitem.approveEffectRange[0].name
              : ''
          // this.submitData.bill = billData;
          //流程实例id赋值(从审核初始化接口)
          this.submitData.workflowInstanceId = this.data.currentDraftWorkitemInstance.workflowInstanceId
          //流程环节实例id赋值(从审核初始化接口)
          this.submitData.workitemInstanceId = this.data.currentDraftWorkitemInstance.workitemInstanceId

          const {entities} = await request({
            url: '/worfklow/api/flowcenter/listProcessTraces',
            method: 'post',
            params: {
              workflowInstanceId: this.data.currentDraftWorkitemInstance
                .workflowInstanceId
            }
          })
          this.list = entities || []
        } catch (e) {} finally {
          this.loading = false
        }
      },
      async submitFlow () {
        try {
          let businessData = null
          const orgUser = this.$refs.orgusermanagelistF7Ref.getSelectData()

          if (!await this.$refs.formData.validate()) return
          if (!orgUser.length) return  this.$Message.warning('请选择用户!')
          if (orgUser.length > 1) return  this.$Message.warning('只能选择一个用户!')

          this.submitting = true
          this.$emit('getBusinessData', function (data) {
            businessData = data
          })
          this.submitData.bill = businessData
          await request({
            url: this.flowUrl + '/apostilleWorkflow',
            method: 'post',
            data: this.submitData.bill,
            params: {
              approveContent: this.submitData.approveContent,
              nextUserId: orgUser[0].userId,
              nextUserName: orgUser[0].userName,
              workflowInstanceId: this.submitData.workflowInstanceId,
              workitemInstanceId: this.submitData.workitemInstanceId
            }
          })

          this.startModal = false
          this.$emit('refresh')
        } catch (e) {} finally {
          this.submitting = false
        }
      }
    },
    data () {
      return {
        startModal: false,
        loading: false,
        data: {}, //流程初始化传入的数据
        flowUrl: '', //发起流程的url
        list: [],
        total: 0,
        submitData: {
          bill: {},
          workflowInstanceId: '',
          workitemInstanceId: '',
          approveContent: '',
          approveEffect: 'AGREE'
        }, //提交流程的数据对象
        rules: {
          approveContent: [
            {
              required: true,
              message: '请选择审核意见',
              trigger: 'blur'
            }
          ]
        },

        submitting: false,
      }
    },
  }
</script>
