/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-17T17:56:04.341
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentIssueHouseApply,
	getRentIssueHouseApplyCreateInit,
	getRentIssueHouseApplyEditInit,
	getRentIssueHouseApplyView,
	startWorkflow,
	deleteRentIssueHouseApply
} from '@api/rentmanage/RentIssueHouseApply';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentIssueHouseApplyItemInit,
listRentIssueHouseApplyItemByAuthorizations,
deleteRentIssueHouseApplyItem
} from '@api/rentmanage/RentIssueHouseApplyItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentIssueHouseApplyCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentIssueHouseApplyCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentIssueHouseApplyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentIssueHouseApplyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		startWorkflow({}, RentIssueHouseApply) {
			return new Promise((resolve, reject) => {
				startWorkflow(RentIssueHouseApply)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		saveRentIssueHouseApply({}, RentIssueHouseApply) {
			return new Promise((resolve, reject) => {
				saveRentIssueHouseApply(RentIssueHouseApply)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentIssueHouseApplyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentIssueHouseApplyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentIssueHouseApply({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentIssueHouseApply({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
