/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2022-05-19T15:50:18.304
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseArtificialSalesPriceItemInit(data){return request({
url: '/rentmanage/api/rentHouseArtificialSalesPriceItem/listRentHouseArtificialSalesPriceItemInit',
method: 'post',
data:data,
});
}


export function listRentHouseArtificialSalesPriceItemByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseArtificialSalesPriceItem/listRentHouseArtificialSalesPriceItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseArtificialSalesPriceItem(data){return request({
url: '/rentmanage/api/rentHouseArtificialSalesPriceItem/listRentHouseArtificialSalesPriceItem',
method: 'post',
data:data,
});
}


export function getRentHouseArtificialSalesPriceItemCreateInit(){return request({
url: '/rentmanage/api/rentHouseArtificialSalesPriceItem/getRentHouseArtificialSalesPriceItemCreateInit',
method: 'post',
});
}


export function getRentHouseArtificialSalesPriceItemEditInit(params){return request({
url: '/rentmanage/api/rentHouseArtificialSalesPriceItem/getRentHouseArtificialSalesPriceItemEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseArtificialSalesPriceItemView(params){return request({
url: '/rentmanage/api/rentHouseArtificialSalesPriceItem/getRentHouseArtificialSalesPriceItemView',
method: 'post',
params:params,
});
}


export function saveRentHouseArtificialSalesPriceItem(data){return request({
url: '/rentmanage/api/rentHouseArtificialSalesPriceItem/saveRentHouseArtificialSalesPriceItem',
method: 'post',
data:data,
});
}

export function downloadHouseSalesPriceTemplateArtificial(params) {
	return request({
		url: '/rentmanage/api/rentHouseArtificialSalesPriceItem/downloadHouseSalesPriceTemplateArtificial',
		method: 'post',
		params:params,
		responseType: 'blob'
	});
}
export function downloadBathHousesExcel(params) {
	return request({
		url: '/rentmanage/api/rentHouseArtificialSalesPriceItem/downloadBathHousesExcel',
		method: 'post',
		params:params,
		responseType: 'blob'
	});
}

export function deleteRentHouseArtificialSalesPriceItem(params){return request({
url: '/rentmanage/api/rentHouseArtificialSalesPriceItem/deleteRentHouseArtificialSalesPriceItem',
method: 'post',
params:params,
});
}


