/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-08T21:12:24.352
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'storagemanage-followbill-'

export default {
	path: '/storagemanage/followbill',
	name: 'storagemanage-followbill',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}followbill-index',

			meta: {
				...meta,
				title: '跟进单'
			},

			component: () => import('@/pages/storagemanage/followbill/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}followbill-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '跟进单编辑'
			},

			component: () => import('@/pages/storagemanage/followbill/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}followbilldetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '跟进单新增'
			},

			component: () => import('@/pages/storagemanage/followbill/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}followbillView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '跟进单详情查看'
			},

			component: () => import('@/pages/storagemanage/followbill/detail-view'),
		},
	]
};
