/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-04-28T09:57:01.359
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listUnitInit(data){return request({
url: '/storagemanage/api/unit/listUnitInit',
method: 'post',
data:data,
});
}


export function listUnitByAuthorizations(data){return request({
url: '/storagemanage/api/unit/listUnitByAuthorizations',
method: 'post',
data:data,
});
}


export function listUnit(data){return request({
url: '/storagemanage/api/unit/listUnit',
method: 'post',
data:data,
});
}


export function getUnitCreateInit(){return request({
url: '/storagemanage/api/unit/getUnitCreateInit',
method: 'post',
});
}


export function getUnitEditInit(params){return request({
url: '/storagemanage/api/unit/getUnitEditInit',
method: 'post',
params:params,
});
}


export function getUnitView(params){return request({
url: '/storagemanage/api/unit/getUnitView',
method: 'post',
params:params,
});
}


export function saveUnit(data){return request({
url: '/storagemanage/api/unit/saveUnit',
method: 'post',
data:data,
});
}


export function deleteUnit(params){return request({
url: '/storagemanage/api/unit/deleteUnit',
method: 'post',
params:params,
});
}


