<template>
	<!-- 发起流程 -->
  <JFDrawer destroy-on-close title="提交流程" v-model="startModal">
    <Form ref="formData" label-position="top"
          :model="submitData" :rules="rules">
      <FormItem label="加签描述：" prop="approveContent">
        <Input show-word-limit type="textarea" placeholder="请填写加签描述"
               :maxlength="500" :autosize="{minRows: 3, maxRows: 5}"
               v-model="submitData.approveContent"/>
      </FormItem>
    </Form>
    <template slot="footer">
      <Button type="primary"
              :long="isMobile"
              :loading="submitting"
              @click="submitFlow">提交流程</Button>
    </template>
  </JFDrawer>
</template>
<script>
  import { mapState } from 'vuex'
	import request from '@/plugins/request'

	export default {
		name: 'DealApostilleFlow',
		computed: {
      ...mapState('admin/layout', ['isMobile']),
		},
		methods: {
			init(data, url, billData) {
        this.startModal = true;
        this.data = data;
        this.flowUrl = url;
        //审核结论设置初始值
        this.submitData.approveEffect = this.data.currentWorkitem.approveEffectRange && this.data.currentWorkitem.approveEffectRange
          .length > 0 ? this.data.currentWorkitem.approveEffectRange[0].code : '';
        //审核意见设置初始值
        this.submitData.approveContent = this.data.currentWorkitem.approveEffectRange && this.data.currentWorkitem.approveEffectRange
          .length > 0 ? this.data.currentWorkitem.approveEffectRange[0].name : '';
        // this.submitData.bill = billData;
        //流程实例id赋值(从审核初始化接口)
        this.submitData.workflowInstanceId = this.data.currentDraftWorkitemInstance.workflowInstanceId;
        //流程环节实例id赋值(从审核初始化接口)
        this.submitData.workitemInstanceId = this.data.currentDraftWorkitemInstance.workitemInstanceId;
			},
			async submitFlow() {
				try {
          if (!await this.$refs.formData.validate()) return
          let businessData = null
          this.$emit('getBusinessData', function(data) {
            businessData = data;
          })
          this.submitData.bill = businessData
          this.submitting = true
          //提交流程
          await request({
            url: this.flowUrl + "/submitWorkflow",
            method: 'post',
            data: this.submitData,
            params: {
              approveContent: this.submitData.approveContent,
              approveEffect: this.submitData.approveEffect,
              workflowInstanceId: this.submitData.workflowInstanceId,
              workitemInstanceId: this.submitData.workitemInstanceId,
            }
          })

          this.startModal = false
          this.$emit('refresh')
        } catch (e) {} finally {
          this.submitting = false
        }
			},
		},
    data() {
      return {
        startModal: false,
        data: {}, //流程初始化传入的数据
        flowUrl: "", //发起流程的url
        submitData: {
          bill: {},
          workflowInstanceId: "",
          workitemInstanceId: "",
          approveContent: "",
          approveEffect: "AGREE",
        }, //提交流程的数据对象
        rules: {
          approveContent: [{
            required: true,
            message: '请选择审核意见',
            trigger: 'blur'
          }],
        },
        submitting: false,
      }
    },
	}
</script>
