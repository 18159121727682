/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-18T19:58:30.511
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listConDeputeContractBackInit(data){return request({
url: '/storagemanage/api/conDeputeContractBack/listConDeputeContractBackInit',
method: 'post',
data:data,
});
}


export function listConDeputeContractBackByAuthorizations(data){return request({
url: '/storagemanage/api/conDeputeContractBack/listConDeputeContractBackByAuthorizations',
method: 'post',
data:data,
});
}


export function listConDeputeContractBack(data){return request({
url: '/storagemanage/api/conDeputeContractBack/listConDeputeContractBack',
method: 'post',
data:data,
});
}


export function getConDeputeContractBackCreateInit(){return request({
url: '/storagemanage/api/conDeputeContractBack/getConDeputeContractBackCreateInit',
method: 'post',
});
}


export function getConDeputeContractBackEditInit(params){return request({
url: '/storagemanage/api/conDeputeContractBack/getConDeputeContractBackEditInit',
method: 'post',
params:params,
});
}


export function getConDeputeContractBackView(params){return request({
url: '/storagemanage/api/conDeputeContractBack/getConDeputeContractBackView',
method: 'post',
params:params,
});
}


export function saveConDeputeContractBack(data){return request({
url: '/storagemanage/api/conDeputeContractBack/saveConDeputeContractBack',
method: 'post',
data:data,
});
}


export function deleteConDeputeContractBack(params){return request({
url: '/storagemanage/api/conDeputeContractBack/deleteConDeputeContractBack',
method: 'post',
params:params,
});
}


