/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:57.853
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listEvaluationMicroRemakeProblemInit(data){return request({
url: '/lcf/api/evaluationMicroRemakeProblem/listEvaluationMicroRemakeProblemInit',
method: 'post',
data:data,
});
}


export function listEvaluationMicroRemakeProblemByAuthorizations(data){return request({
url: '/lcf/api/evaluationMicroRemakeProblem/listEvaluationMicroRemakeProblemByAuthorizations',
method: 'post',
data:data,
});
}


export function listEvaluationMicroRemakeProblem(data){return request({
url: '/lcf/api/evaluationMicroRemakeProblem/listEvaluationMicroRemakeProblem',
method: 'post',
data:data,
});
}


export function getEvaluationMicroRemakeProblemCreateInit(){return request({
url: '/lcf/api/evaluationMicroRemakeProblem/getEvaluationMicroRemakeProblemCreateInit',
method: 'post',
});
}


export function getEvaluationMicroRemakeProblemEditInit(params){return request({
url: '/lcf/api/evaluationMicroRemakeProblem/getEvaluationMicroRemakeProblemEditInit',
method: 'post',
params:params,
});
}


export function getEvaluationMicroRemakeProblemView(params){return request({
url: '/lcf/api/evaluationMicroRemakeProblem/getEvaluationMicroRemakeProblemView',
method: 'post',
params:params,
});
}


export function saveEvaluationMicroRemakeProblem(data){return request({
url: '/lcf/api/evaluationMicroRemakeProblem/saveEvaluationMicroRemakeProblem',
method: 'post',
data:data,
});
}


export function deleteEvaluationMicroRemakeProblem(params){return request({
url: '/lcf/api/evaluationMicroRemakeProblem/deleteEvaluationMicroRemakeProblem',
method: 'post',
params:params,
});
}


