/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-09T17:19:06.611
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportRentContract,
	getReportRentContractCreateInit,
	getReportRentContractEditInit,
	getReportRentContractView,
	deleteReportRentContract
} from '@api/report/ReportRentContract';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportRentContractCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportRentContractCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportRentContractEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportRentContractEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportRentContract({}, ReportRentContract) {
			return new Promise((resolve, reject) => {
				saveReportRentContract(ReportRentContract)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportRentContractView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportRentContractView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportRentContract({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportRentContract({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
