/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-01T18:25:53.628
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listOrgPositionManageInit(data){return request({
url: '/systemmanage/api/orgPositionManage/listOrgPositionManageInit',
method: 'post',
data:data,
});
}


export function listOrgPositionManageByAuthorizations(data){return request({
url: '/systemmanage/api/orgPositionManage/listOrgPositionManageByAuthorizations',
method: 'post',
data:data,
});
}


export function listOrgPositionManage(data){return request({
url: '/systemmanage/api/orgPositionManage/listOrgPositionManage',
method: 'post',
data:data,
});
}


export function getOrgPositionManageCreateInit(){return request({
url: '/systemmanage/api/orgPositionManage/getOrgPositionManageCreateInit',
method: 'post',
});
}


export function getOrgPositionManageEditInit(params){return request({
url: '/systemmanage/api/orgPositionManage/getOrgPositionManageEditInit',
method: 'post',
params:params,
});
}


export function getOrgPositionManageView(params){return request({
url: '/systemmanage/api/orgPositionManage/getOrgPositionManageView',
method: 'post',
params:params,
});
}


export function saveOrgPositionManage(data){return request({
url: '/systemmanage/api/orgPositionManage/saveOrgPositionManage',
method: 'post',
data:data,
});
}


export function deleteOrgPositionManage(params){return request({
url: '/systemmanage/api/orgPositionManage/deleteOrgPositionManage',
method: 'post',
params:params,
});
}


