/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-30T21:02:57.447
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinancialClaim,
	listFinancialClaimInit,
	listFinancialClaimByAuthorizations,
	deleteFinancialClaim,
	cancellationFinancialClaim

} from '@api/financial/FinancialClaim';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFinancialClaim({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialClaim(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialClaimByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialClaimByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities = res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialClaimInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialClaimInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialClaim({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				deleteFinancialClaim({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		cancellationFinancialClaim({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				cancellationFinancialClaim({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
