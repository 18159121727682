/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2022-05-19T15:50:18.343
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHouseArtificialSalesPriceItem,
	getRentHouseArtificialSalesPriceItemCreateInit,
	getRentHouseArtificialSalesPriceItemEditInit,
	getRentHouseArtificialSalesPriceItemView,
	deleteRentHouseArtificialSalesPriceItem
} from '@api/rentmanage/RentHouseArtificialSalesPriceItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentHouseArtificialSalesPriceItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseArtificialSalesPriceItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseArtificialSalesPriceItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseArtificialSalesPriceItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseArtificialSalesPriceItem({}, RentHouseArtificialSalesPriceItem) {
			return new Promise((resolve, reject) => {
				saveRentHouseArtificialSalesPriceItem(RentHouseArtificialSalesPriceItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentHouseArtificialSalesPriceItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseArtificialSalesPriceItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentHouseArtificialSalesPriceItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseArtificialSalesPriceItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
