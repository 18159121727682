import Qs from 'qs'
import { get, pick, isObject } from 'lodash'
import cryptoJS from 'crypto-js'
import dayjs from 'dayjs'

import router from '@/router'
import storage from '@/libs/vendors/storage'
import Setting, { sso, SECRET_IV, SECRET_KEY } from '@/setting'
import cookies from './util.cookies'

/**
 * 是否微信浏览器
 * @return {boolean}
 */
export const isWecaht = () => /micromessenger/i.test(navigator.userAgent)

/**
 * 是否企业微信浏览器
 * @return {boolean}
 */
export const isWxwork = () => /wxwork/i.test(navigator.userAgent)

/**
 * 跳转统一登录
 */
export const jumpSSOLogin = ({ to, logout = false }) => {
  const currentRoute = router.resolve({ name: 'dashboard-console' })
  const redirect = router.resolve({
    name: 'redirect',
    params: { route: currentRoute.route.fullPath.substring(1) }
  })

  // 清除浏览器缓存
  storage.clear()
  storage.session.clear()
  location.href = `${sso}?redirect=${encodeURIComponent(`${location.href.split('#')[0]}${redirect.href}`)}${logout && get(to, 'query.system') !== 'jump' ? `&logout=${logout}` : ''}`
}

/**
 * 加密
 * @param {object | string} data
 * @return {string}
 */
export const encrypt = (data) => {
  //加密
  if (isObject(data)) {
    try {
      data = JSON.stringify(data)
    } catch (e) {
      throw new Error('encrypt error' + e)
    }
  }
  const dataHex = cryptoJS.enc.Utf8.parse(data)
  const encrypted = cryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
    iv: SECRET_IV,
    mode: cryptoJS.mode.CBC,
    padding: cryptoJS.pad.Pkcs7,
  })
  return encrypted.ciphertext.toString()
}

/**
 * 解密
 * @param {string} data
 * @return {string}
 */
export const decrypt = (data) => {
  const encryptedHexStr = cryptoJS.enc.Hex.parse(data)
  const str = cryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = cryptoJS.AES.decrypt(str, SECRET_KEY, {
    iv: SECRET_IV,
    mode: cryptoJS.mode.CBC,
    padding: cryptoJS.pad.Pkcs7,
  })
  const decryptedStr = decrypt.toString(cryptoJS.enc.Utf8)
  return decryptedStr.toString()
}

/**
 * 格式化日期
 * @param value
 * @param format
 * @param customParseFormat
 * @return {string}
 */
export const formatDate = (value, format = 'YYYY-MM-DD', customParseFormat = '') => {
  if (!value) return ''
  if (!customParseFormat) return dayjs(value).format(format)
  return dayjs(value, customParseFormat).format(format)
}

/**
 * 跳转对应平台
 * @param {string|object} data 携带参数
 * @param {string=} redirect 重定向地址
 */
export function jumpPlatform(data, redirect = '') {
  location.href = `${redirect}${redirect.includes('?') ? '&' : '?'}${Qs.stringify(pick(data, ['id', 'userCode', 'userType', 'accessToken']))}`
}

/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */
export const title = ({ title, count }) => {
  const fullTitle = `${title ? `${title} - ` : ''}${Setting.titleSuffix}`

  window.document.title = `${count ? `(${count}条消息) ` : ''}${fullTitle}`
}

export const requestAnimation = (task) => {
  if ('requestAnimationFrame' in window) return window.requestAnimationFrame(task)
  setTimeout(task, 16)
}

export {
  cookies,
}
export default {
  title,
  cookies,
  requestAnimation,
}

