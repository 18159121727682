/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2024-01-11T10:42:57.39
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getRentHouseHoldItemHistoryEditInit,
	getRentHouseHoldItemHistoryView
} from '@api/rentmanage/RentHouseHoldItemHistory';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		getRentHouseHoldItemHistoryEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseHoldItemHistoryEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseHoldItemHistoryView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseHoldItemHistoryView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
