/**
 * 注册、登录、注销
 * */
import util from '@/libs/util';
import router from '@/router';
import {
	pageInit,
	GetProgressList,
	getCode,
	GetDataList,
	addPageInit,
	AddOrEditData,
	editPageInitReq,
	getArea,
	getAreaById,
	deteleRecordReq,
} from '@api/mobile/savehouse';
import {
	formatTime
} from '@/plugins/utils';
import {
	Message,
	Notice
} from 'view-design';

export default {
	namespaced: true,
	state: {
		reqParams: {
			"entity": {
				"approvalStatus": "",
				"areaId": "",
				"areaName": "",
				"cityId": "",
				"cityName": "",
				"companyId": "",
				"companyName": "",
				"content": "",
				"createTime": "",
				"createrId": "",
				"createrName": "",
				"deleteState": "",
				"extField1": "",
				"lastUpdateDate": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"orgId": "",
				"orgName": "",
				"productDetail": "",
				"productId": "",
				"provinceId": "",
				"provinceName": "",
				"remark": "",
				"saasCompany": "",
				"showId": "",
				"showType": "",
				"simpleDescription": "",
				"smallLogo": "",
				"status": "",
				"streetId": "",
				"streetName": "",
				"tags": "",
				"title": "",
				"totalAmount": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 1,
			"pageSize": 10,
			"selectColumns": []
		},
		progressReqParams: {
			"entity": {
				"contractCode": "",
				"contractId": "",
				"createTime": "",
				"createrId": "",
				"createrName": "",
				"deleteState": "",
				"descript": "",
				"houseAddress": "",
				"houseId": "",
				"huoseNo": "",
				"id": "",
				"lastUpdateDate": "",
				"newStatus": "",
				"newStatusName": "",
				"oldStatus": "",
				"oldStatusName": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"relationId": "",
				"saasCompany": "",
				"sort": "",
				"speedProgressTime": "",
				"speedType": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": false,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 1,
			"pageSize": 1000,
			"selectColumns": []
		},
		//列表初始信息
		initInfo: {
			configItems: {}
		},
		//商家信息
		companyInfo:{},
		//商家详情
		companyDetail: '',
		//产品详情
		productDetail: [],
		productIndex:0,
		pageInitParams: {
			"address": "",
			"approvalStatus": "",
			"areaApprovalRemark": "",
			"areaApprovalStatus": "",
			"areaId": "",
			"areaName": "",
			"authStatus": "",
			"cityId": "",
			"cityName": "",
			"companyAddress": "",
			"companyBizType": "",
			"companyCode": "",
			"companyDetail": "",
			"companyEstablishedTime": "",
			"companyId": "",
			"companyLevel": "",
			"companyName": "",
			"companyNature": "",
			"companyShorName": "",
			"companyType": "",
			"createTime": "",
			"createrId": "",
			"createrName": "",
			"deleteState": "",
			"description": "",
			"effectiveTime": "",
			"extField1": "",
			"extField2": "",
			"extField3": "",
			"fixedAssets": "",
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"lastUpdateDate": "",
			"licenceCode": "",
			"licencePeriod": "",
			"licencePeriodType": "",
			"managementHouseNum": "",
			"managementStatus": "",
			"operatorId": "",
			"operatorLongName": "",
			"operatorName": "",
			"operatorTime": "",
			"orderColumn": "",
			"pageIndex": 0,
			"pageSize": 10,
			"platformOrgId": "",
			"platformOrgName": "",
			"productShowList": [{
				"approvalStatus": "",
				"areaId": "",
				"areaName": "",
				"cityId": "",
				"cityName": "",
				"companyId": "",
				"companyName": "",
				"content": "",
				"createTime": "",
				"createrId": "",
				"createrName": "",
				"deleteState": "",
				"extField1": "",
				"lastUpdateDate": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"orgId": "",
				"orgName": "",
				"productDetail": "",
				"productId": "",
				"provinceId": "",
				"provinceName": "",
				"remark": "",
				"saasCompany": "",
				"showId": "",
				"showType": "",
				"simpleDescription": "",
				"smallLogo": "",
				"status": "",
				"streetId": "",
				"streetName": "",
				"tags": "",
				"title": "",
				"totalAmount": ""
			}],
			"provinceId": "",
			"provinceName": "",
			"registeredCapital": "",
			"remark": "",
			"saasCompany": "",
			"selectColumns": [],
			"slogan": "",
			"source": "",
			"starRate": "",
			"status": "",
			"streetId": "",
			"streetName": "",
			"tags": "",
			"theIsSelfEvaluate": ""
		},

		getAreaParams: {
			"entity": {
				"areaLevel": "",
				"areaName": "",
				"areaNum": "",
				"createId": "",
				"createName": "",
				"createTime": "",
				"deleteState": 0,
				"descript": "",
				"id": "",
				"isDirectly": "",
				"lastUpdateDate": "",
				"latitude": "",
				"longitude": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"parentId": "",
				"saasCompany": "",
				"serialNo": "",
				"shortName": "",
				"tmpId": "",
				"tmpLongName": "",
				"tmpOldId": "",
				"tmpOldParentId": "",
				"tmpParentId": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 0,
			"pageSize": 0,
			"selectColumns": []
		},
	},
	actions: {
		pageInit({
			state,
			dispatch
		}) {
			return new Promise((resolve, reject) => {
				pageInit(state.pageInitParams)
					.then(async res => {
						state.initInfo = res;
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getListData({
			dispatch,
			state,
		}) {
			state.reqParams.outerWhereSubjoin.startDate = formatTime(state.reqParams.outerWhereSubjoin.startDate,
				'YYYY-MM-DD HH:mm:ss')
			state.reqParams.outerWhereSubjoin.endDate = formatTime(state.reqParams.outerWhereSubjoin.endDate,
				'YYYY-MM-DD HH:mm:ss')
			return new Promise((resolve, reject) => {
				GetDataList(state.reqParams)
					.then(async res => {
						// state.listTreeDataObj = res
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
		
		GetProgressList({
			dispatch,
			state,
		},userId) {
			return new Promise((resolve, reject) => {
				GetProgressList({
					userId:userId
				})
					.then(async res => {
						// state.listTreeDataObj = res
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
		
		getCode({
			dispatch,
			state,
		},mobile) {
			return new Promise((resolve, reject) => {
				getCode({
					mobile:mobile
				})
					.then(async res => {
						// state.listTreeDataObj = res
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
		
		addPageInit({
			state,
			dispatch
		}, info) {
			return new Promise((resolve, reject) => {
				addPageInit({})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		editPageInit({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				editPageInitReq({
						id: id
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getArea({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				getArea(state.getAreaParams)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getAreaById({
			state,
			dispatch
		}, params) {
			return new Promise((resolve, reject) => {
				getAreaById(params)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		deteleRecord({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				deteleRecordReq({
						id: id
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		submitData({
			state,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				AddOrEditData(data)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
	},
	mutations: {
		updateParentReqParams(state, obj) {
			state.parentParams['entity'][obj.key] = obj.value;
		},
		updateChildReqParams(state, obj) {
			state.childParams['entity'][obj.key] = obj.value;
		},
		resetChildReqParams(state, obj) {
			state.childParams = {
				"entity": {
					"groupType": "",
					"id": "",
					"lastUpdateDate": "",
					"nodeType": "",
					"operatorId": "",
					"operatorLongName": "",
					"operatorName": "",
					"operatorTime": "",
					"parentId": "",
					"remark": "",
					"saasCompany": "",
					"treeCode": "",
					"treeName": ""
				},
				"isOrder": true,
				"isOrderAsc": true,
				"isPage": true,
				"orderColumn": "",
				"outerWhereSubjoin": {
					"fuzzyMatching": ""
				},
				"pageIndex": 1,
				"pageSize": 10,
				"selectColumns": []
			}
		},
	}
};
