/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-16T19:11:22.063
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentHouseBig,
	listRentHouseBigInit,
	listRentHouseBigByAuthorizations,
	deleteRentHouseBig,
	listRentHouseBigBySQL,
	listRentHouseBigBySQLLock,
	wxListRentLeaseContractHousekeeper,
	saveKeyInfo,
	synchronizeHkDataApi,
	cancelReservation,
	rentalApplicationApi,
	listRentHouseBigByChannelSQL,
	downloadRenthousebigFocusTemplate
} from '@api/rentmanage/RentHouseBig';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentHouseBig({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseBig(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		downloadRenthousebigFocusTemplate({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				downloadRenthousebigFocusTemplate(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentHouseBigByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseBigByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseBigInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseBigInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentHouseBigBySQL({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseBigBySQL(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentHouseBigBySQLLock({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseBigBySQLLock(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentHouseBigByChannelSQL({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseBigByChannelSQL(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res; 
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		wxListRentLeaseContractHousekeeper({
			state
		}, {tenantId}) {
			return new Promise((resolve, reject) => {
				wxListRentLeaseContractHousekeeper({
					tenantId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentHouseBig({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseBig({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		//取消保留
		cancelReservationApi({}, data) {
			return new Promise((resolve, reject) => {
				cancelReservation(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		rentalApplicationApi({}, data) {
			return new Promise((resolve, reject) => {
				rentalApplicationApi(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		saveKeyInfo({}, KeyInfo) {
			return new Promise((resolve, reject) => {
				saveKeyInfo(KeyInfo)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		synchronizeHkData({}, data) {
			return new Promise((resolve, reject) => {
				synchronizeHkDataApi(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
