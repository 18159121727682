/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-24T17:21:47.013
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-renthousepic-'

export default 
{
path: '/rentmanage/renthousepic',
name: 'rentmanage-renthousepic',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}renthousepic-index',

meta:{
...meta,
title: '房源图片'
},

component: () => import('@/pages/rentmanage/renthousepic/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}renthousepic-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源图片编辑'
},

component: () => import('@/pages/rentmanage/renthousepic/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}renthousepicdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源图片新增'
},

component: () => import('@/pages/rentmanage/renthousepic/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}renthousepicView',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源图片详情查看'
},

component: () => import('@/pages/rentmanage/renthousepic/detail-view') ,
},
]
};
