/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2021-07-15T10:05:28.363
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'rentmanage-energyapplication-'

export default {
	path: '/rentmanage/energyapplication',
	name: 'rentmanage-energyapplication',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}energyapplication-index',

			meta: {
				...meta,
				title: '日杂费申请单记录'
			},

			component: () => import('@/pages/rentmanage/energyapplication/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}energyapplication-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '日杂费申请单记录编辑'
			},

			component: () => import('@/pages/rentmanage/energyapplication/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}energyapplicationdetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '日杂费申请单记录新增'
			},

			component: () => import('@/pages/rentmanage/energyapplication/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}energyapplicationView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '日杂费申请单记录详情查看'
			},

			component: () => import('@/pages/rentmanage/energyapplication/detail-view'),
		},
	]
};
