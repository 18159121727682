/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-02T18:37:11.297
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveInvoiceToolsHouse,
	getInvoiceToolsHouseCreateInit,
	getInvoiceToolsHouseEditInit,
	getInvoiceToolsHouseView,
	deleteInvoiceToolsHouse
} from '@api/smallapplication/InvoiceToolsHouse';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listInvoiceToolsInvoiceInit,
listInvoiceToolsInvoiceByAuthorizations,
deleteInvoiceToolsInvoice
} from '@api/smallapplication/InvoiceToolsInvoice';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getInvoiceToolsHouseCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getInvoiceToolsHouseCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getInvoiceToolsHouseEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getInvoiceToolsHouseEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveInvoiceToolsHouse({}, InvoiceToolsHouse) {
			return new Promise((resolve, reject) => {
				saveInvoiceToolsHouse(InvoiceToolsHouse)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getInvoiceToolsHouseView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getInvoiceToolsHouseView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteInvoiceToolsHouse({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteInvoiceToolsHouse({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
