/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-11T09:36:43.131
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialStorageContractInit(data){return request({
url: '/report/api/reportFinancialStorageContract/listReportFinancialStorageContractInit',
method: 'post',
data:data,
});
}


export function listReportFinancialStorageContractByAuthorizations(data){return request({
url: '/report/api/reportFinancialStorageContract/listReportFinancialStorageContractByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportFinancialStorageContract(data){return request({
url: '/report/api/reportFinancialStorageContract/listReportFinancialStorageContract',
method: 'post',
data:data,
});
}


export function getReportFinancialStorageContractCreateInit(){return request({
url: '/report/api/reportFinancialStorageContract/getReportFinancialStorageContractCreateInit',
method: 'post',
});
}


export function getReportFinancialStorageContractEditInit(params){return request({
url: '/report/api/reportFinancialStorageContract/getReportFinancialStorageContractEditInit',
method: 'post',
params:params,
});
}


export function getReportFinancialStorageContractView(params){return request({
url: '/report/api/reportFinancialStorageContract/getReportFinancialStorageContractView',
method: 'post',
params:params,
});
}


export function saveReportFinancialStorageContract(data){return request({
url: '/report/api/reportFinancialStorageContract/saveReportFinancialStorageContract',
method: 'post',
data:data,
});
}


export function deleteReportFinancialStorageContract(params){return request({
url: '/report/api/reportFinancialStorageContract/deleteReportFinancialStorageContract',
method: 'post',
params:params,
});
}


