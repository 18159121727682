/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-15T15:47:42.995
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listEvaluateAppointmentBillInit(data){return request({
url: '/storagemanage/api/evaluateAppointmentBill/listEvaluateAppointmentBillInit',
method: 'post',
data:data,
});
}


export function listEvaluateAppointmentBillByAuthorizations(data){return request({
url: '/storagemanage/api/evaluateAppointmentBill/listEvaluateAppointmentBillByAuthorizations',
method: 'post',
data:data,
});
}


export function listEvaluateAppointmentBill(data){return request({
url: '/storagemanage/api/evaluateAppointmentBill/listEvaluateAppointmentBill',
method: 'post',
data:data,
});
}


export function getEvaluateAppointmentBillCreateInit(){return request({
url: '/storagemanage/api/evaluateAppointmentBill/getEvaluateAppointmentBillCreateInit',
method: 'post',
});
}


export function getEvaluateAppointmentBillEditInit(params){return request({
url: '/storagemanage/api/evaluateAppointmentBill/getEvaluateAppointmentBillEditInit',
method: 'post',
params:params,
});
}


export function getEvaluateAppointmentBillView(params){return request({
url: '/storagemanage/api/evaluateAppointmentBill/getEvaluateAppointmentBillView',
method: 'post',
params:params,
});
}


export function saveEvaluateAppointmentBill(data){return request({
url: '/storagemanage/api/evaluateAppointmentBill/saveEvaluateAppointmentBill',
method: 'post',
data:data,
});
}


export function deleteEvaluateAppointmentBill(params){return request({
url: '/storagemanage/api/evaluateAppointmentBill/deleteEvaluateAppointmentBill',
method: 'post',
params:params,
});
}

export function getEvaluateBillByAppointmentId(params){return request({
	url: '/storagemanage/api/evaluateAppointmentBill/getEvaluateBillByAppointmentId',
	method: 'post',
	params:params,
	});
	}



