/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-09T14:42:50.43
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentHouseSalesPrice,
	listRentHouseSalesPriceInit,
	listRentHouseSalesPriceByAuthorizations,
	deleteRentHouseSalesPrice
	
} from '@api/rentmanage/RentHouseSalesPrice';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
		//是否编辑状态查看
		edit:true
	},

	actions: {
		listRentHouseSalesPrice({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseSalesPrice(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseSalesPriceByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseSalesPriceByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseSalesPriceInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseSalesPriceInit(state.AdvanceQuery)
					.then(async res => {
						if(state.AdvanceQuery.entity.salesPriceType == "1"){
						   state.CompoundEntitiesDictionary = res;
						   state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						}
						resolve(res); 
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseSalesPrice({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseSalesPrice({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
