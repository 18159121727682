/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:57.232
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listBusiTrackInfoInit(data){return request({
url: '/lcf/api/busiTrackInfo/listBusiTrackInfoInit',
method: 'post',
data:data,
});
}


export function listBusiTrackInfoByAuthorizations(data){return request({
url: '/lcf/api/busiTrackInfo/listBusiTrackInfoByAuthorizations',
method: 'post',
data:data,
});
}


export function listBusiTrackInfo(data){return request({
url: '/lcf/api/busiTrackInfo/listBusiTrackInfo',
method: 'post',
data:data,
});
}


export function getBusiTrackInfoCreateInit(){return request({
url: '/lcf/api/busiTrackInfo/getBusiTrackInfoCreateInit',
method: 'post',
});
}


export function getBusiTrackInfoEditInit(params){return request({
url: '/lcf/api/busiTrackInfo/getBusiTrackInfoEditInit',
method: 'post',
params:params,
});
}


export function getBusiTrackInfoView(params){return request({
url: '/lcf/api/busiTrackInfo/getBusiTrackInfoView',
method: 'post',
params:params,
});
}


export function saveBusiTrackInfo(data){return request({
url: '/lcf/api/busiTrackInfo/saveBusiTrackInfo',
method: 'post',
data:data,
});
}


export function deleteBusiTrackInfo(params){return request({
url: '/lcf/api/busiTrackInfo/deleteBusiTrackInfo',
method: 'post',
params:params,
});
}


