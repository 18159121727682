import request from '@/plugins/request';

export function listHourseInfoInit(data){return request({
url: '/storagemanage/api/hourseInfo/listHourseInfoInit',
method: 'post',
data:data,
});
}


export function listHourseInfoByAuthorizations(data){return request({
url: '/storagemanage/api/hourseInfo/listHourseInfoByAuthorizations',
method: 'post',
data:data,
});
}


export function listHourseInfo(data){return request({
url: '/storagemanage/api/hourseInfo/listHourseInfo',
method: 'post',
data:data,
});
}


export function getHourseInfoCreateInit(){return request({
url: '/storagemanage/api/hourseInfo/getHourseInfoCreateInit',
method: 'post',
});
}


export function getHourseInfoEditInit(params){return request({
url: '/storagemanage/api/hourseInfo/getHourseInfoEditInit',
method: 'post',
params:params,
});
}


export function getHourseInfoView(params){return request({
url: '/storagemanage/api/hourseInfo/getHourseInfoView',
method: 'post',
params:params,
});
}


export function saveHourseInfo(data){return request({
url: '/storagemanage/api/hourseInfo/saveHourseInfo',
method: 'post',
data:data,
});
}


export function deleteHourseInfo(params){return request({
url: '/storagemanage/api/hourseInfo/deleteHourseInfo',
method: 'post',
params:params,
});
}


