/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-28T14:19:44.603
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveFinancialCollectionDetail,
	getFinancialCollectionDetailCreateInit,
	getFinancialCollectionDetailEditInit,
	getFinancialCollectionDetailView,
	deleteFinancialCollectionDetail
} from '@api/financial/FinancialCollectionDetail';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialCollectionDetailCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionDetailCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialCollectionDetailEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionDetailEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialCollectionDetail({}, FinancialCollectionDetail) {
			return new Promise((resolve, reject) => {
				saveFinancialCollectionDetail(FinancialCollectionDetail)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialCollectionDetailView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionDetailView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialCollectionDetail({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialCollectionDetail({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
