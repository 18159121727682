/**
 * @version GENERATOR_VERSION::20200422
 * @DateTime 2020-04-28T10:23:25.45
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listBuildingInit(data) {
	return request({
		url: '/storagemanage/api/building/listBuildingInit',
		method: 'post',
		data: data,
	});
}


export function listBuildingByAuthorizations(data) {
	return request({
		url: '/storagemanage/api/building/listBuildingByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listBuilding(data) {
	return request({
		url: '/storagemanage/api/building/listBuilding',
		method: 'post',
		data: data,
	});
}


export function getBuildingCreateInit() {
	return request({
		url: '/storagemanage/api/building/getBuildingCreateInit',
		method: 'post',
	});
}


export function getBuildingEditInit(params) {
	return request({
		url: '/storagemanage/api/building/getBuildingEditInit',
		method: 'post',
		params: params,
	});
}


export function getBuildingView(params) {
	return request({
		url: '/storagemanage/api/building/getBuildingView',
		method: 'post',
		params: params,
	});
}


export function saveBuilding(data) {
	return request({
		url: '/storagemanage/api/building/saveBuilding',
		method: 'post',
		data: data,
	});
}


export function deleteBuilding(params) {
	return request({
		url: '/storagemanage/api/building/deleteBuilding',
		method: 'post',
		params: params,
	});
}

export function getStreet(data) {
	return request({
		url: '/storagemanage/api/area/listArea',
		method: 'post',
		data: data,
	});
}