/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-26T14:31:04.553
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listReportFinancialPaymentReminder,
	listReportFinancialPaymentReminderInit,
	listReportFinancialPaymentReminderByAuthorizations,
	deleteReportFinancialPaymentReminder
	
} from '@api/report/ReportFinancialPaymentReminder';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listReportFinancialPaymentReminder({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialPaymentReminder(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportFinancialPaymentReminderByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialPaymentReminderByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportFinancialPaymentReminderInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialPaymentReminderInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteReportFinancialPaymentReminder({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportFinancialPaymentReminder({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
