/**
 * 注册、登录、注销
 * */
import util from '@/libs/util';
import router from '@/router';
import dayjs from 'dayjs';
import {
	GetDataList,
	pageInitReq,
	AddOrEditData,
	editPageInitReq,
	getApply,
	AddReply,
	topReq,
	deleteReq,
	upReq,
	getSimpleDataReq,
	GetDataListTop
} from '@api/smallapplication/publishAdvice';
import {
	Message,
	Notice
} from 'view-design';

import {
	Modal
} from 'view-design';

export default {
	namespaced: true,
	state: {
		//初始化请求参数
		initReqParams: {
			"entity": {
				"billCode": "",
				"billCreateTime": "",
				"billCreaterId": "",
				"billCreaterName": "",
				"billId": "",
				"billTitle": "",
				"billUrl": "",
				"bizType": "",
				"deleteState": 0,
				"deptCode": "",
				"deptName": "",
				"descript": "",
				"employeePost": "",
				"lastUpdateDate": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"saasCompany": "",
				"sugContent": "",
				"sugTitle": "",
				"sugType": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"fuzzyMatching": ""
			},
			"pageIndex": 0,
			"pageSize": 0,
			"selectColumns": []
		},
		// 初始化信息
		initInfo: {
			configItems: {
				suggestType: [],
			}
		},
		editInitInfo: {
			configItems: {
				suggestType: [],
			}
		},
		//查询条件
		reqParams: {
			"entity": {
				"billCode": "",
				"billCreateTime": "",
				"billCreaterId": "",
				"billCreaterName": "",
				"billId": "",
				"billTitle": "",
				"billUrl": "",
				"bizType": "",
				"deleteState": 0,
				"deptCode": "",
				"deptName": "",
				"descript": "",
				"employeePost": "",
				"lastUpdateDate": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"saasCompany": "",
				"sugContent": "",
				"sugTitle": "",
				"sugType": ""
			},
			"outerWhereSubjoin": {
				fuzzyMatching: "",
			},
			'orderColumn':'billCreateTime',
			'isOrder': true,
			'isOrderAsc': false,
			"isPage": true,
			"pageIndex": 1,
			"pageSize": 10,
		},
		
		reqParamsTop: {
			"entity": {
				"billCode": "",
				"billCreateTime": "",
				"billCreaterId": "",
				"billCreaterName": "",
				"billId": "",
				"billTitle": "",
				"billUrl": "",
				"bizType": "",
				"deleteState": 0,
				"deptCode": "",
				"deptName": "",
				"descript": "",
				"employeePost": "",
				"lastUpdateDate": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"saasCompany": "",
				"sugContent": "",
				"sugTitle": "",
				"sugType": ""
			},
			"outerWhereSubjoin": {
				fuzzyMatching: "",
			},
			"isPage": false,
			"pageIndex": 1,
			"pageSize": 1000,
		},
		//查询接口返回的数据
		listDataObj: {
			entityResponse: {
				totalKeys: [],
			},
		},

		//新增参数
		addData: {
			"billCode": "",
			"billCreateTime": "",
			"billCreaterId": "",
			"billCreaterName": "",
			"billId": "",
			"billTitle": "",
			"billUrl": "",
			"bizType": "",
			"deleteState": 0,
			"deptCode": "",
			"deptName": "",
			"descript": "",
			"employeePost": "",
			"lastUpdateDate": "",
			"operatorId": "",
			"operatorLongName": "",
			"operatorName": "",
			"operatorTime": "",
			"saasCompany": "",
			"sugContent": "",
			"sugTitle": "",
			"sugType": "1"
		},

		//编辑参数
		editData: {
			"billCode": "",
			"billCreateTime": "",
			"billCreaterId": "",
			"billCreaterName": "",
			"billId": "",
			"billTitle": "",
			"billUrl": "",
			"bizType": "",
			"deleteState": 0,
			"deptCode": "",
			"deptName": "",
			"descript": "",
			"employeePost": "",
			"lastUpdateDate": "",
			"operatorId": "",
			"operatorLongName": "",
			"operatorName": "",
			"operatorTime": "",
			"saasCompany": "",
			"sugContent": "",
			"sugTitle": "",
			"sugType": ""
		},

		authRole: '', //角色: normal:普通员工  admin:管理员
		authority: false,
		enterAuth: true,
	},
	actions: {
		/**
		 * @description 登录
		 * @param {Object} param context
		 * @param {Object} param userCode {String} 用户账号
		 * @param {Object} param password {String} 密码
		 * @param {Object} param route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
		 */
		getList({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				GetDataList(state.reqParams)
					.then(async res => {
						// 结束
						state.listDataObj = res
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
		
		getListTop({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				GetDataListTop(state.reqParamsTop)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		pageInit({
			state,
			dispatch
		}, info) {
			return new Promise((resolve, reject) => {
				pageInitReq(state.initReqParams)
					.then(async res => {
						state.initInfo = res
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		detailPageInit({
			state,
			dispatch
		}, info) {
			return new Promise((resolve, reject) => {
				addPageInitReq({})
					.then(async res => {
						state.addData.billCreaterId = res.entityResponse.billCreaterId; //创建人id
						state.addData.billCreaterName = res.entityResponse.billCreaterName; //创建人姓名
						state.addData.deptName = res.entityResponse.deptName; //部门名称
						state.addData.deptCode = res.entityResponse.deptCode;
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		editPageInit({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				editPageInitReq({
						id: id
					})
					.then(async res => {
						state.editInitInfo = res
						state.editData = res.entityResponse
						// 结束
						resolve(state.editData);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getApplyReq({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				getApply({
						id: id
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
		
		getSimpleData({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				getSimpleDataReq({
						id: id
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		submitData({
			state,
			dispatch
		}, info) {
			return new Promise((resolve, reject) => {
				AddOrEditData(state.addData)
					.then(async res => {
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		submitReply({
			state,
			dispatch
		}, {
			reply,
			id,
			userId,
		}) {
			return new Promise((resolve, reject) => {
				AddReply({
						reply,
						id,
						userId,
					})
					.then(async res => {
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
		
		up({
			state,
			dispatch
		}, {
			id,
			type,
		}) {
			return new Promise((resolve, reject) => {
				upReq({
						id,
						type,
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
		
		top({
			state,
			dispatch
		}, {
			id,
			type,
		}) {
			return new Promise((resolve, reject) => {
				topReq({
						id,
						type,
					})
					.then(async res => {
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
		
		deleteItem({
			state,
			dispatch
		}, {
			id,
		}) {
			return new Promise((resolve, reject) => {
				deleteReq({
						id,
					})
					.then(async res => {
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		submitDataEdit({
			state,
			dispatch
		}, info) {
			state.editData.outTime = dayjs(state.editData.outTime).format('YYYY-MM-DD HH:mm:ss')
			state.editData.inTime = dayjs(state.editData.inTime).format('YYYY-MM-DD HH:mm:ss')
			// Message.error({
			// 	content: 'outTime:'+state.editData.outTime+'inTime:'+state.editData.inTime,
			// 	duration: 3000
			// });
			return new Promise((resolve, reject) => {
				AddOrEditData(state.editData)
					.then(async res => {
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
	}
};
