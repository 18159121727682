<template>
  <!-- 选择房源(楼盘字典) -->
  <div>
    <component title="选择人员" :is="component" v-bind="props" v-model="visible">
      <orgusermanagelistF7 ref="orgusermanagelistF7Ref"
                           v-if="isAllEmployeeWin" />
      <div v-if="notIsAllEmployeeWin">
        <vxe-table resize auto-resize highlight-hover-row
                   ref="table"
                   row-id="userId" max-height="400"
                   v-bind="tableProps"
                   :data="list">
          <vxe-column type="radio" align="center" width="60" v-if="selectType === 'radio'" />
          <vxe-column type="checkbox" align="center" width="60" v-else-if="selectType === 'checkbox'" />
          <vxe-column field="userId" title="用户ID" />
          <vxe-column field="userName" title="姓名" />
          <vxe-column field="mainDeptName" title="部门" />
        </vxe-table>
      </div>
      <template slot="footer">
        <Row :style="isMobile ? {flex: 1} : {}" justify="end" :gutter="10">
          <Col v-bind="isMobile ? {span: 12} : {}">
            <Button :long="isMobile"
                    @click="visible = false">取消</Button>
          </Col>
          <Col v-bind="isMobile ? {span: 12} : {}">
            <Button type="primary"
                    :long="isMobile"
                    @click="confirm">确认</Button>
          </Col>
        </Row>
      </template>
    </component>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { head } from 'lodash'
  import AutoCompleteCommon from '@/pages/storagemanage/components/autoComplete'
  import orgusermanagelistF7 from '@/pages/systemmanage/orgusermanage/indexF7'

  export default {
    name: 'SelectUser',
    components: {
      AutoCompleteCommon,
      orgusermanagelistF7
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
      props () {
        if (this.isMobile) return {destroyOnClose: true}
        return {width: 720}
      },
      component () {
        if (this.isMobile) return 'JFDrawer'
        return 'Modal'
      },
      tableProps () {
        const config = {
          trigger: 'row',
          highlight: true,
          checkField: 'checked',
        }

        if (this.selectType === 'radio') return {radioConfig: {...config, strict: false}}
        return {checkboxConfig: {...config, reserve: true}}
      },
    },
    methods: {
      init (workflowUserEnum, data, type = 'radio', selectDataName, workitemId) {
        this.workflowUserEnum = workflowUserEnum
        //如果是：ALL_EMPLOYEE，则自己去后台查询所有用户数据回来
        if (workflowUserEnum == 'ALL_EMPLOYEE') {
          this.isAllEmployeeWin = true
          this.notIsAllEmployeeWin = false
        } else {
          this.isAllEmployeeWin = false
        }
        //type  设置单选多选  radio单选  checkbox多选   selectDataName:回传设置的数据字段名
        this.selectType = type
        this.visible = true
        this.selectDataName = selectDataName
        this.workitemId = workitemId

        // 判断是否有选中的数据
        if (data.length === 1) {
          const item = head(data)

          this.selectedData.push(item)
          this.$emit(
            'setData',
            this.selectedData,
            this.selectDataName,
            this.workitemId
          )
        }
        const selectedNames = this.selectedData.map(item => item.userId)

        this.list = data.map(item => ({
          ...item,
          checked: selectedNames.includes(item.userId)
        }))
      },
      confirm () {
        if (this.workflowUserEnum === 'ALL_EMPLOYEE') {
          const orgUser = this.$refs.orgusermanagelistF7Ref.getSelectData()

          this.selectedData = [
            {
              mainDeptId: orgUser[0].mainDeptId,
              mainDeptName: orgUser[0].mainDeptName,
              sassCompany: orgUser[0].sassCompany,
              userCode: orgUser[0].userCode,
              userId: orgUser[0].userId,
              userLongName: orgUser[0].userLongName,
              userName: orgUser[0].userName,
              checked: false,
            }
          ]
        } else {
          //确定
          const selectedData = this.getSelectedData()

          if (!selectedData.length) return this.$Message.warning('请选择人员!')
          this.selectedData = selectedData
        }
        this.$emit(
          'setData',
          this.selectedData,
          this.selectDataName,
          this.workitemId
        )
        this.visible = false
      },
      getSelectedData () {
        if (this.selectType === 'checkbox') return this.$refs.table.getCheckboxRecords()
        return [this.$refs.table.getRadioRecord()]
      },
    },
    data () {
      return {
        workitemId: '',
        workflowUserEnum: '', //人员查询方式
        notIsAllEmployeeWin: true,
        isAllEmployeeWin: false,
        visible: false,
        //房源列表数据
        list: [],
        selectDataName: '',
        selectType: '', //radio:单选  checkbox:多选
        //已选数据
        selectedData: [],
      }
    },
  }
</script>
