/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-07T10:19:46.092
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listCCBSyncLogInit(data){return request({
url: '/house/api/cCBSyncLog/listCCBSyncLogInit',
method: 'post',
data:data,
});
}


export function listCCBSyncLogByAuthorizations(data){return request({
url: '/house/api/cCBSyncLog/listCCBSyncLogByAuthorizations',
method: 'post',
data:data,
});
}


export function listCCBSyncLog(data){return request({
url: '/house/api/cCBSyncLog/listCCBSyncLog',
method: 'post',
data:data,
});
}


export function getCCBSyncLogCreateInit(){return request({
url: '/house/api/cCBSyncLog/getCCBSyncLogCreateInit',
method: 'post',
});
}


export function getCCBSyncLogEditInit(params){return request({
url: '/house/api/cCBSyncLog/getCCBSyncLogEditInit',
method: 'post',
params:params,
});
}


export function getCCBSyncLogView(params){return request({
url: '/house/api/cCBSyncLog/getCCBSyncLogView',
method: 'post',
params:params,
});
}


export function saveCCBSyncLog(data){return request({
url: '/house/api/cCBSyncLog/saveCCBSyncLog',
method: 'post',
data:data,
});
}


export function deleteCCBSyncLog(params){return request({
url: '/house/api/cCBSyncLog/deleteCCBSyncLog',
method: 'post',
params:params,
});
}


