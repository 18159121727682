/**
 * 第一步：mapActions第一个参数，指向到store-->第二步：编写store数据结构-->第三步：引用API接口，调用后台，取得数据-->第四步：绑定数据到store数据结构中
 * */
import util from '@/libs/util';
import router from '@/router';
import dayjs from 'dayjs';
import {
	GetDataList
} from '@api/storagemanage/houseSaveDelegationCommissionerApi';
import {
	Message,
	Notice
} from 'view-design';

import {
	Modal
} from 'view-design';

export default {
	namespaced: true,
	state: {
		// 初始化信息
		initInfo: {
			distributeStatusItems: {
				 
			}
		},
		//查询条件
		reqParams: {
			houseNo:'',
			houseAddress:'',
			billCode: '',
			billStartDate: '',
			billEndDate: '',
			owneName:'',
			distributeStatus:'',
			tableName:'',
			isPage: true,
			pageIndex: 1,
			pageSize: 10,
		},
		//查询接口返回的数据
		listDataObj: {
			entityResponse: {
				totalKeys: [],
			},
		},

		//编辑参数
		editData: {
		},
	},
	actions: { 
		getList({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				GetDataList(state.reqParams)
					.then(async res => {
						// 结束
						state.listDataObj = res
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		}
	}
};
