/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-03T09:53:25.113
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentRepairWorkOrder,
	getRentRepairWorkOrderCreateInit,
	getRentRepairWorkOrderEditInit,
	getRentRepairWorkOrderView,
	deleteRentRepairWorkOrder,
	startWorkflow,
	listRentRepairWorkOrderingByHouseCode
} from '@api/rentmanage/RentRepairWorkOrder';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentRepairWorkOrderDetailsInit,
listRentRepairWorkOrderDetailsByAuthorizations,
deleteRentRepairWorkOrderDetails

} from '@api/rentmanage/RentRepairWorkOrderDetails';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
		billId:"",
	},

	actions: {
		getRentRepairWorkOrderCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairWorkOrderCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		startWorkflow({}, RentRepairWorkOrder) {
			return new Promise((resolve, reject) => {
				startWorkflow(RentRepairWorkOrder)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentRepairWorkOrderEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairWorkOrderEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentRepairWorkOrder({}, RentRepairWorkOrder) {
			return new Promise((resolve, reject) => {
				saveRentRepairWorkOrder(RentRepairWorkOrder)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentRepairWorkOrderingByHouseCode({
			state
		},data) { 
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderingByHouseCode(data)
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentRepairWorkOrderView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairWorkOrderView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentRepairWorkOrder({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentRepairWorkOrder({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
