/**
 * @version GENERATOR_VERSION::20200422
 * @DateTime 2020-05-07T09:38:27.8
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listCCBContractInit(data) {
	return request({
		url: '/house/api/cCBContract/listCCBContractInit',
		method: 'post',
		data: data,
	});
}


export function listCCBContractByAuthorizations(data) {
	return request({
		url: '/house/api/cCBContract/listCCBContractByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listCCBContract(data) {
	return request({
		url: '/house/api/cCBContract/listCCBContract',
		method: 'post',
		data: data,
	});
}


export function getCCBContractCreateInit() {
	return request({
		url: '/house/api/cCBContract/getCCBContractCreateInit',
		method: 'post',
	});
}


export function getCCBContractEditInit(params) {
	return request({
		url: '/house/api/cCBContract/getCCBContractEditInit',
		method: 'post',
		params: params,
	});
}


export function getCCBContractView(params) {
	return request({
		url: '/house/api/cCBContract/getCCBContractView',
		method: 'post',
		params: params,
	});
}


export function saveCCBContract(data) {
	return request({
		url: '/house/api/cCBContract/saveCCBContract',
		method: 'post',
		data: data,
	});
}


export function deleteCCBContract(params) {
	return request({
		url: '/house/api/cCBContract/deleteCCBContract',
		method: 'post',
		params: params,
	});
}

export function syncCCBContract(params) {
	return request({
		url: '/house/api/cCBContract/syncCCBContract',
		method: 'post',
		params: params,
	});
}

export function closeCCBContract(params) {
	return request({
		url: '/house/api/cCBContract/closeCCBContract',
		method: 'post',
		params: params,
	});
}


