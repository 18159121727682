/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-05-28 17:45:44
 * @LastEditors: co
 * @LastEditTime: 2021-06-24 10:44:17
 */
/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-25T11:06:36.041
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listReportRentContractUndue,
	listReportRentContractUndueInit,
	listReportRentContractUndueByAuthorizations,
	deleteReportRentContractUndue

} from '@api/report/ReportRentContractUndue';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listReportRentContractUndue({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportRentContractUndue(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportRentContractUndueByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportRentContractUndueByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities = res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportRentContractUndueInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportRentContractUndueInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteReportRentContractUndue({
			state
		}, { id }) {
			return new Promise((resolve, reject) => {
				deleteReportRentContractUndue({
					id
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
