/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-02T18:37:11.407
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveInvoiceToolsInvoice,
	getInvoiceToolsInvoiceCreateInit,
	getInvoiceToolsInvoiceEditInit,
	getInvoiceToolsInvoiceView,
	deleteInvoiceToolsInvoice
} from '@api/smallapplication/InvoiceToolsInvoice';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getInvoiceToolsInvoiceCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getInvoiceToolsInvoiceCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getInvoiceToolsInvoiceEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getInvoiceToolsInvoiceEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveInvoiceToolsInvoice({}, InvoiceToolsInvoice) {
			return new Promise((resolve, reject) => {
				saveInvoiceToolsInvoice(InvoiceToolsInvoice)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getInvoiceToolsInvoiceView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getInvoiceToolsInvoiceView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteInvoiceToolsInvoice({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteInvoiceToolsInvoice({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
