/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-08T17:39:58.085
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import util from '@/libs/util';
import router from '@/router';
import {
	saveContractSubject,
	getContractSubjectCreateInit,
	getContractSubjectEditInit,
	getContractSubjectView,
	deleteContractSubject,

	getContractSubjectAggregateRootItemCreateInit, //变更 新增
	getContractSubjectAggregateRootItemEditInit, //变更 新增
	saveContractSubjectAggregateRootItem, //变更 新增
	deleteContractSubjectAcount, //变更 新增

} from '@api/storagemanage/ARContractSubject';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		//变更 新增
		getContractSubjectAggregateRootItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getContractSubjectAggregateRootItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		//变更 新增
		getContractSubjectAggregateRootItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getContractSubjectAggregateRootItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		getContractSubjectCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getContractSubjectCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getContractSubjectEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getContractSubjectEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveContractSubject({}, ContractSubject) {
			return new Promise((resolve, reject) => {
				saveContractSubject(ContractSubject)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		//变更 新增 参数不一样
		saveContractSubjectAggregateRootItem({}, data) {
			return new Promise((resolve, reject) => {
				saveContractSubjectAggregateRootItem(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getContractSubjectView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getContractSubjectView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteContractSubject({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				deleteContractSubject({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteContractSubjectAcount({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				deleteContractSubjectAcount({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
