/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-24T09:40:19.554
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listKhmDataChangesApplyInit(data){return request({
url: '/rentmanage/api/khmDataChangesApply/listKhmDataChangesApplyInit',
method: 'post',
data:data,
});
}


export function listKhmDataChangesApplyByAuthorizations(data){return request({
url: '/rentmanage/api/khmDataChangesApply/listKhmDataChangesApplyByAuthorizations',
method: 'post',
data:data,
});
}


export function listKhmDataChangesApply(data){return request({
url: '/rentmanage/api/khmDataChangesApply/listKhmDataChangesApply',
method: 'post',
data:data,
});
}


export function getKhmDataChangesApplyCreateInit(){return request({
url: '/rentmanage/api/khmDataChangesApply/getKhmDataChangesApplyCreateInit',
method: 'post',
});
}


export function getKhmDataChangesApplyEditInit(params){return request({
url: '/rentmanage/api/khmDataChangesApply/getKhmDataChangesApplyEditInit',
method: 'post',
params:params,
});
}


export function getKhmDataChangesApplyView(params){return request({
url: '/rentmanage/api/khmDataChangesApply/getKhmDataChangesApplyView',
method: 'post',
params:params,
});
}


export function saveKhmDataChangesApply(data){return request({
url: '/rentmanage/api/khmDataChangesApply/saveKhmDataChangesApply',
method: 'post',
data:data,
});
}


export function deleteKhmDataChangesApply(params){return request({
url: '/rentmanage/api/khmDataChangesApply/deleteKhmDataChangesApply',
method: 'post',
params:params,
});
}

export function startWorkflow(data){return request({
	url: '/rentmanage/api/khmDataChangesApply/startWorkflow',
	method: 'post',
	data:data,
	});
}
