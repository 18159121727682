/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-26T09:20:14.883
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHousePutaway,
	getRentHousePutawayCreateInit,
	getRentHousePutawayEditInit,
	batchSaveRentHousePutawayItem,
	checkRentHousePutawayHouseRentInfo,
	getRentHousePutawayView,
	deleteRentHousePutaway,
	startWorkflow,
	reissueRentHousePutaway
} from '@api/rentmanage/RentHousePutaway';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
	listRentHousePutawayItemInit,
	listRentHousePutawayItemByAuthorizations,
	deleteRentHousePutawayItem
} from '@api/rentmanage/RentHousePutawayItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentHousePutawayCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHousePutawayCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		checkRentHousePutawayHouseRentInfo({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				checkRentHousePutawayHouseRentInfo({
					houseRentId: obj.houseRentId,
					putawayChannels: obj.putawayChannels
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		batchSaveRentHousePutawayItem({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				batchSaveRentHousePutawayItem(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHousePutawayEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHousePutawayEditInit({
					id
				})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		startWorkflow({ }, RentHousePutaway) {
			return new Promise((resolve, reject) => {
				startWorkflow(RentHousePutaway)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		reissueRentHousePutaway({ }, RentHousePutaway) {
			return new Promise((resolve, reject) => {
				reissueRentHousePutaway(RentHousePutaway)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		saveRentHousePutaway({ }, RentHousePutaway) {
			return new Promise((resolve, reject) => {
				saveRentHousePutaway(RentHousePutaway)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHousePutawayView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHousePutawayView({
					id
				})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHousePutaway({
			state
		}, { id }) {
			return new Promise((resolve, reject) => {
				deleteRentHousePutaway({
					id
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
