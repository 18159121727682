/*
 * @Descripttion:
 * @version:
 * @Author: co
 * @Date: 2021-05-19 14:45:12
 * @LastEditors: co
 * @LastEditTime: 2021-05-19 15:34:17
 * @FilePath: \giant_find\iview-admin-pro-template\src\api\account.js
 */
import request from '@/plugins/request';

export function AccountLogin(data) {
    return request({
        url: '/h5platform/api/register/tokenLoginByUserMobileVerificationCode',
        method: 'post',
        params: data,
    });
}

export function loginByAccount(data) {
    return request({
        url: '/h5platform/api/register/tokenLoginByUserCodePassword',
        method: 'post',
        params: data,
    });
}

export function AccountLoginWithOpenId(data) {
    return request({
        url: '/h5platform/api/register/tokenLoginByUserMobileVerificationCodeBindWechat',
        method: 'post',
        params: data
    });
}

export function loginWithOpenIdAccount(data) {
    return request({
        url: '/h5platform/api/register/tokenLoginByUserCodePasswordBindWechat',
        method: 'post',
        params: data
    });
}

export function AccountRegister(data) {
    return request({
        url: '/api/register',
        method: 'post',
        data
    });
}

export function getUserInfoByCodeReq(data) {
    return request({
        url: '/h5platform/api/register/firstLoginByWeixinCode',
        method: 'post',
        params: data
    });
}

export function getUserInfoByQYWXCodeReq(data) {
    return request({
        url: '/h5platform/api/register/tokenLoginByEnterpriseWechatCode',
        method: 'post',
        params: data
    });
}

export function getValidCodeReq(data) {
    return request({
        url: '/h5platform/api/register/sendSMSVerificationCode',
        method: 'post',
        params: data
    });
}

export function logoutReq(data) {
    return request({
        url: '/h5platform/api/register/logout',
        method: 'post',
        params: data
    });
}

export function verifyGoogleDynamicCode(data) {
    return request({
        url: '/h5platform/api/register/verifyGoogleDynamicCode',
        method: 'post',
        params: data
    });
}

export function bindGoogleSecretSMSVerificationCode(data) {
    return request({
        url: '/h5platform/api/register/bindGoogleSecretSMSVerificationCode',
        method: 'post',
        params: data
    });
}

// 自动登录
export const autoLogin = params => request.post('/h5platform/api/register/loginByTokenAndUserMobile', {}, {params})

export const queryUserInfoByUserCode = params => request.get('/h5platform/api/register/queryUserInfoByUserCode', {params})
