/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T08:26:50.677
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseSalesPromotionInit(data){return request({
url: '/rentmanage/api/rentHouseSalesPromotion/listRentHouseSalesPromotionInit',
method: 'post',
data:data,
});
}


export function listRentHouseSalesPromotionByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseSalesPromotion/listRentHouseSalesPromotionByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseSalesPromotion(data){return request({
url: '/rentmanage/api/rentHouseSalesPromotion/listRentHouseSalesPromotion',
method: 'post',
data:data,
});
}


export function getRentHouseSalesPromotionCreateInit(){return request({
url: '/rentmanage/api/rentHouseSalesPromotion/getRentHouseSalesPromotionCreateInit',
method: 'post',
});
}


export function getRentHouseSalesPromotionEditInit(params){return request({
url: '/rentmanage/api/rentHouseSalesPromotion/getRentHouseSalesPromotionEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseSalesPromotionView(params){return request({
url: '/rentmanage/api/rentHouseSalesPromotion/getRentHouseSalesPromotionView',
method: 'post',
params:params,
});
}


export function saveRentHouseSalesPromotion(data){return request({
url: '/rentmanage/api/rentHouseSalesPromotion/saveRentHouseSalesPromotion',
method: 'post',
data:data,
});
}


export function deleteRentHouseSalesPromotion(params){return request({
url: '/rentmanage/api/rentHouseSalesPromotion/deleteRentHouseSalesPromotion',
method: 'post',
params:params,
});
}

export function startWorkflow(data) {
	return request({
		url: '/rentmanage/api/rentHouseSalesPromotion/startWorkflow',
		method: 'post',
		data: data,
	});
}
