/**
 * @version GENERATOR_VERSION::20200422
 * @DateTime 2020-04-25T22:13:39.997
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: true
};

const pre = 'storagemanage-hourseentrust-'

export default {
    path: '/storagemanage/hourseentrust',
    name: 'storagemanage-hourseentrust',
    redirect: {
        name: '${pre}-index'
    },

    meta: {
        ...meta,
    },

    component: BasicLayout,
    children: [{
            path: 'index',
            name: '${pre}hourseentrust-index',

            meta: {
                ...meta,
                title: '房源信息表'
            },

            component: () => import('@/pages/storagemanage/hourseentrust/index'),
        },
        {
            path: 'detail-edit/:id',
            name: '${pre}hourseentrust-detail-edit',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '房源信息表编辑'
            },

            component: () => import('@/pages/storagemanage/hourseentrust/detail-edit'),
        },
        {
            path: 'detail-edit',
            name: '${pre}hourseentrustdetail-create',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '房源信息表新增'
            },

            component: () => import('@/pages/storagemanage/hourseentrust/detail-edit'),
        },
        {
            path: 'detail-view/:id',
            name: '${pre}hourseentrustView',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '房源信息表详情查看'
            },

            component: () => import('@/pages/storagemanage/hourseentrust/detail-view'),
        },
    ]
};
