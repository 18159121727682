/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T11:12:57.639
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-channel-'

export default 
{
path: '/rentmanage/channel',
name: 'rentmanage-channel',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}channel-index',

meta:{
...meta,
title: '渠道管理'
},

component: () => import('@/pages/rentmanage/channel/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}channel-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '渠道管理编辑'
},

component: () => import('@/pages/rentmanage/channel/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}channeldetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '渠道管理新增'
},

component: () => import('@/pages/rentmanage/channel/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}channelView',
auth: [
'hidden'
],

meta:{
...meta,
title: '渠道管理详情查看'
},

component: () => import('@/pages/rentmanage/channel/detail-view') ,
},
]
};
