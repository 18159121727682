/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-01T14:27:19.571
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentTagInit(data){return request({
url: '/rentmanage/api/rentTag/listRentTagInit',
method: 'post',
data:data,
});
}


export function listRentTagByAuthorizations(data){return request({
url: '/rentmanage/api/rentTag/listRentTagByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentTag(data){return request({
url: '/rentmanage/api/rentTag/listRentTag',
method: 'post',
data:data,
});
}


export function getRentTagCreateInit(){return request({
url: '/rentmanage/api/rentTag/getRentTagCreateInit',
method: 'post',
});
}


export function getRentTagEditInit(params){return request({
url: '/rentmanage/api/rentTag/getRentTagEditInit',
method: 'post',
params:params,
});
}


export function getRentTagView(params){return request({
url: '/rentmanage/api/rentTag/getRentTagView',
method: 'post',
params:params,
});
}


export function saveRentTag(data){return request({
url: '/rentmanage/api/rentTag/saveRentTag',
method: 'post',
data:data,
});
}


export function deleteRentTag(params){return request({
url: '/rentmanage/api/rentTag/deleteRentTag',
method: 'post',
params:params,
});
}


