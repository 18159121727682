<template>
  <div>
    <Form ref="form" :model="formObj" :rules="rules" label-position="top">
      <Row :gutter="16">
        <Col :xl="8" :lg="12" :md="24" :sm="24" :xs="24">
          <FormItem label="配置交付开始日期：" prop="lastReformDeliveryStartDate">
            <DatePicker
              format="yyyy-MM-dd"
              @on-change="transformTimeLastReformDeliveryStartDate"
              v-model="formObj.lastReformDeliveryStartDate"
              type="date"
              placeholder="请选择配置交付开始日期"
              v-width="'100%'"
            />
          </FormItem>
        </Col>

        <Col :xl="8" :lg="12" :md="24" :sm="24" :xs="24">
          <FormItem label="配置交付日期：" prop="lastReformDeliveryDate">
            <DatePicker
              format="yyyy-MM-dd"
              @on-change="transformTimeLastReformDeliveryDate"
              v-model="formObj.lastReformDeliveryDate"
              type="date"
              placeholder="请选择配置交付日期"
              v-width="'100%'"
            />
          </FormItem>
        </Col>

        <Col :xl="8" :lg="12" :md="24" :sm="24" :xs="24">
          <FormItem label="改造总成本(元)：" prop="costAmount">
            <Input
              type="text"
              v-model="formObj.costAmount"
              placeholder="请输入改造总成本(元)"
              element-id="costAmount"
            ></Input>
          </FormItem>
        </Col>
      </Row>
    </Form>
  </div>
</template>
<script>
import { Message, Notice } from 'view-design'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'rentpropertydeliveryDialog',
  components: {},
  computed: {
    ...mapState('rentmanage/rentpropertydelivery/process-form', [
      'propertydeliveryProcessObj',
    ]),
  },
  watch: {},
  props: {},
  data() {
    return {
      visible: false,
      loading: false,
      //列表传入的行数据
      initData: null,
      formObj: {
        costAmount: '',
        lastReformDeliveryStartDate: '',
        lastReformDeliveryDate: '',
      },
      rules: {
        costAmount: [
          {
            required: true,
            message: '请输入改造总成本(元)',
            trigger: 'blur',
          },
        ],
        lastReformDeliveryStartDate: [
          {
            required: true,
            message: '请输入配置交付开始日期',
            trigger: 'blur',
            type: 'any',
          },
        ],
        lastReformDeliveryDate: [
          {
            required: true,
            message: '请输入配置交付日期',
            trigger: 'blur',
            type: 'any',
          },
        ],
      },
    }
  },
  mounted() {
    this.formObj = this.propertydeliveryProcessObj
    if (
      this.formObj.lastReformDeliveryStartDate != null &&
      this.formObj.lastReformDeliveryStartDate != ''
    ) {
      this.formObj.lastReformDeliveryStartDate = this.$Date(
        this.formObj.lastReformDeliveryStartDate
      ).format('YYYY-MM-DD')
    }

    if (
      this.formObj.lastReformDeliveryDate != null &&
      this.formObj.lastReformDeliveryDate != ''
    ) {
      this.formObj.lastReformDeliveryDate = this.$Date(
        this.formObj.lastReformDeliveryDate
      ).format('YYYY-MM-DD')
    }

    this.formObj.costAmount =
      this.propertydeliveryProcessObj.costAmount == 0
        ? '0'
        : this.propertydeliveryProcessObj.costAmount.toString()
  },
  methods: {
    getFormData() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          return true
        } else {
          return false
        }
      })
    },
    transformTimeLastReformDeliveryStartDate(val) {
      //所有时间格式参数格式转换
      this.formObj.lastReformDeliveryStartDate = val
    },
    transformTimeLastReformDeliveryDate(val) {
      //所有时间格式参数格式转换
      this.formObj.lastReformDeliveryDate = val
    },
  },
}
</script>
<style lang="less">
</style>