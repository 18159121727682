/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-07-02T16:30:29.157
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getReportFinancialRentContractItemPaymentCollectionMonthEditInit,
	getReportFinancialRentContractItemPaymentCollectionMonthView
} from '@api/report/ReportFinancialRentContractItemPaymentCollectionMonth';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		getReportFinancialRentContractItemPaymentCollectionMonthEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemPaymentCollectionMonthEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportFinancialRentContractItemPaymentCollectionMonthView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemPaymentCollectionMonthView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
