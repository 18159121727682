/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2021-04-23T14:44:26.986
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listRentHouseGoodsInit(data) {
	return request({
		url: '/rentmanage/api/rentHouseGoods/listRentHouseGoodsInit',
		method: 'post',
		data: data,
	});
}


export function listRentHouseGoodsByAuthorizations(data) {
	return request({
		url: '/rentmanage/api/rentHouseGoods/listRentHouseGoodsByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listRentHouseGoods(data) {
	return request({
		url: '/rentmanage/api/rentHouseGoods/listRentHouseGoods',
		method: 'post',
		data: data,
	});
}


export function getRentHouseGoodsCreateInit() {
	return request({
		url: '/rentmanage/api/rentHouseGoods/getRentHouseGoodsCreateInit',
		method: 'post',
	});
}


export function getRentHouseGoodsEditInit(params) {
	return request({
		url: '/rentmanage/api/rentHouseGoods/getRentHouseGoodsEditInit',
		method: 'post',
		params: params,
	});
}


export function getRentHouseGoodsView(params) {
	return request({
		url: '/rentmanage/api/rentHouseGoods/getRentHouseGoodsView',
		method: 'post',
		params: params,
	});
}


export function saveRentHouseGoods(data) {
	return request({
		url: '/rentmanage/api/rentHouseGoods/saveRentHouseGoods',
		method: 'post',
		data: data,
	});
}


export function deleteRentHouseGoods(params) {
	return request({
		url: '/rentmanage/api/rentHouseGoods/deleteRentHouseGoods',
		method: 'post',
		params: params,
	});
}

export function saveRentHouseGoodsList(data) {
	return request({
		url: '/rentmanage/api/rentHouseGoods/saveRentHouseGoodsList',
		method: 'post',
		data: data,
	});
}

