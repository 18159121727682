import BasicLayout from '@/layouts/basic-layout';
////第一步：配置菜单-->第二步：配置上门评估页面相关的路由-->第三步：注入路由-->第四步：做页面
const meta = {
	auth: true
};

const pre = 'flow-';

export default {
	path: '/flow',
	name: '流程中心',
	redirect: {
		name: `${pre}flowCenter-list`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'flowCenter-list',
		name: `${pre}flowCenter-list`,
		meta: {
			...meta,
			title: '流程中心',
			closable: true
		},
		component: () => import('@/pages/storagemanage/flowCenter/index')
	}]
};
