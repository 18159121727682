/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-15T16:02:24.145
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportRentHouseBig,
	getReportRentHouseBigCreateInit,
	getReportRentHouseBigEditInit,
	getReportRentHouseBigView,
	deleteReportRentHouseBig
} from '@api/report/ReportRentHouseBig';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportRentHouseBigCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportRentHouseBigCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportRentHouseBigEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportRentHouseBigEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportRentHouseBig({}, ReportRentHouseBig) {
			return new Promise((resolve, reject) => {
				saveReportRentHouseBig(ReportRentHouseBig)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportRentHouseBigView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportRentHouseBigView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportRentHouseBig({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportRentHouseBig({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
