import request from '@/plugins/request';

export function GetDataList (data) {
    return request({
        url: '/h5platform/api/houseInfo/listHouseInfoInit',
        method: 'post',
		data:data
    });
}

export function GetUser (data) {
    return request({
        url: '/storagemanage/api/managementAreaUser/listManagementMyCompanyAreaUser',
        method: 'post',
		data:data
    });
}
