/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T17:27:30.632
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentPurchaseOrderDetailedInit(data){return request({
url: '/rentmanage/api/rentPurchaseOrderDetailed/listRentPurchaseOrderDetailedInit',
method: 'post',
data:data,
});
}


export function listRentPurchaseOrderDetailedByAuthorizations(data){return request({
url: '/rentmanage/api/rentPurchaseOrderDetailed/listRentPurchaseOrderDetailedByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentPurchaseOrderDetailed(data){return request({
url: '/rentmanage/api/rentPurchaseOrderDetailed/listRentPurchaseOrderDetailed',
method: 'post',
data:data,
});
}


export function getRentPurchaseOrderDetailedCreateInit(){return request({
url: '/rentmanage/api/rentPurchaseOrderDetailed/getRentPurchaseOrderDetailedCreateInit',
method: 'post',
});
}


export function getRentPurchaseOrderDetailedEditInit(params){return request({
url: '/rentmanage/api/rentPurchaseOrderDetailed/getRentPurchaseOrderDetailedEditInit',
method: 'post',
params:params,
});
}


export function getRentPurchaseOrderDetailedView(params){return request({
url: '/rentmanage/api/rentPurchaseOrderDetailed/getRentPurchaseOrderDetailedView',
method: 'post',
params:params,
});
}


export function saveRentPurchaseOrderDetailed(data){return request({
url: '/rentmanage/api/rentPurchaseOrderDetailed/saveRentPurchaseOrderDetailed',
method: 'post',
data:data,
});
}


export function deleteRentPurchaseOrderDetailed(params){return request({
url: '/rentmanage/api/rentPurchaseOrderDetailed/deleteRentPurchaseOrderDetailed',
method: 'post',
params:params,
});
}


