/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-01T19:13:36.232
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listConDeputeContractBackSettlement,
	listConDeputeContractBackSettlementInit,
	listConDeputeContractBackSettlementByAuthorizations,
	deleteConDeputeContractBackSettlement,
	sendReplyByLetter,
	sendReleaseAgreement,
	confirmDelivery
	
} from '@api/storagemanage/ConDeputeContractBackSettlement';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listConDeputeContractBackSettlement({
			state
		}) {
			return new Promise((resolve, reject) => {
				listConDeputeContractBackSettlement(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listConDeputeContractBackSettlementByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listConDeputeContractBackSettlementByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listConDeputeContractBackSettlementInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listConDeputeContractBackSettlementInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteConDeputeContractBackSettlement({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteConDeputeContractBackSettlement({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		sendReplyByLetter({
			state
		}, {settlementId}) {
			return new Promise((resolve, reject) => {
				sendReplyByLetter({
						settlementId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		sendReleaseAgreement({
			state
		}, {settlementId}) {
			return new Promise((resolve, reject) => {
				sendReleaseAgreement({
						settlementId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		confirmDelivery({
			state
		}, {settlementId}) {
			return new Promise((resolve, reject) => {
				confirmDelivery({
						settlementId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
