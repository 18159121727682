/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-10T15:01:42.848
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentHouseOnlineItem,
	getRentHouseOnlineItemCreateInit,
	getRentHouseOnlineItemEditInit,
	getRentHouseOnlineItemView,
	deleteRentHouseOnlineItem
} from '@api/rentmanage/RentHouseOnlineItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentHouseOnlineItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseOnlineItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseOnlineItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseOnlineItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseOnlineItem({}, RentHouseOnlineItem) {
			return new Promise((resolve, reject) => {
				saveRentHouseOnlineItem(RentHouseOnlineItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseOnlineItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseOnlineItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseOnlineItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseOnlineItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
