import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	redirect: {
		name: `${pre}evaluateAppointment-list`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'evaluateAppointment-list',
		name: `${pre}evaluateAppointment-list`,
		meta: {
			...meta,
			title: '预约评估',
			closable: true
		},
		component: () => import('@/pages/storagemanage/evaluateAppointment/index')
	}, {
		path: 'evaluateAppointment-addAppointment',
		name: `${pre}evaluateAppointment-addAppointment`,
		meta: {
			...meta,
			title: '发起预约',
			closable: true
		},
		component: () => import('@/pages/storagemanage/evaluateAppointment/addAppointment')
	},
	{
		path: 'evaluateAppointment-startEvaluate',
		name: `${pre}evaluateAppointment-startEvaluate`,
		meta: {
			...meta,
			title: '发起评估',
			closable: true
		},
		component: () => import('@/pages/storagemanage/evaluateAppointment/startEvaluate')
	},
	{
		path: 'evaluateAppointment-startEvaluateCheck',
		name: `${pre}evaluateAppointment-startEvaluateCheck`,
		meta: {
			...meta,
			title: '查看评估',
			closable: true
		},
		component: () => import('@/pages/storagemanage/evaluateAppointment/startEvaluateCheck')
	},
	]
};
