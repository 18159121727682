/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-25T10:37:42.938
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHouseTypeSales,
	getRentHouseTypeSalesCreateInit,
	getRentHouseTypeSalesEditInit,
	getRentHouseTypeSalesView,
	deleteRentHouseTypeSales
} from '@api/rentmanage/RentHouseTypeSales';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentHouseTypeSalesCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseTypeSalesCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseTypeSalesEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseTypeSalesEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseTypeSales({}, RentHouseTypeSales) {
			return new Promise((resolve, reject) => {
				saveRentHouseTypeSales(RentHouseTypeSales)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentHouseTypeSalesView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseTypeSalesView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentHouseTypeSales({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseTypeSales({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
