const pre = '/khm/';

export default {
	path: '/khm',
	title: '存房管理',
	header: 'home',
	custom: 'i-icon-demo i-icon-demo-list',
	icon: 'md-speedometer',
	children: [{
			path: `#1`,
			title: '房源管理',
			children: [{
					path: `/storagemanage/hourseentrust/index`,
					title: '房源信息-系统',
				},
				{
					path: `/storagemanage/hourseinfo/index`,
					title: '房源信息-签约'
				},
				{
					path: `${pre}building-list`,
					title: '楼盘信息'
				},
				{
					path: `${pre}block-list`,
					title: '楼栋信息'
				},
			]
		},
		{
			path: `/storagemanage/StockpileIntention/index`,//第一步：跳转路径：/khm/houseSaveApplyManagement-list
			title: '存房委托管理'
		},
		{
			path: `/storagemanage/ManagementAreaUser/indexPlatform`,//第一步：跳转路径：
			title: '平台运营岗设置'
		},
		{
			path: `/storagemanage/ManagementAreaUser/indexBusinessOperation`,//第一步：跳转路径：
			title: '商家运营专员'
		},
		{
			path: `/storagemanage/ManagementAreaUser/indexBusinessCheckIn`,//第一步：跳转路径：
			title: '客户经理'
		},
		{
			path: `/storagemanage/ManagementAreaUser/indexChannelOperation`,//第一步：跳转路径：
			title: '评估运营专员'
		},
		{
			path: `/storagemanage/ManagementAreaUser/indexChannelAssessment`,//第一步：跳转路径：
			title: '渠道评估专员设置'
		},
		{
			path: `/storagemanage/ManagementAreaChannel/index`,//第一步：跳转路径：
			title: '区域评估渠道设置'
		},
		{
			path: `/storagemanage/HouseRepeatPlan/index`,//第一步：跳转路径：
			title: '判重设置'
		},
		{
			path: `/storagemanage/evaluateappointmentfinal/index`,//第一步：跳转路径：
			title: '终谈记录'
		},
		{
			path: `/storagemanage/evaluateappointmentkeeprecord/index`,//第一步：跳转路径：
			title: '预约备案反馈记录'
		},
		{
			path: `/storagemanage/HouseDuplicateRemoval/index`,//第一步：跳转路径：
			title: '房源去重管理'
		},
		{
			//第一步：配置菜单-->第二步：配置存房委托指派商家页面相关的路由-->第三步：注入路由-->第四步：做页面
			path: `${pre}houseSaveDelegation-list`,
			title: '存房委托指派商家'
		},

		{
			//第一步：配置菜单-->第二步：配置存房委托指派专员页面相关的路由-->第三步：注入路由-->第四步：做页面
			path: `${pre}houseSaveDelegationCommissioner-list`,
			title: '存房委托指派专员'
		},
		{
			//第一步：配置菜单-->第二步：配置存房委托指派评估页面相关的路由-->第三步：注入路由-->第四步：做页面
			path: `${pre}houseSaveDelegationAssessment-list`,
			title: '存房委托指派评估'
		},
		{
			//第一步：配置菜单-->第二步：配置存房委托渠道指派专员页面相关的路由-->第三步：注入路由-->第四步：做页面
			path: `${pre}houseSaveChannelAssessment-list`,
			title: '存房委托渠道指派专员'
		},
		{
			path: `${pre}evaluateAppointment-list`,
			title: '预约评估'
		},
		//第一步：配置菜单-->第二步：配置上门评估页面相关的路由-->第三步：注入路由-->第四步：做页面
		{
			path: `/storagemanage/evaluatebill/index`,//${pre}doorToDoorEvaluation-list
			title: '上门评估'
		}, {
			path: `${pre}profitReport-list`,
			title: '资产收益值报告'
		},
		{
			path: `${pre}condeputecontract-list`,
			title: '存房签约'
		},
		{
			path: `/storagemanage/contractsubject/index`,
			title: '合同主体管理'
		},
		{
			// path: `${pre}propertydelivery-list`,
            path: `/storagemanage/droompropertydelivery/index`,
			title: '物业交付'
		},
		{
			path: `/storagemanage/financerentapply/index`,
			title: '租金申请'
		},

		{
			path: `/storagemanage/financepayapply/index`,
			title: '付款申请管理'
		},
		{
			path: `/storagemanage/financepayment/index`,
			title: '付款单管理'
		},
		{
			path: `#1`,
			title: '系统配置',
			children: [{
				path: `${pre}systemSetting-dictionary-list`,
				title: '树状字典管理',
			},{
				path: `${pre}systemSetting-dictionaryPj-list`,
				title: '平级字典管理',
			},
			{ 
				path: `/storagemanage/area/index`,
				title: '区域管理' 
			}]
		},
		{
			path: `${pre}merchant-list`,
			title: '商家管理'
		},{
			path: `${pre}product-list`,
			title: '产品案例'
		},{
			path: `/storagemanage/depositcontracttemplate/index`,
			title: '存房合同模板管理'
		},{
			path: `/storagemanage/contractsubjectmanage/index`,
			title: '存房签约主体'
		},
	]
}
