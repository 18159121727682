/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-09T18:45:59.575
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportRentContractRequestReletInit(data){return request({
url: '/report/api/reportRentContractRequestRelet/listReportRentContractRequestReletInit',
method: 'post',
data:data,
});
}


export function listReportRentContractRequestReletByAuthorizations(data){return request({
url: '/report/api/reportRentContractRequestRelet/listReportRentContractRequestReletByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportRentContractRequestRelet(data){return request({
url: '/report/api/reportRentContractRequestRelet/listReportRentContractRequestRelet',
method: 'post',
data:data,
});
}


export function getReportRentContractRequestReletCreateInit(){return request({
url: '/report/api/reportRentContractRequestRelet/getReportRentContractRequestReletCreateInit',
method: 'post',
});
}


export function getReportRentContractRequestReletEditInit(params){return request({
url: '/report/api/reportRentContractRequestRelet/getReportRentContractRequestReletEditInit',
method: 'post',
params:params,
});
}


export function getReportRentContractRequestReletView(params){return request({
url: '/report/api/reportRentContractRequestRelet/getReportRentContractRequestReletView',
method: 'post',
params:params,
});
}


export function saveReportRentContractRequestRelet(data){return request({
url: '/report/api/reportRentContractRequestRelet/saveReportRentContractRequestRelet',
method: 'post',
data:data,
});
}


export function deleteReportRentContractRequestRelet(params){return request({
url: '/report/api/reportRentContractRequestRelet/deleteReportRentContractRequestRelet',
method: 'post',
params:params,
});
}


