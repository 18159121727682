import util from '@/libs/util';
import router from '@/router';
import {
	listEvaluateBillRemould,
	listEvaluateBillRemouldInit,
	listEvaluateBillRemouldByAuthorizations,
	deleteEvaluateBillRemould,
	downloadRemouldTemplate
} from '@api/storagemanage/EvaluateBillRemould';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listEvaluateBillRemould({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillRemould(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateBillRemouldByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillRemouldByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateBillRemouldInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillRemouldInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateBillRemould({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateBillRemould({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		downloadRemouldTemplate({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				downloadRemouldTemplate(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	},
	
};
