import util from '@/libs/util';
import router from '@/router';
import {
	listHourseInfo,
	listHourseInfoInit,
	listHourseInfoByAuthorizations,
	deleteHourseInfo
	
} from '@api/storagemanage/HourseInfo';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listHourseInfo({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHourseInfo(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listHourseInfoByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHourseInfoByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listHourseInfoInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHourseInfoInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteHourseInfo({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteHourseInfo({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
