/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-25T10:37:42.905
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseTypeSalesInit(data){return request({
url: '/rentmanage/api/rentHouseTypeSales/listRentHouseTypeSalesInit',
method: 'post',
data:data,
});
}


export function listRentHouseTypeSalesByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseTypeSales/listRentHouseTypeSalesByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseTypeSales(data){return request({
url: '/rentmanage/api/rentHouseTypeSales/listRentHouseTypeSales',
method: 'post',
data:data,
});
}


export function getRentHouseTypeSalesCreateInit(){return request({
url: '/rentmanage/api/rentHouseTypeSales/getRentHouseTypeSalesCreateInit',
method: 'post',
});
}


export function getRentHouseTypeSalesEditInit(params){return request({
url: '/rentmanage/api/rentHouseTypeSales/getRentHouseTypeSalesEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseTypeSalesView(params){return request({
url: '/rentmanage/api/rentHouseTypeSales/getRentHouseTypeSalesView',
method: 'post',
params:params,
});
}


export function saveRentHouseTypeSales(data){return request({
url: '/rentmanage/api/rentHouseTypeSales/saveRentHouseTypeSales',
method: 'post',
data:data,
});
}


export function deleteRentHouseTypeSales(params){return request({
url: '/rentmanage/api/rentHouseTypeSales/deleteRentHouseTypeSales',
method: 'post',
params:params,
});
}


