/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-04T19:46:29.332
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveHouseRepeatPlanCondition,
	getHouseRepeatPlanConditionCreateInit,
	getHouseRepeatPlanConditionEditInit,
	getHouseRepeatPlanConditionView,
	deleteHouseRepeatPlanCondition
} from '@api/storagemanage/HouseRepeatPlanCondition';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getHouseRepeatPlanConditionCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getHouseRepeatPlanConditionCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseRepeatPlanConditionEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseRepeatPlanConditionEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveHouseRepeatPlanCondition({}, HouseRepeatPlanCondition) {
			return new Promise((resolve, reject) => {
				saveHouseRepeatPlanCondition(HouseRepeatPlanCondition)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseRepeatPlanConditionView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseRepeatPlanConditionView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteHouseRepeatPlanCondition({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteHouseRepeatPlanCondition({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
