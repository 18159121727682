/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-04-29T08:55:38.978
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listHouseCycleInit(data){return request({
url: '/house/api/houseCycle/listHouseCycleInit',
method: 'post',
data:data,
});
}


export function listHouseCycleByAuthorizations(data){return request({
url: '/house/api/houseCycle/listHouseCycleByAuthorizations',
method: 'post',
data:data,
});
}


export function listHouseCycle(data){return request({
url: '/house/api/houseCycle/listHouseCycle',
method: 'post',
data:data,
});
}


export function getHouseCycleCreateInit(){return request({
url: '/house/api/houseCycle/getHouseCycleCreateInit',
method: 'post',
});
}


export function getHouseCycleEditInit(params){return request({
url: '/house/api/houseCycle/getHouseCycleEditInit',
method: 'post',
params:params,
});
}


export function getHouseCycleView(params){return request({
url: '/house/api/houseCycle/getHouseCycleView',
method: 'post',
params:params,
});
}


export function saveHouseCycle(data){return request({
url: '/house/api/houseCycle/saveHouseCycle',
method: 'post',
data:data,
});
}


export function deleteHouseCycle(params){return request({
url: '/house/api/houseCycle/deleteHouseCycle',
method: 'post',
params:params,
});
}


