/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2024-01-11T10:42:57.379
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-renthouseholditemhistory-'

export default 
{
path: '/rentmanage/renthouseholditemhistory',
name: 'rentmanage-renthouseholditemhistory',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}renthouseholditemhistory-index',

meta:{
...meta,
title: '保留房跟进记录表'
},

component: () => import('@/pages/rentmanage/renthouseholditemhistory/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}renthouseholditemhistory-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '保留进度更新编辑'
},

component: () => import('@/pages/rentmanage/renthouseholditemhistory/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}renthouseholditemhistorydetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '保留进度更新新增'
},

component: () => import('@/pages/rentmanage/renthouseholditemhistory/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}renthouseholditemhistoryView',
auth: [
'hidden'
],

meta:{
...meta,
title: '保留进度更新详情查看'
},

component: () => import('@/pages/rentmanage/renthouseholditemhistory/detail-view') ,
},
]
};
