/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-04-28T09:57:01.37
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getUnitEditInit,
	getUnitView
} from '@api/storagemanage/Unit';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},

	actions: {
		getUnitEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getUnitEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getUnitView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getUnitView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
