/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-07-08T19:32:01.768
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listRentLeaseContractChangeRequestInit(data) {
    return request({
        url: '/rentmanage/api/rentLeaseContractChangeRequest/listRentLeaseContractChangeRequestInit',
        method: 'post',
        data: data,
    });
}


export function listRentLeaseContractChangeRequestByAuthorizations(data) {
    return request({
        url: '/rentmanage/api/rentLeaseContractChangeRequest/listRentLeaseContractChangeRequestByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listRentLeaseContractChangeRequest(data) {
    return request({
        url: '/rentmanage/api/rentLeaseContractChangeRequest/listRentLeaseContractChangeRequest',
        method: 'post',
        data: data,
    });
}


export function getRentLeaseContractChangeRequestCreateInit(params) {
    return request({
        url: '/rentmanage/api/rentLeaseContractChangeRequest/getRentLeaseContractChangeRequestCreateInit',
        method: 'post',
		params: params,
    });
}


export function getRentLeaseContractChangeRequestEditInit(params) {
    return request({
        url: '/rentmanage/api/rentLeaseContractChangeRequest/getRentLeaseContractChangeRequestEditInit',
        method: 'post',
        params: params,
    });
}


export function getRentLeaseContractChangeRequestView(params) {
    return request({
        url: '/rentmanage/api/rentLeaseContractChangeRequest/getRentLeaseContractChangeRequestView',
        method: 'post',
        params: params,
    });
}


export function saveRentLeaseContractChangeRequest(data) {
    return request({
        url: '/rentmanage/api/rentLeaseContractChangeRequest/saveRentLeaseContractChangeRequest',
        method: 'post',
        data: data,
    });
}


export function deleteRentLeaseContractChangeRequest(params) {
    return request({
        url: '/rentmanage/api/rentLeaseContractChangeRequest/deleteRentLeaseContractChangeRequest',
        method: 'post',
        params: params,
    });
}


export function generateLeaseSettlement(params) {
    return request({
        url: '/rentmanage/api/rentLeaseContractChangeRequest/generateLeaseSettlement',
        method: 'post',
        params: params,
    });
}


export function toAcceptChangeRequest(params) {
    return request({
        url: '/rentmanage/api/rentLeaseContractChangeRequest/toAcceptChangeRequest',
        method: 'get',
        params: params,
    });
}

export function withdraw(params) {
    return request({
        url: '/rentmanage/api/rentLeaseContractChangeRequest/withdraw',
        method: 'post',
        params: params,
    });
}