/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-01T18:25:53.537
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getOrganizationManageEditInit,
	getOrganizationManageView
} from '@api/systemmanage/OrganizationManage';

import {
listOrgPositionManageInit,
listOrgPositionManageByAuthorizations,
deleteOrgPositionManage
} from '@api/systemmanage/OrgPositionManage';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getOrganizationManageEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getOrganizationManageEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getOrganizationManageView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getOrganizationManageView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
