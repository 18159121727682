/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-01-05T10:42:26.417
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getFinancialElectronicAccountEditInit,
	getFinancialElectronicAccountView
} from '@api/financial/FinancialElectronicAccount';

import {
listFinancialElectronicAccountItemInit,
listFinancialElectronicAccountItemByAuthorizations,
deleteFinancialElectronicAccountItem
} from '@api/financial/FinancialElectronicAccountItem';

import {
listFinancialElectronicAccountFreezeInit,
listFinancialElectronicAccountFreezeByAuthorizations,
deleteFinancialElectronicAccountFreeze
} from '@api/financial/FinancialElectronicAccountFreeze';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getFinancialElectronicAccountEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialElectronicAccountEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialElectronicAccountView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialElectronicAccountView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
