/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2021-03-08T19:23:44.62
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listFinancialClaimCancellationApplyInit(data) {
	return request({
		url: '/financial/api/financialClaimCancellationApply/listFinancialClaimCancellationApplyInit',
		method: 'post',
		data: data,
	});
}


export function listFinancialClaimCancellationApplyByAuthorizations(data) {
	return request({
		url: '/financial/api/financialClaimCancellationApply/listFinancialClaimCancellationApplyByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listFinancialClaimCancellationApply(data) {
	return request({
		url: '/financial/api/financialClaimCancellationApply/listFinancialClaimCancellationApply',
		method: 'post',
		data: data,
	});
}


export function getFinancialClaimCancellationApplyCreateInit(params) {
	return request({
		url: '/financial/api/financialClaimCancellationApply/getFinancialClaimCancellationApplyCreateInit',
		method: 'post',
		params: params,
	});
}


export function getFinancialClaimCancellationApplyEditInit(params) {
	return request({
		url: '/financial/api/financialClaimCancellationApply/getFinancialClaimCancellationApplyEditInit',
		method: 'post',
		params: params,
	});
}


export function getFinancialClaimCancellationApplyView(params) {
	return request({
		url: '/financial/api/financialClaimCancellationApply/getFinancialClaimCancellationApplyView',
		method: 'post',
		params: params,
	});
}


export function saveFinancialClaimCancellationApply(data) {
	return request({
		url: '/financial/api/financialClaimCancellationApply/saveFinancialClaimCancellationApply',
		method: 'post',
		data: data,
	});
}


export function deleteFinancialClaimCancellationApply(params) {
	return request({
		url: '/financial/api/financialClaimCancellationApply/deleteFinancialClaimCancellationApply',
		method: 'post',
		params: params,
	});
}
