/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-28T14:19:44.663
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveFinancialCollectionCoupont,
	getFinancialCollectionCoupontCreateInit,
	getFinancialCollectionCoupontEditInit,
	getFinancialCollectionCoupontView,
	deleteFinancialCollectionCoupont
} from '@api/financial/FinancialCollectionCoupont';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialCollectionCoupontCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionCoupontCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialCollectionCoupontEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionCoupontEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialCollectionCoupont({}, FinancialCollectionCoupont) {
			return new Promise((resolve, reject) => {
				saveFinancialCollectionCoupont(FinancialCollectionCoupont)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialCollectionCoupontView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionCoupontView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialCollectionCoupont({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialCollectionCoupont({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
