/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-01T16:33:48.736
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-rentfacility-'

export default 
{
path: '/rentmanage/rentfacility',
name: 'rentmanage-rentfacility',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}rentfacility-index',

meta:{
...meta,
title: '设施表'
},

component: () => import('@/pages/rentmanage/rentfacility/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}rentfacility-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '设施表编辑'
},

component: () => import('@/pages/rentmanage/rentfacility/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}rentfacilitydetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '设施表新增'
},

component: () => import('@/pages/rentmanage/rentfacility/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}rentfacilityView',
auth: [
'hidden'
],

meta:{
...meta,
title: '设施表详情查看'
},

component: () => import('@/pages/rentmanage/rentfacility/detail-view') ,
},
]
};
