/**
 * 注册、登录、注销
 * */
import {isArray, omit} from 'lodash'
import util, {jumpSSOLogin } from '@/libs/util'
import storage from '@/libs/vendors/storage'

import {
	AccountLogin,
	loginByAccount,
	AccountRegister,
	getUserInfoByCodeReq,
	getUserInfoByQYWXCodeReq,
	AccountLoginWithOpenId,
	AccountLoginWithOpenIdRent,
	loginWithOpenIdAccount,
	getValidCodeReq,
	logoutReq,
	verifyGoogleDynamicCode,
	bindGoogleSecretSMSVerificationCode,
	queryUserInfoByUserCode
} from '@api/account';
import {
	Message,
	Notice
} from 'view-design';

import {
	Modal
} from 'view-design';
import { cloneDeep } from 'lodash';

export default {
	namespaced: true,
	state: {
		wechatOpenId: '',
		wechatOpenIdMobile: '',
		menuList: null,
	},
	actions: {
		/**
		 * @description 登录
		 * @param {Object} param context
		 * @param {Object} param userCode {String} 用户账号
		 * @param {Object} param password {String} 密码
		 * @param {Object} param route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
		 */
		login({
			dispatch,
			state,
		}, {
			userMobile = '',
			verificationCode = '',
			saasCompany = 10000,
			userType = '2',
		} = {}) {
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				AccountLogin({
					userMobile,
					verificationCode,
					saasCompany,
					userType,
				})
					.then(async res => {
						// 设置 cookie 一定要存 uuid 和 token 两个 cookie
						// 整个系统依赖这两个数据进行校验和存储
						// uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
						// token 代表用户当前登录状态 建议在网络请求中携带 token
						// 如有必要 token 需要定时更新，默认保存一天，可在 setting.js 中修改
						// 如果你的 token 不是通过 cookie 携带，而是普通字段，也可视情况存储在 localStorage
						//清除浏览器缓存
						window.localStorage.clear();
						util.cookies.set('uuid', res.entity.uuid);
						util.cookies.set('token', res.entity.token);
						util.cookies.set('version', res.entity.version);
						util.cookies.set('userType', res.entity.userType);
						util.cookies.set('saasCompany', res.entity.sassCompany);
						util.cookies.set('userCode', res.entity.userId);
						// 设置 vuex 用户信息
						await dispatch('admin/user/set', res.entity, {
							root: true
						});
						// 用户登录后从持久化数据加载一系列的设置
						await dispatch('load');
						//记录用户菜单
						let item = res.entity.iviewMenuAccess;
						for (let i = 0; i < item.length; i++) {
							if (item[i].path.indexOf('http') != -1) {
								item[i]['target'] = '_blank'
							}
							if (item[i].children && item[i].children.length > 0) {
								for (let j = 0; j < item[i].children.length; j++) {
									if (item[i].children[j].path.indexOf('http') != -1) {
										item[i].children[j]['target'] = '_blank'
									}
								}
							}
						}
						state.menuList = res.entity.iviewMenuAccess || null;
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
		verifyGoogleDynamicCode({
			dispatch,
			state
		}, {
			saasCompany = 10000,
			userMobile = '',
			googleDynamicCode = '',
		} = {}) {
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				verifyGoogleDynamicCode({
					saasCompany,
					userMobile,
					googleDynamicCode,
				})
					.then(async res => {
						// 设置 cookie 一定要存 uuid 和 token 两个 cookie
						// 整个系统依赖这两个数据进行校验和存储
						// uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
						// token 代表用户当前登录状态 建议在网络请求中携带 token
						// 如有必要 token 需要定时更新，默认保存一天，可在 setting.js 中修改
						// 如果你的 token 不是通过 cookie 携带，而是普通字段，也可视情况存储在 localStorage
						//清除浏览器缓存
						window.localStorage.clear();
						util.cookies.set('uuid', res.entity.uuid);
						util.cookies.set('token', res.entity.token);
						util.cookies.set('version', res.entity.version);
						util.cookies.set('userType', res.entity.userType);
						util.cookies.set('saasCompany', res.entity.sassCompany);
						util.cookies.set('userCode', res.entity.userId);
						// 设置 vuex 用户信息
						await dispatch('admin/user/set', res.entity, {
							root: true
						});
						// 用户登录后从持久化数据加载一系列的设置
						await dispatch('load');
						//记录用户菜单
						let item = res.entity.iviewMenuAccess || [];
						for (let i = 0; i < item.length; i++) {
							if (item[i].path.indexOf('http') != -1) {
								item[i]['target'] = '_blank'
							}
							if (item[i].children && item[i].children.length > 0) {
								for (let j = 0; j < item[i].children.length; j++) {
									if (item[i].children[j].path.indexOf('http') != -1) {
										item[i].children[j]['target'] = '_blank'
									}
								}
							}
						}
						state.menuList = res.entity.iviewMenuAccess || [];
						// window.localStorage.setItem('menuList', JSON.stringify(state.menuList))
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		loginByAccount({
			dispatch,
			state
		}, {
			userCode = '',
			password = '',
			saasCompany = 10000,
		} = {}) {
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				loginByAccount({
					userCode,
					password,
					saasCompany,
				})
					.then(async res => {
						// 设置 cookie 一定要存 uuid 和 token 两个 cookie
						// 整个系统依赖这两个数据进行校验和存储
						// uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
						// token 代表用户当前登录状态 建议在网络请求中携带 token
						// 如有必要 token 需要定时更新，默认保存一天，可在 setting.js 中修改
						// 如果你的 token 不是通过 cookie 携带，而是普通字段，也可视情况存储在 localStorage
						//清除浏览器缓存
						window.localStorage.clear();
						util.cookies.set('uuid', res.entity.uuid);
						util.cookies.set('token', res.entity.token);
						util.cookies.set('version', res.entity.version);
						util.cookies.set('userType', res.entity.userType);
						util.cookies.set('saasCompany', res.entity.sassCompany);
						util.cookies.set('userCode', res.entity.userId);
						// 设置 vuex 用户信息
						await dispatch('admin/user/set', res.entity, {
							root: true
						});
						// 用户登录后从持久化数据加载一系列的设置
						await dispatch('load');
						//记录用户菜单
						let item = res.entity.iviewMenuAccess || [];
						for (let i = 0; i < item.length; i++) {
							if (item[i].path.indexOf('http') != -1) {
								item[i]['target'] = '_blank'
							}
							if (item[i].children && item[i].children.length > 0) {
								for (let j = 0; j < item[i].children.length; j++) {
									if (item[i].children[j].path.indexOf('http') != -1) {
										item[i].children[j]['target'] = '_blank'
									}
								}
							}
						}
						state.menuList = res.entity.iviewMenuAccess || [];
						// window.localStorage.setItem('menuList', JSON.stringify(state.menuList))
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		loginWithOpenId({
			dispatch,
			state
		}, {
			userMobile = '',
			verificationCode = '',
			saasCompany = 10000,
			wechatUuid = '',
			userType = '',
		} = {}) {
			state.menuList = [];
			// Message.info({
			// 	content: JSON.stringify({
			// 		userMobile,
			// 		verificationCode,
			// 		saasCompany,
			// 		wechatUuid,
			// 	}),
			// 	duration: 300
			// });
			return new Promise((resolve, reject) => {
				storage.set('platform', 'h5')
				// 开始请求登录接口
				AccountLoginWithOpenId({
					userMobile,
					verificationCode,
					saasCompany,
					wechatUuid,
					userType,
				})
					.then(async res => {
						const payload = {
							...res.entity,
							id: res.entity.uuid,
							userCode: res.entity.userId
						}
						storage.set('user', payload)
						storage.set('token', res.entity.token)
						// 设置 vuex 用户信息
						await dispatch('admin/user/set', payload, {root: true})

						// 用户登录后从持久化数据加载一系列的设置
						await dispatch('load');
						if (!res.entity.iviewMenuAccess || res.entity.iviewMenuAccess == [] || res.entity.iviewMenuAccess.length == 0) {
							Message.warning({
								content: '您没有权限访问系统!请联系管理员',
								duration: 2
							});
							reject();
						} else {
							state.menuList = res.entity.iviewMenuAccess || [];
							resolve();
						}
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		loginWithOpenIdRent({ dispatch, state }, params = {}) {
			const {userMobile = '', verificationCode = '', saasCompany = 10000, wechatUuid = '', userType = ''} = params
			state.menuList = []
			return new Promise((resolve, reject) => {
				storage.set('platform', 'h5')
				// 开始请求登录接口
				AccountLoginWithOpenId({
					userMobile,
					verificationCode,
					saasCompany,
					wechatUuid,
					userType,
				})
					.then(async res => {
						const payload = {
							...res.entity,
							id: res.entity.uuid,
							userCode: res.entity.userId
						}
						storage.set('user', payload)
						storage.set('token', res.entity.token)
						// 设置 vuex 用户信息
						await dispatch('admin/user/set', payload, {root: true})
						// 用户登录后从持久化数据加载一系列的设置
						await dispatch('load');
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		loginWithOpenIdAccount({
			dispatch,
			state
		}, {
			userCode = '',
			password = '',
			saasCompany = 10000,
			wechatUuid = '',
		} = {}) {
			state.menuList = [];
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				loginWithOpenIdAccount({
					userCode,
					password,
					saasCompany,
					wechatUuid,
				})
					.then(async res => {
						// 设置 cookie 一定要存 uuid 和 token 两个 cookie
						// 整个系统依赖这两个数据进行校验和存储
						// uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
						// token 代表用户当前登录状态 建议在网络请求中携带 token
						// 如有必要 token 需要定时更新，默认保存一天，可在 setting.js 中修改
						// 如果你的 token 不是通过 cookie 携带，而是普通字段，也可视情况存储在 localStorage
						//清除浏览器缓存
						window.localStorage.clear();
						util.cookies.set('uuid', res.entity.uuid);
						util.cookies.set('token', res.entity.token);
						util.cookies.set('version', res.entity.version);
						util.cookies.set('userType', res.entity.userType);
						util.cookies.set('saasCompany', res.entity.sassCompany);
						util.cookies.set('userCode', res.entity.userId);
						// 设置 vuex 用户信息
						await dispatch('admin/user/set', res.entity, {
							root: true
						});
						// 用户登录后从持久化数据加载一系列的设置
						await dispatch('load');
						if (!res.entity.iviewMenuAccess || res.entity.iviewMenuAccess == [] || res.entity.iviewMenuAccess.length == 0) {
							Message.warning({
								content: '您没有权限访问系统!请联系管理员',
								duration: 2
							});
							reject();
						} else {
							state.menuList = res.entity.iviewMenuAccess;
							resolve();
						}

					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getUserInfoByCode({
			state,
			dispatch
		}, {
			code = '',
			saasCompany = '',
			userType = '',
		} = {}) {
			return new Promise((resolve, reject) => {
				storage.set('platform', 'h5')
				// 开始请求登录接口
				getUserInfoByCodeReq({
					code,
					saasCompany, userType,
				})
					.then(async res => {
						if (!res.entity.userId) {
							state.wechatOpenId = res.entity.weixinOpenid;
							resolve({
								path: '/wxlogin',
							});
							// window.router.push({
							// 	path: '/login',
							// })
						} else {
							const payload = {
								...res.entity,
								id: res.entity.uuid,
								userCode: res.entity.userId
							}
							storage.set('user', payload)
							storage.set('token', res.entity.token)
							// 设置 vuex 用户信息
							await dispatch('admin/user/set', payload, {root: true})
							// 用户登录后从持久化数据加载一系列的设置
							await dispatch('load');
							state.menuList = res.entity.iviewMenuAccess;
							resolve({
								path: '/',
							});
							// window.router.replace({
							// 	path: '/index'
							// });
						}
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getUserInfoByQYWXCode({
			state,
			dispatch
		}, {
			code = '',
			saasCompany = '',
			userType = '',
		} = {}) {
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				getUserInfoByQYWXCodeReq({
					code,
					saasCompany, userType,
				})
					.then(async res => {
						util.cookies.set('uuid', res.entity.uuid);
						util.cookies.set('token', res.entity.token);
						util.cookies.set('version', res.entity.version);
						util.cookies.set('userType', res.entity.userType);
						util.cookies.set('saasCompany', res.entity.sassCompany);
						util.cookies.set('userCode', res.entity.userId);
						// 设置 vuex 用户信息
						await dispatch('admin/user/set', res.entity, {
							root: true
						});
						// 用户登录后从持久化数据加载一系列的设置
						await dispatch('load');
						state.menuList = res.entity.iviewMenuAccess;
						resolve({
							path: '/',
						});
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getUserInfoByCodeRent({
			state,
			dispatch
		}, {
			code = '',
			saasCompany = '',
			userType = '',
		} = {}) {
			return new Promise((resolve, reject) => {
				storage.set('platform', 'h5')
				// 开始请求登录接口
				getUserInfoByCodeReq({
					code,
					saasCompany,
					userType,
				})
					.then(async res => {
						if (!res.entity.userId) {
							state.wechatOpenId = res.entity.weixinOpenid;
							resolve({
								path: '/rentuserlogin',
							});
							// window.router.push({
							// 	path: '/login',
							// })
						} else {
							const payload = {
								...res.entity,
								id: res.entity.uuid,
								userCode: res.entity.userId
							}
							storage.set('user', payload)
							storage.set('token', res.entity.token)
							// 设置 vuex 用户信息
							await dispatch('admin/user/set', payload, {root: true})
							// 用户登录后从持久化数据加载一系列的设置
							await dispatch('load');
							state.menuList = res.entity.iviewMenuAccess;
							resolve({
								path: '/rentusercenter',
							});
							// window.router.replace({
							// 	path: '/index'
							// });
						}
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getUserInfoByCodeMobile({
			state,
			dispatch
		}, {
			code = '',
			saasCompany = '',
			userType = '',
		} = {}) {
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				getUserInfoByCodeReq({
					code,
					saasCompany,
					userType,
				})
					.then(async res => {
						storage.set('platform', 'h5')
						if (!res.entity.userId) {
							state.wechatOpenIdMobile = res.entity.weixinOpenid;
							// 设置 vuex 用户信息
							await dispatch('admin/user/set', res.entity, {
								root: true
							});
							// 用户登录后从持久化数据加载一系列的设置
							await dispatch('load');
							// window.router.replace({
							// 	path: '/wepublicIndex'
							// });
							resolve();
						} else {
							const payload = {
								...res.entity,
								id: res.entity.uuid,
								userCode: res.entity.userId
							}
							storage.set('user', payload)
							storage.set('token', res.entity.token)
							// 设置 vuex 用户信息
							await dispatch('admin/user/set', payload, {root: true})
							// 用户登录后从持久化数据加载一系列的设置
							await dispatch('load');
							// window.router.replace({
							// 	path: '/wepublicIndex'
							// });
							resolve();
						}
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getValidCode({
			dispatch
		}, {
			mobile = '',
		} = {}) {
			return new Promise((resolve, reject) => {
				// 获取验证码
				getValidCodeReq({
					mobile,
				})
					.then(async res => {

						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
		/**
		 * @description 注册
		 * @param {Object} param context
		 * @param {Object} param mail {String} 邮箱
		 * @param {Object} param password {String} 密码
		 * @param {Object} param mobile {String} 手机号码
		 * @param {Object} param captcha {String} 验证码
		 */
		register({
			dispatch
		}, {
			mail = '',
			password = '',
			mobile = '',
			captcha = ''
		} = {}) {
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				AccountRegister({
					mail,
					password,
					mobile,
					captcha
				})
					.then(async res => {
						// 注册成功后，完成与登录一致的操作
						// 注册也可视情况不返还 uuid、token 等数据，在注册完成后，由前端自动执行一次登录逻辑
						util.cookies.set('uuid', res.uuid);
						util.cookies.set('token', res.token);
						// 设置 vuex 用户信息
						await dispatch('admin/user/set', res.info, {
							root: true
						});
						// 用户登录后从持久化数据加载一系列的设置
						await dispatch('load');
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
		bindGoogleSecretSMSVerificationCode({
			dispatch
		}, {
			saasCompany = '',
			userMobile = '',
			verificationCode = ""
		} = {}) {
			return new Promise((resolve, reject) => {
				// 获取验证码
				bindGoogleSecretSMSVerificationCode({
					saasCompany, userMobile, verificationCode
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},


    login2 ({dispatch, state}, payload) {
			return new Promise(async (resolve, reject) => {
				try {
					storage.set('token', payload.accessToken)
          // 查询用户详情
          const entity = await dispatch('queryUserInfoByUser', payload)
          const user = {
            ...payload,
            ...omit(entity, ['userType']),
            saasCompany: entity.sassCompany,
          }

					storage.set('user', user)
					// 设置 vuex 用户信息
					await dispatch('admin/user/set', user, { root: true })
					// 用户登录后从持久化数据加载一系列的设置
					await dispatch('load')
					resolve()
				} catch (e) { reject(e) }
			})
		},
    autoLogin({dispatch, state}, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          // 查询用户详情
          const entity = await dispatch('queryUserInfoByUser', payload)
          const user = {
            ...payload,
            ...omit(entity, ['userType']),
            saasCompany: entity.sassCompany,
          }
          // 设置 vuex 用户信息
          await dispatch('admin/user/set', user, { root: true })
          // 用户登录后从持久化数据加载一系列的设置
          await dispatch('load')
          resolve()
        } catch (e) { reject(e) }
      })
    },

		/**
		 * @description 退出登录
		 * */
		logout({ commit, dispatch }, { confirm = false, vm, rentuserlogin } = {}) {
      const logout = () => {
        dispatch('admin/user/set', {}, {root: true})
        rentuserlogin !== true && jumpSSOLogin({ logout: true })
      }

      if (confirm) {
        return Modal.confirm({
          title: '退出登录确认',
          content: '您确定退出登录当前账户吗',
          onOk: logout
        })
      }
      logout()
		},

    /**
     * 查询用户详情
     */
    queryUserInfoByUser ({ dispatch, state }, payload) {
      const formatMenu = (menuList = []) => {
        return menuList.map(item => {
          if (isArray(item.children)) item.children = formatMenu(item.children)
          return {
            ...item,
            ...item.path.indexOf('http') !== -1 && {target: '_blank'},
          }
        })
      }
      return new Promise(async (resolve, reject) => {
        try {
          // 查询用户详情
          const {entity} = await queryUserInfoByUserCode({userCode: payload.userCode})

          state.menuList = formatMenu(entity.iviewMenuAccess)
          resolve(entity)
        } catch (e) { reject(e) }
      })
    },

    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} state vuex state
     * @param {Object} dispatch vuex dispatch
     */
    load({state, dispatch}) {
      return new Promise(async resolve => {
        // 持久化数据加载上次退出时的多页列表
        await dispatch('admin/page/openedLoad', null, {root: true})
        // end
        resolve()
      })
    },
	}
};
