/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-10T15:01:42.845
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentHouseOnlineItem,
	listRentHouseOnlineItemInit,
	listRentHouseOnlineItemByAuthorizations,
	deleteRentHouseOnlineItem,
	downloadRentHouseOnlineItemTemplate
} from '@api/rentmanage/RentHouseOnlineItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentHouseOnlineItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseOnlineItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseOnlineItemByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseOnlineItemByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseOnlineItemInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseOnlineItemInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		downloadRentHouseOnlineItemTemplate({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				downloadRentHouseOnlineItemTemplate(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentHouseOnlineItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseOnlineItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
