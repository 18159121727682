/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T15:03:08.614
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listAssignedBillInit(data){return request({
url: '/storagemanage/api/assignedBill/listAssignedBillInit',
method: 'post',
data:data,
});
}


export function listAssignedBillByAuthorizations(data){return request({
url: '/storagemanage/api/assignedBill/listAssignedBillByAuthorizations',
method: 'post',
data:data,
});
}


export function listAssignedBill(data){return request({
url: '/storagemanage/api/assignedBill/listAssignedBill',
method: 'post',
data:data,
});
}


export function getAssignedBillCreateInit(){return request({
url: '/storagemanage/api/assignedBill/getAssignedBillCreateInit',
method: 'post',
});
}


export function getAssignedBillEditInit(params){return request({
url: '/storagemanage/api/assignedBill/getAssignedBillEditInit',
method: 'post',
params:params,
});
}


export function getAssignedBillView(params){return request({
url: '/storagemanage/api/assignedBill/getAssignedBillView',
method: 'post',
params:params,
});
}


export function saveAssignedBill(data){return request({
url: '/storagemanage/api/assignedBill/saveAssignedBill',
method: 'post',
data:data,
});
}


export function deleteAssignedBill(params){return request({
url: '/storagemanage/api/assignedBill/deleteAssignedBill',
method: 'post',
params:params,
});
}


