/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-22T14:43:04.501
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentPlaceOrder,
	listRentPlaceOrderInit,
	listRentPlaceOrderByAuthorizations,
	deleteRentPlaceOrder,
	cancelRentPlaceOrder,
	sentRentSign,
	getRentPlaceOrderInfoByHouseId,
	listCompanyRentPlaceOrderBySQL
} from '@api/rentmanage/RentPlaceOrder';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentPlaceOrder({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentPlaceOrder(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		listRentPlaceOrderByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentPlaceOrderByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listCompanyRentPlaceOrderBySQL({
			state
		}) {
			return new Promise((resolve, reject) => {
				listCompanyRentPlaceOrderBySQL(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentPlaceOrderInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentPlaceOrderInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		sentRentSign({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				sentRentSign({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		cancelRentPlaceOrder({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				cancelRentPlaceOrder({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentPlaceOrder({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentPlaceOrder({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentPlaceOrderInfoByHouseId({
			state
		}, {houseRentId}) {
			return new Promise((resolve, reject) => {
				getRentPlaceOrderInfoByHouseId({
					houseRentId
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entity);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
	}
};
