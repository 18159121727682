import request from '@/plugins/request';

export function GetDataList (data) {
    return request({
        url: '/h5platform/api/notify/listNotifyListPublish',
        method: 'post',
		data:data
    });
}

export function pageInitReq (data) {
    return request({
        url: '/h5platform/api/notify/listNotifyListPublishInit',
        method: 'post',
        data
    });
}

export function AddOrEditData (data) {
    return request({
        url: '/h5platform/api/notify/saveManagerOutShow',
        method: 'post',
        data
    });
}

export function addPageInitReq (data) {
    return request({
        url: '/h5platform/api/notify/getManagerOutShowCreateInit',
        method: 'post',
        data
    });
}

export function editPageInitReq (data) {
    return request({
        url: '/h5platform/api/notify/getManagerOutShowEditInit',
        method: 'post',
        params:data
    });
}

