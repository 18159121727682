/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-01T18:10:47.041
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveOrgPosition,
	getOrgPositionCreateInit,
	getOrgPositionEditInit,
	getOrgPositionView,
	deleteOrgPosition
} from '@api/systemmanage/OrgPosition';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getOrgPositionCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getOrgPositionCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getOrgPositionEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getOrgPositionEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveOrgPosition({}, OrgPosition) {
			return new Promise((resolve, reject) => {
				saveOrgPosition(OrgPosition)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getOrgPositionView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getOrgPositionView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteOrgPosition({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteOrgPosition({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
