/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-31T10:17:51.262
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentRepairFeedBackCleaningInit(data){return request({
url: '/rentmanage/api/rentRepairFeedBackCleaning/listRentRepairFeedBackCleaningInit',
method: 'post',
data:data,
});
}


export function listRentRepairFeedBackCleaningByAuthorizations(data){return request({
url: '/rentmanage/api/rentRepairFeedBackCleaning/listRentRepairFeedBackCleaningByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentRepairFeedBackCleaning(data){return request({
url: '/rentmanage/api/rentRepairFeedBackCleaning/listRentRepairFeedBackCleaning',
method: 'post',
data:data,
});
}


export function getRentRepairFeedBackCleaningCreateInit(){return request({
url: '/rentmanage/api/rentRepairFeedBackCleaning/getRentRepairFeedBackCleaningCreateInit',
method: 'post',
});
}


export function getRentRepairFeedBackCleaningEditInit(params){return request({
url: '/rentmanage/api/rentRepairFeedBackCleaning/getRentRepairFeedBackCleaningEditInit',
method: 'post',
params:params,
});
}


export function getRentRepairFeedBackCleaningView(params){return request({
url: '/rentmanage/api/rentRepairFeedBackCleaning/getRentRepairFeedBackCleaningView',
method: 'post',
params:params,
});
}


export function saveRentRepairFeedBackCleaning(data){return request({
url: '/rentmanage/api/rentRepairFeedBackCleaning/saveRentRepairFeedBackCleaning',
method: 'post',
data:data,
});
}


export function deleteRentRepairFeedBackCleaning(params){return request({
url: '/rentmanage/api/rentRepairFeedBackCleaning/deleteRentRepairFeedBackCleaning',
method: 'post',
params:params,
});
}


