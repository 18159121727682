/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:56.804
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listHouseInfoInit(data){return request({
url: '/lcf/api/houseInfo/listHouseInfoInit',
method: 'post',
data:data,
});
}


export function listHouseInfoByAuthorizations(data){return request({
url: '/lcf/api/houseInfo/listHouseInfoByAuthorizations',
method: 'post',
data:data,
});
}


export function listHouseInfo(data){return request({
url: '/lcf/api/houseInfo/listHouseInfo',
method: 'post',
data:data,
});
}


export function getHouseInfoCreateInit(){return request({
url: '/lcf/api/houseInfo/getHouseInfoCreateInit',
method: 'post',
});
}


export function getHouseInfoEditInit(params){return request({
url: '/lcf/api/houseInfo/getHouseInfoEditInit',
method: 'post',
params:params,
});
}


export function getHouseInfoView(params){return request({
url: '/lcf/api/houseInfo/getHouseInfoView',
method: 'post',
params:params,
});
}


export function saveHouseInfo(data){return request({
url: '/lcf/api/houseInfo/saveHouseInfo',
method: 'post',
data:data,
});
}


export function deleteHouseInfo(params){return request({
url: '/lcf/api/houseInfo/deleteHouseInfo',
method: 'post',
params:params,
});
}


