/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T09:30:03.532
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialPayablesItemInit(data){return request({
url: '/financial/api/financialPayablesItem/listFinancialPayablesItemInit',
method: 'post',
data:data,
});
}


export function listFinancialPayablesItemByAuthorizations(data){return request({
url: '/financial/api/financialPayablesItem/listFinancialPayablesItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialPayablesItem(data){return request({
url: '/financial/api/financialPayablesItem/listFinancialPayablesItem',
method: 'post',
data:data,
});
}


export function getFinancialPayablesItemCreateInit(){return request({
url: '/financial/api/financialPayablesItem/getFinancialPayablesItemCreateInit',
method: 'post',
});
}


export function getFinancialPayablesItemEditInit(params){return request({
url: '/financial/api/financialPayablesItem/getFinancialPayablesItemEditInit',
method: 'post',
params:params,
});
}


export function getFinancialPayablesItemView(params){return request({
url: '/financial/api/financialPayablesItem/getFinancialPayablesItemView',
method: 'post',
params:params,
});
}


export function saveFinancialPayablesItem(data){return request({
url: '/financial/api/financialPayablesItem/saveFinancialPayablesItem',
method: 'post',
data:data,
});
}


export function deleteFinancialPayablesItem(params){return request({
url: '/financial/api/financialPayablesItem/deleteFinancialPayablesItem',
method: 'post',
params:params,
});
}


