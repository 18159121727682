/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-18T17:50:48.076
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listkhmPayDeductionApplyItemInit(data){return request({
url: '/storagemanage/api/khmPayDeductionApplyItem/listkhmPayDeductionApplyItemInit',
method: 'post',
data:data,
});
}


export function listkhmPayDeductionApplyItemByAuthorizations(data){return request({
url: '/storagemanage/api/khmPayDeductionApplyItem/listkhmPayDeductionApplyItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listkhmPayDeductionApplyItem(data){return request({
url: '/storagemanage/api/khmPayDeductionApplyItem/listkhmPayDeductionApplyItem',
method: 'post',
data:data,
});
}


export function getkhmPayDeductionApplyItemCreateInit(){return request({
url: '/storagemanage/api/khmPayDeductionApplyItem/getkhmPayDeductionApplyItemCreateInit',
method: 'post',
});
}


export function getkhmPayDeductionApplyItemEditInit(params){return request({
url: '/storagemanage/api/khmPayDeductionApplyItem/getkhmPayDeductionApplyItemEditInit',
method: 'post',
params:params,
});
}


export function getkhmPayDeductionApplyItemView(params){return request({
url: '/storagemanage/api/khmPayDeductionApplyItem/getkhmPayDeductionApplyItemView',
method: 'post',
params:params,
});
}


export function savekhmPayDeductionApplyItem(data){return request({
url: '/storagemanage/api/khmPayDeductionApplyItem/savekhmPayDeductionApplyItem',
method: 'post',
data:data,
});
}


export function deletekhmPayDeductionApplyItem(params){return request({
url: '/storagemanage/api/khmPayDeductionApplyItem/deletekhmPayDeductionApplyItem',
method: 'post',
params:params,
});
}


