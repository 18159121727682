import util from '@/libs/util';
import router from '@/router';
import {
	getEvaluateBillEditInit,
	getEvaluateBillView
} from '@api/storagemanage/EvaluateBill';

import {
listEvaluateBillAttachmentInit,
listEvaluateBillAttachmentByAuthorizations,
deleteEvaluateBillAttachment
} from '@api/storagemanage/EvaluateBillAttachment';

import {
listEvaluateBillHouseInit,
listEvaluateBillHouseByAuthorizations,
deleteEvaluateBillHouse
} from '@api/storagemanage/EvaluateBillHouse';

import {
listEvaluateBillOwnerInit,
listEvaluateBillOwnerByAuthorizations,
deleteEvaluateBillOwner
} from '@api/storagemanage/EvaluateBillOwner';

import {
listEvaluateBillRemouldInit,
listEvaluateBillRemouldByAuthorizations,
deleteEvaluateBillRemould
} from '@api/storagemanage/EvaluateBillRemould';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getEvaluateBillEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateBillView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
