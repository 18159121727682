/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-15T15:47:43.018
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-evaluateappointmentbill-'

export default 
{
path: '/storagemanage/evaluateappointmentbill',
name: 'storagemanage-evaluateappointmentbill',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}evaluateappointmentbill-index',

meta:{
...meta,
title: '房屋评估预约单'
},

component: () => import('@/pages/storagemanage/evaluateappointmentbill/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}evaluateappointmentbill-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '房屋评估预约单编辑'
},

component: () => import('@/pages/storagemanage/evaluateappointmentbill/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}evaluateappointmentbilldetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '房屋评估预约单新增'
},

component: () => import('@/pages/storagemanage/evaluateappointmentbill/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}evaluateappointmentbillView',
auth: [
'hidden'
],

meta:{
...meta,
title: '房屋评估预约单详情查看'
},

component: () => import('@/pages/storagemanage/evaluateappointmentbill/detail-view') ,
},
]
};
