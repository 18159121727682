/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T15:54:15.655
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-RentChannelApply-'

export default 
{
path: '/rentmanage/rentchannelapply',
name: 'rentmanage-RentChannelApply',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}RentChannelApply-index',

meta:{
...meta,
title: '房源分发'
},

component: () => import('@/pages/rentmanage/rentchannelapply/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}RentChannelApply-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源分发编辑'
},

component: () => import('@/pages/rentmanage/rentchannelapply/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}RentChannelApplydetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源分发新增'
},

component: () => import('@/pages/rentmanage/rentchannelapply/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}RentChannelApplyView',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源分发详情查看'
},

component: () => import('@/pages/rentmanage/rentchannelapply/detail-view') ,
},
]
};
