/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-14T20:19:04.275
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'storagemanage-managementareauser-'

export default {
	path: '/storagemanage/managementareauser',
	name: 'storagemanage-managementareauser',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'indexPlatform',
			name: '${pre}managementareauser-indexPlatform',

			meta: {
				...meta,
				title: '平台运营'
			},

			component: () => import('@/pages/storagemanage/managementareauser/indexPlatform'),
		},
		{
			path: 'indexBusinessOperation',
			name: '${pre}managementareauser-indexBusinessOperation',

			meta: {
				...meta,
				title: '商家运营专员'
			},

			component: () => import('@/pages/storagemanage/managementareauser/indexBusinessOperation'),
		},
		{
			path: 'indexBusinessCheckIn',
			name: '${pre}managementareauser-indexBusinessCheckIn',

			meta: {
				...meta,
				title: '客户经理'
			},

			component: () => import('@/pages/storagemanage/managementareauser/indexBusinessCheckIn'),
		},{
			path: 'indexChannelOperation',
			name: '${pre}managementareauser-indexChannelOperation',

			meta: {
				...meta,
				title: '评估运营专员'
			},

			component: () => import('@/pages/storagemanage/managementareauser/indexChannelOperation'),
		},{
			path: 'indexChannelAssessment',
			name: '${pre}managementareauser-indexChannelAssessment',

			meta: {
				...meta,
				title: '评估专员'
			},

			component: () => import('@/pages/storagemanage/managementareauser/indexChannelAssessment'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}managementareauser-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '区域用户编辑'
			},

			component: () => import('@/pages/storagemanage/managementareauser/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}managementareauserdetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '区域用户编辑'
			},

			component: () => import('@/pages/storagemanage/managementareauser/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}managementareauserView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '区域用户详情查看'
			},

			component: () => import('@/pages/storagemanage/managementareauser/detail-view'),
		},
	]
};
