/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:57.914
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveEvaluationMicroRemakeProblem,
	getEvaluationMicroRemakeProblemCreateInit,
	getEvaluationMicroRemakeProblemEditInit,
	getEvaluationMicroRemakeProblemView,
	deleteEvaluationMicroRemakeProblem
} from '@api/lcf/EvaluationMicroRemakeProblem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getEvaluationMicroRemakeProblemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getEvaluationMicroRemakeProblemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluationMicroRemakeProblemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluationMicroRemakeProblemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveEvaluationMicroRemakeProblem({}, EvaluationMicroRemakeProblem) {
			return new Promise((resolve, reject) => {
				saveEvaluationMicroRemakeProblem(EvaluationMicroRemakeProblem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluationMicroRemakeProblemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluationMicroRemakeProblemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluationMicroRemakeProblem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluationMicroRemakeProblem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
