<template>
	<GlobalFooter class="i-copyright" :links="links" :copyright="copyright" />
</template>
<script>
	export default {
		name: 'i-copyright',
		data() {
			return {
				links: [{
					title: '粤ICP备18120239号 粤公网安备 44010602005737号',
					key: '粤ICP备18120239号 粤公网安备 44010602005737号',
					href: 'https://beian.miit.gov.cn/#/Integrated/index',
					blankTarget: true
				}, {
					title: '公司官网：http://www.giantfind.com.cn',
					key: '公司官网：http://www.giantfind.com.cn',
					href: 'http://www.giantfind.com.cn/',
					blankTarget: true
				}, {
					title: '存租热线：4009915651',
					key: '存租热线：4009915651',
					href: 'http://app.giantfind.com.cn/',
					blankTarget: true
				}],
				copyright: ''
			}
		}
	}
</script>
<style lang="less">
	.i-copyright {
    margin: 15px 0 60px 0 !important;
    display: flex;
    justify-content: center;
	}
  .ivu-global-footer-links {
    display: flex;
    flex-direction: column;

    a {
      margin-right: 0 !important;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
</style>
