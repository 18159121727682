/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-08T11:03:21.476
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentMaterialReceivingMainFormInit(data){return request({
url: '/rentmanage/api/rentMaterialReceivingMainForm/listRentMaterialReceivingMainFormInit',
method: 'post',
data:data,
});
}


export function listRentMaterialReceivingMainFormByAuthorizations(data){return request({
url: '/rentmanage/api/rentMaterialReceivingMainForm/listRentMaterialReceivingMainFormByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentMaterialReceivingMainForm(data){return request({
url: '/rentmanage/api/rentMaterialReceivingMainForm/listRentMaterialReceivingMainForm',
method: 'post',
data:data,
});
}


export function getRentMaterialReceivingMainFormCreateInit(){return request({
url: '/rentmanage/api/rentMaterialReceivingMainForm/getRentMaterialReceivingMainFormCreateInit',
method: 'post',
});
}


export function getRentMaterialReceivingMainFormEditInit(params){return request({
url: '/rentmanage/api/rentMaterialReceivingMainForm/getRentMaterialReceivingMainFormEditInit',
method: 'post',
params:params,
});
}


export function getRentMaterialReceivingMainFormView(params){return request({
url: '/rentmanage/api/rentMaterialReceivingMainForm/getRentMaterialReceivingMainFormView',
method: 'post',
params:params,
});
}


export function saveRentMaterialReceivingMainForm(data){return request({
url: '/rentmanage/api/rentMaterialReceivingMainForm/saveRentMaterialReceivingMainForm',
method: 'post',
data:data,
});
}


export function submitRentMaterialReceivingMainForm(data){return request({
url: '/rentmanage/api/rentMaterialReceivingMainForm/submitRentMaterialReceivingMainForm',
method: 'post',
data:data,
});
}

export function deleteRentMaterialReceivingMainForm(params){return request({
url: '/rentmanage/api/rentMaterialReceivingMainForm/deleteRentMaterialReceivingMainForm',
method: 'post',
params:params,
});
}


