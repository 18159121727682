/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-28T14:19:44.381
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinancialCollectionItem,
	listFinancialCollectionItemInit,
	listFinancialCollectionItemByAuthorizations,
	deleteFinancialCollectionItem
	
} from '@api/financial/FinancialCollectionItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFinancialCollectionItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialCollectionItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialCollectionItemByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialCollectionItemByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialCollectionItemInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialCollectionItemInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialCollectionItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialCollectionItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
