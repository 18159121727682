/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-23T16:04:05.353
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listChannelpicInit(data){return request({
url: '/rentmanage/api/channelpic/listChannelpicInit',
method: 'post',
data:data,
});
}


export function listChannelpicByAuthorizations(data){return request({
url: '/rentmanage/api/channelpic/listChannelpicByAuthorizations',
method: 'post',
data:data,
});
}


export function listChannelpic(data){return request({
url: '/rentmanage/api/channelpic/listChannelpic',
method: 'post',
data:data,
});
}


export function getChannelpicCreateInit(){return request({
url: '/rentmanage/api/channelpic/getChannelpicCreateInit',
method: 'post',
});
}


export function getChannelpicEditInit(params){return request({
url: '/rentmanage/api/channelpic/getChannelpicEditInit',
method: 'post',
params:params,
});
}


export function getChannelpicView(params){return request({
url: '/rentmanage/api/channelpic/getChannelpicView',
method: 'post',
params:params,
});
}


export function saveChannelpic(data){return request({
url: '/rentmanage/api/channelpic/saveChannelpic',
method: 'post',
data:data,
});
}


export function deleteChannelpic(params){return request({
url: '/rentmanage/api/channelpic/deleteChannelpic',
method: 'post',
params:params,
});
}


