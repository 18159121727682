/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-16T16:45:54.864
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listKhmDataAuthApplyInit(data){return request({
url: '/rentmanage/api/khmDataAuthApply/listKhmDataAuthApplyInit',
method: 'post',
data:data,
});
}


export function listKhmDataAuthApplyByAuthorizations(data){return request({
url: '/rentmanage/api/khmDataAuthApply/listKhmDataAuthApplyByAuthorizations',
method: 'post',
data:data,
});
}


export function listKhmDataAuthApply(data){return request({
url: '/rentmanage/api/khmDataAuthApply/listKhmDataAuthApply',
method: 'post',
data:data,
});
}


export function getKhmDataAuthApplyCreateInit(){return request({
url: '/rentmanage/api/khmDataAuthApply/getKhmDataAuthApplyCreateInit',
method: 'post',
});
}


export function getKhmDataAuthApplyEditInit(params){return request({
url: '/rentmanage/api/khmDataAuthApply/getKhmDataAuthApplyEditInit',
method: 'post',
params:params,
});
}


export function getKhmDataAuthApplyView(params){return request({
url: '/rentmanage/api/khmDataAuthApply/getKhmDataAuthApplyView',
method: 'post',
params:params,
});
}


export function saveKhmDataAuthApply(data){return request({
url: '/rentmanage/api/khmDataAuthApply/saveKhmDataAuthApply',
method: 'post',
data:data,
});
}


export function deleteKhmDataAuthApply(params){return request({
url: '/rentmanage/api/khmDataAuthApply/deleteKhmDataAuthApply',
method: 'post',
params:params,
});
}


export function startWorkflow(data){return request({
	url: '/rentmanage/api/khmDataAuthApply/startWorkflow',
	method: 'post',
	data:data,
	});
}
