/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-22T15:12:16.195
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-rentplaceorderretreat-'

export default 
{
path: '/rentmanage/rentplaceorderretreat',
name: 'rentmanage-rentplaceorderretreat',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}rentplaceorderretreat-index',

meta:{
...meta,
title: '退订管理'
},

component: () => import('@/pages/rentmanage/rentplaceorderretreat/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}rentplaceorderretreat-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '退订管理编辑'
},

component: () => import('@/pages/rentmanage/rentplaceorderretreat/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}rentplaceorderretreatdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '退订管理新增'
},

component: () => import('@/pages/rentmanage/rentplaceorderretreat/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}rentplaceorderretreatView',
auth: [
'hidden'
],

meta:{
...meta,
title: '退订管理详情查看'
},

component: () => import('@/pages/rentmanage/rentplaceorderretreat/detail-view') ,
},
]
};
