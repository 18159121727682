import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	redirect: {
		name: `${pre}payManagement-list`
	},
	meta,
	component: BasicLayout,
	children: [
		{
			path: 'payManagement-list',
			name: `${pre}payManagement-list`,
			meta: {
				...meta,
				title: '付款单管理',
				closable: true
			},
			component: () => import('@/pages/storagemanage/payManagement/index')
		},
		{
			path: 'payManagement-detail',
			name: `${pre}payManagement-detail`,
			meta: {
				...meta,
				title: '付款单管理查看',
				closable: true
			},
			component: () => import('@/pages/storagemanage/payManagement/detail')
		} ,



	]
};
