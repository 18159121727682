/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-08T11:03:22.495
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentMaterialReceivingForm,
	listRentMaterialReceivingFormInit,
	listRentMaterialReceivingFormByAuthorizations,
	deleteRentMaterialReceivingForm,
	listRentMaterialReceivingFormBySQL
} from '@api/rentmanage/RentMaterialReceivingForm';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentMaterialReceivingForm({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentMaterialReceivingForm(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentMaterialReceivingFormBySQL({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentMaterialReceivingFormBySQL(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentMaterialReceivingFormByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentMaterialReceivingFormByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentMaterialReceivingFormInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentMaterialReceivingFormInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentMaterialReceivingForm({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentMaterialReceivingForm({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
