/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-04-07 14:30:16
 * @LastEditors: co
 * @LastEditTime: 2021-06-09 14:26:06
 * @FilePath: \iview-admin-pro-template\src\store\modules\storagemanage\modules\area\index.js
 */
import util from '@/libs/util';
import router from '@/router';
import {
	listArea,
	listAreaInit,
	listAreaByAuthorizations,
	deleteArea

} from '@api/storagemanage/Area';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listArea({
			state
		}) {
			return new Promise((resolve, reject) => {
				listArea(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listAreaByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listAreaByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities = res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listAreaInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listAreaInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteArea({
			state
		}, { id }) {
			return new Promise((resolve, reject) => {
				deleteArea({
					id
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
