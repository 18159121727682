/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-20T20:38:25.854
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveDroomConEstateInfo,
	getDroomConEstateInfoCreateInit,
	getDroomConEstateInfoEditInit,
	getDroomConEstateInfoView,
	deleteDroomConEstateInfo
} from '@api/storagemanage/DroomConEstateInfo';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getDroomConEstateInfoCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getDroomConEstateInfoCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getDroomConEstateInfoEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getDroomConEstateInfoEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveDroomConEstateInfo({}, DroomConEstateInfo) {
			return new Promise((resolve, reject) => {
				saveDroomConEstateInfo(DroomConEstateInfo)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getDroomConEstateInfoView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getDroomConEstateInfoView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteDroomConEstateInfo({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteDroomConEstateInfo({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
