/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-19T17:51:38.537
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentRepairWorkOrderRelationInit(data){return request({
url: '/rentmanage/api/rentRepairWorkOrderRelation/listRentRepairWorkOrderRelationInit',
method: 'post',
data:data,
});
}


export function listRentRepairWorkOrderRelationByAuthorizations(data){return request({
url: '/rentmanage/api/rentRepairWorkOrderRelation/listRentRepairWorkOrderRelationByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentRepairWorkOrderRelation(data){return request({
url: '/rentmanage/api/rentRepairWorkOrderRelation/listRentRepairWorkOrderRelation',
method: 'post',
data:data,
});
}


export function getRentRepairWorkOrderRelationCreateInit(){return request({
url: '/rentmanage/api/rentRepairWorkOrderRelation/getRentRepairWorkOrderRelationCreateInit',
method: 'post',
});
}


export function getRentRepairWorkOrderRelationEditInit(params){return request({
url: '/rentmanage/api/rentRepairWorkOrderRelation/getRentRepairWorkOrderRelationEditInit',
method: 'post',
params:params,
});
}


export function getRentRepairWorkOrderRelationView(params){return request({
url: '/rentmanage/api/rentRepairWorkOrderRelation/getRentRepairWorkOrderRelationView',
method: 'post',
params:params,
});
}


export function saveRentRepairWorkOrderRelation(data){return request({
url: '/rentmanage/api/rentRepairWorkOrderRelation/saveRentRepairWorkOrderRelation',
method: 'post',
data:data,
});
}


export function deleteRentRepairWorkOrderRelation(params){return request({
url: '/rentmanage/api/rentRepairWorkOrderRelation/deleteRentRepairWorkOrderRelation',
method: 'post',
params:params,
});
}


