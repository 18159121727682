/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-11T10:48:26.329
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
  auth: true
};

const pre = 'rentmanage-renttenant-'

export default {
  path: '/rentmanage/renttenant',
  name: 'rentmanage-renttenant',
  redirect: {
    name: '${pre}-index'
  },

  meta: {
    ...meta,
  },

  component: BasicLayout,
  children: [{
    path: 'index',
    name: '${pre}renttenant-index',

    meta: {
      ...meta,
      title: '客户管理'
    },

    component: () => import('@/pages/rentmanage/renttenant/index'),
  },
  {
    path: 'detail-edit/:id',
    name: `${pre}detail-edit`,
    auth: [
      'hidden'
    ],

    meta: {
      ...meta,
      cache: true,
      title: '租客管理编辑'
    },

    component: () => import('@/pages/rentmanage/renttenant/detail-edit'),
  },
  {
    path: 'detail-edit',
    name: '${pre}renttenantdetail-create',
    auth: [
      'hidden'
    ],

    meta: {
      ...meta,
      title: '租客管理新增'
    },

    component: () => import('@/pages/rentmanage/renttenant/detail-edit'),
  },
  {
    path: 'detail-view/:id',
    name: '${pre}renttenantView',
    auth: [
      'hidden'
    ],

    meta: {
      ...meta,
      title: '租客管理详情查看'
    },

    component: () => import('@/pages/rentmanage/renttenant/detail-view'),
  },
  ]
};
