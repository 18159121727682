/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-03T15:07:15.546
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentMaterialInit(data){return request({
url: '/rentmanage/api/rentMaterial/listRentMaterialInit',
method: 'post',
data:data,
});
}


export function listRentMaterialByAuthorizations(data){return request({
url: '/rentmanage/api/rentMaterial/listRentMaterialByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentMaterial(data){return request({
url: '/rentmanage/api/rentMaterial/listRentMaterial',
method: 'post',
data:data,
});
}


export function getRentMaterialCreateInit(){return request({
url: '/rentmanage/api/rentMaterial/getRentMaterialCreateInit',
method: 'post',
});
}


export function getRentMaterialEditInit(params){return request({
url: '/rentmanage/api/rentMaterial/getRentMaterialEditInit',
method: 'post',
params:params,
});
}


export function getRentMaterialView(params){return request({
url: '/rentmanage/api/rentMaterial/getRentMaterialView',
method: 'post',
params:params,
});
}


export function saveRentMaterial(data){return request({
url: '/rentmanage/api/rentMaterial/saveRentMaterial',
method: 'post',
data:data,
});
}


export function deleteRentMaterial(params){return request({
url: '/rentmanage/api/rentMaterial/deleteRentMaterial',
method: 'post',
params:params,
});
}


