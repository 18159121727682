/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-01-05T10:42:26.657
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialElectronicAccountItemInit(data){return request({
url: '/financial/api/financialElectronicAccountItem/listFinancialElectronicAccountItemInit',
method: 'post',
data:data,
});
}


export function listFinancialElectronicAccountItemByAuthorizations(data){return request({
url: '/financial/api/financialElectronicAccountItem/listFinancialElectronicAccountItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialElectronicAccountItem(data){return request({
url: '/financial/api/financialElectronicAccountItem/listFinancialElectronicAccountItem',
method: 'post',
data:data,
});
}


export function getFinancialElectronicAccountItemCreateInit(){return request({
url: '/financial/api/financialElectronicAccountItem/getFinancialElectronicAccountItemCreateInit',
method: 'post',
});
}


export function getFinancialElectronicAccountItemEditInit(params){return request({
url: '/financial/api/financialElectronicAccountItem/getFinancialElectronicAccountItemEditInit',
method: 'post',
params:params,
});
}


export function getFinancialElectronicAccountItemView(params){return request({
url: '/financial/api/financialElectronicAccountItem/getFinancialElectronicAccountItemView',
method: 'post',
params:params,
});
}


export function saveFinancialElectronicAccountItem(data){return request({
url: '/financial/api/financialElectronicAccountItem/saveFinancialElectronicAccountItem',
method: 'post',
data:data,
});
}


export function deleteFinancialElectronicAccountItem(params){return request({
url: '/financial/api/financialElectronicAccountItem/deleteFinancialElectronicAccountItem',
method: 'post',
params:params,
});
}


