/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2024-01-11T10:42:57.353
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseHoldItemHistoryInit(data){return request({
url: '/rentmanage/api/rentHouseHoldItemHistory/listRentHouseHoldItemHistoryInit',
method: 'post',
data:data,
});
}


export function listRentHouseHoldItemHistoryByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseHoldItemHistory/listRentHouseHoldItemHistoryByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseHoldItemHistory(data){return request({
url: '/rentmanage/api/rentHouseHoldItemHistory/listRentHouseHoldItemHistory',
method: 'post',
data:data,
});
}


export function getRentHouseHoldItemHistoryCreateInit(){return request({
url: '/rentmanage/api/rentHouseHoldItemHistory/getRentHouseHoldItemHistoryCreateInit',
method: 'post',
});
}


export function getRentHouseHoldItemHistoryEditInit(params){return request({
url: '/rentmanage/api/rentHouseHoldItemHistory/getRentHouseHoldItemHistoryEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseHoldItemHistoryView(params){return request({
url: '/rentmanage/api/rentHouseHoldItemHistory/getRentHouseHoldItemHistoryView',
method: 'post',
params:params,
});
}


export function saveRentHouseHoldItemHistory(data){return request({
url: '/rentmanage/api/rentHouseHoldItemHistory/saveRentHouseHoldItemHistory',
method: 'post',
data:data,
});
}


export function deleteRentHouseHoldItemHistory(params){return request({
url: '/rentmanage/api/rentHouseHoldItemHistory/deleteRentHouseHoldItemHistory',
method: 'post',
params:params,
});
}


