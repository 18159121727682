/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-25T16:21:58.296
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseFacilityInit(data){return request({
url: '/rentmanage/api/rentHouseFacility/listRentHouseFacilityInit',
method: 'post',
data:data,
});
}


export function listRentHouseFacilityByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseFacility/listRentHouseFacilityByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseFacility(data){return request({
url: '/rentmanage/api/rentHouseFacility/listRentHouseFacility',
method: 'post',
data:data,
});
}


export function getRentHouseFacilityCreateInit(){return request({
url: '/rentmanage/api/rentHouseFacility/getRentHouseFacilityCreateInit',
method: 'post',
});
}


export function getRentHouseFacilityEditInit(params){return request({
url: '/rentmanage/api/rentHouseFacility/getRentHouseFacilityEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseFacilityView(params){return request({
url: '/rentmanage/api/rentHouseFacility/getRentHouseFacilityView',
method: 'post',
params:params,
});
}


export function saveRentHouseFacility(data){return request({
url: '/rentmanage/api/rentHouseFacility/saveRentHouseFacility',
method: 'post',
data:data,
});
}


export function deleteRentHouseFacility(params){return request({
url: '/rentmanage/api/rentHouseFacility/deleteRentHouseFacility',
method: 'post',
params:params,
});
}


