/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T15:03:08.746
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-AssignedBill-components-AssignedBillItem-'

export default 
{
path: '/storagemanage/assignedbill/components/assignedbillitem',
name: 'storagemanage-assignedbill-components-assignedbillitem',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}AssignedBillItem-index',

meta:{
...meta,
title: '指派单(受理)明细'
},

component: () => import('@/pages/storagemanage/assignedbill/components/assignedbillitem/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}AssignedBillItem-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '指派单(受理)明细编辑'
},

component: () => import('@/pages/storagemanage/assignedbill/components/assignedbillitem/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}AssignedBillItemdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '指派单(受理)明细新增'
},

component: () => import('@/pages/storagemanage/assignedbill/components/assignedbillitem/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}AssignedBillItemView',
auth: [
'hidden'
],

meta:{
...meta,
title: '指派单(受理)明细详情查看'
},

component: () => import('@/pages/storagemanage/assignedbill/components/assignedbillitem/detail-view') ,
},
]
};
