/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T10:58:26.407
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialEvidenceDetailInit(data){return request({
url: '/financial/api/financialEvidenceDetail/listFinancialEvidenceDetailInit',
method: 'post',
data:data,
});
}


export function listFinancialEvidenceDetailByAuthorizations(data){return request({
url: '/financial/api/financialEvidenceDetail/listFinancialEvidenceDetailByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialEvidenceDetail(data){return request({
url: '/financial/api/financialEvidenceDetail/listFinancialEvidenceDetail',
method: 'post',
data:data,
});
}


export function getFinancialEvidenceDetailCreateInit(){return request({
url: '/financial/api/financialEvidenceDetail/getFinancialEvidenceDetailCreateInit',
method: 'post',
});
}


export function getFinancialEvidenceDetailEditInit(params){return request({
url: '/financial/api/financialEvidenceDetail/getFinancialEvidenceDetailEditInit',
method: 'post',
params:params,
});
}


export function getFinancialEvidenceDetailView(params){return request({
url: '/financial/api/financialEvidenceDetail/getFinancialEvidenceDetailView',
method: 'post',
params:params,
});
}


export function saveFinancialEvidenceDetail(data){return request({
url: '/financial/api/financialEvidenceDetail/saveFinancialEvidenceDetail',
method: 'post',
data:data,
});
}


export function deleteFinancialEvidenceDetail(params){return request({
url: '/financial/api/financialEvidenceDetail/deleteFinancialEvidenceDetail',
method: 'post',
params:params,
});
}


