<template>
  <span class="i-layout-header-trigger i-layout-header-trigger-min">
    <Dropdown :trigger="isMobile ? 'click' : 'hover'" class="i-layout-header-user"
              :class="{ 'i-layout-header-user-mobile': isMobile }" @on-click="handleClick">
      <Avatar size="small" :src="info.avatar" v-if="info.avatar"/>
      <span class="i-layout-header-user-name">{{ info.userName }}</span>
      <DropdownMenu slot="list">
        <DropdownItem name="system" v-if="['1', '3'].includes(`${info.userType}`)">
          <Icon type="ios-repeat"/> 切换到「寓」
        </DropdownItem>
        <DropdownItem name="logout" :divided="['1', '3'].includes(`${info.userType}`)">
          <Icon type="ios-log-out" /> 退出登录
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
    <updatePassword ref="updatePassword" v-if="updatePasswordVisible"
                    @destroy="updatePasswordVisible=false;" />
  </span>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import { yu_uri } from '@/setting'
  import updatePassword from '@/pages/account/updatePassword/updatePassword'
  import {jumpPlatform} from '@/libs/util'

  export default {
    name: 'iHeaderUser',
    computed: {
      ...mapState('admin/user', [
        'info'
      ]),
      ...mapState('admin/layout', [
        'isMobile',
        'logoutConfirm'
      ])
    },
    data () {
      return {
        updatePasswordVisible: false,
      }
    },
    components: {
      updatePassword
    },
    methods: {
      ...mapActions('admin/account', [
        'logout'
      ]),
      handleClick (name) {
        if (name === 'system')
          return jumpPlatform(this.info, `${yu_uri}/#/redirect/dashboard/console`)
        if (name === 'updatePassword') {
          this.updatePasswordVisible = true;
          this.$nextTick(() => {
            this.$refs['updatePassword'].init();
          })
        }
        if (name === 'logout') {
          this.logout({
            confirm: this.logoutConfirm,
            vm: this
          });
        }
      }
    }
  }
</script>
