/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2023-12-28T10:48:56.72
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportBizRentContractItemPayment,
	getReportBizRentContractItemPaymentCreateInit,
	getReportBizRentContractItemPaymentEditInit,
	getReportBizRentContractItemPaymentView,
	deleteReportBizRentContractItemPayment
} from '@api/report/ReportBizRentContractItemPayment';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportBizRentContractItemPaymentCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportBizRentContractItemPaymentCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportBizRentContractItemPaymentEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportBizRentContractItemPaymentEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportBizRentContractItemPayment({}, ReportBizRentContractItemPayment) {
			return new Promise((resolve, reject) => {
				saveReportBizRentContractItemPayment(ReportBizRentContractItemPayment)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportBizRentContractItemPaymentView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportBizRentContractItemPaymentView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportBizRentContractItemPayment({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportBizRentContractItemPayment({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
