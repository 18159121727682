/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T08:26:50.727
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHouseSalesPromotion,
	getRentHouseSalesPromotionCreateInit,
	getRentHouseSalesPromotionEditInit,
	getRentHouseSalesPromotionView,
	deleteRentHouseSalesPromotion,
	startWorkflow
} from '@api/rentmanage/RentHouseSalesPromotion';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentHouseSalesPromotionItemInit,
listRentHouseSalesPromotionItemByAuthorizations,
deleteRentHouseSalesPromotionItem
} from '@api/rentmanage/RentHouseSalesPromotionItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentHouseSalesPromotionCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseSalesPromotionCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						state.CompoundEntityDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseSalesPromotionEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseSalesPromotionEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseSalesPromotion({}, RentHouseSalesPromotion) {
			return new Promise((resolve, reject) => {
				saveRentHouseSalesPromotion(RentHouseSalesPromotion)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseSalesPromotionView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseSalesPromotionView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		startWorkflow({}, RentHouseSalesPromotion) {
			return new Promise((resolve, reject) => {
				startWorkflow(RentHouseSalesPromotion)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentHouseSalesPromotion({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseSalesPromotion({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
