/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-18T18:02:48.264
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'systemmanage-authfuncrolemanage-'

export default 
{
path: '/systemmanage/authfuncrolemanage',
name: 'systemmanage-authfuncrolemanage',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}authfuncrolemanage-index',

meta:{
...meta,
title: '角色管理'
},

component: () => import('@/pages/systemmanage/authfuncrolemanage/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}authfuncrolemanage-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '角色管理编辑'
},

component: () => import('@/pages/systemmanage/authfuncrolemanage/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}authfuncrolemanagedetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '角色管理新增'
},

component: () => import('@/pages/systemmanage/authfuncrolemanage/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}authfuncrolemanageView',
auth: [
'hidden'
],

meta:{
...meta,
title: '角色管理详情查看'
},

component: () => import('@/pages/systemmanage/authfuncrolemanage/detail-view') ,
},
]
};
