/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-01-05T10:42:26.421
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancialElectronicAccount,
	getFinancialElectronicAccountCreateInit,
	getFinancialElectronicAccountEditInit,
	getFinancialElectronicAccountView,
	deleteFinancialElectronicAccount
} from '@api/financial/FinancialElectronicAccount';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listFinancialElectronicAccountItemInit,
listFinancialElectronicAccountItemByAuthorizations,
deleteFinancialElectronicAccountItem
} from '@api/financial/FinancialElectronicAccountItem';

import {
listFinancialElectronicAccountFreezeInit,
listFinancialElectronicAccountFreezeByAuthorizations,
deleteFinancialElectronicAccountFreeze
} from '@api/financial/FinancialElectronicAccountFreeze';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialElectronicAccountCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialElectronicAccountCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialElectronicAccountEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialElectronicAccountEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialElectronicAccount({}, FinancialElectronicAccount) {
			return new Promise((resolve, reject) => {
				saveFinancialElectronicAccount(FinancialElectronicAccount)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialElectronicAccountView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialElectronicAccountView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialElectronicAccount({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialElectronicAccount({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
