/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2023-12-28T10:48:56.699
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportBizRentContractItemPaymentInit(data){return request({
url: '/report/api/reportBizRentContractItemPayment/listReportBizRentContractItemPaymentInit',
method: 'post',
data:data,
});
}


export function listReportBizRentContractItemPaymentByAuthorizations(data){return request({
url: '/report/api/reportBizRentContractItemPayment/listReportBizRentContractItemPaymentByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportBizRentContractItemPayment(data){return request({
url: '/report/api/reportBizRentContractItemPayment/listReportBizRentContractItemPayment',
method: 'post',
data:data,
});
}


export function getReportBizRentContractItemPaymentCreateInit(){return request({
url: '/report/api/reportBizRentContractItemPayment/getReportBizRentContractItemPaymentCreateInit',
method: 'post',
});
}


export function getReportBizRentContractItemPaymentEditInit(params){return request({
url: '/report/api/reportBizRentContractItemPayment/getReportBizRentContractItemPaymentEditInit',
method: 'post',
params:params,
});
}


export function getReportBizRentContractItemPaymentView(params){return request({
url: '/report/api/reportBizRentContractItemPayment/getReportBizRentContractItemPaymentView',
method: 'post',
params:params,
});
}


export function saveReportBizRentContractItemPayment(data){return request({
url: '/report/api/reportBizRentContractItemPayment/saveReportBizRentContractItemPayment',
method: 'post',
data:data,
});
}


export function deleteReportBizRentContractItemPayment(params){return request({
url: '/report/api/reportBizRentContractItemPayment/deleteReportBizRentContractItemPayment',
method: 'post',
params:params,
});
}


// 应收报表统计
export function countReportBizRentContractItemPayment(data) {
    return request({
        url: '/report/api/reportBizRentContractItemPayment/countReportBizRentContractItemPayment',
        method: 'post',
        data: data,
    });
}