/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-26T14:31:04.547
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportfinancialpaymentreminder-'

export default 
{
path: '/report/reportfinancialpaymentreminder',
name: 'report-reportfinancialpaymentreminder',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportfinancialpaymentreminder-index',

meta:{
...meta,
title: '应收催缴报表'
},

component: () => import('@/pages/report/reportfinancialpaymentreminder/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportfinancialpaymentreminder-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '应收催缴报表编辑'
},

component: () => import('@/pages/report/reportfinancialpaymentreminder/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportfinancialpaymentreminderdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '应收催缴报表新增'
},

component: () => import('@/pages/report/reportfinancialpaymentreminder/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportfinancialpaymentreminderView',
auth: [
'hidden'
],

meta:{
...meta,
title: '应收催缴报表详情查看'
},

component: () => import('@/pages/report/reportfinancialpaymentreminder/detail-view') ,
},
]
};
