/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-26T10:02:19.813
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentFacilityTestInit(data){return request({
url: '/rentmanage/api/rentFacilityTest/listRentFacilityTestInit',
method: 'post',
data:data,
});
}


export function listRentFacilityTestByAuthorizations(data){return request({
url: '/rentmanage/api/rentFacilityTest/listRentFacilityTestByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentFacilityTest(data){return request({
url: '/rentmanage/api/rentFacilityTest/listRentFacilityTest',
method: 'post',
data:data,
});
}


export function getRentFacilityTestCreateInit(){return request({
url: '/rentmanage/api/rentFacilityTest/getRentFacilityTestCreateInit',
method: 'post',
});
}


export function getRentFacilityTestEditInit(params){return request({
url: '/rentmanage/api/rentFacilityTest/getRentFacilityTestEditInit',
method: 'post',
params:params,
});
}


export function getRentFacilityTestView(params){return request({
url: '/rentmanage/api/rentFacilityTest/getRentFacilityTestView',
method: 'post',
params:params,
});
}


export function saveRentFacilityTest(data){return request({
url: '/rentmanage/api/rentFacilityTest/saveRentFacilityTest',
method: 'post',
data:data,
});
}


export function deleteRentFacilityTest(params){return request({
url: '/rentmanage/api/rentFacilityTest/deleteRentFacilityTest',
method: 'post',
params:params,
});
}


