<template>
  <!-- 流程痕迹 -->
  <List item-layout="vertical" v-if="list.length">
    <ListItem :key="item.workitemTitle" v-for="item in list">
      <ListItemMeta>
        <template slot="title">
          <template v-if="item.approveEffect === 'APOSTILLE' && item.workitemTitle.indexOf('加签意见') !== -1">
            {{ item.createrName }}
          </template>
          <template v-else>{{ item.receiverName }}</template>
        </template>
        <template slot="description">{{ item.workitemTitle }}</template>
      </ListItemMeta>
      <Row :gutter="30">
        <Col v-bind="grid">审核意见：<b style="color: blue">{{ item.approveContent }}</b></Col>
        <Col v-bind="grid">审批时间：{{ item.approveTime }}</Col>
      </Row>
      <Row class="ivu-mt-16" :gutter="30">
        <Col v-bind="grid">审批状态： {{ item.isFinish ? '已审批' : '审批中' }}</Col>
        <Col v-bind="grid">
          审核结果：
          <b style="color: red;">
            <template v-if="item.approveEffect === 'AGREE'">同意</template>
            <template v-if="item.approveEffect === 'DISAGREE'">不同意</template>
            <template v-if="item.approveEffect === 'CONSULT'">征询</template>
            <template v-if="item.approveEffect === 'LEARNED'&&item.workitemId==='LEARNED'">转发</template>
            <template v-if="item.approveEffect === 'LEARNED'&&item.workitemId!='LEARNED'">已知悉</template>
            <template v-if="item.approveEffect === 'DRAFT'"></template>
            <template v-if="item.approveEffect === 'APOSTILLE'">加签</template>
          </b>
        </Col>
        <Col v-bind="grid">上一审批人：{{ item.createrName }}</Col>
        <Col v-bind="grid">创建时间：{{ item.createTime }}</Col>
        <Col v-bind="grid">接收时间：{{ item.receiveTime }}</Col>
      </Row>
    </ListItem>
  </List>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'ProcessTracee',
    props: {
      list: {
        type: Array,
        default: () => ([])
      }
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
      grid () {
        return this.isMobile ? {span: 24} : {}
      },
    },
    methods: {
      //时间排序
      //prop：对象数组排序的键，
      //align：排序方式，"positive"正序，"inverted"倒序。
      compare(prop, align) {
        return function(a, b) {
          var value1 = a[prop]
          var value2 = b[prop]
          if (align == 'positive') {
            //正序
            return new Date(value1) - new Date(value2)
          } else if (align == 'inverted') {
            //倒序
            return new Date(value2) - new Date(value1)
          }
        }
      },
      sortFn(e) {
        let sort = e.target.dataset.sort
        this.sortActive = sort
        if (sort == 'createTimeSort') {
          this.createTimeUpSort = !this.createTimeUpSort
          if (this.list.length > 0) {
            if (this.createTimeUpSort) {
              this.list.sort(this.compare('createTime', 'positive'))
            } else {
              this.list.sort(this.compare('createTime', 'inverted'))
            }
          }
        } else if (sort == 'approveTimeSort') {
          this.approveTimeUpSort = !this.approveTimeUpSort
          if (this.list.length > 0) {
            if (this.approveTimeUpSort) {
              this.list.sort(this.compare('approveTime', 'positive'))
            } else {
              this.list.sort(this.compare('approveTime', 'inverted'))
            }
          }
        } else {
          this.receiveTimeUpSort = !this.receiveTimeUpSort
          if (this.list.length > 0) {
            if (this.receiveTimeUpSort) {
              this.list.sort(this.compare('receiveTime', 'positive'))
            } else {
              this.list.sort(this.compare('receiveTime', 'inverted'))
            }
          }
        }
      }
    },
    data() {
      return {
        sortActive: 'createTimeSort',
        approveTimeUpSort: false,
        createTimeUpSort: false,
        receiveTimeUpSort: false,
      }
    },
  }
</script>

<style scoped lang="less">
  @mainBlue: #2d8cf0;
  .GF_list-filter {
    font-size: 16px;
    overflow: hidden;
    margin-top: 30px;
    position: relative;
    -webkit-overflow-scrolling: touch;
    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      width: 100%;
      height: 0;
    }
    .summary {
      display: inline-block;
      float: left;
      span {
        color: @mainBlue;
      }
    }
    .option {
      float: right;
      a {
        display: inline-block;
        cursor: pointer;
        margin-right: 20px;
        position: relative;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.6);
        &.up:after {
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpEQ0NBNkE5OUE4Q0IxMUVCQTY2REMzNDc0Rjc5RDUyNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpEQ0NBNkE5QUE4Q0IxMUVCQTY2REMzNDc0Rjc5RDUyNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkRDQ0E2QTk3QThDQjExRUJBNjZEQzM0NzRGNzlENTI2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkRDQ0E2QTk4QThDQjExRUJBNjZEQzM0NzRGNzlENTI2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+nsE6nAAAAStJREFUeNrsmVsKwjAQRTuh4gsfiIircHV+Cy7A1bkLFV9VBKvGCeSjFKWJTekMTuDSBPJxT5rJTAhorSPOTUXMmwAIgAAIgACUarHLpNnq5DJtiVrYPqznwyAGixJtHGgh9IcxfJlryAa2b1bmWPcW0pxjQHMOYhJVoOJs/lcAUvW3CmQeOACQM+8DQNJ82VMIuAYxGfM+AEDRvG8tRMq43AcEQADoAiifA4MagDE+sVLcAIz5MaqJaqGmLn+CEoC5K3cy44tL6U4FoIfqZ8YJ6sxlC7VRo8z4hjpUUUpU0Rq5lU9RO59bX90A2T3/RG1QL455QFvzD66JbIu6c83Eexu4bEqJNHdcJqWyn8szK0Dwu0zXfq+FwVHgD+SdWAAEQAAE4K8B3gIMADyjNlNOpC9LAAAAAElFTkSuQmCC)
            no-repeat 50% scroll;
          background-size: 100%;
        }
        &.down:after {
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpENkE4NTUwNEE4Q0IxMUVCQjI2MkY4RkExNDcwREM4OSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpENkE4NTUwNUE4Q0IxMUVCQjI2MkY4RkExNDcwREM4OSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkQ2QTg1NTAyQThDQjExRUJCMjYyRjhGQTE0NzBEQzg5IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkQ2QTg1NTAzQThDQjExRUJCMjYyRjhGQTE0NzBEQzg5Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+KcwYwgAAAS5JREFUeNrsmckKwjAQhpsuVMWj20v4ioJ3H1RcoAdFLGpMZAJBGpLWVGdwBgbS0OX/MslkqZBSJpQtTYgbAzAAAzAAA3xkechNQoig25SPoHyOJdA30eaRvqPFTywAJ8RyU8mGZ/uNQEA31OKHVl2Bqgt5xM+Ul6jHgMMy5fNvtnbMLKTBF2/ib1QAChBvR+8YM/P0CVCC+Myq2ys/URgDAxiwJu1JEH9BP5FBfp9a1w/lO+VXEjOxsrFVvoP4mtJa6ACtrTPNFov4NhEwXUZABBJqAAaCl9MMwAAM4N+EgZMEkI4yCYC1Bwg9wMqx1yYBILuIxwLQJL6mOojtjRNZgFbnRCll8dgAOp3QpZTFYwH4+dmosQo8RLCMIf71Av5PzAAMwAAM8NcATwEGAGaRMSQmQZ1DAAAAAElFTkSuQmCC)
            no-repeat 50% scroll;
          background-size: 100%;
        }
      }
      a.active {
        color: @mainBlue;
        border-bottom: 2px solid @mainBlue;
        position: relative;
        padding-bottom: 10px;
      }

      .ico-sort {
        margin-right: 40px;
        &:after {
          position: absolute;
          content: '';
          top: 3px;
          bottom: 0;
          right: -19px;
          width: 20px;
          height: 14px;
          background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAbdJREFUaAXtmAlPwzAMhcclxo0mrv//6xA3A4S4eR8ikpFomqbtkkm25NVZW/e9F7d1Opm4uQKugCvgCrgCS6zAyoDYybX9m+9pwLzRVOvRvek7AX8kDwQ4s4nEofYdcIDsXn73E2X+DEFgVdcG/JbBsGHiUcO+BAB/It8cFWUkeR8Ca8p7Kl+Y2v/xQMEcg/iZ3IJ/z0nU95wcAoAGvJ29a42bbtq+GKPndyVArQOe8gl2qeAxDBa9tSq2XXuqA7hhw7vjSzHgn+XFLJUAz/djg/JT8YX8xfxXJEwlsGvQfSgG/Kv5r1iYeg9cCSFq86Q5l1cBXjj+PEkYN1koGeqfGajGUksIwJCozlJLqDrgAZATCEqU2voMjKA8ooa3fWv62mYA4LQreBK2pINaZRjmAMCzsqNhpO+iaWydiZoIsFa2a2o6XBrGqNVCYE8o9w3SB8VzM24MayDAx4CZQUh7fmvG0bBLKxFNlLmT1Z1V/k1jGsfW0gnXK03A1nxo0zv1XDWUEGKiOGuMzh8GaiHA0jRrjVEDgRuBz15XlyDAjRqMxyW+dLYjxLibK+AKuAKugCvQS4FvH2YxiGAn5MYAAAAASUVORK5CYII=")
            no-repeat 50% scroll;
          background-size: 100%;
        }
      }
    }
  }
</style>
