/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-06T11:29:37.903
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listReportFinancialRentContractItemUnfinished,
	listReportFinancialRentContractItemUnfinishedInit,
	listReportFinancialRentContractItemUnfinishedByAuthorizations,
	deleteReportFinancialRentContractItemUnfinished
	
} from '@api/report/ReportFinancialRentContractItemUnfinished';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listReportFinancialRentContractItemUnfinished({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialRentContractItemUnfinished(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportFinancialRentContractItemUnfinishedByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialRentContractItemUnfinishedByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportFinancialRentContractItemUnfinishedInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialRentContractItemUnfinishedInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteReportFinancialRentContractItemUnfinished({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportFinancialRentContractItemUnfinished({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
