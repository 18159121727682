/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T17:27:30.243
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentPurchaseOrder,
	getRentPurchaseOrderCreateInit,
	getRentPurchaseOrderEditInit,
	getRentPurchaseOrderView,
	deleteRentPurchaseOrder,
	submitRentPurchaseOrder
} from '@api/rentmanage/RentPurchaseOrder';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentPurchaseOrderDetailedInit,
listRentPurchaseOrderDetailedByAuthorizations,
deleteRentPurchaseOrderDetailed
} from '@api/rentmanage/RentPurchaseOrderDetailed';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentPurchaseOrderCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentPurchaseOrderCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		submitRentPurchaseOrder({}, RentPurchaseOrder) {
			return new Promise((resolve, reject) => {
				submitRentPurchaseOrder(RentPurchaseOrder)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentPurchaseOrderEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPurchaseOrderEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentPurchaseOrder({}, RentPurchaseOrder) {
			return new Promise((resolve, reject) => {
				saveRentPurchaseOrder(RentPurchaseOrder)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentPurchaseOrderView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPurchaseOrderView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentPurchaseOrder({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentPurchaseOrder({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
