import request from '@/plugins/request';

export function listConAttachmentInit(data){return request({
url: '/storagemanage/api/conAttachment/listConAttachmentInit',
method: 'post',
data:data,
});
}

export function listConAttachmentByAuthorizations(data){return request({
url: '/storagemanage/api/conAttachment/listConAttachmentByAuthorizations',
method: 'post',
data:data,
});
}

export function listConAttachment(data){return request({
url: '/storagemanage/api/conAttachment/listConAttachment',
method: 'post',
data:data,
});
}

export function getConAttachmentCreateInit(){return request({
url: '/storagemanage/api/conAttachment/getConAttachmentCreateInit',
method: 'post',
});
}

export function getConAttachmentEditInit(params){return request({
url: '/storagemanage/api/conAttachment/getConAttachmentEditInit',
method: 'post',
params:params,
});
}

export function getConAttachmentView(params){return request({
url: '/storagemanage/api/conAttachment/getConAttachmentView',
method: 'post',
params:params,
});
}

export function saveConAttachment(data){return request({
url: '/storagemanage/api/conAttachment/saveConAttachment',
method: 'post',
data:data,
});
}

export function deleteConAttachment(params){return request({
url: '/storagemanage/api/conAttachment/deleteConAttachment',
method: 'post',
params:params,
});
}

export function startWorkflow(data){return request({
url: '/storagemanage/api/conAttachment/startWorkflow',
method: 'post',
data:data,
});
}

export function todoListActiveWorkflow(data,params){return request({
url: '/storagemanage/api/conAttachment/todoListActiveWorkflow',
method: 'post',
data:data,
params:params,
});
}

export function refreshActiveWorkflow(data,params){return request({
url: '/storagemanage/api/conAttachment/refreshActiveWorkflow',
method: 'post',
data:data,
params:params,
});
}

export function haveDoneListActiveWorkflow(data,params){return request({
url: '/storagemanage/api/conAttachment/haveDoneListActiveWorkflow',
method: 'post',
data:data,
params:params,
});
}

export function billListActiveWorkflow(data,params){return request({
url: '/storagemanage/api/conAttachment/billListActiveWorkflow',
method: 'post',
data:data,
params:params,
});
}

export function tempStorageWorkflow(data,params){return request({
url: '/storagemanage/api/conAttachment/tempStorageWorkflow',
method: 'post',
data:data,
params:params,
});
}

export function submitWorkflow(data,params){return request({
url: '/storagemanage/api/conAttachment/submitWorkflow',
method: 'post',
data:data,
params:params,
});
}

export function rejectWorkflow(data,params){return request({
url: '/storagemanage/api/conAttachment/rejectWorkflow',
method: 'post',
data:data,
params:params,
});
}

export function restartWorkflow(data,params){return request({
url: '/storagemanage/api/conAttachment/restartWorkflow',
method: 'post',
data:data,
params:params,
});
}

export function listWorkflowHistory(params){return request({
url: '/storagemanage/api/conAttachment/listWorkflowHistory',
method: 'post',
params:params,
});
}

