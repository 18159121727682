/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-10-23T09:57:30.912
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-hrprojectparams-'

export default 
{
path: '/rentmanage/hrprojectparams',
name: 'rentmanage-hrprojectparams',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}hrprojectparams-index',

meta:{
...meta,
title: '项目参数'
},

component: () => import('@/pages/rentmanage/hrprojectparams/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}hrprojectparams-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '项目参数编辑'
},

component: () => import('@/pages/rentmanage/hrprojectparams/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}hrprojectparamsdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '项目参数新增'
},

component: () => import('@/pages/rentmanage/hrprojectparams/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}hrprojectparamsView',
auth: [
'hidden'
],

meta:{
...meta,
title: '项目参数详情查看'
},

component: () => import('@/pages/rentmanage/hrprojectparams/detail-view') ,
},
]
};
