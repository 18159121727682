/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2021-04-21T17:44:56.188
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'report-ReportWorkflowCount-'

export default {
	path: '/report/reportworkflowcount',
	name: 'report-ReportWorkflowCount',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}ReportWorkflowCount-index',

			meta: {
				...meta,
				title: '工作流程审批统计'
			},

			component: () => import('@/pages/report/reportworkflowcount/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}ReportWorkflowCount-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '工作流程审批统计编辑'
			},

			component: () => import('@/pages/report/reportworkflowcount/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}ReportWorkflowCountdetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '工作流程审批统计新增'
			},

			component: () => import('@/pages/report/reportworkflowcount/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}ReportWorkflowCountView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '工作流程审批统计详情查看'
			},

			component: () => import('@/pages/report/reportworkflowcount/detail-view'),
		},
	]
};
