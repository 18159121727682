/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-01-05T10:42:26.778
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialElectronicAccountFreezeInit(data){return request({
url: '/financial/api/financialElectronicAccountFreeze/listFinancialElectronicAccountFreezeInit',
method: 'post',
data:data,
});
}


export function listFinancialElectronicAccountFreezeByAuthorizations(data){return request({
url: '/financial/api/financialElectronicAccountFreeze/listFinancialElectronicAccountFreezeByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialElectronicAccountFreeze(data){return request({
url: '/financial/api/financialElectronicAccountFreeze/listFinancialElectronicAccountFreeze',
method: 'post',
data:data,
});
}


export function getFinancialElectronicAccountFreezeCreateInit(){return request({
url: '/financial/api/financialElectronicAccountFreeze/getFinancialElectronicAccountFreezeCreateInit',
method: 'post',
});
}


export function getFinancialElectronicAccountFreezeEditInit(params){return request({
url: '/financial/api/financialElectronicAccountFreeze/getFinancialElectronicAccountFreezeEditInit',
method: 'post',
params:params,
});
}


export function getFinancialElectronicAccountFreezeView(params){return request({
url: '/financial/api/financialElectronicAccountFreeze/getFinancialElectronicAccountFreezeView',
method: 'post',
params:params,
});
}


export function saveFinancialElectronicAccountFreeze(data){return request({
url: '/financial/api/financialElectronicAccountFreeze/saveFinancialElectronicAccountFreeze',
method: 'post',
data:data,
});
}


export function deleteFinancialElectronicAccountFreeze(params){return request({
url: '/financial/api/financialElectronicAccountFreeze/deleteFinancialElectronicAccountFreeze',
method: 'post',
params:params,
});
}


