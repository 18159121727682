/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-21T17:44:56.193
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getReportWorkflowCountEditInit,
	getReportWorkflowCountView
} from '@api/report/ReportWorkflowCount';

import {
listReportWorkflowItemCountInit,
listReportWorkflowItemCountByAuthorizations,
deleteReportWorkflowItemCount
} from '@api/report/ReportWorkflowItemCount';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getReportWorkflowCountEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportWorkflowCountEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportWorkflowCountView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportWorkflowCountView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
