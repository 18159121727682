import request from '@/plugins/request';

export function listEvaluateBillOwnerInit(data){return request({
url: '/storagemanage/api/evaluateBillOwner/listEvaluateBillOwnerInit',
method: 'post',
data:data,
});
}


export function listEvaluateBillOwnerByAuthorizations(data){return request({
url: '/storagemanage/api/evaluateBillOwner/listEvaluateBillOwnerByAuthorizations',
method: 'post',
data:data,
});
}


export function listEvaluateBillOwner(data){return request({
url: '/storagemanage/api/evaluateBillOwner/listEvaluateBillOwner',
method: 'post',
data:data,
});
}


export function getEvaluateBillOwnerCreateInit(){return request({
url: '/storagemanage/api/evaluateBillOwner/getEvaluateBillOwnerCreateInit',
method: 'post',
});
}


export function getEvaluateBillOwnerEditInit(params){return request({
url: '/storagemanage/api/evaluateBillOwner/getEvaluateBillOwnerEditInit',
method: 'post',
params:params,
});
}


export function getEvaluateBillOwnerView(params){return request({
url: '/storagemanage/api/evaluateBillOwner/getEvaluateBillOwnerView',
method: 'post',
params:params,
});
}


export function saveEvaluateBillOwner(data){return request({
url: '/storagemanage/api/evaluateBillOwner/saveEvaluateBillOwner',
method: 'post',
data:data,
});
}


export function deleteEvaluateBillOwner(params){return request({
url: '/storagemanage/api/evaluateBillOwner/deleteEvaluateBillOwner',
method: 'post',
params:params,
});
}


