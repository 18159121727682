/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-10T12:05:16.89
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinancePayApplyItem,
	listFinancePayApplyItemInit,
	listFinancePayApplyItemByAuthorizations,
	deleteFinancePayApplyItem,
	downloadPayApplyItemExcel,
	downloadPrintPayApplyItemExcel
} from '@api/storagemanage/FinancePayApplyItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
		financepayApplyDetailQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},

	actions: {
		listFinancePayApplyItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancePayApplyItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancePayApplyItemByAuthorizations({ state }, query = undefined) {
			return new Promise((resolve, reject) => {
				listFinancePayApplyItemByAuthorizations(query || state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancePayApplyItemInit({ state }, query = undefined) {
			return new Promise((resolve, reject) => {
				listFinancePayApplyItemInit(query || state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancePayApplyItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancePayApplyItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		downloadPayApplyItemExcel({
			dispatch,
			state
		}, {applyId}) {
			return new Promise((resolve, reject) => {
				downloadPayApplyItemExcel({
						applyId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		downloadPrintPayApplyItemExcel({
			dispatch,
			state
		}, {applyId}) {
			return new Promise((resolve, reject) => {
				downloadPrintPayApplyItemExcel({
						applyId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
