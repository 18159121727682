/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-14T15:25:43.923
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listRentLeaseContractReceiptPlanInit(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContractReceiptPlan/listRentLeaseContractReceiptPlanInit',
		method: 'post',
		data: data,
	});
}


export function listRentLeaseContractReceiptPlanByAuthorizations(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContractReceiptPlan/listRentLeaseContractReceiptPlanByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listRentLeaseContractReceiptPlan(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContractReceiptPlan/listRentLeaseContractReceiptPlan',
		method: 'post',
		data: data,
	});
}


export function getRentLeaseContractReceiptPlanCreateInit() {
	return request({
		url: '/rentmanage/api/rentLeaseContractReceiptPlan/getRentLeaseContractReceiptPlanCreateInit',
		method: 'post',
	});
}


export function getRentLeaseContractReceiptPlanEditInit(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContractReceiptPlan/getRentLeaseContractReceiptPlanEditInit',
		method: 'post',
		params: params,
	});
}


export function getRentLeaseContractReceiptPlanView(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContractReceiptPlan/getRentLeaseContractReceiptPlanView',
		method: 'post',
		params: params,
	});
}


export function saveRentLeaseContractReceiptPlan(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContractReceiptPlan/saveRentLeaseContractReceiptPlan',
		method: 'post',
		data: data,
	});
}


export function deleteRentLeaseContractReceiptPlan(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContractReceiptPlan/deleteRentLeaseContractReceiptPlan',
		method: 'post',
		params: params,
	});
}

export function calculatePlan(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContract/calculateLeasePlan',
		method: 'post',
		data: params,
	});
}

export function downloadTemplateReq(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/queryFirstYearDepositRentInfo',
		post: 'post',
		data: data,
		responseType: 'blob'
	});

}