import request from '@/plugins/request';

export function listStockpileIntentionInit(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/listStockpileIntentionInit',
		method: 'post',
		data: data,
	});
}


export function listStockpileIntentionByAuthorizations(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/listStockpileIntentionByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listStockpileIntention(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/listStockpileIntention',
		method: 'post',
		data: data,
	});
}
//指派商家
export function listStockpileIntentionToBusiness(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/listStockpileIntentionToBusiness',
		method: 'post',
		data: data,
	});
}

export function getStockpileIntentionCreateInit() {
	return request({
		url: '/storagemanage/api/stockpileIntention/getStockpileIntentionCreateInit',
		method: 'post',
	});
}


export function getStockpileIntentionEditInit(params) {
	return request({
		url: '/storagemanage/api/stockpileIntention/getStockpileIntentionEditInit',
		method: 'post',
		params: params,
	});
}


export function getStockpileIntentionView(params) {
	return request({
		url: '/storagemanage/api/stockpileIntention/getStockpileIntentionView',
		method: 'post',
		params: params,
	});
}


export function saveStockpileIntention(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/saveStockpileIntention',
		method: 'post',
		data: data,
	});
}

export function withdraw(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/withdraw',
		method: 'post',
		data: data,
	});
}
export function deleteStockpileIntention(params) {
	return request({
		url: '/storagemanage/api/stockpileIntention/deleteStockpileIntention',
		method: 'post',
		params: params,
	});
}
//平台批量指派给商家的列表
export function listStockpileIntentionAcceptance(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/listStockpileIntentionAcceptance',
		method: 'post',
		data: data,
	});
}
//平台批量指派给商家
export function batchAssignmentToBusiness(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/batchAssignmentToBusiness',
		method: 'post',
		data: data,
	});
}

//商家批量指派给商家专员的列表
export function listStockpileIntentionToCommissioner(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/listStockpileIntentionToCommissioner',
		method: 'post',
		data: data,
	});
}
//商家批量指派给商家专员
export function batchAssignmentToCommissioner(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/batchAssignmentToCommissioner',
		method: 'post',
		data: data,
	});
}

//存房委托指派-商家存房专员指派评估公司列表
export function listStockpileIntentionToAssessment(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/listStockpileIntentionToAssessment',
		method: 'post',
		data: data,
	});
}
//存房委托指派-商家存房专员批量指派给评估公司
export function batchAssignmentToAssessment(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/batchAssignmentToAssessment',
		method: 'post',
		data: data,
	});
}


//存房委托指评-估公司（渠道）指派评估专员列表
export function listStockpileIntentionToChannelAssessment(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/listStockpileIntentionToChannelAssessment',
		method: 'post',
		data: data,
	});
}
//存房委托指评-估公司（渠道）指派评估专员
export function batchAssignmentToChannelAssessment(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/batchAssignmentToChannelAssessment',
		method: 'post',
		data: data,
	});
}
//预约评估动态列表
export function getEvaluateAppointmentList(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/getEvaluateAppointmentList',
		method: 'post',
		data: data,
	});
}
//委托列表
export function listStockpileIntentionBySQL(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/listStockpileIntentionBySQL',
		method: 'post',
		data: data,
	});
}


