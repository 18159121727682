/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-11T10:48:26.303
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';
import { isWecaht } from '@/libs/util'

export function listRentTenantInit(data) {
    return request({
        url: '/rentmanage/api/rentTenant/listRentTenantInit',
        method: 'post',
        data: data,
    });
}


export function listRentTenantByAuthorizations(data) {
    return request({
        url: '/rentmanage/api/rentTenant/listRentTenantByAuthorizations',
        method: 'post',
        data: data,
    });
}

export function getRentTenantList(data) {
    return request({
        url: '/rentmanage/api/rentTenant/listRentTenantByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listRentTenant(data) {
    return request({
        url: '/rentmanage/api/rentTenant/listRentTenant',
        method: 'post',
        data: data,
    });
}


export function getRentTenantCreateInit() {
    return request({
        url: '/rentmanage/api/rentTenant/getRentTenantCreateInit',
        method: 'post',
    });
}


export function getRentTenantEditInit(params) {
    return request({
        url: '/rentmanage/api/rentTenant/getRentTenantEditInit',
        method: 'post',
        params: params,
        platform: isWecaht() ? 'h5' : ''
    });
}


export function getRentTenantView(params) {
    return request({
        url: '/rentmanage/api/rentTenant/getRentTenantView',
        method: 'post',
        params: params,
    });
}


export function saveRentTenant(data) {
    return request({
        url: '/rentmanage/api/rentTenant/saveRentTenant',
        method: 'post',
        data: data,
    });
}


export function deleteRentTenant(params) {
    return request({
        url: '/rentmanage/api/rentTenant/deleteRentTenant',
        method: 'post',
        params: params,
    });
}

export function authenticationTenant(params) {
    return request({
        url: '/rentmanage/api/rentTenant/authenticationTenant',
        method: 'get',
        params: params,
    });
}

export function wxAuthenticationTenant(params) {
    return request({
        url: '/rentmanage/api/rentTenant/wxAuthenticationTenant',
        method: 'get',
        params: params,
    });
}

export function telecom3FactorsBdhxMhxc2024(params) {
    return request({
        url: '/rentmanage/api/rentTenant/telecom3FactorsBdhxMhxc2024',
        method: 'get',
        params: params,
    });
}

export function authenticationTenant2(params) {
    return request({
        url: '/rentmanage/api/rentTenant/authenticationTenant2',
        method: 'get',
        params: params,
    });
}

/**
 * 四要素认证 - 获取验证码
 * @param params
 * @return {AxiosPromise<any>}
 */
export const bankCard4Factors = (params) => request.get('/rentmanage/api/rentTenant/bankCard4Factors', {params})

/**
 * 四要素认证
 * @param params
 * @return {AxiosPromise<any>}
 */
export const verifybankCard4Factors = (params) => request.get('/rentmanage/api/rentTenant/verifybankCard4Factors', {params})

export function sendFaceAuth(params) {
    return request({
        url: '/rentmanage/api/rentTenant/sendFaceAuth',
        method: 'get',
        params: params,
    });
}

export function getRentTenant(params) {
    return request({
        url: '/rentmanage/api/rentTenant/getRentTenant',
        method: 'get',
        params: params,
    });
}

export function getContractSubjectManage(params) {
    return request({
        url: '/storagemanage/api/contractSubjectManage/getContractSubjectManage',
        method: 'get',
        params: params,
    });
}
