/**
 * 注册、登录、注销
 * */
import util from '@/libs/util';
import router from '@/router';
import {
	pageInit,
	GetDataList,
	addPageInit,
	AddOrEditData,
	editPageInitReq,
	getArea,
	getAreaById,
	deteleRecordReq,
} from '@api/storagemanage/product';
import {
	formatTime
} from '@/plugins/utils';
import {
	Message,
	Notice
} from 'view-design';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
        // 初始化信息
        CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
        
		reqParams: {
			"entity": {
				"approvalStatus": "",
				"areaId": "",
				"areaName": "",
				"cityId": "",
				"cityName": "",
				"companyId": "",
				"companyName": "",
				"content": "",
				"createTime": "",
				"createrId": "",
				"createrName": "",
				"deleteState": "",
				"extField1": "",
				"lastUpdateDate": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"orgId": "",
				"orgName": "",
				"productDetail": "",
				"productId": "",
				"provinceId": "",
				"provinceName": "",
				"remark": "",
				"saasCompany": "",
				"showId": "",
				"showType": "",
				"simpleDescription": "",
				"smallLogo": "",
				"status": "",
				"streetId": "",
				"streetName": "",
				"tags": "",
				"title": "",
				"totalAmount": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 1,
			"pageSize": 10,
			"selectColumns": []
		},
		//列表初始信息
		initInfo: {
			configItems: {}
		},
		pageInitParams: {
			"entity": {
				"approvalStatus": "",
				"areaId": "",
				"areaName": "",
				"cityId": "",
				"cityName": "",
				"companyId": "",
				"companyName": "",
				"content": "",
				"createTime": "",
				"createrId": "",
				"createrName": "",
				"deleteState": "",
				"extField1": "",
				"lastUpdateDate": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"orgId": "",
				"orgName": "",
				"productDetail": "",
				"productId": "",
				"provinceId": "",
				"provinceName": "",
				"remark": "",
				"saasCompany": "",
				"showId": "",
				"showType": "",
				"simpleDescription": "",
				"smallLogo": "",
				"status": "",
				"streetId": "",
				"streetName": "",
				"tags": "",
				"title": "",
				"totalAmount": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 1,
			"pageSize": 10,
			"selectColumns": []
		},

		getAreaParams: {
			"entity": {
				"areaLevel": "",
				"areaName": "",
				"areaNum": "",
				"createId": "",
				"createName": "",
				"createTime": "",
				"deleteState": 0,
				"descript": "",
				"id": "",
				"isDirectly": "",
				"lastUpdateDate": "",
				"latitude": "",
				"longitude": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"parentId": "",
				"saasCompany": "",
				"serialNo": "",
				"shortName": "",
				"tmpId": "",
				"tmpLongName": "",
				"tmpOldId": "",
				"tmpOldParentId": "",
				"tmpParentId": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 0,
			"pageSize": 0,
			"selectColumns": []
		},
	},
	actions: {
		pageInit({
			state,
			dispatch
		}) {
			return new Promise((resolve, reject) => {
				pageInit(state.pageInitParams)
					.then(async res => {
						// state.initInfo = res;
                        state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getListData({
			dispatch,
			state,
		}) {
			state.reqParams.outerWhereSubjoin.startDate = formatTime(state.reqParams.outerWhereSubjoin.startDate,
				'YYYY-MM-DD HH:mm:ss')
			state.reqParams.outerWhereSubjoin.endDate = formatTime(state.reqParams.outerWhereSubjoin.endDate,
				'YYYY-MM-DD HH:mm:ss')
			return new Promise((resolve, reject) => {
				GetDataList(state.reqParams)
					.then(async res => {
						// state.listTreeDataObj = res
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		addPageInit({
			state,
			dispatch
		}, info) {
			return new Promise((resolve, reject) => {
				addPageInit({})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		editPageInit({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				editPageInitReq({
						id: id
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getArea({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				getArea(state.getAreaParams)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getAreaById({
			state,
			dispatch
		}, params) {
			return new Promise((resolve, reject) => {
				getAreaById(params)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		deteleRecord({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				deteleRecordReq({
						id: id
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		submitDataEdit({
			state,
			dispatch
		}, data) {
			data.companyEstablishedTime = formatTime(data.companyEstablishedTime, 'YYYY-MM-DD')
			data.licencePeriod = formatTime(data.licencePeriod, 'YYYY-MM-DD')
			return new Promise((resolve, reject) => {
				AddOrEditData(data)
					.then(async res => {
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
	},
	mutations: {
		updateParentReqParams(state, obj) {
			state.parentParams['entity'][obj.key] = obj.value;
		},
		updateChildReqParams(state, obj) {
			state.childParams['entity'][obj.key] = obj.value;
		},
		resetChildReqParams(state, obj) {
			state.childParams = {
				"entity": {
					"groupType": "",
					"id": "",
					"lastUpdateDate": "",
					"nodeType": "",
					"operatorId": "",
					"operatorLongName": "",
					"operatorName": "",
					"operatorTime": "",
					"parentId": "",
					"remark": "",
					"saasCompany": "",
					"treeCode": "",
					"treeName": ""
				},
				"isOrder": true,
				"isOrderAsc": true,
				"isPage": true,
				"orderColumn": "",
				"outerWhereSubjoin": {
					"fuzzyMatching": ""
				},
				"pageIndex": 1,
				"pageSize": 10,
				"selectColumns": []
			}
		},
	}
};
