/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-21T03:43:01.819
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveManagementAreaChannel,
	getManagementAreaChannelCreateInit,
	getManagementAreaChannelEditInit,
	getManagementAreaChannelView,
	deleteManagementAreaChannel
} from '@api/storagemanage/ManagementAreaChannel';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getManagementAreaChannelCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getManagementAreaChannelCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getManagementAreaChannelEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getManagementAreaChannelEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveManagementAreaChannel({}, ManagementAreaChannel) {
			return new Promise((resolve, reject) => {
				saveManagementAreaChannel(ManagementAreaChannel)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getManagementAreaChannelView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getManagementAreaChannelView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteManagementAreaChannel({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteManagementAreaChannel({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
