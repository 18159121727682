/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-10-13T17:19:55.603
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentSerComplaintEvaluateInit(data){return request({
url: '/rentmanage/api/rentSerComplaintEvaluate/listRentSerComplaintEvaluateInit',
method: 'post',
data:data,
});
}


export function listRentSerComplaintEvaluateByAuthorizations(data){return request({
url: '/rentmanage/api/rentSerComplaintEvaluate/listRentSerComplaintEvaluateByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentSerComplaintEvaluate(data){return request({
url: '/rentmanage/api/rentSerComplaintEvaluate/listRentSerComplaintEvaluate',
method: 'post',
data:data,
});
}


export function getRentSerComplaintEvaluateCreateInit(){return request({
url: '/rentmanage/api/rentSerComplaintEvaluate/getRentSerComplaintEvaluateCreateInit',
method: 'post',
});
}


export function getRentSerComplaintEvaluateEditInit(params){return request({
url: '/rentmanage/api/rentSerComplaintEvaluate/getRentSerComplaintEvaluateEditInit',
method: 'post',
params:params,
});
}


export function getRentSerComplaintEvaluateView(params){return request({
url: '/rentmanage/api/rentSerComplaintEvaluate/getRentSerComplaintEvaluateView',
method: 'post',
params:params,
});
}


export function saveRentSerComplaintEvaluate(data){return request({
url: '/rentmanage/api/rentSerComplaintEvaluate/saveRentSerComplaintEvaluate',
method: 'post',
data:data,
});
}


export function deleteRentSerComplaintEvaluate(params){return request({
url: '/rentmanage/api/rentSerComplaintEvaluate/deleteRentSerComplaintEvaluate',
method: 'post',
params:params,
});
}


