/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-26T10:02:19.885
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getRentFacilityTestEditInit,
	getRentFacilityTestView
} from '@api/rentmanage/RentFacilityTest';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		getRentFacilityTestEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentFacilityTestEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentFacilityTestView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentFacilityTestView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
