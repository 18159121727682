import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	name: 'khm-4',
	redirect: {
		name: `${pre}houseSaveFollowManagement-list`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'houseSaveFollowManagement-list',
		name: `${pre}houseSaveFollowManagement-list`,
		meta: {
			...meta,
			title: '跟进情况',
			closable: true
		},
		component: () => import('@/pages/storagemanage/houseSaveFollowManagement/index')
	},{
		path: 'houseSaveFollowManagement-addOrEdit',
		name: `${pre}houseSaveFollowManagement-addOrEdit`,
		meta: {
			...meta,
			title: '新建跟进',
			closable: true
		},
		component: () => import('@/pages/storagemanage/houseSaveFollowManagement/edit')
	},{
		path: 'houseSaveFollowManagement-check',
		name: `${pre}houseSaveFollowManagement-check`,
		meta: {
			...meta,
			title: '查看跟进',
			closable: true
		},
		component: () => import('@/pages/storagemanage/houseSaveFollowManagement/check')
	}, ]
};
