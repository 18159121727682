/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T10:40:36.015
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialEvidenceInit(data){return request({
url: '/financial/api/financialEvidence/listFinancialEvidenceInit',
method: 'post',
data:data,
});
}


export function listFinancialEvidenceByAuthorizations(data){return request({
url: '/financial/api/financialEvidence/listFinancialEvidenceByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialEvidence(data){return request({
url: '/financial/api/financialEvidence/listFinancialEvidence',
method: 'post',
data:data,
});
}


export function getFinancialEvidenceCreateInit(){return request({
url: '/financial/api/financialEvidence/getFinancialEvidenceCreateInit',
method: 'post',
});
}


export function getFinancialEvidenceEditInit(params){return request({
url: '/financial/api/financialEvidence/getFinancialEvidenceEditInit',
method: 'post',
params:params,
});
}


export function getFinancialEvidenceView(params){return request({
url: '/financial/api/financialEvidence/getFinancialEvidenceView',
method: 'post',
params:params,
});
}


export function saveFinancialEvidence(data){return request({
url: '/financial/api/financialEvidence/saveFinancialEvidence',
method: 'post',
data:data,
});
}


export function deleteFinancialEvidence(params){return request({
url: '/financial/api/financialEvidence/deleteFinancialEvidence',
method: 'post',
params:params,
});
}


