/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-01T17:31:37.011
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialAdjustmentApplyItemInit(data){return request({
url: '/financial/api/financialAdjustmentApplyItem/listFinancialAdjustmentApplyItemInit',
method: 'post',
data:data,
});
}


export function listFinancialAdjustmentApplyItemByAuthorizations(data){return request({
url: '/financial/api/financialAdjustmentApplyItem/listFinancialAdjustmentApplyItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialAdjustmentApplyItem(data){return request({
url: '/financial/api/financialAdjustmentApplyItem/listFinancialAdjustmentApplyItem',
method: 'post',
data:data,
});
}


export function getFinancialAdjustmentApplyItemCreateInit(){return request({
url: '/financial/api/financialAdjustmentApplyItem/getFinancialAdjustmentApplyItemCreateInit',
method: 'post',
});
}


export function getFinancialAdjustmentApplyItemEditInit(params){return request({
url: '/financial/api/financialAdjustmentApplyItem/getFinancialAdjustmentApplyItemEditInit',
method: 'post',
params:params,
});
}


export function getFinancialAdjustmentApplyItemView(params){return request({
url: '/financial/api/financialAdjustmentApplyItem/getFinancialAdjustmentApplyItemView',
method: 'post',
params:params,
});
}


export function saveFinancialAdjustmentApplyItem(data){return request({
url: '/financial/api/financialAdjustmentApplyItem/saveFinancialAdjustmentApplyItem',
method: 'post',
data:data,
});
}


export function deleteFinancialAdjustmentApplyItem(params){return request({
url: '/financial/api/financialAdjustmentApplyItem/deleteFinancialAdjustmentApplyItem',
method: 'post',
params:params,
});
}


