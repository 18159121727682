import util from '@/libs/util';
import router from '@/router';
import {
	saveConAttachment,
	getConAttachmentCreateInit,
	getConAttachmentEditInit,
	getConAttachmentView,
	deleteConAttachment
} from '@api/storagemanage/ConAttachment';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getConAttachmentCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getConAttachmentCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getConAttachmentEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getConAttachmentEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveConAttachment({}, ConAttachment) {
			return new Promise((resolve, reject) => {
				saveConAttachment(ConAttachment)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getConAttachmentView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getConAttachmentView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteConAttachment({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteConAttachment({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
