/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-28T14:19:44.388
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancialCollectionItem,
	getFinancialCollectionItemCreateInit,
	getFinancialCollectionItemEditInit,
	getFinancialCollectionItemView,
	deleteFinancialCollectionItem
} from '@api/financial/FinancialCollectionItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listFinancialCollectionDetailInit,
listFinancialCollectionDetailByAuthorizations,
deleteFinancialCollectionDetail
} from '@api/financial/FinancialCollectionDetail';

import {
listFinancialCollectionCoupontInit,
listFinancialCollectionCoupontByAuthorizations,
deleteFinancialCollectionCoupont
} from '@api/financial/FinancialCollectionCoupont';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialCollectionItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialCollectionItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialCollectionItem({}, FinancialCollectionItem) {
			return new Promise((resolve, reject) => {
				saveFinancialCollectionItem(FinancialCollectionItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialCollectionItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialCollectionItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialCollectionItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
