/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T11:12:57.642
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listChannel,
	listChannelInit,
	listChannelByAuthorizations,
	deleteChannel,
	synchronizationChannel
	
} from '@api/rentmanage/Channel';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
		//房源列表查询参数
		reqParams: {
			entity: {
				"houseRentCode": "",
				"houseStorageCode": "",
				"houseNo": "",
				"houseStorageAddress": "",
				"projectName": "",
				"billCode": "",
				"billDate": "",
				"billSource": "",
				"houseState": "",
				"bizCode": "",
				"bizName": "",
				"bizType": "",
				"branchId": "",
				"branchName": "",
				"channelSource": "",
				"companyId": "",
				"companyName": "",
				"createrId": "",
				"createrName": "",
				"createrTime": "",
				"deleteState": 0,
				"descript": "",
				"houseCode": "",
				"houseId": "",
				"housePlaneStructure": "",
				"houseStairsType": "",
				"houseType": "",
				"id": "",
				"isOrder": true,
				"isOrderAsc": true,
				"isPage": true,
				"lastUpdateDate": "",
				"limitTime": 0,
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"operatorType": "",
				"orderColumn": "",
				"orgId": "",
				"orgName": "",
				"ownerId": "",
				"ownerMobile": "",
				"ownerName": "",
				"ownerSex": "",
				"ownerType": "",
				"pageIndex": 0,
				"pageSize": 0,
				"proxyChannelName": "",
				"proxyChannelType": "",
				"proxyLinkmanName": "",
				"proxyLinkmanSex": "",
				"proxyLinkmanTel": "",
				"recommendMobile": "",
				"recommendationReason": "",
				"rentLeft": 0,
				"rentPayType": "",
				"rentRight": 0,
				"rentStoreYear": 0,
				"rentType": "",
				"roomFullName": "",
				"saasApplication": "",
				"saasCompany": "",
				"schemeResultType": "",
				"schemeStoreType": "",
				"selectColumns": [],
				"theIsProxy": "",
				"visitTimeType": ""
			},
			"innerWhere": {
				"saasCompany": "",
				"userId": ""
			},
			"isOrder": false,
			"isOrderAsc": false,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 1,
			"pageSize": 10,
			"selectColumns": [], 
		},
	},

	actions: {
		listChannel({
			state
		}) {
			return new Promise((resolve, reject) => {
				listChannel(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listChannelByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listChannelByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listChannelInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listChannelInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
                        state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		synchronizationChannel({
			state
		}) {
			return new Promise((resolve, reject) => {
				synchronizationChannel()
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteChannel({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteChannel({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
