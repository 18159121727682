/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-06-23T10:15:33.259
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportRentHouseBigItemInit(data){return request({
url: '/rentmanage/api/reportRentHouseBigItem/listReportRentHouseBigItemInit',
method: 'post',
data:data,
});
}


export function listReportRentHouseBigItemByAuthorizations(data){return request({
url: '/rentmanage/api/reportRentHouseBigItem/listReportRentHouseBigItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportRentHouseBigItem(data){return request({
url: '/rentmanage/api/reportRentHouseBigItem/listReportRentHouseBigItem',
method: 'post',
data:data,
});
}


export function getReportRentHouseBigItemCreateInit(){return request({
url: '/rentmanage/api/reportRentHouseBigItem/getReportRentHouseBigItemCreateInit',
method: 'post',
});
}


export function getReportRentHouseBigItemEditInit(params){return request({
url: '/rentmanage/api/reportRentHouseBigItem/getReportRentHouseBigItemEditInit',
method: 'post',
params:params,
});
}


export function getReportRentHouseBigItemView(params){return request({
url: '/rentmanage/api/reportRentHouseBigItem/getReportRentHouseBigItemView',
method: 'post',
params:params,
});
}


export function saveReportRentHouseBigItem(data){return request({
url: '/rentmanage/api/reportRentHouseBigItem/saveReportRentHouseBigItem',
method: 'post',
data:data,
});
}


export function deleteReportRentHouseBigItem(params){return request({
url: '/rentmanage/api/reportRentHouseBigItem/deleteReportRentHouseBigItem',
method: 'post',
params:params,
});
}


