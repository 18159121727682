import BasicLayout from '@/layouts/basic-layout';
////第一步：配置菜单-->第二步：配置存房委托指派商家页面相关的路由-->第三步：注入路由-->第四步：做页面
const meta = {
	auth: true
};

const pre = 'rentmanage-rentHouse';

export default {
	path: '/rentmanage/renthouselockpassword',
	name: '门锁密码',
	redirect: {
		name: `${pre}rentHouseLockPassWordlist`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'index',
		name: `${pre}rentHouseLockPassWordlist`,
		meta: {
			...meta,
			title: '门锁密码',
			closable: true
		},
		component: () => import('@/pages/rentmanage/renthouselockpassword/index')
	}]
};
