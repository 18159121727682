/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T09:30:03.317
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancialPayables,
	getFinancialPayablesCreateInit,
	getFinancialPayablesEditInit,
	getFinancialPayablesView,
	deleteFinancialPayables
} from '@api/financial/FinancialPayables';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listFinancialPayablesItemInit,
listFinancialPayablesItemByAuthorizations,
deleteFinancialPayablesItem
} from '@api/financial/FinancialPayablesItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialPayablesCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayablesCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialPayablesEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayablesEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialPayables({}, FinancialPayables) {
			return new Promise((resolve, reject) => {
				saveFinancialPayables(FinancialPayables)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialPayablesView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayablesView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialPayables({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialPayables({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
