import util from '@/libs/util';
import router from '@/router';
import {
	saveMenuTreemManage,
	getMenuTreemManageCreateInit,
	getMenuTreemManageEditInit,
	getMenuTreemManageView,
	deleteMenuTreemManage,
	listMenuTreemManageByAuthorizations
} from '@api/systemmanage/MenuTreemManage';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
		Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},
	actions: {
		getMenuTreemManageCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getMenuTreemManageCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getMenuTreemManageEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getMenuTreemManageEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveMenuTreemManage({}, MenuTreemManage) {
			return new Promise((resolve, reject) => {
				saveMenuTreemManage(MenuTreemManage)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getMenuTreemManageView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getMenuTreemManageView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		listMenuTreemManageByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				state.AdvanceQuery.isPage = false;
				listMenuTreemManageByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		
		deleteMenuTreemManage({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteMenuTreemManage({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
