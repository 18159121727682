import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	redirect: {
		name: `${pre}product-list`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'product-list',
		name: `${pre}product-list`,
		meta: {
			...meta,
			title: '产品案例管理',
			closable: true
		},
		component: () => import('@/pages/storagemanage/product/index')
	},{
		path: 'product-check',
		name: `${pre}product-check`,
		meta: {
			...meta,
			title: '查看产品案例',
			closable: true
		},
		component: () => import('@/pages/storagemanage/product/check')
	}, ]
};
