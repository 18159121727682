/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T16:23:43.423
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listEnergyRecordItem,
	listEnergyRecordItemInit,
	listEnergyRecordItemByAuthorizations,
	deleteEnergyRecordItem
} from '@api/rentmanage/EnergyRecordItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listEnergyRecordItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEnergyRecordItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEnergyRecordItemByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEnergyRecordItemByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEnergyRecordItemInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEnergyRecordItemInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
                        state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEnergyRecordItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEnergyRecordItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
