/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-18T17:50:47.985
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-KhmPayDeductionApply-'

export default 
{
path: '/storagemanage/khmpaydeductionapply',
name: 'storagemanage-KhmPayDeductionApply',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}KhmPayDeductionApply-index',

meta:{
...meta,
title: '存房合同付款抵扣申请'
},

component: () => import('@/pages/storagemanage/khmpaydeductionapply/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}KhmPayDeductionApply-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '存房合同付款抵扣申请编辑'
},

component: () => import('@/pages/storagemanage/khmpaydeductionapply/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}KhmPayDeductionApplydetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '存房合同付款抵扣申请新增'
},

component: () => import('@/pages/storagemanage/khmpaydeductionapply/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}KhmPayDeductionApplyView',
auth: [
'hidden'
],

meta:{
...meta,
title: '存房合同付款抵扣申请详情查看'
},

component: () => import('@/pages/storagemanage/khmpaydeductionapply/detail-view') ,
},
]
};
