/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-19T17:51:38.558
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentRepairWorkOrderRelation,
	listRentRepairWorkOrderRelationInit,
	listRentRepairWorkOrderRelationByAuthorizations,
	deleteRentRepairWorkOrderRelation
	
} from '@api/rentmanage/RentRepairWorkOrderRelation';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentRepairWorkOrderRelation({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderRelation(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentRepairWorkOrderRelationByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderRelationByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentRepairWorkOrderRelationInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderRelationInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentRepairWorkOrderRelation({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentRepairWorkOrderRelation({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
