/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-24T17:21:46.995
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHousePicInit(data){return request({
url: '/rentmanage/api/rentHousePic/listRentHousePicInit',
method: 'post',
data:data,
});
}


export function listRentHousePicByAuthorizations(data){return request({
url: '/rentmanage/api/rentHousePic/listRentHousePicByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHousePic(data){return request({
url: '/rentmanage/api/rentHousePic/listRentHousePic',
method: 'post',
data:data,
});
}


export function getRentHousePicCreateInit(){return request({
url: '/rentmanage/api/rentHousePic/getRentHousePicCreateInit',
method: 'post',
});
}


export function getRentHousePicEditInit(params){return request({
url: '/rentmanage/api/rentHousePic/getRentHousePicEditInit',
method: 'post',
params:params,
});
}


export function getRentHousePicView(params){return request({
url: '/rentmanage/api/rentHousePic/getRentHousePicView',
method: 'post',
params:params,
});
}


export function saveRentHousePic(data){return request({
url: '/rentmanage/api/rentHousePic/saveRentHousePic',
method: 'post',
data:data,
});
}


export function deleteRentHousePic(params){return request({
url: '/rentmanage/api/rentHousePic/deleteRentHousePic',
method: 'post',
params:params,
});
}


