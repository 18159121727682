/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-12T09:55:24.118
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportPlaceorderInit(data){return request({
url: '/report/api/reportPlaceorder/listReportPlaceorderInit',
method: 'post',
data:data,
});
}


export function listReportPlaceorderByAuthorizations(data){return request({
url: '/report/api/reportPlaceorder/listReportPlaceorderByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportPlaceorder(data){return request({
url: '/report/api/reportPlaceorder/listReportPlaceorder',
method: 'post',
data:data,
});
}


export function getReportPlaceorderCreateInit(){return request({
url: '/report/api/reportPlaceorder/getReportPlaceorderCreateInit',
method: 'post',
});
}


export function getReportPlaceorderEditInit(params){return request({
url: '/report/api/reportPlaceorder/getReportPlaceorderEditInit',
method: 'post',
params:params,
});
}


export function getReportPlaceorderView(params){return request({
url: '/report/api/reportPlaceorder/getReportPlaceorderView',
method: 'post',
params:params,
});
}


export function saveReportPlaceorder(data){return request({
url: '/report/api/reportPlaceorder/saveReportPlaceorder',
method: 'post',
data:data,
});
}


export function deleteReportPlaceorder(params){return request({
url: '/report/api/reportPlaceorder/deleteReportPlaceorder',
method: 'post',
params:params,
});
}


