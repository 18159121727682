/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T17:27:30.214
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentPurchaseOrderInit(data){return request({
url: '/rentmanage/api/rentPurchaseOrder/listRentPurchaseOrderInit',
method: 'post',
data:data,
});
}


export function listRentPurchaseOrderByAuthorizations(data){return request({
url: '/rentmanage/api/rentPurchaseOrder/listRentPurchaseOrderByAuthorizations',
method: 'post',
data:data,
});
}

export function submitRentPurchaseOrder(data){return request({
	url: '/rentmanage/api/rentPurchaseOrder/submitRentPurchaseOrder',
	method: 'post',
	data:data,
	});
	}

export function listRentPurchaseOrder(data){return request({
url: '/rentmanage/api/rentPurchaseOrder/listRentPurchaseOrder',
method: 'post',
data:data,
});
}


export function getRentPurchaseOrderCreateInit(){return request({
url: '/rentmanage/api/rentPurchaseOrder/getRentPurchaseOrderCreateInit',
method: 'post',
});
}


export function getRentPurchaseOrderEditInit(params){return request({
url: '/rentmanage/api/rentPurchaseOrder/getRentPurchaseOrderEditInit',
method: 'post',
params:params,
});
}


export function getRentPurchaseOrderView(params){return request({
url: '/rentmanage/api/rentPurchaseOrder/getRentPurchaseOrderView',
method: 'post',
params:params,
});
}


export function saveRentPurchaseOrder(data){return request({
url: '/rentmanage/api/rentPurchaseOrder/saveRentPurchaseOrder',
method: 'post',
data:data,
});
}


export function deleteRentPurchaseOrder(params){return request({
url: '/rentmanage/api/rentPurchaseOrder/deleteRentPurchaseOrder',
method: 'post',
params:params,
});
}


