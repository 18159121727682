/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-03T10:29:11.909
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentPublishChannelConfigInit(data){return request({
url: '/rentmanage/api/rentPublishChannelConfig/listRentPublishChannelConfigInit',
method: 'post',
data:data,
});
}


export function listRentPublishChannelConfigByAuthorizations(data){return request({
url: '/rentmanage/api/rentPublishChannelConfig/listRentPublishChannelConfigByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentPublishChannelConfig(data){return request({
url: '/rentmanage/api/rentPublishChannelConfig/listRentPublishChannelConfig',
method: 'post',
data:data,
});
}


export function getRentPublishChannelConfigCreateInit(){return request({
url: '/rentmanage/api/rentPublishChannelConfig/getRentPublishChannelConfigCreateInit',
method: 'post',
});
}


export function getRentPublishChannelConfigEditInit(params){return request({
url: '/rentmanage/api/rentPublishChannelConfig/getRentPublishChannelConfigEditInit',
method: 'post',
params:params,
});
}


export function getRentPublishChannelConfigView(params){return request({
url: '/rentmanage/api/rentPublishChannelConfig/getRentPublishChannelConfigView',
method: 'post',
params:params,
});
}


export function saveRentPublishChannelConfig(data){return request({
url: '/rentmanage/api/rentPublishChannelConfig/saveRentPublishChannelConfig',
method: 'post',
data:data,
});
}


export function deleteRentPublishChannelConfig(params){return request({
url: '/rentmanage/api/rentPublishChannelConfig/deleteRentPublishChannelConfig',
method: 'post',
params:params,
});
}


