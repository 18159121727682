/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-13T16:30:31.516
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
  listEnergyRecord,
  listEnergyRecordInit,
  listEnergyRecordByAuthorizations,
  deleteEnergyRecord,
  cancelEnergyRecord
} from '@api/rentmanage/EnergyRecord';

import {
  cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
  namespaced: true,
  state: {
    // 初始化信息
    CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
    //查询接口返回的数据
    CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
    Entities: cloneObj(RespCommonDef.Entities),
    //查询条件
    AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
  },

  actions: {
    listEnergyRecord({
      state
    }) {
      return new Promise((resolve, reject) => {
        listEnergyRecord(state.AdvanceQuery)
          .then(async res => {
            state.Entities = res;
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
      })
    },

    listEnergyRecordByAuthorizations({
      state
    }) {
      return new Promise((resolve, reject) => {
        listEnergyRecordByAuthorizations(state.AdvanceQuery)
          .then(async res => {
            state.CompoundEntities = res
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
      })
    },

    listEnergyRecordInit({
      state
    }) {
      return new Promise((resolve, reject) => {
        listEnergyRecordInit(state.AdvanceQuery)
          .then(async res => {
            state.CompoundEntitiesDictionary = res;
            state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
      })
    },

    deleteEnergyRecord({
      state
    }, { id }) {
      return new Promise((resolve, reject) => {
        deleteEnergyRecord({
          id
        })
          .then(async res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
      })
    },

    cancelEnergyRecord({
      state
    }, { id }) {
      return new Promise((resolve, reject) => {
        cancelEnergyRecord({
          id
        })
          .then(async res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
      })
    },

  }
};
