/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2021-03-25T16:40:15.459
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'report-reportfinancialpaymentpostpone-'

export default {
	path: '/report/reportfinancialpaymentpostpone',
	name: 'report-reportfinancialpaymentpostpone',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}reportfinancialpaymentpostpone-index',

			meta: {
				...meta,
				title: '应收预警报表报表'
			},

			component: () => import('@/pages/report/reportfinancialpaymentpostpone/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}reportfinancialpaymentpostpone-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '应收预警报表编辑'
			},

			component: () => import('@/pages/report/reportfinancialpaymentpostpone/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}reportfinancialpaymentpostponedetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '应收预警报表新增'
			},

			component: () => import('@/pages/report/reportfinancialpaymentpostpone/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}reportfinancialpaymentpostponeView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '应收预警报表详情查看'
			},

			component: () => import('@/pages/report/reportfinancialpaymentpostpone/detail-view'),
		},
	]
};
