import util from '@/libs/util';
import router from '@/router';
import {
	saveEvaluateBill,
	getEvaluateBillCreateInit,
	getEvaluateBillEditInit,
	getEvaluateBillView,
	deleteEvaluateBill,
	submitEvaluateBill
} from '@api/storagemanage/EvaluateBill';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listEvaluateBillAttachmentInit,
listEvaluateBillAttachmentByAuthorizations,
deleteEvaluateBillAttachment
} from '@api/storagemanage/EvaluateBillAttachment';

import {
listEvaluateBillHouseInit,
listEvaluateBillHouseByAuthorizations,
deleteEvaluateBillHouse
} from '@api/storagemanage/EvaluateBillHouse';

import {
listEvaluateBillOwnerInit,
listEvaluateBillOwnerByAuthorizations,
deleteEvaluateBillOwner
} from '@api/storagemanage/EvaluateBillOwner';

import {
listEvaluateBillRemouldInit,
listEvaluateBillRemouldByAuthorizations,
deleteEvaluateBillRemould
} from '@api/storagemanage/EvaluateBillRemould';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getEvaluateBillCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateBillEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveEvaluateBill({}, EvaluateBill) {
			return new Promise((resolve, reject) => {
				saveEvaluateBill(EvaluateBill)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		submitEvaluateBill({}, EvaluateBill) {
			return new Promise((resolve, reject) => {
				submitEvaluateBill(EvaluateBill)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getEvaluateBillView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateBill({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateBill({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
