/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-31T10:17:51.27
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentRepairFeedBackCleaning,
	getRentRepairFeedBackCleaningCreateInit,
	getRentRepairFeedBackCleaningEditInit,
	getRentRepairFeedBackCleaningView,
	deleteRentRepairFeedBackCleaning
} from '@api/rentmanage/RentRepairFeedBackCleaning';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentRepairFeedBackCleaningCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairFeedBackCleaningCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentRepairFeedBackCleaningEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairFeedBackCleaningEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentRepairFeedBackCleaning({}, RentRepairFeedBackCleaning) {
			return new Promise((resolve, reject) => {
				saveRentRepairFeedBackCleaning(RentRepairFeedBackCleaning)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentRepairFeedBackCleaningView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairFeedBackCleaningView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentRepairFeedBackCleaning({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentRepairFeedBackCleaning({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
