/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-20T19:25:48.986
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentHouseTraffic,
	listRentHouseTrafficInit,
	listRentHouseTrafficByAuthorizations,
	deleteRentHouseTraffic
	
} from '@api/rentmanage/RentHouseTraffic';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentHouseTraffic({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseTraffic(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseTrafficByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseTrafficByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseTrafficInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseTrafficInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseTraffic({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseTraffic({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
