import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	redirect: {
		name: `${pre}merchant-list`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'merchant-list',
		name: `${pre}merchant-list`,
		meta: {
			...meta,
			title: '商家管理',
			closable: true
		},
		component: () => import('@/pages/storagemanage/merchant/index')
	}, {
		path: 'merchant-addOrEdit',
		name: `${pre}merchant-addOrEdit`,
		meta: {
			...meta,
			title: '商家管理编辑',
			closable: true
		},
		component: () => import('@/pages/storagemanage/merchant/edit')
	}, {
		path: 'merchant-check',
		name: `${pre}merchant-check`,
		meta: {
			...meta,
			title: '商家管理查看',
			closable: true
		},
		component: () => import('@/pages/storagemanage/merchant/check')
	}, ]
};
