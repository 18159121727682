/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-25T16:40:15.477
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportFinancialPaymentPostpone,
	getReportFinancialPaymentPostponeCreateInit,
	getReportFinancialPaymentPostponeEditInit,
	getReportFinancialPaymentPostponeView,
	deleteReportFinancialPaymentPostpone
} from '@api/report/ReportFinancialPaymentPostpone';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportFinancialPaymentPostponeCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialPaymentPostponeCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportFinancialPaymentPostponeEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialPaymentPostponeEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportFinancialPaymentPostpone({}, ReportFinancialPaymentPostpone) {
			return new Promise((resolve, reject) => {
				saveReportFinancialPaymentPostpone(ReportFinancialPaymentPostpone)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportFinancialPaymentPostponeView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialPaymentPostponeView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportFinancialPaymentPostpone({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportFinancialPaymentPostpone({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
