/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-28T14:14:36.613
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getFinancialCollectionEditInit,
	getFinancialCollectionView
} from '@api/financial/FinancialCollection';

import {
listFinancialCollectionItemInit,
listFinancialCollectionItemByAuthorizations,
deleteFinancialCollectionItem
} from '@api/financial/FinancialCollectionItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getFinancialCollectionEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialCollectionView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
