/**
 * @version GENERATOR_VERSION::20200422
 * @DateTime 2020-05-01T18:25:53.511
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listOrganizationManageInit(data) {
	return request({
		url: '/systemmanage/api/organizationManage/listOrganizationManageInit',
		method: 'post',
		data: data,
	});
}


export function listOrganizationManageByAuthorizations(data) {
	return request({
		url: '/systemmanage/api/organizationManage/listOrganizationManageByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listOrganizationManage(data) {
	return request({
		url: '/systemmanage/api/organizationManage/listOrganizationManage',
		method: 'post',
		data: data,
	});
}


export function getOrganizationManageCreateInit() {
	return request({
		url: '/systemmanage/api/organizationManage/getOrganizationManageCreateInit',
		method: 'post',
	});
}


export function getOrganizationManageEditInit(params) {
	return request({
		url: '/systemmanage/api/organizationManage/getOrganizationManageEditInit',
		method: 'post',
		params: params,
	});
}


export function getOrganizationManageView(params) {
	return request({
		url: '/systemmanage/api/organizationManage/getOrganizationManageView',
		method: 'post',
		params: params,
	});
}


export function saveOrganizationManage(data) {
	return request({
		url: '/systemmanage/api/organizationManage/saveOrganizationManage',
		method: 'post',
		data: data,
	});
}


export function deleteOrganizationManage(params) {
	return request({
		url: '/systemmanage/api/organizationManage/deleteOrganizationManage',
		method: 'post',
		params: params,
	});
}

export function getTreeData(params) {
	return request({
		url: '/systemmanage/api/organizationManage/getOrganizationTree',
		method: 'post',
		data: params,
	});
}