/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-12T10:12:57.94
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialTransferInit(data){return request({
url: '/report/api/reportFinancialTransfer/listReportFinancialTransferInit',
method: 'post',
data:data,
});
}


export function listReportFinancialTransferByAuthorizations(data){return request({
url: '/report/api/reportFinancialTransfer/listReportFinancialTransferByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportFinancialTransfer(data){return request({
url: '/report/api/reportFinancialTransfer/listReportFinancialTransfer',
method: 'post',
data:data,
});
}


export function getReportFinancialTransferCreateInit(){return request({
url: '/report/api/reportFinancialTransfer/getReportFinancialTransferCreateInit',
method: 'post',
});
}


export function getReportFinancialTransferEditInit(params){return request({
url: '/report/api/reportFinancialTransfer/getReportFinancialTransferEditInit',
method: 'post',
params:params,
});
}


export function getReportFinancialTransferView(params){return request({
url: '/report/api/reportFinancialTransfer/getReportFinancialTransferView',
method: 'post',
params:params,
});
}


export function saveReportFinancialTransfer(data){return request({
url: '/report/api/reportFinancialTransfer/saveReportFinancialTransfer',
method: 'post',
data:data,
});
}


export function deleteReportFinancialTransfer(params){return request({
url: '/report/api/reportFinancialTransfer/deleteReportFinancialTransfer',
method: 'post',
params:params,
});
}


