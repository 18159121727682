/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-09T15:06:38.733
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-FinancePayment-'

export default 
{
path: '/storagemanage/financepayment',
name: 'storagemanage-FinancePayment',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}FinancePayment-index',

meta:{
...meta,
title: '存端付款管理'
},

component: () => import('@/pages/storagemanage/financepayment/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}FinancePayment-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '存端付款管理编辑'
},

component: () => import('@/pages/storagemanage/financepayment/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}FinancePaymentdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '存端付款管理新增'
},

component: () => import('@/pages/storagemanage/financepayment/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}FinancePaymentView',
auth: [
'hidden'
],

meta:{
...meta,
title: '存端付款管理详情查看'
},

component: () => import('@/pages/storagemanage/financepayment/detail-view') ,
},
]
};
