/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-11T10:54:45.416
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseHoldInit(data){return request({
url: '/rentmanage/api/rentHouseHold/listRentHouseHoldInit',
method: 'post',
data:data,
});
}


export function listRentHouseHoldByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseHold/listRentHouseHoldByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseHold(data){return request({
url: '/rentmanage/api/rentHouseHold/listRentHouseHold',
method: 'post',
data:data,
});
}


export function getRentHouseHoldCreateInit(){return request({
url: '/rentmanage/api/rentHouseHold/getRentHouseHoldCreateInit',
method: 'post',
});
}


export function getRentHouseHoldEditInit(params){return request({
url: '/rentmanage/api/rentHouseHold/getRentHouseHoldEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseHoldView(params){return request({
url: '/rentmanage/api/rentHouseHold/getRentHouseHoldView',
method: 'post',
params:params,
});
}


export function saveRentHouseHold(data){return request({
url: '/rentmanage/api/rentHouseHold/saveRentHouseHold',
method: 'post',
data:data,
});
}


export function deleteRentHouseHold(params){return request({
url: '/rentmanage/api/rentHouseHold/deleteRentHouseHold',
method: 'post',
params:params,
});
}

export function startWorkflow(data){return request({
	url: '/rentmanage/api/rentHouseHold/startWorkflow',
	method: 'post',
	data:data,
	});
	}
