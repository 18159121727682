/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-07T10:19:46.046
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listCCBFinBillItemInit(data){return request({
url: '/house/api/cCBFinBillItem/listCCBFinBillItemInit',
method: 'post',
data:data,
});
}


export function listCCBFinBillItemByAuthorizations(data){return request({
url: '/house/api/cCBFinBillItem/listCCBFinBillItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listCCBFinBillItem(data){return request({
url: '/house/api/cCBFinBillItem/listCCBFinBillItem',
method: 'post',
data:data,
});
}


export function getCCBFinBillItemCreateInit(){return request({
url: '/house/api/cCBFinBillItem/getCCBFinBillItemCreateInit',
method: 'post',
});
}


export function getCCBFinBillItemEditInit(params){return request({
url: '/house/api/cCBFinBillItem/getCCBFinBillItemEditInit',
method: 'post',
params:params,
});
}


export function getCCBFinBillItemView(params){return request({
url: '/house/api/cCBFinBillItem/getCCBFinBillItemView',
method: 'post',
params:params,
});
}


export function saveCCBFinBillItem(data){return request({
url: '/house/api/cCBFinBillItem/saveCCBFinBillItem',
method: 'post',
data:data,
});
}


export function deleteCCBFinBillItem(params){return request({
url: '/house/api/cCBFinBillItem/deleteCCBFinBillItem',
method: 'post',
params:params,
});
}


