/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-06-07 18:58:24
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-30 14:56:32
 * @FilePath: \iview-admin-pro-template\src\store\modules\report\modules\reporthousecount\index.js
 */
/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-30T17:33:20.308
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getProjectInfo, getHousekeeperListInfo, listBuilding, findHomeReportDisplayInfo, findHomeReportDisplayInfoStatistics, findHomeReportDisplayInfoRenewal, findHomeReportDisplayInfoPayment, findHomeReportDisplayInfoPutOnShelf, listBigArea
} from '@api/report/ReportHouseCount';
import {
	listArea
} from '@api/storagemanage/Area';
import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listBigArea({
			state
		}) {
			return new Promise((resolve, reject) => {
				listBigArea(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getProjectInfo({ }, obj) {
			return new Promise((resolve, reject) => {
				getProjectInfo(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getHousekeeperListInfo({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				getHousekeeperListInfo(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		findHomeReportDisplayInfo({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				findHomeReportDisplayInfo(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		findHomeReportDisplayInfoStatistics({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				findHomeReportDisplayInfoStatistics(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		findHomeReportDisplayInfoRenewal({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				findHomeReportDisplayInfoRenewal(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		findHomeReportDisplayInfoPayment({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				findHomeReportDisplayInfoPayment(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		findHomeReportDisplayInfoPutOnShelf({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				findHomeReportDisplayInfoPutOnShelf(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		listBuilding({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				listBuilding(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
