/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-20T19:56:11.01
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseAirCheckItemInit(data){return request({
url: '/rentmanage/api/rentHouseAirCheckItem/listRentHouseAirCheckItemInit',
method: 'post',
data:data,
});
}


export function listRentHouseAirCheckItemByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseAirCheckItem/listRentHouseAirCheckItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseAirCheckItem(data){return request({
url: '/rentmanage/api/rentHouseAirCheckItem/listRentHouseAirCheckItem',
method: 'post',
data:data,
});
}


export function getRentHouseAirCheckItemCreateInit(){return request({
url: '/rentmanage/api/rentHouseAirCheckItem/getRentHouseAirCheckItemCreateInit',
method: 'post',
});
}


export function getRentHouseAirCheckItemEditInit(params){return request({
url: '/rentmanage/api/rentHouseAirCheckItem/getRentHouseAirCheckItemEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseAirCheckItemView(params){return request({
url: '/rentmanage/api/rentHouseAirCheckItem/getRentHouseAirCheckItemView',
method: 'post',
params:params,
});
}


export function saveRentHouseAirCheckItem(data){return request({
url: '/rentmanage/api/rentHouseAirCheckItem/saveRentHouseAirCheckItem',
method: 'post',
data:data,
});
}


export function deleteRentHouseAirCheckItem(params){return request({
url: '/rentmanage/api/rentHouseAirCheckItem/deleteRentHouseAirCheckItem',
method: 'post',
params:params,
});
}


