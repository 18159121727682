import util from '@/libs/util';
import router from '@/router';
import {
	saveArea,
	getAreaCreateInit,
	getAreaEditInit,
	getAreaView,
	deleteArea
} from '@api/storagemanage/Area';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getAreaCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getAreaCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getAreaEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getAreaEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveArea({}, Area) {
			return new Promise((resolve, reject) => {
				saveArea(Area)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getAreaView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getAreaView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteArea({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteArea({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
