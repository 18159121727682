/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-04-29T08:55:39.224
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listHouseCycleDetail,
	listHouseCycleDetailInit,
	listHouseCycleDetailByAuthorizations,
	deleteHouseCycleDetail,
	listHouseCycleSteps
} from '@api/house/HouseCycleDetail';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listHouseCycleDetail({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHouseCycleDetail(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listHouseCycleDetailByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHouseCycleDetailByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listHouseCycleDetailInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHouseCycleDetailInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listHouseCycleSteps({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHouseCycleSteps(state.AdvanceQuery)
					.then(async res => {
						// state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		deleteHouseCycleDetail({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteHouseCycleDetail({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
