/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-14T15:25:43.963
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request'

export function listRentLeaseContractTenantInit(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContractTenant/listRentLeaseContractTenantInit',
		method: 'post',
		data: data,
	});
}


export function listRentLeaseContractTenantByAuthorizations(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContractTenant/listRentLeaseContractTenantByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listRentLeaseContractTenant(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContractTenant/listRentLeaseContractTenant',
		method: 'post',
		data: data,
	});
}


export function getRentLeaseContractTenantCreateInit() {
	return request({
		url: '/rentmanage/api/rentLeaseContractTenant/getRentLeaseContractTenantCreateInit',
		method: 'post',
	});
}


export function getRentLeaseContractTenantEditInit(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContractTenant/getRentLeaseContractTenantEditInit',
		method: 'post',
		params: params,
	});
}


export function getRentLeaseContractTenantView(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContractTenant/getRentLeaseContractTenantView',
		method: 'post',
		params: params,
	});
}


export function saveRentLeaseContractTenant(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContractTenant/saveRentLeaseContractTenant',
		method: 'post',
		data: data,
	});
}


export function deleteRentLeaseContractTenant(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContractTenant/deleteRentLeaseContractTenant',
		method: 'post',
		params: params,
	});
}

export function getRentLeaseContractTenantEditInit2(params) {
	return request({
		url: '/rentmanage/api/rentTenant/getRentTenantEditInit',
		method: 'post',
		params: params
	});
}


