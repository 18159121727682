/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2023-12-28T10:48:56.717
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listReportBizRentContractItemPayment,
	listReportBizRentContractItemPaymentInit,
	listReportBizRentContractItemPaymentByAuthorizations,
	deleteReportBizRentContractItemPayment,
  countReportBizRentContractItemPayment
	
} from '@api/report/ReportBizRentContractItemPayment';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listReportBizRentContractItemPayment({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportBizRentContractItemPayment(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportBizRentContractItemPaymentByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportBizRentContractItemPaymentByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportBizRentContractItemPaymentInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportBizRentContractItemPaymentInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

    countReportBizRentContractItemPayment({
			state
		}) {
			return new Promise((resolve, reject) => {
				countReportBizRentContractItemPayment(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
    
		deleteReportBizRentContractItemPayment({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportBizRentContractItemPayment({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
