/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-16T15:24:16.932
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentTakeLookEvaluateInit(data){return request({
url: '/rentmanage/api/rentTakeLookEvaluate/listRentTakeLookEvaluateInit',
method: 'post',
data:data,
});
}


export function listRentTakeLookEvaluateByAuthorizations(data){return request({
url: '/rentmanage/api/rentTakeLookEvaluate/listRentTakeLookEvaluateByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentTakeLookEvaluate(data){return request({
url: '/rentmanage/api/rentTakeLookEvaluate/listRentTakeLookEvaluate',
method: 'post',
data:data,
});
}


export function getRentTakeLookEvaluateCreateInit(){return request({
url: '/rentmanage/api/rentTakeLookEvaluate/getRentTakeLookEvaluateCreateInit',
method: 'post',
});
}


export function getRentTakeLookEvaluateEditInit(params){return request({
url: '/rentmanage/api/rentTakeLookEvaluate/getRentTakeLookEvaluateEditInit',
method: 'post',
params:params,
});
}


export function getRentTakeLookEvaluateView(params){return request({
url: '/rentmanage/api/rentTakeLookEvaluate/getRentTakeLookEvaluateView',
method: 'post',
params:params,
});
}


export function saveRentTakeLookEvaluate(data){return request({
url: '/rentmanage/api/rentTakeLookEvaluate/saveRentTakeLookEvaluate',
method: 'post',
data:data,
});
}


export function deleteRentTakeLookEvaluate(params){return request({
url: '/rentmanage/api/rentTakeLookEvaluate/deleteRentTakeLookEvaluate',
method: 'post',
params:params,
});
}


