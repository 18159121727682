/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-18T17:50:48.083
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	savekhmPayDeductionApplyItem,
	getkhmPayDeductionApplyItemCreateInit,
	getkhmPayDeductionApplyItemEditInit,
	getkhmPayDeductionApplyItemView,
	deletekhmPayDeductionApplyItem
} from '@api/storagemanage/khmPayDeductionApplyItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getkhmPayDeductionApplyItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getkhmPayDeductionApplyItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getkhmPayDeductionApplyItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getkhmPayDeductionApplyItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		savekhmPayDeductionApplyItem({}, khmPayDeductionApplyItem) {
			return new Promise((resolve, reject) => {
				savekhmPayDeductionApplyItem(khmPayDeductionApplyItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getkhmPayDeductionApplyItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getkhmPayDeductionApplyItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deletekhmPayDeductionApplyItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deletekhmPayDeductionApplyItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
