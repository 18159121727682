/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-20T19:56:10.81
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseAirCheckInit(data){return request({
url: '/rentmanage/api/rentHouseAirCheck/listRentHouseAirCheckInit',
method: 'post',
data:data,
});
}


export function listRentHouseAirCheckByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseAirCheck/listRentHouseAirCheckByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseAirCheck(data){return request({
url: '/rentmanage/api/rentHouseAirCheck/listRentHouseAirCheck',
method: 'post',
data:data,
});
}


export function getRentHouseAirCheckCreateInit(){return request({
url: '/rentmanage/api/rentHouseAirCheck/getRentHouseAirCheckCreateInit',
method: 'post',
});
}


export function getRentHouseAirCheckEditInit(params){return request({
url: '/rentmanage/api/rentHouseAirCheck/getRentHouseAirCheckEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseAirCheckView(params){return request({
url: '/rentmanage/api/rentHouseAirCheck/getRentHouseAirCheckView',
method: 'post',
params:params,
});
}


export function saveRentHouseAirCheck(data){return request({
url: '/rentmanage/api/rentHouseAirCheck/saveRentHouseAirCheck',
method: 'post',
data:data,
});
}


export function deleteRentHouseAirCheck(params){return request({
url: '/rentmanage/api/rentHouseAirCheck/deleteRentHouseAirCheck',
method: 'post',
params:params,
});
}


