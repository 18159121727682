import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	name: 'khm-6',
	redirect: {
		name: `${pre}propertydelivery-list`
	},
	meta,
	component: BasicLayout,
	children: [
		{
			path: 'propertydelivery-list',
			name: `${pre}propertydelivery-list`,
			meta: {
				...meta,
				title: '物业交付',
				closable: true
			},
			component: () => import('@/pages/storagemanage/propertydelivery')
		},
		{
			path: 'propertydelivery-edit',
			name: `${pre}propertydelivery-edit`,
			meta: {
				...meta,
				title: '物业交付编辑',
				closable: true
			},
			component: () => import('@/pages/storagemanage/propertydelivery/edit')
		},
		{
			path: 'propertydelivery-detail',
			name: `${pre}propertydelivery-detail`,
			meta: {
				...meta,
				title: '物业交付查看',
				closable: true
			},
			component: () => import('@/pages/storagemanage/propertydelivery/detail')
		},
		
	]
};
