// 菜单，顶栏

export default [
    {
        path: '/',
        title: '首页',
        icon: 'md-home',
        hideSider: false,
        name: 'home'
    },
];
