/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-03T09:53:25.108
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentRepairWorkOrder,
	listRentRepairWorkOrderInit,
	listRentRepairWorkOrderByAuthorizations,
	listRentRepairWorkOrderInitNeedDealt,
	listRentRepairWorkOrderByAuthorizationsNeedDealt, 
	listRentRepairWorkOrderByAuthorizationsParticipation,
	listRentRepairWorkOrderByAuthorizationsUserAll,
	deleteRentRepairWorkOrder,
	assignmentSubmit,
	cancelRepairWorkOrder,
	exportDetails,
	listRentRepairWorkOrderInitCount
	
} from '@api/rentmanage/RentRepairWorkOrder';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
		//是否编辑状态查看
		edit:false
	},

	actions: {
		listRentRepairWorkOrder({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrder(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentRepairWorkOrderByAuthorizationsNeedDealt({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderByAuthorizationsNeedDealt(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentRepairWorkOrderByAuthorizationsParticipation({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderByAuthorizationsParticipation(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentRepairWorkOrderByAuthorizationsUserAll({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderByAuthorizationsUserAll(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentRepairWorkOrderInitNeedDealt({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderInitNeedDealt(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentRepairWorkOrderByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentRepairWorkOrderInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentRepairWorkOrderInitCount({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderInitCount(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		//指派
		assignmentSubmit({}, date) {
			return new Promise((resolve, reject) => {
				assignmentSubmit(date)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		cancelRepairWorkOrder({
			state
		},date) {
			return new Promise((resolve, reject) => {
				cancelRepairWorkOrder(date)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		exportDetails({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				exportDetails(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentRepairWorkOrder({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentRepairWorkOrder({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
