/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-14T15:25:43.93
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentLeaseContractReceiptPlan,
	listRentLeaseContractReceiptPlanInit,
	listRentLeaseContractReceiptPlanByAuthorizations,
	deleteRentLeaseContractReceiptPlan,
	calculatePlan,
	downloadTemplateReq,
} from '@api/rentmanage/RentLeaseContractReceiptPlan';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentLeaseContractReceiptPlan({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentLeaseContractReceiptPlan(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentLeaseContractReceiptPlanByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentLeaseContractReceiptPlanByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentLeaseContractReceiptPlanInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentLeaseContractReceiptPlanInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
                        state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentLeaseContractReceiptPlan({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentLeaseContractReceiptPlan({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		calculate({
			state
		}, params) {
			return new Promise((resolve, reject) => {
				calculatePlan(params)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		downloadTemplateReq({
			state
		}, params) {
			return new Promise((resolve, reject) => {
				downloadTemplateReq(params)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
