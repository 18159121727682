/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T08:26:50.71
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
  auth: true
};

const pre = 'rentmanage-RentHouseSalesPromotion-'

export default
  {
    path: '/rentmanage/renthousesalespromotion',
    name: 'rentmanage-RentHouseSalesPromotion',
    redirect: {
      name: '${pre}-index'
    }
    ,

    meta: {
      ...meta,
    },

    component: BasicLayout,
    children:
      [
        {
          path: 'index',
          name: '${pre}RentHouseSalesPromotion-index',

          meta: {
            ...meta,
            title: '卡券申请'
          },

          component: () => import('@/pages/rentmanage/renthousesalespromotion/index'),
        },
        {
          path: 'detail-edit/:id',
          name: `${pre}detail-edit`,
          auth: [
            'hidden'
          ],

          meta: {
            ...meta,
            cache: true,
            title: '卡券申请编辑'
          },

          component: () => import('@/pages/rentmanage/renthousesalespromotion/detail-edit'),
        },
        {
          path: 'detail-edit',
          name: '${pre}RentHouseSalesPromotiondetail-create',
          auth: [
            'hidden'
          ],

          meta: {
            ...meta,
            title: '卡券申请新增'
          },

          component: () => import('@/pages/rentmanage/renthousesalespromotion/detail-edit'),
        },
        {
          path: 'detail-view/:id',
          name: '${pre}RentHouseSalesPromotionView',
          auth: [
            'hidden'
          ],

          meta: {
            ...meta,
            title: '卡券申请详情查看'
          },

          component: () => import('@/pages/rentmanage/renthousesalespromotion/detail-view'),
        },
      ]
  };
