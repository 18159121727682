/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-30T17:06:33.678
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialTransferInit(data){return request({
url: '/financial/api/financialTransfer/listFinancialTransferInit',
method: 'post',
data:data,
});
}


export function listFinancialTransferByAuthorizations(data){return request({
url: '/financial/api/financialTransfer/listFinancialTransferByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialTransfer(data){return request({
url: '/financial/api/financialTransfer/listFinancialTransfer',
method: 'post',
data:data,
});
}


export function getFinancialTransferCreateInit(){return request({
url: '/financial/api/financialTransfer/getFinancialTransferCreateInit',
method: 'post',
});
}


export function getFinancialTransferEditInit(params){return request({
url: '/financial/api/financialTransfer/getFinancialTransferEditInit',
method: 'post',
params:params,
});
}


export function getFinancialTransferView(params){return request({
url: '/financial/api/financialTransfer/getFinancialTransferView',
method: 'post',
params:params,
});
}


export function saveFinancialTransfer(data){return request({
url: '/financial/api/financialTransfer/saveFinancialTransfer',
method: 'post',
data:data,
});
}


export function deleteFinancialTransfer(params){return request({
url: '/financial/api/financialTransfer/deleteFinancialTransfer',
method: 'post',
params:params,
});
}

export function financialTransferOpr(params){return request({
url: '/financial/api/financialTransfer/financialTransferOpr',
method: 'post',
params:params,
});
}



