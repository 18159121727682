/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-16T16:45:54.888
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveKhmDataAuthApply,
	getKhmDataAuthApplyCreateInit,
	getKhmDataAuthApplyEditInit,
	getKhmDataAuthApplyView,
	deleteKhmDataAuthApply,
	startWorkflow
} from '@api/rentmanage/KhmDataAuthApply';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getKhmDataAuthApplyCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getKhmDataAuthApplyCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getKhmDataAuthApplyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getKhmDataAuthApplyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveKhmDataAuthApply({}, KhmDataAuthApply) {
			return new Promise((resolve, reject) => {
				saveKhmDataAuthApply(KhmDataAuthApply)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getKhmDataAuthApplyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getKhmDataAuthApplyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		startWorkflow({}, KhmDataAuthApply) {
			return new Promise((resolve, reject) => {
				startWorkflow(KhmDataAuthApply)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteKhmDataAuthApply({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteKhmDataAuthApply({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
