/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-24T16:23:29.423
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialPaymentInit(data){return request({
url: '/financial/api/financialPayment/listFinancialPaymentInit',
method: 'post',
data:data,
});
}


export function listFinancialPaymentByAuthorizations(data){return request({
url: '/financial/api/financialPayment/listFinancialPaymentByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialPayment(data){return request({
url: '/financial/api/financialPayment/listFinancialPayment',
method: 'post',
data:data,
});
}


export function getFinancialPaymentCreateInit(){return request({
url: '/financial/api/financialPayment/getFinancialPaymentCreateInit',
method: 'post',
});
}


export function getFinancialPaymentEditInit(params){return request({
url: '/financial/api/financialPayment/getFinancialPaymentEditInit',
method: 'post',
params:params,
});
}


export function getFinancialPaymentView(params){return request({
url: '/financial/api/financialPayment/getFinancialPaymentView',
method: 'post',
params:params,
});
}


export function saveFinancialPayment(data){return request({
url: '/financial/api/financialPayment/saveFinancialPayment',
method: 'post',
data:data,
});
}


export function deleteFinancialPayment(params){return request({
url: '/financial/api/financialPayment/deleteFinancialPayment',
method: 'post',
params:params,
});
}

export function financialPaymentStateChange(params){return request({
url: '/financial/api/financialPayment/financialPaymentStateChange',
method: 'post',
params:params,
});
}


