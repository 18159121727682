/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2021-03-17T15:34:14.743
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'house-publishoperationlog-'

export default {
	path: '/house/publishoperationlog',
	name: 'house-publishoperationlog',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}publishoperationlog-index',

			meta: {
				...meta,
				title: '发布操作日志'
			},

			component: () => import('@/pages/house/publishoperationlog/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}publishoperationlog-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '发布操作日志编辑'
			},

			component: () => import('@/pages/house/publishoperationlog/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}publishoperationlogdetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '发布操作日志新增'
			},

			component: () => import('@/pages/house/publishoperationlog/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}publishoperationlogView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '发布操作日志详情查看'
			},

			component: () => import('@/pages/house/publishoperationlog/detail-view'),
		},
	]
};
