/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-20T16:51:35.232
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveFinancialPayApplyItem,
	getFinancialPayApplyItemCreateInit,
	getFinancialPayApplyItemEditInit,
	getFinancialPayApplyItemView,
	deleteFinancialPayApplyItem
} from '@api/rentmanage/FinancialPayApplyItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialPayApplyItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayApplyItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialPayApplyItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayApplyItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialPayApplyItem({}, FinancialPayApplyItem) {
			return new Promise((resolve, reject) => {
				saveFinancialPayApplyItem(FinancialPayApplyItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialPayApplyItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayApplyItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialPayApplyItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialPayApplyItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
