/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-07-05T16:23:43.222
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listRentPropertyDeliveryInit(data) {
    return request({
        url: '/rentmanage/api/rentPropertyDelivery/listRentPropertyDeliveryInit',
        method: 'post',
        data: data,
    });
}


export function listRentPropertyDeliveryByAuthorizations(data) {
    return request({
        url: '/rentmanage/api/rentPropertyDelivery/listRentPropertyDeliveryByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listRentPropertyDelivery(data) {
    return request({
        url: '/rentmanage/api/rentPropertyDelivery/listRentPropertyDelivery',
        method: 'post',
        data: data,
    });
}


export function getRentPropertyDeliveryCreateInit(params) {
    return request({
        url: '/rentmanage/api/rentPropertyDelivery/getRentPropertyDeliveryCreateInit',
        method: 'post',
		params: params,
    });
}


export function getRentPropertyDeliveryEditInit(params) {
    return request({
        url: '/rentmanage/api/rentPropertyDelivery/getRentPropertyDeliveryEditInit',
        method: 'post',
        params: params,
    });
}


export function getRentPropertyDeliveryView(params) {
    return request({
        url: '/rentmanage/api/rentPropertyDelivery/getRentPropertyDeliveryView',
        method: 'post',
        params: params,
    });
}


export function saveRentPropertyDelivery(data) {
    return request({
        url: '/rentmanage/api/rentPropertyDelivery/saveRentPropertyDelivery',
        method: 'post',
        data: data,
    });
}


export function deleteRentPropertyDelivery(params) {
    return request({
        url: '/rentmanage/api/rentPropertyDelivery/deleteRentPropertyDelivery',
        method: 'post',
        params: params,
    });
}

export function sendSign(data) {
	return request({
		url: '/rentmanage/api/rentPropertyDelivery/sendSigningLink',
		method: 'post',
		params: data
	});
}

export function confirmPropertyDelivery(data) {
	return request({
		url: '/rentmanage/api/rentPropertyDelivery/confirmPropertyDelivery',
		method: 'post',
		params: data
	});
}

export function confirmConfigure(data) {
	return request({
		url: '/rentmanage/api/rentPropertyDelivery/confirmConfigure',
		method: 'post',
		params: data
	});
}

export function organConfirmConfigure(data) {
	return request({
		url: '/rentmanage/api/rentPropertyDelivery/organConfirmConfigure',
		method: 'post',
		params: data
	});
}

export function withdraw(data) {
	return request({
		url: '/rentmanage/api/rentPropertyDelivery/withdraw',
		method: 'post',
		params: data
	});
}