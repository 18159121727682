/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T19:13:41.167
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-RentRepairFeedbackDetails-'

export default 
{
path: '/rentmanage/rentrepairfeedbackdetails',
name: 'rentmanage-RentRepairFeedbackDetails',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}RentRepairFeedbackDetails-index',

meta:{
...meta,
title: '工单反馈'
},

component: () => import('@/pages/rentmanage/rentrepairfeedbackdetails/index') ,
},
{
	path: 'indexMain',
	name: '${pre}RentRepairFeedbackDetails-indexMain',

	meta:{
	...meta,
	title: '反馈跟踪'
	},

	component: () => import('@/pages/rentmanage/rentrepairfeedbackdetails/indexMain') ,
	},
{
path: 'detail-edit/:id',
name: '${pre}RentRepairFeedbackDetails-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '工单反馈编辑'
},

component: () => import('@/pages/rentmanage/rentrepairfeedbackdetails/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}RentRepairFeedbackDetailsdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '工单反馈新增'
},

component: () => import('@/pages/rentmanage/rentrepairfeedbackdetails/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}RentRepairFeedbackDetailsView',
auth: [
'hidden'
],

meta:{
...meta,
title: '工单反馈详情查看'
},

component: () => import('@/pages/rentmanage/rentrepairfeedbackdetails/detail-view') ,
},
]
};
