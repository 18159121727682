/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-17T17:56:04.445
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentIssueHouseApplyItemInit(data){return request({
url: '/rentmanage/api/rentIssueHouseApplyItem/listRentIssueHouseApplyItemInit',
method: 'post',
data:data,
});
}


export function listRentIssueHouseApplyItemByAuthorizations(data){return request({
url: '/rentmanage/api/rentIssueHouseApplyItem/listRentIssueHouseApplyItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentIssueHouseApplyItem(data){return request({
url: '/rentmanage/api/rentIssueHouseApplyItem/listRentIssueHouseApplyItem',
method: 'post',
data:data,
});
}


export function getRentIssueHouseApplyItemCreateInit(){return request({
url: '/rentmanage/api/rentIssueHouseApplyItem/getRentIssueHouseApplyItemCreateInit',
method: 'post',
});
}


export function getRentIssueHouseApplyItemEditInit(params){return request({
url: '/rentmanage/api/rentIssueHouseApplyItem/getRentIssueHouseApplyItemEditInit',
method: 'post',
params:params,
});
}


export function getRentIssueHouseApplyItemView(params){return request({
url: '/rentmanage/api/rentIssueHouseApplyItem/getRentIssueHouseApplyItemView',
method: 'post',
params:params,
});
}


export function saveRentIssueHouseApplyItem(data){return request({
url: '/rentmanage/api/rentIssueHouseApplyItem/saveRentIssueHouseApplyItem',
method: 'post',
data:data,
});
}


export function deleteRentIssueHouseApplyItem(params){return request({
url: '/rentmanage/api/rentIssueHouseApplyItem/deleteRentIssueHouseApplyItem',
method: 'post',
params:params,
});
}


