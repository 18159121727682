/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-20T19:56:10.844
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-RentHouseAirCheck-'

export default 
{
path: '/rentmanage/renthouseaircheck',
name: 'rentmanage-RentHouseAirCheck',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}RentHouseAirCheck-index',

meta:{
...meta,
title: '房源空气检查报告'
},

component: () => import('@/pages/rentmanage/renthouseaircheck/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}RentHouseAirCheck-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源空气检查报告编辑'
},

component: () => import('@/pages/rentmanage/renthouseaircheck/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}RentHouseAirCheckdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源空气检查报告新增'
},

component: () => import('@/pages/rentmanage/renthouseaircheck/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}RentHouseAirCheckView',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源空气检查报告详情查看'
},

component: () => import('@/pages/rentmanage/renthouseaircheck/detail-view') ,
},
]
};
