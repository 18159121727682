/*
 * @Author: co
 * @Date: 2021-04-07 14:30:16
 */
import BasicLayout from '@/layouts/basic-layout';
////第一步：配置菜单-->第二步：配置存房委托指派商家页面相关的路由-->第三步：注入路由-->第四步：做页面
const meta = {
	// auth: true
};

const pre = 'rentmanage-findroom';

export default {
	path: '/rentmanage/findroom',
	name: '我要找房',
	redirect: {
		name: `${pre}findroom-index`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'index',
		name: `${pre}findroom-index`,
		meta: {
			...meta,
			title: '我要找房',
			closable: true
		},
		component: () => import('@/pages/rentmanage/findroom/index')
	}, {
		path: 'detail-view/:id',
		name: '${pre}findroomView',
		auth: [
			'hidden'
		],

		meta: {
			...meta,
			title: '房源详情'
		},

		component: () => import('@/pages/rentmanage/findroom/detail-view'),
	}, , {
		path: 'video/:src',
		name: '${pre}findroomVideo',
		auth: [
			'hidden'
		],

		meta: {
			...meta,
			title: '房源视频'
		},

		component: () => import('@/pages/rentmanage/findroom/video'),
	},]
};
