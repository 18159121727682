/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-01T17:09:50.463
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancialAdjustmentApply,
	getFinancialAdjustmentApplyCreateInit,
	getFinancialAdjustmentApplyEditInit,
	getFinancialAdjustmentApplyView,
	deleteFinancialAdjustmentApply
} from '@api/financial/FinancialAdjustmentApply';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listFinancialAdjustmentApplyItemInit,
listFinancialAdjustmentApplyItemByAuthorizations,
deleteFinancialAdjustmentApplyItem
} from '@api/financial/FinancialAdjustmentApplyItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialAdjustmentApplyCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialAdjustmentApplyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialAdjustmentApply({}, FinancialAdjustmentApply) {
			return new Promise((resolve, reject) => {
				saveFinancialAdjustmentApply(FinancialAdjustmentApply)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialAdjustmentApplyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialAdjustmentApply({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialAdjustmentApply({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
