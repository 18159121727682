/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-30T17:33:20.308
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listReportCardUseCount,
	listReportCardUseCountInit,
	listReportCardUseCountByAuthorizations,
	deleteReportCardUseCount
	
} from '@api/report/ReportCardUseCount';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listReportCardUseCount({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportCardUseCount(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportCardUseCountByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportCardUseCountByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportCardUseCountInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportCardUseCountInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteReportCardUseCount({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportCardUseCount({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
