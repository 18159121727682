/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-05-17T10:46:25.387
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listEvaluateAppointmentKeeprecordInit(data){return request({
url: '/storagemanage/api/evaluateAppointmentKeeprecord/listEvaluateAppointmentKeeprecordInit',
method: 'post',
data:data,
});
}


export function listEvaluateAppointmentKeeprecordByAuthorizations(data){return request({
url: '/storagemanage/api/evaluateAppointmentKeeprecord/listEvaluateAppointmentKeeprecordByAuthorizations',
method: 'post',
data:data,
});
}


export function listEvaluateAppointmentKeeprecord(data){return request({
url: '/storagemanage/api/evaluateAppointmentKeeprecord/listEvaluateAppointmentKeeprecord',
method: 'post',
data:data,
});
}


export function getEvaluateAppointmentKeeprecordCreateInit(){return request({
url: '/storagemanage/api/evaluateAppointmentKeeprecord/getEvaluateAppointmentKeeprecordCreateInit',
method: 'post',
});
}


export function getEvaluateAppointmentKeeprecordEditInit(params){return request({
url: '/storagemanage/api/evaluateAppointmentKeeprecord/getEvaluateAppointmentKeeprecordEditInit',
method: 'post',
params:params,
});
}


export function getEvaluateAppointmentKeeprecordView(params){return request({
url: '/storagemanage/api/evaluateAppointmentKeeprecord/getEvaluateAppointmentKeeprecordView',
method: 'post',
params:params,
});
}


export function saveEvaluateAppointmentKeeprecord(data){return request({
url: '/storagemanage/api/evaluateAppointmentKeeprecord/saveEvaluateAppointmentKeeprecord',
method: 'post',
data:data,
});
}


export function deleteEvaluateAppointmentKeeprecord(params){return request({
url: '/storagemanage/api/evaluateAppointmentKeeprecord/deleteEvaluateAppointmentKeeprecord',
method: 'post',
params:params,
});
}


