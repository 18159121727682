/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T10:58:26.209
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialEvidenceItemInit(data){return request({
url: '/financial/api/financialEvidenceItem/listFinancialEvidenceItemInit',
method: 'post',
data:data,
});
}


export function listFinancialEvidenceItemByAuthorizations(data){return request({
url: '/financial/api/financialEvidenceItem/listFinancialEvidenceItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialEvidenceItem(data){return request({
url: '/financial/api/financialEvidenceItem/listFinancialEvidenceItem',
method: 'post',
data:data,
});
}


export function getFinancialEvidenceItemCreateInit(){return request({
url: '/financial/api/financialEvidenceItem/getFinancialEvidenceItemCreateInit',
method: 'post',
});
}


export function getFinancialEvidenceItemEditInit(params){return request({
url: '/financial/api/financialEvidenceItem/getFinancialEvidenceItemEditInit',
method: 'post',
params:params,
});
}


export function getFinancialEvidenceItemView(params){return request({
url: '/financial/api/financialEvidenceItem/getFinancialEvidenceItemView',
method: 'post',
params:params,
});
}


export function saveFinancialEvidenceItem(data){return request({
url: '/financial/api/financialEvidenceItem/saveFinancialEvidenceItem',
method: 'post',
data:data,
});
}


export function deleteFinancialEvidenceItem(params){return request({
url: '/financial/api/financialEvidenceItem/deleteFinancialEvidenceItem',
method: 'post',
params:params,
});
}


