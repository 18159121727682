/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T19:13:41.296
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getRentConsumeMaterialCompanyEditInit,
	getRentConsumeMaterialCompanyView
} from '@api/rentmanage/RentConsumeMaterialCompany';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},

	actions: {
		getRentConsumeMaterialCompanyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentConsumeMaterialCompanyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentConsumeMaterialCompanyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentConsumeMaterialCompanyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
