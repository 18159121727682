/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-01T17:31:37.064
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getFinancialAdjustmentApplyItemEditInit,
	getFinancialAdjustmentApplyItemView
} from '@api/financial/FinancialAdjustmentApplyItem';

import {
listFinancialAdjustmentApplyDetailInit,
listFinancialAdjustmentApplyDetailByAuthorizations,
deleteFinancialAdjustmentApplyDetail
} from '@api/financial/FinancialAdjustmentApplyDetail';

import {
listFinancialAdjustmentApplyCollectionInit,
listFinancialAdjustmentApplyCollectionByAuthorizations,
deleteFinancialAdjustmentApplyCollection
} from '@api/financial/FinancialAdjustmentApplyCollection';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getFinancialAdjustmentApplyItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialAdjustmentApplyItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
