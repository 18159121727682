/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-23T14:56:33.7
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentGoodsSort,
	getRentGoodsSortCreateInit,
	getRentGoodsSortEditInit,
	getRentGoodsSortView,
	deleteRentGoodsSort
} from '@api/rentmanage/RentGoodsSort';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentGoodsInit,
listRentGoodsByAuthorizations,
deleteRentGoods
} from '@api/rentmanage/RentGoods';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentGoodsSortCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentGoodsSortCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentGoodsSortEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentGoodsSortEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentGoodsSort({}, RentGoodsSort) {
			return new Promise((resolve, reject) => {
				saveRentGoodsSort(RentGoodsSort)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentGoodsSortView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentGoodsSortView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentGoodsSort({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentGoodsSort({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
