/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-15T17:10:25.422
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listFinanceRentApplyInit(data) {
    return request({
        url: '/storagemanage/api/financeRentApply/listFinanceRentApplyInit',
        method: 'post',
        data: data,
    });
}


export function listFinanceRentApplyByAuthorizations(data) {
    return request({
        url: '/storagemanage/api/financeRentApply/listFinanceRentApplyByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listFinanceRentApply(data) {
    return request({
        url: '/storagemanage/api/financeRentApply/listFinanceRentApply',
        method: 'post',
        data: data,
    });
}


export function getFinanceRentApplyCreateInit() {
    return request({
        url: '/storagemanage/api/financeRentApply/getFinanceRentApplyCreateInit',
        method: 'post',
    });
}


export function getFinanceRentApplyEditInit(params) {
    return request({
        url: '/storagemanage/api/financeRentApply/getFinanceRentApplyEditInit',
        method: 'post',
        params: params,
    });
}


export function getFinanceRentApplyView(params) {
    return request({
        url: '/storagemanage/api/financeRentApply/getFinanceRentApplyView',
        method: 'post',
        params: params,
    });
}


export function saveFinanceRentApply(data) {
    return request({
        url: '/storagemanage/api/financeRentApply/saveFinanceRentApply',
        method: 'post',
        data: data,
    });
}


export function deleteFinanceRentApply(params) {
    return request({
        url: '/storagemanage/api/financeRentApply/deleteFinanceRentApply',
        method: 'post',
        params: params,
    });
}

