/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-11T09:45:26.434
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listReportFinancialRentContractItemPayment,
	listReportFinancialRentContractItemPaymentInit,
	listReportFinancialRentContractItemPaymentByAuthorizations,
	deleteReportFinancialRentContractItemPayment,
	countReportFinancialRentContractItemPayment
} from '@api/report/ReportFinancialRentContractItemPayment';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listReportFinancialRentContractItemPayment({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialRentContractItemPayment(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportFinancialRentContractItemPaymentByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialRentContractItemPaymentByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities = res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportFinancialRentContractItemPaymentInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialRentContractItemPaymentInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		countReportFinancialRentContractItemPayment({
			state
		}) {
			return new Promise((resolve, reject) => {
				countReportFinancialRentContractItemPayment(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		deleteReportFinancialRentContractItemPayment({
			state
		}, { id }) {
			return new Promise((resolve, reject) => {
				deleteReportFinancialRentContractItemPayment({
					id
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
