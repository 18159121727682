import util from '@/libs/util';
import router from '@/router';

import {
	saveEvaluateBillAttachment,
	getEvaluateBillAttachmentCreateInit,
	getEvaluateBillAttachmentEditInit,
	getEvaluateBillAttachmentView,
	deleteEvaluateBillAttachment
} from '@api/storagemanage/EvaluateBillAttachment';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getEvaluateBillAttachmentCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillAttachmentCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateBillAttachmentEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillAttachmentEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveEvaluateBillAttachment({}, EvaluateBillAttachment) {
			return new Promise((resolve, reject) => {
				saveEvaluateBillAttachment(EvaluateBillAttachment)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateBillAttachmentView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillAttachmentView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateBillAttachment({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateBillAttachment({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
