<template>
  <Drawer lock-scroll v-bind="attrs" v-model="visible">
    <template v-if="!destroyClose">
      <template slot="header" v-if="$slots.header"><slot name="header" /></template>
      <div class="drawer-body">
        <slot />
      </div>
      <div class="drawer-footer" v-if="$slots.footer">
        <div class="drawer-footer-inner"><slot name="footer" /></div>
        <div class="safe-area-inset-bottom" />
      </div>
    </template>
  </Drawer>
</template>

<script lang="ts">
  import { extend } from 'lodash'
  import { mapState } from 'vuex'

  export default {
    name: 'JFDrawer',
    inheritAttrs: false,
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      className: {
        type: String,
        default: '',
      },
      // 关闭时销毁 Drawer 里的子元素
      destroyOnClose: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      visible (value) {
        if (!value) {
          setTimeout(() => {
            if (this.destroyOnClose) this.destroyClose = true
          }, 350)
        }
        if (value) {
          this.destroyClose = false
        }
        this.$emit('input', value)
        this.$emit('on-visible-change', value)
      },
      value: {
        handler (value) {
          this.visible = value
        },
        immediate: true
      },
      isMobile: {
        handler (value) {
          this.attrs = extend({width: 50}, value ? {
            width: 100,
            height: 100,
            placement: 'bottom',
          } : {}, this.$attrs, {className: [this.className, 'JFDrawer'].join(' ')})
        },
        immediate: true
      }
    },
    computed: mapState('admin/layout', ['isMobile']),
    data () {
      return {
        attrs: {},
        visible: false
      }
    }
  }
</script>

<style scoped lang="less">
/deep/ .JFDrawer {

  .ivu-drawer-body {
    display: flex;
    padding: 0;
    flex-direction: column;
  }
  .ivu-card-head,
  .ivu-card-body {
    padding-left: 0;
    padding-right: 0;
  }
  .ivu-card-extra {
    right: 0;
  }
}
.drawer {

  &-body {
    flex: 1;
    padding: 16px;
    overflow: auto;
  }
  &-footer {
    border-top: 1px solid #e8eaec;
    box-shadow: 0 0 20px 0 rgba(0, 91, 172, .1);
    background-color: #fff;
    position: relative;
    z-index: 10;

    &-inner {
      display: flex;
      padding: 7.5px 15px;
      justify-content: flex-end;
    }
  }
}
</style>
