/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-04T19:46:29.202
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listHouseRepeatPlanInit(data){return request({
url: '/storagemanage/api/houseRepeatPlan/listHouseRepeatPlanInit',
method: 'post',
data:data,
});
}


export function listHouseRepeatPlanByAuthorizations(data){return request({
url: '/storagemanage/api/houseRepeatPlan/listHouseRepeatPlanByAuthorizations',
method: 'post',
data:data,
});
}


export function listHouseRepeatPlan(data){return request({
url: '/storagemanage/api/houseRepeatPlan/listHouseRepeatPlan',
method: 'post',
data:data,
});
}


export function getHouseRepeatPlanCreateInit(){return request({
url: '/storagemanage/api/houseRepeatPlan/getHouseRepeatPlanCreateInit',
method: 'post',
});
}


export function getHouseRepeatPlanEditInit(params){return request({
url: '/storagemanage/api/houseRepeatPlan/getHouseRepeatPlanEditInit',
method: 'post',
params:params,
});
}


export function getHouseRepeatPlanView(params){return request({
url: '/storagemanage/api/houseRepeatPlan/getHouseRepeatPlanView',
method: 'post',
params:params,
});
}


export function saveHouseRepeatPlan(data){return request({
url: '/storagemanage/api/houseRepeatPlan/saveHouseRepeatPlan',
method: 'post',
data:data,
});
}


export function deleteHouseRepeatPlan(params){return request({
url: '/storagemanage/api/houseRepeatPlan/deleteHouseRepeatPlan',
method: 'post',
params:params,
});
}


