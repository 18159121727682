<template>
	<!-- 修改密码 -->
	<div v-if="visible">
		<Modal v-model="visible" title="修改密码" :mask-closable="false" width="600px">
			<Form ref="form" :model="data" :rules="rules" label-position="left">
				<Row :gutter="24">
					<Col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
					<FormItem label="原密码：" prop="oldPassword">
						<Input type="password" v-model="data.oldPassword" placeholder="请填写原密码" />
					</FormItem>
					</Col>
					<Col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
					<FormItem label="新密码：" prop="password">
						<Input type="password" v-model="data.password" placeholder="请填写新密码" />
					</FormItem>
					</Col>
					<Col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
					<FormItem label="重复新密码：" prop="repeatPassword">
						<Input type="password" v-model="data.repeatPassword" placeholder="请填写重复新密码" />
					</FormItem>
					</Col>
				</Row>
			</Form>
			<div slot="footer">
				<Button @click="visible = false" :loading="loading">取消</Button>
				<Button type="primary" :loading="loading" @click="submitData">确认</Button>
			</div>
		</Modal>
	</div>
</template>
<script>
	import Setting from '@/setting';
	import request from '@/plugins/request';
	export default {
		name: 'updatePassword',
		components: {},
		computed: {
			user() {
				return this.$store.state.admin.user.info
			},
			Setting() {
				return Setting
			},
		},
		watch: {
			//监听visible  false的话销毁组件  重置组件数据
			'visible'(val) {
				if (val == false) {
					this.$emit('destroy')
				}
			},
		},
		data() {
			const that = this;
			const valiPassword = function(rule, value, callback) {
				if (value === '') {
					callback(new Error('请输入重复新密码'));
				} else {
					if (value !== that.data.password) {
						callback(new Error('密码输入不一致'));
					} else {
						callback();
					}
				}
			}
			return {
				visible: false,
				loading: false,
				data: {
					oldPassword: '',
					password: '',
					repeatPassword: '',
					userId: '',
				},
				rules: {
					oldPassword: [{
						required: true,
						message: '请填写旧密码',
						trigger: 'blur',
					}],
					password: [{
						required: true,
						message: '请填写新密码',
						trigger: 'blur',
					}],
					repeatPassword: [{
						required: true,
						trigger: 'blur',
						validator: valiPassword,
					}],
				},
			}
		},
		mounted() {

		},
		methods: {
			init() {
				this.visible = true;
				this.$nextTick(() => {

				})
			},
			submitData() {
				this.loading = true;
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.data.userId = this.user ? this.user.userId : ''
						return new Promise((resolve, reject) => {
							request({
									url: "/systemmanage/api/orgUserManage/passwordModify",
									method: 'post',
									params: this.data,
								}).then(async res => {
									this.loading = false;
									this.visible = false;
									resolve();
								})
								.catch(err => {
									this.loading = false;
									reject(err);
								});
						})
					} else {
						this.loading = false;
					}
				})
			},
		},
	}
</script>
<style lang="less">
</style>
