<template>
  <!-- 发起流程 -->
  <div>
    <JFDrawer destroy-on-close title="提交流程" v-model="startModal">
      <Form ref="formData" :model="submitData" :rules="rules">
        <div class="i-table-no-border">
          <FormItem label="审核结论：" prop="approveEffect">
            <RadioGroup v-model="submitData.approveEffect" @on-change="changeApproveEffect">
              <template v-for="item in data.currentWorkitem.approveEffectRange">
                <Radio
                  v-if="isCondeputecontractbacksettlement&&item.code==='AGREE'&&data.currentWorkitem.workitemId==='RuntimeHandWorkflowTerminationServiceImpl:housekeeper'"
                  :label="item.code"
                  :disabled="true"
                  :key="item.code"
                >{{item.name}}</Radio>
                <Radio v-else :label="item.code" :key="item.code">{{item.name}}</Radio>
              </template>
            </RadioGroup>
            <Alert
              v-if="isCondeputecontractbacksettlement&&data.currentWorkitem.workitemId==='RuntimeHandWorkflowTerminationServiceImpl:housekeeper'"
              type="warning"
            >当前环境只允许驳回，如果业主对复函费用无异议，请在业主确认复函结果后，发送解约协议，并完成确认交付。在确认交付后，当前解约流程自动完成。</Alert>
          </FormItem>
          <FormItem label="审核意见：" prop="approveContent">
            <Input show-word-limit type="textarea" placeholder="请填写意见"
                   :maxlength="500" :autosize="{minRows: 3, maxRows: 5}"
                   v-model="submitData.approveContent"
            />
          </FormItem>

          <rentpropertydeliveryDialog ref="rentpropertydeliveryDialogRef" :defaultData="rentpropertydeliveryDefaultData"
                                      v-if="showRentpropertydeliveryDialog&&!data.currentWorkitem.isRoot&&submitData.approveEffect === 'AGREE'"/>
          <Row :gutter="16" v-if="businessData.organizationPriceConfig && submitData.approveEffect === 'AGREE'">
            <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
              <FormItem label="首年存房月租金(元)：" prop="firstMonthStoreRent">
                <CurrencyInput clearable placeholder="请输入首年存房月租金(元)" v-model="submitData.firstMonthStoreRent" />
              </FormItem>
            </Col>
            <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
              <FormItem label="出租小B非空置期总月度：" prop="unoccupiedMonthly">
                <CurrencyInput clearable placeholder="请输入出租小B非空置期总月度" v-model="submitData.unoccupiedMonthly" />
              </FormItem>
            </Col>
            <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
              <FormItem label="首年首月小B承租租金(元)：" prop="rental">
                <CurrencyInput clearable placeholder="请输入首年首月小B承租租金(元)" v-model="submitData.rental" />
              </FormItem>
            </Col>
            <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
              <FormItem label="租房起租日：" prop="rentalStartDate">
                <DatePicker clearable type="date" style="width: 100%" placeholder="请选择租房起租日" v-model="submitData.rentalStartDate" />
              </FormItem>
            </Col>
            <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
              <FormItem label="租房空置期(月)：" prop="rentalVacancyPeriod">
                <CurrencyInput clearable placeholder="请输入租房空置期(月)" v-model="submitData.rentalVacancyPeriod" />
              </FormItem>
            </Col>
            <Col :xl="8" :lg="8" :md="8" :sm="8" :xs="8">
              <FormItem label="是否含税：" prop="isTaxIncluded">
                <Select clearable placeholder="请选择是否含税" v-model="submitData.isTaxIncluded" >
                  <Option label="否" value="0" />
                  <Option label="是" value="1" />
                </Select>
              </FormItem>
            </Col>
          </Row>

          <!-- 必选,且只能选一个 -->
          <div v-if="nextWorkitems('ONLY_ONE').length>0"></div>
          <div
            v-if="onlyOneFlowUserColumnsData.length>0&&submitData.approveEffect==onlyOneFlowUserColumnsData[0].approveEffectCode||onlyOneFlowUserColumnsData.length>0&&onlyOneFlowUserColumnsData[0].approveEffectCode=='CONSULT'&&submitData.approveEffect==''"
          >必选组(单选)</div>
          <Table
            v-if="onlyOneFlowUserColumnsData.length>0&&submitData.approveEffect==onlyOneFlowUserColumnsData[0].approveEffectCode||onlyOneFlowUserColumnsData.length>0&&onlyOneFlowUserColumnsData[0].approveEffectCode=='CONSULT'&&submitData.approveEffect==''"
            :columns="onlyOneFlowUserColumns"
            :data="onlyOneFlowUserColumnsData"
            class="ivu-mt"
          >
            <template slot-scope="{ row }" slot="nextUser">
              <Tag
                v-if="row.selectedNextUser.length>0"
                v-for="(item,indexTwo) in row.selectedNextUser"
                :key="indexTwo"
              >{{item.userName}}</Tag>
            </template>
          </Table>
          <!-- 必选,且可以选多个 -->
          <div v-if="nextWorkitems('ONE_OR_MORE').length>0"></div>
          <div
            v-if="oneOrMoreFlowUserColumnsData.length>0&&submitData.approveEffect==oneOrMoreFlowUserColumnsData[0].approveEffectCode||oneOrMoreFlowUserColumnsData.length>0&&oneOrMoreFlowUserColumnsData[0].approveEffectCode=='CONSULT'&&submitData.approveEffect==''"
          >必选组(多选)</div>
          <Table
            v-if="oneOrMoreFlowUserColumnsData.length>0&&submitData.approveEffect==oneOrMoreFlowUserColumnsData[0].approveEffectCode||oneOrMoreFlowUserColumnsData.length>0&&oneOrMoreFlowUserColumnsData[0].approveEffectCode=='CONSULT'&&submitData.approveEffect==''"
            :columns="oneOrMoreFlowUserColumns"
            :data="oneOrMoreFlowUserColumnsData"
            class="ivu-mt"
            @on-select="handleSelect"
            @on-select-cancel="handleSelectCancel"
            @on-select-all="handleSelectAll"
            @on-select-all-cancel="handleSelectAllCancel"
          >
            <template slot-scope="{ row }" slot="nextUser">
              <Tag
                v-if="row.selectedNextUser.length>0"
                v-for="(item,indexTwo) in row.selectedNextUser"
                :key="indexTwo"
              >{{item.userName}}</Tag>
              <!-- <Input
                v-model="row.nextUserName"
                readonly
                icon="ios-search"
                :disabled="submitData.approveEffect != 'CONSULT'"
                @on-click="openSelectUser(row,'selectedDataOneOrMoreNextUser')"
                placeholder="点击右边搜索图标选择审核人员"
              />-->
            </template>
          </Table>
          <!-- 可选，可不选，可多选 -->
          <div v-if="nextWorkitems('ZORE_OR_MORE').length>0">候选组</div>
          <Table
            v-if="nextWorkitems('ZORE_OR_MORE').length>0"
            :columns="zeroOrMoreFlowUserColumns"
            :data="nextWorkitems('ZORE_OR_MORE')"
            class="ivu-mt"
          >
            <template slot-scope="{ row }" slot="nextUser">
              <Input
                v-model="zeroOrMoreInputViewData"
                readonly
                icon="ios-search"
                :disabled="submitData.approveEffect != 'CONSULT'"
                @on-click="openSelectUser(row,'selectedDataZeroOrMoreNextUser')"
                placeholder="点击右边搜索图标选择审核人员"
              />
            </template>
          </Table>
          <!-- 全部都得选上 -->
          <div v-if="nextWorkitems('ALL').length>0"></div>
          <div
            v-if="allFlowUserColumnsData.length>0&&submitData.approveEffect==allFlowUserColumnsData[0].approveEffectCode||allFlowUserColumnsData.length>0&&allFlowUserColumnsData[0].approveEffectCode=='CONSULT'&&submitData.approveEffectCode==''"
          >全选组</div>
          <Table
            v-if="allFlowUserColumnsData.length>0&&submitData.approveEffect==allFlowUserColumnsData[0].approveEffectCode||allFlowUserColumnsData.length>0&&allFlowUserColumnsData[0].approveEffectCode=='CONSULT'&&submitData.approveEffectCode==''"
            :columns="allFlowUserColumns"
            :data="allFlowUserColumnsData"
            class="ivu-mt"
          >
            <template slot-scope="{ row }" slot="nextUser">
              <Tag
                v-if="row.selectedNextUser.length>0"
                v-for="(item,indexTwo) in row.selectedNextUser"
                :key="indexTwo"
              >{{item.userName}}</Tag>
            </template>
          </Table>
        </div>
      </Form>
      <Card dis-hover title="流程痕迹" :bordered="false">
        <ProcessTrace ref="processTrace" :list="list" />
      </Card>

      <Spin fix v-if="loading" />
      <template slot="footer" v-if="!loading">
        <Row :style="isMobile ? {flex: 1} : {}" :gutter="10">
          <Col v-bind="isMobile ? {span: 12} : {}">
            <Button :long="isMobile" :disabled="submitting" @click="submitFlow(0)">暂存</Button>
          </Col>
          <Col v-bind="isMobile ? {span: 12} : {}">
            <Button type="primary"
                    :long="isMobile" :loading="submitting"
                    @click="submitFlow(1)">提交流程</Button>
          </Col>
        </Row>
      </template>
    </JFDrawer>
    <selectUser ref="selectUser"
                v-if="selectUserVisible"
                @setData="setDataUser"
                @destroy="selectUserVisible = false;" />
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import {omit, cloneDeep} from 'lodash'
  import selectUser from './selectUser'
  import rentpropertydeliveryDialog from './rentpropertydeliveryDialog'
  import request from '@/plugins/request'
  import ProcessTrace from './processTrace'

  export default {
    name: 'DealFlow',
    components: {
      selectUser,
      ProcessTrace,
      rentpropertydeliveryDialog,
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
      //选组数据  包含:
      //type:ONLY_ONE(必选组,单选)  ONE_OR_MORE(必选组,多选)  ZORE_OR_MORE(候选组,多选,可不选)  ALL(确选组,全部都必须要选)
      nextWorkitems() {
        return function (type) {
          //取出当前审核结论对应的name
          let approveEffect = this.data.currentWorkitem.approveEffectRange.find(
            (value) => value.code == this.submitData.approveEffect
          )
          let approveEffectName = ''
          if (approveEffect) {
            approveEffectName = approveEffect.name
          }
          //循环对人员集合分组
          let result = []
          let arr = this.data.nextWorkitems
          for (let i = 0; i < arr.length; i++) {
            //人员与审核结论挂钩,同意租跟不同意租,判断当前数组与已选审核结论相等,就push
            if (arr[i].groupName == approveEffectName) {
              //判断人员组别
              switch (arr[i].groupOperationEnum) {
                case type:
                  result.push(arr[i])
                  break
                default:
                  break
              }
            }
          }
          if (result.length > 0) {
            //默认选中第一项人员
            //   this.selectedDataOnlyOne = [result[0]]
            //非征询不能选环节人员,默认选中所有
            // if (this.submitData.approveEffect != 'CONSULT') {
            if (type == 'ONLY_ONE') {
              //   this.selectedDataOnlyOneNextUser = result[0].nextUser
              //   this.onlyOneFlowUserColumnsData = result
              if (
                this.onlyOneFlowUserColumnsData.length == 0 ||
                this.onlyOneFlowUserColumnsData[0].approveEffectCode !=
                  this.submitData.approveEffect
              ) {
                this.onlyOneFlowUserColumnsData = result
                this.selectedDataOnlyOne = [this.onlyOneFlowUserColumnsData[0]]
                this.onlyOneFlowUserColumnsData.forEach((v, i) => {
                  if (this.onlyOneFlowUserColumnsData[i].nextUser.length == 1) {
                    this.onlyOneFlowUserColumnsData[i].selectedNextUser =
                      this.onlyOneFlowUserColumnsData[i].nextUser
                  } else {
                    this.onlyOneFlowUserColumnsData[i].selectedNextUser = []
                  }
                })
                this.onlyOneFlowUserColumnsData[0]._checked = true
              }
            } else if (type == 'ONE_OR_MORE') {
              if (
                this.oneOrMoreFlowUserColumnsData.length == 0 ||
                this.oneOrMoreFlowUserColumnsData[0].approveEffectCode !=
                  this.submitData.approveEffect
              ) {
                this.oneOrMoreFlowUserColumnsData = result
                if (this.oneOrMoreFlowUserColumnsData.length == 1) {
                  this.selectedDataOneOrMore.push(
                    this.oneOrMoreFlowUserColumnsData[0]
                  )
                  this.oneOrMoreFlowUserColumnsData[0]._checked = true
                }
                this.oneOrMoreFlowUserColumnsData.forEach((v, i) => {
                  if (this.oneOrMoreFlowUserColumnsData[i].nextUser.length == 1) {
                    this.oneOrMoreFlowUserColumnsData[i].selectedNextUser =
                      this.oneOrMoreFlowUserColumnsData[i].nextUser
                    this.selectedDataOneOrMoreNextUser =
                      this.oneOrMoreFlowUserColumnsData[i].nextUser
                  } else {
                    this.oneOrMoreFlowUserColumnsData[i].selectedNextUser = []
                  }
                })
              }
            } else if (type == 'ZORE_OR_MORE') {
            } else if (type == 'ALL') {
              if (
                this.allFlowUserColumnsData.length == 0 ||
                this.allFlowUserColumnsData[0].approveEffectCode !=
                  this.submitData.approveEffect
              ) {
                this.allFlowUserColumnsData = result
                this.allFlowUserColumnsData.forEach((v, i) => {
                  if (this.allFlowUserColumnsData[i].nextUser.length == 1) {
                    this.allFlowUserColumnsData[i].selectedNextUser =
                      this.allFlowUserColumnsData[i].nextUser
                  } else {
                    this.allFlowUserColumnsData[i].selectedNextUser = []
                  }
                  this.allFlowUserColumnsData[i]._checked = true
                  this.allFlowUserColumnsData[i]._disabled = true
                })
              }
            }
            // }
          }
          return result
        }
      },
      //必选组已选人员input model(单选)
      onlyOneInputViewData() {
        let str = ''
        for (let i = 0; i < this.selectedDataOnlyOneNextUser.length; i++) {
          // this.selectedDataOnlyOneNextUser[i].userId + "/" +
          str += this.selectedDataOnlyOneNextUser[i].userName + ','
        }
        //去除最后一位的,
        return str.substring(0, str.length - 1)
      },
      //必选组已选人员input model(多选)
      oneOrMoreInputViewData() {
        let str = ''
        for (let i = 0; i < this.selectedDataOneOrMoreNextUser.length; i++) {
          // this.selectedDataOneOrMoreNextUser[i].userId + "/" +
          str += this.selectedDataOneOrMoreNextUser[i].userName + ','
        }
        //去除最后一位的,
        return str.substring(0, str.length - 1)
      },
      //候选组已选人员input model(多选)
      zeroOrMoreInputViewData() {
        let str = ''
        for (let i = 0; i < this.selectedDataZoreOrMoreNextUser.length; i++) {
          // this.selectedDataZoreOrMoreNextUser[i].userId + "/" +
          str += this.selectedDataZoreOrMoreNextUser[i].userName + ','
        }
        //去除最后一位的,
        return str.substring(0, str.length - 1)
      },
      //确选组已选人员input model(多选)
      allInputViewData() {
        let str = ''
        for (let i = 0; i < this.selectedDataALLNextUser.length; i++) {
          // this.selectedDataALLNextUser[i].userId + "/" +
          str += this.selectedDataALLNextUser[i].userName + ','
        }
        //去除最后一位的,
        return str.substring(0, str.length - 1)
      },
    },
    methods: {
      condeputecontractbacksettlementProcessFn() {
        let pathStr = this.$route.path
        if (
          pathStr.indexOf(
            '/storagemanage/condeputecontractbacksettlement/detail-view'
          ) != -1
        ) {
          this.isCondeputecontractbacksettlement = true
          this.$nextTick(() => {
            this.submitData.approveEffect = 'DISAGREE'
            this.submitData.approveContent = '不同意'
          })
        } else {
          this.isCondeputecontractbacksettlement = false
        }
      },
      // 选中一项，将数据添加至已选项中
      handleSelect(selection, row) {
        this.selectedDataOneOrMore.push(row)
      },

      // 取消选中一项，将取消的数据从已选项中删除
      handleSelectCancel(selection, row) {
        const index = this.selectedDataOneOrMore.findIndex(
          (item) => item.workitemId === row.workitemId
        )
        this.selectedDataOneOrMore.splice(index, 1)
      },

      // 当前页全选时，判断已选数据是否存在，不存在则添加
      handleSelectAll(selection) {
        selection.forEach((item) => {
          if (
            this.selectedDataOneOrMore.findIndex(
              (i) => i.workitemId === item.workitemId
            ) < 0
          ) {
            this.selectedDataOneOrMore.push(item)
          }
        })
      },

      // 取消当前页全选时，将当前页的数据（即 dataWithPage）从已选项中删除
      handleSelectAllCancel() {
        const selection = this.dataWithPage
        selection.forEach((item) => {
          const index = this.selectedDataOneOrMore.findIndex(
            (i) => i.workitemId === item.workitemId
          )
          if (index >= 0) {
            this.selectedDataOneOrMore.splice(index, 1)
          }
        })
      },
      async init(data, url, billData) {
        try {
          this.startModal = true
          this.data = data
          this.flowUrl = url
          //审核结论设置初始值
          this.submitData.approveEffect =
            this.data.currentWorkitem.approveEffectRange &&
            this.data.currentWorkitem.approveEffectRange.length > 0
              ? this.data.currentWorkitem.approveEffectRange[0].code
              : ''
          //审核意见设置初始值
          this.submitData.approveContent =
            this.data.currentWorkitem.approveEffectRange &&
            this.data.currentWorkitem.approveEffectRange.length > 0
              ? this.data.currentWorkitem.approveEffectRange[0].name
              : ''
          if (this.data.currentDraftWorkitemInstance.approveContent) {
            this.submitData.approveContent =
              this.data.currentDraftWorkitemInstance.approveContent
          }
          // this.submitData.bill = billData;
          //流程实例id赋值(从审核初始化接口)
          this.submitData.workflowInstanceId =
            this.data.currentDraftWorkitemInstance.workflowInstanceId
          //流程环节实例id赋值(从审核初始化接口)
          this.submitData.workitemInstanceId =
            this.data.currentDraftWorkitemInstance.workitemInstanceId

          this.loading = true
          const {total, entities} = await request({
            url: '/worfklow/api/flowcenter/listProcessTraces',
            method: 'post',
            params: {
              workflowInstanceId:
              this.data.currentDraftWorkitemInstance.workflowInstanceId,
            },
          })

          this.list = entities || []
          this.total = total || 0
          if (this.data.currentWorkitem.workitemId === 'RuntimeHandWorkflowTerminationServiceImpl:housekeeper') {
            this.condeputecontractbacksettlementProcessFn()
          }
          this.nextWorkitemList = this.nextWorkitems('ONE_OR_MORE')
        } catch (e) {} finally {
          this.loading = false
        }
      },
      changeApproveEffect() {
        //切换审核结论  清空环节已选人员
        this.selectedDataOnlyOneNextUser = []

        this.selectedDataZoreOrMoreNextUser = []
        this.selectedDataALLNextUser = []

        this.onlyOneFlowUserColumnsData.forEach((v, i) => {
          // this.onlyOneFlowUserColumnsData[i].selectedNextUser = []
          if (this.onlyOneFlowUserColumnsData[i].nextUser.length == 1) {
            this.onlyOneFlowUserColumnsData.splice(i, 1, {
              ...this.onlyOneFlowUserColumnsData[i],
              selectedNextUser: this.onlyOneFlowUserColumnsData[i].nextUser,
            })
          } else {
            this.onlyOneFlowUserColumnsData.splice(i, 1, {
              ...this.onlyOneFlowUserColumnsData[i],
              selectedNextUser: [],
            })
          }
        })

        this.oneOrMoreFlowUserColumnsData.forEach((v, i) => {
          // this.oneOrMoreFlowUserColumnsData[i].selectedNextUser = []
          if (this.oneOrMoreFlowUserColumnsData[i].nextUser.length == 1) {
            this.oneOrMoreFlowUserColumnsData.splice(i, 1, {
              ...this.oneOrMoreFlowUserColumnsData[i],
              selectedNextUser: this.oneOrMoreFlowUserColumnsData[i].nextUser,
            })
          } else {
            this.selectedDataOneOrMoreNextUser = []
            this.oneOrMoreFlowUserColumnsData.splice(i, 1, {
              ...this.oneOrMoreFlowUserColumnsData[i],
              selectedNextUser: [],
            })
          }
        })

        this.allFlowUserColumnsData.forEach((v, i) => {
          // this.allFlowUserColumnsData[i].selectedNextUser = []
          if (this.allFlowUserColumnsData[i].nextUser.length == 1) {
            this.allFlowUserColumnsData.splice(i, 1, {
              ...this.allFlowUserColumnsData[i],
              selectedNextUser: this.allFlowUserColumnsData[i].nextUser,
            })
          } else {
            this.allFlowUserColumnsData.splice(i, 1, {
              ...this.allFlowUserColumnsData[i],
              selectedNextUser: [],
            })
          }
        })
        // console.log(
        //   this.submitData.approveEffect,
        //   this.onlyOneFlowUserColumnsData
        // )

        if (this.submitData.approveEffect == 'AGREE') {
          this.submitData.approveContent = '同意'
        } else if (this.submitData.approveEffect == 'DISAGREE') {
          this.submitData.approveContent = '不同意'
        } else {
          //征询
          this.submitData.approveContent = ''
        }
        //判断是否征询  征询能选环节人员,非征询不能选 ,默认所有人员
        // if (this.submitData.approveEffect != 'CONSULT') {
        // 	for (let i = 0; i < this.nextWorkitems('ONLY_ONE').length; i++) {
        // 		this.selectedDataOnlyOneNextUser = this.nextWorkitems('ONLY_ONE')[i].nextUser
        // 	}
        // }
      },
      openSelectUser(row, selectDataName) {
        //点击选择环节人员
        //row:当前行数据  selectDataName:选择人员之后回传,要设置的数据的字段名
        this.selectUserVisible = true
        this.$nextTick(() => {
          //isMoreUser多选单选
          this.$refs['selectUser'].init(
            row.workflowUserEnum,
            row.nextUser,
            row.isMoreUser ? 'checkbox' : 'radio',
            selectDataName,
            row.workitemId
          )
        })
      },
      setDataUser(selectedData, selectDataName, workitemId) {
        //选择人员回传(每次覆盖上次所选)
        if (selectDataName === 'selectedDataOnlyOneNextUser') {
          //必选组(单选)
          this.onlyOneFlowUserColumnsData.forEach((v, i) => {
            if (this.onlyOneFlowUserColumnsData[i].workitemId === workitemId) {
              this.onlyOneFlowUserColumnsData.splice(i, 1, {
                ...this.onlyOneFlowUserColumnsData[i],
                selectedNextUser: selectedData,
              })
            }
          })
        } else if (selectDataName === 'selectedDataOneOrMoreNextUser') {
          //必选组(多选)
          this.oneOrMoreFlowUserColumnsData.forEach((v, i) => {
            if (this.oneOrMoreFlowUserColumnsData[i].workitemId === workitemId) {
              if (this.selectedDataOneOrMore.length > 0) {
                this.selectedDataOneOrMore.forEach((v, i) => {
                  if (this.selectedDataOneOrMore[i].workitemId === workitemId) {
                    this.selectedDataOneOrMore[i] =
                      this.oneOrMoreFlowUserColumnsData[i]
                  }
                })
              }
              this.oneOrMoreFlowUserColumnsData.splice(i, 1, {
                ...this.oneOrMoreFlowUserColumnsData[i],
                selectedNextUser: selectedData,
              })
            }
          })
          this.selectedDataOneOrMoreNextUser = selectedData
          if (this.oneOrMoreFlowUserColumnsData.length === 1) {
            this.selectedDataOneOrMore = this.oneOrMoreFlowUserColumnsData[0]
          }
        } else if (selectDataName === 'selectedDataZoreOrMoreNextUser') {
          //候选组(多选)
        } else if (selectDataName === 'selectedDataAllNextUser') {
          this.allFlowUserColumnsData.forEach((v, i) => {
            if (this.allFlowUserColumnsData[i].workitemId === workitemId) {
              this.allFlowUserColumnsData.splice(i, 1, {
                ...this.allFlowUserColumnsData[i],
                selectedNextUser: selectedData,
              })
            }
          })
          //确选组(多选)
        }
      },
      async submitFlow(type) {
        if (this.loading) return
        if (this.showRentpropertydeliveryDialog && this.submitData.approveEffect === 'AGREE') {
          this.$refs.rentpropertydeliveryDialogRef.getFormData()
          const {costAmount, lastReformDeliveryDate, lastReformDeliveryStartDate} = this.$refs.rentpropertydeliveryDialogRef.formObj

          if (costAmount == '' || lastReformDeliveryStartDate == '' || lastReformDeliveryDate == '') return
        }
        if (!await this.$refs.formData.validate()) return
        this.loading = true
        this.submitData.nextWorkitemAndUsers.nextWorkitemAndUserList = []

        //判断环节是否已选
        if (this.nextWorkitems('ONLY_ONE').length > 0) {
          if (this.selectedDataOnlyOne.length == 0) {
            this.$Message.warning('请选择环节')
            this.loading = false
            return
          } else {
            for (
              let i = 0;
              i < this.onlyOneFlowUserColumnsData.length;
              i++
            ) {
              if (
                this.selectedDataOnlyOne[0].workitemId ==
                this.onlyOneFlowUserColumnsData[i].workitemId &&
                this.onlyOneFlowUserColumnsData[i].selectedNextUser.length >
                0
              ) {
                this.submitData.nextWorkitemAndUsers.nextWorkitemAndUserList.push(
                  {
                    workitemId:
                    this.onlyOneFlowUserColumnsData[i].workitemId,
                    workitemUsers:
                    this.onlyOneFlowUserColumnsData[i].selectedNextUser,
                  }
                )
              } else {
                this.$Message.warning('请选择环节人员')
                this.loading = false
                return false
              }
            }
          }
        } else if (this.nextWorkitems('ONE_OR_MORE').length > 0) {
          if (this.selectedDataOneOrMore.length == 0) {
            this.$Message.warning('请选择环节')
            this.loading = false
            return
          } else {
            if (this.selectedDataOneOrMoreNextUser.length == 0) {
              this.$Message.warning('请选择环节人员')
              this.loading = false
              return
            } else {
              for (
                let i = 0;
                i < this.oneOrMoreFlowUserColumnsData.length;
                i++
              ) {
                if (
                  this.oneOrMoreFlowUserColumnsData[i].selectedNextUser
                    .length == 0
                ) {
                  this.$Message.warning('请选择环节人员')
                  this.loading = false
                  return true
                }
                this.submitData.nextWorkitemAndUsers.nextWorkitemAndUserList.push(
                  {
                    workitemId:
                    this.oneOrMoreFlowUserColumnsData[i].workitemId,
                    workitemUsers:
                    this.oneOrMoreFlowUserColumnsData[i].selectedNextUser,
                  }
                )
              }
            }
          }
        } else if (this.nextWorkitems('ALL').length > 0) {
          // console.log(this.allFlowUserColumnsData)

          for (let i = 0; i < this.allFlowUserColumnsData.length; i++) {
            if (
              this.allFlowUserColumnsData[i].selectedNextUser.length == 0
            ) {
              this.$Message.warning('请选择环节人员')
              this.loading = false
              return true
            }
            this.submitData.nextWorkitemAndUsers.nextWorkitemAndUserList.push(
              {
                workitemId: this.allFlowUserColumnsData[i].workitemId,
                workitemUsers:
                this.allFlowUserColumnsData[i].selectedNextUser,
              }
            )
          }
        }
        let rentpropertydeliveryDialogRef = {}

        this.$emit('getBusinessData', data => this.submitData.bill = data)
        if (this.showRentpropertydeliveryDialog && this.submitData.approveEffect === 'AGREE') {
          const {lastReformDeliveryDate, lastReformDeliveryStartDate, ...form} = this.$refs.rentpropertydeliveryDialogRef.formObj

          rentpropertydeliveryDialogRef = {
            ...form,
            lastReformDeliveryDate: this.$Date(lastReformDeliveryDate).format('YYYY-MM-DD'),
            lastReformDeliveryStartDate: this.$Date(lastReformDeliveryStartDate).format('YYYY-MM-DD')
          }
        }
        const {
          rental, isTaxIncluded, rentalStartDate,
          unoccupiedMonthly, rentalVacancyPeriod, firstMonthStoreRent,
          ...submitData
        } = cloneDeep(this.submitData)

        submitData.bill = {
          ...omit(submitData.bill, [
            'rental', 'isTaxIncluded', 'rentalStartDate', 'unoccupiedMonthly', 'rentalVacancyPeriod',
            'firstMonthStoreRent', 'costAmount', 'lastReformDeliveryDate', 'lastReformDeliveryStartDate'
          ]),
          ...submitData.approveEffect === 'AGREE' ? {
            rental, isTaxIncluded,
            unoccupiedMonthly, rentalVacancyPeriod, firstMonthStoreRent,
            ...rentpropertydeliveryDialogRef,
            // 格式化日期
            rentalStartDate: rentalStartDate ? this.$Date(rentalStartDate).format('YYYY-MM-DD') : ''
          } : {}
        }
        if (type == 0) {
          //暂存
          return new Promise((resolve, reject) => {
            request({
              url: this.flowUrl + '/tempStorageWorkflow',
              method: 'post',
              data: submitData,
              params: {
                approveContent: submitData.approveContent,
                approveEffect: submitData.approveEffect,
                workflowInstanceId: submitData.workflowInstanceId,
                workitemInstanceId: submitData.workitemInstanceId,
              },
            })
              .then(async (res) => {
                this.loading = false
                this.startModal = false
                this.$emit('refresh')
                resolve()
              })
              .catch((err) => {
                this.loading = true
                reject(err)
              })
          })
        } else {
          //提交流程
          return new Promise((resolve, reject) => {
            request({
              url: this.flowUrl + '/submitWorkflow',
              method: 'post',
              data: submitData,
              params: {
                approveContent: submitData.approveContent,
                approveEffect: submitData.approveEffect,
                workflowInstanceId: submitData.workflowInstanceId,
                workitemInstanceId: submitData.workitemInstanceId,
              },
            })
              .then(async (res) => {
                this.loading = false
                this.startModal = false
                this.$emit('refresh')
                resolve()
              })
              .catch((err) => {
                this.loading = false
                reject(err)
              })
          })
        }
      },
    },
    mounted() {
      this.showRentpropertydeliveryDialog = this.$route.path.indexOf('/rentmanage/rentpropertydelivery/detail-view') !== -1
      this.$emit('getBusinessData', data => {
        const {
          rental, isTaxIncluded, rentalStartDate,
          unoccupiedMonthly, rentalVacancyPeriod, firstMonthStoreRent
        } = data

        this.businessData = data
        this.submitData = {
          ...this.submitData,
          rental, rentalStartDate,
          unoccupiedMonthly, rentalVacancyPeriod, firstMonthStoreRent,
          isTaxIncluded: `${isTaxIncluded}`
        }
      })
    },
    data() {
      return {
        isCondeputecontractbacksettlement: false,
        rentpropertydeliveryDefaultData: {
          costAmount: '',
          lastReformDeliveryStartDate: '',
          lastReformDeliveryDate: '',
        },
        showRentpropertydeliveryDialog: false,
        err: '',
        startModal: false,
        selectUserVisible: false, //选人弹框
        checkTracksVisible: false,
        list: [],
        total: 0,
        loading: false,
        data: {
          nextWorkitems: [],
          currentWorkitem: {
            approveEffectRange: [],
          }
        }, //流程初始化传入的数据
        flowUrl: '', //发起流程的url
        submitData: {
          bill: {},
          nextWorkitemAndUsers: {
            nextWorkitemAndUserList: [],
          },
          workflowInstanceId: '',
          workitemInstanceId: '',
          approveContent: '',
          approveEffect: 'AGREE',
        }, //提交流程的数据对象
        selectedDataOnlyOne: [], //必选组已选(单选)
        selectedDataOnlyOneNextUser: [], //必选组已选环节人员(单选)
        selectedDataOneOrMore: [], //必选组已选(多选)
        selectedDataOneOrMoreNextUser: [], //必选组已选环节人员(多选)
        selectedDataZoreOrMore: [], //候选组已选(多选)
        selectedDataZoreOrMoreNextUser: [], //候选组已选环节人员
        selectedDataALL: [], //确选组已选(多选)
        selectedDataALLNextUser: [], //确选组已选环节人员
        nextWorkitemAndUserList: [], //必选组多个环节（多选）
        nextWorkitemList: [],
        //必选组column(单选)
        onlyOneFlowUserColumns: [
          {
            title: '选择',
            key: 'id',
            width: 70,
            align: 'center',
            render: (h, params) => {
              let id = params.row.workitemId
              let obj = params.row
              let flag = false
              //当前行是否已被选中
              let objIndex = this.selectedDataOnlyOne.findIndex(
                  (value) => value.workitemId == id
              )
              if (objIndex != -1) {
                flag = true
              } else {
                flag = false
              }
              let self = this
              return h('div', [
                h('Radio', {
                  props: {
                    value: flag,
                  },
                  on: {
                    'on-change': () => {
                      this.selectedDataOnlyOne = [obj]
                      console.log(this.selectedDataOnlyOne)
                      //非征询不能选环节人员,默认选中所有
                      if (this.submitData.approveEffect != 'CONSULT') {
                        this.selectedDataOnlyOneNextUser =
                            this.selectedDataOnlyOne[0].nextUser
                      }
                    },
                  },
                }),
              ])
            },
          },
          {
            title: '环节名称',
            align: 'center',
            minWidth: 140,
            key: 'workitemName',
          },
          {
            title: '环节人员',
            align: 'center',
            minWidth: 140,
            key: 'userName',
            slot: 'nextUser',
          },
          {
            title: '操作',
            key: 'action',
            width: 150,
            align: 'center',
            render: (h, params) => {
              return h('div', [
                h(
                    'Button',
                    {
                      props: {
                        type: 'primary',
                        size: 'small',
                      },
                      style: {
                        marginRight: '5px',
                      },
                      on: {
                        click: () => {
                          this.openSelectUser(
                              params.row,
                              'selectedDataOnlyOneNextUser'
                          )
                        },
                      },
                    },
                    '选择人员'
                ),
              ])
            },
          },
        ],
        oneOrMoreFlowUserColumns: [
          {
            title: '',
            minWidth: 70,
            type: 'selection',
          },
          {
            title: '环节名称',
            align: 'center',
            minWidth: 140,
            key: 'workitemName',
          },
          {
            title: '环节人员',
            align: 'center',
            minWidth: 140,
            key: 'userName',
            slot: 'nextUser',
          },
          {
            title: '操作',
            key: 'action',
            width: 150,
            align: 'center',
            render: (h, params) => {
              return h('div', [
                h(
                    'Button',
                    {
                      props: {
                        type: 'primary',
                        size: 'small',
                      },
                      style: {
                        marginRight: '5px',
                      },
                      on: {
                        click: () => {
                          this.openSelectUser(
                              params.row,
                              'selectedDataOneOrMoreNextUser'
                          )
                        },
                      },
                    },
                    '选择人员'
                ),
              ])
            },
          },
        ],
        zeroOrMoreFlowUserColumns: [
          {
            title: '',
            minWidth: 70,
            type: 'selection',
          },
          {
            title: '环节名称',
            align: 'center',
            minWidth: 140,
            key: 'workitemName',
          },
          {
            title: '环节人员',
            align: 'center',
            minWidth: 140,
            key: 'userName',
            slot: 'nextUser',
          },
        ],
        allFlowUserColumns: [
          {
            title: '',
            minWidth: 70,
            type: 'selection',
          },
          {
            title: '环节名称',
            align: 'center',
            minWidth: 140,
            key: 'workitemName',
          },
          {
            title: '环节人员',
            align: 'center',
            minWidth: 140,
            key: 'userName',
            slot: 'nextUser',
          },
          {
            title: '操作',
            key: 'action',
            width: 150,
            align: 'center',
            render: (h, params) => {
              return h('div', [
                h(
                    'Button',
                    {
                      props: {
                        type: 'primary',
                        size: 'small',
                      },
                      style: {
                        marginRight: '5px',
                      },
                      on: {
                        click: () => {
                          this.openSelectUser(params.row, 'selectedDataAllNextUser')
                        },
                      },
                    },
                    '选择人员'
                ),
              ])
            },
          },
        ],
        onlyOneFlowUserColumnsData: [],
        oneOrMoreFlowUserColumnsData: [],
        zeroOrMoreFlowUserColumnsData: [],
        allFlowUserColumnsData: [],
        rules: {
          approveEffect: [{required: true, message: '请选择审核结论'}],
          approveContent: [{required: true, message: '请选择审核意见'}],
          firstMonthStoreRent: [{required: true, message: '请输入首年存房月租金(元)'}],
          unoccupiedMonthly: [{required: true, message: '请输入出租小B非空置期总月度'}],
          rental: [{required: true, message: '请输入首年首月小B承租租金(元)'}],
          rentalStartDate: [{required: true, message: '请选择出租小B非空置期总月度'}],
          rentalVacancyPeriod: [{required: true, message: '请输入租房空置期(月)'}],
          isTaxIncluded: [{required: true, message: '请选择是否含税'}]
        },
        businessData: {},
        submitting: false,
      }
    }
  }
</script>
