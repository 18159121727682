/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-09T15:13:19.198
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listDepositContractTemplateInit(data){return request({
url: '/storagemanage/api/depositContractTemplate/listDepositContractTemplateInit',
method: 'post',
data:data,
});
}


export function listDepositContractTemplateByAuthorizations(data){return request({
url: '/storagemanage/api/depositContractTemplate/listDepositContractTemplateByAuthorizations',
method: 'post',
data:data,
});
}


export function listDepositContractTemplate(data){return request({
url: '/storagemanage/api/depositContractTemplate/listDepositContractTemplate',
method: 'post',
data:data,
});
}


export function getDepositContractTemplateCreateInit(){return request({
url: '/storagemanage/api/depositContractTemplate/getDepositContractTemplateCreateInit',
method: 'post',
});
}


export function getDepositContractTemplateEditInit(params){return request({
url: '/storagemanage/api/depositContractTemplate/getDepositContractTemplateEditInit',
method: 'post',
params:params,
});
}


export function getDepositContractTemplateView(params){return request({
url: '/storagemanage/api/depositContractTemplate/getDepositContractTemplateView',
method: 'post',
params:params,
});
}


export function saveDepositContractTemplate(data){return request({
url: '/storagemanage/api/depositContractTemplate/saveDepositContractTemplate',
method: 'post',
data:data,
});
}


export function deleteDepositContractTemplate(params){return request({
url: '/storagemanage/api/depositContractTemplate/deleteDepositContractTemplate',
method: 'post',
params:params,
});
}


