/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-20T10:20:49.544
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseStateChangeInit(data){return request({
url: '/rentmanage/api/rentHouseStateChange/listRentHouseStateChangeInit',
method: 'post',
data:data,
});
}


export function listRentHouseStateChangeByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseStateChange/listRentHouseStateChangeByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseStateChange(data){return request({
url: '/rentmanage/api/rentHouseStateChange/listRentHouseStateChange',
method: 'post',
data:data,
});
}


export function getRentHouseStateChangeCreateInit(){return request({
url: '/rentmanage/api/rentHouseStateChange/getRentHouseStateChangeCreateInit',
method: 'post',
});
}


export function getRentHouseStateChangeEditInit(params){return request({
url: '/rentmanage/api/rentHouseStateChange/getRentHouseStateChangeEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseStateChangeView(params){return request({
url: '/rentmanage/api/rentHouseStateChange/getRentHouseStateChangeView',
method: 'post',
params:params,
});
}


export function saveRentHouseStateChange(data){return request({
url: '/rentmanage/api/rentHouseStateChange/saveRentHouseStateChange',
method: 'post',
data:data,
});
}


export function deleteRentHouseStateChange(params){return request({
url: '/rentmanage/api/rentHouseStateChange/deleteRentHouseStateChange',
method: 'post',
params:params,
});
}


