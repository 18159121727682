/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-20T16:51:35.221
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialPayApplyItemInit(data){return request({
url: '/rentmanage/api/financialPayApplyItem/listFinancialPayApplyItemInit',
method: 'post',
data:data,
});
}


export function listFinancialPayApplyItemByAuthorizations(data){return request({
url: '/rentmanage/api/financialPayApplyItem/listFinancialPayApplyItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialPayApplyItem(data){return request({
url: '/rentmanage/api/financialPayApplyItem/listFinancialPayApplyItem',
method: 'post',
data:data,
});
}


export function getFinancialPayApplyItemCreateInit(){return request({
url: '/rentmanage/api/financialPayApplyItem/getFinancialPayApplyItemCreateInit',
method: 'post',
});
}


export function getFinancialPayApplyItemEditInit(params){return request({
url: '/rentmanage/api/financialPayApplyItem/getFinancialPayApplyItemEditInit',
method: 'post',
params:params,
});
}


export function getFinancialPayApplyItemView(params){return request({
url: '/rentmanage/api/financialPayApplyItem/getFinancialPayApplyItemView',
method: 'post',
params:params,
});
}


export function saveFinancialPayApplyItem(data){return request({
url: '/rentmanage/api/financialPayApplyItem/saveFinancialPayApplyItem',
method: 'post',
data:data,
});
}


export function deleteFinancialPayApplyItem(params){return request({
url: '/rentmanage/api/financialPayApplyItem/deleteFinancialPayApplyItem',
method: 'post',
params:params,
});
}


