/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T15:54:15.791
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentChannelApplyItem,
	listRentChannelApplyItemInit,
	listRentChannelApplyItemSQLInit,
	listRentChannelApplyItemByAuthorizations,
	deleteRentChannelApplyItem,
	downloadTemplate,
	toCancelDistribution,
	adminDownloadRentChannelApplyItemTemplate
} from '@api/rentmanage/RentChannelApplyItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentChannelApplyItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentChannelApplyItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentChannelApplyItemByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentChannelApplyItemByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		adminDownloadRentChannelApplyItemTemplate({
			state
		}) {
			return new Promise((resolve, reject) => {
				adminDownloadRentChannelApplyItemTemplate(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentChannelApplyItemSQLInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentChannelApplyItemSQLInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentChannelApplyItemInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentChannelApplyItemInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		downloadRentChannelApplyItemTemplate({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				downloadTemplate(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		toCancelDistribution({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				toCancelDistribution({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentChannelApplyItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentChannelApplyItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
