/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-09T14:42:50.594
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentHouseSalesPriceItem,
	listRentHouseSalesPriceItemInit,
	listRentHouseSalesPriceItemByAuthorizations,
	deleteRentHouseSalesPriceItem,
	downloadTemplate,
	batchSaveItemByHouseList,
	downloadHouseShowPriceTemplate,
	downloadHouseSalesPriceNullTemplate
} from '@api/rentmanage/RentHouseSalesPriceItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentHouseSalesPriceItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseSalesPriceItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseSalesPriceItemByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseSalesPriceItemByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseSalesPriceItemInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseSalesPriceItemInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		downloadHouseSalesPriceTemplate({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				downloadTemplate(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		downloadHouseShowPriceTemplate({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				downloadHouseShowPriceTemplate(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		downloadHouseSalesPriceNullTemplate({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				downloadHouseSalesPriceNullTemplate(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		batchSaveItemByHouseList({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				batchSaveItemByHouseList(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentHouseSalesPriceItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseSalesPriceItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
