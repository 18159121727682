/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-24T09:31:31.176
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHouseTag,
	getRentHouseTagCreateInit,
	getRentHouseTagEditInit,
	getRentHouseTagView,
	deleteRentHouseTag
} from '@api/rentmanage/RentHouseTag';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentHouseTagCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseTagCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseTagEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseTagEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseTag({}, RentHouseTag) {
			return new Promise((resolve, reject) => {
				saveRentHouseTag(RentHouseTag)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentHouseTagView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseTagView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentHouseTag({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseTag({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
