/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-10T12:05:16.896
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveFinancePayApplyItem,
	getFinancePayApplyItemCreateInit,
	getFinancePayApplyItemEditInit,
	getFinancePayApplyItemView,
	deleteFinancePayApplyItem,
	updateFinancePayApplyItem,
} from '@api/storagemanage/FinancePayApplyItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancePayApplyItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancePayApplyItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancePayApplyItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancePayApplyItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancePayApplyItem({}, FinancePayApplyItem) {
			return new Promise((resolve, reject) => {
				saveFinancePayApplyItem(FinancePayApplyItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancePayApplyItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancePayApplyItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancePayApplyItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancePayApplyItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		updateFinancePayApplyItem({}, FinancePayApplyItem) {
			return new Promise((resolve, reject) => {
				updateFinancePayApplyItem(FinancePayApplyItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
