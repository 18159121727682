/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-29T19:23:13.413
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listReportDayAllTargetedValue,
	listReportDayAllTargetedValueInit,
	listReportDayAllTargetedValueByAuthorizations,
	deleteReportDayAllTargetedValue,
	queryReportDayAllTargetedValue

} from '@api/dashboard/ReportDayAllTargetedValue';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listReportDayAllTargetedValue({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportDayAllTargetedValue(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportDayAllTargetedValueByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportDayAllTargetedValueByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities = res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportDayAllTargetedValueInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportDayAllTargetedValueInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportDayAllTargetedValue({
			state
		}, { id }) {
			return new Promise((resolve, reject) => {
				deleteReportDayAllTargetedValue({
					id
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		queryReportDayAllTargetedValue({
			state
		}) {
			return new Promise((resolve, reject) => {
				queryReportDayAllTargetedValue(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
