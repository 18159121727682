/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-21T03:43:01.795
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listManagementAreaChannelInit(data){return request({
url: '/storagemanage/api/managementAreaChannel/listManagementAreaChannelInit',
method: 'post',
data:data,
});
}


export function listManagementAreaChannelByAuthorizations(data){return request({
url: '/storagemanage/api/managementAreaChannel/listManagementAreaChannelByAuthorizations',
method: 'post',
data:data,
});
}


export function listManagementAreaChannel(data){return request({
url: '/storagemanage/api/managementAreaChannel/listManagementAreaChannel',
method: 'post',
data:data,
});
}


export function getManagementAreaChannelCreateInit(){return request({
url: '/storagemanage/api/managementAreaChannel/getManagementAreaChannelCreateInit',
method: 'post',
});
}


export function getManagementAreaChannelEditInit(params){return request({
url: '/storagemanage/api/managementAreaChannel/getManagementAreaChannelEditInit',
method: 'post',
params:params,
});
}


export function getManagementAreaChannelView(params){return request({
url: '/storagemanage/api/managementAreaChannel/getManagementAreaChannelView',
method: 'post',
params:params,
});
}


export function saveManagementAreaChannel(data){return request({
url: '/storagemanage/api/managementAreaChannel/saveManagementAreaChannel',
method: 'post',
data:data,
});
}


export function deleteManagementAreaChannel(params){return request({
url: '/storagemanage/api/managementAreaChannel/deleteManagementAreaChannel',
method: 'post',
params:params,
});
}



export function saveOrgAreaChannelApi(data){return request({
url: '/storagemanage/api/managementAreaChannel/saveOrgAreaChannelByCompany',
method: 'post',
data:data,
});
}



