/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-11T10:54:45.832
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseHoldItemInit(data){return request({
url: '/rentmanage/api/rentHouseHoldItem/listRentHouseHoldItemInit',
method: 'post',
data:data,
});
}


export function listRentHouseHoldItemByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseHoldItem/listRentHouseHoldItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseHoldItem(data){return request({
url: '/rentmanage/api/rentHouseHoldItem/listRentHouseHoldItem',
method: 'post',
data:data,
});
}


export function getRentHouseHoldItemCreateInit(){return request({
url: '/rentmanage/api/rentHouseHoldItem/getRentHouseHoldItemCreateInit',
method: 'post',
});
}


export function getRentHouseHoldItemEditInit(params){return request({
url: '/rentmanage/api/rentHouseHoldItem/getRentHouseHoldItemEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseHoldItemView(params){return request({
url: '/rentmanage/api/rentHouseHoldItem/getRentHouseHoldItemView',
method: 'post',
params:params,
});
}


export function saveRentHouseHoldItem(data){return request({
url: '/rentmanage/api/rentHouseHoldItem/saveRentHouseHoldItem',
method: 'post',
data:data,
});
}


export function deleteRentHouseHoldItem(params){return request({
url: '/rentmanage/api/rentHouseHoldItem/deleteRentHouseHoldItem',
method: 'post',
params:params,
});
}

export function downloadTemplate(data) {
	return request({
		url: '/rentmanage/api/rentHouseHoldItem/downloadRentHouseHoldItemTemplate',
		method: 'post',
		data: data,
		responseType: 'blob'
	});
}

