/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T17:39:58.073
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-ContractSubject-'

export default 
{
path: '/storagemanage/arcontractsubject',
name: 'storagemanage-ContractSubject',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}-index',

meta:{
...meta,
title: '合同主体管理'
},

component: () => import('@/pages/storagemanage/arcontractsubject/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}ContractSubject-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '合同主体管理编辑'
},

component: () => import('@/pages/storagemanage/arcontractsubject/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}ContractSubjectdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '合同主体管理新增'
},

component: () => import('@/pages/storagemanage/arcontractsubject/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}ContractSubjectView',
auth: [
'hidden'
],

meta:{
...meta,
title: '合同主体管理详情查看'
},

component: () => import('@/pages/storagemanage/arcontractsubject/detail-view') ,
},
]
};
