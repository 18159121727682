/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-21T03:43:01.814
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listManagementAreaChannel,
	listManagementAreaChannelInit,
	listManagementAreaChannelByAuthorizations,
	deleteManagementAreaChannel,
	saveOrgAreaChannelApi
	
} from '@api/storagemanage/ManagementAreaChannel';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listManagementAreaChannel({
			state
		}) {
			return new Promise((resolve, reject) => {
				listManagementAreaChannel(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listManagementAreaChannelByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listManagementAreaChannelByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listManagementAreaChannelInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listManagementAreaChannelInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteManagementAreaChannel({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteManagementAreaChannel({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		saveOrgAreaChannel({}, ManagementAreaChannel) {
			return new Promise((resolve, reject) => {
				saveOrgAreaChannelApi(ManagementAreaChannel)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
