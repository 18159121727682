import BasicLayout from '@/layouts/basic-layout';
////第一步：配置菜单-->第二步：配置上门评估页面相关的路由-->第三步：注入路由-->第四步：做页面
const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	name: '上门评估',
	redirect: {
		name: `${pre}doorToDoorEvaluation-list`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'doorToDoorEvaluation-list',
		name: `${pre}doorToDoorEvaluation-list`,
		meta: {
			...meta,
			title: '上门评估',
			closable: true
		},
		component: () => import('@/pages/storagemanage/doorToDoorEvaluation/index')
	}, 
	{
		path: 'doorToDoorEvaluation-startEvaluate',
		name: `${pre}doorToDoorEvaluation-startEvaluate`,
		meta: {
			...meta,
			title: '查看评估',
			closable: true
		},
		component: () => import('@/pages/storagemanage/doorToDoorEvaluation/startEvaluate')
	}]
};
