/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-30T21:02:57.578
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listFinancialClaimItemInit(data) {
	return request({
		url: '/financial/api/financialClaimItem/listFinancialClaimItemInit',
		method: 'post',
		data: data,
	});
}

export function listFinancialClaimItemInfo(data) {
	return request({
		url: '/financial/api/financialClaimItem/listFinancialClaimItemInfo',
		method: 'post',
		data: data,
	});
}


export function listFinancialClaimItemByAuthorizations(data) {
	return request({
		url: '/financial/api/financialClaimItem/listFinancialClaimItemByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listFinancialClaimItem(data) {
	return request({
		url: '/financial/api/financialClaimItem/listFinancialClaimItem',
		method: 'post',
		data: data,
	});
}


export function getFinancialClaimItemCreateInit() {
	return request({
		url: '/financial/api/financialClaimItem/getFinancialClaimItemCreateInit',
		method: 'post',
	});
}


export function getFinancialClaimItemEditInit(params) {
	return request({
		url: '/financial/api/financialClaimItem/getFinancialClaimItemEditInit',
		method: 'post',
		params: params,
	});
}


export function getFinancialClaimItemView(params) {
	return request({
		url: '/financial/api/financialClaimItem/getFinancialClaimItemView',
		method: 'post',
		params: params,
	});
}


export function saveFinancialClaimItem(data) {
	return request({
		url: '/financial/api/financialClaimItem/saveFinancialClaimItem',
		method: 'post',
		data: data,
	});
}


export function deleteFinancialClaimItem(params) {
	return request({
		url: '/financial/api/financialClaimItem/deleteFinancialClaimItem',
		method: 'post',
		params: params,
	});
}
