/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-24T16:43:12.873
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getConAttachmentTestEditInit,
	getConAttachmentTestView
} from '@api/storagemanage/ConAttachmentTest';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		getConAttachmentTestEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getConAttachmentTestEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getConAttachmentTestView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getConAttachmentTestView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
