import request from '@/plugins/request';

export function TreeDataInit(data) {
	return request({
		url: '/datadictionarymanage/api/configtreeManage/listConfigtreeManageTreeInit',
		method: 'post',
		data: data
	});
}

export function GetTreeDataList(data) {
	return request({
		url: '/datadictionarymanage/api/configtreeManage/listConfigtreeManageTree',
		method: 'post',
		data: data
	});
}


export function GetDataList(data) {
	return request({
		url: '/datadictionarymanage/api/configtreeManage/listConfigtreeManage',
		method: 'post',
		data: data
	});
}

export function AddOrEditData(data) {
	return request({
		url: '/datadictionarymanage/api/configtreeManage/saveConfigtreeManage',
		method: 'post',
		data: data
	});
}

export function editPageInitReq(data) {
	return request({
		url: '/datadictionarymanage/api/configtreeManage/getConfigtreeManageEditInit',
		method: 'post',
		params: data
	});
}

export function deteleRecordReq(data) {
	return request({
		url: '/datadictionarymanage/api/configtreeManage/deleteConfigtreeManage',
		method: 'post',
		params: data
	});
}
