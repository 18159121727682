/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-10T11:30:43.703
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listContractSubjectManageInit(data) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/listContractSubjectManageInit',
		method: 'post',
		data: data,
	});
}


export function listContractSubjectManageByAuthorizations(data) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/listContractSubjectManageByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listContractSubjectManage(data) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/listContractSubjectManage',
		method: 'post',
		data: data,
	});
}


export function getContractSubjectManageCreateInit() {
	return request({
		url: '/storagemanage/api/contractSubjectManage/getContractSubjectManageCreateInit',
		method: 'post',
	});
}


export function getContractSubjectManageEditInit(params) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/getContractSubjectManageEditInit',
		method: 'post',
		params: params,
	});
}


export function getContractSubjectManageView(params) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/getContractSubjectManageView',
		method: 'post',
		params: params,
	});
}


export function saveContractSubjectManage(data) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/saveContractSubjectManage',
		method: 'post',
		data: data,
	});
}


export function deleteContractSubjectManage(params) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/deleteContractSubjectManage',
		method: 'post',
		params: params,
	});
}

export function authenticationSubject(params) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/authenticationSubject',
		method: 'get',
		params: params,
	});
}

export function telecom3FactorsBdhxMhxc2024(params) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/telecom3FactorsBdhxMhxc2024',
		method: 'get',
		params: params,
	});
}

export function authenticationSubject2(params) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/authenticationSubject2',
		method: 'get',
		params: params,
	});
}


export function sendFaceAuth(params) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/sendFaceAuth',
		method: 'get',
		params: params,
	});
}