/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-07-02T16:30:29.112
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialRentContractItemPaymentCollectionMonthInit(data){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollectionMonth/listReportFinancialRentContractItemPaymentCollectionMonthInit',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractItemPaymentCollectionMonthByAuthorizations(data){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollectionMonth/listReportFinancialRentContractItemPaymentCollectionMonthByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractItemPaymentCollectionMonth(data){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollectionMonth/listReportFinancialRentContractItemPaymentCollectionMonth',
method: 'post',
data:data,
});
}


export function getReportFinancialRentContractItemPaymentCollectionMonthCreateInit(){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollectionMonth/getReportFinancialRentContractItemPaymentCollectionMonthCreateInit',
method: 'post',
});
}


export function getReportFinancialRentContractItemPaymentCollectionMonthEditInit(params){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollectionMonth/getReportFinancialRentContractItemPaymentCollectionMonthEditInit',
method: 'post',
params:params,
});
}


export function getReportFinancialRentContractItemPaymentCollectionMonthView(params){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollectionMonth/getReportFinancialRentContractItemPaymentCollectionMonthView',
method: 'post',
params:params,
});
}


export function saveReportFinancialRentContractItemPaymentCollectionMonth(data){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollectionMonth/saveReportFinancialRentContractItemPaymentCollectionMonth',
method: 'post',
data:data,
});
}


export function deleteReportFinancialRentContractItemPaymentCollectionMonth(params){return request({
url: '/report/api/reportFinancialRentContractItemPaymentCollectionMonth/deleteReportFinancialRentContractItemPaymentCollectionMonth',
method: 'post',
params:params,
});
}


