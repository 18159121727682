/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-23T14:56:33.658
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentGoodsSortInit(data){return request({
url: '/rentmanage/api/rentGoodsSort/listRentGoodsSortInit',
method: 'post',
data:data,
});
}


export function listRentGoodsSortByAuthorizations(data){return request({
url: '/rentmanage/api/rentGoodsSort/listRentGoodsSortByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentGoodsSort(data){return request({
url: '/rentmanage/api/rentGoodsSort/listRentGoodsSort',
method: 'post',
data:data,
});
}


export function getRentGoodsSortCreateInit(){return request({
url: '/rentmanage/api/rentGoodsSort/getRentGoodsSortCreateInit',
method: 'post',
});
}


export function getRentGoodsSortEditInit(params){return request({
url: '/rentmanage/api/rentGoodsSort/getRentGoodsSortEditInit',
method: 'post',
params:params,
});
}


export function getRentGoodsSortView(params){return request({
url: '/rentmanage/api/rentGoodsSort/getRentGoodsSortView',
method: 'post',
params:params,
});
}


export function saveRentGoodsSort(data){return request({
url: '/rentmanage/api/rentGoodsSort/saveRentGoodsSort',
method: 'post',
data:data,
});
}


export function deleteRentGoodsSort(params){return request({
url: '/rentmanage/api/rentGoodsSort/deleteRentGoodsSort',
method: 'post',
params:params,
});
}


