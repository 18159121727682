/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-24T14:53:19.354
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseReformItemInit(data){return request({
url: '/rentmanage/api/rentHouseReformItem/listRentHouseReformItemInit',
method: 'post',
data:data,
});
}


export function listRentHouseReformItemByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseReformItem/listRentHouseReformItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseReformItem(data){return request({
url: '/rentmanage/api/rentHouseReformItem/listRentHouseReformItem',
method: 'post',
data:data,
});
}


export function getRentHouseReformItemCreateInit(){return request({
url: '/rentmanage/api/rentHouseReformItem/getRentHouseReformItemCreateInit',
method: 'post',
});
}


export function getRentHouseReformItemEditInit(params){return request({
url: '/rentmanage/api/rentHouseReformItem/getRentHouseReformItemEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseReformItemView(params){return request({
url: '/rentmanage/api/rentHouseReformItem/getRentHouseReformItemView',
method: 'post',
params:params,
});
}


export function saveRentHouseReformItem(data){return request({
url: '/rentmanage/api/rentHouseReformItem/saveRentHouseReformItem',
method: 'post',
data:data,
});
}


export function deleteRentHouseReformItem(params){return request({
url: '/rentmanage/api/rentHouseReformItem/deleteRentHouseReformItem',
method: 'post',
params:params,
});
}


