/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-10-13T17:19:55.633
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentSerComplaintFollowupInit(data){return request({
url: '/rentmanage/api/rentSerComplaintFollowup/listRentSerComplaintFollowupInit',
method: 'post',
data:data,
});
}


export function listRentSerComplaintFollowupByAuthorizations(data){return request({
url: '/rentmanage/api/rentSerComplaintFollowup/listRentSerComplaintFollowupByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentSerComplaintFollowup(data){return request({
url: '/rentmanage/api/rentSerComplaintFollowup/listRentSerComplaintFollowup',
method: 'post',
data:data,
});
}


export function getRentSerComplaintFollowupCreateInit(){return request({
url: '/rentmanage/api/rentSerComplaintFollowup/getRentSerComplaintFollowupCreateInit',
method: 'post',
});
}


export function getRentSerComplaintFollowupEditInit(params){return request({
url: '/rentmanage/api/rentSerComplaintFollowup/getRentSerComplaintFollowupEditInit',
method: 'post',
params:params,
});
}


export function getRentSerComplaintFollowupView(params){return request({
url: '/rentmanage/api/rentSerComplaintFollowup/getRentSerComplaintFollowupView',
method: 'post',
params:params,
});
}


export function saveRentSerComplaintFollowup(data){return request({
url: '/rentmanage/api/rentSerComplaintFollowup/saveRentSerComplaintFollowup',
method: 'post',
data:data,
});
}


export function deleteRentSerComplaintFollowup(params){return request({
url: '/rentmanage/api/rentSerComplaintFollowup/deleteRentSerComplaintFollowup',
method: 'post',
params:params,
});
}


