/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-15T11:07:06.868
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentHouseSalesCard,
	listRentHouseSalesCardInit,
	listRentHouseSalesCardByAuthorizations,
	deleteRentHouseSalesCard,
	getRentList,
	saveRent,
	withdrawCard,
	handleDownLoadUnallocatedCards
	
} from '@api/rentmanage/RentHouseSalesCard';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentHouseSalesCard({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseSalesCard(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseSalesCardByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseSalesCardByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseSalesCardInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseSalesCardInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseSalesCard({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseSalesCard({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		getRentList({
			state
		}, params) {
			return new Promise((resolve, reject) => {
				getRentList(params)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		saveRent({
			state
		}, params) {
			return new Promise((resolve, reject) => {
				saveRent(params)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		handleDownLoadUnallocatedCards({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				handleDownLoadUnallocatedCards(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		withdrawCard({
			state
		}, params) {
			return new Promise((resolve, reject) => {
				withdrawCard(params)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
