/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-02T18:37:11.278
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listInvoiceToolsHouseInit(data){return request({
url: '/smallapplication/api/invoiceToolsHouse/listInvoiceToolsHouseInit',
method: 'post',
data:data,
});
}


export function listInvoiceToolsHouseByAuthorizations(data){return request({
url: '/smallapplication/api/invoiceToolsHouse/listInvoiceToolsHouseByAuthorizations',
method: 'post',
data:data,
});
}


export function listInvoiceToolsHouse(data){return request({
url: '/smallapplication/api/invoiceToolsHouse/listInvoiceToolsHouse',
method: 'post',
data:data,
});
}


export function getInvoiceToolsHouseCreateInit(){return request({
url: '/smallapplication/api/invoiceToolsHouse/getInvoiceToolsHouseCreateInit',
method: 'post',
});
}


export function getInvoiceToolsHouseEditInit(params){return request({
url: '/smallapplication/api/invoiceToolsHouse/getInvoiceToolsHouseEditInit',
method: 'post',
params:params,
});
}


export function getInvoiceToolsHouseView(params){return request({
url: '/smallapplication/api/invoiceToolsHouse/getInvoiceToolsHouseView',
method: 'post',
params:params,
});
}


export function saveInvoiceToolsHouse(data){return request({
url: '/smallapplication/api/invoiceToolsHouse/saveInvoiceToolsHouse',
method: 'post',
data:data,
});
}


export function deleteInvoiceToolsHouse(params){return request({
url: '/smallapplication/api/invoiceToolsHouse/deleteInvoiceToolsHouse',
method: 'post',
params:params,
});
}

export function downloadResult(){return request({
url: '/smallapplication/api/invoiceToolsHouse/downloadResult',
method: 'get',
});
}

export function messageNoticeByLastYear(){return request({
url: '/smallapplication/api/invoiceToolsHouse/messageNoticeByLastYear',
method: 'get',
});
}


