/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T15:03:08.756
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveAssignedBillItem,
	getAssignedBillItemCreateInit,
	getAssignedBillItemEditInit,
	getAssignedBillItemView,
	deleteAssignedBillItem
} from '@api/storagemanage/AssignedBillItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getAssignedBillItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getAssignedBillItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getAssignedBillItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getAssignedBillItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveAssignedBillItem({}, AssignedBillItem) {
			return new Promise((resolve, reject) => {
				saveAssignedBillItem(AssignedBillItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getAssignedBillItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getAssignedBillItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteAssignedBillItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteAssignedBillItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
