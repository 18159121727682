/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:57.589
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listHouseRentalInfoInit(data){return request({
url: '/lcf/api/houseRentalInfo/listHouseRentalInfoInit',
method: 'post',
data:data,
});
}


export function listHouseRentalInfoByAuthorizations(data){return request({
url: '/lcf/api/houseRentalInfo/listHouseRentalInfoByAuthorizations',
method: 'post',
data:data,
});
}


export function listHouseRentalInfo(data){return request({
url: '/lcf/api/houseRentalInfo/listHouseRentalInfo',
method: 'post',
data:data,
});
}


export function getHouseRentalInfoCreateInit(){return request({
url: '/lcf/api/houseRentalInfo/getHouseRentalInfoCreateInit',
method: 'post',
});
}


export function getHouseRentalInfoEditInit(params){return request({
url: '/lcf/api/houseRentalInfo/getHouseRentalInfoEditInit',
method: 'post',
params:params,
});
}


export function getHouseRentalInfoView(params){return request({
url: '/lcf/api/houseRentalInfo/getHouseRentalInfoView',
method: 'post',
params:params,
});
}


export function saveHouseRentalInfo(data){return request({
url: '/lcf/api/houseRentalInfo/saveHouseRentalInfo',
method: 'post',
data:data,
});
}


export function deleteHouseRentalInfo(params){return request({
url: '/lcf/api/houseRentalInfo/deleteHouseRentalInfo',
method: 'post',
params:params,
});
}


