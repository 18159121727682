/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-29T19:23:13.427
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportDayAllTargetedValue,
	getReportDayAllTargetedValueCreateInit,
	getReportDayAllTargetedValueEditInit,
	getReportDayAllTargetedValueView,
	deleteReportDayAllTargetedValue
} from '@api/dashboard/ReportDayAllTargetedValue';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportDayAllTargetedValueCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportDayAllTargetedValueCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportDayAllTargetedValueEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportDayAllTargetedValueEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportDayAllTargetedValue({}, ReportDayAllTargetedValue) {
			return new Promise((resolve, reject) => {
				saveReportDayAllTargetedValue(ReportDayAllTargetedValue)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportDayAllTargetedValueView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportDayAllTargetedValueView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportDayAllTargetedValue({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportDayAllTargetedValue({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
