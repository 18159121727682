/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-20T16:51:35.111
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancialPayApply,
	getFinancialPayApplyCreateInit,
	getFinancialPayApplyEditInit,
	getFinancialPayApplyView,
	deleteFinancialPayApply
} from '@api/rentmanage/FinancialPayApply';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listFinancialPayApplyItemInit,
listFinancialPayApplyItemByAuthorizations,
deleteFinancialPayApplyItem
} from '@api/rentmanage/FinancialPayApplyItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialPayApplyCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayApplyCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialPayApplyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayApplyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialPayApply({}, FinancialPayApply) {
			return new Promise((resolve, reject) => {
				saveFinancialPayApply(FinancialPayApply)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialPayApplyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayApplyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialPayApply({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialPayApply({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
