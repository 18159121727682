import BasicLayout from '@/layouts/basic-layout';

const meta = {
  auth: ['dashboard']
};

const pre = 'dashboard-';

export default {
  path: '/dashboard',
  name: 'dashboard',
  redirect: {
    name: `${pre}console`
  },
  meta,
  component: BasicLayout,
  children: [
    {
      path: 'console',
      name: `${pre}console`,
      meta: {
        ...meta,
        title: '主控台',
        closable: false,
      },
      component: () => import('@/pages/dashboard/console')
    },
    {
      path: 'reportday',
      name: `${pre}reportday`,
      meta: {
        ...meta,
        title: '日报报表'
      },
      component: () => import('@/pages/dashboard/reportdayalltargetedvalue')
    }
  ]
};
