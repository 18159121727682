/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-07-10T17:00:05.644
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listFinancialSettlementInit(data) {
	return request({
		url: '/financial/api/financialSettlement/listFinancialSettlementInit',
		method: 'post',
		data: data,
	});
}


export function listFinancialSettlementByAuthorizations(data) {
	return request({
		url: '/financial/api/financialSettlement/listFinancialSettlementByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listFinancialSettlement(data) {
	return request({
		url: '/financial/api/financialSettlement/listFinancialSettlement',
		method: 'post',
		data: data,
	});
}


export function getFinancialSettlementCreateInit() {
	return request({
		url: '/financial/api/financialSettlement/getFinancialSettlementCreateInit',
		method: 'post',
	});
}


export function getFinancialSettlementEditInit(params) {
	return request({
		url: '/financial/api/financialSettlement/getFinancialSettlementEditInit',
		method: 'post',
		params: params,
	});
}


export function getFinancialSettlementView(params) {
	return request({
		url: '/financial/api/financialSettlement/getFinancialSettlementView',
		method: 'post',
		params: params,
	});
}


export function saveFinancialSettlement(data) {
	return request({
		url: '/financial/api/financialSettlement/saveFinancialSettlement',
		method: 'post',
		data: data,
	});
}


export function deleteFinancialSettlement(params) {
	return request({
		url: '/financial/api/financialSettlement/deleteFinancialSettlement',
		method: 'post',
		params: params,
	});
}


export function financialSettlementPayment(data) {
	return request({
		url: '/financial/api/financialSettlement/financialSettlementPayment',
		method: 'post',
		data: data,
	});
}

export function financialSettlementReceipt(data) {
	return request({
		url: '/financial/api/financialSettlement/financialSettlementReceipt',
		method: 'post',
		data: data,
	});
}

export function financialSettlementEffect(params) {
	return request({
		url: '/financial/api/financialSettlement/financialSettlementEffect',
		method: 'post',
		params: params,
	});
}

export function generatePreFinancialSettlement(params) {
	return request({
		url: '/financial/api/financialSettlement/generatePreFinancialSettlement',
		method: 'post',
		params: params,
	});
}

export function generateFinancialSettlement(data) {
	return request({
		url: '/financial/api/financialSettlement/generateFinancialSettlement',
		method: 'post',
		data: data,
	});
}