/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-10-13T17:19:55.482
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
  auth: true
};

const pre = 'rentmanage-RentSerComplaint-'

export default {
  path: '/rentmanage/rentsercomplaint',
  redirect: {
    name: `${pre}index`
  },

  meta: {
    ...meta,
  },

  component: BasicLayout,
  children:
    [
      {
        path: 'index',
        name: `${pre}index`,

        meta: {
          ...meta,
          title: '投诉信息'
        },

        component: () => import('@/pages/rentmanage/rentsercomplaint/index'),
      },
      {
        path: 'detail-edit/:id',
        name: `${pre}detail-edit`,
        auth: [
          'hidden'
        ],

        meta: {
          ...meta,
          title: '投诉信息编辑'
        },

        component: () => import('@/pages/rentmanage/rentsercomplaint/detail-edit'),
      },
      {
        path: 'detail-edit',
        name: `${pre}detail-create`,
        auth: [
          'hidden'
        ],

        meta: {
          ...meta,
          title: '投诉信息新增'
        },

        component: () => import('@/pages/rentmanage/rentsercomplaint/detail-edit'),
      },
      {
        path: 'detail-view/:id',
        name: `${pre}detail-view`,
        auth: [
          'hidden'
        ],

        meta: {
          ...meta,
          title: '投诉信息详情查看'
        },

        component: () => import('@/pages/rentmanage/rentsercomplaint/detail-view'),
      },
    ]
};
