import util from '@/libs/util';
import router from '@/router';
import {
	listEvaluateBill,
	listEvaluateBillInit,
	listEvaluateBillByAuthorizations,
	deleteEvaluateBill,
	listEvaluateBillInitByHandSQL
	
} from '@api/storagemanage/EvaluateBill';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
		//是否查看
		IsEdit:false,
	},

	actions: {
		listEvaluateBill({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBill(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateBillByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillInitByHandSQL(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateBillInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillInitByHandSQL(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateBill({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateBill({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
