/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T17:39:58.032
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';


export function saveContractSubjectAggregateRootItem(data){return request({
url: '/storagemanage/api/contractSubjectAggregateRoot/saveContractSubjectAggregateRootItem',
method: 'post',
data:data,
});
}

export function listContractSubjectAggregateRootInit(data){return request({
url: '/storagemanage/api/contractSubjectAggregateRoot/listContractSubjectAggregateRootInit',
method: 'post',
data:data,
});
}

export function listContractSubjectAggregateRoot(data){return request({
url: '/storagemanage/api/contractSubjectAggregateRoot/listContractSubjectAggregateRoot',
method: 'post',
data:data,
});
}

export function deleteContractSubjectAggregateRootItem(params){return request({
url: '/storagemanage/api/contractSubjectAggregateRoot/deleteContractSubjectAggregateRootItem',
method: 'post',
params:params,
});
}

export function getContractSubjectAggregateRootItemEditInit(params){return request({
url: '/storagemanage/api/contractSubjectAggregateRoot/getContractSubjectAggregateRootItemEditInit',
method: 'post',
params:params,
});
}

export function getContractSubjectAggregateRootItemCreateInit(params){return request({
url: '/storagemanage/api/contractSubjectAggregateRoot/getContractSubjectAggregateRootItemCreateInit',
method: 'post',
params:params,
});
}

export function deleteContractSubjectAcount(params){return request({
url: '/storagemanage/api/contractSubjectAggregateRoot/deleteContractSubjectAcount',
method: 'post',
params:params,
});
}


