/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-11T09:42:55.413
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportfinancialrentcontractitemcollection-'

export default 
{
path: '/report/reportfinancialrentcontractitemcollection',
name: 'report-reportfinancialrentcontractitemcollection',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportfinancialrentcontractitemcollection-index',

meta:{
...meta,
title: '实收报表'
},

component: () => import('@/pages/report/reportfinancialrentcontractitemcollection/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportfinancialrentcontractitemcollection-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '实收报表编辑'
},

component: () => import('@/pages/report/reportfinancialrentcontractitemcollection/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportfinancialrentcontractitemcollectiondetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '实收报表新增'
},

component: () => import('@/pages/report/reportfinancialrentcontractitemcollection/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportfinancialrentcontractitemcollectionView',
auth: [
'hidden'
],

meta:{
...meta,
title: '实收报表详情查看'
},

component: () => import('@/pages/report/reportfinancialrentcontractitemcollection/detail-view') ,
},
]
};
