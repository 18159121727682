import BasicLayout from '@/layouts/basic-layout';
////第一步：配置菜单-->第二步：配置存房委托指派评估页面相关的路由-->第三步：注入路由-->第四步：做页面
const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	name: '指派评估渠道',
	redirect: {
		name: `${pre}houseSaveDelegationAssessment-list`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'houseSaveDelegationAssessment-list',
		name: `${pre}houseSaveDelegationAssessment-list`,
		meta: {
			...meta,
			title: '指派评估渠道',//存房委托指派评估
			closable: true
		},
		component: () => import('@/pages/storagemanage/houseSaveDelegationAssessment/index')
	}]
};
