/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-08T11:03:21.5
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getRentMaterialReceivingMainFormEditInit,
	getRentMaterialReceivingMainFormView
} from '@api/rentmanage/RentMaterialReceivingMainForm';

import {
listRentMaterialReceivingFormInit,
listRentMaterialReceivingFormByAuthorizations,
deleteRentMaterialReceivingForm
} from '@api/rentmanage/RentMaterialReceivingForm';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getRentMaterialReceivingMainFormEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentMaterialReceivingMainFormEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentMaterialReceivingMainFormView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentMaterialReceivingMainFormView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
