/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-20T12:07:15.824
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listAuthFuncRoleManageInit(data) {
	return request({
		url: '/systemmanage/api/authFuncRoleManage/listAuthFuncRoleManageInit',
		method: 'post',
		data: data,
	});
}


export function listAuthFuncRoleManageByAuthorizations(data) {
	return request({
		url: '/systemmanage/api/authFuncRoleManage/listAuthFuncRoleManageByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listAuthFuncRoleManage(data) {
	return request({
		url: '/systemmanage/api/authFuncRoleManage/listAuthFuncRoleManage',
		method: 'post',
		data: data,
	});
}


export function getAuthFuncRoleManageCreateInit() {
	return request({
		url: '/systemmanage/api/authFuncRoleManage/getAuthFuncRoleManageCreateInit',
		method: 'post',
	});
}


export function getAuthFuncRoleManageEditInit(params) {
	return request({
		url: '/systemmanage/api/authFuncRoleManage/getAuthFuncRoleManageEditInit',
		method: 'post',
		params: params,
	});
}


export function getAuthFuncRoleManageView(params) {
	return request({
		url: '/systemmanage/api/authFuncRoleManage/getAuthFuncRoleManageView',
		method: 'post',
		params: params,
	});
}


export function saveAuthFuncRoleManage(data) {
	return request({
		url: '/systemmanage/api/authFuncRoleManage/saveAuthFuncRoleManage',
		method: 'post',
		data: data,
	});
}


export function deleteAuthFuncRoleManage(params) {
	return request({
		url: '/systemmanage/api/authFuncRoleManage/deleteAuthFuncRoleManage',
		method: 'post',
		params: params,
	});
}

export function getAuthMenuTree(data) {
	return request({
		url: '/systemmanage/api/authFuncRoleManage/getAuthMenuTree',
		method: 'post',
		data: data,
	});
}

export function getAuthMenuListByRoleId(params) {
	return request({
		url: '/systemmanage/api/authFuncRoleManage/getAuthMenuListByRoleId',
		method: 'post',
		params: params,
	});
}

export function saveRoleAuthMenuList(data) {
	return request({
		url: '/systemmanage/api/authFuncRoleManage/saveRoleAuthMenuList',
		method: 'post',
		data: data,
	});
}
