/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-17T17:56:04.298
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentIssueHouseApplyInit(data){return request({
url: '/rentmanage/api/rentIssueHouseApply/listRentIssueHouseApplyInit',
method: 'post',
data:data,
});
}


export function listRentIssueHouseApplyByAuthorizations(data){return request({
url: '/rentmanage/api/rentIssueHouseApply/listRentIssueHouseApplyByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentIssueHouseApply(data){return request({
url: '/rentmanage/api/rentIssueHouseApply/listRentIssueHouseApply',
method: 'post',
data:data,
});
}


export function getRentIssueHouseApplyCreateInit(){return request({
url: '/rentmanage/api/rentIssueHouseApply/getRentIssueHouseApplyCreateInit',
method: 'post',
});
}


export function getRentIssueHouseApplyEditInit(params){return request({
url: '/rentmanage/api/rentIssueHouseApply/getRentIssueHouseApplyEditInit',
method: 'post',
params:params,
});
}


export function getRentIssueHouseApplyView(params){return request({
url: '/rentmanage/api/rentIssueHouseApply/getRentIssueHouseApplyView',
method: 'post',
params:params,
});
}


export function saveRentIssueHouseApply(data){return request({
url: '/rentmanage/api/rentIssueHouseApply/saveRentIssueHouseApply',
method: 'post',
data:data,
});
}


export function deleteRentIssueHouseApply(params){return request({
url: '/rentmanage/api/rentIssueHouseApply/deleteRentIssueHouseApply',
method: 'post',
params:params,
});
}

export function startWorkflow(data){return request({
	url: '/rentmanage/api/rentIssueHouseApply/startWorkflow',
	method: 'post',
	data:data,
	});
	}
