/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-28T14:14:36.513
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialCollectionInit(data){return request({
url: '/financial/api/financialCollection/listFinancialCollectionInit',
method: 'post',
data:data,
});
}


export function listFinancialCollectionByAuthorizations(data){return request({
url: '/financial/api/financialCollection/listFinancialCollectionByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialCollection(data){return request({
url: '/financial/api/financialCollection/listFinancialCollection',
method: 'post',
data:data,
});
}


export function getFinancialCollectionCreateInit(){return request({
url: '/financial/api/financialCollection/getFinancialCollectionCreateInit',
method: 'post',
});
}


export function getFinancialCollectionEditInit(params){return request({
url: '/financial/api/financialCollection/getFinancialCollectionEditInit',
method: 'post',
params:params,
});
}


export function getFinancialCollectionView(params){return request({
url: '/financial/api/financialCollection/getFinancialCollectionView',
method: 'post',
params:params,
});
}


export function saveFinancialCollection(data){return request({
url: '/financial/api/financialCollection/saveFinancialCollection',
method: 'post',
data:data,
});
}


export function deleteFinancialCollection(params){return request({
url: '/financial/api/financialCollection/deleteFinancialCollection',
method: 'post',
params:params,
});
}

export function paymentFinancialCollection(data){return request({
url: '/financial/api/financialCollection/paymentFinancialCollection',
method: 'post',
data:data,
});
}

export function cancellationFinancialCollection(params){return request({
url: '/financial/api/financialCollection/cancellationFinancialCollection',
method: 'post',
params:params,
});
}

export function cardCheck(data){return request({
url: '/financial/api/financialCollection/cardCheck',
method: 'post',
data:data,
});
}

export function queryElectromicAmount(params){return request({
url: '/financial/api/financialCollection/queryElectromicAmount',
method: 'post',
params:params,
});
}