/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-07-02T17:43:04.05
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialRentContractItemPaymentMonthInit(data){return request({
url: '/report/api/reportFinancialRentContractItemPaymentMonth/listReportFinancialRentContractItemPaymentMonthInit',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractItemPaymentMonthByAuthorizations(data){return request({
url: '/report/api/reportFinancialRentContractItemPaymentMonth/listReportFinancialRentContractItemPaymentMonthByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractItemPaymentMonth(data){return request({
url: '/report/api/reportFinancialRentContractItemPaymentMonth/listReportFinancialRentContractItemPaymentMonth',
method: 'post',
data:data,
});
}


export function getReportFinancialRentContractItemPaymentMonthCreateInit(){return request({
url: '/report/api/reportFinancialRentContractItemPaymentMonth/getReportFinancialRentContractItemPaymentMonthCreateInit',
method: 'post',
});
}


export function getReportFinancialRentContractItemPaymentMonthEditInit(params){return request({
url: '/report/api/reportFinancialRentContractItemPaymentMonth/getReportFinancialRentContractItemPaymentMonthEditInit',
method: 'post',
params:params,
});
}


export function getReportFinancialRentContractItemPaymentMonthView(params){return request({
url: '/report/api/reportFinancialRentContractItemPaymentMonth/getReportFinancialRentContractItemPaymentMonthView',
method: 'post',
params:params,
});
}


export function saveReportFinancialRentContractItemPaymentMonth(data){return request({
url: '/report/api/reportFinancialRentContractItemPaymentMonth/saveReportFinancialRentContractItemPaymentMonth',
method: 'post',
data:data,
});
}


export function deleteReportFinancialRentContractItemPaymentMonth(params){return request({
url: '/report/api/reportFinancialRentContractItemPaymentMonth/deleteReportFinancialRentContractItemPaymentMonth',
method: 'post',
params:params,
});
}


