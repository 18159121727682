/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-04-28T09:57:01.086
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-Block-'

export default 
{
path: '/storagemanage/block',
name: 'storagemanage-Block',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}Block-index',

meta:{
...meta,
title: '楼栋'
},

component: () => import('@/pages/storagemanage/block/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}Block-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '楼栋编辑'
},

component: () => import('@/pages/storagemanage/block/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}Blockdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '楼栋新增'
},

component: () => import('@/pages/storagemanage/block/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}BlockView',
auth: [
'hidden'
],

meta:{
...meta,
title: '楼栋详情查看'
},

component: () => import('@/pages/storagemanage/block/detail-view') ,
},
]
};
