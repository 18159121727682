/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T10:58:26.413
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinancialEvidenceDetail,
	listFinancialEvidenceDetailInit,
	listFinancialEvidenceDetailByAuthorizations,
	deleteFinancialEvidenceDetail
} from '@api/financial/FinancialEvidenceDetail';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFinancialEvidenceDetail({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialEvidenceDetail(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialEvidenceDetailByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialEvidenceDetailByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialEvidenceDetailInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialEvidenceDetailInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialEvidenceDetail({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialEvidenceDetail({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
