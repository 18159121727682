/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-07-10T17:00:05.661
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'financial-FinancialSettlement-'

export default {
	path: '/financial/financialsettlement',
	name: 'financial-FinancialSettlement',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}FinancialSettlement-index',

			meta: {
				...meta,
				title: '结算管理'
			},

			component: () => import('@/pages/financial/financialsettlement/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}FinancialSettlement-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '结算管理编辑'
			},

			component: () => import('@/pages/financial/financialsettlement/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}FinancialSettlementdetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '结算管理新增'
			},

			component: () => import('@/pages/financial/financialsettlement/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}FinancialSettlementView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '结算管理详情查看'
			},

			component: () => import('@/pages/financial/financialsettlement/detail-view'),
		},
		{
			path: 'pre-detail-view/:id',
			name: '${pre}FinancialSettlementViewPre',
			auth: [
				'hidden'
			],
			
			meta: {
				...meta,
				title: '财务预结算帐单'
			},
		
			component: () => import('@/pages/financial/financialsettlement/pre-detail-view'),
		},
	]
};
