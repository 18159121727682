import request from '@/plugins/request';

export function toPayApi(data) {
	return request({
		url: '/pay/api/order/createPre',
		method: 'post',
		params: data
	});
}

