/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-07-02T17:43:04.086
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportFinancialRentContractItemPaymentMonth,
	getReportFinancialRentContractItemPaymentMonthCreateInit,
	getReportFinancialRentContractItemPaymentMonthEditInit,
	getReportFinancialRentContractItemPaymentMonthView,
	deleteReportFinancialRentContractItemPaymentMonth
} from '@api/report/ReportFinancialRentContractItemPaymentMonth';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportFinancialRentContractItemPaymentMonthCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemPaymentMonthCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportFinancialRentContractItemPaymentMonthEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemPaymentMonthEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportFinancialRentContractItemPaymentMonth({}, ReportFinancialRentContractItemPaymentMonth) {
			return new Promise((resolve, reject) => {
				saveReportFinancialRentContractItemPaymentMonth(ReportFinancialRentContractItemPaymentMonth)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportFinancialRentContractItemPaymentMonthView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemPaymentMonthView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportFinancialRentContractItemPaymentMonth({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportFinancialRentContractItemPaymentMonth({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
