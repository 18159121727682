import request from '@/plugins/request';

export function getHousePassWord(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContract/getHousePassWord',
		method: 'get',
        params: params,
	});
}

