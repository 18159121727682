/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-14T15:25:43.882
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentLeaseContractReceiptAgreementInit(data){return request({
url: '/rentmanage/api/rentLeaseContractReceiptAgreement/listRentLeaseContractReceiptAgreementInit',
method: 'post',
data:data,
});
}


export function listRentLeaseContractReceiptAgreementByAuthorizations(data){return request({
url: '/rentmanage/api/rentLeaseContractReceiptAgreement/listRentLeaseContractReceiptAgreementByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentLeaseContractReceiptAgreement(data){return request({
url: '/rentmanage/api/rentLeaseContractReceiptAgreement/listRentLeaseContractReceiptAgreement',
method: 'post',
data:data,
});
}


export function getRentLeaseContractReceiptAgreementCreateInit(){return request({
url: '/rentmanage/api/rentLeaseContractReceiptAgreement/getRentLeaseContractReceiptAgreementCreateInit',
method: 'post',
});
}


export function getRentLeaseContractReceiptAgreementEditInit(params){return request({
url: '/rentmanage/api/rentLeaseContractReceiptAgreement/getRentLeaseContractReceiptAgreementEditInit',
method: 'post',
params:params,
});
}


export function getRentLeaseContractReceiptAgreementView(params){return request({
url: '/rentmanage/api/rentLeaseContractReceiptAgreement/getRentLeaseContractReceiptAgreementView',
method: 'post',
params:params,
});
}


export function saveRentLeaseContractReceiptAgreement(data){return request({
url: '/rentmanage/api/rentLeaseContractReceiptAgreement/saveRentLeaseContractReceiptAgreement',
method: 'post',
data:data,
});
}


export function deleteRentLeaseContractReceiptAgreement(params){return request({
url: '/rentmanage/api/rentLeaseContractReceiptAgreement/deleteRentLeaseContractReceiptAgreement',
method: 'post',
params:params,
});
}


