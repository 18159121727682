/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-08-16T18:06:35.479
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialPreOrderInit(data){return request({
url: '/financial/api/financialPreOrder/listFinancialPreOrderInit',
method: 'post',
data:data,
});
}


export function listFinancialPreOrderByAuthorizations(data){return request({
url: '/financial/api/financialPreOrder/listFinancialPreOrderByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialPreOrder(data){return request({
url: '/financial/api/financialPreOrder/listFinancialPreOrder',
method: 'post',
data:data,
});
}


export function getFinancialPreOrderCreateInit(){return request({
url: '/financial/api/financialPreOrder/getFinancialPreOrderCreateInit',
method: 'post',
});
}


export function getFinancialPreOrderEditInit(params){return request({
url: '/financial/api/financialPreOrder/getFinancialPreOrderEditInit',
method: 'post',
params:params,
});
}


export function getFinancialPreOrderView(params){return request({
url: '/financial/api/financialPreOrder/getFinancialPreOrderView',
method: 'post',
params:params,
});
}


export function saveFinancialPreOrder(data){return request({
url: '/financial/api/financialPreOrder/saveFinancialPreOrder',
method: 'post',
data:data,
});
}


export function deleteFinancialPreOrder(params){return request({
url: '/financial/api/financialPreOrder/deleteFinancialPreOrder',
method: 'post',
params:params,
});
}


