<template>
  <div>
    <Table ref="table" :columns="columns" :data="dataWithPage" :loading="loading"
           @on-sort-change="handleSortChange" @on-select="handleSelect"
           @on-select-cancel="handleSelectCancel" @on-select-all="handleSelectAll"
           @on-select-all-cancel="handleSelectAllCancel">

      <template slot-scope="{row}" slot="userType">
        {{ getConfigItemName('user_type', row.userType) }}
      </template>


      <template slot-scope="{row}" slot="userName">
        {{ row.userName }}
      </template>


      <template slot-scope="{row}" slot="userCode">
        {{ row.userCode }}
      </template>


      <template slot-scope="{row}" slot="userMobile">
        {{ row.userMobile }}
      </template>

    </Table>

    <div class="ivu-mt ivu-text-center">
      <Page :total="total" :simple="isMobile"
            :current.sync="AdvanceQuery.pageIndex"
            @on-change="getData"/>
    </div>
  </div>
</template>
<script>
  import {
    mapState,
    mapActions
  } from 'vuex';

  export default {
    computed: {
      ...mapState('admin/layout', ['isMobile']),
      AdvanceQuery () {
        return this.$store.state.systemmanage['orgusermanage/index'].AdvanceQuery;
      },

      rowFunctionAuthorization () {
        if (this.$store.state.systemmanage['orgusermanage/index'].CompoundEntitiesDictionary.rowFunctionAuthorization == null) return '';
        if (this.$store.state.systemmanage['orgusermanage/index'].CompoundEntities.rowFunctionAuthorization.length == 0) {
          return this.$store.state.systemmanage['orgusermanage/index'].CompoundEntitiesDictionary.rowFunctionAuthorization;
        } else {
          return this.$store.state.systemmanage['orgusermanage/index'].CompoundEntities.rowFunctionAuthorization;
        }
      },

      pageFunctionAuthorization () {
        if (this.$store.state.systemmanage['orgusermanage/index'].CompoundEntitiesDictionary.pageFunctionAuthorizations[0] != null) {
          return this.$store.state.systemmanage['orgusermanage/index'].CompoundEntitiesDictionary.pageFunctionAuthorizations[0].rangeAuthorizations;
        } else {
          return '';
        }
      },

      limitData () {
        let data = [...this.list];
        // 动态计算排序类型
        const sortColumns = this.sortColumns;
        if (this.sortType === 'asc') {
          data = data.sort((a, b) => {
            return a[sortColumns] > b[sortColumns] ? 1 : -1;
          });
        }
        if (this.sortType === 'desc') {
          data = data.sort((a, b) => {
            return a[sortColumns] < b[sortColumns] ? 1 : -1;
          });
        }
        // 判断是否有选中的数据
        const selectedNames = this.selectedData.map(item => item.userId);
        data.map(item => {
          item._checked = selectedNames.indexOf(item.userId) >= 0;
          return item;
        });
        return data;
      },

      // 因为要动态计算总页数，所以还需要一个计算属性来返回最终给 Table 的 data
      dataWithPage () {
        const data = this.limitData;
        const start = this.current * this.size - this.size;
        const end = start + this.size;
        return [...data].slice(start, end);
      },

      totalSelectedCount () {
        let count = 0;
        this.selectedData.forEach(item => {
          count += item.count;
        });
        return count;
      }

    },
    methods: {
      ...mapActions('systemmanage/orgusermanage/index', [
        'deleteOrgUserManage', 'listOrgUserManageInit', 'listOrgUserManageByAuthorizations', 'listOrgUserManage'
      ]),

      async init () {
        this.loading = true;
        this.selectedData = [];
        this.listOrgUserManageInit().then((data) => {
          this.total = data.entities.total
          for (let i = 0; i < data.entities.entities.length; i++) {
            data.entities.entities[i]._checked = false;
          }
          this.list = data.entities.entities;
          this.loading = false;
        });
      },

      async getData () {
        this.loading = true;
        this.selectedData = [];
        let data = await this.listOrgUserManageByAuthorizations();
        this.total = data.entities.total;
        for (let i = 0; i < data.entities.entities.length; i++) {
          data.entities.entities[i]._checked = false;
        }
        this.list = data.entities.entities;
        this.loading = false;
      },
      //TAB切换userType
      changeTabuserType (val) {
        this.AdvanceQuery.entity.userType = val;
        this.getData();
      },
      //TAB切换sex
      changeTabsex (val) {
        this.AdvanceQuery.entity.sex = val;
        this.getData();
      },
      //TAB切换adminState
      changeTabadminState (val) {
        this.AdvanceQuery.entity.adminState = val;
        this.getData();
      },
      //TAB切换workflowAdminState
      changeTabworkflowAdminState (val) {
        this.AdvanceQuery.entity.workflowAdminState = val;
        this.getData();
      },
      //需动态生成 key为数据字典的key,获取code对应的名称
      getConfigItemName (key, code) {
        if (this.$store.state.systemmanage['orgusermanage/index'].CompoundEntitiesDictionary.configItems[key] == null) {
          return '';
        }
        let obj = this.$store.state.systemmanage['orgusermanage/index'].CompoundEntitiesDictionary.configItems[key].find(value => value.itemCode ==
          code);
        return obj ? obj.itemName : '';
      },

      getConfigItem (key) {
        if (this.$store.state.systemmanage['orgusermanage/index'].CompoundEntitiesDictionary.configItems[key] == null) {
          return '';
        }
        return this.$store.state.systemmanage['orgusermanage/index'].CompoundEntitiesDictionary.configItems[key];
      },

      // 点击排序按钮时触发
      handleSortChange ({ key, order }) {
        // 将排序保存到数据
        this.AdvanceQuery.orderColumn = key;
        if (order == 'asc') {
          this.AdvanceQuery.isOrderAsc = true;
        } else {
          this.AdvanceQuery.isOrderAsc = false;
        }
        this.AdvanceQuery.isOrder = true;
        this.AdvanceQuery.pageIndex = 1;
        this.getData();
      },

      // 选中一项，将数据添加至已选项中
      handleSelect (selection, row) {
        this.selectedData.push(row);
        console.log(this.selectedData, 'this.selectedData')
      },

      // 取消选中一项，将取消的数据从已选项中删除
      handleSelectCancel (selection, row) {
        const index = this.selectedData.findIndex(item => item.userId === row.userId);
        this.selectedData.splice(index, 1);
      },

      // 当前页全选时，判断已选数据是否存在，不存在则添加
      handleSelectAll (selection) {
        selection.forEach(item => {
          if (this.selectedData.findIndex(i => i.userId === item.userId) < 0) {
            this.selectedData.push(item);
          }
        });
      },

      // 取消当前页全选时，将当前页的数据（即 dataWithPage）从已选项中删除
      handleSelectAllCancel () {
        const selection = this.dataWithPage;
        selection.forEach(item => {
          const index = this.selectedData.findIndex(i => i.userId === item.userId);
          if (index >= 0) {
            this.selectedData.splice(index, 1);
          }
        });
      },

      // 清空所有已选项
      handleClearSelect () {
        this.selectedData = [];
      },

      handleClickItem (name) {
        if (name === 'delete') {
          this.$Modal.confirm({
            title: '删除确认',
            content: '请确认是否批量删除？',
            onOk: () => {
              var bathDelIds = '';
              this.selectedData.forEach(item => {
                bathDelIds += item.userId + ',';
                this.deleteContractSubject({ id: item.userId });
              });
              //调用批量删除接口 bathDelIds
              console.log('bathDelIds: ', bathDelIds);
              this.selectedData = [];
              this.$Message.success('删除成功');
              this.getData();
            },
            onCancel: () => {
              // this.$Message.info('Clicked cancel');
            }
          });
        }
      },

      handleOpenCreate () {
        this.$router.push({
          path: 'detail-edit',
        })
      },

      toDelete (row) {
        this.$Modal.confirm({
          title: '删除确认',
          content: '请确认是否删除？',
          onOk: () => {
            this.deleteOrgUserManage({ id: row.userId }).then(() => {
              this.$Message.success('删除成功');
              this.getData();
            });
          },
          onCancel: () => {
            // this.$Message.info('Clicked cancel');
          }
        });
      },
      getSelectData () {
        return this.selectedData;
      },
      toDetail (row) {
        this.$router.push({
          path: 'detail-view/' + row.userId,
          query: {
            id: row.userId
          }
        })
      },

      // 编辑数据
      toUpdate (row) {
        this.$router.push({
          path: 'detail-edit/' + row.userId,
          query: {
            id: row.userId
          }
        })
      }

    },
    data () {
      return {
        total: 0,
        columns: [
          {
            type: 'selection',
            width: 60,
            align: 'center'
          },
          {
            sortable: 'custom',
            title: '用户名字',
            key: 'userName',
            minWidth: 140,
            tooltip: true,
          },
          {
            sortable: 'custom',
            title: '用户帐户',
            key: 'userCode',
            minWidth: 140,
            tooltip: true,
          },
          {
            sortable: 'custom',
            title: '手机号码',
            key: 'userMobile',
            minWidth: 140,
            tooltip: true,
          },
        ],
        loading: false,
        list: [],
        selectedData: [],
        current: 1,
        size: 10,
        sortType: 'normal', // 当前排序类型，可选值为：normal（默认） || asc（升序）|| desc（降序）,
        sortColumns: '',
        filterType: undefined,
        showCreate: false,
        createData: {
          desc: ''
        },
        createRules: {
          desc: [{
            required: true,
            message: '请输入描述',
            trigger: 'blur'
          }]
        },
        creating: true,
        updateIndex: -1
      }
    },
  }
</script>

