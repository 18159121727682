/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-16T15:24:16.934
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentTakeLookEvaluate,
	listRentTakeLookEvaluateInit,
	listRentTakeLookEvaluateByAuthorizations,
	deleteRentTakeLookEvaluate
} from '@api/rentmanage/RentTakeLookEvaluate';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentTakeLookEvaluate({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTakeLookEvaluate(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentTakeLookEvaluateByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTakeLookEvaluateByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentTakeLookEvaluateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTakeLookEvaluateInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentTakeLookEvaluate({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentTakeLookEvaluate({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
