/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-03T15:07:15.57
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentMaterial,
	getRentMaterialCreateInit,
	getRentMaterialEditInit,
	getRentMaterialView,
	deleteRentMaterial
} from '@api/rentmanage/RentMaterial';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentMaterialCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentMaterialCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentMaterialEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentMaterialEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentMaterial({}, RentMaterial) {
			return new Promise((resolve, reject) => {
				saveRentMaterial(RentMaterial)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentMaterialView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentMaterialView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentMaterial({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentMaterial({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
