/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-10T17:00:05.804
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialSettlementItemInit(data){return request({
url: '/financial/api/financialSettlementItem/listFinancialSettlementItemInit',
method: 'post',
data:data,
});
}


export function listFinancialSettlementItemByAuthorizations(data){return request({
url: '/financial/api/financialSettlementItem/listFinancialSettlementItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialSettlementItem(data){return request({
url: '/financial/api/financialSettlementItem/listFinancialSettlementItem',
method: 'post',
data:data,
});
}


export function getFinancialSettlementItemCreateInit(){return request({
url: '/financial/api/financialSettlementItem/getFinancialSettlementItemCreateInit',
method: 'post',
});
}


export function getFinancialSettlementItemEditInit(params){return request({
url: '/financial/api/financialSettlementItem/getFinancialSettlementItemEditInit',
method: 'post',
params:params,
});
}


export function getFinancialSettlementItemView(params){return request({
url: '/financial/api/financialSettlementItem/getFinancialSettlementItemView',
method: 'post',
params:params,
});
}


export function saveFinancialSettlementItem(data){return request({
url: '/financial/api/financialSettlementItem/saveFinancialSettlementItem',
method: 'post',
data:data,
});
}


export function deleteFinancialSettlementItem(params){return request({
url: '/financial/api/financialSettlementItem/deleteFinancialSettlementItem',
method: 'post',
params:params,
});
}


