/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-10T17:00:05.667
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancialSettlement,
	getFinancialSettlementCreateInit,
	getFinancialSettlementEditInit,
	getFinancialSettlementView,
	deleteFinancialSettlement
} from '@api/financial/FinancialSettlement';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listFinancialSettlementItemInit,
listFinancialSettlementItemByAuthorizations,
deleteFinancialSettlementItem
} from '@api/financial/FinancialSettlementItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialSettlementCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialSettlementCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialSettlementEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialSettlementEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialSettlement({}, FinancialSettlement) {
			return new Promise((resolve, reject) => {
				saveFinancialSettlement(FinancialSettlement)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialSettlementView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialSettlementView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialSettlement({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialSettlement({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
