/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-14T15:25:43.955
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentLeaseContractSale,
	getRentLeaseContractSaleCreateInit,
	getRentLeaseContractSaleEditInit,
	getRentLeaseContractSaleView,
	deleteRentLeaseContractSale,
	getSuperiorUserInfo
} from '@api/rentmanage/RentLeaseContractSale';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentLeaseContractSaleCreateInit({
			state
		},{
			referId
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractSaleCreateInit({
					referId
				})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentLeaseContractSaleEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractSaleEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentLeaseContractSale({}, RentLeaseContractSale) {
			return new Promise((resolve, reject) => {
				saveRentLeaseContractSale(RentLeaseContractSale)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		getRentLeaseContractSaleView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractSaleView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentLeaseContractSale({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentLeaseContractSale({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		getSuperiorUserInfo({}, RentLeaseContractSale) {
			return new Promise((resolve, reject) => {
				getSuperiorUserInfo(RentLeaseContractSale)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
