/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-24T10:10:29.693
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listKhmDemandApplyInit(data){return request({
url: '/rentmanage/api/khmDemandApply/listKhmDemandApplyInit',
method: 'post',
data:data,
});
}

export function startWorkflow(data){return request({
	url: '/rentmanage/api/khmDemandApply/startWorkflow',
	method: 'post',
	data:data,
	});
}
export function listKhmDemandApplyByAuthorizations(data){return request({
url: '/rentmanage/api/khmDemandApply/listKhmDemandApplyByAuthorizations',
method: 'post',
data:data,
});
}


export function listKhmDemandApply(data){return request({
url: '/rentmanage/api/khmDemandApply/listKhmDemandApply',
method: 'post',
data:data,
});
}


export function getKhmDemandApplyCreateInit(){return request({
url: '/rentmanage/api/khmDemandApply/getKhmDemandApplyCreateInit',
method: 'post',
});
}


export function getKhmDemandApplyEditInit(params){return request({
url: '/rentmanage/api/khmDemandApply/getKhmDemandApplyEditInit',
method: 'post',
params:params,
});
}


export function getKhmDemandApplyView(params){return request({
url: '/rentmanage/api/khmDemandApply/getKhmDemandApplyView',
method: 'post',
params:params,
});
}


export function saveKhmDemandApply(data){return request({
url: '/rentmanage/api/khmDemandApply/saveKhmDemandApply',
method: 'post',
data:data,
});
}


export function deleteKhmDemandApply(params){return request({
url: '/rentmanage/api/khmDemandApply/deleteKhmDemandApply',
method: 'post',
params:params,
});
}


