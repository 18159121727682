import request from '@/plugins/request';

export function GetDataList (data) {
    return request({
        url: '/h5platform/api/managerOutShow/listCompositeManagerOutShowInit',
        method: 'post',
		data:data
    });
}

export function pageInitReq (data) {
    return request({
        url: '/h5platform/api/managerOutShow/listManagerOutShowInit',
        method: 'post',
        data
    });
}

export function AddOrEditData (data) {
    return request({
        url: '/h5platform/api/managerOutShow/saveManagerOutShow',
        method: 'post',
        data
    });
}

export function addPageInitReq (data) {
    return request({
        url: '/h5platform/api/managerOutShow/getManagerOutShowCreateInit',
        method: 'post',
        data
    });
}

export function editPageInitReq (data) {
    return request({
        url: '/h5platform/api/managerOutShow/getManagerOutShowEditInit',
        method: 'post',
        params:data
    });
}

