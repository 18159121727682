/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T11:12:57.642
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listChannel,
	listChannelInit,
	listChannelByAuthorizations,
	deleteChannel,
	synchronizationChannel
	
} from '@api/rentmanage/Channel';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listChannel({
			state
		}) {
			return new Promise((resolve, reject) => {
				listChannel(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listChannelByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listChannelByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listChannelInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listChannelInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
                        state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		synchronizationChannel({
			state
		}) {
			return new Promise((resolve, reject) => {
				synchronizationChannel()
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteChannel({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteChannel({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
