/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-01T17:31:37.215
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialAdjustmentApplyDetailInit(data){return request({
url: '/financial/api/financialAdjustmentApplyDetail/listFinancialAdjustmentApplyDetailInit',
method: 'post',
data:data,
});
}


export function listFinancialAdjustmentApplyDetailByAuthorizations(data){return request({
url: '/financial/api/financialAdjustmentApplyDetail/listFinancialAdjustmentApplyDetailByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialAdjustmentApplyDetail(data){return request({
url: '/financial/api/financialAdjustmentApplyDetail/listFinancialAdjustmentApplyDetail',
method: 'post',
data:data,
});
}


export function getFinancialAdjustmentApplyDetailCreateInit(){return request({
url: '/financial/api/financialAdjustmentApplyDetail/getFinancialAdjustmentApplyDetailCreateInit',
method: 'post',
});
}


export function getFinancialAdjustmentApplyDetailEditInit(params){return request({
url: '/financial/api/financialAdjustmentApplyDetail/getFinancialAdjustmentApplyDetailEditInit',
method: 'post',
params:params,
});
}


export function getFinancialAdjustmentApplyDetailView(params){return request({
url: '/financial/api/financialAdjustmentApplyDetail/getFinancialAdjustmentApplyDetailView',
method: 'post',
params:params,
});
}


export function saveFinancialAdjustmentApplyDetail(data){return request({
url: '/financial/api/financialAdjustmentApplyDetail/saveFinancialAdjustmentApplyDetail',
method: 'post',
data:data,
});
}


export function deleteFinancialAdjustmentApplyDetail(params){return request({
url: '/financial/api/financialAdjustmentApplyDetail/deleteFinancialAdjustmentApplyDetail',
method: 'post',
params:params,
});
}


