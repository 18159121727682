/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-01T17:31:37.23
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveFinancialAdjustmentApplyDetail,
	getFinancialAdjustmentApplyDetailCreateInit,
	getFinancialAdjustmentApplyDetailEditInit,
	getFinancialAdjustmentApplyDetailView,
	deleteFinancialAdjustmentApplyDetail
} from '@api/financial/FinancialAdjustmentApplyDetail';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialAdjustmentApplyDetailCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyDetailCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialAdjustmentApplyDetailEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyDetailEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialAdjustmentApplyDetail({}, FinancialAdjustmentApplyDetail) {
			return new Promise((resolve, reject) => {
				saveFinancialAdjustmentApplyDetail(FinancialAdjustmentApplyDetail)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialAdjustmentApplyDetailView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyDetailView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialAdjustmentApplyDetail({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialAdjustmentApplyDetail({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
