/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-11T10:48:26.333
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentTenant,
	listRentTenantInit,
	listRentTenantByAuthorizations,
	deleteRentTenant,
	authenticationTenant,
	getRentTenantList,
	wxAuthenticationTenant,
	telecom3FactorsBdhxMhxc2024,
	authenticationTenant2,
	sendFaceAuth,
	getRentTenant,
	getContractSubjectManage
} from '@api/rentmanage/RentTenant';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentTenant({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTenant(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentTenantByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTenantByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities = res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentTenantList({
			state
		}, data) {
			return new Promise((resolve, reject) => {
				getRentTenantList(data)
					.then(async res => {
						state.CompoundEntities = res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentTenantInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTenantInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentTenant({
			state
		}, { id }) {
			return new Promise((resolve, reject) => {
				deleteRentTenant({
					id
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		authenticationTenant({
			state
		}, data) {
			return new Promise((resolve, reject) => {
				authenticationTenant(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		wxAuthenticationTenant({
			state
		}, data) {
			return new Promise((resolve, reject) => {
				wxAuthenticationTenant(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		telecom3FactorsBdhxMhxc2024({
			state
		}, data) {
			return new Promise((resolve, reject) => {
				telecom3FactorsBdhxMhxc2024(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		authenticationTenant2({
			state
		}, data) {
			return new Promise((resolve, reject) => {
				authenticationTenant2(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		sendFaceAuth({
			state
		}, tenantId) {
			return new Promise((resolve, reject) => {
				sendFaceAuth({
					tenantId: tenantId
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentTenant({
			state
		}, tenantId) {
			return new Promise((resolve, reject) => {
				getRentTenant({
					tenantId: tenantId
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getContractSubjectManage({
			state
		}, subjectId) {
			return new Promise((resolve, reject) => {
				getContractSubjectManage({
					subjectId: subjectId
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
