/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-09T15:06:38.911
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancePaymentItemInit(data){return request({
url: '/storagemanage/api/financePaymentItem/listFinancePaymentItemInit',
method: 'post',
data:data,
});
}


export function listFinancePaymentItemByAuthorizations(data){return request({
url: '/storagemanage/api/financePaymentItem/listFinancePaymentItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancePaymentItem(data){return request({
url: '/storagemanage/api/financePaymentItem/listFinancePaymentItem',
method: 'post',
data:data,
});
}


export function getFinancePaymentItemCreateInit(){return request({
url: '/storagemanage/api/financePaymentItem/getFinancePaymentItemCreateInit',
method: 'post',
});
}


export function getFinancePaymentItemEditInit(params){return request({
url: '/storagemanage/api/financePaymentItem/getFinancePaymentItemEditInit',
method: 'post',
params:params,
});
}


export function getFinancePaymentItemView(params){return request({
url: '/storagemanage/api/financePaymentItem/getFinancePaymentItemView',
method: 'post',
params:params,
});
}


export function saveFinancePaymentItem(data){return request({
url: '/storagemanage/api/financePaymentItem/saveFinancePaymentItem',
method: 'post',
data:data,
});
}


export function deleteFinancePaymentItem(params){return request({
url: '/storagemanage/api/financePaymentItem/deleteFinancePaymentItem',
method: 'post',
params:params,
});
}


