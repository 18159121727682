/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-20T20:38:25.777
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveDroomPropertyDelivery,
	getDroomPropertyDeliveryCreateInit,
	getDroomPropertyDeliveryEditInit,
	getDroomPropertyDeliveryView,
	deleteDroomPropertyDelivery
} from '@api/storagemanage/DroomPropertyDelivery';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listDroomConEstateInfoInit,
listDroomConEstateInfoByAuthorizations,
deleteDroomConEstateInfo
} from '@api/storagemanage/DroomConEstateInfo';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getDroomPropertyDeliveryCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getDroomPropertyDeliveryCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getDroomPropertyDeliveryEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getDroomPropertyDeliveryEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveDroomPropertyDelivery({}, DroomPropertyDelivery) {
			return new Promise((resolve, reject) => {
				saveDroomPropertyDelivery(DroomPropertyDelivery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getDroomPropertyDeliveryView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getDroomPropertyDeliveryView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteDroomPropertyDelivery({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteDroomPropertyDelivery({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
