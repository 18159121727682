/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-18T18:02:48.268
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listAuthFuncRoleManage,
	listAuthFuncRoleManageInit,
	listAuthFuncRoleManageByAuthorizations,
	deleteAuthFuncRoleManage,
	getAuthMenuListByRoleId,
	saveRoleAuthMenuList,
	getAuthMenuTree
	
} from '@api/systemmanage/AuthFuncRoleManage';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listAuthFuncRoleManage({
			state
		}) {
			return new Promise((resolve, reject) => {
				listAuthFuncRoleManage(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listAuthFuncRoleManageByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listAuthFuncRoleManageByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listAuthFuncRoleManageInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listAuthFuncRoleManageInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteAuthFuncRoleManage({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteAuthFuncRoleManage({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		getAuthMenuTree({
			state
		}) {
			return new Promise((resolve, reject) => {
				getAuthMenuTree()
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		
		getAuthMenuListByRoleId({
			state
		}, {roleId}) {
			return new Promise((resolve, reject) => {
				getAuthMenuListByRoleId({
						roleId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		saveRoleAuthMenuList({
			state
		}, data) {
			return new Promise((resolve, reject) => {
				saveRoleAuthMenuList(data)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		

	}
};
