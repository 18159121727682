/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:58.077
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveEvaluationRent,
	getEvaluationRentCreateInit,
	getEvaluationRentEditInit,
	getEvaluationRentView,
	deleteEvaluationRent
} from '@api/lcf/EvaluationRent';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getEvaluationRentCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getEvaluationRentCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluationRentEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluationRentEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveEvaluationRent({}, EvaluationRent) {
			return new Promise((resolve, reject) => {
				saveEvaluationRent(EvaluationRent)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluationRentView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluationRentView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluationRent({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluationRent({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
