/**
 * 注册、登录、注销
 * */
import util from '@/libs/util';
import router from '@/router';
import {
	toPayApi
} from '@api/mobile/rent/rentpay';
import {
	formatTime
} from '@/plugins/utils';
import {
	Message,
	Notice
} from 'view-design';

export default {
	namespaced: true,
	state: {},
	actions: {
		toPay({
			state,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				toPayApi(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

	},
	mutations: {

	}
};
