/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-23T14:44:27.044
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHouseGoods,
	getRentHouseGoodsCreateInit,
	getRentHouseGoodsEditInit,
	getRentHouseGoodsView,
	deleteRentHouseGoods,
	saveRentHouseGoodsList
} from '@api/rentmanage/RentHouseGoods';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentHouseGoodsCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseGoodsCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseGoodsEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseGoodsEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseGoods({}, RentHouseGoods) {
			return new Promise((resolve, reject) => {
				saveRentHouseGoods(RentHouseGoods)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		
		saveRentHouseGoodsList({}, RentHouseGoods) {
			return new Promise((resolve, reject) => {
				saveRentHouseGoodsList(RentHouseGoods)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		getRentHouseGoodsView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseGoodsView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentHouseGoods({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseGoods({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
