/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-23T14:56:33.694
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentGoodsSort,
	listRentGoodsSortInit,
	listRentGoodsSortByAuthorizations,
	deleteRentGoodsSort
	
} from '@api/rentmanage/RentGoodsSort';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentGoodsSort({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentGoodsSort(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentGoodsSortByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentGoodsSortByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentGoodsSortInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentGoodsSortInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentGoodsSort({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentGoodsSort({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
