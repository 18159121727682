/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-09T14:42:50.591
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseSalesPriceItemInit(data){return request({
url: '/rentmanage/api/rentHouseSalesPriceItem/listRentHouseSalesPriceItemInit',
method: 'post',
data:data,
});
}


export function listRentHouseSalesPriceItemByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseSalesPriceItem/listRentHouseSalesPriceItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseSalesPriceItem(data){return request({
url: '/rentmanage/api/rentHouseSalesPriceItem/listRentHouseSalesPriceItem',
method: 'post',
data:data,
});
}


export function getRentHouseSalesPriceItemCreateInit(){return request({
url: '/rentmanage/api/rentHouseSalesPriceItem/getRentHouseSalesPriceItemCreateInit',
method: 'post',
});
}

export function priceCalculationCostByHouseId(params){return request({
	url: '/rentmanage/api/rentHouseSalesPriceItem/priceCalculationCostByHouseId',
	method: 'post',
	params:params,
	});
}


export function getRentHouseSalesPriceItemEditInit(params){return request({
url: '/rentmanage/api/rentHouseSalesPriceItem/getRentHouseSalesPriceItemEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseSalesPriceItemView(params){return request({
url: '/rentmanage/api/rentHouseSalesPriceItem/getRentHouseSalesPriceItemView',
method: 'post',
params:params,
});
}


export function saveRentHouseSalesPriceItem(data){return request({
url: '/rentmanage/api/rentHouseSalesPriceItem/saveRentHouseSalesPriceItem',
method: 'post',
data:data,
});
}


export function deleteRentHouseSalesPriceItem(params){return request({
url: '/rentmanage/api/rentHouseSalesPriceItem/deleteRentHouseSalesPriceItem',
method: 'post',
params:params,
});
}


export function downloadTemplate(params) {
	return request({
		url: '/rentmanage/api/rentHouseSalesPriceItem/downloadRentHouseSalesPriceItemTemplate',
		method: 'post',
		params:params,
		responseType: 'blob'
	});
}
export function downloadHouseSalesPriceNullTemplate(params) {
	return request({
		url: '/rentmanage/api/rentHouseSalesPriceItem/downloadHouseSalesPriceNullTemplate',
		method: 'post',
		params:params,
		responseType: 'blob'
	});
}

export function downloadHouseShowPriceTemplate(params) {
	return request({
		url: '/rentmanage/api/rentHouseSalesPriceItem/downloadRentHouseShowPriceItemTemplate',
		method: 'post',
		params:params,
		responseType: 'blob'
	});
}

export function batchSaveItemByHouseList(params) {
	return request({
		url: '/rentmanage/api/rentHouseSalesPriceItem/batchSaveItemByHouseList',
		method: 'post',
		data:params
	});
}
