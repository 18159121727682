/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-24T09:40:19.58
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getKhmDataChangesApplyEditInit,
	getKhmDataChangesApplyView
} from '@api/rentmanage/KhmDataChangesApply';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		getKhmDataChangesApplyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getKhmDataChangesApplyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getKhmDataChangesApplyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getKhmDataChangesApplyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
