/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-04-07 14:30:15
 * @LastEditors: co
 * @LastEditTime: 2021-06-09 14:24:07
 * @FilePath: \iview-admin-pro-template\src\api\report\ReportRentHouseBig.js
 */
/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-15T16:02:24.1
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportRentHouseBigInit(data) {
    return request({
        url: '/report/api/reportRentHouseBig/listReportRentHouseBigInit',
        method: 'post',
        data: data,
    });
}


export function listReportRentHouseBigByAuthorizations(data) {
    return request({
        url: '/report/api/reportRentHouseBig/listReportRentHouseBigByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listReportRentHouseBig(data) {
    return request({
        url: '/report/api/reportRentHouseBig/listReportRentHouseBig',
        method: 'post',
        data: data,
    });
}


export function getReportRentHouseBigCreateInit() {
    return request({
        url: '/report/api/reportRentHouseBig/getReportRentHouseBigCreateInit',
        method: 'post',
    });
}


export function getReportRentHouseBigEditInit(params) {
    return request({
        url: '/report/api/reportRentHouseBig/getReportRentHouseBigEditInit',
        method: 'post',
        params: params,
    });
}


export function getReportRentHouseBigView(params) {
    return request({
        url: '/report/api/reportRentHouseBig/getReportRentHouseBigView',
        method: 'post',
        params: params,
    });
}


export function saveReportRentHouseBig(data) {
    return request({
        url: '/report/api/reportRentHouseBig/saveReportRentHouseBig',
        method: 'post',
        data: data,
    });
}


export function deleteReportRentHouseBig(params) {
    return request({
        url: '/report/api/reportRentHouseBig/deleteReportRentHouseBig',
        method: 'post',
        params: params,
    });
}


