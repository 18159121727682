/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2022-01-26T10:50:44.51
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialTransferOprLogInit(data){return request({
url: '/financial/api/financialTransferOprLog/listFinancialTransferOprLogInit',
method: 'post',
data:data,
});
}


export function listFinancialTransferOprLogByAuthorizations(data){return request({
url: '/financial/api/financialTransferOprLog/listFinancialTransferOprLogByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialTransferOprLog(data){return request({
url: '/financial/api/financialTransferOprLog/listFinancialTransferOprLog',
method: 'post',
data:data,
});
}


export function getFinancialTransferOprLogCreateInit(){return request({
url: '/financial/api/financialTransferOprLog/getFinancialTransferOprLogCreateInit',
method: 'post',
});
}


export function getFinancialTransferOprLogEditInit(params){return request({
url: '/financial/api/financialTransferOprLog/getFinancialTransferOprLogEditInit',
method: 'post',
params:params,
});
}


export function getFinancialTransferOprLogView(params){return request({
url: '/financial/api/financialTransferOprLog/getFinancialTransferOprLogView',
method: 'post',
params:params,
});
}


export function saveFinancialTransferOprLog(data){return request({
url: '/financial/api/financialTransferOprLog/saveFinancialTransferOprLog',
method: 'post',
data:data,
});
}


export function deleteFinancialTransferOprLog(params){return request({
url: '/financial/api/financialTransferOprLog/deleteFinancialTransferOprLog',
method: 'post',
params:params,
});
}


