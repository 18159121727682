/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T11:12:57.623
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listChannelInit(data){return request({
url: '/rentmanage/api/channel/listChannelInit',
method: 'post',
data:data,
});
}


export function listChannelByAuthorizations(data){return request({
url: '/rentmanage/api/channel/listChannelByAuthorizations',
method: 'post',
data:data,
});
}


export function listChannel(data){return request({
url: '/rentmanage/api/channel/listChannel',
method: 'post',
data:data,
});
}


export function getChannelCreateInit(){return request({
url: '/rentmanage/api/channel/getChannelCreateInit',
method: 'post',
});
}


export function getChannelEditInit(params){return request({
url: '/rentmanage/api/channel/getChannelEditInit',
method: 'post',
params:params,
});
}


export function getChannelView(params){return request({
url: '/rentmanage/api/channel/getChannelView',
method: 'post',
params:params,
});
}


export function saveChannel(data){return request({
url: '/rentmanage/api/channel/saveChannel',
method: 'post',
data:data,
});
}


export function deleteChannel(params){return request({
url: '/rentmanage/api/channel/deleteChannel',
method: 'post',
params:params,
});
}

export function synchronizationChannel(params){return request({
	url: '/rentmanage/api/channel/synchronizationChannel',
	method: 'post',
	params:params,
	});
	}

