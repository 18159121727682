/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-17T17:56:04.321
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-RentIssueHouseApply-'

export default 
{
path: '/rentmanage/rentissuehouseapply',
name: 'rentmanage-RentIssueHouseApply',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}RentIssueHouseApply-index',

meta:{
...meta,
title: '问题房源申请'
},

component: () => import('@/pages/rentmanage/rentissuehouseapply/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}RentIssueHouseApply-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '问题房源申请编辑'
},

component: () => import('@/pages/rentmanage/rentissuehouseapply/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}RentIssueHouseApplydetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '问题房源申请新增'
},

component: () => import('@/pages/rentmanage/rentissuehouseapply/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}RentIssueHouseApplyView',
auth: [
'hidden'
],

meta:{
...meta,
title: '问题房源申请详情查看'
},

component: () => import('@/pages/rentmanage/rentissuehouseapply/detail-view') ,
},
]
};
