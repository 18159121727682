<template>
	<span class="i-layout-header-trigger i-layout-header-trigger-min i-layout-header-trigger-in">
		<Notification :wide="isMobile" :badge-props="badgeProps" class="i-layout-header-notice" :class="{ 'i-layout-header-notice-mobile': isMobile }"
		 :count="storePage.listDataObj.total" @on-load-more="handleLoadMore" @on-clear="handleClear">
			<Icon slot="icon" custom="i-icon i-icon-notification" />
			<NotificationTab title="通知" name="message" :count="unreadMessage" :loaded-all="messageList.length == storePage.listDataObj.total"
			 :loading="messageLoading" :scroll-to-load="false">
				<NotificationItem v-for="(item, index) in messageList" :key="index" :title="item.notifyTitle" icon="md-people"
				 icon-color="#f06292" :time="getTimestamp(item.publishTime)" :read="item.read" @on-item-click="instance(item)"/>
			</NotificationTab>
			<NotificationTab title="关注" name="follow" :count="unreadFollow" :loaded-all="followList.length >= 15" :loading="followLoading"
			 :scroll-to-load="false">
				<NotificationItem v-for="(item, index) in followList" :key="index" :avatar="item.avatar" :title="item.title" :time="item.time"
				 :read="item.read" />
			</NotificationTab>
			<NotificationTab title="待办" name="todo" :count="unreadTodo" :loaded-all="todoList.length >= 15" :loading="todoLoading"
			 :scroll-to-load="false">
				<NotificationItem v-for="(item, index) in todoList" :key="index" :title="item.title" :content="item.content" :tag="item.tag"
				 :tag-props="item.tagProps" :read="item.read" />
			</NotificationTab>
		</Notification>
	</span>
</template>
<script>
	import {
		mapState,
		mapActions
	} from 'vuex';
	export default {
		name: 'iHeaderNotice',
		data() {
			return {
				badgeProps: {
					offset: [20, 0]
				},
				messageBaseList: [{
					icon: 'md-people',
					iconColor: '#f06292',
					title: '2020年3月6日，行程报备改版上线，新架构新体验，欢迎试用！',
					read: 0,
					time: 1583470538
				}],
				followBaseList: [],
				todoBaseList: [

				],
				messageList: [],
				followList: [],
				todoList: [],
				messageLoading: false,
				followLoading: false,
				todoLoading: false
			}
		},
		computed: {
			...mapState('admin/layout', [
				'isMobile'
			]),
			storePage() {
				return this.$store.state.admin.notification
			},
			unreadMessage() {
				let unread = 0;
				this.messageList.forEach(item => {
					if (!item.read) unread++;
				});
				return unread;
			},
			unreadFollow() {
				let unread = 0;
				this.followList.forEach(item => {
					if (!item.read) unread++;
				});
				return unread;
			},
			unreadTodo() {
				let unread = 0;
				this.todoList.forEach(item => {
					if (!item.read) unread++;
				});
				return unread;
			}
		},
		methods: {
			...mapActions('admin/notification', [
				'pageInit', 'submitData', 'detailPageInit', 'getList'
			]),
			instance(item) {
				const title = '通知内容';
				const content1 = '<p>'+item.content+'</p>';
				this.$Modal.info({
					title: title,
					content: content1
				});
			},
			
			getTimestamp(time) { //把时间日期转成时间戳
			  return (new Date(time)).getTime() / 1000
			 },
			handleLoadMore(tab) {
				this.loadMore(tab.name);
			},
			getData(type) {
				this.getList().then((res) => {
					this.messageList = this.storePage.listDataObj.entities;
					this[`${type}Loading`] = false;
				});
			},
			loadMore(type) {
				if (this[`${type}Loading`]) return;
				this[`${type}Loading`] = true;
				this.$store.commit('admin/notification/updateReqParams', {
					key: 'pageIndex',
					value: this.storePage.reqParams.pageIndex + 1
				})
				this.getData(type);
				// setTimeout(() => {
				// 	this[`${type}List`] = this[`${type}List`].concat([...this[`${type}BaseList`]]);
				// 	this[`${type}Loading`] = false;
				// }, 1000);

			},
			handleClear(tab) {
				this.clearUnread(tab.name);
			},
			clearUnread(type) {
				// this[`${type}List`] = this[`${type}List`].map(item => {
				// 	item.read = 1;
				// 	return item;
				// });
				this.messageList = [];
				this.storePage.listDataObj.total = 0;
			}
		},
		mounted() {
			this.storePage.listDataObj.entities = [];
			this.storePage.listDataObj.total = 0;
			this.pageInit();
			this.messageLoading = true;
			this.getData('message');
			// this.messageList = [...this.messageBaseList];

			this.followList = [...this.followBaseList];
			this.todoList = [...this.todoBaseList];
		}
	}



</script>
