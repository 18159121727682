/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2021-04-20T19:25:48.953
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listRentHouseTrafficInit(data) {
	return request({
		url: '/rentmanage/api/rentHouseTraffic/listRentHouseTrafficInit',
		method: 'post',
		data: data,
	});
}


export function listRentHouseTrafficByAuthorizations(data) {
	return request({
		url: '/rentmanage/api/rentHouseTraffic/listRentHouseTrafficByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listRentHouseTraffic(data) {
	return request({
		url: '/rentmanage/api/rentHouseTraffic/listRentHouseTraffic',
		method: 'post',
		data: data,
	});
}


export function getRentHouseTrafficCreateInit() {
	return request({
		url: '/rentmanage/api/rentHouseTraffic/getRentHouseTrafficCreateInit',
		method: 'post',
	});
}


export function getRentHouseTrafficEditInit(params) {
	return request({
		url: '/rentmanage/api/rentHouseTraffic/getRentHouseTrafficEditInit',
		method: 'post',
		params: params,
	});
}


export function getRentHouseTrafficView(params) {
	return request({
		url: '/rentmanage/api/rentHouseTraffic/getRentHouseTrafficView',
		method: 'post',
		params: params,
	});
}


export function saveRentHouseTraffic(data) {
	return request({
		url: '/rentmanage/api/rentHouseTraffic/saveRentHouseTraffic',
		method: 'post',
		data: data,
	});
}


export function deleteRentHouseTraffic(params) {
	return request({
		url: '/rentmanage/api/rentHouseTraffic/deleteRentHouseTraffic',
		method: 'post',
		params: params,
	});
}

export function getRentHouseTrafficById(params) {
	return request({
		url: '/rentmanage/api/rentHouseTraffic/getRentHouseTrafficById',
		method: 'get',
		params: params,
	});
}

