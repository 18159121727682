/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-26T09:20:14.872
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-RentHousePutaway-'

export default 
{
path: '/rentmanage/renthouseputaway',
name: 'rentmanage-RentHousePutaway',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}RentHousePutaway-index',

meta:{
...meta,
title: '房源上下架'
},

component: () => import('@/pages/rentmanage/renthouseputaway/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}RentHousePutaway-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源上下架编辑'
},

component: () => import('@/pages/rentmanage/renthouseputaway/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}RentHousePutawaydetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源上下架新增'
},

component: () => import('@/pages/rentmanage/renthouseputaway/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}RentHousePutawayView',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源上下架详情查看'
},

component: () => import('@/pages/rentmanage/renthouseputaway/detail-view') ,
},
]
};
