/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-23T16:11:31.61
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listChanneltagInit(data){return request({
url: '/rentmanage/api/channeltag/listChanneltagInit',
method: 'post',
data:data,
});
}


export function listChanneltagByAuthorizations(data){return request({
url: '/rentmanage/api/channeltag/listChanneltagByAuthorizations',
method: 'post',
data:data,
});
}


export function listChanneltag(data){return request({
url: '/rentmanage/api/channeltag/listChanneltag',
method: 'post',
data:data,
});
}


export function getChanneltagCreateInit(){return request({
url: '/rentmanage/api/channeltag/getChanneltagCreateInit',
method: 'post',
});
}


export function getChanneltagEditInit(params){return request({
url: '/rentmanage/api/channeltag/getChanneltagEditInit',
method: 'post',
params:params,
});
}


export function getChanneltagView(params){return request({
url: '/rentmanage/api/channeltag/getChanneltagView',
method: 'post',
params:params,
});
}


export function saveChanneltag(data){return request({
url: '/rentmanage/api/channeltag/saveChanneltag',
method: 'post',
data:data,
});
}


export function deleteChanneltag(params){return request({
url: '/rentmanage/api/channeltag/deleteChanneltag',
method: 'post',
params:params,
});
}


