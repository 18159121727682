import iLink from './link'
import Drawer from './drawer'
import CurrencyInput from './currency-input'
export default {
    install (Vue) {
      Vue.component(iLink.name, iLink)
      Vue.component(Drawer.name, Drawer)
      Vue.component(CurrencyInput.name, CurrencyInput)
    }
}
