<template>
	<Card dis-hover :bordered="false">
		<indexTableQuery ref="queryTable" @on-submit="getData" @on-reset="getData" />
		<indexTableList ref="table" />
	</Card>
</template>
<script>
	import indexTableQuery from './index-table-query';
	import indexTableList from './index-table-listF7';

	export default {
		name: 'list-table-list',
		components: {
			indexTableQuery,
			indexTableList
		},
		computed: {},
		data() {
			return {}
		},
		methods: {
		    resetData() {
				this.$refs.queryTable.handleReset();
			},
			getData() {
				this.$refs.table.getData();
			},
			getInitData() {
				this.$refs.table.init();
			},
			getSelectData(){
			   return this.$refs.table.getSelectData();
			}
		},
		mounted() {
			this.getInitData();
		}
	}
</script>
