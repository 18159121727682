/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-20T12:07:15.697
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listOrgUserManageInit(data) {
	return request({
		url: '/systemmanage/api/orgUserManage/listOrgUserManageInit',
		method: 'post',
		data: data,
	});
}


export function listOrgUserManageByAuthorizations(data) {
	return request({
		url: '/systemmanage/api/orgUserManage/listOrgUserManageByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listOrgUserManage(data) {
	return request({
		url: '/systemmanage/api/orgUserManage/listOrgUserManage',
		method: 'post',
		data: data,
	});
}


export function getOrgUserManageCreateInit() {
	return request({
		url: '/systemmanage/api/orgUserManage/getOrgUserManageCreateInit',
		method: 'post',
	});
}


export function getOrgUserManageEditInit(params) {
	return request({
		url: '/systemmanage/api/orgUserManage/getOrgUserManageEditInit',
		method: 'post',
		params: params,
	});
}


export function getOrgUserManageView(params) {
	return request({
		url: '/systemmanage/api/orgUserManage/getOrgUserManageView',
		method: 'post',
		params: params,
	});
}


export function saveOrgUserManage(data) {
	return request({
		url: '/systemmanage/api/orgUserManage/saveOrgUserManage',
		method: 'post',
		data: data,
	});
}

export function getAuthRoleByUserid(params) {
	return request({
		url: '/systemmanage/api/orgUserManage/getAuthRoleByUserid',
		method: 'post',
		params: params,
	});
}

export function saveUserAuthRole(data) {
	return request({
		url: '/systemmanage/api/orgUserManage/saveUserAuthRole',
		method: 'post',
		data: data,
	});
}


export function deleteOrgUserManage(params) {
	return request({
		url: '/systemmanage/api/orgUserManage/deleteOrgUserManage',
		method: 'post',
		params: params,
	});
}

export function getOrgTree(params) {
	return request({
		url: '/systemmanage/api/organizationManage/getOrganizationTree',
		method: 'post',
		data: params,
	});
}

export function getStationTree(params) {
	return request({
		url: '/systemmanage/api/organizationManage/getPostByOrgIds?'+params,
		method: 'post',
		// params: params,
	});
}

export function resetPassword(params) {
	return request({
		url: '/systemmanage/api/orgUserManage/passwordReset',
		method: 'post',
		params: params,
	});
}