/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-20T10:20:49.704
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentHouseStateChange,
	listRentHouseStateChangeInit,
	listRentHouseStateChangeByAuthorizations,
	deleteRentHouseStateChange
	
} from '@api/rentmanage/RentHouseStateChange';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentHouseStateChange({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseStateChange(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseStateChangeByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseStateChangeByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseStateChangeInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseStateChangeInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseStateChange({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseStateChange({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
