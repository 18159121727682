/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-01T18:10:47.024
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listOrgPositionInit(data){return request({
url: '/systemmanage/api/orgPosition/listOrgPositionInit',
method: 'post',
data:data,
});
}


export function listOrgPositionByAuthorizations(data){return request({
url: '/systemmanage/api/orgPosition/listOrgPositionByAuthorizations',
method: 'post',
data:data,
});
}


export function listOrgPosition(data){return request({
url: '/systemmanage/api/orgPosition/listOrgPosition',
method: 'post',
data:data,
});
}


export function getOrgPositionCreateInit(){return request({
url: '/systemmanage/api/orgPosition/getOrgPositionCreateInit',
method: 'post',
});
}


export function getOrgPositionEditInit(params){return request({
url: '/systemmanage/api/orgPosition/getOrgPositionEditInit',
method: 'post',
params:params,
});
}


export function getOrgPositionView(params){return request({
url: '/systemmanage/api/orgPosition/getOrgPositionView',
method: 'post',
params:params,
});
}


export function saveOrgPosition(data){return request({
url: '/systemmanage/api/orgPosition/saveOrgPosition',
method: 'post',
data:data,
});
}


export function deleteOrgPosition(params){return request({
url: '/systemmanage/api/orgPosition/deleteOrgPosition',
method: 'post',
params:params,
});
}


