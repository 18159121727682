<template>
  <!-- 流程痕迹 -->
  <JFDrawer destroy-on-close title="流程痕迹" v-model="visible">
    <ProcessTrace ref="processTrace" :list="list" />
    <Spin fix v-if="loading" />
    <template slot="footer" v-if="!loading">
      <Button type="primary"
              :long="isMobile"
              @click="visible = false">关闭</Button>
    </template>
  </JFDrawer>
</template>
<script>
  import { mapState } from 'vuex'
  import request from '@/plugins/request'
  import ProcessTrace from './processTrace'

  export default {
    name: 'CheckTracks',
    components: {ProcessTrace},
    computed: {
      ...mapState('admin/layout', ['isMobile']),
    },
    methods: {
      async init (id) {
        try {
          this.visible = true
          this.loading = true
          const {entities} = await request({
            url: '/worfklow/api/flowcenter/listProcessTraces',
            method: 'post',
            params: {
              workflowInstanceId: id
            }
          })

          this.list = entities || []
        } catch (e) {} finally {
          this.loading = false
        }
      }
    },
    data () {
      return {
        visible: false,
        loading: false,
        list: [],
      }
    },
  }
</script>
