/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-01-05T10:42:26.342
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialElectronicAccountInit(data){return request({
url: '/financial/api/financialElectronicAccount/listFinancialElectronicAccountInit',
method: 'post',
data:data,
});
}


export function listFinancialElectronicAccountByAuthorizations(data){return request({
url: '/financial/api/financialElectronicAccount/listFinancialElectronicAccountByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialElectronicAccount(data){return request({
url: '/financial/api/financialElectronicAccount/listFinancialElectronicAccount',
method: 'post',
data:data,
});
}


export function getFinancialElectronicAccountCreateInit(){return request({
url: '/financial/api/financialElectronicAccount/getFinancialElectronicAccountCreateInit',
method: 'post',
});
}


export function getFinancialElectronicAccountEditInit(params){return request({
url: '/financial/api/financialElectronicAccount/getFinancialElectronicAccountEditInit',
method: 'post',
params:params,
});
}


export function getFinancialElectronicAccountView(params){return request({
url: '/financial/api/financialElectronicAccount/getFinancialElectronicAccountView',
method: 'post',
params:params,
});
}


export function saveFinancialElectronicAccount(data){return request({
url: '/financial/api/financialElectronicAccount/saveFinancialElectronicAccount',
method: 'post',
data:data,
});
}


export function deleteFinancialElectronicAccount(params){return request({
url: '/financial/api/financialElectronicAccount/deleteFinancialElectronicAccount',
method: 'post',
params:params,
});
}


