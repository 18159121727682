import request from '@/plugins/request';

export function listEvaluateBillInit(data){return request({
url: '/storagemanage/api/evaluateBill/listEvaluateBillInit',
method: 'post',
data:data,
});
}


export function listEvaluateBillByAuthorizations(data){return request({
url: '/storagemanage/api/evaluateBill/listEvaluateBillByAuthorizations',
method: 'post',
data:data,
});
}


export function listEvaluateBill(data){return request({
url: '/storagemanage/api/evaluateBill/listEvaluateBill',
method: 'post',
data:data,
});
}


export function getEvaluateBillCreateInit(){return request({
url: '/storagemanage/api/evaluateBill/getEvaluateBillCreateInit',
method: 'post',
});
}


export function getEvaluateBillEditInit(params){return request({
url: '/storagemanage/api/evaluateBill/getEvaluateBillEditInit',
method: 'post',
params:params,
});
}


export function getEvaluateBillView(params){return request({
url: '/storagemanage/api/evaluateBill/getEvaluateBillView',
method: 'post',
params:params,
});
}


export function saveEvaluateBill(data){return request({
url: '/storagemanage/api/evaluateBill/saveEvaluateBill',
method: 'post',
data:data,
});
}

export function submitEvaluateBill(data){return request({
	url: '/storagemanage/api/evaluateBill/submitEvaluateBill',
	method: 'post',
	data:data,
	});
	}

export function deleteEvaluateBill(params){return request({
url: '/storagemanage/api/evaluateBill/deleteEvaluateBill',
method: 'post',
params:params,
});
}

export function listEvaluateBillInitByHandSQL(data){return request({
	url: '/storagemanage/api/evaluateBill/listEvaluateBillInitByHandSQL',
	method: 'post',
	data:data,
	});
	}
