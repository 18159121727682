/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-05-16T17:00:11.346
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listEvaluateAppointmentFinalInit(data) {
    return request({
        url: '/storagemanage/api/evaluateAppointmentFinal/listEvaluateAppointmentFinalInit',
        method: 'post',
        data: data,
    });
}


export function listEvaluateAppointmentFinalByAuthorizations(data) {
    return request({
        url: '/storagemanage/api/evaluateAppointmentFinal/listEvaluateAppointmentFinalByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listEvaluateAppointmentFinal(data) {
    return request({
        url: '/storagemanage/api/evaluateAppointmentFinal/listEvaluateAppointmentFinal',
        method: 'post',
        data: data,
    });
}


export function getEvaluateAppointmentFinalCreateInit() {
    return request({
        url: '/storagemanage/api/evaluateAppointmentFinal/getEvaluateAppointmentFinalCreateInit',
        method: 'post',
    });
}


export function getEvaluateAppointmentFinalEditInit(params) {
    return request({
        url: '/storagemanage/api/evaluateAppointmentFinal/getEvaluateAppointmentFinalEditInit',
        method: 'post',
        params: params,
    });
}


export function getEvaluateAppointmentFinalView(params) {
    return request({
        url: '/storagemanage/api/evaluateAppointmentFinal/getEvaluateAppointmentFinalView',
        method: 'post',
        params: params,
    });
}


export function saveEvaluateAppointmentFinal(data) {
    return request({
        url: '/storagemanage/api/evaluateAppointmentFinal/saveEvaluateAppointmentFinal',
        method: 'post',
        data: data,
    });
}


export function deleteEvaluateAppointmentFinal(params) {
    return request({
        url: '/storagemanage/api/evaluateAppointmentFinal/deleteEvaluateAppointmentFinal',
        method: 'post',
        params: params,
    });
}

export function queryReportReturnAssetsEvalfactor(data) {
    return request({
        url: '/storagemanage/api/reportReturnAssetsEvalfactor/queryReportReturnAssetsEvalfactor',
        method: 'get',
        params: data
    });
    
}
