/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-15T15:47:43.021
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listEvaluateAppointmentBill,
	listEvaluateAppointmentBillInit,
	listEvaluateAppointmentBillByAuthorizations,
	deleteEvaluateAppointmentBill,
	getEvaluateBillByAppointmentId
	
} from '@api/storagemanage/EvaluateAppointmentBill';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
		//封装着委托信息
		StockpileintentionInfo:{},
		//封装着预约评估列表查询内容
		EvaluateAppointmentListInfo:{
			ownerName:'',
			entrustCode:'',
			houseAddress:'',
			pageIndex:1
		}
	},

	actions: {
		listEvaluateAppointmentBill({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateAppointmentBill(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateAppointmentBillByAuthorizations({
			state
		},data) {
			return new Promise((resolve, reject) => {
				state.AdvanceQuery.entity.sourceBillId=data.sourceBillId;
				listEvaluateAppointmentBillByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateAppointmentBillInit({
			state
		},data) {
			return new Promise((resolve, reject) => {
				state.AdvanceQuery.entity.sourceBillId=data.sourceBillId;
				listEvaluateAppointmentBillInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateAppointmentBill({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateAppointmentBill({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getEvaluateBillByAppointmentId({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillByAppointmentId({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		

	}
};
