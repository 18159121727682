import Vue from 'vue'
import Vuex from 'vuex'

import lcf from './modules/lcf'
import house from './modules/house'
import admin from './modules/admin'
import mobile from './modules/mobile'
import report from './modules/report'
import financial from './modules/financial'
import dashboard from './modules/dashboard'
import rentmanage from './modules/rentmanage'
import systemmanage from './modules/systemmanage'
import storagemanage from './modules/storagemanage'
import smallapplication from './modules/smallapplication'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
    lcf,
    house,
		admin,
    mobile,
    report,
    dashboard,
    financial,
    rentmanage,
    systemmanage,
		storagemanage,
		smallapplication,
	},
})
