/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-18T17:50:47.946
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listKhmPayDeductionApplyInit(data){return request({
url: '/storagemanage/api/khmPayDeductionApply/listKhmPayDeductionApplyInit',
method: 'post',
data:data,
});
}


export function listKhmPayDeductionApplyByAuthorizations(data){return request({
url: '/storagemanage/api/khmPayDeductionApply/listKhmPayDeductionApplyByAuthorizations',
method: 'post',
data:data,
});
}


export function listKhmPayDeductionApply(data){return request({
url: '/storagemanage/api/khmPayDeductionApply/listKhmPayDeductionApply',
method: 'post',
data:data,
});
}


export function getKhmPayDeductionApplyCreateInit(){return request({
url: '/storagemanage/api/khmPayDeductionApply/getKhmPayDeductionApplyCreateInit',
method: 'post',
});
}


export function getKhmPayDeductionApplyEditInit(params){return request({
url: '/storagemanage/api/khmPayDeductionApply/getKhmPayDeductionApplyEditInit',
method: 'post',
params:params,
});
}


export function getKhmPayDeductionApplyView(params){return request({
url: '/storagemanage/api/khmPayDeductionApply/getKhmPayDeductionApplyView',
method: 'post',
params:params,
});
}


export function saveKhmPayDeductionApply(data){return request({
url: '/storagemanage/api/khmPayDeductionApply/saveKhmPayDeductionApply',
method: 'post',
data:data,
});
}

export function startWorkflow(data){return request({
	url: '/storagemanage/api/khmPayDeductionApply/startWorkflow',
	method: 'post',
	data:data,
	});
	}

export function deleteKhmPayDeductionApply(params){return request({
url: '/storagemanage/api/khmPayDeductionApply/deleteKhmPayDeductionApply',
method: 'post',
params:params,
});
}


