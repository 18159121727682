/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-08T11:03:22.49
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentMaterialReceivingFormInit(data){return request({
url: '/rentmanage/api/rentMaterialReceivingForm/listRentMaterialReceivingFormInit',
method: 'post',
data:data,
});
}


export function listRentMaterialReceivingFormBySQL(data){return request({
	url: '/rentmanage/api/rentMaterialReceivingForm/listRentMaterialReceivingFormBySQL',
	method: 'post',
	data:data,
	});
	}

export function listRentMaterialReceivingFormByAuthorizations(data){return request({
url: '/rentmanage/api/rentMaterialReceivingForm/listRentMaterialReceivingFormByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentMaterialReceivingForm(data){return request({
url: '/rentmanage/api/rentMaterialReceivingForm/listRentMaterialReceivingForm',
method: 'post',
data:data,
});
}


export function getRentMaterialReceivingFormCreateInit(){return request({
url: '/rentmanage/api/rentMaterialReceivingForm/getRentMaterialReceivingFormCreateInit',
method: 'post',
});
}


export function getRentMaterialReceivingFormEditInit(params){return request({
url: '/rentmanage/api/rentMaterialReceivingForm/getRentMaterialReceivingFormEditInit',
method: 'post',
params:params,
});
}


export function getRentMaterialReceivingFormView(params){return request({
url: '/rentmanage/api/rentMaterialReceivingForm/getRentMaterialReceivingFormView',
method: 'post',
params:params,
});
}


export function saveRentMaterialReceivingForm(data){return request({
url: '/rentmanage/api/rentMaterialReceivingForm/saveRentMaterialReceivingForm',
method: 'post',
data:data,
});
}


export function deleteRentMaterialReceivingForm(params){return request({
url: '/rentmanage/api/rentMaterialReceivingForm/deleteRentMaterialReceivingForm',
method: 'post',
params:params,
});
}


