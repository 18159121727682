/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-14T19:21:18.566
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportstorageintention-'

export default 
{
path: '/report/reportstorageintention',
name: 'report-reportstorageintention',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportstorageintention-index',

meta:{
...meta,
title: '渠道进件评估反馈信息表'
},

component: () => import('@/pages/report/reportstorageintention/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportstorageintention-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '渠道进件评估反馈信息表编辑'
},

component: () => import('@/pages/report/reportstorageintention/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportstorageintentiondetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '渠道进件评估反馈信息表新增'
},

component: () => import('@/pages/report/reportstorageintention/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportstorageintentionView',
auth: [
'hidden'
],

meta:{
...meta,
title: '渠道进件评估反馈信息表详情查看'
},

component: () => import('@/pages/report/reportstorageintention/detail-view') ,
},
]
};
