/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-20T16:51:35.109
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getFinancialPayApplyEditInit,
	getFinancialPayApplyView
} from '@api/rentmanage/FinancialPayApply';

import {
listFinancialPayApplyItemInit,
listFinancialPayApplyItemByAuthorizations,
deleteFinancialPayApplyItem
} from '@api/rentmanage/FinancialPayApplyItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getFinancialPayApplyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayApplyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialPayApplyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayApplyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
