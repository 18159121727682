/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-11T09:27:30.345
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportfinancialstoragecontractplan-'

export default 
{
path: '/report/reportfinancialstoragecontractplan',
name: 'report-reportfinancialstoragecontractplan',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportfinancialstoragecontractplan-index',

meta:{
...meta,
title: '存房合同付款计划'
},

component: () => import('@/pages/report/reportfinancialstoragecontractplan/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportfinancialstoragecontractplan-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '存房合同付款计划编辑'
},

component: () => import('@/pages/report/reportfinancialstoragecontractplan/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportfinancialstoragecontractplandetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '存房合同付款计划新增'
},

component: () => import('@/pages/report/reportfinancialstoragecontractplan/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportfinancialstoragecontractplanView',
auth: [
'hidden'
],

meta:{
...meta,
title: '存房合同付款计划详情查看'
},

component: () => import('@/pages/report/reportfinancialstoragecontractplan/detail-view') ,
},
]
};
