/*
 * @Author: co
 * @Date: 2021-04-07 14:30:15
 */
/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-30T21:02:57.429
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialClaimInit(data) {
    return request({
        url: '/financial/api/financialClaim/listFinancialClaimInit',
        method: 'post',
        data: data,
    });
}


export function listFinancialClaimByAuthorizations(data) {
    return request({
        url: '/financial/api/financialClaim/listFinancialClaimByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listFinancialClaim(data) {
    return request({
        url: '/financial/api/financialClaim/listFinancialClaim',
        method: 'post',
        data: data,
    });
}


export function getFinancialClaimCreateInit() {
    return request({
        url: '/financial/api/financialClaim/getFinancialClaimCreateInit',
        method: 'post',
    });
}


export function getFinancialClaimEditInit(params) {
    return request({
        url: '/financial/api/financialClaim/getFinancialClaimEditInit',
        method: 'post',
        params: params,
    });
}


export function getFinancialClaimView(params) {
    return request({
        url: '/financial/api/financialClaim/getFinancialClaimView',
        method: 'post',
        params: params,
    });
}


export function saveFinancialClaim(data) {
    return request({
        url: '/financial/api/financialClaim/saveFinancialClaim',
        method: 'post',
        data: data,
    });
}

// 认领单管理编辑多选保存
export function saveSettlementList(data) {
    return request({
        url: '/financial/api/financialClaim/saveSettlementList',
        method: 'post',
        data: data,
    });
}

// 基本信息列表
export function getFinancialTransferListCreateInit(data) {
    return request({
        url: '/financial/api/financialTransfer/getFinancialTransferListCreateInit',
        method: 'post',
        data: data,
    });
}

// 多选认领单，结算单点击确认
export function listFinancialClaimBySettlementList(data) {
    return request({
        url: '/financial/api/financialClaim/listFinancialClaimBySettlementList',
        method: 'post',
        data: data,
    });
}


export function deleteFinancialClaim(params) {
    return request({
        url: '/financial/api/financialClaim/deleteFinancialClaim',
        method: 'post',
        params: params,
    });
}

export function cancellationFinancialClaim(params) {
    return request({
        url: '/financial/api/financialClaim/cancellationFinancialClaim',
        method: 'post',
        params: params,
    });
}


