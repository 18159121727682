/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:58.174
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveHouseLeaseContract,
	getHouseLeaseContractCreateInit,
	getHouseLeaseContractEditInit,
	getHouseLeaseContractView,
	deleteHouseLeaseContract
} from '@api/lcf/HouseLeaseContract';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getHouseLeaseContractCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getHouseLeaseContractCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseLeaseContractEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseLeaseContractEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveHouseLeaseContract({}, HouseLeaseContract) {
			return new Promise((resolve, reject) => {
				saveHouseLeaseContract(HouseLeaseContract)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseLeaseContractView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseLeaseContractView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteHouseLeaseContract({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteHouseLeaseContract({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
