/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-09T17:00:10.541
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentRepairFeedbackCallInit(data){return request({
url: '/rentmanage/api/rentRepairFeedbackCall/listRentRepairFeedbackCallInit',
method: 'post',
data:data,
});
}


export function listRentRepairFeedbackCallByAuthorizations(data){return request({
url: '/rentmanage/api/rentRepairFeedbackCall/listRentRepairFeedbackCallByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentRepairFeedbackCall(data){return request({
url: '/rentmanage/api/rentRepairFeedbackCall/listRentRepairFeedbackCall',
method: 'post',
data:data,
});
}


export function getRentRepairFeedbackCallCreateInit(){return request({
url: '/rentmanage/api/rentRepairFeedbackCall/getRentRepairFeedbackCallCreateInit',
method: 'post',
});
}


export function getRentRepairFeedbackCallEditInit(params){return request({
url: '/rentmanage/api/rentRepairFeedbackCall/getRentRepairFeedbackCallEditInit',
method: 'post',
params:params,
});
}


export function getRentRepairFeedbackCallView(params){return request({
url: '/rentmanage/api/rentRepairFeedbackCall/getRentRepairFeedbackCallView',
method: 'post',
params:params,
});
}


export function saveRentRepairFeedbackCall(data){return request({
url: '/rentmanage/api/rentRepairFeedbackCall/saveRentRepairFeedbackCall',
method: 'post',
data:data,
});
}


export function deleteRentRepairFeedbackCall(params){return request({
url: '/rentmanage/api/rentRepairFeedbackCall/deleteRentRepairFeedbackCall',
method: 'post',
params:params,
});
}


