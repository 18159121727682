import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	redirect: {
		name: `${pre}profitReport-list`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'profitReport-list',
		name: `${pre}profitReport-list`,
		meta: {
			...meta,
			title: '资产收益值报告',
			closable: true
		},
		component: () => import('@/pages/storagemanage/profitReport/index')
	}, {
		path: 'profitReport-addOrEdit',
		name: `${pre}profitReport-addOrEdit`,
		meta: {
			...meta,
			title: '资产收益值报告编辑',
            cache: true,
			closable: true
		},
		component: () => import('@/pages/storagemanage/profitReport/edit')
	},{
		path: 'profitReport-check',
		name: `${pre}profitReport-check`,
		meta: {
			...meta,
			title: '资产收益值报告详情查看',
			closable: true
		},
		component: () => import('@/pages/storagemanage/profitReport/check')
	},{
		path: 'profitReport-history-list',
		name: `${pre}profitReport-history-list`,
		meta: {
			...meta,
			title: '历史资产收益值报告查看',
			closable: true
		},
		component: () => import('@/pages/storagemanage/profitReport/history')
	}, ]
};
