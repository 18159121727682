/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-12T09:55:24.245
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportplaceorder-'

export default 
{
path: '/report/reportplaceorder',
name: 'report-reportplaceorder',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportplaceorder-index',

meta:{
...meta,
title: '预定报表'
},

component: () => import('@/pages/report/reportplaceorder/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportplaceorder-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '预定报表编辑'
},

component: () => import('@/pages/report/reportplaceorder/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportplaceorderdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '预定报表新增'
},

component: () => import('@/pages/report/reportplaceorder/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportplaceorderView',
auth: [
'hidden'
],

meta:{
...meta,
title: '预定报表详情查看'
},

component: () => import('@/pages/report/reportplaceorder/detail-view') ,
},
]
};
