/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-13T16:30:31.472
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listEnergyRecordInit(data) {
  return request({
    url: '/rentmanage/api/energyRecord/listEnergyRecordInit',
    method: 'post',
    data: data,
  });
}


export function listEnergyRecordByAuthorizations(data) {
  return request({
    url: '/rentmanage/api/energyRecord/listEnergyRecordByAuthorizations',
    method: 'post',
    data: data,
  });
}


export function listEnergyRecord(data) {
  return request({
    url: '/rentmanage/api/energyRecord/listEnergyRecord',
    method: 'post',
    data: data,
  });
}


export function getEnergyRecordCreateInit() {
  return request({
    url: '/rentmanage/api/energyRecord/getEnergyRecordCreateInit',
    method: 'post',
  });
}


export function getEnergyRecordEditInit(params) {
  return request({
    url: '/rentmanage/api/energyRecord/getEnergyRecordEditInit',
    method: 'post',
    params: params,
  });
}


export function getEnergyRecordView(params) {
  return request({
    url: '/rentmanage/api/energyRecord/getEnergyRecordView',
    method: 'post',
    params: params,
  });
}


export function saveEnergyRecord(data) {
  return request({
    url: '/rentmanage/api/energyRecord/saveEnergyRecord',
    method: 'post',
    data: data,
  });
}


export function deleteEnergyRecord(params) {
  return request({
    url: '/rentmanage/api/energyRecord/deleteEnergyRecord',
    method: 'post',
    params: params,
  });
}

export function cancelEnergyRecord(params) {
  return request({
    url: '/rentmanage/api/energyRecord/cancelEnergyRecord',
    method: 'get',
    params: params,
  });
}

