/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-25T16:21:58.326
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHouseFacility,
	getRentHouseFacilityCreateInit,
	getRentHouseFacilityEditInit,
	getRentHouseFacilityView,
	deleteRentHouseFacility
} from '@api/rentmanage/RentHouseFacility';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentHouseFacilityCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseFacilityCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseFacilityEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseFacilityEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseFacility({}, RentHouseFacility) {
			return new Promise((resolve, reject) => {
				saveRentHouseFacility(RentHouseFacility)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentHouseFacilityView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseFacilityView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentHouseFacility({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseFacility({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
