/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-16T16:45:54.88
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-khmdataauthapply-'

export default 
{
path: '/rentmanage/khmdataauthapply',
name: 'rentmanage-khmdataauthapply',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}khmdataauthapply-index',

meta:{
...meta,
title: '使用权限开通'
},

component: () => import('@/pages/rentmanage/khmdataauthapply/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}khmdataauthapply-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '使用权限开通编辑'
},

component: () => import('@/pages/rentmanage/khmdataauthapply/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}khmdataauthapplydetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '使用权限开通新增'
},

component: () => import('@/pages/rentmanage/khmdataauthapply/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}khmdataauthapplyView',
auth: [
'hidden'
],

meta:{
...meta,
title: '使用权限开通详情查看'
},

component: () => import('@/pages/rentmanage/khmdataauthapply/detail-view') ,
},
]
};
