/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:57.703
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listHouseCoownerInfoInit(data){return request({
url: '/lcf/api/houseCoownerInfo/listHouseCoownerInfoInit',
method: 'post',
data:data,
});
}


export function listHouseCoownerInfoByAuthorizations(data){return request({
url: '/lcf/api/houseCoownerInfo/listHouseCoownerInfoByAuthorizations',
method: 'post',
data:data,
});
}


export function listHouseCoownerInfo(data){return request({
url: '/lcf/api/houseCoownerInfo/listHouseCoownerInfo',
method: 'post',
data:data,
});
}


export function getHouseCoownerInfoCreateInit(){return request({
url: '/lcf/api/houseCoownerInfo/getHouseCoownerInfoCreateInit',
method: 'post',
});
}


export function getHouseCoownerInfoEditInit(params){return request({
url: '/lcf/api/houseCoownerInfo/getHouseCoownerInfoEditInit',
method: 'post',
params:params,
});
}


export function getHouseCoownerInfoView(params){return request({
url: '/lcf/api/houseCoownerInfo/getHouseCoownerInfoView',
method: 'post',
params:params,
});
}


export function saveHouseCoownerInfo(data){return request({
url: '/lcf/api/houseCoownerInfo/saveHouseCoownerInfo',
method: 'post',
data:data,
});
}


export function deleteHouseCoownerInfo(params){return request({
url: '/lcf/api/houseCoownerInfo/deleteHouseCoownerInfo',
method: 'post',
params:params,
});
}


