import {omit} from 'lodash'

import BasicLayout from '@/layouts/basic-layout';
import dashboard from './modules/dashboard';
import workplatform from './modules/workplatform';
import khmroutes from './modules/khmroutes';
import storagemanageSontractsubject from './modules/storagemanage/ARContractSubject';
import storagemanagePropertydelivery from './modules/storagemanage/propertydelivery';
import houseSaveFollowManagement from './modules/storagemanage/houseSaveFollowManagement';
import components from './modules/storagemanage/components';
import publishAdvice from './modules/smallapplication/publishAdvice/publishAdvice';
import publishAdviceReply from './modules/smallapplication/publishAdvice/reply';
import storagemanageRentApply from './modules/storagemanage/rentApply';
import storagemanagePayApply from './modules/storagemanage/payApply';
import storagemanagePayManagement from './modules/storagemanage/payManagement';
import houseSaveDelegation from './modules/storagemanage/houseSaveDelegation';
import houseSaveDelegationCommissioner from './modules/storagemanage/houseSaveDelegationCommissioner';
import houseSaveDelegationAssessment from './modules/storagemanage/houseSaveDelegationAssessment';
import houseSaveDelegationAssessmentCompany from './modules/storagemanage/houseSaveDelegationAssessmentCompany';
import evaluateAppointment from './modules/storagemanage/evaluateAppointment';

import storagemanageSignHouseInfo from './modules/storagemanage/signHouseInfo';
import houseSaveChannelAssessment from './modules/storagemanage/houseSaveChannelAssessment';
import Building from './modules/storagemanage/Building';
import Block from './modules/storagemanage/Block';
import doorToDoorEvaluation from './modules/storagemanage/doorToDoorEvaluation';
import profitReport from './modules/storagemanage/profitReport';
import flowCenter from './modules/storagemanage/flowCenter';
import systemSetting from './modules/storagemanage/systemSetting';
import ConAttachment from './modules/storagemanage/ConAttachment';
import ContractSubjectAcount from './modules/storagemanage/arcontractsubjectcomponents/ContractSubjectAcount';
import merchant from './modules/storagemanage/merchant';
import product from './modules/storagemanage/product';
import Area from './modules/storagemanage/Area';
import StockpileIntention from './modules/storagemanage/StockpileIntention';
import StockpileIntentionSelf from './modules/storagemanage/StockpileIntentionSelf';
import HourseInfo from './modules/storagemanage/HourseInfo';
import HourseEntrust from './modules/storagemanage/HourseEntrust';
import EvaluateBill from './modules/storagemanage/EvaluateBill';
import FollowBill from './modules/storagemanage/FollowBill';
import StockpileIntentionOwner from './modules/storagemanage/StockpileIntentionOwner';
import DepositContractTemplate from './modules/storagemanage/DepositContractTemplate';
import ContractSubjectManage from './modules/storagemanage/ContractSubjectManage';
//权限相关
import OrgUserManage from './modules/systemmanage/OrgUserManage';
import MenuTreemManage from './modules/systemmanage/MenuTreemManage';
import AuthFuncRoleManage from './modules/systemmanage/AuthFuncRoleManage';
import OrganizationManage from './modules/systemmanage/OrganizationManage';

// 财务相关
import FinancePayment from './modules/storagemanage/FinancePayment';
import FinancePayApply from './modules/storagemanage/FinancePayApply';
import FinanceRentApply from './modules/storagemanage/FinanceRentApply';
import ManagementAreaUser from './modules/storagemanage/ManagementAreaUser';
import EvaluateAppointmentBill from './modules/storagemanage/EvaluateAppointmentBill';
import DroomPropertyDelivery from './modules/storagemanage/DroomPropertyDelivery';
import ManagementAreaChannel from './modules/storagemanage/ManagementAreaChannel';


import HouseRepeatPlan from './modules/storagemanage/HouseRepeatPlan';
import EvaluateAppointmentFinal from './modules/storagemanage/EvaluateAppointmentFinal';
import EvaluateAppointmentKeeprecord from './modules/storagemanage/EvaluateAppointmentKeeprecord';

//旧存房服务
import lcfHouseInfo from './modules/lcf/HouseInfo';
import HouseCycle from './modules/house/HouseCycle';
import HouseDuplicateRemoval from './modules/storagemanage/HouseDuplicateRemoval';
import CCBContract from './modules/house/CCBContract';
import CCBFinBill from './modules/house/CCBFinBill';
import AssignedBill from './modules/storagemanage/AssignedBill';

//财务开票小工具
import InvoiceToolsHouse from './modules/smallapplication/InvoiceToolsHouse';
import InvoiceToolsInvoice from './modules/smallapplication/InvoiceToolsInvoice';

import AssignedBillItem from './modules/storagemanage/assignedbillcomponents/AssignedBillItem';

import RentHouseSalesPrice from './modules/rentmanage/RentHouseSalesPrice';

import RentHouseOnline from './modules/rentmanage/RentHouseOnline';
import RentHouseHold from './modules/rentmanage/RentHouseHold';
import RentTenant from './modules/rentmanage/RentTenant';
import RentLegalEntity from './modules/rentmanage/RentLegalEntity';
import Channel from './modules/rentmanage/Channel';
import Storemanage from './modules/rentmanage/Storemanage';
import RentChannelApply from './modules/rentmanage/RentChannelApply';
import RentLeaseContract from './modules/rentmanage/RentLeaseContract';
import RentHouse from './modules/rentmanage/RentHouse';
import RentHouseChannel from './modules/rentmanage/RentHouseChannel';
import RentTakeLookRecord from './modules/rentmanage/RentTakeLookRecord';
//卡券
import RentHouseSalesCard from './modules/rentmanage/RentHouseSalesCard';
import RentHouseSalesPromotion from './modules/rentmanage/RentHouseSalesPromotion';

import RentHouseBig from './modules/rentmanage/RentHouseBig';
import RentPlaceOrder from './modules/rentmanage/RentPlaceOrder';

import RentPlaceOrderRetreat from './modules/rentmanage/RentPlaceOrderRetreat';

import ConDeputeContractBack from './modules/storagemanage/ConDeputeContractBack';
// 财务相关
import FinancialPayment from './modules/financial/FinancialPayment';
import FinancialElectronicAccount from './modules/financial/FinancialElectronicAccount';
import FinancialCollection from './modules/financial/FinancialCollection';
import FinancialTransfer from './modules/financial/FinancialTransfer';
import FinancialClaim from './modules/financial/FinancialClaim';
import FinancialSettlement from './modules/financial/FinancialSettlement';
import FinancialPayables from './modules/financial/FinancialPayables';
import FinancialEvidence from './modules/financial/FinancialEvidence';
import FinancialPreOrder from './modules/financial/FinancialPreOrder';
import FinancialAdjustmentApply from './modules/financial/FinancialAdjustmentApply';
import FinancialAdjustmentApplyItem from './modules/financial/FinancialAdjustmentApplyItem';

import RentRepairWorkOrder from './modules/rentmanage/RentRepairWorkOrder';
import RentRepairWorkOrderHandAssign from './modules/rentmanage/RentRepairWorkOrderHandAssign';
import RentMaterial from './modules/rentmanage/RentMaterial';
import RentMaterialReceivingMainForm from './modules/rentmanage/RentMaterialReceivingMainForm';
import RentRepairFeedbackDetails from './modules/rentmanage/RentRepairFeedbackDetails';
import RentPropertyDelivery from './modules/rentmanage/RentPropertyDelivery';
import RentRepairWorkOrderAssign from './modules/rentmanage/RentRepairWorkOrderAssign';
import RentLeaseContractChangeRequest from './modules/rentmanage/RentLeaseContractChangeRequest';
import EnergyRecord from './modules/rentmanage/EnergyRecord';
import RentPurchaseOrder from './modules/rentmanage/RentPurchaseOrder';
import FinancialPayApply from './modules/rentmanage/FinancialPayApply';
import RenewRent from './modules/rentmanage/RenewRent';
import ChangeRent from './modules/rentmanage/ChangeRent';
import ConversionRent from './modules/rentmanage/ConversionRent';
import TermsChangeRent from './modules/rentmanage/TermsChangeRent';
import RentHouseLock from './modules/rentmanage/RentHouseLock';
import RentHouseLockPassWord from './modules/rentmanage/RentHouseLockPassWord';

import RentHouseLockLog from './modules/rentmanage/RentHouseLockLog';
import RentTakeLookHouse from './modules/rentmanage/renttakelookrecordcomponents/RentTakeLookHouse';
import RentSerComplaint from './modules/rentmanage/RentSerComplaint';

import HrProjectParams from './modules/rentmanage/HrProjectParams';
import EnergyApplication from './modules/rentmanage/EnergyApplication';

//报表
import ReportFinancialRentContractItem from './modules/report/ReportFinancialRentContractItem';
import ReportFinancialRentContractItemUnfinished from './modules/report/ReportFinancialRentContractItemUnfinished';
import ReportRentContract from './modules/report/ReportRentContract';
import ReportRentContractRequestRelet from './modules/report/ReportRentContractRequestRelet';
import ReportRentContractRequestCheckoutLease from './modules/report/ReportRentContractRequestCheckoutLease';
import ReportFinancialRentContractItemCollection from './modules/report/ReportFinancialRentContractItemCollection';
import ReportFinancialRentContractItemPayment from './modules/report/ReportFinancialRentContractItemPayment';
import ReportFinancialTransfer from './modules/report/ReportFinancialTransfer';
import ReportPlaceorder from './modules/report/ReportPlaceorder';
import ReportFinancialStorageContract from './modules/report/ReportFinancialStorageContract';
import ReportFinancialStorageContractPlan from './modules/report/ReportFinancialStorageContractPlan';
import ReportRentHouseBig from './modules/report/ReportRentHouseBig';
import ReportFinancialRentContractDetailCollection from './modules/report/ReportFinancialRentContractDetailCollection';
import ReportCardUseCount from './modules/report/ReportCardUseCount';
import ReportHouseCount from './modules/report/ReportHouseCount';
import ReportFinancialPaymentPostpone from './modules/report/ReportFinancialPaymentPostpone';
import ReportFinancialRentContractItemPaymentCollection from './modules/report/ReportFinancialRentContractItemPaymentCollection';
import ReportFinancialRentContractItemPaymentMonth from './modules/report/ReportFinancialRentContractItemPaymentMonth';
import ReportFinancialRentContractItemPaymentCollectionMonth from './modules/report/ReportFinancialRentContractItemPaymentCollectionMonth';
import ReportFinancialRentContractDetailCollectionMonth from './modules/report/ReportFinancialRentContractDetailCollectionMonth';
import ReportFinancialOutsideStorageContract from './modules/report/ReportFinancialOutsideStorageContract';

import KhmPayDeductionApply from './modules/storagemanage/KhmPayDeductionApply';
import KhmDataChangesApply from './modules/rentmanage/KhmDataChangesApply';
import ConDeputeContractBackSettlement from './modules/storagemanage/ConDeputeContractBackSettlement';
import KhmDataAuthApply from './modules/rentmanage/KhmDataAuthApply';
import KhmDemandApply from './modules/rentmanage/KhmDemandApply';
import RentHouseTag from './modules/rentmanage/RentHouseTag';
import RentHouseTypeTag from './modules/rentmanage/RentHouseTypeTag';

import RentHousePic from './modules/rentmanage/RentHousePic';
import RentHouseTypePic from './modules/rentmanage/RentHouseTypePic';
import RentHouseSales from './modules/rentmanage/RentHouseSales';
import RentHouseTypeSales from './modules/rentmanage/RentHouseTypeSales';
import RentHouseFacility from './modules/rentmanage/RentHouseFacility';
import RentHousePutaway from './modules/rentmanage/RentHousePutaway';
import FindRoom from './modules/rentmanage/FindRoom';
import RentFacility from './modules/rentmanage/RentFacility';
import RentTag from './modules/rentmanage/RentTag';
import RentPublishChannel from './modules/rentmanage/RentPublishChannel';
import FinancialClaimCancellationApply from './modules/financial/FinancialClaimCancellationApply';

import PublishOperationLog from './modules/house/PublishOperationLog';
import Channelpic from './modules/rentmanage/Channelpic';
import Channeltag from './modules/rentmanage/Channeltag';

import ReportStorageIntention from './modules/report/ReportStorageIntention';
import RentBusinessArea from './modules/rentmanage/RentBusinessArea';
import RentTrafficLine from './modules/rentmanage/RentTrafficLine';
import RentHouseTraffic from './modules/rentmanage/RentHouseTraffic';
import RentHouseAirCheck from './modules/rentmanage/RentHouseAirCheck';
import RentGoodsSort from './modules/rentmanage/RentGoodsSort';
import RentHouseGoods from './modules/rentmanage/RentHouseGoods';
import ReportFinancialPaymentReminder from './modules/report/ReportFinancialPaymentReminder';
import ReportRentContractUndue from './modules/report/ReportRentContractUndue';
import RentIssueHouseApply from './modules/rentmanage/RentIssueHouseApply';
import ReportWorkflowCount from './modules/report/ReportWorkflowCount';

import RentViewOrderRetreat from './modules/rentmanage/RentViewOrderRetreat';

import FinancialTransferOprLog from './modules/financial/FinancialTransferOprLog';
import ReportBizRentContractItemPayment from './modules/report/ReportBizRentContractItemPayment';
import RentHouseHoldItemHistory  from './modules/rentmanage/RentHouseHoldItemHistory';

/**
 * 异步路由
 * @type { import('vue-router/types/router').Route[] }
 */
export const asyncRouterMap = [
  dashboard,
  workplatform,
  khmroutes,
  // mobile,
  storagemanageSontractsubject,
  storagemanagePropertydelivery,
  houseSaveFollowManagement,
  components,
  storagemanageRentApply,
  storagemanagePayApply,
  storagemanagePayManagement,
  houseSaveDelegation,
  houseSaveDelegationCommissioner,
  houseSaveDelegationAssessment,
  houseSaveDelegationAssessmentCompany,
  evaluateAppointment,
  storagemanageSignHouseInfo,
  houseSaveChannelAssessment,
  Building,
  Block,
  doorToDoorEvaluation,
  profitReport,
  flowCenter,
  systemSetting,
  ConAttachment,
  ContractSubjectAcount,
  merchant,
  product,
  Area,
  StockpileIntention,
  StockpileIntentionSelf,
  HourseInfo,
  HourseEntrust,
  EvaluateBill,
  FollowBill,
  StockpileIntentionOwner,
  DepositContractTemplate,
  ContractSubjectManage,
  OrgUserManage,
  MenuTreemManage,
  FinancePayment,
  FinancePayApply,
  FinanceRentApply,
  ManagementAreaUser,
  EvaluateAppointmentBill,
  DroomPropertyDelivery,
  ManagementAreaChannel,
  AuthFuncRoleManage,
  lcfHouseInfo,
  HouseCycle,
  HouseRepeatPlan,
  HouseDuplicateRemoval,
  OrganizationManage,
  CCBContract,
  CCBFinBill,
  EvaluateAppointmentFinal,
  EvaluateAppointmentKeeprecord,
  AssignedBill,
  InvoiceToolsHouse,
  InvoiceToolsInvoice,
  AssignedBillItem,
  RentHouseSalesPrice,
  RentHouseOnline,
  RentHouseHold,
  RentTenant,
  RentLegalEntity,
  Channel,
  RentChannelApply,
  RentHouse,
  RentHouseChannel,
  RentLeaseContract,
  RentTakeLookRecord,
  RentHouseSalesPromotion,
  RentHouseSalesCard,
  RentHouseBig,
  RentPlaceOrder,
  RentPlaceOrderRetreat,
  ConDeputeContractBack,
  FinancialPayment,
  FinancialElectronicAccount,
  FinancialCollection,
  FinancialTransfer,
  FinancialClaim,
  RentRepairWorkOrder,
  RentRepairWorkOrderHandAssign,
  RentMaterial,
  RentMaterialReceivingMainForm,
  RentRepairFeedbackDetails,
  RentPropertyDelivery,
  RentRepairWorkOrderAssign,
  RentLeaseContractChangeRequest,
  EnergyRecord,
  RentPurchaseOrder,
  FinancialSettlement,
  FinancialPayables,
  FinancialEvidence,
  FinancialPayApply,
  RenewRent,
  ChangeRent,
  ConversionRent,
  TermsChangeRent,
  RentHouseLock,
  RentHouseLockPassWord,
  FinancialPreOrder,
  RentHouseLockLog,
  RentTakeLookHouse,
  Storemanage,
  RentSerComplaint,
  HrProjectParams,
  ReportFinancialRentContractItem,
  ReportFinancialRentContractItemUnfinished,
  ReportRentContract,
  ReportRentContractRequestRelet,
  ReportRentContractRequestCheckoutLease,
  ReportFinancialRentContractItemCollection,
  ReportFinancialRentContractItemPayment,
  ReportFinancialTransfer,
  ReportPlaceorder,
  ReportFinancialStorageContract,
  ReportFinancialStorageContractPlan,
  KhmPayDeductionApply,
  KhmDataChangesApply,
  ConDeputeContractBackSettlement,
  ReportRentHouseBig,
  KhmDataAuthApply,
  ReportFinancialRentContractDetailCollection,
  KhmDemandApply,
  ReportCardUseCount,
  ReportHouseCount,
  FinancialAdjustmentApply,
  FinancialAdjustmentApplyItem,
  RentHouseTag,
  RentHouseTypeTag,
  RentHousePic,
  RentHouseTypePic,
  RentHouseSales,
  RentHouseTypeSales,
  RentHouseFacility,
  RentHousePutaway,
  RentFacility,
  RentTag,
  RentPublishChannel,
  FinancialClaimCancellationApply,
  PublishOperationLog,
  Channelpic,
  Channeltag,
  ReportFinancialPaymentPostpone,
  ReportStorageIntention,
  RentBusinessArea,
  RentTrafficLine,
  RentHouseTraffic,
  RentHouseAirCheck,
  RentGoodsSort,
  RentHouseGoods,
  ReportFinancialPaymentReminder,
  ReportRentContractUndue,
  RentIssueHouseApply,
  ReportWorkflowCount,
  RentViewOrderRetreat,
  EnergyApplication,
  ReportFinancialRentContractItemPaymentCollection,
  ReportFinancialRentContractItemPaymentMonth,
  ReportFinancialRentContractItemPaymentCollectionMonth,
  ReportFinancialRentContractDetailCollectionMonth,
  ReportFinancialOutsideStorageContract,
  FinancialTransferOprLog,
  ReportBizRentContractItemPayment,
  RentHouseHoldItemHistory
]

export const constantRouterMap = [
  {
    path: '/',
    hidden: true,
    redirect: {name: 'dashboard-console'},
    component: BasicLayout,
    children: [
      // 刷新页面 必须保留
      {
        path: 'refresh',
        name: 'refresh',
        meta: {auth: false},
        hidden: true,
        component: {
          beforeRouteEnter (to, from, next) {
            next(instance => instance.$router.replace(from.fullPath));
          },
          render: h => h()
        }
      },
      // 页面重定向 必须保留
      {
        path: 'redirect/:route*',
        name: 'redirect',
        meta: {auth: false},
        hidden: true,
        component: {
          beforeRouteEnter(to, from, next) {
            const route = decodeURIComponent(to.params.route)

            next({
              ...(route && route.includes('{'))
                ? route
                : {path: `/${route}`, query: omit(to.query, ['id', 'userCode', 'userType', 'accessToken'])},
              replace: true
            })
          },
          render: h => h()
        }
      }
    ]
  },
  {
    path: '/403',
    name: '403',
    meta: {auth: false, title: '403'},
    hidden: true,
    component: () => import('@/pages/system/error/403')
  },
  {
    path: '/500',
    name: '500',
    meta: {auth: false, title: '500'},
    hidden: true,
    component: () => import('@/pages/system/error/500')
  },
  {
    path: '/404',
    name: '404',
    meta: {auth: false, title: '404'},
    hidden: true,
    component: () => import('@/pages/system/error/404')
  },
  // pc端登录
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    redirect: '/'
  },
  //移动端登录(从微信跳转,无userId跳转到这个页面,然后用手机或者账号登录(带openId),并绑定)
  {
    path: '/wxlogin',
    name: 'wxlogin',
    meta: {
      title: '登录'
    },
    component: () => import('@/pages/account/wxlogin')
  },
  {
    path: '/qyWechatLogin',
    name: 'qyWechatLogin',
    meta: {
      title: '企业微信自动登录'
    },
    component: () => import('@/pages/account/qyWechatLogin')
  },
  // pc端登录微信登录中转
  {
    path: '/wechatLogin',
    name: 'wechatLogin',
    meta: {
      title: '登录'
    },
    component: () => import('@/pages/account/wechatLogin')
  },
  // 移动端登录微信登录中转
  {
    path: '/wxpubliclogin',
    name: 'wxpubliclogin',
    meta: {
      title: '登录'
    },
    component: () => import('@/pages/account/wechatLoginMobile')
  },
  // 移动端登录(租端)微信登录中转
  {
    path: '/rentwxloginjump',
    name: 'rentwxloginjump',
    meta: {
      title: '登录'
    },
    component: () => import('@/pages/account/wechatLoginRent')
  },
  //租端移动端登录(从微信跳转,无userId跳转到这个页面,然后用手机或者账号登录(带openId),并绑定)
  {
    path: '/rentuserlogin',
    name: 'rentuserlogin',
    meta: {
      title: '用户登录'
    },
    component: () => import('@/pages/account/wxloginRent')
  },
  //租端相关路由
  {
    path: '/rentusercenter',
    name: 'rentusercenter',
    meta: {
      title: '个人中心'
    },
    component: () => import('@/pages/mobile/rentusercenter')
  },
  //我的预定
  {
    path: '/rentmybook',
    name: 'rentmybook',
    meta: {
      title: '我的预定'
    },
    component: () => import('@/pages/mobile/rentusercenter/mybook')
  },
  //我的报修
  {
    path: '/myrepair',
    name: '/mobile/rentusercenter/myrepair',
    alias: ['/mobile/rentusercenter/myrepair'],
    meta: {
      title: '我的报修'
    },
    component: () => import('@/pages/mobile/rentusercenter/myrepair')
  },
  {
    path: '/mobile/rentusercenter/myrepair/detail-edit',
    name: '/mobile/rentusercenter/myrepair/detail-edit',
    meta: {
      title: '新建报修'
    },
    component: () => import('@/pages/mobile/rentusercenter/myrepair/detail-edit')
  },
  //我的投诉
  {
    path: '/mycomplaint',
    name: '/mobile/rentusercenter/mycomplaint',
    alias: ['/mobile/rentusercenter/mycomplaint'],
    meta: {
      title: '我的投诉'
    },
    component: () => import('@/pages/mobile/rentusercenter/mycomplaint')
  },

  {
    path: '/mobile/rentusercenter/mycomplaint/detail-edit',
    name: '/mobile/rentusercenter/mycomplaint/detail-edit',
    meta: {
      title: '新建投诉'
    },
    component: () => import('@/pages/mobile/rentusercenter/mycomplaint/detail-edit')
  },
  //我的管家
  {
    path: '/myhousekeeper',
    name: 'myhousekeeper',
    meta: {
      title: '我的管家'
    },
    component: () => import('@/pages/mobile/rentusercenter/myhousekeeper')
  },
  //优惠券
  {
    path: '/mobile/card',
    name: '/mobile/card',
    meta: {
      title: '优惠券'
    },
    component: () => import('@/pages/mobile/rentusercenter/card')
  },
  //我的账单
  {
    path: '/rentmyorder',
    name: 'rentmyorder',
    meta: {
      title: '我的账单'
    },
    component: () => import('@/pages/mobile/rentusercenter/order')
  },
  //我的账单列表
  {
    path: '/rentmybilllist',
    name: 'rentmybilllist',
    meta: {
      title: '账单列表'
    },
    component: () => import('@/pages/mobile/rentusercenter/order/components/bill-list')
  },
  //支付结果
  {
    path: '/payresult',
    name: 'payresult',
    meta: {
      title: '支付结果'
    },
    component: () => import('@/pages/mobile/rentusercenter/order/payResult')
  },
  //我的结算单详情
  {
    path: '/rentmysettlementdetail',
    name: 'rentmysettlementdetail',
    meta: {
      title: '结算单详情'
    },
    component: () => import('@/pages/financial/financialsettlement/detail-view')
  },
  //支付记录
  {
    path: '/rentpayrecord',
    name: 'rentpayrecord',
    meta: {
      title: '支付记录'
    },
    component: () => import('@/pages/mobile/rentusercenter/order/record')
  },
  //支付调转
  {
    path: '/payjump',
    name: 'payjump',
    meta: {
      title: '支付跳转'
    },
    component: () => import('@/pages/mobile/rentusercenter/order/payjump')
  },
  //批量支付
  {
    path: '/rentbatchpay',
    name: 'rentbatchpay',
    meta: {
      title: '支付'
    },
    component: () => import('@/pages/mobile/rentusercenter/order/batch-pay')
  },
  //支付
  {
    path: '/rentpay',
    name: 'rentpay',
    meta: {
      title: '支付'
    },
    component: () => import('@/pages/mobile/rentusercenter/order/pay')
  },
  //小程序跳转支付
  {
    path: '/wxtransferpay',
    name: 'wxtransferpay',
    meta: {
      title: '支付'
    },
    component: () => import('@/pages/mobile/rentusercenter/order/wx-transfer-pay')
  },
  // 注册
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '注册'
    },
    component: () => import('@/pages/account/register')
  },
  // 注册结果
  {
    path: '/register/result',
    name: 'register-result',
    meta: {
      auth: true,
      title: '注册结果'
    },
    component: () => import('@/pages/account/register/result')
  },
  {
    path: '/wepublicIndex',
    name: `wepublicIndex`,
    meta: {
      auth: false,
      title: '在线存房'
    },
    component: () => import('@/pages/mobile/savehouse')
  },
  {
    path: '/mobile/provider-detail',
    name: '/mobile/provider-detail',
    meta: {
      auth: false,
      title: '服务商介绍'
    },
    component: () => import('@/pages/mobile/savehouse/providerDetail')
  },
  {
    path: '/mobile/project-detail',
    name: '/mobile/project-detail',
    meta: {
      auth: false,
      title: '项目介绍'
    },
    component: () => import('@/pages/mobile/savehouse/projectDetail')
  },
  {
    path: '/mobile/sinature',
    name: '/mobile/sinature',
    meta: {
      auth: false,
      title: '合同签约'
    },
    component: () => import('@/pages/mobile/sinature')
  },
  {
    path: '/mobile/sinature/signature',
    name: '/mobile/sinature/signature',
    meta: {
      auth: false,
      title: '签字'
    },
    component: () => import('@/pages/mobile/sinature/signature')
  },
  {
    path: '/mobile/tenantsinature',
    name: '/mobile/tenantsinature',
    meta: {
      auth: false,
      title: '租客签约'
    },
    component: () => import('@/pages/mobile/tenantsinature')
  },
  {
    path: '/mobile/tenantsinature/signature',
    name: '/mobile/tenantsinature/signature',
    meta: {
      auth: false,
      title: '租客签字'
    },
    component: () => import('@/pages/mobile/tenantsinature/signature')
  },
  {
    path: '/mobile/tenantordersign',
    name: '/mobile/tenantordersign',
    meta: {
      auth: false,
      title: '租客下定签字首页'
    },
    component: () => import('@/pages/mobile/tenantordersign')
  },
  {
    path: '/mobile/tenantordersign/signature',
    name: '/mobile/tenantordersign/signature',
    meta: {
      auth: false,
      title: '租客下定签字面板'
    },
    component: () => import('@/pages/mobile/tenantordersign/signature')
  },
  {
    path: '/mobile/tenantterminationsign',
    name: '/mobile/tenantterminationsign',
    meta: {
      auth: false,
      title: '解约签字首页'
    },
    component: () => import('@/pages/mobile/tenantterminationsign')
  },
  {
    path: '/mobile/tenantterminationsign/signature',
    name: '/mobile/tenantterminationsign/signature',
    meta: {
      auth: false,
      title: '解约签字面板'
    },
    component: () => import('@/pages/mobile/tenantterminationsign/signature')
  },
  {
    path: '/mobile/tenantCardSinature',
    name: '/mobile/tenantCardSinature',
    meta: {
      auth: false,
      title: '租客卡券签署首页'
    },
    component: () => import('@/pages/mobile/tenantCardSinature')
  },
  {
    path: '/mobile/tenantCardSignature/signature',
    name: '/mobile/tenantCardSignature/signature',
    meta: {
      auth: false,
      title: '租客卡券签字'
    },
    component: () => import('@/pages/mobile/tenantCardSinature/signature')
  },
  {
    path: '/mobile/tenantPropertyDeliverysinature',
    name: '/mobile/tenantPropertyDeliverysinature',
    meta: {
      auth: false,
      title: '房屋交割'
    },
    component: () => import('@/pages/mobile/tenantPropertyDeliverysinature')
  },
  {
    path: '/mobile/tenantPropertyDeliverysinature/signature',
    name: '/mobile/tenantPropertyDeliverysinature/signature',
    meta: {
      auth: false,
      title: '房屋交割签字'
    },
    component: () => import('@/pages/mobile/tenantPropertyDeliverysinature/signature')
  },
  {
    path: '/mobile/tenantChangePropertyDeliverysinature',
    name: '/mobile/tenantChangePropertyDeliverysinature',
    meta: {
      auth: false,
      title: '房屋退租交割'
    },
    component: () => import('@/pages/mobile/tenantChangePropertyDeliverysinature')
  },
  {
    path: '/mobile/tenantChangePropertyDeliverysinature/signature',
    name: '/mobile/tenantChangePropertyDeliverysinature/signature',
    meta: {
      auth: false,
      title: '房屋退租交割签字'
    },
    component: () => import('@/pages/mobile/tenantChangePropertyDeliverysinature/signature')
  },
  {
    path: '/mobile/tenantChangePropertyDeliverysinature/faceVerification',
    name: '/mobile/tenantChangePropertyDeliverysinature/faceVerification',
    meta: {
      auth: false,
      title: '人脸验证'
    },
    component: () => import('@/pages/mobile/tenantChangePropertyDeliverysinature/faceVerification')
  },
  {
    path: '/mobile/backSettlementSinature',
    name: '/mobile/backSettlementSinature',
    meta: {
      auth: false,
      title: '解约复函'
    },
    component: () => import('@/pages/mobile/backSettlementSinature/index')
  },
  {
    path: '/mobile/releaseAgreementSignature',
    name: '/mobile/releaseAgreementSignature',
    meta: {
      auth: false,
      title: '解约协议'
    },
    component: () => import('@/pages/mobile/releaseAgreementSignature')
  },
  {
    path: '/mobile/releaseAgreementSignature/signature',
    name: '/mobile/releaseAgreementSignature/signature',
    meta: {
      auth: false,
      title: '解约协议签字'
    },
    component: () => import('@/pages/mobile/releaseAgreementSignature/signature')
  },
  /*******************我的合同*************************/
  {
    path: '/rentcontract',
    name: 'rentcontract',
    meta: {
      title: '我的合同'
    },
    component: () => import('@/pages/mobile/rentusercenter/contract')
  },
  {
    path: '/mobile/rentusercenter/contract/contractDetail',
    name: '/mobile/rentusercenter/contract/contractDetail',
    meta: {
      title: '合同详情'
    },
    component: () => import('@/pages/mobile/rentusercenter/contract/contractDetail')
  },
  {
    path: '/mobile/rentusercenter/contract/changeRequestContract',
    name: '/mobile/rentusercenter/contract/changeRequestContract',
    meta: {
      title: '合同变更申请'
    },
    component: () => import('@/pages/mobile/rentusercenter/contract/changeRequestContract')
  },
  {
    path: '/mobile/rentusercenter/contract/changeRequestContractView',
    name: '/mobile/rentusercenter/contract/changeRequestContractView',
    meta: {
      title: '合同变更详情'
    },
    component: () => import('@/pages/mobile/rentusercenter/contract/changeRequestContractView')
  },
  {
    path: '/smartLocks',
    name: 'smartLocks',
    meta: {
      title: '智能门锁'
    },
    component: () => import('@/pages/mobile/rentusercenter/smartLocks')
  },
  {
    path: '/authenticationEdit',
    name: 'authenticationEdit',
    meta: {
      title: '编辑个人信息'
    },
    component: () => import('@/pages/mobile/rentusercenter/realNameAuthentication/edit')
  },
  {
    path: '/authenticationView',
    name: 'authenticationView',
    meta: {
      title: '查看个人信息'
    },
    component: () => import('@/pages/mobile/rentusercenter/realNameAuthentication/view')
  },
  {
    path: '/storagemanage/evaluateappointmentfinal/detail-view-wx/:id',
    name: 'EvaluateAppointmentFinalViewWX',
    meta: {
      title: '预约终谈详情查看'
    },
    component: () => import('@/pages/storagemanage/evaluateappointmentfinal/detail-view-wx'),
  },
  {
    path: '/rentmanage/findroom/index',
    name: 'findroom-index',
    meta: {
      title: '我要找房',
    },
    component: () => import('@/pages/rentmanage/findroom/index')
  }, {
    path: '/rentmanage/findroom/map-find-house',
    name: 'mapFindHouse',
    meta: {
      title: '地图找房',
    },
    component: () => import('@/pages/rentmanage/findroom/map-find-house')
  }, {
    path: '/rentmanage/findroom/air-test',
    name: 'airTest',
    meta: {
      title: '空气检测',
    },
    component: () => import('@/pages/rentmanage/findroom/air-test')
  }, {
    path: '/rentmanage/findroom/video',
    name: 'video',
    meta: {
      title: '视频播放',
    },
    component: () => import('@/pages/rentmanage/findroom/video')
  }, {
    path: '/rentmanage/findroom/detail-view/:id',
    name: 'findroomView',
    meta: {
      title: '房源详情'
    },
    component: () => import('@/pages/rentmanage/findroom/detail-view'),
  },
  {
    path: '/rentmanage/findroom/indexphone',
    name: 'findroom-indexphone',
    meta: {
      title: '我要手机找房',
    },
    component: () => import('@/pages/rentmanage/findroom/indexphone')
  }, {
    path: '/rentmanage/findroom/detail-view-phone/:id',
    name: 'findroomPhoneView',
    meta: {
      title: '房源手机详情'
    },
    component: () => import('@/pages/rentmanage/findroom/detail-view-phone'),
  },
  {
    path: '/rentmanage/findroom/detail-view-map-phone',
    name: 'findroomMapPhoneView',
    meta: {
      title: '房源地图详情'
    },
    component: () => import('@/pages/rentmanage/findroom/detail-view-map-phone'),
  },
  //补充入住人
  {
    path: '/mobile/add-occupant',
    name: '/mobile/add-occupant',
    meta: {
      title: '补充入住人'
    },
    component: () => import('@/pages/mobile/add-occupant')
  },
  /*******************我的合同*************************/
  publishAdvice,
  publishAdviceReply
]

export default [
  ...constantRouterMap,
  ...asyncRouterMap,
  { path: '*', redirect: '/404', hidden: true }
];
