/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-09T15:06:38.706
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancePaymentInit(data){return request({
url: '/storagemanage/api/financePayment/listFinancePaymentInit',
method: 'post',
data:data,
});
}


export function listFinancePaymentByAuthorizations(data){return request({
url: '/storagemanage/api/financePayment/listFinancePaymentByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancePayment(data){return request({
url: '/storagemanage/api/financePayment/listFinancePayment',
method: 'post',
data:data,
});
}


export function getFinancePaymentCreateInit(){return request({
url: '/storagemanage/api/financePayment/getFinancePaymentCreateInit',
method: 'post',
});
}


export function getFinancePaymentEditInit(params){return request({
url: '/storagemanage/api/financePayment/getFinancePaymentEditInit',
method: 'post',
params:params,
});
}


export function getFinancePaymentView(params){return request({
url: '/storagemanage/api/financePayment/getFinancePaymentView',
method: 'post',
params:params,
});
}


export function saveFinancePayment(data){return request({
url: '/storagemanage/api/financePayment/saveFinancePayment',
method: 'post',
data:data,
});
}


export function deleteFinancePayment(params){return request({
url: '/storagemanage/api/financePayment/deleteFinancePayment',
method: 'post',
params:params,
});
}


