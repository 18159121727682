/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-30T21:02:57.45
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancialClaim,
	saveSettlementList,
	getFinancialClaimCreateInit,
	getFinancialClaimEditInit,
	getFinancialClaimView,
	deleteFinancialClaim,
	getFinancialTransferListCreateInit
} from '@api/financial/FinancialClaim';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
	listFinancialClaimItemInit,
	listFinancialClaimItemByAuthorizations,
	deleteFinancialClaimItem
} from '@api/financial/FinancialClaimItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialClaimCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialClaimCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialClaimEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialClaimEditInit({
					id
				})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialClaim({ }, FinancialClaim) {
			return new Promise((resolve, reject) => {
				saveFinancialClaim(FinancialClaim)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		// 认领单管理编辑多选保存
		saveSettlementList({ }, FinancialClaim) {
			return new Promise((resolve, reject) => {
				saveSettlementList(FinancialClaim)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		// 认领单管理编辑多选保存
		getFinancialTransferListCreateInit({ }, obj) {
			return new Promise((resolve, reject) => {
				getFinancialTransferListCreateInit(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},



		getFinancialClaimView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialClaimView({
					id
				})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialClaim({
			state
		}, { id }) {
			return new Promise((resolve, reject) => {
				deleteFinancialClaim({
					id
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
