import util from '@/libs/util';
import router from '@/router';
import {
	listEvaluateBillAttachment,
	listEvaluateBillAttachmentInit,
	listEvaluateBillAttachmentByAuthorizations,
	deleteEvaluateBillAttachment
} from '@api/storagemanage/EvaluateBillAttachment';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listEvaluateBillAttachment({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillAttachment(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateBillAttachmentByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillAttachmentByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateBillAttachmentInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillAttachmentInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateBillAttachment({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateBillAttachment({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
