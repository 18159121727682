/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-07-02T16:29:31.16
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialRentContractDetailCollectionMonthInit(data){return request({
url: '/report/api/reportFinancialRentContractDetailCollectionMonth/listReportFinancialRentContractDetailCollectionMonthInit',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractDetailCollectionMonthByAuthorizations(data){return request({
url: '/report/api/reportFinancialRentContractDetailCollectionMonth/listReportFinancialRentContractDetailCollectionMonthByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractDetailCollectionMonth(data){return request({
url: '/report/api/reportFinancialRentContractDetailCollectionMonth/listReportFinancialRentContractDetailCollectionMonth',
method: 'post',
data:data,
});
}


export function getReportFinancialRentContractDetailCollectionMonthCreateInit(){return request({
url: '/report/api/reportFinancialRentContractDetailCollectionMonth/getReportFinancialRentContractDetailCollectionMonthCreateInit',
method: 'post',
});
}


export function getReportFinancialRentContractDetailCollectionMonthEditInit(params){return request({
url: '/report/api/reportFinancialRentContractDetailCollectionMonth/getReportFinancialRentContractDetailCollectionMonthEditInit',
method: 'post',
params:params,
});
}


export function getReportFinancialRentContractDetailCollectionMonthView(params){return request({
url: '/report/api/reportFinancialRentContractDetailCollectionMonth/getReportFinancialRentContractDetailCollectionMonthView',
method: 'post',
params:params,
});
}


export function saveReportFinancialRentContractDetailCollectionMonth(data){return request({
url: '/report/api/reportFinancialRentContractDetailCollectionMonth/saveReportFinancialRentContractDetailCollectionMonth',
method: 'post',
data:data,
});
}


export function deleteReportFinancialRentContractDetailCollectionMonth(params){return request({
url: '/report/api/reportFinancialRentContractDetailCollectionMonth/deleteReportFinancialRentContractDetailCollectionMonth',
method: 'post',
params:params,
});
}


