import util from '@/libs/util';
import router from '@/router';
import {
	saveStockpileIntention,
	getStockpileIntentionCreateInit,
	getStockpileIntentionEditInit,
	getStockpileIntentionView,
	deleteStockpileIntention
} from '@api/storagemanage/StockpileIntention';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getStockpileIntentionCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getStockpileIntentionCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getStockpileIntentionEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getStockpileIntentionEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveStockpileIntention({}, StockpileIntention) {
			return new Promise((resolve, reject) => { 
				saveStockpileIntention(StockpileIntention)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getStockpileIntentionView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getStockpileIntentionView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteStockpileIntention({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				deleteStockpileIntention({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
