import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'storagemanage-hourseinfo-'

export default {
	path: '/storagemanage/hourseinfo',
	name: 'storagemanage-hourseinfo',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}hourseinfo-index',

			meta: {
				...meta,
				title: '房源表(签约后房源信息)'
			},

			component: () => import('@/pages/storagemanage/hourseinfo/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}hourseinfo-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '房源表(签约后房源信息)编辑'
			},

			component: () => import('@/pages/storagemanage/hourseinfo/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}hourseinfodetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '房源表(签约后房源信息)新增'
			},

			component: () => import('@/pages/storagemanage/hourseinfo/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}hourseinfoView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '房源表(签约后房源信息)详情查看'
			},

			component: () => import('@/pages/storagemanage/hourseinfo/detail-view'),
		},
	]
};
