/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-01T19:13:36.239
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveConDeputeContractBackSettlement,
	getConDeputeContractBackSettlementCreateInit,
	getConDeputeContractBackSettlementEditInit,
	getConDeputeContractBackSettlementView,
	deleteConDeputeContractBackSettlement
} from '@api/storagemanage/ConDeputeContractBackSettlement';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listConDeputeContractBackSettlementItemInit,
listConDeputeContractBackSettlementItemByAuthorizations,
deleteConDeputeContractBackSettlementItem
} from '@api/storagemanage/ConDeputeContractBackSettlementItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getConDeputeContractBackSettlementCreateInit({
			state
		},createInitInVo) {
			return new Promise((resolve, reject) => {
				getConDeputeContractBackSettlementCreateInit(createInitInVo)
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getConDeputeContractBackSettlementEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getConDeputeContractBackSettlementEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveConDeputeContractBackSettlement({}, ConDeputeContractBackSettlement) {
			return new Promise((resolve, reject) => {
				saveConDeputeContractBackSettlement(ConDeputeContractBackSettlement)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getConDeputeContractBackSettlementView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getConDeputeContractBackSettlementView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteConDeputeContractBackSettlement({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteConDeputeContractBackSettlement({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
