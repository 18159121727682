/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2021-07-29T14:37:11.977
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'report-reportfinancialoutsidestoragecontract-'

export default {
	path: '/report/reportfinancialoutsidestoragecontract',
	name: 'report-reportfinancialoutsidestoragecontract',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}reportfinancialoutsidestoragecontract-index',

			meta: {
				...meta,
				title: '存房合同(外部)'
			},

			component: () => import('@/pages/report/reportfinancialoutsidestoragecontract/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}reportfinancialoutsidestoragecontract-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '存房合同(外部)编辑'
			},

			component: () => import('@/pages/report/reportfinancialoutsidestoragecontract/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}reportfinancialoutsidestoragecontractdetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '存房合同(外部)新增'
			},

			component: () => import('@/pages/report/reportfinancialoutsidestoragecontract/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}reportfinancialoutsidestoragecontractView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '存房合同(外部)详情查看'
			},

			component: () => import('@/pages/report/reportfinancialoutsidestoragecontract/detail-view'),
		},
	]
};
