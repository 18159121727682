/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-20T20:38:25.776
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getDroomPropertyDeliveryEditInit,
	getDroomPropertyDeliveryView
} from '@api/storagemanage/DroomPropertyDelivery';

import {
listDroomConEstateInfoInit,
listDroomConEstateInfoByAuthorizations,
deleteDroomConEstateInfo
} from '@api/storagemanage/DroomConEstateInfo';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getDroomPropertyDeliveryEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getDroomPropertyDeliveryEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getDroomPropertyDeliveryView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getDroomPropertyDeliveryView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
