/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-23T14:44:27.037
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentHouseGoods,
	listRentHouseGoodsInit,
	listRentHouseGoodsByAuthorizations,
	deleteRentHouseGoods
	
} from '@api/rentmanage/RentHouseGoods';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentHouseGoods({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseGoods(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseGoodsByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseGoodsByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseGoodsInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseGoodsInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseGoods({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseGoods({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
