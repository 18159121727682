/**
 * @version GENERATOR_VERSION::20200422
 * @DateTime 2020-05-01T18:25:53.535
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import util from '@/libs/util';
import router from '@/router';
import {
	listOrganizationManage,
	listOrganizationManageInit,
	listOrganizationManageByAuthorizations,
	deleteOrganizationManage,
	getTreeData,
	getOrganizationManageEditInit,

} from '@api/systemmanage/OrganizationManage';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
		treeParams: {
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": false,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 1,
			"pageSize": 10,
			"selectColumns": [],
			"specificChannel": {}
		},
	},

	actions: {
		listOrganizationManage({
			state
		}) {
			return new Promise((resolve, reject) => {
				listOrganizationManage(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listOrganizationManageByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listOrganizationManageByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities = res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listOrganizationManageInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listOrganizationManageInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteOrganizationManage({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				deleteOrganizationManage({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getTreeData({
			state
		}) {
			return new Promise((resolve, reject) => {
				getTreeData(state.treeParams)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		getDataEdit({
			state
		},id) {
			return new Promise((resolve, reject) => {
				getOrganizationManageEditInit({
					id:id
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
