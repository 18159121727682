/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-01-05T10:42:26.402
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'financial-FinancialElectronicAccount-'

export default 
{
path: '/financial/financialelectronicaccount',
name: 'financial-FinancialElectronicAccount',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}FinancialElectronicAccount-index',

meta:{
...meta,
title: '财务电子账户'
},

component: () => import('@/pages/financial/financialelectronicaccount/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}FinancialElectronicAccount-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '财务电子账户编辑'
},

component: () => import('@/pages/financial/financialelectronicaccount/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}FinancialElectronicAccountdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '财务电子账户新增'
},

component: () => import('@/pages/financial/financialelectronicaccount/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}FinancialElectronicAccountView',
auth: [
'hidden'
],

meta:{
...meta,
title: '财务电子账户详情查看'
},

component: () => import('@/pages/financial/financialelectronicaccount/detail-view') ,
},
]
};
