import BasicLayout from '@/layouts/basic-layout';

const meta = {
  auth: true
};

const pre = 'khm-';

export default {
  path: '/khm',
  name: 'khm-0',
  redirect: {
    name: `${pre}house-list`
  },
  meta,
  component: BasicLayout,
  children: [
    {
      path: 'condeputecontract-list',
      name: `${pre}condeputecontract-list`,
      meta: {
        ...meta,
        title: '业主签约',
        cache: true,
        closable: true
      },
      component: () => import('@/pages/storagemanage/condeputecontract/index')
    },
    {
      path: 'condeputecontract-edit',
      name: `${pre}condeputecontract-edit`,
      meta: {
        ...meta,
        title: '业主签约编辑',
        cache: true,
        closable: true
      },
      component: () => import('@/pages/storagemanage/condeputecontract/edit')
    },
    {
      path: 'condeputecontract-check',
      name: `${pre}condeputecontract-check`,
      meta: {
        ...meta,
        title: '业主签约详情查看',
        closable: true
      },
      component: () => import('@/pages/storagemanage/condeputecontract/check')
    },
  ]
};
