/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-02T18:37:11.525
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import util from '@/libs/util';
import router from '@/router';
import {
	listInvoiceToolsInvoice,
	listInvoiceToolsInvoiceInit,
	listInvoiceToolsInvoiceByAuthorizations,
	deleteInvoiceToolsInvoice,
	changeInvoiceToolsInvoiceStatus

} from '@api/smallapplication/InvoiceToolsInvoice';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listInvoiceToolsInvoice({
			state
		}) {
			return new Promise((resolve, reject) => {
				listInvoiceToolsInvoice(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listInvoiceToolsInvoiceByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listInvoiceToolsInvoiceByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities = res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listInvoiceToolsInvoiceInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listInvoiceToolsInvoiceInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteInvoiceToolsInvoice({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				deleteInvoiceToolsInvoice({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		changeInvoiceToolsInvoiceStatus({}, {
			id,
			status
		}) {
			return new Promise((resolve, reject) => {
				changeInvoiceToolsInvoiceStatus({
						id,
						status
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
