/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-25T10:30:39.702
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentHouseSales,
	listRentHouseSalesInit,
	listRentHouseSalesByAuthorizations,
	deleteRentHouseSales
	
} from '@api/rentmanage/RentHouseSales';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentHouseSales({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseSales(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseSalesByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseSalesByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listRentHouseSalesInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseSalesInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseSales({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseSales({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
