/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-06T11:28:50.141
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportFinancialRentContractItem,
	getReportFinancialRentContractItemCreateInit,
	getReportFinancialRentContractItemEditInit,
	getReportFinancialRentContractItemView,
	deleteReportFinancialRentContractItem
} from '@api/report/ReportFinancialRentContractItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportFinancialRentContractItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportFinancialRentContractItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportFinancialRentContractItem({}, ReportFinancialRentContractItem) {
			return new Promise((resolve, reject) => {
				saveReportFinancialRentContractItem(ReportFinancialRentContractItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportFinancialRentContractItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportFinancialRentContractItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportFinancialRentContractItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
