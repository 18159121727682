/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-09T15:13:19.201
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: true
};

const pre = 'storagemanage-depositcontracttemplate-'

export default {
    path: '/storagemanage/depositcontracttemplate',
    name: 'storagemanage-depositcontracttemplate',
    redirect: {
        name: '${pre}-index'
    },

    meta: {
        ...meta,
    },

    component: BasicLayout,
    children: [{
            path: 'index',
            name: '${pre}depositcontracttemplate-index',

            meta: {
                ...meta,
                title: '存房合同模板'
            },

            component: () => import('@/pages/storagemanage/depositcontracttemplate/index'),
        },
        {
            path: 'detail-edit/:id',
            name: '${pre}depositcontracttemplate-detail-edit',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '存房合同模板编辑'
            },

            component: () => import('@/pages/storagemanage/depositcontracttemplate/detail-edit'),
        },
        {
            path: 'detail-edit',
            name: '${pre}depositcontracttemplatedetail-create',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '存房合同模板新增'
            },

            component: () => import('@/pages/storagemanage/depositcontracttemplate/detail-edit'),
        },
        {
            path: 'detail-view/:id',
            name: '${pre}depositcontracttemplateView',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '存房合同模板详情查看'
            },

            component: () => import('@/pages/storagemanage/depositcontracttemplate/detail-view'),
        },
    ]
};
