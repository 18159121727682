/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-09T17:00:10.553
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentRepairFeedbackCall,
	getRentRepairFeedbackCallCreateInit,
	getRentRepairFeedbackCallEditInit,
	getRentRepairFeedbackCallView,
	deleteRentRepairFeedbackCall
} from '@api/rentmanage/RentRepairFeedbackCall';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentRepairFeedbackCallCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairFeedbackCallCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentRepairFeedbackCallEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairFeedbackCallEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentRepairFeedbackCall({}, RentRepairFeedbackCall) {
			return new Promise((resolve, reject) => {
				saveRentRepairFeedbackCall(RentRepairFeedbackCall)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentRepairFeedbackCallView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairFeedbackCallView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentRepairFeedbackCall({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentRepairFeedbackCall({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
