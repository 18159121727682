/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-11T09:42:55.35
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialRentContractItemCollectionInit(data){return request({
url: '/report/api/reportFinancialRentContractItemCollection/listReportFinancialRentContractItemCollectionInit',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractItemCollectionByAuthorizations(data){return request({
url: '/report/api/reportFinancialRentContractItemCollection/listReportFinancialRentContractItemCollectionByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractItemCollection(data){return request({
url: '/report/api/reportFinancialRentContractItemCollection/listReportFinancialRentContractItemCollection',
method: 'post',
data:data,
});
}


export function getReportFinancialRentContractItemCollectionCreateInit(){return request({
url: '/report/api/reportFinancialRentContractItemCollection/getReportFinancialRentContractItemCollectionCreateInit',
method: 'post',
});
}


export function getReportFinancialRentContractItemCollectionEditInit(params){return request({
url: '/report/api/reportFinancialRentContractItemCollection/getReportFinancialRentContractItemCollectionEditInit',
method: 'post',
params:params,
});
}


export function getReportFinancialRentContractItemCollectionView(params){return request({
url: '/report/api/reportFinancialRentContractItemCollection/getReportFinancialRentContractItemCollectionView',
method: 'post',
params:params,
});
}


export function saveReportFinancialRentContractItemCollection(data){return request({
url: '/report/api/reportFinancialRentContractItemCollection/saveReportFinancialRentContractItemCollection',
method: 'post',
data:data,
});
}


export function deleteReportFinancialRentContractItemCollection(params){return request({
url: '/report/api/reportFinancialRentContractItemCollection/deleteReportFinancialRentContractItemCollection',
method: 'post',
params:params,
});
}


