/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-20T16:51:35.083
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialPayApplyInit(data){return request({
url: '/rentmanage/api/financialPayApply/listFinancialPayApplyInit',
method: 'post',
data:data,
});
}


export function listFinancialPayApplyByAuthorizations(data){return request({
url: '/rentmanage/api/financialPayApply/listFinancialPayApplyByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialPayApply(data){return request({
url: '/rentmanage/api/financialPayApply/listFinancialPayApply',
method: 'post',
data:data,
});
}


export function getFinancialPayApplyCreateInit(){return request({
url: '/rentmanage/api/financialPayApply/getFinancialPayApplyCreateInit',
method: 'post',
});
}


export function getFinancialPayApplyEditInit(params){return request({
url: '/rentmanage/api/financialPayApply/getFinancialPayApplyEditInit',
method: 'post',
params:params,
});
}


export function getFinancialPayApplyView(params){return request({
url: '/rentmanage/api/financialPayApply/getFinancialPayApplyView',
method: 'post',
params:params,
});
}


export function saveFinancialPayApply(data){return request({
url: '/rentmanage/api/financialPayApply/saveFinancialPayApply',
method: 'post',
data:data,
});
}


export function deleteFinancialPayApply(params){return request({
url: '/rentmanage/api/financialPayApply/deleteFinancialPayApply',
method: 'post',
params:params,
});
}


