/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-11T09:45:26.43
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportfinancialrentcontractitempayment-'

export default 
{
path: '/report/reportfinancialrentcontractitempayment',
name: 'report-reportfinancialrentcontractitempayment',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportfinancialrentcontractitempayment-index',

meta:{
...meta,
title: '应收报表'
},

component: () => import('@/pages/report/reportfinancialrentcontractitempayment/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportfinancialrentcontractitempayment-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '应收报表编辑'
},

component: () => import('@/pages/report/reportfinancialrentcontractitempayment/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportfinancialrentcontractitempaymentdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '应收报表新增'
},

component: () => import('@/pages/report/reportfinancialrentcontractitempayment/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportfinancialrentcontractitempaymentView',
auth: [
'hidden'
],

meta:{
...meta,
title: '应收报表详情查看'
},

component: () => import('@/pages/report/reportfinancialrentcontractitempayment/detail-view') ,
},
]
};
