const pre = '/dashboard/';

export default {
    path: '/dashboard',
    title: '工作台',
    header: 'home',
    icon: 'md-speedometer',
    children: [
		{
		    path: `/workplatform/outGoingReport`,
		    title: '行程报备',
		},
		{
		    path: `#21`,
		    title: '健康上报',
		},
		{
		    path: `#22`,
		    title: '疫情上报',
		},
		{
		    path: `http://house.giantfind.com.cn/dkhreport-pchtml/#/reportManagement/report/previewNew?id=dwh_subject_sub_daily_operations_day&reportName=%E8%BF%90%E8%90%A5%E6%97%A5%E6%8A%A5_%E6%80%BB%E8%A1%A8`,
		    title: '动态可视化数据',
			target:'_blank'
		},{//第一步：配置菜单-->第二步：配置流程中心页面相关的路由-->第三步：注入路由-->第四步：做页面
		    path: `/flow/flowCenter-list`,
		    title: '流程中心',
		},
		{
		    path: `${pre}console`,
		    title: '主控台'
		}
    ]
}
