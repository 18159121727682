/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-07-13T16:30:31.512
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: true
};

const pre = 'rentmanage-energyrecord-'

export default {
    path: '/rentmanage/energyrecord',
    name: 'rentmanage-energyrecord',
    redirect: {
        name: '${pre}-index'
    },

    meta: {
        ...meta,
    },

    component: BasicLayout,
    children: [{
            path: 'index',
            name: '${pre}energyrecord-index',

            meta: {
                ...meta,
                title: '杂费管理'
            },

            component: () => import('@/pages/rentmanage/energyrecord/index'),
        },
        {
            path: 'detail-edit/:id',
            name: '${pre}energyrecord-detail-edit',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '杂费管理编辑'
            },

            component: () => import('@/pages/rentmanage/energyrecord/detail-edit'),
        },
        {
            path: 'detail-edit',
            name: '${pre}energyrecorddetail-create',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '杂费管理新增'
            },

            component: () => import('@/pages/rentmanage/energyrecord/detail-edit'),
        },
        {
            path: 'detail-view/:id',
            name: '${pre}energyrecordView',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '杂费管理详情查看'
            },

            component: () => import('@/pages/rentmanage/energyrecord/detail-view'),
        },
    ]
};
