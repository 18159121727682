/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-09T14:42:50.427
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'rentmanage-RentHouseSalesPrice-'

export default {
	path: '/rentmanage/renthousesalesprice',
	name: 'rentmanage-RentHouseSalesPrice',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}RentHouseSalesPrice-index',

			meta: {
				...meta,
				title: '定价管理'
			},

			component: () => import('@/pages/rentmanage/renthousesalesprice/index'),
		},
		{
			path: 'artificialIndex',
			name: '${pre}RentHouseSalesPrice-artificialIndex',

			meta: {
				...meta,
				title: '人工定价管理'
			},

			component: () => import('@/pages/rentmanage/renthousesalesprice/artificialIndex'),
		},
		{
			path: 'detail-edit-batch',
			name: '${pre}RentHouseSalesPrice-detail-edit-batch',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '定价管理编辑'
			},

			component: () => import('@/pages/rentmanage/renthousesalesprice/detail-edit-batch'),
		},
		{
			path: 'detail-edit-out',
			name: '${pre}RentHouseSalesPrice-detail-edit-out',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '定价管理编辑'
			},

			component: () => import('@/pages/rentmanage/renthousesalesprice/detail-edit-out'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}RentHouseSalesPrice-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '定价管理编辑'
			},

			component: () => import('@/pages/rentmanage/renthousesalesprice/detail-edit'),
		},
		{
			path: 'detail-editManualPricing/:id',
			name: '${pre}RentHouseSalesPrice-detail-editManualPricing',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '人工定价管理编辑'
			},

			component: () => import('@/pages/rentmanage/renthousesalesprice/detail-editManualPricing'),
		},
		{
			path: 'detail-edit',
			name: '${pre}RentHouseSalesPricedetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '定价管理新增'
			},

			component: () => import('@/pages/rentmanage/renthousesalesprice/detail-edit'),
		},
		{
			path: 'detail-editManualPricing',
			name: '${pre}RentHouseSalesPricedetail-createManualPricing',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '人工定价管理新增'
			},

			component: () => import('@/pages/rentmanage/renthousesalesprice/detail-editManualPricing'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}RentHouseSalesPriceView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '定价管理详情查看'
			},

			component: () => import('@/pages/rentmanage/renthousesalesprice/detail-view'),
		},
	]
};
