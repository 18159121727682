/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-24T17:21:47.023
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHousePic,
	getRentHousePicCreateInit,
	getRentHousePicEditInit,
	getRentHousePicView,
	deleteRentHousePic
} from '@api/rentmanage/RentHousePic';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentHousePicCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHousePicCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHousePicEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHousePicEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHousePic({}, RentHousePic) {
			return new Promise((resolve, reject) => {
				saveRentHousePic(RentHousePic)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentHousePicView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHousePicView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentHousePic({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHousePic({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
