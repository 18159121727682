/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-04-22 09:41:27
 * @LastEditors: co
 * @LastEditTime: 2021-04-25 11:02:12
 * @FilePath: \giant_find\iview-admin-pro-template\src\store\modules\rentmanage\modules\rentbusinessarea\detail-view.js
 */
/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-16T10:11:30.182
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getRentBusinessAreaEditInit,
	getRentBusinessAreaView,
	getRentBusinessArea
} from '@api/rentmanage/RentBusinessArea';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		getRentBusinessAreaEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentBusinessAreaEditInit({
					id
				})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentBusinessAreaView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentBusinessAreaView({
					id
				})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentBusinessArea({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				getRentBusinessArea(obj)
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
