import request from '@/plugins/request';

export function startWorkflowApi (data) {
    return request({
        url: data.bizType+'/startWorkflow',
        method: 'post',
		data:data
    });
}
export function submitWorkflowApi (data,params) {
    return request({
        url:  data.bizType+'/submitWorkflow',
        method: 'post',
		data:data.compoundBillAndNextWorkitemAndUser,
		params:params
    });
}
export function listTodoWorkitemInstanceBySelf (data) {
    return request({
        url: '/worfklow/api/flowcenter/listTodoWorkitemInstanceBySelf',
        method: 'post',
		data:data
    });
}
export function listHaveDoneWorkitemInstanceBySelf (data) {
    return request({
        url: '/worfklow/api/flowcenter/listHaveDoneWorkitemInstanceBySelf',
        method: 'post',
		data:data
    });
}
export function todoListActiveWorkflow (data,params) {
    return request({
        url: data.bill.bizType+'/todoListActiveWorkflow',
        method: 'post',
		data:data,
		params:params
    });
}
export function listStartWorkitemInstanceBySelf (data) {
    return request({
        url: '/worfklow/api/flowcenter/listStartWorkitemInstanceBySelf',
        method: 'post',
		data:data
    });
}
export function processTracesList (params) {
    return request({
        url: '/worfklow/api/flowcenter/listProcessTraces',
        method: 'post',
		params:params
    });
}
export function tempStorageWorkflowApi (data,params) {
    return request({
        url: data.bizType+'/tempStorageWorkflow',
        method: 'post',
		data:data,
		params:params
    });
}
export function rejectWorkflowApi (data,params) {
    return request({
        url: data.bizType+'/rejectWorkflow',
        method: 'post',
		data:data.bill,
		params:params
    });
}
export function restartWorkflowApi (data,params) {
    return request({
        url: data.bizType+'/restartWorkflow',
        method: 'post',
		data:data.bill,
		params:params
    });
}




