/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-26T14:31:04.458
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialPaymentReminderInit(data){return request({
url: '/report/api/reportFinancialPaymentReminder/listReportFinancialPaymentReminderInit',
method: 'post',
data:data,
});
}


export function listReportFinancialPaymentReminderByAuthorizations(data){return request({
url: '/report/api/reportFinancialPaymentReminder/listReportFinancialPaymentReminderByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportFinancialPaymentReminder(data){return request({
url: '/report/api/reportFinancialPaymentReminder/listReportFinancialPaymentReminder',
method: 'post',
data:data,
});
}


export function getReportFinancialPaymentReminderCreateInit(){return request({
url: '/report/api/reportFinancialPaymentReminder/getReportFinancialPaymentReminderCreateInit',
method: 'post',
});
}


export function getReportFinancialPaymentReminderEditInit(params){return request({
url: '/report/api/reportFinancialPaymentReminder/getReportFinancialPaymentReminderEditInit',
method: 'post',
params:params,
});
}


export function getReportFinancialPaymentReminderView(params){return request({
url: '/report/api/reportFinancialPaymentReminder/getReportFinancialPaymentReminderView',
method: 'post',
params:params,
});
}


export function saveReportFinancialPaymentReminder(data){return request({
url: '/report/api/reportFinancialPaymentReminder/saveReportFinancialPaymentReminder',
method: 'post',
data:data,
});
}


export function deleteReportFinancialPaymentReminder(params){return request({
url: '/report/api/reportFinancialPaymentReminder/deleteReportFinancialPaymentReminder',
method: 'post',
params:params,
});
}


