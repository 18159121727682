import util from '@/libs/util';
import router from '@/router';

import {
	saveEvaluateBillHouse,
	getEvaluateBillHouseCreateInit,
	getEvaluateBillHouseEditInit,
	getEvaluateBillHouseView,
	deleteEvaluateBillHouse
} from '@api/storagemanage/EvaluateBillHouse';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getEvaluateBillHouseCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillHouseCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateBillHouseEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillHouseEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveEvaluateBillHouse({}, EvaluateBillHouse) {
			return new Promise((resolve, reject) => {
				saveEvaluateBillHouse(EvaluateBillHouse)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateBillHouseView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillHouseView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateBillHouse({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateBillHouse({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
