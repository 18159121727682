const pre = '/tool/';

export default {
    path: '/tool',
    title: '动态建模',
    header: 'home',
    custom: 'i-icon-demo i-icon-demo-tool',
	icon: 'md-speedometer',
    children: [
        {
            path: `#25`,
            title: '权限管理',
            children: [
				{
				    path: `/systemmanage/menutreemmanage/index`,
				    title: '菜单管理'
				},
                {
                    path: `/systemmanage/orgusermanage/index`,
                    title: '用户管理'
                },
                {
                    path: `/systemmanage/authfuncrolemanage/index`,
                    title: '角色管理'
                }
            ]
        },
        {
            path: `#26`,
            title: '配置中心',
            children: [
                {
                    path: `#29`,
                    title: '商家管理'
                },
                {
                    path: `#30`,
                    title: '签约主体'
                },
                {
                    path: `#31`,
                    title: '楼盘字典'
                },
                {
                    path: `#32`,
                    title: '案例配置'
                }
            ]
        }
    ]
}
