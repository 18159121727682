/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-20T20:38:25.759
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listDroomPropertyDeliveryInit(data) {
    return request({
        url: '/storagemanage/api/droomPropertyDelivery/listDroomPropertyDeliveryInit',
        method: 'post',
        data: data,
    });
}


export function listDroomPropertyDeliveryByAuthorizations(data) {
    return request({
        url: '/storagemanage/api/droomPropertyDelivery/listDroomPropertyDeliveryByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listDroomPropertyDelivery(data) {
    return request({
        url: '/storagemanage/api/droomPropertyDelivery/listDroomPropertyDelivery',
        method: 'post',
        data: data,
    });
}


export function getDroomPropertyDeliveryCreateInit() {
    return request({
        url: '/storagemanage/api/droomPropertyDelivery/getDroomPropertyDeliveryCreateInit',
        method: 'post',
    });
}


export function getDroomPropertyDeliveryEditInit(params) {
    return request({
        url: '/storagemanage/api/droomPropertyDelivery/getDroomPropertyDeliveryEditInit',
        method: 'post',
        params: params,
    });
}


export function getDroomPropertyDeliveryView(params) {
    return request({
        url: '/storagemanage/api/droomPropertyDelivery/getDroomPropertyDeliveryView',
        method: 'post',
        params: params,
    });
}


export function saveDroomPropertyDelivery(data) {
    return request({
        url: '/storagemanage/api/droomPropertyDelivery/saveDroomPropertyDelivery',
        method: 'post',
        data: data,
    });
}


export function deleteDroomPropertyDelivery(params) {
    return request({
        url: '/storagemanage/api/droomPropertyDelivery/deleteDroomPropertyDelivery',
        method: 'post',
        params: params,
    });
}

export function confirmPropertyDelivery(data) {
	return request({
		url: '/storagemanage/api/droomPropertyDelivery/confirmPropertyDelivery',
		method: 'post',
		params: data
	});
}

export function confirmConfigure(data) {
	return request({
		url: '/storagemanage/api/droomPropertyDelivery/confirmConfigure',
		method: 'post',
		params: data
	});
}

