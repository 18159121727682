import util from '@/libs/util';
import router from '@/router';

import {
	saveStockpileIntentionHouse,
	getStockpileIntentionHouseCreateInit,
	getStockpileIntentionHouseEditInit,
	getStockpileIntentionHouseView,
	deleteStockpileIntentionHouse
} from '@api/storagemanage/StockpileIntentionHouse';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getStockpileIntentionHouseCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getStockpileIntentionHouseCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getStockpileIntentionHouseEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getStockpileIntentionHouseEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveStockpileIntentionHouse({}, StockpileIntentionHouse) {
			return new Promise((resolve, reject) => {
				saveStockpileIntentionHouse(StockpileIntentionHouse)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getStockpileIntentionHouseView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getStockpileIntentionHouseView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteStockpileIntentionHouse({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteStockpileIntentionHouse({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
