/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-04-22 09:41:27
 * @LastEditors: co
 * @LastEditTime: 2021-04-25 10:55:26
 * @FilePath: \giant_find\iview-admin-pro-template\src\store\modules\rentmanage\modules\rentbusinessarea\index.js
 */
/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-16T10:11:30.179
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentBusinessArea,
	listRentBusinessAreaInit,
	listRentBusinessAreaByAuthorizations,
	deleteRentBusinessArea

} from '@api/rentmanage/RentBusinessArea';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentBusinessArea({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentBusinessArea(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentBusinessAreaByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentBusinessAreaByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities = res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentBusinessAreaInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentBusinessAreaInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentBusinessArea({
			state
		}, { id }) {
			return new Promise((resolve, reject) => {
				deleteRentBusinessArea({
					id
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
