/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-12T10:12:58.002
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportFinancialTransfer,
	getReportFinancialTransferCreateInit,
	getReportFinancialTransferEditInit,
	getReportFinancialTransferView,
	deleteReportFinancialTransfer
} from '@api/report/ReportFinancialTransfer';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportFinancialTransferCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialTransferCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportFinancialTransferEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialTransferEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportFinancialTransfer({}, ReportFinancialTransfer) {
			return new Promise((resolve, reject) => {
				saveReportFinancialTransfer(ReportFinancialTransfer)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportFinancialTransferView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialTransferView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportFinancialTransfer({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportFinancialTransfer({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
