/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-10T12:05:16.691
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'storagemanage-FinancePayApply-'

export default {
	path: '/storagemanage/financepayapply',
	name: 'storagemanage-FinancePayApply',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}FinancePayApply-index',

			meta: {
				...meta,
				title: '付款管理'
			},

			component: () => import('@/pages/storagemanage/financepayapply/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}FinancePayApply-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '付款管理编辑',
                cache: true,
			},

			component: () => import('@/pages/storagemanage/financepayapply/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}FinancePayApplydetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '付款管理编辑'
			},

			component: () => import('@/pages/storagemanage/financepayapply/detail-edit'),
		},
		{
			path: 'detail-pay',
			name: '${pre}FinancePayApplydetail-pay',
			auth: [
				'hidden'
			],
		
			meta: {
				...meta,
				title: '提交线下支付申请'
			},
		
			component: () => import('@/pages/storagemanage/financepayapply/detail-pay'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}FinancePayApplyView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '付款管理详情查看'
			},

			component: () => import('@/pages/storagemanage/financepayapply/detail-view'),
		},
	]
};
