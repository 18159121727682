/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T16:23:43.42
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listEnergyRecordItemInit(data){return request({
url: '/rentmanage/api/energyRecordItem/listEnergyRecordItemInit',
method: 'post',
data:data,
});
}


export function listEnergyRecordItemByAuthorizations(data){return request({
url: '/rentmanage/api/energyRecordItem/listEnergyRecordItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listEnergyRecordItem(data){return request({
url: '/rentmanage/api/energyRecordItem/listEnergyRecordItem',
method: 'post',
data:data,
});
}


export function getEnergyRecordItemCreateInit(){return request({
url: '/rentmanage/api/energyRecordItem/getEnergyRecordItemCreateInit',
method: 'post',
});
}


export function getEnergyRecordItemEditInit(params){return request({
url: '/rentmanage/api/energyRecordItem/getEnergyRecordItemEditInit',
method: 'post',
params:params,
});
}


export function getEnergyRecordItemView(params){return request({
url: '/rentmanage/api/energyRecordItem/getEnergyRecordItemView',
method: 'post',
params:params,
});
}


export function saveEnergyRecordItem(data){return request({
url: '/rentmanage/api/energyRecordItem/saveEnergyRecordItem',
method: 'post',
data:data,
});
}


export function deleteEnergyRecordItem(params){return request({
url: '/rentmanage/api/energyRecordItem/deleteEnergyRecordItem',
method: 'post',
params:params,
});
}


