import request from '@/plugins/request';

export function pageInit(data) {
	return request({
		url: '/storagemanage/api/companyManage/listCompanyManageList',
		method: 'post',
		data: data
	});
}

export function GetDataList(data) {
	return request({
		url: '/storagemanage/api/companyManage/listCompanyManage',
		method: 'post',
		data: data
	});
}

export function GetProgressList(data) {
	return request({
		url: '/storagemanage/api/speedProgress/listSpeedProgressByUserId',
		method: 'post',
		params: data
	});
}

export function getCode(data) {
	return request({
		url: '/h5platform/api/register/sendSMSVerificationCode',
		method: 'post',
		params: data
	});
}

export function addPageInit(data) {
	return request({
		url: '/storagemanage/api/productShow/getProductShowCreateInit',
		method: 'post',
		data: data
	});
}

export function editPageInitReq(data) {
	return request({
		url: '/storagemanage/api/productShow/getProductShowEditInit',
		method: 'post',
		params: data
	});
}

export function AddOrEditData(data) {
	return request({
		url: '/storagemanage/api/stockpileIntention/saveStockpileIntentionByWechat',
		method: 'post',
		data: data,
		params: {
			userMobile: data.ownerMobile,
			verificationCode: data.verificationCode,
			openId:data.openId?data.openId:'',
		}
	});
}

export function getArea(data) {
	return request({
		url: '/storagemanage/api/area/getAreaSimpleList',
		method: 'post',
		data: data
	});
}

export function getAreaById(data) {
	return request({
		url: '/storagemanage/api/area/listArea',
		method: 'post',
		data: data
	});
}

export function deteleRecordReq(data) {
	return request({
		url: '/storagemanage/api/productShow/deleteProductShow',
		method: 'post',
		params: data
	});
}
