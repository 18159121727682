/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-15T11:07:06.873
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHouseSalesCard,
	getRentHouseSalesCardCreateInit,
	getRentHouseSalesCardEditInit,
	getRentHouseSalesCardView,
	deleteRentHouseSalesCard
} from '@api/rentmanage/RentHouseSalesCard';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentHouseSalesCardConsumeInit,
listRentHouseSalesCardConsumeByAuthorizations,
deleteRentHouseSalesCardConsume
} from '@api/rentmanage/RentHouseSalesCardConsume';

import {
listCardOperationLogInit,
listCardOperationLogByAuthorizations,
deleteCardOperationLog
} from '@api/rentmanage/CardOperationLog';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentHouseSalesCardCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseSalesCardCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseSalesCardEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseSalesCardEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseSalesCard({}, RentHouseSalesCard) {
			return new Promise((resolve, reject) => {
				saveRentHouseSalesCard(RentHouseSalesCard)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseSalesCardView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseSalesCardView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseSalesCard({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseSalesCard({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
