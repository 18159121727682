/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-02T19:10:02.082
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentRepairWorkOrderAssign,
	listRentRepairWorkOrderAssignInit,
	listRentRepairWorkOrderAssignByAuthorizations,
	deleteRentRepairWorkOrderAssign,
	withdrawRentRepairWorkOrderAssign
	
} from '@api/rentmanage/RentRepairWorkOrderAssign';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentRepairWorkOrderAssign({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderAssign(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentRepairWorkOrderAssignByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderAssignByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentRepairWorkOrderAssignInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentRepairWorkOrderAssignInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		withdrawRentRepairWorkOrderAssign({
			state
		}, RentRepairWorkOrderAssign) {
			return new Promise((resolve, reject) => {
				withdrawRentRepairWorkOrderAssign(RentRepairWorkOrderAssign)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentRepairWorkOrderAssign({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentRepairWorkOrderAssign({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
