/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-11T09:36:43.264
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportfinancialstoragecontract-'

export default 
{
path: '/report/reportfinancialstoragecontract',
name: 'report-reportfinancialstoragecontract',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportfinancialstoragecontract-index',

meta:{
...meta,
title: '存房合同'
},

component: () => import('@/pages/report/reportfinancialstoragecontract/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportfinancialstoragecontract-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '存房合同编辑'
},

component: () => import('@/pages/report/reportfinancialstoragecontract/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportfinancialstoragecontractdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '存房合同新增'
},

component: () => import('@/pages/report/reportfinancialstoragecontract/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportfinancialstoragecontractView',
auth: [
'hidden'
],

meta:{
...meta,
title: '存房合同详情查看'
},

component: () => import('@/pages/report/reportfinancialstoragecontract/detail-view') ,
},
]
};
