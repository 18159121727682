/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-04-07 14:30:15
 * @LastEditors: co
 * @LastEditTime: 2021-05-28 10:29:06
 * @FilePath: \giant_find\iview-admin-pro-template\src\api\mobile\tenantChangePropertyDeliverysignature.js
 */
import request from '@/plugins/request';

export function getPdf(data) {
	return request({
		url: '/rentmanage/api/rentPropertyDelivery/esignDetail',
		method: 'get',
		params: data
	});
}

export function sendSignMobileCodeReq(data) {
	return request({
		url: '/storagemanage/api/esign/sendSignMobileCode3rd',
		method: 'get',
		params: data
	});
}

export function sign(data) {
	return request({
		url: '/storagemanage/api/esign/tenantChangePropertyDeliverySignature',
		method: 'post',
		data: data
	});
}

export function getFaceEsignInfo(data) {
	return request({
		url: '/storagemanage/api/esign/getFaceEsignInfo',
		method: 'get',
		params: data
	});
}
export function verificationResultReq(data) {
	return request({
		url: '/storagemanage/api/esign/detail',
		method: 'get',
		params: data
	});
}