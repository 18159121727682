/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-14T19:43:30.544
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentTrafficLineInit(data){return request({
url: '/rentmanage/api/rentTrafficLine/listRentTrafficLineInit',
method: 'post',
data:data,
});
}


export function listRentTrafficLineByAuthorizations(data){return request({
url: '/rentmanage/api/rentTrafficLine/listRentTrafficLineByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentTrafficLine(data){return request({
url: '/rentmanage/api/rentTrafficLine/listRentTrafficLine',
method: 'post',
data:data,
});
}


export function getRentTrafficLineCreateInit(){return request({
url: '/rentmanage/api/rentTrafficLine/getRentTrafficLineCreateInit',
method: 'post',
});
}


export function getRentTrafficLineEditInit(params){return request({
url: '/rentmanage/api/rentTrafficLine/getRentTrafficLineEditInit',
method: 'post',
params:params,
});
}


export function getRentTrafficLineView(params){return request({
url: '/rentmanage/api/rentTrafficLine/getRentTrafficLineView',
method: 'post',
params:params,
});
}


export function saveRentTrafficLine(data){return request({
url: '/rentmanage/api/rentTrafficLine/saveRentTrafficLine',
method: 'post',
data:data,
});
}


export function deleteRentTrafficLine(params){return request({
url: '/rentmanage/api/rentTrafficLine/deleteRentTrafficLine',
method: 'post',
params:params,
});
}


