import util from '@/libs/util';
import router from '@/router';

import {
	saveEvaluateBillOwner,
	getEvaluateBillOwnerCreateInit,
	getEvaluateBillOwnerEditInit,
	getEvaluateBillOwnerView,
	deleteEvaluateBillOwner
} from '@api/storagemanage/EvaluateBillOwner';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getEvaluateBillOwnerCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillOwnerCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateBillOwnerEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillOwnerEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveEvaluateBillOwner({}, EvaluateBillOwner) {
			return new Promise((resolve, reject) => {
				saveEvaluateBillOwner(EvaluateBillOwner)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateBillOwnerView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillOwnerView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateBillOwner({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateBillOwner({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
