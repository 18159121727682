/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-08T19:32:01.814
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentLeaseContractChangeRequest,
	getRentLeaseContractChangeRequestCreateInit,
	getRentLeaseContractChangeRequestEditInit,
	getRentLeaseContractChangeRequestView,
	deleteRentLeaseContractChangeRequest
} from '@api/rentmanage/RentLeaseContractChangeRequest';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentLeaseContractChangeRequestCreateInit({
			state
		},{
			bizType
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractChangeRequestCreateInit({
					bizType
				})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentLeaseContractChangeRequestEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractChangeRequestEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentLeaseContractChangeRequest({}, RentLeaseContractChangeRequest) {
			return new Promise((resolve, reject) => {
				saveRentLeaseContractChangeRequest(RentLeaseContractChangeRequest)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentLeaseContractChangeRequestView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractChangeRequestView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entity);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentLeaseContractChangeRequest({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentLeaseContractChangeRequest({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
