/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-07-29T14:37:11.955
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialOutsideStorageContractInit(data){return request({
url: '/report/api/reportFinancialOutsideStorageContract/listReportFinancialOutsideStorageContractInit',
method: 'post',
data:data,
});
}


export function listReportFinancialOutsideStorageContractByAuthorizations(data){return request({
url: '/report/api/reportFinancialOutsideStorageContract/listReportFinancialOutsideStorageContractByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportFinancialOutsideStorageContract(data){return request({
url: '/report/api/reportFinancialOutsideStorageContract/listReportFinancialOutsideStorageContract',
method: 'post',
data:data,
});
}


export function getReportFinancialOutsideStorageContractCreateInit(){return request({
url: '/report/api/reportFinancialOutsideStorageContract/getReportFinancialOutsideStorageContractCreateInit',
method: 'post',
});
}


export function getReportFinancialOutsideStorageContractEditInit(params){return request({
url: '/report/api/reportFinancialOutsideStorageContract/getReportFinancialOutsideStorageContractEditInit',
method: 'post',
params:params,
});
}


export function getReportFinancialOutsideStorageContractView(params){return request({
url: '/report/api/reportFinancialOutsideStorageContract/getReportFinancialOutsideStorageContractView',
method: 'post',
params:params,
});
}


export function saveReportFinancialOutsideStorageContract(data){return request({
url: '/report/api/reportFinancialOutsideStorageContract/saveReportFinancialOutsideStorageContract',
method: 'post',
data:data,
});
}


export function deleteReportFinancialOutsideStorageContract(params){return request({
url: '/report/api/reportFinancialOutsideStorageContract/deleteReportFinancialOutsideStorageContract',
method: 'post',
params:params,
});
}


