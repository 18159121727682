/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T15:03:08.633
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-AssignedBill-'

export default 
{
path: '/storagemanage/assignedbill',
name: 'storagemanage-AssignedBill',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}AssignedBill-index',

meta:{
...meta,
title: '指派单(受理)'
},

component: () => import('@/pages/storagemanage/assignedbill/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}AssignedBill-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '指派单编辑'
},

component: () => import('@/pages/storagemanage/assignedbill/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}AssignedBilldetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '指派单新增'
},

component: () => import('@/pages/storagemanage/assignedbill/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}AssignedBillView',
auth: [
'hidden'
],

meta:{
...meta,
title: '指派单详情查看'
},

component: () => import('@/pages/storagemanage/assignedbill/detail-view') ,
},
]
};
