/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2021-06-25T18:30:18.944
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'rentmanage-rentvieworderretreat-'

export default {
	path: '/rentmanage/rentvieworderretreat',
	name: 'rentmanage-rentvieworderretreat',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}rentvieworderretreat-index',

			meta: {
				...meta,
				title: '挞定日报'
			},

			component: () => import('@/pages/rentmanage/rentvieworderretreat/index'),
		},{
			path: 'indexMonth',
			name: '${pre}rentvieworderretreat-indexMonth',

			meta: {
				...meta,
				title: '挞定月报'
			},

			component: () => import('@/pages/rentmanage/rentvieworderretreat/indexMonth'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}rentvieworderretreat-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '挞定日报编辑'
			},

			component: () => import('@/pages/rentmanage/rentvieworderretreat/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}rentvieworderretreatdetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '挞定日报新增'
			},

			component: () => import('@/pages/rentmanage/rentvieworderretreat/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}rentvieworderretreatView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '挞定日报详情查看'
			},

			component: () => import('@/pages/rentmanage/rentvieworderretreat/detail-view'),
		},
	]
};
