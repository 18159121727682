/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-23T16:11:31.63
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-channeltag-'

export default 
{
path: '/rentmanage/channeltag',
name: 'rentmanage-channeltag',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}channeltag-index',

meta:{
...meta,
title: '标签'
},

component: () => import('@/pages/rentmanage/channeltag/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}channeltag-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '标签编辑'
},

component: () => import('@/pages/rentmanage/channeltag/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}channeltagdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '标签新增'
},

component: () => import('@/pages/rentmanage/channeltag/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}channeltagView',
auth: [
'hidden'
],

meta:{
...meta,
title: '标签详情查看'
},

component: () => import('@/pages/rentmanage/channeltag/detail-view') ,
},
]
};
