import util from '@/libs/util';
import router from '@/router';
import {
	listEvaluateBillOwner,
	listEvaluateBillOwnerInit,
	listEvaluateBillOwnerByAuthorizations,
	deleteEvaluateBillOwner
} from '@api/storagemanage/EvaluateBillOwner';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listEvaluateBillOwner({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillOwner(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateBillOwnerByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillOwnerByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateBillOwnerInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateBillOwnerInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateBillOwner({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateBillOwner({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
