/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T09:30:03.303
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'financial-FinancialPayables-'

export default 
{
path: '/financial/financialpayables',
name: 'financial-FinancialPayables',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}FinancialPayables-index',

meta:{
...meta,
title: '应付管理'
},

component: () => import('@/pages/financial/financialpayables/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}FinancialPayables-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '应付管理编辑'
},

component: () => import('@/pages/financial/financialpayables/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}FinancialPayablesdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '应付管理新增'
},

component: () => import('@/pages/financial/financialpayables/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}FinancialPayablesView',
auth: [
'hidden'
],

meta:{
...meta,
title: '应付管理详情查看'
},

component: () => import('@/pages/financial/financialpayables/detail-view') ,
},
]
};
