/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T17:39:58.828
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveContractSubjectAcount,
	getContractSubjectAcountCreateInit,
	getContractSubjectAcountEditInit,
	getContractSubjectAcountView,
	deleteContractSubjectAcount
} from '@api/storagemanage/ContractSubjectAcount';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getContractSubjectAcountCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getContractSubjectAcountCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getContractSubjectAcountEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getContractSubjectAcountEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveContractSubjectAcount({}, ContractSubjectAcount) {
			return new Promise((resolve, reject) => {
				saveContractSubjectAcount(ContractSubjectAcount)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getContractSubjectAcountView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getContractSubjectAcountView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteContractSubjectAcount({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteContractSubjectAcount({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
