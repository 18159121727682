/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-01T17:31:37.294
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveFinancialAdjustmentApplyCollection,
	getFinancialAdjustmentApplyCollectionCreateInit,
	getFinancialAdjustmentApplyCollectionEditInit,
	getFinancialAdjustmentApplyCollectionView,
	deleteFinancialAdjustmentApplyCollection
} from '@api/financial/FinancialAdjustmentApplyCollection';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialAdjustmentApplyCollectionCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyCollectionCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialAdjustmentApplyCollectionEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyCollectionEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialAdjustmentApplyCollection({}, FinancialAdjustmentApplyCollection) {
			return new Promise((resolve, reject) => {
				saveFinancialAdjustmentApplyCollection(FinancialAdjustmentApplyCollection)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialAdjustmentApplyCollectionView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyCollectionView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialAdjustmentApplyCollection({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialAdjustmentApplyCollection({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
