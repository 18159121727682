/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:57.795
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveHouseRateInfo,
	getHouseRateInfoCreateInit,
	getHouseRateInfoEditInit,
	getHouseRateInfoView,
	deleteHouseRateInfo
} from '@api/lcf/HouseRateInfo';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getHouseRateInfoCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getHouseRateInfoCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseRateInfoEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseRateInfoEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveHouseRateInfo({}, HouseRateInfo) {
			return new Promise((resolve, reject) => {
				saveHouseRateInfo(HouseRateInfo)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseRateInfoView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseRateInfoView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteHouseRateInfo({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteHouseRateInfo({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
