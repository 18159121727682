/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-30T21:02:57.445
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'financial-FinancialClaim-'

export default {
	path: '/financial/financialclaim',
	name: 'financial-FinancialClaim',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
		path: 'index',
		name: '${pre}FinancialClaim-index',

		meta: {
			...meta,
			title: '认领单管理'
		},

		component: () => import('@/pages/financial/financialclaim/index'),
	},
	{
		path: 'detail-edit/:id',
		name: '${pre}FinancialClaim-detail-edit',
		auth: [
			'hidden'
		],

		meta: {
			...meta,
			title: '认领单管理编辑'
		},

		component: () => import('@/pages/financial/financialclaim/detail-edit'),
	},
	{
		path: 'detail-edit-mult',
		name: '${pre}FinancialClaim-detail-edit-mult',
		auth: [
			'hidden'
		],

		meta: {
			...meta,
			title: '认领单管理编辑多选'
		},

		component: () => import('@/pages/financial/financialclaim/detail-edit-mult'),
	},
	{
		path: 'detail-edit-settlement/:id',
		name: '${pre}FinancialClaim-detail-edit-settlement',
		auth: [
			'hidden'
		],

		meta: {
			...meta,
			title: '认领单管理编辑'
		},

		component: () => import('@/pages/financial/financialclaim/detail-edit-settlement'),
	},
	{
		path: 'detail-edit',
		name: '${pre}FinancialClaimdetail-create',
		auth: [
			'hidden'
		],

		meta: {
			...meta,
			title: '认领单管理新增'
		},

		component: () => import('@/pages/financial/financialclaim/detail-edit'),
	},
	{
		path: 'detail-view/:id',
		name: '${pre}FinancialClaimView',
		auth: [
			'hidden'
		],

		meta: {
			...meta,
			title: '认领单管理详情查看'
		},

		component: () => import('@/pages/financial/financialclaim/detail-view'),
	},
	{
		path: 'detail-view-settlement/:id',
		name: '${pre}FinancialClaimViewSettlement',
		auth: [
			'hidden'
		],

		meta: {
			...meta,
			title: '认领单管理详情查看'
		},

		component: () => import('@/pages/financial/financialclaim/detail-view-settlement'),
	},
	]
};
