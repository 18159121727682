/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-11T17:16:49.207
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: true
};

const pre = 'rentmanage-rentlegalentity-'

export default {
    path: '/rentmanage/rentlegalentity',
    name: 'rentmanage-rentlegalentity',
    redirect: {
        name: '${pre}-index'
    },

    meta: {
        ...meta,
    },

    component: BasicLayout,
    children: [{
            path: 'index',
            name: '${pre}rentlegalentity-index',

            meta: {
                ...meta,
                title: '签约公司'
            },

            component: () => import('@/pages/rentmanage/rentlegalentity/index'),
        },
        {
            path: 'detail-edit/:id',
            name: '${pre}rentlegalentity-detail-edit',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '签约公司编辑'
            },

            component: () => import('@/pages/rentmanage/rentlegalentity/detail-edit'),
        },
        {
            path: 'detail-edit',
            name: '${pre}rentlegalentitydetail-create',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '签约公司新增'
            },

            component: () => import('@/pages/rentmanage/rentlegalentity/detail-edit'),
        },
        {
            path: 'detail-view/:id',
            name: '${pre}rentlegalentityView',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '签约公司详情查看'
            },

            component: () => import('@/pages/rentmanage/rentlegalentity/detail-view'),
        },
    ]
};
