/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-14T19:43:31.604
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentTrafficLinePlatformInit(data){return request({
url: '/rentmanage/api/rentTrafficLinePlatform/listRentTrafficLinePlatformInit',
method: 'post',
data:data,
});
}


export function listRentTrafficLinePlatformByAuthorizations(data){return request({
url: '/rentmanage/api/rentTrafficLinePlatform/listRentTrafficLinePlatformByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentTrafficLinePlatform(data){return request({
url: '/rentmanage/api/rentTrafficLinePlatform/listRentTrafficLinePlatform',
method: 'post',
data:data,
});
}


export function getRentTrafficLinePlatformCreateInit(){return request({
url: '/rentmanage/api/rentTrafficLinePlatform/getRentTrafficLinePlatformCreateInit',
method: 'post',
});
}


export function getRentTrafficLinePlatformEditInit(params){return request({
url: '/rentmanage/api/rentTrafficLinePlatform/getRentTrafficLinePlatformEditInit',
method: 'post',
params:params,
});
}


export function getRentTrafficLinePlatformView(params){return request({
url: '/rentmanage/api/rentTrafficLinePlatform/getRentTrafficLinePlatformView',
method: 'post',
params:params,
});
}


export function saveRentTrafficLinePlatform(data){return request({
url: '/rentmanage/api/rentTrafficLinePlatform/saveRentTrafficLinePlatform',
method: 'post',
data:data,
});
}


export function deleteRentTrafficLinePlatform(params){return request({
url: '/rentmanage/api/rentTrafficLinePlatform/deleteRentTrafficLinePlatform',
method: 'post',
params:params,
});
}


