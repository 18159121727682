/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-06-25T18:30:18.917
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentViewOrderRetreatInit(data){return request({
url: '/rentmanage/api/rentViewOrderRetreat/listRentViewOrderRetreatInit',
method: 'post',
data:data,
});
}


export function listRentViewOrderRetreatByAuthorizations(data){return request({
url: '/rentmanage/api/rentViewOrderRetreat/listRentViewOrderRetreatByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentViewOrderRetreat(data){return request({
url: '/rentmanage/api/rentViewOrderRetreat/listRentViewOrderRetreat',
method: 'post',
data:data,
});
}


export function getRentViewOrderRetreatCreateInit(){return request({
url: '/rentmanage/api/rentViewOrderRetreat/getRentViewOrderRetreatCreateInit',
method: 'post',
});
}


export function getRentViewOrderRetreatEditInit(params){return request({
url: '/rentmanage/api/rentViewOrderRetreat/getRentViewOrderRetreatEditInit',
method: 'post',
params:params,
});
}


export function getRentViewOrderRetreatView(params){return request({
url: '/rentmanage/api/rentViewOrderRetreat/getRentViewOrderRetreatView',
method: 'post',
params:params,
});
}


export function saveRentViewOrderRetreat(data){return request({
url: '/rentmanage/api/rentViewOrderRetreat/saveRentViewOrderRetreat',
method: 'post',
data:data,
});
}


export function deleteRentViewOrderRetreat(params){return request({
url: '/rentmanage/api/rentViewOrderRetreat/deleteRentViewOrderRetreat',
method: 'post',
params:params,
});
}


