/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-24T17:34:22.437
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listConAttachmentTestInit(data){return request({
url: '/storagemanage/api/conAttachmentTest/listConAttachmentTestInit',
method: 'post',
data:data,
});
}


export function listConAttachmentTestByAuthorizations(data){return request({
url: '/storagemanage/api/conAttachmentTest/listConAttachmentTestByAuthorizations',
method: 'post',
data:data,
});
}


export function listConAttachmentTest(data){return request({
url: '/storagemanage/api/conAttachmentTest/listConAttachmentTest',
method: 'post',
data:data,
});
}


export function getConAttachmentTestCreateInit(){return request({
url: '/storagemanage/api/conAttachmentTest/getConAttachmentTestCreateInit',
method: 'post',
});
}


export function getConAttachmentTestEditInit(params){return request({
url: '/storagemanage/api/conAttachmentTest/getConAttachmentTestEditInit',
method: 'post',
params:params,
});
}


export function getConAttachmentTestView(params){return request({
url: '/storagemanage/api/conAttachmentTest/getConAttachmentTestView',
method: 'post',
params:params,
});
}


export function saveConAttachmentTest(data){return request({
url: '/storagemanage/api/conAttachmentTest/saveConAttachmentTest',
method: 'post',
data:data,
});
}


export function deleteConAttachmentTest(params){return request({
url: '/storagemanage/api/conAttachmentTest/deleteConAttachmentTest',
method: 'post',
params:params,
});
}


