/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-23T14:56:33.829
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentGoodsInit(data){return request({
url: '/rentmanage/api/rentGoods/listRentGoodsInit',
method: 'post',
data:data,
});
}


export function listRentGoodsByAuthorizations(data){return request({
url: '/rentmanage/api/rentGoods/listRentGoodsByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentGoods(data){return request({
url: '/rentmanage/api/rentGoods/listRentGoods',
method: 'post',
data:data,
});
}


export function getRentGoodsCreateInit(){return request({
url: '/rentmanage/api/rentGoods/getRentGoodsCreateInit',
method: 'post',
});
}


export function getRentGoodsEditInit(params){return request({
url: '/rentmanage/api/rentGoods/getRentGoodsEditInit',
method: 'post',
params:params,
});
}


export function getRentGoodsView(params){return request({
url: '/rentmanage/api/rentGoods/getRentGoodsView',
method: 'post',
params:params,
});
}


export function saveRentGoods(data){return request({
url: '/rentmanage/api/rentGoods/saveRentGoods',
method: 'post',
data:data,
});
}


export function deleteRentGoods(params){return request({
url: '/rentmanage/api/rentGoods/deleteRentGoods',
method: 'post',
params:params,
});
}


