/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-01T17:09:50.648
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveFinancialAdjustmentApplyItem,
	getFinancialAdjustmentApplyItemCreateInit,
	getFinancialAdjustmentApplyItemEditInit,
	getFinancialAdjustmentApplyItemView,
	deleteFinancialAdjustmentApplyItem
} from '@api/financial/FinancialAdjustmentApplyItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialAdjustmentApplyItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialAdjustmentApplyItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialAdjustmentApplyItem({}, FinancialAdjustmentApplyItem) {
			return new Promise((resolve, reject) => {
				saveFinancialAdjustmentApplyItem(FinancialAdjustmentApplyItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialAdjustmentApplyItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialAdjustmentApplyItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialAdjustmentApplyItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
