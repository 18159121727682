/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-10-13T17:19:55.462
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentSerComplaintInit(data){return request({
url: '/rentmanage/api/rentSerComplaint/listRentSerComplaintInit',
method: 'post',
data:data,
});
}


export function listRentSerComplaintByAuthorizations(data){return request({
url: '/rentmanage/api/rentSerComplaint/listRentSerComplaintByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentSerComplaint(data){return request({
url: '/rentmanage/api/rentSerComplaint/listRentSerComplaint',
method: 'post',
data:data,
});
}


export function getRentSerComplaintCreateInit(){return request({
url: '/rentmanage/api/rentSerComplaint/getRentSerComplaintCreateInit',
method: 'post',
});
}


export function getRentSerComplaintEditInit(params){return request({
url: '/rentmanage/api/rentSerComplaint/getRentSerComplaintEditInit',
method: 'post',
params:params,
});
}


export function getRentSerComplaintView(params){return request({
url: '/rentmanage/api/rentSerComplaint/getRentSerComplaintView',
method: 'post',
params:params,
});
}


export function saveRentSerComplaint(data){return request({
url: '/rentmanage/api/rentSerComplaint/saveRentSerComplaint',
method: 'post',
data:data,
});
}


export function deleteRentSerComplaint(params){return request({
url: '/rentmanage/api/rentSerComplaint/deleteRentSerComplaint',
method: 'post',
params:params,
});
}


