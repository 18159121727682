/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-01T19:13:36.46
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveConDeputeContractBackSettlementItem,
	getConDeputeContractBackSettlementItemCreateInit,
	getConDeputeContractBackSettlementItemEditInit,
	getConDeputeContractBackSettlementItemView,
	deleteConDeputeContractBackSettlementItem
} from '@api/storagemanage/ConDeputeContractBackSettlementItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getConDeputeContractBackSettlementItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getConDeputeContractBackSettlementItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getConDeputeContractBackSettlementItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getConDeputeContractBackSettlementItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveConDeputeContractBackSettlementItem({}, ConDeputeContractBackSettlementItem) {
			return new Promise((resolve, reject) => {
				saveConDeputeContractBackSettlementItem(ConDeputeContractBackSettlementItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getConDeputeContractBackSettlementItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getConDeputeContractBackSettlementItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteConDeputeContractBackSettlementItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteConDeputeContractBackSettlementItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
