/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-01T17:31:37.285
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialAdjustmentApplyCollectionInit(data){return request({
url: '/financial/api/financialAdjustmentApplyCollection/listFinancialAdjustmentApplyCollectionInit',
method: 'post',
data:data,
});
}


export function listFinancialAdjustmentApplyCollectionByAuthorizations(data){return request({
url: '/financial/api/financialAdjustmentApplyCollection/listFinancialAdjustmentApplyCollectionByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialAdjustmentApplyCollection(data){return request({
url: '/financial/api/financialAdjustmentApplyCollection/listFinancialAdjustmentApplyCollection',
method: 'post',
data:data,
});
}


export function getFinancialAdjustmentApplyCollectionCreateInit(){return request({
url: '/financial/api/financialAdjustmentApplyCollection/getFinancialAdjustmentApplyCollectionCreateInit',
method: 'post',
});
}


export function getFinancialAdjustmentApplyCollectionEditInit(params){return request({
url: '/financial/api/financialAdjustmentApplyCollection/getFinancialAdjustmentApplyCollectionEditInit',
method: 'post',
params:params,
});
}


export function getFinancialAdjustmentApplyCollectionView(params){return request({
url: '/financial/api/financialAdjustmentApplyCollection/getFinancialAdjustmentApplyCollectionView',
method: 'post',
params:params,
});
}


export function saveFinancialAdjustmentApplyCollection(data){return request({
url: '/financial/api/financialAdjustmentApplyCollection/saveFinancialAdjustmentApplyCollection',
method: 'post',
data:data,
});
}


export function deleteFinancialAdjustmentApplyCollection(params){return request({
url: '/financial/api/financialAdjustmentApplyCollection/deleteFinancialAdjustmentApplyCollection',
method: 'post',
params:params,
});
}


