/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T19:13:41.342
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentRepairFeedbackType,
	getRentRepairFeedbackTypeCreateInit,
	getRentRepairFeedbackTypeEditInit,
	getRentRepairFeedbackTypeView,
	deleteRentRepairFeedbackType
} from '@api/rentmanage/RentRepairFeedbackType';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentRepairFeedbackTypeCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairFeedbackTypeCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentRepairFeedbackTypeEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairFeedbackTypeEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentRepairFeedbackType({}, RentRepairFeedbackType) {
			return new Promise((resolve, reject) => {
				saveRentRepairFeedbackType(RentRepairFeedbackType)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentRepairFeedbackTypeView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairFeedbackTypeView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentRepairFeedbackType({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentRepairFeedbackType({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
