/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-18T18:02:48.28
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveAuthFuncRoleManage,
	getAuthFuncRoleManageCreateInit,
	getAuthFuncRoleManageEditInit,
	getAuthFuncRoleManageView,
	deleteAuthFuncRoleManage
} from '@api/systemmanage/AuthFuncRoleManage';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getAuthFuncRoleManageCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getAuthFuncRoleManageCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getAuthFuncRoleManageEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getAuthFuncRoleManageEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveAuthFuncRoleManage({}, AuthFuncRoleManage) {
			return new Promise((resolve, reject) => {
				saveAuthFuncRoleManage(AuthFuncRoleManage)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getAuthFuncRoleManageView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getAuthFuncRoleManageView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteAuthFuncRoleManage({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteAuthFuncRoleManage({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
