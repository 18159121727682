/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-03T15:07:15.563
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentMaterial,
	listRentMaterialInit,
	listRentMaterialByAuthorizations,
	deleteRentMaterial
	
} from '@api/rentmanage/RentMaterial';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentMaterial({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentMaterial(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentMaterialByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentMaterialByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentMaterialInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentMaterialInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentMaterial({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentMaterial({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
