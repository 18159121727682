/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T17:39:58.811
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-ContractSubject-components-ContractSubjectAcount-'

export default 
{
path: '/storagemanage/arcontractsubject/components/contractsubjectacount',
name: 'storagemanage-contractsubject-components-contractsubjectacount',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}ContractSubjectAcount-index',

meta:{
...meta,
title: '合同主体账户表'
},

component: () => import('@/pages/storagemanage/arcontractsubject/components/contractsubjectacount/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}ContractSubjectAcount-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '合同主体账户表编辑'
},

component: () => import('@/pages/storagemanage/arcontractsubject/components/contractsubjectacount/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}ContractSubjectAcountdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '合同主体账户表新增'
},

component: () => import('@/pages/storagemanage/arcontractsubject/components/contractsubjectacount/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}ContractSubjectAcountView',
auth: [
'hidden'
],

meta:{
...meta,
title: '合同主体账户表详情查看'
},

component: () => import('@/pages/storagemanage/arcontractsubject/components/contractsubjectacount/detail-view') ,
},
]
};
