/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-07T09:38:27.822
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'house-CCBContract-'

export default 
{
path: '/house/ccbcontract',
name: 'house-CCBContract',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}CCBContract-index',

meta:{
...meta,
title: '合同签约'
},

component: () => import('@/pages/house/ccbcontract/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}CCBContract-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '合同签约编辑'
},

component: () => import('@/pages/house/ccbcontract/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}CCBContractdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '合同签约新增'
},

component: () => import('@/pages/house/ccbcontract/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}CCBContractView',
auth: [
'hidden'
],

meta:{
...meta,
title: '合同签约详情查看'
},

component: () => import('@/pages/house/ccbcontract/detail-view') ,
},
]
};
