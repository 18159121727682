import util from '@/libs/util';
import router from '@/router';
import {
	saveHourseInfo,
	getHourseInfoCreateInit,
	getHourseInfoEditInit,
	getHourseInfoView,
	deleteHourseInfo
} from '@api/storagemanage/HourseInfo';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getHourseInfoCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getHourseInfoCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHourseInfoEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHourseInfoEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveHourseInfo({}, HourseInfo) {
			return new Promise((resolve, reject) => {
				saveHourseInfo(HourseInfo)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getHourseInfoView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHourseInfoView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteHourseInfo({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteHourseInfo({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
