/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T09:30:03.546
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveFinancialPayablesItem,
	getFinancialPayablesItemCreateInit,
	getFinancialPayablesItemEditInit,
	getFinancialPayablesItemView,
	deleteFinancialPayablesItem
} from '@api/financial/FinancialPayablesItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialPayablesItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayablesItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialPayablesItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayablesItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialPayablesItem({}, FinancialPayablesItem) {
			return new Promise((resolve, reject) => {
				saveFinancialPayablesItem(FinancialPayablesItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialPayablesItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialPayablesItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialPayablesItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialPayablesItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
