const pre = '/rent/';

export default {
    path: '/rent',
    title: '出租管理',
    header: 'home',
    custom: 'i-icon-demo i-icon-demo-list',
	icon: 'md-speedometer',
    children: [
        {
            path: `${pre}basic-list`,
            title: '房源管理',
            children: [
			{
				path: `/rentmanage/renthousebig/index`,
				title: '房源总表',
			},{
				path: `/rentmanage/renthousesalesprice/index`,
				title: '房源定价',
			},
			{
				path: `/rentmanage/renthouseonline/index`,
				title: '房源上线',
			},
			{
				path: `/rentmanage/renthousehold/index`,
				title: '房源保留',
			},
			{
				path: `/rentmanage/channel/index`,
				title: '渠道管理',
			},
			{
				path: `/rentmanage/rentchannelapply/index`,
				title: '房源分发',
			},
			{
				path: `/rentmanage/renthouse/index`,
				title: '出租管理',
			},
			{
				path: `/rentmanage/renttakelookrecord/index`,
				title: '带看管理',
			}
		]
        },
		{
		    path: `#50`,
		    title: '预定管理',
		    children: [
		   			{
		   				path: `/rentmanage/rentplaceorder/index`,
		   				title: '预定管理',
		   			},
		   			{
		   				path: `/rentmanage/rentplaceorderretreat/index`,
		   				title: '退定管理',
		   			},
		     ]
		},
        {
            path: `#51`,
            title: '合同管理'
        },
        {
            path: `#52`,
            title: '财务管理'
        },{
		    path: `#53`,
		    title: '维修管理',
		    children: [
		   			{
		   				path: `/rentmanage/rentrepairworkorder/index`,
		   				title: '工单管理',
		   			},
		   			{
		   				path: `/rentmanage/rentrepairworkorderhandassign`,
		   				title: '工单指派',
		   			},
		   			{
		   				path: `/rentmanage/rentmaterial/index`,
		   				title: '材料管理',
		   			},
		   			{
		   				path: `/rentmanage/rentmaterialreceivingmainform/index`,
		   				title: '材料领取',
		   			},
		   			{
		   				path: `/rentmanage/rentrepairfeedbackdetails/index`,
		   				title: '工单反馈',
		   			},
		   			{
		   				path: `/rentmanage/rentrepairfeedbackdetails/indexMain`,
		   				title: '工单跟踪',
		   			},{
		   				path: `/rentmanage/rentpurchaseorder/index`,
		   				title: '进货管理',
		   			},
		     ]
		},
    ]
}
