import request from '@/plugins/request';

export function listEvaluateBillAttachmentInit(data){return request({
url: '/storagemanage/api/evaluateBillAttachment/listEvaluateBillAttachmentInit',
method: 'post',
data:data,
});
}


export function listEvaluateBillAttachmentByAuthorizations(data){return request({
url: '/storagemanage/api/evaluateBillAttachment/listEvaluateBillAttachmentByAuthorizations',
method: 'post',
data:data,
});
}


export function listEvaluateBillAttachment(data){return request({
url: '/storagemanage/api/evaluateBillAttachment/listEvaluateBillAttachment',
method: 'post',
data:data,
});
}


export function getEvaluateBillAttachmentCreateInit(){return request({
url: '/storagemanage/api/evaluateBillAttachment/getEvaluateBillAttachmentCreateInit',
method: 'post',
});
}


export function getEvaluateBillAttachmentEditInit(params){return request({
url: '/storagemanage/api/evaluateBillAttachment/getEvaluateBillAttachmentEditInit',
method: 'post',
params:params,
});
}


export function getEvaluateBillAttachmentView(params){return request({
url: '/storagemanage/api/evaluateBillAttachment/getEvaluateBillAttachmentView',
method: 'post',
params:params,
});
}


export function saveEvaluateBillAttachment(data){return request({
url: '/storagemanage/api/evaluateBillAttachment/saveEvaluateBillAttachment',
method: 'post',
data:data,
});
}


export function deleteEvaluateBillAttachment(params){return request({
url: '/storagemanage/api/evaluateBillAttachment/deleteEvaluateBillAttachment',
method: 'post',
params:params,
});
}


