/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-04T19:46:29.228
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveHouseRepeatPlan,
	getHouseRepeatPlanCreateInit,
	getHouseRepeatPlanEditInit,
	getHouseRepeatPlanView,
	deleteHouseRepeatPlan
} from '@api/storagemanage/HouseRepeatPlan';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listHouseRepeatPlanConditionInit,
listHouseRepeatPlanConditionByAuthorizations,
deleteHouseRepeatPlanCondition
} from '@api/storagemanage/HouseRepeatPlanCondition';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getHouseRepeatPlanCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getHouseRepeatPlanCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseRepeatPlanEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseRepeatPlanEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveHouseRepeatPlan({}, HouseRepeatPlan) {
			return new Promise((resolve, reject) => {
				saveHouseRepeatPlan(HouseRepeatPlan)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseRepeatPlanView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseRepeatPlanView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteHouseRepeatPlan({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteHouseRepeatPlan({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
