/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:58.286
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listVacancyPeriodInfo,
	listVacancyPeriodInfoInit,
	listVacancyPeriodInfoByAuthorizations,
	deleteVacancyPeriodInfo
} from '@api/lcf/VacancyPeriodInfo';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listVacancyPeriodInfo({
			state
		}) {
			return new Promise((resolve, reject) => {
				listVacancyPeriodInfo(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listVacancyPeriodInfoByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listVacancyPeriodInfoByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listVacancyPeriodInfoInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listVacancyPeriodInfoInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteVacancyPeriodInfo({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteVacancyPeriodInfo({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
