/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:56.853
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveHouseInfo,
	getHouseInfoCreateInit,
	getHouseInfoEditInit,
	getHouseInfoView,
	deleteHouseInfo
} from '@api/lcf/HouseInfo';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listBusiTrackInfoInit,
listBusiTrackInfoByAuthorizations,
deleteBusiTrackInfo
} from '@api/lcf/BusiTrackInfo';

import {
listHouseRentalInfoInit,
listHouseRentalInfoByAuthorizations,
deleteHouseRentalInfo
} from '@api/lcf/HouseRentalInfo';

import {
listHouseSchoolInfoInit,
listHouseSchoolInfoByAuthorizations,
deleteHouseSchoolInfo
} from '@api/lcf/HouseSchoolInfo';

import {
listHouseCoownerInfoInit,
listHouseCoownerInfoByAuthorizations,
deleteHouseCoownerInfo
} from '@api/lcf/HouseCoownerInfo';

import {
listHouseRateInfoInit,
listHouseRateInfoByAuthorizations,
deleteHouseRateInfo
} from '@api/lcf/HouseRateInfo';

import {
listEvaluationMicroRemakeProblemInit,
listEvaluationMicroRemakeProblemByAuthorizations,
deleteEvaluationMicroRemakeProblem
} from '@api/lcf/EvaluationMicroRemakeProblem';

import {
listFinalInterviewInit,
listFinalInterviewByAuthorizations,
deleteFinalInterview
} from '@api/lcf/FinalInterview';

import {
listEvaluationRentInit,
listEvaluationRentByAuthorizations,
deleteEvaluationRent
} from '@api/lcf/EvaluationRent';

import {
listHouseLeaseContractInit,
listHouseLeaseContractByAuthorizations,
deleteHouseLeaseContract
} from '@api/lcf/HouseLeaseContract';

import {
listVacancyPeriodInfoInit,
listVacancyPeriodInfoByAuthorizations,
deleteVacancyPeriodInfo
} from '@api/lcf/VacancyPeriodInfo';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getHouseInfoCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getHouseInfoCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseInfoEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseInfoEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveHouseInfo({}, HouseInfo) {
			return new Promise((resolve, reject) => {
				saveHouseInfo(HouseInfo)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseInfoView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseInfoView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteHouseInfo({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteHouseInfo({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
