/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-05-16T17:00:11.45
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listEvaluateAppointmentFinalRejection,
	listEvaluateAppointmentFinalRejectionInit,
	listEvaluateAppointmentFinalRejectionByAuthorizations,
	deleteEvaluateAppointmentFinalRejection
} from '@api/storagemanage/EvaluateAppointmentFinalRejection';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listEvaluateAppointmentFinalRejection({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateAppointmentFinalRejection(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateAppointmentFinalRejectionByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateAppointmentFinalRejectionByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateAppointmentFinalRejectionInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateAppointmentFinalRejectionInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateAppointmentFinalRejection({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateAppointmentFinalRejection({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
