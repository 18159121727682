import util from '@/libs/util';
import router from '@/router';
import dayjs from 'dayjs';
import {
	pageInit,
	GetDataList,
	addPageInit,
	calculatePrice,
	downloadCalTemplate,
	importPayPlanReq,
	getSubjectList,
	sendSign,
	subjectListPageInit,
	subjectEditPageInit,
	AddOrEditData,
	editPageInitReq,
	confirmSigning,
	supplementconAttachment,
	deleteConParticipant,
	deleteConEstateInfo,
	deleteConDeputeContract,
	getProfitData,
	sentTerminationSign,
	listDeputeContractSubjectManage,
	renewDeposit,
	delay,
	sendDelaySigningLink
} from '@api/storagemanage/conDeputeContract';
import {
	startWorkflowApi
} from '@api/storagemanage/flowCenterApi';
import {
	Message,
	Notice
} from 'view-design';

import {
	cloneObj
} from '@/plugins/utils';
import RespCommonDef from '@/plugins/utils/RespCommonDef';

import {
	Modal
} from 'view-design';

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),

		//列表初始信息
		initInfo: {
			configItems: {}
		},

		pageInitParams: {
			entity: {
				"channelType": "",
				"contractCode": "",
				"contractStatus": "",
				"propertyRightAddress": "",
				"createrName": "",
				"deleteState": "",
				"endDate": "",
				"houseCode": "",
				"isOrder": true,
				"isOrderAsc": true,
				"isPage": true,
				"orderColumn": "",
				"ownerName": "",
				"pageIndex": 1,
				"pageSize": 10,
				"roomFullName": "",
				"saasCompany": "",
				"selectColumns": [],
				"startDate": ""
			}
		},

		reqParams: {
			entity: {
				"channelType": "",
				"contractCode": "",
				"contractStatus": "",
				"propertyRightAddress": "",
				"createrName": "",
				"deleteState": "",
				"endDate": "",
				"houseCode": "",
				"isOrder": true,
				"isOrderAsc": true,
				"isPage": true,
				"orderColumn": "",
				"ownerName": "",
				"pageIndex": 1,
				"pageSize": 10,
				"roomFullName": "",
				"saasCompany": "",
				"selectColumns": [],
				"startDate": "",
				"signSource": ""
			}
		},

		subjectInitParams: {
			"entity": {
				"address": "",
				"cardNum": "",
				"cardType": "",
				"certificationType": "",
				"createTime": "",
				"createrId": "",
				"createrName": "",
				"customerLabel": "",
				"deleteState": 0,
				"instanceId": "",
				"lastUpdateDate": "",
				"legalPersonNum": "",
				"legalPersonType": "",
				"legalRepresentative": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"phoneNum": "",
				"receiptAccount": "",
				"receiptBank": "",
				"receiptBankName": "",
				"receipter": "",
				"remark": "",
				"saasCompany": "",
				"sealColor": "",
				"sealType": "",
				"sex": "",
				"spouseCertiNum": "",
				"spouseCertiType": "",
				"spouseMobileNum": "",
				"spouseName": "",
				"status": "",
				"subjectCode": "",
				"subjectId": "",
				"subjectName": "",
				"subjectType": "",
				"theIsMarriage": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 1,
			"pageSize": 10,
			"selectColumns": []
		},

	},
	actions: {
		pageInit({
			state,
			dispatch
		}) {
			return new Promise((resolve, reject) => {
				pageInit(state.pageInitParams)
					.then(async res => {
						state.initInfo = res;
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getListData({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				GetDataList(state.reqParams)
					.then(async res => {
						resolve(res);

					})
					.catch(err => {
						reject(err);
					})
			})
		},

		addPageInit({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				addPageInit()
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		editPageInit({
			dispatch,
			state,
		}, id) {
			return new Promise((resolve, reject) => {
				editPageInitReq({
						id: id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		calculatePrice({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				calculatePrice(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		downloadCalTemplate({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				downloadCalTemplate(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		importPayPlanReq({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				importPayPlanReq({
						file: data
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		subjectListPageInit({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				addPageInit(state.subjectInitParams)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getSubjectList({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				getSubjectList(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		listDeputeContractSubjectManage({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				listDeputeContractSubjectManage(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		subjectEditPageInit({
			dispatch,
			state,
		}, id) {
			return new Promise((resolve, reject) => {
				subjectEditPageInit({
						id: id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		AddOrEditData({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				AddOrEditData(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		sendSign({
			dispatch,
			state,
		}, id) {
			return new Promise((resolve, reject) => {
				sendSign({
						contractId: id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteConDeputeContract({
			dispatch,
			state,
		}, id) {
			return new Promise((resolve, reject) => {
				deleteConDeputeContract({
						id: id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getProfitData({
			dispatch,
			state,
		}, obj) {
			return new Promise((resolve, reject) => {
				getProfitData(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		confirmSigning({
			dispatch,
			state,
		}, id) {
			return new Promise((resolve, reject) => {
				confirmSigning({
						id: id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteConEstateInfo({
			dispatch,
			state,
		}, id) {
			return new Promise((resolve, reject) => {
				deleteConEstateInfo({
						id: id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		sentTerminationSign({
			state
		}, noticeTerminationData) {
			return new Promise((resolve, reject) => {
				sentTerminationSign(noticeTerminationData)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteConParticipant({
			dispatch,
			state,
		}, id) {
			return new Promise((resolve, reject) => {
				deleteConParticipant({
						id: id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		renewDeposit({
			dispatch,
			state,
		}, id) {
			return new Promise((resolve, reject) => {
				renewDeposit({
						id: id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		delay({
			dispatch,
			state,
		}, delayVo) {
			return new Promise((resolve, reject) => {
				delay(delayVo)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		sendDelaySigningLink({
			dispatch,
			state,
		}, contractId) {
			return new Promise((resolve, reject) => {
				sendDelaySigningLink({
						contractId: contractId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
	},
};
