/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-17T17:56:04.456
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentIssueHouseApplyItem,
	getRentIssueHouseApplyItemCreateInit,
	getRentIssueHouseApplyItemEditInit,
	getRentIssueHouseApplyItemView,
	deleteRentIssueHouseApplyItem
} from '@api/rentmanage/RentIssueHouseApplyItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentIssueHouseApplyItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentIssueHouseApplyItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentIssueHouseApplyItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentIssueHouseApplyItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentIssueHouseApplyItem({}, RentIssueHouseApplyItem) {
			return new Promise((resolve, reject) => {
				saveRentIssueHouseApplyItem(RentIssueHouseApplyItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentIssueHouseApplyItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentIssueHouseApplyItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentIssueHouseApplyItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentIssueHouseApplyItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
