/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-01-05T10:42:26.665
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinancialElectronicAccountItem,
	listFinancialElectronicAccountItemInit,
	listFinancialElectronicAccountItemByAuthorizations,
	deleteFinancialElectronicAccountItem
} from '@api/financial/FinancialElectronicAccountItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFinancialElectronicAccountItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialElectronicAccountItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialElectronicAccountItemByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialElectronicAccountItemByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialElectronicAccountItemInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialElectronicAccountItemInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialElectronicAccountItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialElectronicAccountItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
