import BasicLayout from '@/layouts/basic-layout';
////第一步：配置菜单-->第二步：配置存房委托指派商家页面相关的路由-->第三步：注入路由-->第四步：做页面
const meta = {
	auth: true
};

const pre = 'rentmanage-rentHouse';

export default {
	path: '/rentmanage/renthouse/index',
	name: '房源出租',//存房委托指派专员
	redirect: {
		name: `${pre}rentHouse-list`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'rentHouse-list',
		name: `${pre}rentHouse-list`,
		meta: {
			...meta,
			title: '房源出租',
			closable: true
		},
		component: () => import('@/pages/rentmanage/renthouse/index')
	},{
		path: 'housekeeperIndex',
		name: `${pre}housekeeperIndex`,
		meta: {
			...meta,
			title: '专属管家',
			closable: true
		},
		component: () => import('@/pages/rentmanage/renthouse/housekeeperIndex')
	}]
};
