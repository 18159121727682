import request from '@/plugins/request';

export function pageInit(data) {
	return request({
		url: '/storagemanage/api/conDeputeContract/listConDeputeContractInit',
		method: 'post',
		data: data
	});
}

export function GetDataList(data) {
	return request({
		url: '/storagemanage/api/conDeputeContract/listConDeputeContractByAuthorizations',
		method: 'post',
		data: data
	});
}

export function addPageInit(data) {
	return request({
		url: '/storagemanage/api/conDeputeContract/getConDeputeContractCreateInit',
		method: 'post',
		data: data
	});
}

export function calculatePrice(data) {
	return request({
		url: '/storagemanage/api/conDeputeContract/calculationDepositPrice',
		method: 'post',
		data: data
	});
}

export function downloadCalTemplate(data) {
	return request({
		url: '/storagemanage/api/conDeputeContract/downloadExcelTemplate',
		method: 'post',
		data: data,
		responseType: 'blob'
	});
}

export function importPayPlanReq(data) {
	return request({
		url: '/storagemanage/api/conDeputeContract/importPayPlan',
		method: 'post',
		data: data,
	});
}

export function subjectListPageInit(data) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/listContractSubjectManageInit',
		method: 'post',
		data: data
	});
}

export function getSubjectList(data) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/listContractSubjectManage',
		method: 'post',
		data: data
	});
}

export function listDeputeContractSubjectManage(data) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/listDeputeContractSubjectManage',
		method: 'post',
		data: data
	});
}

export function subjectEditPageInit(data) {
	return request({
		url: '/storagemanage/api/contractSubjectManage/getContractSubjectManageEditInit',
		method: 'post',
		params: data
	});
}

export function getArea(data) {
	return request({
		url: '/storagemanage/api/area/getAreaSimpleList',
		method: 'post',
		data: data
	});
}

export function getAreaById(data) {
	return request({
		url: '/storagemanage/api/area/listArea',
		method: 'post',
		data: data
	});
}

export function AddOrEditData(data) {
	return request({
		url: '/storagemanage/api/conDeputeContract/saveConDeputeContract',
		method: 'post',
		data: data
	});
}

export function editPageInitReq(data) {
	return request({
		url: '/storagemanage/api/conDeputeContract/getConDeputeContractEditInit',
		method: 'post',
		params: data
	});
}

export function deteleRecordReq(data) {
	return request({
		url: '/datadictionarymanage/api/configtreeManage/deleteConfigtreeManage',
		method: 'post',
		params: data
	});
}

export function sendSign(data) {
	return request({
		url: '/storagemanage/api/conDeputeContract/sendSigningLink',
		method: 'post',
		params: data
	});
}

export function confirmSigning(data) {
	return request({
		url: '/storagemanage/api/conDeputeContract/confirmSigning',
		method: 'post',
		params: data
	});
}

export function supplementconAttachment(data) {
	return request({
		url: '/storagemanage/api/conDeputeContract/supplementconAttachment',
		method: 'post',
		params: data
	});
}

export function deleteConEstateInfo(data) {
	return request({
		url: '/storagemanage/api/conEstateInfo/deleteConEstateInfo',
		method: 'post',
		params: data
	});
}

export function deleteConParticipant(data) {
	return request({
		url: '/storagemanage/api/conParticipant/deleteConParticipant',
		method: 'post',
		params: data
	});
}
    
export function deleteConDeputeContract(data) {
    return request({
        url: '/storagemanage/api/conDeputeContract/deleteConDeputeContract',
        method: 'post',
        params: data
    });
    
}


export function sentTerminationSign(data){return request({
	url: '/storagemanage/api/conDeputeContract/sentTerminationSign',
	method: 'post',
	data: data
	});
}

export function getProfitData(data) {
    return request({
        url: '/storagemanage/api/reportReturnAssets/queryFirstYearDepositRentInfo',
        method: 'get',
        params: data
    });
    
}

export function renewDeposit(data) {
    return request({
        url: '/storagemanage/api/conDeputeContract/renewDeposit',
        method: 'post',
        params: data
    });
}

export function delay(data) {
    return request({
        url: '/storagemanage/api/conDeputeContract/delay',
        method: 'post',
        data: data
    });
}

export function sendDelaySigningLink(data) {
    return request({
        url: '/storagemanage/api/conDeputeContract/sendDelaySigningLink',
        method: 'post',
        params: data
    });
}