/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-23T16:04:05.44
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getChannelpicEditInit,
	getChannelpicView
} from '@api/rentmanage/Channelpic';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		getChannelpicEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getChannelpicEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getChannelpicView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getChannelpicView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
