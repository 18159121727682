import request from '@/plugins/request';

export function pageInit(data) {
	return request({
		url: '/storagemanage/api/companyManage/listCompanyManageInit',
		method: 'post',
		data: data
	});
}

export function GetDataList(data) {
	return request({
		url: '/storagemanage/api/companyManage/listCompanyManage',
		method: 'post',
		data: data
	});
}

export function getCompanyDropDowList(data) {
	return request({
		url: '/storagemanage/api/companyManage/listCompanyManageDropDownData',
		method: 'post',
		data: data
	});
}

export function addPageInit(data) {
	return request({
		url: '/storagemanage/api/companyManage/getCompanyManageCreateInit',
		method: 'post',
		data: data
	});
}

export function getArea(data) {
	return request({
		url: '/storagemanage/api/area/getAreaSimpleList',
		method: 'post',
		data: data
	});
}

export function getAreaById(data) {
	return request({
		url: '/storagemanage/api/area/listArea',
		method: 'post',
		data: data
	});
}

export function AddOrEditData(data) {
	return request({
		url: '/storagemanage/api/companyManage/saveCompanyManage',
		method: 'post',
		data: data
	});
}

export function editPageInitReq(data) {
	return request({
		url: '/storagemanage/api/companyManage/getCompanyManageEditInit',
		method: 'post',
		params: data
	});
}

export function deteleRecordReq(data) {
	return request({
		url: '/datadictionarymanage/api/configtreeManage/deleteConfigtreeManage',
		method: 'post',
		params: data
	});
}
