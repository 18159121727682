/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-07T09:38:28.033
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listCCBSyncLog,
	listCCBSyncLogInit,
	listCCBSyncLogByAuthorizations,
	deleteCCBSyncLog
} from '@api/house/CCBSyncLog';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listCCBSyncLog({
			state
		}) {
			return new Promise((resolve, reject) => {
				listCCBSyncLog(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listCCBSyncLogByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listCCBSyncLogByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listCCBSyncLogInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listCCBSyncLogInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteCCBSyncLog({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteCCBSyncLog({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
