/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-28T14:14:36.619
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancialCollection,
	getFinancialCollectionCreateInit,
	getFinancialCollectionEditInit,
	getFinancialCollectionView,
	deleteFinancialCollection
} from '@api/financial/FinancialCollection';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listFinancialCollectionItemInit,
listFinancialCollectionItemByAuthorizations,
deleteFinancialCollectionItem
} from '@api/financial/FinancialCollectionItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialCollectionCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialCollectionEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialCollection({}, FinancialCollection) {
			return new Promise((resolve, reject) => {
				saveFinancialCollection(FinancialCollection)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialCollectionView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialCollectionView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialCollection({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialCollection({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
