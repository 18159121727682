/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-11-06T11:29:37.899
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'report-reportfinancialrentcontractitemunfinished-'

export default {
	path: '/report/reportfinancialrentcontractitemunfinished',
	name: 'report-reportfinancialrentcontractitemunfinished',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}reportfinancialrentcontractitemunfinished-index',

			meta: {
				...meta,
				title: '业务营收报表-欠费'
			},

			component: () => import('@/pages/report/reportfinancialrentcontractitemunfinished/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}reportfinancialrentcontractitemunfinished-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '业务营收报表-欠费编辑'
			},

			component: () => import('@/pages/report/reportfinancialrentcontractitemunfinished/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}reportfinancialrentcontractitemunfinisheddetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '业务营收报表-欠费新增'
			},

			component: () => import('@/pages/report/reportfinancialrentcontractitemunfinished/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}reportfinancialrentcontractitemunfinishedView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '业务营收报表-欠费详情查看'
			},

			component: () => import('@/pages/report/reportfinancialrentcontractitemunfinished/detail-view'),
		},
	]
};
