<template>
  <div>
    <JFDrawer destroy-on-close title="流程转发" v-model="startModal">
      <orgusermanagelistF7 ref="orgusermanagelistF7Ref" />
      <template slot="footer">
        <Button type="primary"
                :long="isMobile"
                :loading="loading"
                @click="submitFlow">提交流程</Button>
      </template>
    </JFDrawer>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import orgusermanagelistF7 from '@/pages/systemmanage/orgusermanage/indexF7'
  import request from '@/plugins/request'

  export default {
    name: 'TranspondFlow',
    components: {orgusermanagelistF7},
    computed: {
      ...mapState('admin/layout', ['isMobile']),
    },
    methods: {
      init (data, url, billData) {
        this.startModal = true
        this.data = data
        this.flowUrl = url
        //审核结论设置初始值
        this.submitData.approveEffect = this.data.currentWorkitem.approveEffectRange && this.data.currentWorkitem.approveEffectRange
          .length > 0 ? this.data.currentWorkitem.approveEffectRange[0].code : '';
        //审核意见设置初始值
        this.submitData.approveContent = this.data.currentWorkitem.approveEffectRange && this.data.currentWorkitem.approveEffectRange
          .length > 0 ? this.data.currentWorkitem.approveEffectRange[0].name : '';
        // this.submitData.bill = billData;
        //流程实例id赋值(从审核初始化接口)
        this.submitData.workflowInstanceId = this.data.currentDraftWorkitemInstance.workflowInstanceId;
        //流程环节实例id赋值(从审核初始化接口)
        this.submitData.workitemInstanceId = this.data.currentDraftWorkitemInstance.workitemInstanceId;
      },
      async submitFlow () {
        try {
          let businessData = null
          const orgUser = this.$refs.orgusermanagelistF7Ref.getSelectData()

          this.$emit('getBusinessData', function (data) {
            businessData = data
          })
          this.submitData.bill = businessData;
          if (!orgUser.length) return this.$Message.warning('请选择用户!')
          if (orgUser.length > 1) return this.$Message.warning('只能选择一个用户!')
          this.loading = true
          //提交流程
          await request({
            url: this.flowUrl + '/transpondWorkflow',
            method: 'post',
            data: this.submitData.bill,
            params: {
              nextUserId: orgUser[0].userId,
              nextUserName: orgUser[0].userName,
              workflowInstanceId: this.submitData.workflowInstanceId,
              workitemInstanceId: this.submitData.workitemInstanceId,
            }
          })
          this.startModal = false;
          this.$emit('refresh')
        } catch (e) {} finally {
          this.loading = false
        }
      },
    },
    data () {
      return {
        startModal: false,
        loading: false,
        data: {}, //流程初始化传入的数据
        flowUrl: '', //发起流程的url
        submitData: {
          bill: {},
          workflowInstanceId: '',
          workitemInstanceId: '',
          approveContent: '',
          approveEffect: 'AGREE',
        }, //提交流程的数据对象
      }
    },
  }
</script>
