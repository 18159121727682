/**
 * 第一步：mapActions第一个参数，指向到store-->第二步：编写store数据结构-->第三步：引用API接口，调用后台，取得数据-->第四步：绑定数据到store数据结构中
 * */
import util from '@/libs/util';
import router from '@/router';
import dayjs from 'dayjs';
import {
	GetDataList,
	GetUser,
} from '@api/storagemanage/houseSaveDelegationAssessmentCompanyApi';
import {
	listManagementAreaUserInit,
} from '@api/storagemanage/ManagementAreaUser';
import {
	Message,
	Notice
} from 'view-design';

import {
	Modal
} from 'view-design';
import {
	cloneObj
} from '@/plugins/utils'
import RespCommonDef from '@/plugins/utils/RespCommonDef'
export default {
	namespaced: true,
	state: {
		// 初始化信息
		initInfo: {
			distributeStatusItems: {

			}
		},
		//查询条件
		reqParams: {
			houseNo:'',
			houseAddress:'',
			billCode: '',
			billStartDate: '',
			billEndDate: '',
			owneName:'',
			distributeStatus:'',
			tableName:'',
			isPage: true,
			pageIndex: 1,
			pageSize: 10,
		},
		manageParams:cloneObj(RespCommonDef.AdvanceQuery),
		userParams: {
			"entity": {
				"areaId": "",
				"areaName": "",
				"cityId": "",
				"cityName": "",
				"companyBizType": "",
				"companyId": "",
				"companyName": "",
				"createTime": "",
				"createrId": "",
				"deleteState": "",
				"descript": "",
				"id": "",
				"lastUpdateDate": "",
				"mobile": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorNameCopy1": "",
				"operatorTime": "",
				"orgId": "",
				"orgName": "",
				"provinceId": "",
				"provinceName": "",
				"saasCompany": "",
				"userId": "",
				"userName": "",
				"userType": "3"
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": false,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 1,
			"pageSize": 10,
			"selectColumns": [],
			"specificChannel": {}
		},
		//查询接口返回的数据
		listDataObj: {
			entityResponse: {
				totalKeys: [],
			},
		},

		//编辑参数
		editData: {},
	},
	actions: {
		getList({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				GetDataList(state.reqParams)
					.then(async res => {
						// 结束
						state.listDataObj = res
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getUsers({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				GetUser(state.userParams)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
		listManagementAreaUserInit({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				listManagementAreaUserInit(state.manageParams)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
	}
};
