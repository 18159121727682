/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-08T11:03:22.5
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentMaterialReceivingForm,
	getRentMaterialReceivingFormCreateInit,
	getRentMaterialReceivingFormEditInit,
	getRentMaterialReceivingFormView,
	deleteRentMaterialReceivingForm
} from '@api/rentmanage/RentMaterialReceivingForm';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentMaterialReceivingFormCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentMaterialReceivingFormCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentMaterialReceivingFormEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentMaterialReceivingFormEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentMaterialReceivingForm({}, RentMaterialReceivingForm) {
			return new Promise((resolve, reject) => {
				saveRentMaterialReceivingForm(RentMaterialReceivingForm)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentMaterialReceivingFormView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentMaterialReceivingFormView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentMaterialReceivingForm({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentMaterialReceivingForm({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
