/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T19:13:41.337
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentRepairFeedbackTypeInit(data){return request({
url: '/rentmanage/api/rentRepairFeedbackType/listRentRepairFeedbackTypeInit',
method: 'post',
data:data,
});
}


export function listRentRepairFeedbackTypeByAuthorizations(data){return request({
url: '/rentmanage/api/rentRepairFeedbackType/listRentRepairFeedbackTypeByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentRepairFeedbackType(data){return request({
url: '/rentmanage/api/rentRepairFeedbackType/listRentRepairFeedbackType',
method: 'post',
data:data,
});
}


export function getRentRepairFeedbackTypeCreateInit(){return request({
url: '/rentmanage/api/rentRepairFeedbackType/getRentRepairFeedbackTypeCreateInit',
method: 'post',
});
}


export function getRentRepairFeedbackTypeEditInit(params){return request({
url: '/rentmanage/api/rentRepairFeedbackType/getRentRepairFeedbackTypeEditInit',
method: 'post',
params:params,
});
}


export function getRentRepairFeedbackTypeView(params){return request({
url: '/rentmanage/api/rentRepairFeedbackType/getRentRepairFeedbackTypeView',
method: 'post',
params:params,
});
}


export function saveRentRepairFeedbackType(data){return request({
url: '/rentmanage/api/rentRepairFeedbackType/saveRentRepairFeedbackType',
method: 'post',
data:data,
});
}


export function deleteRentRepairFeedbackType(params){return request({
url: '/rentmanage/api/rentRepairFeedbackType/deleteRentRepairFeedbackType',
method: 'post',
params:params,
});
}


