import Vue from 'vue';
import VXETable from 'vxe-table'
import App from './App';

// 配置
import Setting from './setting';

// 混合
import mixinApp from '@/mixins/app';

// 插件
import plugins from '@/plugins';

// store
import store from '@/store/index';

// iView 和 iView Pro
import ViewUI from 'view-design';
import iViewPro from '@/libs/iview-pro/iview-pro.min'
import echarts from 'echarts'


Vue.prototype.$echarts = echarts

import { VueJsonp } from 'vue-jsonp'

// Vue Plugin.
Vue.use(VueJsonp)

// 菜单和路由
import router from './router';
import menuHeader from '@/menu/header';
import menuSider from '@/menu/sider';
import {asyncRouterMap} from '@/router/routes'

// 方法
import {
	getHeaderName,
	getMenuSider,
	getSiderSubmenu
} from '@/libs/system';

// 内置组件
import Components from '@/components'

// 使用样式，修改主题可以在 styles 目录下创建新的主题包并修改 iView 默认的 less 变量
// 参考 https://www.iviewui.com/docs/guide/theme
import './styles/index.less';
import './libs/iview-pro/iview-pro.css';
import 'vxe-table/lib/style.css'


import {
	rowAuth,
	getUploadList,
	deleteFixed,
} from '@/plugins/utils';
Vue.prototype.rowAuth = rowAuth;
Vue.prototype.getUploadList = getUploadList;
Vue.prototype.deleteFixed = deleteFixed;

import {
	initSQL, initPhoneSQL
} from '@/plugins/utils/initsqllist'
Vue.prototype.$initSQL = initSQL
Vue.prototype.$initPhoneSQL = initPhoneSQL

Vue.use(plugins);
window.router = router

Vue.use(ViewUI);
Vue.use(iViewPro);
Vue.use(VXETable)
import flowTabs from '@/pages/storagemanage/components/flow/flowTabs'
Vue.component('flowTabs', flowTabs);



import cascaderMulti from "cascader-multi";
Vue.use(cascaderMulti);
import vueSignature from 'vue-signature'
Vue.component(vueSignature.name, vueSignature);
import iPrint from '@/components/print/print.min';
Vue.use(iPrint);
Vue.use(Components)

// 取消 Vue 所有的日志与警告
Vue.config.silent = true
new Vue({
	mixins: [mixinApp],
	router,
	store,
	render: h => h(App),
	created() {
		// 处理路由 得到每一级的路由设置
		this.$store.commit('admin/page/init', asyncRouterMap);
		// 设置顶栏菜单
		this.$store.commit('admin/menu/setHeader', menuHeader);
		// 加载用户登录的数据
		this.$store.dispatch('admin/account/load');
		// 初始化全屏监听
		this.$store.dispatch('admin/layout/listenFullscreen')
	},
	watch: {
		// 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
		'$route'(to, from) {
			let path = to.matched[to.matched.length - 1].path;
			if (!Setting.dynamicSiderMenu) {
				const headerName = getHeaderName(path, menuSider);
				// 在 404 时，是没有 headerName 的
				if (headerName !== null) {
					this.$store.commit('admin/menu/setHeaderName', headerName);

					const filterMenuSider = getMenuSider(menuSider, headerName);
					this.$store.commit('admin/menu/setSider', filterMenuSider);
					this.$store.commit('admin/menu/setActivePath', path);

					const openNames = getSiderSubmenu(path, menuSider);
					this.$store.commit('admin/menu/setOpenNames', openNames);
				}
			} else {
				let headerName = null;
				if (this.$store.state.admin.account.menuList) {
					headerName = getHeaderName(path, this.$store.state.admin.account.menuList || []);
				}
				// 在 404 时，是没有 headerName 的
				if (headerName !== null) {
					this.$store.commit('admin/menu/setHeaderName', headerName);

					const filterMenuSider = getMenuSider(this.$store.state.admin.account.menuList, headerName);
					this.$store.commit('admin/menu/setSider', filterMenuSider)
					this.$store.commit('admin/menu/setActivePath', path);

					const openNames = getSiderSubmenu(path, this.$store.state.admin.account.menuList);
					this.$store.commit('admin/menu/setOpenNames', openNames);
				}
			}
			this.appRouteChange(to, from);
		}
	}
}).$mount('#app');

