/**
 * @version GENERATOR_VERSION::20200422
 * @DateTime 2020-04-28T09:57:01.101
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import util from '@/libs/util';
import router from '@/router';
import {
	saveBlock,
	getBlockCreateInit,
	getBlockEditInit,
	getBlockView,
	deleteBlock
} from '@api/storagemanage/Block';
import {
	listArea
} from '@api/storagemanage/Area';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
	listUnitInit,
	listUnitByAuthorizations,
	deleteUnit
} from '@api/storagemanage/Unit';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getBlockCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getBlockCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getBlockEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getBlockEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveBlock({}, Block) {
			return new Promise((resolve, reject) => {
				saveBlock(Block)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getBlockView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getBlockView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteBlock({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				deleteBlock({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getStreet({
			state
		}, data) {
			return new Promise((resolve, reject) => {
				listArea(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
