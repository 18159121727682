/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-30T17:06:33.723
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinancialTransfer,
	listFinancialTransferInit,
	listFinancialTransferByAuthorizations,
	deleteFinancialTransfer,
	financialTransferOpr
} from '@api/financial/FinancialTransfer';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFinancialTransfer({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialTransfer(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialTransferByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialTransferByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialTransferInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialTransferInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		financialTransferOpr({
			state
		}, {id,type}) {
			return new Promise((resolve, reject) => {
				financialTransferOpr({
						id,type
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		deleteFinancialTransfer({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialTransfer({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
