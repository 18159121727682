/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-15T11:07:07.022
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseSalesCardConsumeInit(data){return request({
url: '/rentmanage/api/rentHouseSalesCardConsume/listRentHouseSalesCardConsumeInit',
method: 'post',
data:data,
});
}


export function listRentHouseSalesCardConsumeByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseSalesCardConsume/listRentHouseSalesCardConsumeByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseSalesCardConsume(data){return request({
url: '/rentmanage/api/rentHouseSalesCardConsume/listRentHouseSalesCardConsume',
method: 'post',
data:data,
});
}


export function getRentHouseSalesCardConsumeCreateInit(){return request({
url: '/rentmanage/api/rentHouseSalesCardConsume/getRentHouseSalesCardConsumeCreateInit',
method: 'post',
});
}


export function getRentHouseSalesCardConsumeEditInit(params){return request({
url: '/rentmanage/api/rentHouseSalesCardConsume/getRentHouseSalesCardConsumeEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseSalesCardConsumeView(params){return request({
url: '/rentmanage/api/rentHouseSalesCardConsume/getRentHouseSalesCardConsumeView',
method: 'post',
params:params,
});
}


export function saveRentHouseSalesCardConsume(data){return request({
url: '/rentmanage/api/rentHouseSalesCardConsume/saveRentHouseSalesCardConsume',
method: 'post',
data:data,
});
}


export function deleteRentHouseSalesCardConsume(params){return request({
url: '/rentmanage/api/rentHouseSalesCardConsume/deleteRentHouseSalesCardConsume',
method: 'post',
params:params,
});
}


