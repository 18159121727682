/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-10-13T17:19:55.491
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getRentSerComplaintEditInit,
	getRentSerComplaintView
} from '@api/rentmanage/RentSerComplaint';

import {
listRentSerComplaintEvaluateInit,
listRentSerComplaintEvaluateByAuthorizations,
deleteRentSerComplaintEvaluate
} from '@api/rentmanage/RentSerComplaintEvaluate';

import {
listRentSerComplaintFollowupInit,
listRentSerComplaintFollowupByAuthorizations,
deleteRentSerComplaintFollowup
} from '@api/rentmanage/RentSerComplaintFollowup';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getRentSerComplaintEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentSerComplaintEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentSerComplaintView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentSerComplaintView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
