/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2021-03-17T15:34:14.716
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listPublishOperationLogInit(data) {
	return request({
		url: '/house/api/publishOperationLog/listPublishOperationLogInit',
		method: 'post',
		data: data,
	});
}


export function listPublishOperationLogByAuthorizations(data) {
	return request({
		url: '/house/api/publishOperationLog/listPublishOperationLogByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listPublishOperationLog(data) {
	return request({
		url: '/house/api/publishOperationLog/listPublishOperationLog',
		method: 'post',
		data: data,
	});
}


export function getPublishOperationLogCreateInit() {
	return request({
		url: '/house/api/publishOperationLog/getPublishOperationLogCreateInit',
		method: 'post',
	});
}


export function getPublishOperationLogEditInit(params) {
	return request({
		url: '/house/api/publishOperationLog/getPublishOperationLogEditInit',
		method: 'post',
		params: params,
	});
}


export function getPublishOperationLogView(params) {
	return request({
		url: '/house/api/publishOperationLog/getPublishOperationLogView',
		method: 'post',
		params: params,
	});
}


export function savePublishOperationLog(data) {
	return request({
		url: '/house/api/publishOperationLog/savePublishOperationLog',
		method: 'post',
		data: data,
	});
}


export function deletePublishOperationLog(params) {
	return request({
		url: '/house/api/publishOperationLog/deletePublishOperationLog',
		method: 'post',
		params: params,
	});
}


export function retry(params) {
	return request({
		url: '/house/api/publishOperationLog/retry',
		method: 'post',
		params: params,
	});
}
