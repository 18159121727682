/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:57.985
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getFinalInterviewEditInit,
	getFinalInterviewView
} from '@api/lcf/FinalInterview';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},

	actions: {
		getFinalInterviewEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinalInterviewEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinalInterviewView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinalInterviewView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
