/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-01-05T10:42:26.68
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveFinancialElectronicAccountItem,
	getFinancialElectronicAccountItemCreateInit,
	getFinancialElectronicAccountItemEditInit,
	getFinancialElectronicAccountItemView,
	deleteFinancialElectronicAccountItem
} from '@api/financial/FinancialElectronicAccountItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialElectronicAccountItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialElectronicAccountItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialElectronicAccountItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialElectronicAccountItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialElectronicAccountItem({}, FinancialElectronicAccountItem) {
			return new Promise((resolve, reject) => {
				saveFinancialElectronicAccountItem(FinancialElectronicAccountItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialElectronicAccountItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialElectronicAccountItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialElectronicAccountItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialElectronicAccountItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
