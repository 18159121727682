/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-21T17:44:56.33
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveReportWorkflowItemCount,
	getReportWorkflowItemCountCreateInit,
	getReportWorkflowItemCountEditInit,
	getReportWorkflowItemCountView,
	deleteReportWorkflowItemCount
} from '@api/report/ReportWorkflowItemCount';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getReportWorkflowItemCountCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportWorkflowItemCountCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportWorkflowItemCountEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportWorkflowItemCountEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportWorkflowItemCount({}, ReportWorkflowItemCount) {
			return new Promise((resolve, reject) => {
				saveReportWorkflowItemCount(ReportWorkflowItemCount)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportWorkflowItemCountView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportWorkflowItemCountView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteReportWorkflowItemCount({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportWorkflowItemCount({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
