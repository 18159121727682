/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-17T15:34:14.75
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	savePublishOperationLog,
	getPublishOperationLogCreateInit,
	getPublishOperationLogEditInit,
	getPublishOperationLogView,
	deletePublishOperationLog
} from '@api/house/PublishOperationLog';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getPublishOperationLogCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getPublishOperationLogCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getPublishOperationLogEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getPublishOperationLogEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		savePublishOperationLog({}, PublishOperationLog) {
			return new Promise((resolve, reject) => {
				savePublishOperationLog(PublishOperationLog)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getPublishOperationLogView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getPublishOperationLogView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deletePublishOperationLog({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deletePublishOperationLog({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
