import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'storagemanage-stockpileintention-'

export default {
	path: '/storagemanage/stockpileintention',
	name: 'storagemanage-stockpileintention',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}stockpileintention-index',

			meta: {
				...meta,
				title: '委托管理'
			},

			component: () => import('@/pages/storagemanage/stockpileintention/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}stockpileintention-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '委托管理编辑',
				cache:true,
				closable: true
			},

			component: () => import('@/pages/storagemanage/stockpileintention/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}stockpileintentiondetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '委托管理新增'
			},

			component: () => import('@/pages/storagemanage/stockpileintention/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}stockpileintentionView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '委托管理详情查看'
			},

			component: () => import('@/pages/storagemanage/stockpileintention/detail-view'),
		},
	]
};
