<template>
  <!-- 流程功能选择下拉 -->
  <div :class="['flowTabs', {mobile: isMobile}]" v-if="isVisible">
    <Card dis-hover title="流程操作" :bordered="false" v-if="!isMobile">
      <div class="process-btn-area">
        <Button  type="info" class="ivu-mr-8 ivu-mt-8"
                 :loading="loading"
                 v-if="btnAuth.indexOf('DOCASE_WORKFLOW') !== -1"
                 @click="dropdownAction('dealProcess')">流程审批
        </Button>
        <Button type="info" class="ivu-mr-8 ivu-mt-8"
                :loading="loading"
                v-if="btnAuth.indexOf('DOCASE_WORKFLOW') !== -1"
                @click="dropdownAction('apostilleProcess')">流程加签
        </Button>
        <Button type="info" class="ivu-mr-8 ivu-mt-8"
                :loading="loading"
                v-if="btnAuth.indexOf('DOCASE_WORKFLOW') !== -1"
                @click="dropdownAction('callBack')">流程回退
        </Button>
        <Button type="info" class="ivu-mr-8 ivu-mt-8"
                :loading="loading"
                @click="dropdownAction('transpond')"
                v-if="btnAuth.indexOf('PROCESS_TRACES') !== -1">流程转发
        </Button>

        <Button type="info" class="ivu-mr-8 ivu-mt-8"
                :loading="loading"
                v-if="btnAuth.indexOf('PROCESS_TRACES') !== -1 && flowData.workflowInstance.approveState === 1"
                @click="dropdownAction('retractWorkflow')">流程回撤
        </Button>
        <Button type="info" class="ivu-mr-8 ivu-mt-8"
                :loading="loading"
                v-if="btnAuth.indexOf('REJECT_WORKFLOW') !== -1"
                @click="dropdownAction('cancelFlow')">流程作废
        </Button>
        <Button type="info" class="ivu-mr-8 ivu-mt-8"
                :loading="loading"
                v-if="btnAuth.indexOf('RESTART_WORKFLOW') !== -1"
                @click="dropdownAction('restartFlow')">流程重启
        </Button>
        <Button type="info" class="ivu-mr-8 ivu-mt-8"
                :loading="loading"
                v-if="btnAuth.indexOf('PROCESS_TRACES') !== -1"
                @click="dropdownAction('checkTracks')">查看痕迹
        </Button>
        <Button type="info" class="ivu-mr-8 ivu-mt-8"
                :loading="loading"
                v-if="btnAuth.indexOf('VIEW_WORKFLOW') !== -1"
                @click="dropdownAction('flowPic')">流程图
        </Button>
      </div>
    </Card>
    <template v-else>
      <div class="toolbar" v-if="isInit">
        <div class="toolbar-inner">
          <Dropdown trigger="click" placement="top"
                    v-if="isActions"
                    @on-click="dropdownAction">
            <Button long>更多操作</Button>
            <DropdownMenu slot="list">
              <DropdownItem name="apostilleProcess" v-if="btnAuth.indexOf('DOCASE_WORKFLOW') !== -1">流程加签</DropdownItem>
              <DropdownItem name="callBack" v-if="btnAuth.indexOf('DOCASE_WORKFLOW') !== -1">流程回退</DropdownItem>
              <DropdownItem name="transpond" v-if="btnAuth.indexOf('PROCESS_TRACES') !== -1">流程转发</DropdownItem>
              <DropdownItem name="retractWorkflow"
                            v-if="btnAuth.indexOf('PROCESS_TRACES') !== -1 && flowData.workflowInstance.approveState === 1">
                流程回撤
              </DropdownItem>
              <DropdownItem name="cancelFlow" v-if="btnAuth.indexOf('REJECT_WORKFLOW') !== -1">流程作废</DropdownItem>
              <DropdownItem name="restartFlow" v-if="btnAuth.indexOf('RESTART_WORKFLOW') !== -1">流程重启</DropdownItem>
              <DropdownItem name="flowPic" v-if="btnAuth.indexOf('VIEW_WORKFLOW') !== -1">流程图</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Button type="primary"
                  v-if="btnAuth.indexOf('PROCESS_TRACES') !== -1"
                  @click="dropdownAction('checkTracks')">查看痕迹</Button>
          <Button type="primary"
                  v-if="btnAuth.indexOf('DOCASE_WORKFLOW') !== -1"
                  @click="dropdownAction('dealProcess')">流程审批</Button>
        </div>
        <div class="safe-area-inset-bottom" />
      </div>
    </template>

    <dealFlow ref="dealFlow"
              @refresh="refresh"
              @getBusinessData="getBusinessData1" />
    <dealApostilleFlow ref="dealApostilleFlow"
                       @refresh="refresh"
                       @getBusinessData="getBusinessData1" />
    <apostilleFlow ref="apostilleFlow"
                   @refresh="refresh"
                   @getBusinessData="getBusinessData1" />
    <callBackFlow ref="callBackFlow"
                  @refresh="refresh"
                  @getBusinessData="getBusinessData1" />
    <transpondFlow ref="transpondFlow"
                   @refresh="refresh"
                   @getBusinessData="getBusinessData1" />
    <checkTracks ref="checkTracks" />
    <flowPic ref="flowPic" />
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import dealFlow from './dealFlow'
  import transpondFlow from './transpondFlow'
  import apostilleFlow from './apostilleFlow'
  import dealApostilleFlow from './dealApostilleFlow'
  import callBackFlow from './callBackFlow'
  import checkTracks from './checkTracks'
  import flowPic from './flowPic'
  import { mapActions } from 'vuex'
  import request from '@/plugins/request'
  import {getFlexValueNameByCode } from '@/plugins/utils'

  export default {
    name: 'FlowTabs',
    components: {
      dealFlow,
      apostilleFlow,
      transpondFlow,
      checkTracks,
      callBackFlow,
      dealApostilleFlow,
      flowPic
    },
    props: {
      //是否显示新增按钮
      showAdd: {
        type: Boolean,
        required: false,
        default: true
      },
      //是否初始触发请求(附件跟主表是否分隔,若附件是放到主表数据里一起提交的,则传false,如果是单独关联提交的,则传true)
      //额外参数(新增时放进数据体内)
      extraParams: {
        type: Object,
        required: false,
        default: () => {
          return {}
        }
      },
      flowPicUrl: {
        type: String,
        required: false,
        default: ''
      }
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),
      getFlexValueNameByCode () {
        return getFlexValueNameByCode
      },
      user () {
        return this.$store.state.admin.user.info
      },
      isActions () {
        const codes = [
          'DOCASE_WORKFLOW', 'DOCASE_WORKFLOW', 'PROCESS_TRACES',
          'PROCESS_TRACES', 'REJECT_WORKFLOW', 'RESTART_WORKFLOW', 'VIEW_WORKFLOW'
        ]

        return codes.some(item => this.btnAuth.includes(item))
      },
      isVisible () {
        const codes = ['PROCESS_TRACES', 'DOCASE_WORKFLOW']

        return codes.some(item => this.btnAuth.includes(item)) && this.isActions
      }
    },
    methods: {
      ...mapActions('storagemanage/flowCenterStore', [
        'processTracesList',
        'tempStorageWorkflow',
        'submitFlowAction',
        'rejectWorkflowAction',
        'restartFlowAction'
      ]),
      setBtnStateFn () {
        this.loading = false
      },
      async init (url, id, isOpen, routeWorkflowId) {
        this.loading = false
        this.clickType = this.$route.query.clickType
        //url流程初始化url,id业务id
        let params = {}
        if (routeWorkflowId) {
          params.billId = id
          params.workflowId = routeWorkflowId
        } else {
          params.billId = id
        }
        return new Promise((resolve, reject) => {
          request({
            url: url,
            method: 'post',
            params: params
          })
            .then(async res => {
              this.flowData = res.entity
              this.flowUrl = this.flowData.workflowInstance
                ? this.flowData.workflowInstance.bizType
                : ''
              this.initAuth(isOpen)
              resolve()
            })
            .catch(err => {
              reject(err)
            })
        })
      },
      initAuth (isOpen) {
        this.btnAuth = []
        if (
          this.flowData.currentWorkitemAuthorizations &&
          this.flowData.currentWorkitemAuthorizations.length > 0
        ) {
          let auths = this.flowData.currentWorkitemAuthorizations[0].accessCodes
          for (let i = 0; i < auths.length; i++) {
            if (
              auths[i].split('@')[0] == 'SUBMIT_WORKFLOW' &&
              auths[i].split('@')[1] == 'true'
            ) {
              this.btnAuth.push('SUBMIT_WORKFLOW')
            }
            if (
              auths[i].split('@')[0] == 'VIEW_WORKFLOW' &&
              auths[i].split('@')[1] == 'true'
            ) {
              this.btnAuth.push('VIEW_WORKFLOW')
            }
            if (
              auths[i].split('@')[0] == 'DOCASE_WORKFLOW' &&
              auths[i].split('@')[1] == 'true'
            ) {
              this.btnAuth.push('DOCASE_WORKFLOW')
              if (isOpen) {
                this.dropdownAction('dealProcess')
              }
            }
            if (
              auths[i].split('@')[0] == 'PROCESS_TRACES' &&
              auths[i].split('@')[1] == 'true'
            ) {
              this.btnAuth.push('PROCESS_TRACES')
            }
            if (
              auths[i].split('@')[0] == 'REJECT_WORKFLOW' &&
              auths[i].split('@')[1] == 'true'
            ) {
              this.btnAuth.push('REJECT_WORKFLOW')
            }
            if (
              auths[i].split('@')[0] == 'RESTART_WORKFLOW' &&
              auths[i].split('@')[1] == 'true'
            ) {
              this.btnAuth.push('RESTART_WORKFLOW')
            }
          }
        }
      },
      getBusinessData1 (cb) {
        this.$emit('getBusinessData', cb)
      },
      dropdownAction (name) {
        switch (name) {
          case 'transpond': //转发
            this.$nextTick(() => {
              //flowData:点击审核后初始化流程表单的数据,flowUrl:发起流程的url,initFlowParams:流程中心传过来的表单数据
              this.$refs['transpondFlow'].init(this.flowData, this.flowUrl)
            })
            break

          case 'callBack': //回退
            this.$nextTick(() => {
              //flowData:点击审核后初始化流程表单的数据,flowUrl:发起流程的url,initFlowParams:流程中心传过来的表单数据
              this.$refs['callBackFlow'].init(this.flowData, this.flowUrl)
            })
            break
          case 'apostilleProcess':
            this.$nextTick(() => {
              //flowData:点击审核后初始化流程表单的数据,flowUrl:发起流程的url,initFlowParams:流程中心传过来的表单数据
              this.$refs['apostilleFlow'].init(this.flowData, this.flowUrl)
            })
            break
          case 'retractWorkflow':
            //流程回撤
            this.retractWorkflow()
            break
          case 'dealProcess':
            if (
              this.flowData.currentWorkitem.workitemId == 'APOSTILLE' &&
              this.flowData.currentWorkitem.workitemName == '加签'
            ) {
              this.$nextTick(() => {
                //flowData:点击审核后初始化流程表单的数据,flowUrl:发起流程的url,initFlowParams:流程中心传过来的表单数据
                this.$refs['dealApostilleFlow'].init(this.flowData, this.flowUrl)
              })
            } else {
              //流程处理
              this.$nextTick(() => {
                //flowData:点击审核后初始化流程表单的数据,flowUrl:发起流程的url,initFlowParams:流程中心传过来的表单数据
                this.$refs['dealFlow'].init(this.flowData, this.flowUrl)
              })
            }
            break
          case 'checkTracks':
            //流程痕迹
            this.$nextTick(() => {
              //flowData:点击审核后初始化流程表单的数据,flowUrl:发起流程的url,initFlowParams:流程中心传过来的表单数据
              this.$refs['checkTracks'].init(
                this.flowData.workflowInstance.workflowInstanceId
              )
            })
            break
          case 'flowPic':
            //流程图
            this.$refs['flowPic'].init(this.flowPicUrl)
            break
          case 'cancelFlow':
            //作废流程
            this.rejectWorkflow()
            break
          default:
            break
        }
      },

      retractWorkflow () {
        this.$Modal.confirm({
          title: '确认',
          content: '请确认是否回撤流程？',
          onOk: async () => {
            try {
              let businessData = null

              this.loading = true
              this.$emit('getBusinessData', function (data) {
                businessData = data
              })
              await request({
                url: this.flowData.workflowInstance.bizType + '/retractWorkflow',
                method: 'post',
                data: businessData,
                params: {
                  workflowInstanceId: this.flowData.currentDraftWorkitemInstance
                    .workflowInstanceId,
                  workitemInstanceId: this.flowData.currentDraftWorkitemInstance
                    .workitemInstanceId
                }
              })

              this.refresh()
              this.$Message.success('流程回撤成功')
            } catch (e) {} finally {
              this.loading = false
            }
          },
        })
      },
      rejectWorkflow () {
        this.$Modal.confirm({
          title: '确认',
          content: '请确认是否作废流程？',
          onOk: async () => {
            try {
              let businessData = null

              this.loading = true
              this.$emit('getBusinessData', function (data) {
                businessData = data
              })
              await this.rejectWorkflowAction({
                bill: businessData,
                workflowInstanceId: this.flowData.workflowInstance
                  .workflowInstanceId,
                bizType: this.flowData.workflowInstance.bizType
              })
              this.$emit('refresh')
              this.$Message.success('作废流程成功')
            } catch (e) {} finally {
              this.loading = false
            }
          },
        })
      },
      restartWorkflow () {
        this.$Modal.confirm({
          title: '确认',
          content: '请确认是否作废流程？',
          onOk: () => {
            return new Promise((resolve, reject) => {
              request({
                url: this.flowData.workflowInstance.bizType + '/restartWorkflow',
                method: 'post',
                data: this.flowData.workflowInstance
              })
                .then(async res => {
                  this.$emit('refresh')
                  resolve()
                })
                .catch(err => {
                  reject(err)
                })
            })
          },
          onCancel: () => {
          }
        })
      },
      refresh () {
        this.$emit('refresh')
      }
    },
    data () {
      return {
        isInit: true,
        loading: true,

        initFlowParams: {}, //初始化流程的参数,从流程中心点击审核放到url上带过来
        flowData: {
          workflowInstance: {},
          currentWorkitemAuthorizations: [
            {
              accessCodes: []
            }
          ]
        }, //初始化流程返回的数据
        flowUrl: '', //发起流程的地址
        clickType: '',
        btnAuth: []
      }
    },
  }
</script>
<style scoped lang="less">
  .flowTabs {
    //   padding: 15px;
    display: block;
    background: #ffffff;
    margin-bottom: 15px;

    .process-btn-area {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .toolbar {
    box-shadow: 0 0 20px 0 rgba(0, 91, 172, .1);
    background-color: #fff;
    position: fixed;
    z-index: 10;
    bottom: 0;
    right: 0;
    left: 0;

    &-inner {
      display: flex;
      padding: 7.5px 15px;
      align-items: center;
      justify-content: space-between;

      > * {
        width: 100%;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      /deep/ .ivu-select-dropdown {
        width: auto !important;
      }
    }
  }
</style>
