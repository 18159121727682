import util from '@/libs/util';
import router from '@/router';
import dayjs from 'dayjs';
import {
	
} from '@api/storagemanage/propertyDelivery';
import {
	Message,
	Notice
} from 'view-design';

import {
	Modal
} from 'view-design';

export default {
		namespaced: true,
		state: {
			// 初始化信息
			initInfo: {
				configItems: {
					'单据状态': []
				}
			},
			//查询条件
			reqParams: {
				houseNum: "",
				custName: "",
				deliveryLeftTime: "",
				deliveryRightTime: "",
				agentName: "",
				telephone: "",
				address: "",
				roomFullName: "",
				isPage: true,
				pageIndex: 1,
				pageSize: 10,
			},
			//查询接口返回的数据
			listDataObj: {
				entities: [],
				total:0,
			},
			//查看明细参数
			detailData:{
				deliverNo:"",
				agentName:"",
				deliverDate:"",
				custName:"",
				telephone:"",
				propertyContactInfo:"",
				propertyFees:"",
				isPaymentFees:"",
				materialFeesRemark:"",
			}
		},
		actions: {
			/**
			 * @description 登录
			 * @param {Object} param context
			 * @param {Object} param userCode {String} 用户账号
			 * @param {Object} param password {String} 密码
			 * @param {Object} param route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
			 */
			getList({
				dispatch,
				state,
			}) {
				return new Promise((resolve, reject) => {
					// // 开始请求登录接口
					// listDataReq(state.reqParams)
					// 	.then(async res => {
					// 		// 结束
					// 		state.listDataObj.entities = res.entities;
					// 		state.listDataObj.total = res.total;
					// 		console.log('res err: ', res);
					// 		console.log('err11: ', state.listDataObj);
					// 		resolve(state.listDataObj);
						
					// 	})
					// 	.catch(err => {
					// 		reject(err);
					// 	})
				})
			},
	
			pageInit({
				state,
				dispatch
			}, info) {
				return new Promise((resolve, reject) => {
					listDataInitReq({})
						.then(async res => {
							state.initInfo = res
							// 结束
							resolve(state.initInfo);
						})
						.catch(err => {
							// console.log('err: ', err);
							reject(err);
						})
				})
			},
		}
}