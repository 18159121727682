/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-05-18T12:09:52.296
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listStockpileIntentionAttachmentInit(data){return request({
url: '/storagemanage/api/stockpileIntentionAttachment/listStockpileIntentionAttachmentInit',
method: 'post',
data:data,
});
}


export function listStockpileIntentionAttachmentByAuthorizations(data){return request({
url: '/storagemanage/api/stockpileIntentionAttachment/listStockpileIntentionAttachmentByAuthorizations',
method: 'post',
data:data,
});
}


export function listStockpileIntentionAttachment(data){return request({
url: '/storagemanage/api/stockpileIntentionAttachment/listStockpileIntentionAttachment',
method: 'post',
data:data,
});
}


export function getStockpileIntentionAttachmentCreateInit(){return request({
url: '/storagemanage/api/stockpileIntentionAttachment/getStockpileIntentionAttachmentCreateInit',
method: 'post',
});
}


export function getStockpileIntentionAttachmentEditInit(params){return request({
url: '/storagemanage/api/stockpileIntentionAttachment/getStockpileIntentionAttachmentEditInit',
method: 'post',
params:params,
});
}


export function getStockpileIntentionAttachmentView(params){return request({
url: '/storagemanage/api/stockpileIntentionAttachment/getStockpileIntentionAttachmentView',
method: 'post',
params:params,
});
}


export function saveStockpileIntentionAttachment(data){return request({
url: '/storagemanage/api/stockpileIntentionAttachment/saveStockpileIntentionAttachment',
method: 'post',
data:data,
});
}


export function deleteStockpileIntentionAttachment(params){return request({
url: '/storagemanage/api/stockpileIntentionAttachment/deleteStockpileIntentionAttachment',
method: 'post',
params:params,
});
}


