/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-20T12:07:15.721
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import util from '@/libs/util';
import router from '@/router';
import {
	saveOrgUserManage,
	getOrgUserManageCreateInit,
	getOrgUserManageEditInit,
	getOrgUserManageView,
	deleteOrgUserManage,
	getOrgTree,
	getStationTree,
} from '@api/systemmanage/OrgUserManage';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
	listAuthFuncRoleManageInit,
	listAuthFuncRoleManageByAuthorizations,
	deleteAuthFuncRoleManage
} from '@api/systemmanage/AuthFuncRoleManage';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
		orgParams: {
			"entity": {
				"bizType01": "",
				"bizType02": "",
				"bizType03": "",
				"bizType04": "",
				"bizType05": "",
				"children": [],
				"deleteState": 0,
				"depth": "",
				"descript": "",
				"establishDate": "",
				"lastUpdateDate": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"orderNo": "",
				"orgAddress": "",
				"orgCode": "",
				"orgId": "",
				"orgLevel": "",
				"orgLongCode": "",
				"orgLongName": "",
				"orgName": "",
				"orgType": "",
				"parentOrgId": "",
				"platformState": "",
				"regionalismId": "",
				"regionalismName": "",
				"saasCompany": "",
				"title": "",
				"value": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": false,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 0,
			"pageSize": 0,
			"selectColumns": [],
			"specificChannel": {}
		},
	},

	actions: {
		getOrgUserManageCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getOrgUserManageCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getOrgUserManageEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getOrgUserManageEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveOrgUserManage({}, OrgUserManage) {
			return new Promise((resolve, reject) => {
				saveOrgUserManage(OrgUserManage)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getOrgUserManageView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getOrgUserManageView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteOrgUserManage({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				deleteOrgUserManage({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getOrgTree({
			state
		}) {
			return new Promise((resolve, reject) => {
				getOrgTree(state.orgParams)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		getStationTree({
			state
		},orgIds) {
			return new Promise((resolve, reject) => {
				getStationTree(orgIds)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
