/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T15:03:08.748
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listAssignedBillItem,
	listAssignedBillItemInit,
	listAssignedBillItemByAuthorizations,
	deleteAssignedBillItem
} from '@api/storagemanage/AssignedBillItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listAssignedBillItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listAssignedBillItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listAssignedBillItemByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listAssignedBillItemByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listAssignedBillItemInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listAssignedBillItemInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteAssignedBillItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteAssignedBillItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
