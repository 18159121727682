/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-10T17:00:05.664
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinancialSettlement,
	listFinancialSettlementInit,
	listFinancialSettlementByAuthorizations,
	deleteFinancialSettlement
	
} from '@api/financial/FinancialSettlement';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFinancialSettlement({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialSettlement(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialSettlementByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialSettlementByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialSettlementInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialSettlementInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialSettlement({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialSettlement({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
