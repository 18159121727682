/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T15:54:15.663
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getRentChannelApplyEditInit,
	getRentChannelApplyView
} from '@api/rentmanage/RentChannelApply';

import {
listRentChannelApplyItemInit,
listRentChannelApplyItemByAuthorizations,
deleteRentChannelApplyItem
} from '@api/rentmanage/RentChannelApplyItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getRentChannelApplyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentChannelApplyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentChannelApplyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentChannelApplyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
