/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:57.782
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listHouseRateInfoInit(data){return request({
url: '/lcf/api/houseRateInfo/listHouseRateInfoInit',
method: 'post',
data:data,
});
}


export function listHouseRateInfoByAuthorizations(data){return request({
url: '/lcf/api/houseRateInfo/listHouseRateInfoByAuthorizations',
method: 'post',
data:data,
});
}


export function listHouseRateInfo(data){return request({
url: '/lcf/api/houseRateInfo/listHouseRateInfo',
method: 'post',
data:data,
});
}


export function getHouseRateInfoCreateInit(){return request({
url: '/lcf/api/houseRateInfo/getHouseRateInfoCreateInit',
method: 'post',
});
}


export function getHouseRateInfoEditInit(params){return request({
url: '/lcf/api/houseRateInfo/getHouseRateInfoEditInit',
method: 'post',
params:params,
});
}


export function getHouseRateInfoView(params){return request({
url: '/lcf/api/houseRateInfo/getHouseRateInfoView',
method: 'post',
params:params,
});
}


export function saveHouseRateInfo(data){return request({
url: '/lcf/api/houseRateInfo/saveHouseRateInfo',
method: 'post',
data:data,
});
}


export function deleteHouseRateInfo(params){return request({
url: '/lcf/api/houseRateInfo/deleteHouseRateInfo',
method: 'post',
params:params,
});
}


