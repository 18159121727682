/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-09T14:50:23.936
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listStockpileIntentionOwnerInit(data){return request({
url: '/storagemanage/api/stockpileIntentionOwner/listStockpileIntentionOwnerInit',
method: 'post',
data:data,
});
}


export function listStockpileIntentionOwnerByAuthorizations(data){return request({
url: '/storagemanage/api/stockpileIntentionOwner/listStockpileIntentionOwnerByAuthorizations',
method: 'post',
data:data,
});
}


export function listStockpileIntentionOwner(data){return request({
url: '/storagemanage/api/stockpileIntentionOwner/listStockpileIntentionOwner',
method: 'post',
data:data,
});
}


export function getStockpileIntentionOwnerCreateInit(){return request({
url: '/storagemanage/api/stockpileIntentionOwner/getStockpileIntentionOwnerCreateInit',
method: 'post',
});
}


export function getStockpileIntentionOwnerEditInit(params){return request({
url: '/storagemanage/api/stockpileIntentionOwner/getStockpileIntentionOwnerEditInit',
method: 'post',
params:params,
});
}


export function getStockpileIntentionOwnerView(params){return request({
url: '/storagemanage/api/stockpileIntentionOwner/getStockpileIntentionOwnerView',
method: 'post',
params:params,
});
}


export function saveStockpileIntentionOwner(data){return request({
url: '/storagemanage/api/stockpileIntentionOwner/saveStockpileIntentionOwner',
method: 'post',
data:data,
});
}


export function deleteStockpileIntentionOwner(params){return request({
url: '/storagemanage/api/stockpileIntentionOwner/deleteStockpileIntentionOwner',
method: 'post',
params:params,
});
}


