/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-12T08:26:50.986
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listRentHouseSalesPromotionItemInit(data) {
	return request({
		url: '/rentmanage/api/rentHouseSalesPromotionItem/listRentHouseSalesPromotionItemInit',
		method: 'post',
		data: data,
	});
}


export function listRentHouseSalesPromotionItemByAuthorizations(data) {
	return request({
		url: '/rentmanage/api/rentHouseSalesPromotionItem/listRentHouseSalesPromotionItemByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listRentHouseSalesPromotionItem(data) {
	return request({
		url: '/rentmanage/api/rentHouseSalesPromotionItem/listRentHouseSalesPromotionItem',
		method: 'post',
		data: data,
	});
}


export function getRentHouseSalesPromotionItemCreateInit() {
	return request({
		url: '/rentmanage/api/rentHouseSalesPromotionItem/getRentHouseSalesPromotionItemCreateInit',
		method: 'post',
	});
}


export function getRentHouseSalesPromotionItemEditInit(params) {
	return request({
		url: '/rentmanage/api/rentHouseSalesPromotionItem/getRentHouseSalesPromotionItemEditInit',
		method: 'post',
		params: params,
	});
}


export function getRentHouseSalesPromotionItemView(params) {
	return request({
		url: '/rentmanage/api/rentHouseSalesPromotionItem/getRentHouseSalesPromotionItemView',
		method: 'post',
		params: params,
	});
}


export function saveRentHouseSalesPromotionItem(data) {
	return request({
		url: '/rentmanage/api/rentHouseSalesPromotionItem/saveRentHouseSalesPromotionItem',
		method: 'post',
		data: data,
	});
}


export function deleteRentHouseSalesPromotionItem(params) {
	return request({
		url: '/rentmanage/api/rentHouseSalesPromotionItem/deleteRentHouseSalesPromotionItem',
		method: 'post',
		params: params,
	});
}


