/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-14T19:43:30.574
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-RentTrafficLine-'

export default 
{
path: '/rentmanage/renttrafficline',
name: 'rentmanage-RentTrafficLine',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}RentTrafficLine-index',

meta:{
...meta,
title: '房源交通线路'
},

component: () => import('@/pages/rentmanage/renttrafficline/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}RentTrafficLine-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源交通线路编辑'
},

component: () => import('@/pages/rentmanage/renttrafficline/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}RentTrafficLinedetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源交通线路新增'
},

component: () => import('@/pages/rentmanage/renttrafficline/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}RentTrafficLineView',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源交通线路详情查看'
},

component: () => import('@/pages/rentmanage/renttrafficline/detail-view') ,
},
]
};
