/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-11T10:54:45.442
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHouseHold,
	getRentHouseHoldCreateInit,
	getRentHouseHoldEditInit,
	getRentHouseHoldView,
	deleteRentHouseHold,
	startWorkflow
} from '@api/rentmanage/RentHouseHold';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentHouseHoldItemInit,
listRentHouseHoldItemByAuthorizations,
deleteRentHouseHoldItem
} from '@api/rentmanage/RentHouseHoldItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentHouseHoldCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseHoldCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		startWorkflow({}, RentHouseHold) {
			return new Promise((resolve, reject) => {
				startWorkflow(RentHouseHold)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentHouseHoldEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseHoldEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseHold({}, RentHouseHold) {
			return new Promise((resolve, reject) => {
				saveRentHouseHold(RentHouseHold)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseHoldView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseHoldView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseHold({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseHold({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
