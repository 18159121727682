/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-22T15:12:16.203
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentPlaceOrderRetreat,
	getRentPlaceOrderRetreatCreateInit,
	getRentPlaceOrderRetreatEditInit,
	getRentPlaceOrderRetreatView,
	deleteRentPlaceOrderRetreat,
	startWorkflow
} from '@api/rentmanage/RentPlaceOrderRetreat';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentPlaceOrderRetreatCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentPlaceOrderRetreatCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentPlaceOrderRetreatEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPlaceOrderRetreatEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		startWorkflow({}, RentPlaceOrderRetreat) {
			return new Promise((resolve, reject) => {
				startWorkflow(RentPlaceOrderRetreat)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		saveRentPlaceOrderRetreat({}, RentPlaceOrderRetreat) {
			return new Promise((resolve, reject) => {
				saveRentPlaceOrderRetreat(RentPlaceOrderRetreat)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentPlaceOrderRetreatView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPlaceOrderRetreatView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentPlaceOrderRetreat({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentPlaceOrderRetreat({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
