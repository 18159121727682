/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T16:23:43.251
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentPropertyDelivery,
	getRentPropertyDeliveryCreateInit,
	getRentPropertyDeliveryEditInit,
	getRentPropertyDeliveryView,
	deleteRentPropertyDelivery
} from '@api/rentmanage/RentPropertyDelivery';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentPropertyDeliveryItemInit,
listRentPropertyDeliveryItemByAuthorizations,
deleteRentPropertyDeliveryItem
} from '@api/rentmanage/RentPropertyDeliveryItem';

import {
listEnergyRecordItemInit,
listEnergyRecordItemByAuthorizations,
deleteEnergyRecordItem
} from '@api/rentmanage/EnergyRecordItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentPropertyDeliveryCreateInit({
			state,
		},{
			referId,
			houseRentId,
			sourceBillType,
			showflag
		}) {
			return new Promise((resolve, reject) => {
				getRentPropertyDeliveryCreateInit({
					referId,
					houseRentId,
					sourceBillType,
					showflag
				})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentPropertyDeliveryEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPropertyDeliveryEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentPropertyDelivery({}, RentPropertyDelivery) {
			return new Promise((resolve, reject) => {
				saveRentPropertyDelivery(RentPropertyDelivery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentPropertyDeliveryView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPropertyDeliveryView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentPropertyDelivery({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentPropertyDelivery({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
