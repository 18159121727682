/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-14T19:43:31.61
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentTrafficLinePlatform,
	listRentTrafficLinePlatformInit,
	listRentTrafficLinePlatformByAuthorizations,
	deleteRentTrafficLinePlatform
} from '@api/rentmanage/RentTrafficLinePlatform';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentTrafficLinePlatform({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTrafficLinePlatform(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentTrafficLinePlatformByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTrafficLinePlatformByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentTrafficLinePlatformInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTrafficLinePlatformInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentTrafficLinePlatform({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentTrafficLinePlatform({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
