/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T19:13:41.297
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentConsumeMaterialCompany,
	getRentConsumeMaterialCompanyCreateInit,
	getRentConsumeMaterialCompanyEditInit,
	getRentConsumeMaterialCompanyView,
	deleteRentConsumeMaterialCompany
} from '@api/rentmanage/RentConsumeMaterialCompany';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentConsumeMaterialCompanyCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentConsumeMaterialCompanyCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentConsumeMaterialCompanyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentConsumeMaterialCompanyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentConsumeMaterialCompany({}, RentConsumeMaterialCompany) {
			return new Promise((resolve, reject) => {
				saveRentConsumeMaterialCompany(RentConsumeMaterialCompany)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentConsumeMaterialCompanyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentConsumeMaterialCompanyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentConsumeMaterialCompany({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentConsumeMaterialCompany({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
