import request from '@/plugins/request';

export function getPdf(data) {
	return request({
		url: '/storagemanage/api/conDeputeContractBackSettlement/getReplyByLetterDetail',
		method: 'get',
		params: data
	});
}

export function confirm(data) {
	return request({
		url: '/storagemanage/api/conDeputeContractBackSettlement/confirmReplyByLetter',
		method: 'get',
		params: data
	});
}
