/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-07-02T16:29:31.205
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getReportFinancialRentContractDetailCollectionMonthEditInit,
	getReportFinancialRentContractDetailCollectionMonthView
} from '@api/report/ReportFinancialRentContractDetailCollectionMonth';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		getReportFinancialRentContractDetailCollectionMonthEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractDetailCollectionMonthEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportFinancialRentContractDetailCollectionMonthView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractDetailCollectionMonthView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
