/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-25T11:06:36.039
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportrentcontractundue-'

export default 
{
path: '/report/reportrentcontractundue',
name: 'report-reportrentcontractundue',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportrentcontractundue-index',

meta:{
...meta,
title: '未到期有效出租合同报表'
},

component: () => import('@/pages/report/reportrentcontractundue/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportrentcontractundue-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '未到期有效出租合同报表编辑'
},

component: () => import('@/pages/report/reportrentcontractundue/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportrentcontractunduedetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '未到期有效出租合同报表新增'
},

component: () => import('@/pages/report/reportrentcontractundue/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportrentcontractundueView',
auth: [
'hidden'
],

meta:{
...meta,
title: '未到期有效出租合同报表详情查看'
},

component: () => import('@/pages/report/reportrentcontractundue/detail-view') ,
},
]
};
