/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-26T10:02:19.908
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentFacilityTest,
	getRentFacilityTestCreateInit,
	getRentFacilityTestEditInit,
	getRentFacilityTestView,
	deleteRentFacilityTest
} from '@api/rentmanage/RentFacilityTest';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentFacilityTestCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentFacilityTestCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentFacilityTestEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentFacilityTestEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentFacilityTest({}, RentFacilityTest) {
			return new Promise((resolve, reject) => {
				saveRentFacilityTest(RentFacilityTest)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentFacilityTestView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentFacilityTestView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentFacilityTest({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentFacilityTest({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
