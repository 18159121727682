/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-09T17:00:10.598
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentRepairFeedbackHouseElectricInit(data){return request({
url: '/rentmanage/api/rentRepairFeedbackHouseElectric/listRentRepairFeedbackHouseElectricInit',
method: 'post',
data:data,
});
}


export function listRentRepairFeedbackHouseElectricByAuthorizations(data){return request({
url: '/rentmanage/api/rentRepairFeedbackHouseElectric/listRentRepairFeedbackHouseElectricByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentRepairFeedbackHouseElectric(data){return request({
url: '/rentmanage/api/rentRepairFeedbackHouseElectric/listRentRepairFeedbackHouseElectric',
method: 'post',
data:data,
});
}


export function getRentRepairFeedbackHouseElectricCreateInit(){return request({
url: '/rentmanage/api/rentRepairFeedbackHouseElectric/getRentRepairFeedbackHouseElectricCreateInit',
method: 'post',
});
}


export function getRentRepairFeedbackHouseElectricEditInit(params){return request({
url: '/rentmanage/api/rentRepairFeedbackHouseElectric/getRentRepairFeedbackHouseElectricEditInit',
method: 'post',
params:params,
});
}


export function getRentRepairFeedbackHouseElectricView(params){return request({
url: '/rentmanage/api/rentRepairFeedbackHouseElectric/getRentRepairFeedbackHouseElectricView',
method: 'post',
params:params,
});
}


export function saveRentRepairFeedbackHouseElectric(data){return request({
url: '/rentmanage/api/rentRepairFeedbackHouseElectric/saveRentRepairFeedbackHouseElectric',
method: 'post',
data:data,
});
}


export function deleteRentRepairFeedbackHouseElectric(params){return request({
url: '/rentmanage/api/rentRepairFeedbackHouseElectric/deleteRentRepairFeedbackHouseElectric',
method: 'post',
params:params,
});
}


