import request from '@/plugins/request';

export function listMenuTreemManageInit(data){return request({
url: '/systemmanage/api/menuTreemManage/listMenuTreemManageInit',
method: 'post',
data:data,
});
}


export function listMenuTreemManageByAuthorizations(data){return request({
url: '/systemmanage/api/menuTreemManage/listMenuTreemManageByAuthorizations',
method: 'post',
data:data,
});
}


export function listMenuTreemManage(data){return request({
url: '/systemmanage/api/menuTreemManage/listMenuTreemManage',
method: 'post',
data:data,
});
}


export function getMenuTreemManageCreateInit(){return request({
url: '/systemmanage/api/menuTreemManage/getMenuTreemManageCreateInit',
method: 'post',
});
}


export function getMenuTreemManageEditInit(params){return request({
url: '/systemmanage/api/menuTreemManage/getMenuTreemManageEditInit',
method: 'post',
params:params,
});
}


export function getMenuTreemManageView(params){return request({
url: '/systemmanage/api/menuTreemManage/getMenuTreemManageView',
method: 'post',
params:params,
});
}


export function saveMenuTreemManage(data){return request({
url: '/systemmanage/api/menuTreemManage/saveMenuTreemManage',
method: 'post',
data:data,
});
}


export function deleteMenuTreemManage(params){return request({
url: '/systemmanage/api/menuTreemManage/deleteMenuTreemManage',
method: 'post',
params:params,
});
}


