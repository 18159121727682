/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-14T15:25:43.677
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listRentLeaseContractInit(data) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/listRentLeaseContractInit',
    method: 'post',
    data: data,
  });
}


export function listRentLeaseContractByAuthorizations(data) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/listRentLeaseContractByAuthorizations',
    method: 'post',
    data: data,
  });
}


export function listRentLeaseContract(data) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/listRentLeaseContract',
    method: 'post',
    data: data,
  });
}


export function getRentLeaseContractCreateInit() {
  return request({
    url: '/rentmanage/api/rentLeaseContract/getRentLeaseContractCreateInit',
    method: 'post',
  });
}


export function getRentLeaseContractEditInit(params) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/getRentLeaseContractEditInit',
    method: 'post',
    params: params,
  });
}


export function getRentLeaseContractView(params) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/getRentLeaseContractView',
    method: 'post',
    params: params,
  });
}


export function saveRentLeaseContract(data) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/saveRentLeaseContract',
    method: 'post',
    data: data,
  });
}


export function deleteRentLeaseContract(params) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/deleteRentLeaseContract',
    method: 'post',
    params: params,
  });
}

export function sendSign(data) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/sendSigningLink',
    method: 'post',
    params: data
  });
}

export function confirmSigning(data) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/confirmSigning',
    method: 'post',
    params: data
  });
}

export function downloadLeasePlan(data) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/downloadLeasePlan',
    method: 'post',
    data: data,
    responseType: 'blob'
  });
}

export function calculationEndDate(params) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/calculationEndDate',
    method: 'get',
    params: params,
  });
}

export function createTenantList(params) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/createTenantList',
    method: 'get',
    params: params,
  });
}

export function createMakeDecisionTenantList(params) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/createMakeDecisionTenantList',
    method: 'get',
    params: params,
  });
}

export function createAgreementList(params) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/createAgreementList',
    method: 'get',
    params: params,
  });
}

export function cancellation(data) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/cancellation',
    method: 'post',
    params: data
  });
}

export function regenerateContract(data) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/regenerateContract',
    method: 'post',
    params: data
  });
}

export function changeSignature(params) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/changeSignature',
    method: 'get',
    params: params,
  });
}

export function restoreChangeSignature(params) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/restoreChangeSignature',
    method: 'get',
    params: params,
  });
}
// 修改合同结束日期
export function createPayPlan(params) {
  return request({
    url: '/rentmanage/api/rentLeaseContract/createPayPlan',
    method: 'get',
    params: params,
  });
}


