/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-16T15:24:16.91
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getRentTakeLookHouseEditInit,
	getRentTakeLookHouseView
} from '@api/rentmanage/RentTakeLookHouse';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},

	actions: {
		getRentTakeLookHouseEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentTakeLookHouseEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentTakeLookHouseView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentTakeLookHouseView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
