/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T09:30:03.277
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialPayablesInit(data){return request({
url: '/financial/api/financialPayables/listFinancialPayablesInit',
method: 'post',
data:data,
});
}


export function listFinancialPayablesByAuthorizations(data){return request({
url: '/financial/api/financialPayables/listFinancialPayablesByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialPayables(data){return request({
url: '/financial/api/financialPayables/listFinancialPayables',
method: 'post',
data:data,
});
}


export function getFinancialPayablesCreateInit(){return request({
url: '/financial/api/financialPayables/getFinancialPayablesCreateInit',
method: 'post',
});
}


export function getFinancialPayablesEditInit(params){return request({
url: '/financial/api/financialPayables/getFinancialPayablesEditInit',
method: 'post',
params:params,
});
}


export function getFinancialPayablesView(params){return request({
url: '/financial/api/financialPayables/getFinancialPayablesView',
method: 'post',
params:params,
});
}


export function saveFinancialPayables(data){return request({
url: '/financial/api/financialPayables/saveFinancialPayables',
method: 'post',
data:data,
});
}


export function deleteFinancialPayables(params){return request({
url: '/financial/api/financialPayables/deleteFinancialPayables',
method: 'post',
params:params,
});
}


