/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-10-13T17:19:55.612
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentSerComplaintEvaluate,
	getRentSerComplaintEvaluateCreateInit,
	getRentSerComplaintEvaluateEditInit,
	getRentSerComplaintEvaluateView,
	deleteRentSerComplaintEvaluate
} from '@api/rentmanage/RentSerComplaintEvaluate';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentSerComplaintEvaluateCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentSerComplaintEvaluateCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentSerComplaintEvaluateEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentSerComplaintEvaluateEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentSerComplaintEvaluate({}, RentSerComplaintEvaluate) {
			return new Promise((resolve, reject) => {
				saveRentSerComplaintEvaluate(RentSerComplaintEvaluate)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentSerComplaintEvaluateView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentSerComplaintEvaluateView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentSerComplaintEvaluate({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentSerComplaintEvaluate({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
