/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-06-23T10:15:33.282
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listReportRentHouseBigItem,
	listReportRentHouseBigItemInit,
	listReportRentHouseBigItemByAuthorizations,
	deleteReportRentHouseBigItem
	
} from '@api/rentmanage/ReportRentHouseBigItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listReportRentHouseBigItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportRentHouseBigItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportRentHouseBigItemByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportRentHouseBigItemByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportRentHouseBigItemInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportRentHouseBigItemInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteReportRentHouseBigItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportRentHouseBigItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
