/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-30T17:33:20.313
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportCardUseCount,
	getReportCardUseCountCreateInit,
	getReportCardUseCountEditInit,
	getReportCardUseCountView,
	deleteReportCardUseCount
} from '@api/report/ReportCardUseCount';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportCardUseCountCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportCardUseCountCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportCardUseCountEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportCardUseCountEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportCardUseCount({}, ReportCardUseCount) {
			return new Promise((resolve, reject) => {
				saveReportCardUseCount(ReportCardUseCount)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportCardUseCountView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportCardUseCountView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportCardUseCount({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportCardUseCount({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
