/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-11T09:27:30.208
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialStorageContractPlanInit(data){return request({
url: '/report/api/reportFinancialStorageContractPlan/listReportFinancialStorageContractPlanInit',
method: 'post',
data:data,
});
}


export function listReportFinancialStorageContractPlanByAuthorizations(data){return request({
url: '/report/api/reportFinancialStorageContractPlan/listReportFinancialStorageContractPlanByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportFinancialStorageContractPlan(data){return request({
url: '/report/api/reportFinancialStorageContractPlan/listReportFinancialStorageContractPlan',
method: 'post',
data:data,
});
}


export function getReportFinancialStorageContractPlanCreateInit(){return request({
url: '/report/api/reportFinancialStorageContractPlan/getReportFinancialStorageContractPlanCreateInit',
method: 'post',
});
}


export function getReportFinancialStorageContractPlanEditInit(params){return request({
url: '/report/api/reportFinancialStorageContractPlan/getReportFinancialStorageContractPlanEditInit',
method: 'post',
params:params,
});
}


export function getReportFinancialStorageContractPlanView(params){return request({
url: '/report/api/reportFinancialStorageContractPlan/getReportFinancialStorageContractPlanView',
method: 'post',
params:params,
});
}


export function saveReportFinancialStorageContractPlan(data){return request({
url: '/report/api/reportFinancialStorageContractPlan/saveReportFinancialStorageContractPlan',
method: 'post',
data:data,
});
}


export function deleteReportFinancialStorageContractPlan(params){return request({
url: '/report/api/reportFinancialStorageContractPlan/deleteReportFinancialStorageContractPlan',
method: 'post',
params:params,
});
}


