/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-05-17T10:46:25.406
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listEvaluateAppointmentKeeprecord,
	listEvaluateAppointmentKeeprecordInit,
	listEvaluateAppointmentKeeprecordByAuthorizations,
	deleteEvaluateAppointmentKeeprecord
	
} from '@api/storagemanage/EvaluateAppointmentKeeprecord';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listEvaluateAppointmentKeeprecord({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateAppointmentKeeprecord(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateAppointmentKeeprecordByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateAppointmentKeeprecordByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listEvaluateAppointmentKeeprecordInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listEvaluateAppointmentKeeprecordInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateAppointmentKeeprecord({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateAppointmentKeeprecord({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
