/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-11T09:45:26.441
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportFinancialRentContractItemPayment,
	getReportFinancialRentContractItemPaymentCreateInit,
	getReportFinancialRentContractItemPaymentEditInit,
	getReportFinancialRentContractItemPaymentView,
	deleteReportFinancialRentContractItemPayment
} from '@api/report/ReportFinancialRentContractItemPayment';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportFinancialRentContractItemPaymentCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemPaymentCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportFinancialRentContractItemPaymentEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemPaymentEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportFinancialRentContractItemPayment({}, ReportFinancialRentContractItemPayment) {
			return new Promise((resolve, reject) => {
				saveReportFinancialRentContractItemPayment(ReportFinancialRentContractItemPayment)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportFinancialRentContractItemPaymentView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemPaymentView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportFinancialRentContractItemPayment({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportFinancialRentContractItemPayment({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
