import util from '@/libs/util';
import router from '@/router';
import {
	listConAttachment,
	listConAttachmentInit,
	listConAttachmentByAuthorizations,
	deleteConAttachment
	
} from '@api/storagemanage/ConAttachment';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),

	},
	actions: {
		listConAttachment({
			state
		}) {
			return new Promise((resolve, reject) => {
				listConAttachment(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);

					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listConAttachmentByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listConAttachmentByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listConAttachmentInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listConAttachmentInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteConAttachment({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteConAttachment({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
