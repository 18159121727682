/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-06-23T10:15:33.285
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportRentHouseBigItem,
	getReportRentHouseBigItemCreateInit,
	getReportRentHouseBigItemEditInit,
	getReportRentHouseBigItemView,
	deleteReportRentHouseBigItem
} from '@api/rentmanage/ReportRentHouseBigItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportRentHouseBigItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportRentHouseBigItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportRentHouseBigItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportRentHouseBigItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportRentHouseBigItem({}, ReportRentHouseBigItem) {
			return new Promise((resolve, reject) => {
				saveReportRentHouseBigItem(ReportRentHouseBigItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportRentHouseBigItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportRentHouseBigItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportRentHouseBigItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportRentHouseBigItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
