/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-21T17:44:56.319
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportWorkflowItemCountInit(data){return request({
url: '/report/api/reportWorkflowItemCount/listReportWorkflowItemCountInit',
method: 'post',
data:data,
});
}


export function listReportWorkflowItemCountByAuthorizations(data){return request({
url: '/report/api/reportWorkflowItemCount/listReportWorkflowItemCountByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportWorkflowItemCount(data){return request({
url: '/report/api/reportWorkflowItemCount/listReportWorkflowItemCount',
method: 'post',
data:data,
});
}


export function getReportWorkflowItemCountCreateInit(){return request({
url: '/report/api/reportWorkflowItemCount/getReportWorkflowItemCountCreateInit',
method: 'post',
});
}


export function getReportWorkflowItemCountEditInit(params){return request({
url: '/report/api/reportWorkflowItemCount/getReportWorkflowItemCountEditInit',
method: 'post',
params:params,
});
}


export function getReportWorkflowItemCountView(params){return request({
url: '/report/api/reportWorkflowItemCount/getReportWorkflowItemCountView',
method: 'post',
params:params,
});
}


export function saveReportWorkflowItemCount(data){return request({
url: '/report/api/reportWorkflowItemCount/saveReportWorkflowItemCount',
method: 'post',
data:data,
});
}


export function deleteReportWorkflowItemCount(params){return request({
url: '/report/api/reportWorkflowItemCount/deleteReportWorkflowItemCount',
method: 'post',
params:params,
});
}


