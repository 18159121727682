/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T17:27:30.241
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getRentPurchaseOrderEditInit,
	getRentPurchaseOrderView
} from '@api/rentmanage/RentPurchaseOrder';

import {
listRentPurchaseOrderDetailedInit,
listRentPurchaseOrderDetailedByAuthorizations,
deleteRentPurchaseOrderDetailed
} from '@api/rentmanage/RentPurchaseOrderDetailed';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getRentPurchaseOrderEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPurchaseOrderEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentPurchaseOrderView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPurchaseOrderView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
