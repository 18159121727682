/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-09T18:45:59.603
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportrentcontractrequestrelet-'

export default 
{
path: '/report/reportrentcontractrequestrelet',
name: 'report-reportrentcontractrequestrelet',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportrentcontractrequestrelet-index',

meta:{
...meta,
title: '运营管理-续租'
},

component: () => import('@/pages/report/reportrentcontractrequestrelet/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportrentcontractrequestrelet-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '运营管理-续租编辑'
},

component: () => import('@/pages/report/reportrentcontractrequestrelet/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportrentcontractrequestreletdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '运营管理-续租新增'
},

component: () => import('@/pages/report/reportrentcontractrequestrelet/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportrentcontractrequestreletView',
auth: [
'hidden'
],

meta:{
...meta,
title: '运营管理-续租详情查看'
},

component: () => import('@/pages/report/reportrentcontractrequestrelet/detail-view') ,
},
]
};
