/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-24T16:23:29.438
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinancialPayment,
	listFinancialPaymentInit,
	listFinancialPaymentByAuthorizations,
	deleteFinancialPayment,
	financialPaymentStateChange
	
} from '@api/financial/FinancialPayment';

import {
	paymentFinancialCollection
} from '@api/financial/FinancialCollection';


import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFinancialPayment({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialPayment(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialPaymentByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialPaymentByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialPaymentInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialPaymentInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialPayment({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialPayment({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		financialPaymentStateChange({
			state
		}, {id,payment_state}) {
			return new Promise((resolve, reject) => {
				financialPaymentStateChange({
						id,payment_state
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		paymentFinancialCollection({}, FinancialCollection) {
			return new Promise((resolve, reject) => {
				paymentFinancialCollection(FinancialCollection)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
