import request from '@/plugins/request';

export function pageInit(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/listReportReturnAssetsInit',
		method: 'post',
		data: data
	});
}

export function pageInitHistory(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/listHisReportReturnAssetsInit',
		method: 'post',
		data: data
	});
}

export function GetDataList(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/listReportReturnAssetsByAuthorizations',
		method: 'post',
		data: data
	});
}

export function deleteProfit(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/cancelAssetearningReport',
		method: 'post',
		params: data
	});
}

export function restartProfit(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/reissuedAssetearningReport',
		method: 'post',
		params: data
	});
}

export function startSignProfit(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/initiateSign',
		method: 'post',
		params: data
	});
}

export function getListDataHistory(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/listHisReportReturnAssets',
		method: 'post',
		data: data
	});
}

export function addPageInit(data) {
	return request({
		url: '/storagemanage/api/companyManage/getCompanyManageCreateInit',
		method: 'post',
		data: data
	});
}

export function getArea(data) {
	return request({
		url: '/storagemanage/api/area/getAreaSimpleList',
		method: 'post',
		data: data
	});
}

export function getAreaById(data) {
	return request({
		url: '/storagemanage/api/area/listArea',
		method: 'post',
		data: data
	});
}

export function AddOrEditData(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/saveReportReturnAssets',
		method: 'post',
		data: data
	});
}

export function editPageInitReq(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/getReportReturnAssetsEditInit',
		method: 'post',
		params: data
	});
}

export function getEvaluteDetail(data) {
	return request({
		url: '/storagemanage/api/evaluateBill/findEvaluateBillVoByAssetsId',
		method: 'post',
		params: data
	});
}

export function submitHouseRateReq(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/calculationHouseLevel',
		method: 'post',
		data: data
	});
}

export function calculateReq(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/calculationAssetearningReport',
		method: 'post',
		data: data
	});
}

export function getProfitDetail(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/getReportReturnAssetsRusltItem',
		method: 'post',
		params: data
	});
}

export function deteleRecordReq(data) {
	return request({
		url: '/datadictionarymanage/api/configtreeManage/deleteConfigtreeManage',
		method: 'post',
		params: data
	});
}

export function getFirstYearRent(data) {
	return request({
		url: '/storagemanage/api/reportReturnAssets/calculationFirstYearDepositRent',
		method: 'post',
		data: data
	});
}
