/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-01T17:31:37.048
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'financial-FinancialAdjustmentApplyItem-'

export default 
{
path: '/financial/financialadjustmentapplyitem',
name: 'financial-FinancialAdjustmentApplyItem',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}FinancialAdjustmentApplyItem-index',

meta:{
...meta,
title: '财务调整申请单'
},

component: () => import('@/pages/financial/financialadjustmentapplyitem/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}FinancialAdjustmentApplyItem-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '财务调整申请单编辑'
},

component: () => import('@/pages/financial/financialadjustmentapplyitem/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}FinancialAdjustmentApplyItemdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '财务调整申请单新增'
},

component: () => import('@/pages/financial/financialadjustmentapplyitem/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}FinancialAdjustmentApplyItemView',
auth: [
'hidden'
],

meta:{
...meta,
title: '财务调整申请单详情查看'
},

component: () => import('@/pages/financial/financialadjustmentapplyitem/detail-view') ,
},
]
};
