/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-08T19:32:01.805
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentLeaseContractChangeRequest,
	listRentLeaseContractChangeRequestInit,
	listRentLeaseContractChangeRequestByAuthorizations,
	deleteRentLeaseContractChangeRequest,
    generateLeaseSettlement,
    toAcceptChangeRequest,
	withdraw
	
} from '@api/rentmanage/RentLeaseContractChangeRequest';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentLeaseContractChangeRequest({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentLeaseContractChangeRequest(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentLeaseContractChangeRequestByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentLeaseContractChangeRequestByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentLeaseContractChangeRequestInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentLeaseContractChangeRequestInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
                        state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentLeaseContractChangeRequest({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentLeaseContractChangeRequest({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
        
        generateLeaseSettlement({
        	state
        }, {
        	id
        }) {
        	return new Promise((resolve, reject) => {
        		generateLeaseSettlement({
        				id
        			})
        			.then(async res => {
        				state.CompoundEntityDictionary = res;
        				resolve(res.entityResponse);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
        
        toAcceptChangeRequest({
        	state
        }, data) {
        	return new Promise((resolve, reject) => {
        		toAcceptChangeRequest(data)
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
		
		withdraw({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				withdraw({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
