/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-15T16:02:24.134
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportrenthousebig-'

export default 
{
path: '/report/reportrenthousebig',
name: 'report-reportrenthousebig',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportrenthousebig-index',

meta:{
...meta,
title: '房态报表'
},

component: () => import('@/pages/report/reportrenthousebig/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportrenthousebig-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '房态报表编辑'
},

component: () => import('@/pages/report/reportrenthousebig/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportrenthousebigdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '房态报表新增'
},

component: () => import('@/pages/report/reportrenthousebig/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportrenthousebigView',
auth: [
'hidden'
],

meta:{
...meta,
title: '房态报表详情查看'
},

component: () => import('@/pages/report/reportrenthousebig/detail-view') ,
},
]
};
