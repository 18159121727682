<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  import { on, off } from 'view-design/src/utils/dom'
  import { setMatchMedia } from 'view-design/src/utils/assist'

  import { mapMutations } from 'vuex'

  setMatchMedia()

  export default {
    name: 'App',
    methods: {
      ...mapMutations('admin/layout', ['setDevice']),
      handleWindowResize () {
        this.handleMatchMedia()
      },
      handleMatchMedia () {
        const matchMedia = window.matchMedia

        if (matchMedia('(max-width: 600px)').matches) {
          this.setDevice('Mobile')
        } else if (matchMedia('(max-width: 992px)').matches) {
          this.setDevice('Tablet')
        } else {
          this.setDevice('Desktop')
        }
      },
    },
    mounted () {
      on(window, 'resize', this.handleWindowResize)
      this.handleMatchMedia()
    },
    beforeDestroy () {
      off(window, 'resize', this.handleWindowResize)
    },
  }
</script>

<style lang="less">
  // 安全区
  .safe-area-inset-bottom {
    padding-bottom: 0;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .safe-area-inset-top {
    padding-top: 0;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }

  // 文本颜色
  .ivu-text-primary {
    color: #2d8cf0;
  }

  .ivu-text-success {
    color: #19be6b;
  }

  .ivu-text-warning {
    color: #ff9900;
  }

  .ivu-text-error {
    color: #ed4014;
  }

  .ivu-text-info {
    color: #2db7f5;
  }
</style>
