/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-06T11:29:37.909
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportFinancialRentContractItemUnfinished,
	getReportFinancialRentContractItemUnfinishedCreateInit,
	getReportFinancialRentContractItemUnfinishedEditInit,
	getReportFinancialRentContractItemUnfinishedView,
	deleteReportFinancialRentContractItemUnfinished
} from '@api/report/ReportFinancialRentContractItemUnfinished';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportFinancialRentContractItemUnfinishedCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemUnfinishedCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportFinancialRentContractItemUnfinishedEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemUnfinishedEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportFinancialRentContractItemUnfinished({}, ReportFinancialRentContractItemUnfinished) {
			return new Promise((resolve, reject) => {
				saveReportFinancialRentContractItemUnfinished(ReportFinancialRentContractItemUnfinished)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportFinancialRentContractItemUnfinishedView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemUnfinishedView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportFinancialRentContractItemUnfinished({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportFinancialRentContractItemUnfinished({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
