/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-14T15:25:43.975
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentLeaseContractTenant,
	getRentLeaseContractTenantCreateInit,
	getRentLeaseContractTenantEditInit,
	getRentLeaseContractTenantEditInit2,
	getRentLeaseContractTenantView,
	deleteRentLeaseContractTenant,
} from '@api/rentmanage/RentLeaseContractTenant';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentLeaseContractTenantCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractTenantCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentLeaseContractTenantEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractTenantEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentLeaseContractTenantEditInit2({
			state
		}, id) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractTenantEditInit2({
						id: id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentLeaseContractTenant({}, RentLeaseContractTenant) {
			return new Promise((resolve, reject) => {
				saveRentLeaseContractTenant(RentLeaseContractTenant)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentLeaseContractTenantView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractTenantView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentLeaseContractTenant({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				deleteRentLeaseContractTenant({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
