import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'systemmanage-menutreemmanage-'

export default 
{
path: '/systemmanage/menutreemmanage',
name: 'systemmanage-menutreemmanage',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}menutreemmanage-index',

meta:{
...meta,
title: '动态菜单'
},

component: () => import('@/pages/systemmanage/menutreemmanage/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}menutreemmanage-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '动态菜单编辑'
},

component: () => import('@/pages/systemmanage/menutreemmanage/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}menutreemmanagedetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '动态菜单新增'
},

component: () => import('@/pages/systemmanage/menutreemmanage/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}menutreemmanageView',
auth: [
'hidden'
],

meta:{
...meta,
title: '动态菜单详情查看'
},

component: () => import('@/pages/systemmanage/menutreemmanage/detail-view') ,
},
]
};
