/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-05-18T12:09:52.312
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveStockpileIntentionAttachment,
	getStockpileIntentionAttachmentCreateInit,
	getStockpileIntentionAttachmentEditInit,
	getStockpileIntentionAttachmentView,
	deleteStockpileIntentionAttachment
} from '@api/storagemanage/StockpileIntentionAttachment';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getStockpileIntentionAttachmentCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getStockpileIntentionAttachmentCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getStockpileIntentionAttachmentEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getStockpileIntentionAttachmentEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveStockpileIntentionAttachment({}, StockpileIntentionAttachment) {
			return new Promise((resolve, reject) => {
				saveStockpileIntentionAttachment(StockpileIntentionAttachment)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getStockpileIntentionAttachmentView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getStockpileIntentionAttachmentView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteStockpileIntentionAttachment({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteStockpileIntentionAttachment({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
