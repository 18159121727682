/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:58.047
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listEvaluationRentInit(data){return request({
url: '/lcf/api/evaluationRent/listEvaluationRentInit',
method: 'post',
data:data,
});
}


export function listEvaluationRentByAuthorizations(data){return request({
url: '/lcf/api/evaluationRent/listEvaluationRentByAuthorizations',
method: 'post',
data:data,
});
}


export function listEvaluationRent(data){return request({
url: '/lcf/api/evaluationRent/listEvaluationRent',
method: 'post',
data:data,
});
}


export function getEvaluationRentCreateInit(){return request({
url: '/lcf/api/evaluationRent/getEvaluationRentCreateInit',
method: 'post',
});
}


export function getEvaluationRentEditInit(params){return request({
url: '/lcf/api/evaluationRent/getEvaluationRentEditInit',
method: 'post',
params:params,
});
}


export function getEvaluationRentView(params){return request({
url: '/lcf/api/evaluationRent/getEvaluationRentView',
method: 'post',
params:params,
});
}


export function saveEvaluationRent(data){return request({
url: '/lcf/api/evaluationRent/saveEvaluationRent',
method: 'post',
data:data,
});
}


export function deleteEvaluationRent(params){return request({
url: '/lcf/api/evaluationRent/deleteEvaluationRent',
method: 'post',
params:params,
});
}


