/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-03T09:53:25.223
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentRepairWorkOrderDetailsInit(data){return request({
url: '/rentmanage/api/rentRepairWorkOrderDetails/listRentRepairWorkOrderDetailsInit',
method: 'post',
data:data,
});
}


export function listRentRepairWorkOrderDetailsByAuthorizations(data){return request({
url: '/rentmanage/api/rentRepairWorkOrderDetails/listRentRepairWorkOrderDetailsByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentRepairWorkOrderDetails(data){return request({
url: '/rentmanage/api/rentRepairWorkOrderDetails/listRentRepairWorkOrderDetails',
method: 'post',
data:data,
});
}


export function getRentRepairWorkOrderDetailsCreateInit(){return request({
url: '/rentmanage/api/rentRepairWorkOrderDetails/getRentRepairWorkOrderDetailsCreateInit',
method: 'post',
});
}


export function getRentRepairWorkOrderDetailsEditInit(params){return request({
url: '/rentmanage/api/rentRepairWorkOrderDetails/getRentRepairWorkOrderDetailsEditInit',
method: 'post',
params:params,
});
}


export function getRentRepairWorkOrderDetailsView(params){return request({
url: '/rentmanage/api/rentRepairWorkOrderDetails/getRentRepairWorkOrderDetailsView',
method: 'post',
params:params,
});
}


export function saveRentRepairWorkOrderDetails(data){return request({
url: '/rentmanage/api/rentRepairWorkOrderDetails/saveRentRepairWorkOrderDetails',
method: 'post',
data:data,
});
}


export function deleteRentRepairWorkOrderDetails(params){return request({
url: '/rentmanage/api/rentRepairWorkOrderDetails/deleteRentRepairWorkOrderDetails',
method: 'post',
params:params,
});
}


