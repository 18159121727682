/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-07T10:19:45.933
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'house-CCBFinBill-'

export default 
{
path: '/house/ccbfinbill',
name: 'house-CCBFinBill',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}CCBFinBill-index',

meta:{
...meta,
title: '帐单'
},

component: () => import('@/pages/house/ccbfinbill/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}CCBFinBill-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '帐单编辑'
},

component: () => import('@/pages/house/ccbfinbill/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}CCBFinBilldetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '帐单新增'
},

component: () => import('@/pages/house/ccbfinbill/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}CCBFinBillView',
auth: [
'hidden'
],

meta:{
...meta,
title: '帐单详情查看'
},

component: () => import('@/pages/house/ccbfinbill/detail-view') ,
},
]
};
