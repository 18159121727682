/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-30T17:33:20.28
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportCardUseCountInit(data){return request({
url: '/report/api/reportCardUseCount/listReportCardUseCountInit',
method: 'post',
data:data,
});
}


export function listReportCardUseCountByAuthorizations(data){return request({
url: '/report/api/reportCardUseCount/listReportCardUseCountByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportCardUseCount(data){return request({
url: '/report/api/reportCardUseCount/listReportCardUseCount',
method: 'post',
data:data,
});
}


export function getReportCardUseCountCreateInit(){return request({
url: '/report/api/reportCardUseCount/getReportCardUseCountCreateInit',
method: 'post',
});
}


export function getReportCardUseCountEditInit(params){return request({
url: '/report/api/reportCardUseCount/getReportCardUseCountEditInit',
method: 'post',
params:params,
});
}


export function getReportCardUseCountView(params){return request({
url: '/report/api/reportCardUseCount/getReportCardUseCountView',
method: 'post',
params:params,
});
}


export function saveReportCardUseCount(data){return request({
url: '/report/api/reportCardUseCount/saveReportCardUseCount',
method: 'post',
data:data,
});
}


export function deleteReportCardUseCount(params){return request({
url: '/report/api/reportCardUseCount/deleteReportCardUseCount',
method: 'post',
params:params,
});
}


