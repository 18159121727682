/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-04-25T22:13:40.003
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveHourseEntrust,
	getHourseEntrustCreateInit,
	getHourseEntrustEditInit,
	getHourseEntrustView,
	deleteHourseEntrust
} from '@api/storagemanage/HourseEntrust';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getHourseEntrustCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getHourseEntrustCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHourseEntrustEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHourseEntrustEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveHourseEntrust({}, HourseEntrust) {
			return new Promise((resolve, reject) => {
				saveHourseEntrust(HourseEntrust)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getHourseEntrustView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHourseEntrustView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteHourseEntrust({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteHourseEntrust({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
