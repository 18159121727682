/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-15T11:07:06.851
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listRentHouseSalesCardInit(data) {
	return request({
		url: '/rentmanage/api/rentHouseSalesCard/listRentHouseSalesCardInit',
		method: 'post',
		data: data,
	});
}


export function listRentHouseSalesCardByAuthorizations(data) {
	return request({
		url: '/rentmanage/api/rentHouseSalesCard/listRentHouseSalesCardByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listRentHouseSalesCard(data) {
	return request({
		url: '/rentmanage/api/rentHouseSalesCard/listRentHouseSalesCard',
		method: 'post',
		data: data,
	});
}


export function getRentHouseSalesCardCreateInit() {
	return request({
		url: '/rentmanage/api/rentHouseSalesCard/getRentHouseSalesCardCreateInit',
		method: 'post',
	});
}


export function getRentHouseSalesCardEditInit(params) {
	return request({
		url: '/rentmanage/api/rentHouseSalesCard/getRentHouseSalesCardEditInit',
		method: 'post',
		params: params,
	});
}


export function getRentHouseSalesCardView(params) {
	return request({
		url: '/rentmanage/api/rentHouseSalesCard/getRentHouseSalesCardView',
		method: 'post',
		params: params,
	});
}


export function saveRentHouseSalesCard(data) {
	return request({
		url: '/rentmanage/api/rentHouseSalesCard/saveRentHouseSalesCard',
		method: 'post',
		data: data,
	});
}


export function deleteRentHouseSalesCard(params) {
	return request({
		url: '/rentmanage/api/rentHouseSalesCard/deleteRentHouseSalesCard',
		method: 'post',
		params: params,
	});
}

export function getRentList(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContract/listRentLeaseContractByAuthorizations',
		method: 'post',
		data: params,
	});
}

export function saveRent(params) {
	return request({
		url: '/rentmanage/api/rentHouseSalesCard/allocateRentHouseSalesCard',
		method: 'post',
		data: params,
	});
}
export function handleDownLoadUnallocatedCards(params) {
	return request({
		url: '/rentmanage/api/rentHouseSalesCard/handleDownLoadUnallocatedCards',
		method: 'post',
		params:params,
		responseType: 'blob'
	});
}
export function withdrawCard(params) {
	return request({
		url: '/rentmanage/api/rentHouseSalesCard/backRentHouseSalesCard',
		method: 'post',
		data: params,
	});
}