/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-01T17:09:50.407
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialAdjustmentApplyInit(data){return request({
url: '/financial/api/financialAdjustmentApply/listFinancialAdjustmentApplyInit',
method: 'post',
data:data,
});
}


export function listFinancialAdjustmentApplyByAuthorizations(data){return request({
url: '/financial/api/financialAdjustmentApply/listFinancialAdjustmentApplyByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialAdjustmentApply(data){return request({
url: '/financial/api/financialAdjustmentApply/listFinancialAdjustmentApply',
method: 'post',
data:data,
});
}


export function getFinancialAdjustmentApplyCreateInit(){return request({
url: '/financial/api/financialAdjustmentApply/getFinancialAdjustmentApplyCreateInit',
method: 'post',
});
}


export function getFinancialAdjustmentApplyEditInit(params){return request({
url: '/financial/api/financialAdjustmentApply/getFinancialAdjustmentApplyEditInit',
method: 'post',
params:params,
});
}


export function getFinancialAdjustmentApplyView(params){return request({
url: '/financial/api/financialAdjustmentApply/getFinancialAdjustmentApplyView',
method: 'post',
params:params,
});
}


export function saveFinancialAdjustmentApply(data){return request({
url: '/financial/api/financialAdjustmentApply/saveFinancialAdjustmentApply',
method: 'post',
data:data,
});
}


export function deleteFinancialAdjustmentApply(params){return request({
url: '/financial/api/financialAdjustmentApply/deleteFinancialAdjustmentApply',
method: 'post',
params:params,
});
}


