/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-03T09:53:25.105
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-RentRepairWorkOrder-'

export default 
{
path: '/rentmanage/rentrepairworkorder',
name: 'rentmanage-RentRepairWorkOrder',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}RentRepairWorkOrder-index',

meta:{
...meta,
title: '工单管理'
},

component: () => import('@/pages/rentmanage/rentrepairworkorder/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}RentRepairWorkOrder-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '工单管理编辑'
},

component: () => import('@/pages/rentmanage/rentrepairworkorder/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}RentRepairWorkOrderdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '工单管理新增'
},

component: () => import('@/pages/rentmanage/rentrepairworkorder/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}RentRepairWorkOrderView',
auth: [
'hidden'
],

meta:{
...meta,
title: '工单管理详情查看'
},

component: () => import('@/pages/rentmanage/rentrepairworkorder/detail-view') ,
},
]
};
