/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:57.985
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinalInterviewInit(data){return request({
url: '/lcf/api/finalInterview/listFinalInterviewInit',
method: 'post',
data:data,
});
}


export function listFinalInterviewByAuthorizations(data){return request({
url: '/lcf/api/finalInterview/listFinalInterviewByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinalInterview(data){return request({
url: '/lcf/api/finalInterview/listFinalInterview',
method: 'post',
data:data,
});
}


export function getFinalInterviewCreateInit(){return request({
url: '/lcf/api/finalInterview/getFinalInterviewCreateInit',
method: 'post',
});
}


export function getFinalInterviewEditInit(params){return request({
url: '/lcf/api/finalInterview/getFinalInterviewEditInit',
method: 'post',
params:params,
});
}


export function getFinalInterviewView(params){return request({
url: '/lcf/api/finalInterview/getFinalInterviewView',
method: 'post',
params:params,
});
}


export function saveFinalInterview(data){return request({
url: '/lcf/api/finalInterview/saveFinalInterview',
method: 'post',
data:data,
});
}


export function deleteFinalInterview(params){return request({
url: '/lcf/api/finalInterview/deleteFinalInterview',
method: 'post',
params:params,
});
}


