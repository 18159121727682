/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2022-01-26T10:50:44.541
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'financial-financialtransferoprlog-'

export default 
{
path: '/financial/financialtransferoprlog',
name: 'financial-financialtransferoprlog',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}financialtransferoprlog-index',

meta:{
...meta,
title: '财务转帐流水操作日志'
},

component: () => import('@/pages/financial/financialtransferoprlog/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}financialtransferoprlog-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '财务转帐流水操作日志编辑'
},

component: () => import('@/pages/financial/financialtransferoprlog/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}financialtransferoprlogdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '财务转帐流水操作日志新增'
},

component: () => import('@/pages/financial/financialtransferoprlog/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}financialtransferoprlogView',
auth: [
'hidden'
],

meta:{
...meta,
title: '财务转帐流水操作日志详情查看'
},

component: () => import('@/pages/financial/financialtransferoprlog/detail-view') ,
},
]
};
