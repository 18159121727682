/**
 * @version GENERATOR_VERSION::20200422
 * @DateTime 2020-04-29T08:55:39.025
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: ['HouseCycle']
};

const pre = 'house-HouseCycle-'

export default {
	path: '/house/housecycle',
	name: 'house-HouseCycle',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}HouseCycle-index',

			meta: {
				...meta,
				title: '全生命周期管控'
			},

			component: () => import('@/pages/house/housecycle/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}HouseCycle-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '全生命周期管控编辑'
			},

			component: () => import('@/pages/house/housecycle/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}HouseCycledetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '全生命周期管控编辑'
			},

			component: () => import('@/pages/house/housecycle/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}HouseCycleView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '全生命周期管控详情查看'
			},

			component: () => import('@/pages/house/housecycle/detail-view'),
		},
	]
};
