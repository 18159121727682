/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-28T14:19:44.345
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialCollectionItemInit(data){return request({
url: '/financial/api/financialCollectionItem/listFinancialCollectionItemInit',
method: 'post',
data:data,
});
}


export function listFinancialCollectionItemByAuthorizations(data){return request({
url: '/financial/api/financialCollectionItem/listFinancialCollectionItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialCollectionItem(data){return request({
url: '/financial/api/financialCollectionItem/listFinancialCollectionItem',
method: 'post',
data:data,
});
}


export function getFinancialCollectionItemCreateInit(){return request({
url: '/financial/api/financialCollectionItem/getFinancialCollectionItemCreateInit',
method: 'post',
});
}


export function getFinancialCollectionItemEditInit(params){return request({
url: '/financial/api/financialCollectionItem/getFinancialCollectionItemEditInit',
method: 'post',
params:params,
});
}


export function getFinancialCollectionItemView(params){return request({
url: '/financial/api/financialCollectionItem/getFinancialCollectionItemView',
method: 'post',
params:params,
});
}


export function saveFinancialCollectionItem(data){return request({
url: '/financial/api/financialCollectionItem/saveFinancialCollectionItem',
method: 'post',
data:data,
});
}


export function deleteFinancialCollectionItem(params){return request({
url: '/financial/api/financialCollectionItem/deleteFinancialCollectionItem',
method: 'post',
params:params,
});
}


