/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T21:12:24.354
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFollowBill,
	listFollowBillInit,
	listFollowBillByAuthorizations,
	deleteFollowBill
	
} from '@api/storagemanage/FollowBill';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFollowBill({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFollowBill(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFollowBillByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFollowBillByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFollowBillInit({
			state
		},data) {  
			state.AdvanceQuery.entity.sourceBillId=data.sourceBillId;
		    state.AdvanceQuery.entity.sourceBillType=data.sourceBillType;
			return new Promise((resolve, reject) => {
				listFollowBillInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFollowBill({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFollowBill({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
