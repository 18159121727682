/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-04-07 14:30:15
 * @LastEditors: co
 * @LastEditTime: 2021-04-23 17:25:22
 * @FilePath: \giant_find\iview-admin-pro-template\src\api\storagemanage\Area.js
 */
import request from '@/plugins/request';

export function listAreaInit(data) {
    return request({
        url: '/storagemanage/api/area/listAreaInit',
        method: 'post',
        data: data,
    });
}


export function listAreaByAuthorizations(data) {
    return request({
        url: '/storagemanage/api/area/listAreaByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listArea(data) {
    return request({
        url: '/storagemanage/api/area/listArea',
        method: 'post',
        data: data,
    });
}


export function getAreaCreateInit() {
    return request({
        url: '/storagemanage/api/area/getAreaCreateInit',
        method: 'post',
    });
}


export function getAreaEditInit(params) {
    return request({
        url: '/storagemanage/api/area/getAreaEditInit',
        method: 'post',
        params: params,
    });
}


export function getAreaView(params) {
    return request({
        url: '/storagemanage/api/area/getAreaView',
        method: 'post',
        params: params,
    });
}


export function saveArea(data) {
    return request({
        url: '/storagemanage/api/area/saveArea',
        method: 'post',
        data: data,
    });
}


export function deleteArea(params) {
    return request({
        url: '/storagemanage/api/area/deleteArea',
        method: 'post',
        params: params,
    });
}

export function findHouseGetArea(params) {
    return request({
        url: '/storagemanage/api/area/getArea',
        method: 'get',
        params: params,
    });
}


