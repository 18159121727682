/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-09T14:42:50.408
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseSalesPriceInit(data){return request({
url: '/rentmanage/api/rentHouseSalesPrice/listRentHouseSalesPriceInit',
method: 'post',
data:data,
});
}


export function listRentHouseSalesPriceByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseSalesPrice/listRentHouseSalesPriceByAuthorizations',
method: 'post',
data:data,
});
}

export function listRentHouseSalesPrice(data){return request({
url: '/rentmanage/api/rentHouseSalesPrice/listRentHouseSalesPrice',
method: 'post',
data:data,
});
}


export function getRentHouseSalesPriceCreateInit(){return request({
url: '/rentmanage/api/rentHouseSalesPrice/getRentHouseSalesPriceCreateInit',
method: 'post',
});
}


export function getRentHouseSalesPriceEditInit(params){return request({
url: '/rentmanage/api/rentHouseSalesPrice/getRentHouseSalesPriceEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseSalesPriceView(params){return request({
url: '/rentmanage/api/rentHouseSalesPrice/getRentHouseSalesPriceView',
method: 'post',
params:params,
});
}


export function saveRentHouseSalesPrice(data){return request({
url: '/rentmanage/api/rentHouseSalesPrice/saveRentHouseSalesPrice',
method: 'post',
data:data,
});
}


export function deleteRentHouseSalesPrice(params){return request({
url: '/rentmanage/api/rentHouseSalesPrice/deleteRentHouseSalesPrice',
method: 'post',
params:params,
});
}

export function submitRentHouseSalesPrice(data){return request({
	url: '/rentmanage/api/rentHouseSalesPrice/submitEvaluateBill',
	method: 'post',
	data:data,
	});
	}

export function startWorkflow(data){return request({
	url: '/rentmanage/api/rentHouseSalesPrice/startWorkflow',
	method: 'post',
	data:data,
	});
	}
