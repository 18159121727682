/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-25T10:30:39.672
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseSalesInit(data){return request({
url: '/rentmanage/api/rentHouseSales/listRentHouseSalesInit',
method: 'post',
data:data,
});
}


export function listRentHouseSalesByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseSales/listRentHouseSalesByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseSales(data){return request({
url: '/rentmanage/api/rentHouseSales/listRentHouseSales',
method: 'post',
data:data,
});
}


export function getRentHouseSalesCreateInit(){return request({
url: '/rentmanage/api/rentHouseSales/getRentHouseSalesCreateInit',
method: 'post',
});
}


export function getRentHouseSalesEditInit(params){return request({
url: '/rentmanage/api/rentHouseSales/getRentHouseSalesEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseSalesView(params){return request({
url: '/rentmanage/api/rentHouseSales/getRentHouseSalesView',
method: 'post',
params:params,
});
}


export function saveRentHouseSales(data){return request({
url: '/rentmanage/api/rentHouseSales/saveRentHouseSales',
method: 'post',
data:data,
});
}


export function deleteRentHouseSales(params){return request({
url: '/rentmanage/api/rentHouseSales/deleteRentHouseSales',
method: 'post',
params:params,
});
}


