/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-01T16:33:48.702
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentFacilityInit(data){return request({
url: '/rentmanage/api/rentFacility/listRentFacilityInit',
method: 'post',
data:data,
});
}


export function listRentFacilityByAuthorizations(data){return request({
url: '/rentmanage/api/rentFacility/listRentFacilityByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentFacility(data){return request({
url: '/rentmanage/api/rentFacility/listRentFacility',
method: 'post',
data:data,
});
}


export function getRentFacilityCreateInit(){return request({
url: '/rentmanage/api/rentFacility/getRentFacilityCreateInit',
method: 'post',
});
}


export function getRentFacilityEditInit(params){return request({
url: '/rentmanage/api/rentFacility/getRentFacilityEditInit',
method: 'post',
params:params,
});
}


export function getRentFacilityView(params){return request({
url: '/rentmanage/api/rentFacility/getRentFacilityView',
method: 'post',
params:params,
});
}


export function saveRentFacility(data){return request({
url: '/rentmanage/api/rentFacility/saveRentFacility',
method: 'post',
data:data,
});
}


export function deleteRentFacility(params){return request({
url: '/rentmanage/api/rentFacility/deleteRentFacility',
method: 'post',
params:params,
});
}


