/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-24T09:31:31.149
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseTagInit(data){return request({
url: '/rentmanage/api/rentHouseTag/listRentHouseTagInit',
method: 'post',
data:data,
});
}


export function listRentHouseTagByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseTag/listRentHouseTagByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseTag(data){return request({
url: '/rentmanage/api/rentHouseTag/listRentHouseTag',
method: 'post',
data:data,
});
}


export function getRentHouseTagCreateInit(){return request({
url: '/rentmanage/api/rentHouseTag/getRentHouseTagCreateInit',
method: 'post',
});
}


export function getRentHouseTagEditInit(params){return request({
url: '/rentmanage/api/rentHouseTag/getRentHouseTagEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseTagView(params){return request({
url: '/rentmanage/api/rentHouseTag/getRentHouseTagView',
method: 'post',
params:params,
});
}


export function saveRentHouseTag(data){return request({
url: '/rentmanage/api/rentHouseTag/saveRentHouseTag',
method: 'post',
data:data,
});
}


export function deleteRentHouseTag(params){return request({
url: '/rentmanage/api/rentHouseTag/deleteRentHouseTag',
method: 'post',
params:params,
});
}


