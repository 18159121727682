/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-25T16:21:58.319
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentHouseFacility,
	listRentHouseFacilityInit,
	listRentHouseFacilityByAuthorizations,
	deleteRentHouseFacility
	
} from '@api/rentmanage/RentHouseFacility';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentHouseFacility({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseFacility(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseFacilityByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseFacilityByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseFacilityInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseFacilityInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseFacility({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseFacility({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
