import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-EvaluateBill-'

export default 
{
path: '/storagemanage/evaluatebill',
name: 'storagemanage-EvaluateBill',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}EvaluateBill-index',

meta:{
...meta,
title: '上门评估'
},

component: () => import('@/pages/storagemanage/evaluatebill/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}EvaluateBill-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '上门评估编辑'
},

component: () => import('@/pages/storagemanage/evaluatebill/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}EvaluateBilldetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '上门评估新增'
},

component: () => import('@/pages/storagemanage/evaluatebill/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}EvaluateBillView',
auth: [
'hidden'
],

meta:{
...meta,
title: '上门评估详情查看'
},

component: () => import('@/pages/storagemanage/evaluatebill/detail-view') ,
},
]
};
