/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2022-05-19T15:50:18.335
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentHouseArtificialSalesPriceItem,
	listRentHouseArtificialSalesPriceItemInit,
	listRentHouseArtificialSalesPriceItemByAuthorizations,
	deleteRentHouseArtificialSalesPriceItem,
	downloadHouseSalesPriceTemplateArtificial,
	downloadBathHousesExcel
	
} from '@api/rentmanage/RentHouseArtificialSalesPriceItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentHouseArtificialSalesPriceItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseArtificialSalesPriceItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseArtificialSalesPriceItemByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseArtificialSalesPriceItemByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseArtificialSalesPriceItemInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseArtificialSalesPriceItemInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		downloadHouseSalesPriceTemplateArtificial({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				downloadHouseSalesPriceTemplateArtificial(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		downloadBathHousesExcel({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				downloadBathHousesExcel(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentHouseArtificialSalesPriceItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseArtificialSalesPriceItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
