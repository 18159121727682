import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	name: 'khm-5',
	redirect: {
		name: `${pre}payApply-list`
	},
	meta,
	component: BasicLayout,
	children: [
		{
			path: 'payApply-list',
			name: `${pre}payApply-list`,
			meta: {
				...meta,
				title: '付款申请管理',
				closable: true
			},
			component: () => import('@/pages/storagemanage/payApply')
		},
		{
			path: 'payApply-underPayEdit',
			name: `${pre}payApply-underPayEdit`,
			meta: {
				...meta,
				title: '线下付款支付编辑',
				closable: true
			},
			component: () => import('@/pages/storagemanage/payApply/underPayEdit')
		},
		{
			path: 'payApply-edit',
			name: `${pre}payApply-edit`,
			meta: {
				...meta,
				title: '付款申请管理编辑',
				closable: true
			},
			component: () => import('@/pages/storagemanage/payApply/edit')
		},
		{
			path: 'payApply-detail',
			name: `${pre}payApply-detail`,
			meta: {
				...meta,
				title: '付款申请管理查看',
				closable: true
			},
			component: () => import('@/pages/storagemanage/payApply/detail')
		},
		
	]
};
