<template>
  <!-- 发起流程 -->
  <div>
    <JFDrawer destroy-on-close title="流程回退" v-model="startModal">
      <Form ref="formData" label-position="top" :model="submitData" :rules="rules">
        <FormItem label="回退描述：" prop="approveContent">
          <Input show-word-limit type="textarea" placeholder="请填写回退描述"
                 :maxlength="500" :autosize="{minRows: 3, maxRows: 5}"
                 v-model="submitData.approveContent"/>
        </FormItem>
        <template v-if="isMobile">
          <Card dis-hover title="环节列表" :bordered="false">
            <div class="cell-group">
              <div class="cell">
                <div class="cell-title">环节人员</div>
                <div class="cell-desc">
                  <span class="nextAuditPersonName" v-if="selectedRow.approverName">{{ selectedRow.approverName }}</span>
                  <a @click="selectApprover = true">选择人员</a>
                </div>
              </div>
            </div>
          </Card>
        </template>
        <template v-else>
          <vxe-table resize auto-resize highlight-hover-row
                     ref="table"
                     row-id="workitemId" max-height="400"
                     :data="selectedDataOnlyOne"
                     :radio-config="radioConfig">
            <vxe-column type="radio" align="center" width="60" />
            <vxe-column field="workitemId" title="环节代码" />
            <vxe-column field="workitemTitle" title="环节名称" />
            <vxe-column field="approverName" title="审批人" />
          </vxe-table>
        </template>
      </Form>
      <Card dis-hover title="流程痕迹" :bordered="false">
        <ProcessTrace ref="processTrace" :list="list" />
      </Card>
      <Spin fix v-if="loading" />
      <template slot="footer" v-if="!loading">
        <Button type="primary"
                :long="isMobile"
                :loading="submitting"
                @click="submitFlow">提交流程</Button>
      </template>
    </JFDrawer>
    <JFDrawer destroy-on-close style="position: relative; z-index: 1003;" title="选择人员" v-model="selectApprover">
      <RadioGroup v-model="radio">
        <template v-for="item of selectedDataOnlyOne">
          <Radio :key="item.workitemId" :label="item.workitemId">
            <Card dis-hover :bordered="false">
              <div class="cell-group">
                <div class="cell">
                  <div class="cell-title">审批人</div>
                  <div class="cell-desc">{{ item.approverName || '' }}</div>
                </div>
                <div class="cell">
                  <div class="cell-title">环节代码</div>
                  <div class="cell-desc">{{ item.workitemId }}</div>
                </div>
                <div class="cell">
                  <div class="cell-title">环节名称</div>
                  <div class="cell-desc">{{ item.workitemTitle }}</div>
                </div>
              </div>
            </Card>
          </Radio>
        </template>
      </RadioGroup>
      <template slot="footer">
        <Row style="flex: 1" :gutter="10">
          <Col :span="12"><Button long @click="selectApprover = false">取消</Button></Col>
          <Col :span="12"><Button long type="primary" @click="getSelectEvent">确认</Button></Col>
        </Row>
      </template>
    </JFDrawer>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { has } from 'lodash'
  import ProcessTrace from './processTrace'
  import request from '@/plugins/request'

  export default {
    name: 'CallBackFlow',
    components: {ProcessTrace},
    computed: {
      ...mapState('admin/layout', ['isMobile']),
      radioConfig () {
        return {
          strict: false,
          trigger: 'row',
          highlight: true
        }
      },
    },
    methods: {
      async init (data, url, billData) {
        try {
          this.startModal = true
          this.data = data
          this.flowUrl = url
          //审核结论设置初始值
          this.submitData.approveEffect =
            this.data.currentWorkitem.approveEffectRange &&
            this.data.currentWorkitem.approveEffectRange.length > 0
              ? this.data.currentWorkitem.approveEffectRange[0].code
              : ''
          //审核意见设置初始值
          this.submitData.approveContent = '不同意' // this.data.currentWorkitem.approveEffectRange && this.data.currentWorkitem.approveEffectRange
          //.length > 0 ? this.data.currentWorkitem.approveEffectRange[0].name : '';
          // this.submitData.bill = billData;
          //流程实例id赋值(从审核初始化接口)
          this.submitData.workflowInstanceId = this.data.currentDraftWorkitemInstance.workflowInstanceId
          //流程环节实例id赋值(从审核初始化接口)
          this.submitData.workitemInstanceId = this.data.currentDraftWorkitemInstance.workitemInstanceId
          //加载回退申请接口
          let businessData = null
          this.$emit('getBusinessData', function (data) {
            businessData = data
          })

          this.submitData.bill = businessData
          this.loading = true
          const [{entities: selectedDataOnlyOne}, {entities}] = await Promise.all([
            request({
              url: this.flowUrl + '/callbackWorkflowRequest',
              method: 'post',
              data: this.submitData.bill,
              params: {
                workflowInstanceId: this.submitData.workflowInstanceId,
                workitemInstanceId: this.submitData.workitemInstanceId
              }
            }),
            request({
              url: '/worfklow/api/flowcenter/listProcessTraces',
              method: 'post',
              params: {
                workflowInstanceId: this.data.currentDraftWorkitemInstance
                  .workflowInstanceId
              }
            })
          ])

          this.selectedDataOnlyOne = selectedDataOnlyOne
          this.list = entities || []
        } catch (e) {} finally {
          this.loading = false
        }
      },
      changeApproveEffect () {
        //切换审核结论  清空环节已选人员
        this.selectedDataOnlyOneNextUser = []
        this.selectedDataOneOrMoreNextUser = []
        this.selectedDataZoreOrMoreNextUser = []
        this.selectedDataALLNextUser = []

        if (this.submitData.approveEffect == 'AGREE') {
          this.submitData.approveContent = '同意'
        } else if (this.submitData.approveEffect == 'DISAGREE') {
          this.submitData.approveContent = '不同意'
        } else {
          //征询
          this.submitData.approveContent = ''
        }
      },
      openSelectUser (row, selectDataName) {
        //点击选择环节人员
        //row:当前行数据  selectDataName:选择人员之后回传,要设置的数据的字段名
        this.selectUserVisible = true
        this.$nextTick(() => {
          //isMoreUser多选单选
          this.$refs['selectUser'].init(
            row.workflowUserEnum,
            row.nextUser,
            row.isMoreUser ? 'checkbox' : 'radio',
            selectDataName
          )
        })
      },
      setDataUser (data) {
        //选择人员回传(每次覆盖上次所选)
        if (data[1] == 'selectedDataOnlyOneNextUser') {
          //必选组(单选)
          this[data[1]] = data[0]
        } else if (data[1] == 'selectedDataOneOrMoreNextUser') {
          //必选组(多选)
          this[data[1]] = data[0]
        } else if (data[1] == 'selectedDataZoreOrMoreNextUser') {
          //候选组(多选)
        } else if (data[1] == 'selectedDataALLNextUser') {
          //确选组(多选)
        }
      },
      async submitFlow () {
        try {
          if (!await this.$refs.formData.validate()) return
          if (!has(this.getRadioRecord(), 'workitemId')) return this.$Message.warning('请选择环节')
          let businessData = null

          this.submitting = true
          this.$emit('getBusinessData', function (data) {
            businessData = data
          })
          this.submitData.bill = businessData
          //提交流程
          await request({
            url: this.flowUrl + '/callbackWorkflow',
            method: 'post',
            data: this.submitData.bill,
            params: {
              approveContent: this.submitData.approveContent,
              callBackWorkitemInstanceId: this.getRadioRecord().workitemInstanceId,
              workflowInstanceId: this.submitData.workflowInstanceId,
              workitemInstanceId: this.submitData.workitemInstanceId
            }
          })

          this.startModal = false
          this.$emit('refresh')
        } catch (e) {} finally {
          this.submitting = false
        }
      },

      getSelectEvent () {
        this.selectedRow = this.list.find(item => item.workitemId === this.radio) || {}
        this.selectApprover = false
      },
      getRadioRecord () {
        if (this.isMobile) return this.selectedRow
        return this.$refs.table.getRadioRecord()
      },
    },
    data () {
      return {
        startModal: false,
        selectUserVisible: false, //选人弹框
        loading: false,
        list: [],
        total: 0,
        data: {}, //流程初始化传入的数据
        flowUrl: '', //发起流程的url
        submitData: {
          bill: {},
          workflowInstanceId: '',
          workitemInstanceId: '',
          approveContent: '',
          approveEffect: 'AGREE'
        }, //提交流程的数据对象
        selectedDataOnlyOne: [], //必选组已选(单选)
        rules: {
          approveContent: [
            {
              required: true,
              message: '请选择审核意见',
              trigger: 'blur'
            }
          ]
        },
        selectedRow: {},
        selectApprover: false,

        radio: '',
        submitting: false
      }
    },
  }
</script>

<style scoped lang="less">
  .cell {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
    &-title {
      flex: 0 0 70px;
    }
    &-desc {
      flex: 1;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }
  }
  .nextAuditPersonName {
    margin-right: 15px;
  }
  /deep/ .ivu-radio-group {
    display: block;
  }
  /deep/ .ivu-radio-wrapper {
    padding: 16px 0;
    display: block;
    border-bottom: 1px solid #e8eaec !important;
    position: relative;

    .ivu-card {
      padding-left: 30px;

      &-body {
        padding: 0;
      }
    }
  }
  /deep/ .ivu-radio {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 17px;
  }
</style>
