/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-30T21:02:57.588
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveFinancialClaimItem,
	getFinancialClaimItemCreateInit,
	getFinancialClaimItemEditInit,
	getFinancialClaimItemView,
	deleteFinancialClaimItem
} from '@api/financial/FinancialClaimItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialClaimItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialClaimItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialClaimItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialClaimItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialClaimItem({}, FinancialClaimItem) {
			return new Promise((resolve, reject) => {
				saveFinancialClaimItem(FinancialClaimItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialClaimItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialClaimItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialClaimItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialClaimItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
