/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-06-25T18:30:18.958
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentViewOrderRetreat,
	getRentViewOrderRetreatCreateInit,
	getRentViewOrderRetreatEditInit,
	getRentViewOrderRetreatView,
	deleteRentViewOrderRetreat
} from '@api/rentmanage/RentViewOrderRetreat';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentViewOrderRetreatCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentViewOrderRetreatCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentViewOrderRetreatEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentViewOrderRetreatEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentViewOrderRetreat({}, RentViewOrderRetreat) {
			return new Promise((resolve, reject) => {
				saveRentViewOrderRetreat(RentViewOrderRetreat)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentViewOrderRetreatView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentViewOrderRetreatView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentViewOrderRetreat({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentViewOrderRetreat({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
