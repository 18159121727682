/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-15T17:10:25.446
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-financerentapply-'

export default 
{
path: '/storagemanage/financerentapply',
name: 'storagemanage-financerentapply',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}financerentapply-index',

meta:{
...meta,
title: '租金申请'
},

component: () => import('@/pages/storagemanage/financerentapply/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}financerentapply-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '租金申请编辑'
},

component: () => import('@/pages/storagemanage/financerentapply/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}financerentapplydetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '租金申请新增'
},

component: () => import('@/pages/storagemanage/financerentapply/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}financerentapplyView',
auth: [
'hidden'
],

meta:{
...meta,
title: '租金申请详情查看'
},

component: () => import('@/pages/storagemanage/financerentapply/detail-view') ,
},
]
};
