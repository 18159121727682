/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-14T19:43:30.582
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getRentTrafficLineEditInit,
	getRentTrafficLineView
} from '@api/rentmanage/RentTrafficLine';

import {
listRentTrafficLinePlatformInit,
listRentTrafficLinePlatformByAuthorizations,
deleteRentTrafficLinePlatform
} from '@api/rentmanage/RentTrafficLinePlatform';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getRentTrafficLineEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentTrafficLineEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentTrafficLineView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentTrafficLineView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
