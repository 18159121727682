/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-03T10:29:11.464
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentPublishChannelInit(data){return request({
url: '/rentmanage/api/rentPublishChannel/listRentPublishChannelInit',
method: 'post',
data:data,
});
}


export function listRentPublishChannelByAuthorizations(data){return request({
url: '/rentmanage/api/rentPublishChannel/listRentPublishChannelByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentPublishChannel(data){return request({
url: '/rentmanage/api/rentPublishChannel/listRentPublishChannel',
method: 'post',
data:data,
});
}


export function getRentPublishChannelCreateInit(){return request({
url: '/rentmanage/api/rentPublishChannel/getRentPublishChannelCreateInit',
method: 'post',
});
}


export function getRentPublishChannelEditInit(params){return request({
url: '/rentmanage/api/rentPublishChannel/getRentPublishChannelEditInit',
method: 'post',
params:params,
});
}


export function getRentPublishChannelView(params){return request({
url: '/rentmanage/api/rentPublishChannel/getRentPublishChannelView',
method: 'post',
params:params,
});
}


export function saveRentPublishChannel(data){return request({
url: '/rentmanage/api/rentPublishChannel/saveRentPublishChannel',
method: 'post',
data:data,
});
}


export function deleteRentPublishChannel(params){return request({
url: '/rentmanage/api/rentPublishChannel/deleteRentPublishChannel',
method: 'post',
params:params,
});
}


