/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-11T10:54:45.437
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentHouseHold,
	listRentHouseHoldInit,
	listRentHouseHoldByAuthorizations,
	deleteRentHouseHold
	
} from '@api/rentmanage/RentHouseHold';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
		//传送的参数
		deliveryData:{
			holdType:'',
			startDate:'',
			endDate:''
		},
		//是否编辑状态查看
		edit:true
	},

	actions: {
		listRentHouseHold({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseHold(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseHoldByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseHoldByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentHouseHoldInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentHouseHoldInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseHold({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseHold({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
