/**
 * @version GENERATOR_VERSION::20200422
 * @DateTime 2020-04-28T10:23:25.78
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listBlockInit(data) {
	return request({
		url: '/storagemanage/api/block/listBlockInit',
		method: 'post',
		data: data,
	});
}


export function listBlockByAuthorizations(data) {
	return request({
		url: '/storagemanage/api/block/listBlockByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listBlock(data) {
	return request({
		url: '/storagemanage/api/block/listBlock',
		method: 'post',
		data: data,
	});
}


export function getBlockCreateInit() {
	return request({
		url: '/storagemanage/api/block/getBlockCreateInit',
		method: 'post',
	});
}


export function getBlockEditInit(params) {
	return request({
		url: '/storagemanage/api/block/getBlockEditInit',
		method: 'post',
		params: params,
	});
}


export function getBlockView(params) {
	return request({
		url: '/storagemanage/api/block/getBlockView',
		method: 'post',
		params: params,
	});
}


export function saveBlock(data) {
	return request({
		url: '/storagemanage/api/block/insertOrUpdateBlock',
		method: 'post',
		data: data,
	});
}

export function saveBlockNew(data) {
	return request({
		url: '/storagemanage/api/block/saveBlock',
		method: 'post',
		data: data,
	});
}


export function deleteBlock(params) {
	return request({
		url: '/storagemanage/api/block/deleteBlock',
		method: 'post',
		params: params,
	});
}
