import request from '@/plugins/request';

export function GetDataList (data) {
    return request({
        url: '/smallapplication/api/suggest/listSuggest',
        method: 'post',
		data:data
    });
}

export function pageInitReq (data) {
    return request({
        url: '/smallapplication/api/suggest/listSuggestInit',
        method: 'post',
        data
    });
}

export function editPageInitReq (data) {
    return request({
        url: '/smallapplication/api/suggest/getSuggestEditInit',
        method: 'post',
        params:data
    });
}

export function AddOrEditData (data) {
    return request({
        url: '/smallapplication/api/suggest/saveSuggest',
        method: 'post',
        data
    });
}

export function getApply (data) {
    return request({
        url: '/smallapplication/api/suggest/querySuggestReply',
        method: 'post',
         params:data
    });
}

export function AddReply (data) {
    return request({
        url: '/smallapplication/api/suggest/saveSuggestReply',
        method: 'post',
        params:data
    });
}

export function topReq (data) {
    return request({
        url: '/smallapplication/api/suggest/topSuggest',
        method: 'post',
        params:data
    });
}

export function deleteReq (data) {
    return request({
        url: '/smallapplication/api/suggest/deleteSuggest',
        method: 'post',
        params:data
    });
}

export function upReq (data) {
    return request({
        url: '/smallapplication/api/suggest/countSuggest',
        method: 'post',
        params:data
    });
}

export function getSimpleDataReq (data) {
    return request({
        url: '/smallapplication/api/suggest/getSuggestView',
        method: 'post',
        params:data
    });
}

export function GetDataListTop (data) {
    return request({
        url: '/smallapplication/api/suggest/listSuggestTop',
        method: 'post',
		data:data
    });
}
