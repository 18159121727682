/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-21T03:43:01.811
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'storagemanage-managementareachannel-'

export default {
	path: '/storagemanage/managementareachannel',
	name: 'storagemanage-managementareachannel',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}managementareachannel-index',

			meta: {
				...meta,
				title: '评估渠道'
			},

			component: () => import('@/pages/storagemanage/managementareachannel/index?companyBizType=2'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}managementareachannel-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '评估渠道编辑'
			},

			component: () => import('@/pages/storagemanage/managementareachannel/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}managementareachanneldetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '评估渠道编辑'
			},

			component: () => import('@/pages/storagemanage/managementareachannel/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}managementareachannelView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '评估渠道详情查看'
			},

			component: () => import('@/pages/storagemanage/managementareachannel/detail-view'),
		},
	]
};
