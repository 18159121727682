/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:57.639
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listHouseSchoolInfoInit(data){return request({
url: '/lcf/api/houseSchoolInfo/listHouseSchoolInfoInit',
method: 'post',
data:data,
});
}


export function listHouseSchoolInfoByAuthorizations(data){return request({
url: '/lcf/api/houseSchoolInfo/listHouseSchoolInfoByAuthorizations',
method: 'post',
data:data,
});
}


export function listHouseSchoolInfo(data){return request({
url: '/lcf/api/houseSchoolInfo/listHouseSchoolInfo',
method: 'post',
data:data,
});
}


export function getHouseSchoolInfoCreateInit(){return request({
url: '/lcf/api/houseSchoolInfo/getHouseSchoolInfoCreateInit',
method: 'post',
});
}


export function getHouseSchoolInfoEditInit(params){return request({
url: '/lcf/api/houseSchoolInfo/getHouseSchoolInfoEditInit',
method: 'post',
params:params,
});
}


export function getHouseSchoolInfoView(params){return request({
url: '/lcf/api/houseSchoolInfo/getHouseSchoolInfoView',
method: 'post',
params:params,
});
}


export function saveHouseSchoolInfo(data){return request({
url: '/lcf/api/houseSchoolInfo/saveHouseSchoolInfo',
method: 'post',
data:data,
});
}


export function deleteHouseSchoolInfo(params){return request({
url: '/lcf/api/houseSchoolInfo/deleteHouseSchoolInfo',
method: 'post',
params:params,
});
}


