/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-25T11:06:36.118
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportRentContractUndue,
	getReportRentContractUndueCreateInit,
	getReportRentContractUndueEditInit,
	getReportRentContractUndueView,
	deleteReportRentContractUndue
} from '@api/report/ReportRentContractUndue';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportRentContractUndueCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportRentContractUndueCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportRentContractUndueEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportRentContractUndueEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportRentContractUndue({}, ReportRentContractUndue) {
			return new Promise((resolve, reject) => {
				saveReportRentContractUndue(ReportRentContractUndue)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportRentContractUndueView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportRentContractUndueView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportRentContractUndue({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportRentContractUndue({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
