/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-24T15:57:51.844
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHouseTypeTag,
	getRentHouseTypeTagCreateInit,
	getRentHouseTypeTagEditInit,
	getRentHouseTypeTagView,
	deleteRentHouseTypeTag
} from '@api/rentmanage/RentHouseTypeTag';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentHouseTypeTagCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseTypeTagCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseTypeTagEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseTypeTagEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseTypeTag({}, RentHouseTypeTag) {
			return new Promise((resolve, reject) => {
				saveRentHouseTypeTag(RentHouseTypeTag)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentHouseTypeTagView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseTypeTagView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentHouseTypeTag({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseTypeTag({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
