/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-14T19:21:18.582
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportStorageIntention,
	getReportStorageIntentionCreateInit,
	getReportStorageIntentionEditInit,
	getReportStorageIntentionView,
	deleteReportStorageIntention
} from '@api/report/ReportStorageIntention';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getReportStorageIntentionCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportStorageIntentionCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportStorageIntentionEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportStorageIntentionEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportStorageIntention({}, ReportStorageIntention) {
			return new Promise((resolve, reject) => {
				saveReportStorageIntention(ReportStorageIntention)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getReportStorageIntentionView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportStorageIntentionView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteReportStorageIntention({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportStorageIntention({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
