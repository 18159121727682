/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-04T20:25:05.16
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listHouseDuplicateRemoval,
	listHouseDuplicateRemovalInit,
	listHouseDuplicateRemovalByAuthorizations,
	deleteHouseDuplicateRemoval,
	listHouseDuplicateRemovalInitBySQL,
	repetitionSubmit
	
} from '@api/storagemanage/HouseDuplicateRemoval';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listHouseDuplicateRemoval({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHouseDuplicateRemoval(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listHouseDuplicateRemovalByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHouseDuplicateRemovalByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listHouseDuplicateRemovalInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHouseDuplicateRemovalInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		listHouseDuplicateRemovalInitBySQL({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHouseDuplicateRemovalInitBySQL(state.AdvanceQuery)
					.then(async res => {
						//state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		repetitionSubmit({
			state
		},data) {
			return new Promise((resolve, reject) => {
				repetitionSubmit(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteHouseDuplicateRemoval({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteHouseDuplicateRemoval({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
