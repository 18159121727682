/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-30T17:06:33.72
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'financial-financialtransfer-'

export default 
{
path: '/financial/financialtransfer',
name: 'financial-financialtransfer',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}financialtransfer-index',

meta:{
...meta,
title: '银行流水'
},

component: () => import('@/pages/financial/financialtransfer/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}financialtransfer-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '银行流水编辑'
},

component: () => import('@/pages/financial/financialtransfer/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}financialtransferdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '银行流水新增'
},

component: () => import('@/pages/financial/financialtransfer/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}financialtransferView',
auth: [
'hidden'
],

meta:{
...meta,
title: '银行流水详情查看'
},

component: () => import('@/pages/financial/financialtransfer/detail-view') ,
},
]
};
