/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-07-15T10:05:28.37
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getEnergyApplicationEditInit,
	getEnergyApplicationView,
	financialPaymentStateChange
} from '@api/rentmanage/EnergyApplication';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		getEnergyApplicationEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEnergyApplicationEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEnergyApplicationView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEnergyApplicationView({
						id
					})
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		async financialPaymentStateChange({},data) {
			return await financialPaymentStateChange(data)
		}
	}
};
