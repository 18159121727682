/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-05-16T17:00:11.364
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: true
};

const pre = 'storagemanage-EvaluateAppointmentFinal-'

export default {
    path: '/storagemanage/evaluateappointmentfinal',
    name: 'storagemanage-EvaluateAppointmentFinal',
    redirect: {
        name: '${pre}-index'
    },

    meta: {
        ...meta,
    },

    component: BasicLayout,
    children: [{
            path: 'table-index',
            name: '${pre}EvaluateAppointmentFinal-table-index',

            meta: {
                ...meta,
                title: '预约终谈'
            },

            component: () => import('@/pages/storagemanage/evaluateappointmentfinal/table-index'),
        },{
            path: 'index',
            name: '${pre}EvaluateAppointmentFinal-index',

            meta: {
                ...meta,
                title: '预约终谈查看'
            },

            component: () => import('@/pages/storagemanage/evaluateappointmentfinal/index'),
        },
        {
            path: 'detail-edit/:id',
            name: '${pre}EvaluateAppointmentFinal-detail-edit',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                cache: true,
                title: '预约终谈编辑'
            },

            component: () => import('@/pages/storagemanage/evaluateappointmentfinal/detail-edit'),
        },
        {
            path: 'detail-edit',
            name: '${pre}EvaluateAppointmentFinaldetail-create',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                  cache: true,
                title: '预约终谈编辑'
            },

            component: () => import('@/pages/storagemanage/evaluateappointmentfinal/detail-edit'),
        },
        {
            path: 'detail-view/:id',
            name: '${pre}EvaluateAppointmentFinalView',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '预约终谈详情查看'
            },

            component: () => import('@/pages/storagemanage/evaluateappointmentfinal/detail-view'),
        },

    ]
};
