/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-04-24T12:53:00.544
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveOrgUserManage,
	getOrgUserManageCreateInit,
	getOrgUserManageEditInit,
	getOrgUserManageView,
	deleteOrgUserManage
} from '@api/systemmanage/OrgUserManage';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getOrgUserManageCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getOrgUserManageCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getOrgUserManageEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getOrgUserManageEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveOrgUserManage({}, OrgUserManage) {
			return new Promise((resolve, reject) => {
				saveOrgUserManage(OrgUserManage)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getOrgUserManageView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getOrgUserManageView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteOrgUserManage({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteOrgUserManage({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
