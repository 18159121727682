/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-14T15:25:43.702
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
  auth: true
};

const pre = 'rentmanage-RentLeaseContract-'

export default {
  path: '/rentmanage/rentleasecontract',
  name: 'rentmanage-RentLeaseContract',
  redirect: {
    name: '${pre}-index'
  },

  meta: {
    ...meta,
  },

  component: BasicLayout,
  children: [{
    path: 'index',
    name: '${pre}RentLeaseContract-index',

    meta: {
      ...meta,
      title: '租客签约'
    },

    component: () => import('@/pages/rentmanage/rentleasecontract/index'),
  },
  {
    path: 'detail-edit/:id',
    name: `${pre}detail-edit`,
    auth: [
      'hidden'
    ],

    meta: {
      ...meta,
      cache: true,
      title: '租客签约编辑'
    },

    component: () => import('@/pages/rentmanage/rentleasecontract/detail-edit'),
  },
  {
    path: 'detail-edit',
    name: '${pre}RentLeaseContractdetail-create',
    auth: [
      'hidden'
    ],

    meta: {
      ...meta,
      title: '租客签约新增'
    },

    component: () => import('@/pages/rentmanage/rentleasecontract/detail-edit'),
  },
  {
    path: 'detail-view/:id',
    name: '${pre}RentLeaseContractView',
    auth: [
      'hidden'
    ],

    meta: {
      ...meta,
      title: '租客签约详情查看'
    },

    component: () => import('@/pages/rentmanage/rentleasecontract/detail-view'),
  },
  ]
};
