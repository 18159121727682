/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-14T19:43:30.585
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentTrafficLine,
	getRentTrafficLineCreateInit,
	getRentTrafficLineEditInit,
	getRentTrafficLineView,
	deleteRentTrafficLine
} from '@api/rentmanage/RentTrafficLine';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentTrafficLinePlatformInit,
listRentTrafficLinePlatformByAuthorizations,
deleteRentTrafficLinePlatform
} from '@api/rentmanage/RentTrafficLinePlatform';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentTrafficLineCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentTrafficLineCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentTrafficLineEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentTrafficLineEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentTrafficLine({}, RentTrafficLine) {
			return new Promise((resolve, reject) => {
				saveRentTrafficLine(RentTrafficLine)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentTrafficLineView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentTrafficLineView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentTrafficLine({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentTrafficLine({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
