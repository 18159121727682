import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	redirect: {
		name: `${pre}signHouseInfo-list`
	},
	meta,
	component: BasicLayout,
	children: [
		{
			path: 'signHouseInfo-list',
			name: `${pre}signHouseInfo-list`,
			meta: {
				...meta,
				title: '房源信息(签约)',
				closable: true
			},
			component: () => import('@/pages/storagemanage/signHouseInfo')
		},
	]
};
