/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T17:39:58.806
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listContractSubjectAcountInit(data){return request({
url: '/storagemanage/api/contractSubjectAcount/listContractSubjectAcountInit',
method: 'post',
data:data,
});
}


export function listContractSubjectAcountByAuthorizations(data){return request({
url: '/storagemanage/api/contractSubjectAcount/listContractSubjectAcountByAuthorizations',
method: 'post',
data:data,
});
}


export function listContractSubjectAcount(data){return request({
url: '/storagemanage/api/contractSubjectAcount/listContractSubjectAcount',
method: 'post',
data:data,
});
}


export function getContractSubjectAcountCreateInit(){return request({
url: '/storagemanage/api/contractSubjectAcount/getContractSubjectAcountCreateInit',
method: 'post',
});
}


export function getContractSubjectAcountEditInit(params){return request({
url: '/storagemanage/api/contractSubjectAcount/getContractSubjectAcountEditInit',
method: 'post',
params:params,
});
}


export function getContractSubjectAcountView(params){return request({
url: '/storagemanage/api/contractSubjectAcount/getContractSubjectAcountView',
method: 'post',
params:params,
});
}


export function saveContractSubjectAcount(data){return request({
url: '/storagemanage/api/contractSubjectAcount/saveContractSubjectAcount',
method: 'post',
data:data,
});
}


export function deleteContractSubjectAcount(params){return request({
url: '/storagemanage/api/contractSubjectAcount/deleteContractSubjectAcount',
method: 'post',
params:params,
});
}


