/**
 * 获取uuid
 */
export function getUUID() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
		return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
	})
}
/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(module, operate) {
	var href = window.location.href;
	var str = href.match(/from=[a-zA-Z]+/ig);
	if (str && str[0].split("=")[1] == "BI") {
		return null;
	}
	var permission = parent.permission;
	var hasPermissiond;
	try {
		hasPermissiond = getUPOperationRight(module, operate);
	} catch (e) {
		hasPermissiond = false;
	}
	return true
	return hasPermissiond;
}

export function getRightAfter(module, operation) {
	var userId = ""
	userId = JSON.parse(window.sessionStorage.getItem('userInfo')).userId
	var userMPRight = getUPModuleRight(userId, module),
		isHasOperation = false;
	if (userMPRight && userMPRight[operation]) {
		var powers = userMPRight[operation];
		if (powers) {
			isHasOperation = true;
		}
	}
	return isHasOperation;
}

export function getUPOperationRight(module, operation) {
	if (window.sessionStorage.getItem('userInfo') && window.sessionStorage.getItem('userInfo') != {}) {
		let flag = getRightAfter(module, operation);
		return flag;
	} else {
		httpRequest({
			url: httpRequest.adornUrl('/storage/storageContract/getUserInfo'),
			method: 'get',
		}).then(({
			data
		}) => {
			// window.sessionStorage.setItem('userInfo', JSON.stringify(data.data));
			let flag = getRightAfter(module, operation);
			return flag;
		}).catch((e) => { })
	}
}

export function getUPModuleRight(userId, module) {
	var userIdUPRight = getUserIdMPRight(userId);
	return userIdUPRight[module] || null;
}

export function getUserIdMPRight(userId) {
	var m = getUserIdRight('mangerPermission_', userId);
	if (m === null) {
		m = this.initMPToCache(userId);
	}
	return m || {};
}

export function getUserIdRight(key, userId) {
	return parent.CachCommonHashMap.get(key + userId);
}

export function getUserInfo() {
	return new Promise((resovle, reject) => {
		httpRequest({
			url: httpRequest.adornUrl('/storage/storageContract/getUserInfo'),
			method: 'get',
		}).then(({
			data
		}) => {
			// window.sessionStorage.setItem('userInfo', JSON.stringify(data.data));
			resovle(data.data);
		}).catch((e) => { })
	})
}

export function getUserInfoObj() {
	let userInfo = window.sessionStorage.getItem('userInfo');
	return JSON.parse(userInfo);
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
	var res = []
	var temp = {}
	for (var i = 0; i < data.length; i++) {
		temp[data[i][id]] = data[i]
	}
	for (var k = 0; k < data.length; k++) {
		if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
			if (!temp[data[k][pid]]['children']) {
				temp[data[k][pid]]['children'] = []
			}
			if (!temp[data[k][pid]]['_level']) {
				temp[data[k][pid]]['_level'] = 1
			}
			data[k]['_level'] = temp[data[k][pid]]._level + 1
			temp[data[k][pid]]['children'].push(data[k])
		} else {
			res.push(data[k])
		}
	}
	return res
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
	window.localStorage.removeItem("Authorization");
	window.localStorage.removeItem("rentUserData");
	//	Vue.cookie.delete('token')
	store.commit('resetStore')
	router.options.isAddDynamicMenuRoutes = false
}

export function formateTime(v, fmt) {
	v = v / 1000
	let date = new Date(v * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
	let Y = date.getFullYear() + '-';
	let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	let D = date.getDate() + ' ';
	let h = date.getHours() + ':';
	let m = date.getMinutes() + ':';
	let s = date.getSeconds();
	return Y + M + D + h + m + s;
}

export function formateDay(v, fmt) {
	v = v / 1000
	let date = new Date(v * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
	let Y = date.getFullYear() + '-';
	let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
	return Y + M + D;
}

export function compare(prop) {
	return function (obj1, obj2) {
		var val1 = obj1[prop];
		var val2 = obj2[prop];
		if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
			val1 = Number(val1);
			val2 = Number(val2);
		}
		if (val1 < val2) {
			return -1;
		} else if (val1 > val2) {
			return 1;
		} else {
			return 0;
		}
	}
}

//对象深度克隆
export function cloneObj(obj) {
	var str, newobj = obj.constructor === Array ? [] : {};
	if (typeof obj !== 'object') {
		return;
	} else if (window.JSON) {
		str = JSON.stringify(obj), //序列化对象
			newobj = JSON.parse(str); //还原
	} else {
		for (var i in obj) {
			newobj[i] = typeof obj[i] === 'object' ? cloneObj(obj[i]) : obj[i];
		}
	}
	return newobj;
};

//导出链接拼接
export function formatExportHref(apiUrl, param) {
	//重置请求地址
	getUrlFront(apiUrl);
	let exportParam = cloneObj(param);
	apiUrl = window.SITE_CONFIG.baseUrl + apiUrl;
	if (exportParam && exportParam != {}) {
		//去除分页参数
		delete exportParam.pageIndex
		delete exportParam.pageNum
		delete exportParam.pageNumber
		delete exportParam.pageSize
		apiUrl += "?"
		for (let key in exportParam) {
			if (exportParam[key]) {
				apiUrl += key + '=' + exportParam[key] + "&"
			}
		}
		//去除最后一个&
		apiUrl = apiUrl.substring(0, apiUrl.length - 1)
	}
	return apiUrl
}

//获取到每月的第一天和最后一天
export function getMonthFirstOrLaseDay(month) {
	var month = month || (new Date()).getMonth() + 1 //设置默认 如果不穿 取当前月份
	var nowdays = new Date();
	var year = nowdays.getFullYear();
	if (month == 0) {
		month = 12;
		year = year - 1;
	}
	if (month < 10) {
		month = "0" + month;
	}
	var firstDay = year + '-' + month + '-' + "01";
	var myDate = new Date(year, month, 0);
	var lastDay = year + '-' + month + '-' + myDate.getDate();
	return {
		firstDay: firstDay,
		lastDay: lastDay
	}
}

export function getTime() {
	let now = new Date(); //当前日期
	let nowDayOfWeek = now.getDay(); //今天本周的第几天
	let nowDay = now.getDate(); //当前日
	let nowMonth = now.getMonth(); //当前月
	let nowYear = now.getYear(); //当前年
	nowYear += (nowYear < 2000) ? 1900 : 0; //
	return {
		now: now,
		nowDayOfWeek: nowDayOfWeek,
		nowDay: nowDay,
		nowMonth: nowMonth,
		nowYear: nowYear
	}
}

//获取本日(日期格式)
export function getCurrentDay() {
	let now = getTime().now; //当前日期
	let nowDayOfWeek = getTime().nowDayOfWeek //今天本周的第几天
	let nowDay = getTime().nowDay < 10 ? '0' + getTime().nowDay : getTime().nowDay; //当前日
	let nowMonth = getTime().nowMonth + 1 < 10 ? '0' + (getTime().nowMonth + 1) : getTime().nowMonth + 1; //当前月
	let nowYear = getTime().nowYear; //当前年
	return nowYear + "-" + nowMonth + "-" + nowDay
}

//获取本周开始结束时间(日期格式)
export function getCurrentWeek() {
	let now = getTime().now; //当前日期
	let nowDayOfWeek = getTime().nowDayOfWeek //今天本周的第几天
	let nowDay = getTime().nowDay; //当前日
	let nowMonth = getTime().nowMonth; //当前月
	let nowYear = getTime().nowYear; //当前年
	var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
	var weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
	return {
		startDate: formateDay(weekStartDate),
		endDate: formateDay(weekEndDate)
	}
}

//获得某月的天数
export function getMonthDays(myMonth) {
	let nowYear = getTime().nowYear; //当前年
	var monthStartDate = new Date(nowYear, myMonth, 1);
	var monthEndDate = new Date(nowYear, myMonth + 1, 1);
	var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
	return days;
}
//获取本月开始结束时间(日期格式)
export function getCurrentMonth() {
	let now = getTime().now; //当前日期
	let nowDayOfWeek = getTime().nowDayOfWeek //今天本周的第几天
	let nowDay = getTime().nowDay; //当前日
	let nowMonth = getTime().nowMonth; //当前月
	let nowYear = getTime().nowYear; //当前年
	var monthStartDate = new Date(nowYear, nowMonth, 1);
	var monthEndDate = new Date(nowYear, nowMonth, getMonthDays(nowMonth));
	return {
		startDate: formateDay(monthStartDate),
		endDate: formateDay(monthEndDate)
	}
}

//获取本年开始结束时间(日期格式)
export function getCurrentYear() {
	let now = getTime().now; //当前日期
	let nowDayOfWeek = getTime().nowDayOfWeek //今天本周的第几天
	let nowDay = getTime().nowDay; //当前日
	let nowMonth = getTime().nowMonth; //当前月
	let nowYear = getTime().nowYear; //当前年
	return {
		startDate: nowYear + "-01-01",
		endDate: nowYear + "-12-31",
	}
}

export function resetChoose(params, fun) {
	Object.keys(params).forEach(function (key) {
		params[key] = "";
	});
	params.pageNum = 1;
	params.pageSize = 10;
	if (fun) {
		fun();
	}
}

export function getFlexValue(params) {
	return new Promise((resovle, reject) => {
		httpRequest({
			url: httpRequest.adornUrl('/api/dictionary/getDictionaryByParentCodeList'),
			method: 'post',
			data: JSON.stringify(params)
		}).then(({
			data
		}) => {
			resovle(data.data);
		}).catch((e) => {
			reject('1111');
		})
	})
}

export function getFlexValueNameByCode(valueArray, value) {
	let result = "";
	if (valueArray) {
		valueArray.forEach((item, index) => {
			if (item.itemCode == value) {
				result = item.itemName;
			}
		})
	}
	return result
}

export function formateDayTable(row, column, cellValue) {
	if (cellValue) {
		return new Date(cellValue).Format("yyyy-MM-dd")
	} else {
		return "/"
	}
}

export function clearData(data1, data2) {
	//reserveArr保留字段
	Object.assign(data1, data2)
}
// 格式化日期 yyyy/mm/dd hh/mm/ss
export function getTimeStrByDate(date) {
	var y = date.getFullYear();
	var M = date.getMonth() + 1;
	var d = date.getDate();
	var H = date.getHours();
	var m = date.getMinutes();
	var s = date.getSeconds();
	return y + '-' + (M < 10 ? ('0' + M) : M) + '-' + (d < 10 ? ('0' + d) : d) + " " + (H < 10 ? ('0' + H) : H) + ":" + (m < 10 ? ('0' + m) : m) + ":" + (s < 10 ? ('0' + s) : s);
}
export function formatTime(date, format) {
	if (!date) {
		return ''
	}

	let d = new Date(date)

	// 年
	if (/YYYY/.test(format)) {
		format = format.replace(/YYYY/, d.getFullYear())
	}
	// 月份
	let month = d.getMonth() + 1
	if (/MM/.test(format)) {
		let monthStr = month < 10 ? '0' + month : month
		format = format.replace(/MM/, monthStr)
	} else if (/M/.test(format)) {
		format = format.replace(/M/, month)
	}
	// 日期
	let dates = d.getDate()
	if (/DD/.test(format)) {
		let dateStr = dates < 10 ? '0' + dates : dates
		format = format.replace(/DD/, dateStr)
	} else if (/D/.test(format)) {
		format = format.replace(/D/, dates)
	}
	// 小时
	let hours = d.getHours()
	if (/HH/.test(format)) {
		let hoursStr = hours < 10 ? '0' + hours : hours
		format = format.replace(/HH/, hoursStr)
	} else if (/H/.test(format)) {
		format = format.replace(/H/, hours)
	} else if (/hh/.test(format)) {
		let hoursMin = hours > 12 ? hours - 12 : hours
		let hoursStr = hoursMin < 10 ? '0' + hoursMin : hoursMin
		format = format.replace(/hh/, hoursStr)
	} else if (/h/.test(format)) {
		let hoursMin = hours > 12 ? hours - 12 : hours
		format = format.replace(/h/, hoursMin)
	}
	// 分
	let minutes = d.getMinutes()
	if (/mm/.test(format)) {
		let minutesStr = minutes < 10 ? '0' + minutes : minutes
		format = format.replace(/mm/, minutesStr)
	} else if (/m/.test(format)) {
		format = format.replace(/m/, minutes)
	}
	// 秒
	let seconds = d.getSeconds()
	if (/ss/.test(format)) {
		let secondsStr = seconds < 10 ? '0' + seconds : seconds
		format = format.replace(/ss/, secondsStr)
	} else if (/s/.test(format)) {
		format = format.replace(/s/, seconds)
	}
	return format
}

//行级鉴权
export function rowAuth(arr, authStr) {
	if (!arr) {
		return false
	}
	return arr.indexOf(authStr) != -1
}

//获取upload的文件列表
export function getUploadList(list) {
	let arr = [];
	for (let i = 0; i < list.length; i++) {
		let url = "";
		if (list[i].url) {
			url = list[i].url
		} else {
			if (list[i].response && list[i].response.data && list[i].response.data.entities && list[i].response.data.entities[0]) {
				url = list[i].response.data.entities[0]
			}
		}
		arr.push({
			name: list[i].name,
			url: url,
			size: list[i].size,
		})
	}
	return arr;
}

//使用post下载二进制流文件
export function postDownLoad(res, fileName) {
	const content = res
	const blob = new Blob([content])
	if ('download' in document.createElement('a')) { // 非IE下载
		const elink = document.createElement('a')
		elink.download = fileName
		elink.style.display = 'none'
		elink.href = URL.createObjectURL(blob)
		document.body.appendChild(elink)
		elink.click()
		URL.revokeObjectURL(elink.href) // 释放URL 对象
		document.body.removeChild(elink)
	} else { // IE10+下载
		navigator.msSaveBlob(blob, fileName)
	}
}

export function GetWeekDate() {
	var now = new Date(); //当前日期   
	var nowDayOfWeek = now.getDay(); //今天本周的第几天  
	var nowYear = now.getYear(); //当前年   
	var nowMonth = now.getMonth(); //月   
	var nowDay = now.getDate(); //日   
	var beginHour = "09:00:00";
	var endHour = "23:59:59";

	nowYear += (nowYear < 2000) ? 1900 : 0; //  
	nowDayOfWeek = nowDayOfWeek == 0 ? 7 : nowDayOfWeek; // 如果是周日，就变成周七  
	return {
		begin: new Date(nowYear, nowMonth, nowDay - nowDayOfWeek + 1),
		end: new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek + 1))
	}
}

GetWeekDate.prototype.date2str = function (x, y) { //date2str(new Date(curTime),"yyyy-MM-dd")  
	var z = {
		y: x.getFullYear(),
		M: x.getMonth() + 1,
		d: x.getDate(),
		h: x.getHours(),
		m: x.getMinutes(),
		s: x.getSeconds()
	};
	return y.replace(/(y+|M+|d+|h+|m+|s+)/g, function (v) {
		return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-(v.length > 2 ? v.length : 2))
	});
}

GetWeekDate.prototype.formatDate = function (date) { //格局化日期：yyyy-MM-dd   
	var myyear = date.getFullYear();
	var mymonth = date.getMonth() + 1;
	var myweekday = date.getDate();
	//alert("formatDate"+myyear+":"+mymonth+":"+myweekday)  
	if (mymonth < 10) {
		mymonth = "0" + mymonth;
	}
	if (myweekday < 10) {
		myweekday = "0" + myweekday;
	}
	return (myyear + "-" + mymonth + "-" + myweekday);
}

GetWeekDate.prototype.getWeekStartDate = function () { //获得本周的开端日期  
	var weekStartDate = new Date(this.nowYear, this.nowMonth, this.nowDay - this.nowDayOfWeek + 1);
	return this.formatDate(weekStartDate);
}

GetWeekDate.prototype.getWeekEndDate = function () { //获得本周的停止日期  
	var weekEndDate = new Date(this.nowYear, this.nowMonth, this.nowDay + (6 - this.nowDayOfWeek + 1));
	return this.formatDate(weekEndDate);
}

GetWeekDate.prototype.getAWeedkYMD = function () { //获得本周周一~周日的年月日  
	var ymdArr = [];
	for (var i = 0; i < 7; i++) {
		ymdArr[i] = [];
		//ymdArr[i][0]=this.formatDate(new Date(this.nowYear, this.nowMonth, this.nowDay - this.nowDayOfWeek+i+1));  
		ymdArr[i][0] = this.date2str(new Date(this.nowYear, this.nowMonth, this.nowDay - this.nowDayOfWeek + i + 1),
			'yyyy-MM-dd');
		ymdArr[i][1] = this.date2str(new Date(this.nowYear, this.nowMonth, this.nowDay - this.nowDayOfWeek + i + 1),
			'MM月dd日');
	};

	return ymdArr;
}
GetWeekDate.prototype.getQishu = function (time) { //获得本周是指定日期(7.12日)的第几周  
	var oNDate = new Date(time); //系统当前时间  
	var oEDate = new Date('2015/7/12 23:59:59');
	var diff = oNDate.getTime() - oEDate.getTime();
	//console.log(diff)  
	//console.log("相差天："+diff/(24*60*60*1000))  
	//console.log("相差期数："+parseInt(diff/(24*60*60*1000))/7)  
	//console.log("取整期数："+Math.floor(parseInt(diff/(24*60*60*1000))/7))  
	return (diff / (24 * 60 * 60 * 1000)) / 7;
}

GetWeekDate.prototype.getWeeksDates = function (time) { //获取历史周排行的周一到周日时间段  
	var qishu = (this.qishu || this.qishu == 0) ? this.qishu : this.getQishu(time);
	//var qishu=this.getQishu(time);  

	var WeeksTimes = []; //存放时间的二维数组  
	minYear = 2015,
		minMonth = 7,
		minDay = 12;
	for (var i = 0; i < qishu; i++) {
		var sday;
		var eday;
		WeeksTimes[i] = [];
		if (i == 0) { //如果离2015-07-12只相差1期  
			sday = minDay + 1; //开始时间+1天  
		} else { //如果离2015-07-12相差期数>1  
			sday = minDay + 1 + 7 * i;
		}
		eday = minDay + 7 * (i + 1); //结束时间+7天  
		WeeksTimes[i][0] = this.formatDate(new Date(minYear, minMonth - 1, sday)) + " " + this.beginHour;
		WeeksTimes[i][1] = this.formatDate(new Date(minYear, minMonth - 1, eday)) + " " + this.endHour;
	};
	//如果是一周的周日就不要减去一期  
	return WeeksTimes;

}

export function getDiffDate(s1, s2) {
	var mons = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
	let data = diff(s1, s2);
	return data;

	function isLeapYear(year) {
		var r = year / 100
		if (r === parseInt(r)) {
			r = year / 400
			return r === parseInt(r)
		}
		r = year / 4
		if (r === parseInt(r)) {
			return true
		}
		return false
	}

	function getDaysOfMonth(month, year) {
		if (month === 2 && isLeapYear(year)) {
			return 29
		}
		return mons[month]
	}

	function getMonthsOfYear(year) {
		if (isLeapYear(year)) {
			return 366
		}
		return 365
	}

	function diff(s1, s2) {
		var arr1 = s1.split('-').map(Number)
		var arr2 = s2.split('-').map(Number)
		var [year, month, day] = arr2.map((n, i) => n - arr1[i])
		if (day < 0) {
			day += getDaysOfMonth(arr2[1], arr2[0])
			month--
		}
		if (month < 0) {
			month += getMonthsOfYear(arr2[0])
			year--
		}
		return [year, month, day]
	}
}

export function numberChinese(str) {
	var num = parseFloat(str);
	var strOutput = "",
		strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分';
	num += "00";
	var intPos = num.indexOf('.');
	if (intPos >= 0) {
		num = num.substring(0, intPos) + num.substr(intPos + 1, 2);
	}
	strUnit = strUnit.substr(strUnit.length - num.length);
	for (var i = 0; i < num.length; i++) {
		strOutput += '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i, 1), 1) + strUnit.substr(i, 1);
	}
	return strOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零').replace(/零([亿|万])/g, '$1').replace(
		/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, "零元")
}

//移动端删除colunms的操作列的fixed属性
export function deleteFixed(column) {
	if (column && column.length > 0) {
		//最后一行配置删除fixed属性
		delete column[column.length - 1].fixed;
	}
	return column
}

/**
 * 验证身份证
 * @return {boolean}
 */
export function checkIdCard(idCard, required = false) {
	let msg = '';
	let status = true;

	if (required && !idCard) {
		msg = '请输入身份证';
		status = false;
	}
	//不是15或者18，位数不对
	else if (15 !== idCard.length && idCard.length !== 18) {
		msg = '身份证号码位数不对';
		status = false;
	} else if (!checkArea(idCard)) {
		msg = '身份证地区不正确';
		status = false;
	} else if (!checkBirth(idCard)) {
		msg = '身份证号码出生日期不正确';
		status = false;
	} else if (idCard.length === 18 && !check18Code(idCard)) {
		msg = '身份证号码校验错误';
		status = false;
	}

	console.log('checkIdCard msg', msg);
	return msg;


	/**
	 * 验证身份证的地区码
	 * @param {Object} idCard 身份证字符串
	 */
	function checkArea(idCard) {
		// 区域ID
		let areaMap = {
			11: "北京",
			12: "天津",
			13: "河北",
			14: "山西",
			15: "内蒙古",
			21: "辽宁",
			22: "吉林",
			23: "黑龙江",
			31: "上海",
			32: "江苏",
			33: "浙江",
			34: "安徽",
			35: "福建",
			36: "江西",
			37: "山东",
			41: "河南",
			42: "湖北",
			43: "湖南",
			44: "广东",
			45: "广西",
			46: "海南",
			50: "重庆",
			51: "四川",
			52: "贵州",
			53: "云南",
			54: "西藏",
			61: "陕西",
			62: "甘肃",
			63: "青海",
			64: "宁夏",
			65: "新疆",
			71: "台湾",
			81: "香港",
			82: "澳门",
			91: "国外"
		};

		return areaMap[parseInt(idCard.substr(0, 2))];
	}

	/**
	 * 验证身份证号码中的生日是否是有效生日
	 * @param idCard 身份证字符串
	 * @return
	 */
	function checkBirth(idCard) {
		let result = true;

		if (15 === idCard.length) {
			let year = idCard.substring(6, 8);
			let month = idCard.substring(8, 10);
			let day = idCard.substring(10, 12);
			let date = new Date(year, parseFloat(month) - 1, parseFloat(day));

			// 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
			if (date.getYear() !== parseFloat(year) || date.getMonth() !== parseFloat(month) - 1 || date.getDate() !==
				parseFloat(day)) {
				result = false;
			}
		} else if (18 === idCard.length) {
			let year = idCard.substring(6, 10);
			let month = idCard.substring(10, 12);
			let day = idCard.substring(12, 14);
			let date = new Date(year, parseFloat(month) - 1, parseFloat(day));

			// 这里用getFullYear()获取年份，避免千年虫问题
			if (date.getFullYear() !== parseFloat(year) || date.getMonth() !== parseFloat(month) - 1 || date.getDate() !==
				parseFloat(day)) {
				result = false;
			}
		} else {
			result = false;
		}

		return result;
	}

	/**
	 * 判断身份证号码为18位时最后的验证位是否正确
	 * @param idCard 身份证号码
	 * @return
	 */
	function check18Code(idCard) {

		idCard = idCard.split('');
		//∑(ai×Wi)(mod 11)
		//加权因子
		let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
		//校验位
		let parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
		let sum = 0;
		let ai = 0;
		let wi = 0;
		for (let i = 0; i < 17; i++) {
			ai = idCard[i];
			wi = factor[i];
			sum += ai * wi;
		}

		// let check = parity[sum % 11];
		// let up = idCard[17].toUpperCase();
		// console.log('check', check);
		// console.log('up', up);
		return parity[sum % 11] + '' === idCard[17].toUpperCase();
	}

}

export function addmulMonth(dtstr, n) { // n个月后
	var s = dtstr.split("-");
	var yy = parseInt(s[0]);
	var mm = parseInt(s[1] - 1);
	var dd = parseInt(s[2]);
	var dt = new Date(yy, mm, dd);
	dt.setMonth(dt.getMonth() + n);
	if ((dt.getFullYear() * 12 + dt.getMonth()) > (yy * 12 + mm + n)) {
		dt = new Date(dt.getFullYear(), dt.getMonth(), 0);
	}
	var year = dt.getFullYear();
	var month = dt.getMonth() + 1;
	var days = dt.getDate();
	var dd = year + "-" + month + "-" + days;
	return dd;
}

/**
   * @desc 函数防抖
   * @param func 目标函数
   * @param wait 延迟执行毫秒数
   * @param immediate true - 立即执行， false - 延迟执行
   */
export function debounce(func, wait, immediate) {
	let timer;
	return function () {
		let context = this,
			args = arguments;

		if (timer) clearTimeout(timer);
		if (immediate) {
			let callNow = !timer;
			timer = setTimeout(() => {
				timer = null;
			}, wait);
			if (callNow) func.apply(context, args);
		} else {
			timer = setTimeout(() => {
				func.apply
			}, wait)
		}
	}
}
