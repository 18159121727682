/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-08-16T18:06:35.505
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'financial-financialpreorder-'

export default 
{
path: '/financial/financialpreorder',
name: 'financial-financialpreorder',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}financialpreorder-index',

meta:{
...meta,
title: '财务预支付帐单'
},

component: () => import('@/pages/financial/financialpreorder/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}financialpreorder-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '财务预支付帐单编辑'
},

component: () => import('@/pages/financial/financialpreorder/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}financialpreorderdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '财务预支付帐单新增'
},

component: () => import('@/pages/financial/financialpreorder/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}financialpreorderView',
auth: [
'hidden'
],

meta:{
...meta,
title: '财务预支付帐单详情查看'
},

component: () => import('@/pages/financial/financialpreorder/detail-view') ,
},
]
};
