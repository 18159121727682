/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-05-16T17:00:11.371
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveEvaluateAppointmentFinal,
	getEvaluateAppointmentFinalCreateInit,
	getEvaluateAppointmentFinalEditInit,
	getEvaluateAppointmentFinalView,
	deleteEvaluateAppointmentFinal,
    queryReportReturnAssetsEvalfactor
} from '@api/storagemanage/EvaluateAppointmentFinal';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listEvaluateAppointmentFinalRejectionInit,
listEvaluateAppointmentFinalRejectionByAuthorizations,
deleteEvaluateAppointmentFinalRejection
} from '@api/storagemanage/EvaluateAppointmentFinalRejection';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getEvaluateAppointmentFinalCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateAppointmentFinalCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateAppointmentFinalEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateAppointmentFinalEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveEvaluateAppointmentFinal({}, EvaluateAppointmentFinal) {
			return new Promise((resolve, reject) => {
				saveEvaluateAppointmentFinal(EvaluateAppointmentFinal)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateAppointmentFinalView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateAppointmentFinalView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateAppointmentFinal({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateAppointmentFinal({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
        
        queryReportReturnAssetsEvalfactor({
        	dispatch,
        	state,
        }, obj) {
        	return new Promise((resolve, reject) => {
        		queryReportReturnAssetsEvalfactor(obj)
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
	}
};
