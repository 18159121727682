import util from '@/libs/util';
import router from '@/router';
import {
	listStockpileIntention,
	listStockpileIntentionInit,
	listStockpileIntentionByAuthorizations,
	deleteStockpileIntention,
	listStockpileIntentionAcceptance,
	listStockpileIntentionToBusiness,
	batchAssignmentToBusiness,
	listStockpileIntentionToCommissioner,
	batchAssignmentToCommissioner,
	listStockpileIntentionToAssessment,
	batchAssignmentToAssessment,
	listStockpileIntentionToChannelAssessment,
	batchAssignmentToChannelAssessment,
	getEvaluateAppointmentList,
	listStockpileIntentionBySQL,
	withdraw

} from '@api/storagemanage/StockpileIntention';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
		//查询条件
		reqParams: {
			houseCode:'',
			bizType:'',
			roomFullName:'',
			billCode: '',
			billStatus: '',
			billSource: '',
			schemeStoreType:'',
			ownerType:'',
			ownerName:'',
			tableName:'',
			isPage: true,
			pageIndex: 1,
			pageSize: 10,
		},
	},

	actions: {
		//委托单列表
		listStockpileIntentionBySQL({
			state
		},data) {
			return new Promise((resolve, reject) => {
				
				
				state.AdvanceQuery.pageIndex=data.pageIndex;
				state.AdvanceQuery.entity.houseCode=data.houseCode;
				state.AdvanceQuery.entity.billCode=data.billCode;
				state.AdvanceQuery.entity.roomFullName=data.roomFullName;
				state.AdvanceQuery.entity.billStatus=data.billStatus;
				state.AdvanceQuery.entity.billSource=data.billSource;
				state.AdvanceQuery.entity.schemeStoreType=data.schemeStoreType;
				state.AdvanceQuery.entity.bizType=data.bizType;
				state.AdvanceQuery.entity.ownerType=data.ownerType;
				state.AdvanceQuery.entity.ownerName=data.ownerName; 
				listStockpileIntentionBySQL(state.AdvanceQuery).then(async res => {
					state.CompoundEntitiesDictionary = res;
					resolve(res);
				})
				.catch(err => {
					reject(err);
				})
			})
		},
		
		//平台指派商家列表数据
		listStockpileIntentionToBusiness({
			state
		},data) {
			return new Promise((resolve, reject) => {
				state.AdvanceQuery.pageIndex=data.pageIndex;
				state.AdvanceQuery.entity.houseCode=data.houseCode;
				state.AdvanceQuery.entity.billCode=data.billCode;
				state.AdvanceQuery.entity.houseAddress=data.houseAddress;
				state.AdvanceQuery.entity.distributeStatus=data.distributeStatus;
				state.AdvanceQuery.entity.ownerType="";
				listStockpileIntentionToBusiness(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		
		listStockpileIntention({
			state
		}) {
			return new Promise((resolve, reject) => {
				listStockpileIntention(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listStockpileIntentionByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listStockpileIntentionByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities = res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listStockpileIntentionInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listStockpileIntentionInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		withdraw({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				let data={};
				data.billItemId=id;
				withdraw(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteStockpileIntention({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				deleteStockpileIntention({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
        listStockpileIntentionAcceptance({//批量受理/不受理--委托
			dispatch,
			state,
		},data) {
			return new Promise((resolve, reject) => {
				listStockpileIntentionAcceptance(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		batchAssignmentToBusiness({//批量指派--给商家
			dispatch,
			state,
		},data) {
			return new Promise((resolve, reject) => {
				batchAssignmentToBusiness(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		batchAssignmentToCommissioner({//商家批量指派--给商家专员
			dispatch,
			state,
		},data) {
			return new Promise((resolve, reject) => {
				batchAssignmentToCommissioner(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		listStockpileIntentionToCommissioner({//商家批量指派给专员的列表
			dispatch,
			state,
		},data) {
			return new Promise((resolve, reject) => {
				state.AdvanceQuery.pageIndex=data.pageIndex;
				state.AdvanceQuery.entity=data;
				listStockpileIntentionToCommissioner(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listStockpileIntentionToAssessment({//存房委托指派-商家存房专员指派评估公司列表
			dispatch,
			state,
		},data) {
			return new Promise((resolve, reject) => {
				state.AdvanceQuery.pageIndex=data.pageIndex;
				state.AdvanceQuery.entity=data;
				listStockpileIntentionToAssessment(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getEvaluateAppointmentList({//预约评估动态列表
			dispatch,
			state,
		},data) {
			return new Promise((resolve, reject) => {
				state.AdvanceQuery.pageIndex=data.pageIndex;
				state.AdvanceQuery.entity=data;
				getEvaluateAppointmentList(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		batchAssignmentToAssessment({//存房委托指派-商家存房专员批量指派给评估公司
			dispatch,
			state,
		},data) {
			return new Promise((resolve, reject) => { 
				batchAssignmentToAssessment(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},	
		listStockpileIntentionToChannelAssessment({//存房委托指评-估公司（渠道）指派评估专员列表
			dispatch,
			state,
		},data) {
			return new Promise((resolve, reject) => {
				state.AdvanceQuery.pageIndex=data.pageIndex;
				state.AdvanceQuery.entity=data;
				listStockpileIntentionToChannelAssessment(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		batchAssignmentToChannelAssessment({//存房委托指评-估公司（渠道）指派评估专员
			dispatch,
			state,
		},data) {
			return new Promise((resolve, reject) => {
				batchAssignmentToChannelAssessment(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},	 
	}
};
