/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-02T19:10:02.064
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentRepairWorkOrderAssignInit(data){return request({
url: '/rentmanage/api/rentRepairWorkOrderAssign/listRentRepairWorkOrderAssignInit',
method: 'post',
data:data,
});
}


export function listRentRepairWorkOrderAssignByAuthorizations(data){return request({
url: '/rentmanage/api/rentRepairWorkOrderAssign/listRentRepairWorkOrderAssignByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentRepairWorkOrderAssign(data){return request({
url: '/rentmanage/api/rentRepairWorkOrderAssign/listRentRepairWorkOrderAssign',
method: 'post',
data:data,
});
}


export function getRentRepairWorkOrderAssignCreateInit(){return request({
url: '/rentmanage/api/rentRepairWorkOrderAssign/getRentRepairWorkOrderAssignCreateInit',
method: 'post',
});
}


export function getRentRepairWorkOrderAssignEditInit(params){return request({
url: '/rentmanage/api/rentRepairWorkOrderAssign/getRentRepairWorkOrderAssignEditInit',
method: 'post',
params:params,
});
}


export function getRentRepairWorkOrderAssignView(params){return request({
url: '/rentmanage/api/rentRepairWorkOrderAssign/getRentRepairWorkOrderAssignView',
method: 'post',
params:params,
});
}


export function saveRentRepairWorkOrderAssign(data){return request({
url: '/rentmanage/api/rentRepairWorkOrderAssign/saveRentRepairWorkOrderAssign',
method: 'post',
data:data,
});
}

export function withdrawRentRepairWorkOrderAssign(data){return request({
	url: '/rentmanage/api/rentRepairWorkOrderAssign/withdrawRentRepairWorkOrderAssign',
	method: 'post',
	data:data,
	});
	}

export function deleteRentRepairWorkOrderAssign(params){return request({
url: '/rentmanage/api/rentRepairWorkOrderAssign/deleteRentRepairWorkOrderAssign',
method: 'post',
params:params,
});
}


