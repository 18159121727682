import axios from 'axios'
import {isWecaht, jumpSSOLogin} from '@/libs/util'
import store from '@/store'
import Setting from '@/setting'
import router from '@/router'
import storage from '@/libs/vendors/storage'

import {
  Message,
  Notice
} from 'view-design'

// 创建一个错误
const errorCreate = (msg) => {
  const err = new Error(msg)

  errorLog(err)
  throw err
}
// 记录和显示错误
const errorLog = (err) => {
  // 打印到控制台
  if (process.env.VUE_APP_NODE_ENV !== 'production') console.error(err)
  // 显示提示，可配置使用 iView 的 $Message 还是 $Notice 组件来显示
  if (Setting.errorModalType === 'Message') {
    Message.error({
      content: err.message,
      duration: Setting.modalDuration
    });
  } else if (Setting.errorModalType === 'Notice') {
    Notice.error({
      title: '提示',
      desc: err.message,
      duration: Setting.modalDuration
    })
  }
}

// 创建一个 axios 实例
const service = axios.create({
  baseURL: Setting.apiBaseURL,
  timeout: 360000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    const token = storage.get('token')
    const platform = storage.get('platform')
    const { userCode, version, sassCompany, saasCompany } = storage.get('user', {})

    // 平台H5，使用旧token
    if (platform === 'h5') {
      if (token) config.headers['token'] = token
      if (sassCompany) config.headers['saasCompany'] = sassCompany
    } else {
      if (token) config.headers['Authorization'] = `Bearer ${token}`
      if (saasCompany) config.headers['saasCompany'] = saasCompany
      config.headers['requestSource'] = 'oauth2Pc'
    }
    if (version) config.headers['version'] = version
    if (userCode) config.headers['userCode'] = userCode
    return config
  },
  error => {
    // 发送失败
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;
    // 这个状态码是和后端约定的
    const {
      respCode
    } = dataAxios;
    // 根据 respCode 进行判断
    if (respCode === undefined) {
      // 如果没有 respCode 代表这不是项目后端开发的接口
      return dataAxios;
    } else {
      // 有 respCode 代表这是一个后端接口 可以进行进一步的判断
      switch (respCode) {
        case '0000':
          // [ 示例 ] respCode === 0000 代表没有错误
          if (dataAxios.overview && dataAxios.overview != '成功') {
            Message.success({
              content: `${dataAxios.overview}`,
              duration: Setting.modalDuration
            });
          }
          return dataAxios.data;
        case '0008':
          const { userType } = storage.get('user', {})
          //租客
          if (userType == '6' || location.href.includes('rentuserlogin')) {
            // 直接回调登录
            if (storage.get('platform') === 'h5' && isWecaht()) return location.href = Setting.wxCallBackURL
          } else if (dataAxios.overview === '用户不存在') {
            router.push({ name: '403' })
          } else {
            jumpSSOLogin({logout: true})
            // 清空用户信息
            store.dispatch('admin/user/set', {})
          }
          errorCreate(`${dataAxios.overview}`);
          break;
        case '2001':
          return dataAxios;
        case '2003':
          return dataAxios;
        default:
          // 不是正确的 respCode
          errorCreate(`${dataAxios.overview}`);
          break;
      }
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '请求错误';
          break;
        case 401:
          error.message = '未授权，请登录';
          break;
        case 403:
          error.message = '拒绝访问';
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = '请求超时';
          break;
        case 500:
          error.message = '服务器内部错误';
          break;
        case 501:
          error.message = '服务未实现';
          break;
        case 502:
          error.message = '网关错误';
          break;
        case 503:
          error.message = '服务不可用';
          break;
        case 504:
          error.message = '网关超时';
          break;
        case 505:
          error.message = 'HTTP版本不受支持';
          break;
        default:
          break;
      }
    }
    errorLog(error);
    return Promise.reject(error)
  }
)

export default service
