/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-07-02T16:30:29.146
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportfinancialrentcontractitempaymentcollectionmonth-'

export default 
{
path: '/report/reportfinancialrentcontractitempaymentcollectionmonth',
name: 'report-reportfinancialrentcontractitempaymentcollectionmonth',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportfinancialrentcontractitempaymentcollectionmonth-index',

meta:{
...meta,
title: '应收追缴报表（月）'
},

component: () => import('@/pages/report/reportfinancialrentcontractitempaymentcollectionmonth/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportfinancialrentcontractitempaymentcollectionmonth-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '应收追缴报表（月）编辑'
},

component: () => import('@/pages/report/reportfinancialrentcontractitempaymentcollectionmonth/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportfinancialrentcontractitempaymentcollectionmonthdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '应收追缴报表（月）新增'
},

component: () => import('@/pages/report/reportfinancialrentcontractitempaymentcollectionmonth/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportfinancialrentcontractitempaymentcollectionmonthView',
auth: [
'hidden'
],

meta:{
...meta,
title: '应收追缴报表（月）详情查看'
},

component: () => import('@/pages/report/reportfinancialrentcontractitempaymentcollectionmonth/detail-view') ,
},
]
};
