/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-23T14:44:27.032
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-renthousegoods-'

export default 
{
path: '/rentmanage/renthousegoods',
name: 'rentmanage-renthousegoods',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}renthousegoods-index',

meta:{
...meta,
title: '房源物品清单表'
},

component: () => import('@/pages/rentmanage/renthousegoods/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}renthousegoods-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源物品清单表编辑'
},

component: () => import('@/pages/rentmanage/renthousegoods/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}renthousegoodsdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源物品清单表新增'
},

component: () => import('@/pages/rentmanage/renthousegoods/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}renthousegoodsView',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源物品清单表详情查看'
},

component: () => import('@/pages/rentmanage/renthousegoods/detail-view') ,
},
]
};
