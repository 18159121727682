import BasicLayout from '@/layouts/basic-layout'; 
const meta = {
	auth: true
};

const pre = 'rentmanage-RentRepairWorkOrderHandAssign-';

export default {
	path: '/rentmanage/rentrepairworkorderhandassign',
	name: '工单指派',
	redirect: {
		name: `rentmanage-RentRepairWorkOrderHandAssign`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: '/rentmanage/rentrepairworkorderhandassign',
		name: 'rentmanage-RentRepairWorkOrderHandAssign',
		meta: {
			...meta,
			title: '工单指派',
			closable: true
		},
		component: () => import('@/pages/rentmanage/rentrepairworkorderhandassign/index')
	}]
};
