/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-01T17:31:37.293
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getFinancialAdjustmentApplyCollectionEditInit,
	getFinancialAdjustmentApplyCollectionView
} from '@api/financial/FinancialAdjustmentApplyCollection';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},

	actions: {
		getFinancialAdjustmentApplyCollectionEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyCollectionEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialAdjustmentApplyCollectionView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialAdjustmentApplyCollectionView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
