/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-08-24T17:05:26.902
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-renthouselocklog-'

export default 
{
path: '/rentmanage/renthouselocklog',
name: 'rentmanage-renthouselocklog',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}renthouselocklog-index',

meta:{
...meta,
title: '门锁密码'
},

component: () => import('@/pages/rentmanage/renthouselocklog/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}renthouselocklog-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '门锁密码编辑'
},

component: () => import('@/pages/rentmanage/renthouselocklog/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}renthouselocklogdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '门锁密码新增'
},

component: () => import('@/pages/rentmanage/renthouselocklog/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}renthouselocklogView',
auth: [
'hidden'
],

meta:{
...meta,
title: '门锁密码详情查看'
},

component: () => import('@/pages/rentmanage/renthouselocklog/detail-view') ,
},
]
};
