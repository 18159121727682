/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-21T17:44:56.161
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportWorkflowCountInit(data){return request({
url: '/report/api/reportWorkflowCount/listReportWorkflowCountInit',
method: 'post',
data:data,
});
}


export function listReportWorkflowCountByAuthorizations(data){return request({
url: '/report/api/reportWorkflowCount/listReportWorkflowCountByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportWorkflowCount(data){return request({
url: '/report/api/reportWorkflowCount/listReportWorkflowCount',
method: 'post',
data:data,
});
}


export function getReportWorkflowCountCreateInit(){return request({
url: '/report/api/reportWorkflowCount/getReportWorkflowCountCreateInit',
method: 'post',
});
}


export function getReportWorkflowCountEditInit(params){return request({
url: '/report/api/reportWorkflowCount/getReportWorkflowCountEditInit',
method: 'post',
params:params,
});
}


export function getReportWorkflowCountView(params){return request({
url: '/report/api/reportWorkflowCount/getReportWorkflowCountView',
method: 'post',
params:params,
});
}


export function saveReportWorkflowCount(data){return request({
url: '/report/api/reportWorkflowCount/saveReportWorkflowCount',
method: 'post',
data:data,
});
}


export function deleteReportWorkflowCount(params){return request({
url: '/report/api/reportWorkflowCount/deleteReportWorkflowCount',
method: 'post',
params:params,
});
}


