/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-28T14:19:44.589
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialCollectionDetailInit(data){return request({
url: '/financial/api/financialCollectionDetail/listFinancialCollectionDetailInit',
method: 'post',
data:data,
});
}


export function listFinancialCollectionDetailByAuthorizations(data){return request({
url: '/financial/api/financialCollectionDetail/listFinancialCollectionDetailByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialCollectionDetail(data){return request({
url: '/financial/api/financialCollectionDetail/listFinancialCollectionDetail',
method: 'post',
data:data,
});
}


export function getFinancialCollectionDetailCreateInit(){return request({
url: '/financial/api/financialCollectionDetail/getFinancialCollectionDetailCreateInit',
method: 'post',
});
}


export function getFinancialCollectionDetailEditInit(params){return request({
url: '/financial/api/financialCollectionDetail/getFinancialCollectionDetailEditInit',
method: 'post',
params:params,
});
}


export function getFinancialCollectionDetailView(params){return request({
url: '/financial/api/financialCollectionDetail/getFinancialCollectionDetailView',
method: 'post',
params:params,
});
}


export function saveFinancialCollectionDetail(data){return request({
url: '/financial/api/financialCollectionDetail/saveFinancialCollectionDetail',
method: 'post',
data:data,
});
}


export function deleteFinancialCollectionDetail(params){return request({
url: '/financial/api/financialCollectionDetail/deleteFinancialCollectionDetail',
method: 'post',
params:params,
});
}


