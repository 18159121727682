/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-07T09:38:27.963
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listCCBContractFeesInit(data){return request({
url: '/house/api/cCBContractFees/listCCBContractFeesInit',
method: 'post',
data:data,
});
}


export function listCCBContractFeesByAuthorizations(data){return request({
url: '/house/api/cCBContractFees/listCCBContractFeesByAuthorizations',
method: 'post',
data:data,
});
}


export function listCCBContractFees(data){return request({
url: '/house/api/cCBContractFees/listCCBContractFees',
method: 'post',
data:data,
});
}


export function getCCBContractFeesCreateInit(){return request({
url: '/house/api/cCBContractFees/getCCBContractFeesCreateInit',
method: 'post',
});
}


export function getCCBContractFeesEditInit(params){return request({
url: '/house/api/cCBContractFees/getCCBContractFeesEditInit',
method: 'post',
params:params,
});
}


export function getCCBContractFeesView(params){return request({
url: '/house/api/cCBContractFees/getCCBContractFeesView',
method: 'post',
params:params,
});
}


export function saveCCBContractFees(data){return request({
url: '/house/api/cCBContractFees/saveCCBContractFees',
method: 'post',
data:data,
});
}


export function deleteCCBContractFees(params){return request({
url: '/house/api/cCBContractFees/deleteCCBContractFees',
method: 'post',
params:params,
});
}


