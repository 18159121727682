import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: false
};

const pre = '/smallapplication/';

export default {
    path: `${pre}publishAdviceReply`,
    name: 'publishAdviceReply',
	meta: {
		...meta,
		title: '员工回复',
		closable: false
	},
	component: () => import('@/pages/smallapplication/publishAdvice/reply')
};
