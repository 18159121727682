/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-07-20T16:51:35.103
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: true
};

const pre = 'rentmanage-FinancialPayApply-'

export default {
    path: '/rentmanage/financialpayapply',
    name: 'rentmanage-FinancialPayApply',
    redirect: {
        name: '${pre}-index'
    },

    meta: {
        ...meta,
    },

    component: BasicLayout,
    children: [{
            path: 'index',
            name: '${pre}FinancialPayApply-index',

            meta: {
                ...meta,
                title: '付款申请'
            },

            component: () => import('@/pages/rentmanage/financialpayapply/index'),
        },
        {
            path: 'detail-edit/:id',
            name: '${pre}FinancialPayApply-detail-edit',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '付款申请编辑'
            },

            component: () => import('@/pages/rentmanage/financialpayapply/detail-edit'),
        },
        {
            path: 'detail-edit',
            name: '${pre}FinancialPayApplydetail-create',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '付款申请新增'
            },

            component: () => import('@/pages/rentmanage/financialpayapply/detail-edit'),
        },
        {
            path: 'detail-view/:id',
            name: '${pre}FinancialPayApplyView',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '付款申请详情查看'
            },

            component: () => import('@/pages/rentmanage/financialpayapply/detail-view'),
        },
    ]
};
