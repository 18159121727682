/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T16:23:43.385
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentPropertyDeliveryItem,
	listRentPropertyDeliveryItemInit,
	listRentPropertyDeliveryItemByAuthorizations,
	deleteRentPropertyDeliveryItem,
    downloadTemplate,
    saveRentPropertyDeliveryItemList,
	downloadRentTemplate
} from '@api/rentmanage/RentPropertyDeliveryItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentPropertyDeliveryItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentPropertyDeliveryItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentPropertyDeliveryItemByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentPropertyDeliveryItemByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentPropertyDeliveryItemInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentPropertyDeliveryItemInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
                        state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentPropertyDeliveryItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentPropertyDeliveryItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
        
        downloadTemplate({
        	dispatch,
        	state,
        }, data) {
        	return new Promise((resolve, reject) => {
        		downloadTemplate(data)
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
		
		downloadRentTemplate({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				downloadRentTemplate(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
        
        saveRentPropertyDeliveryItemList({
            
        }, deliveryItemList) {
        	return new Promise((resolve, reject) => {
        		saveRentPropertyDeliveryItemList(deliveryItemList)
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },

	}
};
