/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-01T19:13:36.439
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listConDeputeContractBackSettlementItemInit(data){return request({
url: '/storagemanage/api/conDeputeContractBackSettlementItem/listConDeputeContractBackSettlementItemInit',
method: 'post',
data:data,
});
}


export function listConDeputeContractBackSettlementItemByAuthorizations(data){return request({
url: '/storagemanage/api/conDeputeContractBackSettlementItem/listConDeputeContractBackSettlementItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listConDeputeContractBackSettlementItem(data){return request({
url: '/storagemanage/api/conDeputeContractBackSettlementItem/listConDeputeContractBackSettlementItem',
method: 'post',
data:data,
});
}


export function getConDeputeContractBackSettlementItemCreateInit(){return request({
url: '/storagemanage/api/conDeputeContractBackSettlementItem/getConDeputeContractBackSettlementItemCreateInit',
method: 'post',
});
}


export function getConDeputeContractBackSettlementItemEditInit(params){return request({
url: '/storagemanage/api/conDeputeContractBackSettlementItem/getConDeputeContractBackSettlementItemEditInit',
method: 'post',
params:params,
});
}


export function getConDeputeContractBackSettlementItemView(params){return request({
url: '/storagemanage/api/conDeputeContractBackSettlementItem/getConDeputeContractBackSettlementItemView',
method: 'post',
params:params,
});
}


export function saveConDeputeContractBackSettlementItem(data){return request({
url: '/storagemanage/api/conDeputeContractBackSettlementItem/saveConDeputeContractBackSettlementItem',
method: 'post',
data:data,
});
}


export function deleteConDeputeContractBackSettlementItem(params){return request({
url: '/storagemanage/api/conDeputeContractBackSettlementItem/deleteConDeputeContractBackSettlementItem',
method: 'post',
params:params,
});
}


