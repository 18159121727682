/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-13T16:30:31.53
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveEnergyRecord,
	getEnergyRecordCreateInit,
	getEnergyRecordEditInit,
	getEnergyRecordView,
	deleteEnergyRecord
} from '@api/rentmanage/EnergyRecord';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getEnergyRecordCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getEnergyRecordCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEnergyRecordEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEnergyRecordEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveEnergyRecord({}, EnergyRecord) {
			return new Promise((resolve, reject) => {
				saveEnergyRecord(EnergyRecord)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getEnergyRecordView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEnergyRecordView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteEnergyRecord({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEnergyRecord({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
