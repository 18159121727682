/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-09T15:06:38.917
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinancePaymentItem,
	listFinancePaymentItemInit,
	listFinancePaymentItemByAuthorizations,
	deleteFinancePaymentItem
} from '@api/storagemanage/FinancePaymentItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFinancePaymentItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancePaymentItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancePaymentItemByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancePaymentItemByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancePaymentItemInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancePaymentItemInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancePaymentItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancePaymentItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
