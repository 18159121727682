/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-10-13T17:19:55.492
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentSerComplaint,
	getRentSerComplaintCreateInit,
	getRentSerComplaintEditInit,
	getRentSerComplaintView,
	deleteRentSerComplaint
} from '@api/rentmanage/RentSerComplaint';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentSerComplaintEvaluateInit,
listRentSerComplaintEvaluateByAuthorizations,
deleteRentSerComplaintEvaluate
} from '@api/rentmanage/RentSerComplaintEvaluate';

import {
listRentSerComplaintFollowupInit,
listRentSerComplaintFollowupByAuthorizations,
deleteRentSerComplaintFollowup
} from '@api/rentmanage/RentSerComplaintFollowup';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentSerComplaintCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentSerComplaintCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentSerComplaintEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentSerComplaintEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentSerComplaint({}, RentSerComplaint) {
			return new Promise((resolve, reject) => {
				saveRentSerComplaint(RentSerComplaint)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentSerComplaintView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentSerComplaintView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentSerComplaint({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentSerComplaint({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
