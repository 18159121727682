/**
 * 注册、登录、注销
 * */
import util from '@/libs/util';
import router from '@/router';
import {
	pageInit,
	GetDataList,
	addPageInit,
	AddOrEditData,
	editPageInitReq,
	getArea,
	getAreaById,
	deteleRecordReq,
	getCompanyDropDowList
} from '@api/storagemanage/merchant';
import {
	formatTime
} from '@/plugins/utils';
import {
	Message,
	Notice
} from 'view-design';

export default {
	namespaced: true,
	state: {
		reqParams: {
			"entity": {
				"address": "",
				"approvalStatus": "",
				"areaApprovalRemark": "",
				"areaApprovalStatus": "",
				"areaId": "",
				"areaName": "",
				"authStatus": "",
				"cityId": "",
				"cityName": "",
				"companyAddress": "",
				"companyBizType": "",
				"companyCode": "",
				"companyDetail": "",
				"companyEstablishedTime": "",
				"companyId": "",
				"companyLevel": "",
				"companyName": "",
				"companyNature": "",
				"companyShorName": "",
				"companyType": "",
				"createTime": "",
				"createrId": "",
				"createrName": "",
				"customerAttachmentList": [{
					"attachmentType": "",
					"createTime": "",
					"createrId": "",
					"createrName": "",
					"deleteState": "",
					"extField1": "",
					"fileName": "",
					"filePath": "",
					"fileSize": "",
					"fileSuffix": "",
					"fileType": "",
					"id": "",
					"lastUpdateDate": "",
					"operatorId": "",
					"operatorLongName": "",
					"operatorName": "",
					"operatorTime": "",
					"relationId": "",
					"remark": "",
					"saasCompany": "",
					"serialNo": "",
					"status": ""
				}],
				"deleteState": "",
				"description": "",
				"effectiveTime": "",
				"extField1": "",
				"extField2": "",
				"extField3": "",
				"fixedAssets": "",
				"lastUpdateDate": "",
				"licenceCode": "",
				"licencePeriod": "",
				"licencePeriodType": "",
				"managementAreaList": [{
					"areaId": "",
					"cityId": "",
					"companyId": "",
					"createTime": "",
					"provinceId": "",
					"statusFlag": ""
				}],
				"managementHouseNum": "",
				"managementStatus": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"platformOrgId": "",
				"platformOrgName": "",
				"provinceId": "",
				"provinceName": "",
				"registeredCapital": "",
				"remark": "",
				"saasCompany": "",
				"slogan": "",
				"source": "",
				"starRate": "",
				"status": "",
				"streetId": "",
				"streetName": "",
				"tags": "",
				"theIsSelfEvaluate": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 1,
			"pageSize": 10,
			"selectColumns": []
		},
		//列表初始信息
		initInfo: {
			configItems: {}
		},
		pageInitParams: {
			"entity": {
				"address": "",
				"approvalStatus": "",
				"areaApprovalRemark": "",
				"areaApprovalStatus": 0,
				"areaId": "",
				"areaName": "",
				"authStatus": "",
				"cityId": "",
				"cityName": "",
				"companyAddress": "",
				"companyBizType": "",
				"companyCode": "",
				"companyDetail": "",
				"companyEstablishedTime": "",
				"companyId": "",
				"companyLevel": 0,
				"companyName": "",
				"companyNature": "",
				"companyShorName": "",
				"companyType": "",
				"createTime": "",
				"createrId": "",
				"createrName": "",
				"customerAttachmentList": [{
					"attachmentType": "",
					"createTime": "",
					"createrId": "",
					"createrName": "",
					"deleteState": "",
					"extField1": "",
					"fileName": "",
					"filePath": "",
					"fileSize": 0,
					"fileSuffix": "",
					"fileType": "",
					"id": "",
					"lastUpdateDate": "",
					"operatorId": "",
					"operatorLongName": "",
					"operatorName": "",
					"operatorTime": "",
					"relationId": "",
					"remark": "",
					"saasCompany": "",
					"serialNo": 0,
					"status": ""
				}],
				"deleteState": "",
				"description": "",
				"effectiveTime": "",
				"extField1": "",
				"extField2": "",
				"extField3": "",
				"fixedAssets": 0,
				"lastUpdateDate": "",
				"licenceCode": "",
				"licencePeriod": "",
				"licencePeriodType": "",
				"managementHouseNum": 0,
				"managementStatus": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"platformOrgId": "",
				"platformOrgName": "",
				"provinceId": "",
				"provinceName": "",
				"registeredCapital": 0,
				"remark": "",
				"saasCompany": "",
				"slogan": "",
				"source": "",
				"starRate": 0,
				"status": "",
				"streetId": "",
				"streetName": "",
				"tags": "",
				"theIsSelfEvaluate": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 0,
			"pageSize": 0,
			"selectColumns": []
		},

		getAreaParams: {
			"entity": {
				"areaLevel": "",
				"areaName": "",
				"areaNum": "",
				"createId": "",
				"createName": "",
				"createTime": "",
				"deleteState": 0,
				"descript": "",
				"id": "",
				"isDirectly": "",
				"lastUpdateDate": "",
				"latitude": "",
				"longitude": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"parentId": "",
				"saasCompany": "",
				"serialNo": "",
				"shortName": "",
				"tmpId": "",
				"tmpLongName": "",
				"tmpOldId": "",
				"tmpOldParentId": "",
				"tmpParentId": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 0,
			"pageSize": 0,
			"selectColumns": []
		},
	},
	actions: {
		pageInit({
			state,
			dispatch
		}) {
			return new Promise((resolve, reject) => {
				pageInit(state.pageInitParams)
					.then(async res => {
						state.initInfo = res;
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getListData({
			dispatch,
			state,
		}) {
			state.reqParams.outerWhereSubjoin.startDate = formatTime(state.reqParams.outerWhereSubjoin.startDate,
				'YYYY-MM-DD HH:mm:ss')
			state.reqParams.outerWhereSubjoin.endDate = formatTime(state.reqParams.outerWhereSubjoin.endDate,
				'YYYY-MM-DD HH:mm:ss')
			return new Promise((resolve, reject) => {
				GetDataList(state.reqParams)
					.then(async res => {
						// state.listTreeDataObj = res
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		addPageInit({
			state,
			dispatch
		}, info) {
			return new Promise((resolve, reject) => {
				addPageInit({})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		editPageInit({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				editPageInitReq({
						id: id
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getArea({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				getArea(state.getAreaParams)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getAreaById({
			state,
			dispatch
		}, params) {
			return new Promise((resolve, reject) => {
				getAreaById(params)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		deteleRecord({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				deteleRecordReq({
						id: id
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getCompanyDropDowList({
			dispatch,
			state,
		}) {
			state.reqParams.outerWhereSubjoin.startDate = formatTime(state.reqParams.outerWhereSubjoin.startDate,
				'YYYY-MM-DD HH:mm:ss')
			state.reqParams.outerWhereSubjoin.endDate = formatTime(state.reqParams.outerWhereSubjoin.endDate,
				'YYYY-MM-DD HH:mm:ss')
			return new Promise((resolve, reject) => {
				state.reqParams.isPage=false;
				
				getCompanyDropDowList(state.reqParams)
					.then(async res => { 
						resolve(res);
					})
					.catch(err => { 
						reject(err);
					})
			})
		},

		submitDataEdit({
			state,
			dispatch
		}, data) {
			data.companyEstablishedTime = formatTime(data.companyEstablishedTime, 'YYYY-MM-DD')
			data.licencePeriod = formatTime(data.licencePeriod, 'YYYY-MM-DD')
			return new Promise((resolve, reject) => {
				AddOrEditData(data)
					.then(async res => {
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
	},
	mutations: {
		updateParentReqParams(state, obj) {
			state.parentParams['entity'][obj.key] = obj.value;
		},
		updateChildReqParams(state, obj) {
			state.childParams['entity'][obj.key] = obj.value;
		},
		resetChildReqParams(state, obj) {
			state.childParams = {
				"entity": {
					"groupType": "",
					"id": "",
					"lastUpdateDate": "",
					"nodeType": "",
					"operatorId": "",
					"operatorLongName": "",
					"operatorName": "",
					"operatorTime": "",
					"parentId": "",
					"remark": "",
					"saasCompany": "",
					"treeCode": "",
					"treeName": ""
				},
				"isOrder": true,
				"isOrderAsc": true,
				"isPage": true,
				"orderColumn": "",
				"outerWhereSubjoin": {
					"fuzzyMatching": ""
				},
				"pageIndex": 1,
				"pageSize": 10,
				"selectColumns": []
			}
		},
	}
};
