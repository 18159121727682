/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T16:23:43.245
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentPropertyDelivery,
	listRentPropertyDeliveryInit,
	listRentPropertyDeliveryByAuthorizations,
	deleteRentPropertyDelivery,
	sendSign,
    confirmPropertyDelivery,
    confirmConfigure,
	organConfirmConfigure,
	withdraw
} from '@api/rentmanage/RentPropertyDelivery';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentPropertyDelivery({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentPropertyDelivery(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentPropertyDeliveryByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentPropertyDeliveryByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentPropertyDeliveryInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentPropertyDeliveryInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
                        state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentPropertyDelivery({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentPropertyDelivery({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
        
        sendSign({
        	dispatch,
        	state,
        }, billId) {
        	return new Promise((resolve, reject) => {
        		sendSign({
        				billId: billId
        			})
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
        
        confirmPropertyDelivery({
        	dispatch,
        	state,
        }, billId) {
        	return new Promise((resolve, reject) => {
        		confirmPropertyDelivery({
        				billId : billId
        			})
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
        
        confirmConfigure({
        	dispatch,
        	state,
        }, data) {
        	return new Promise((resolve, reject) => {
        		confirmConfigure(data)
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
		organConfirmConfigure({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				organConfirmConfigure(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		withdraw({
			dispatch,
			state,
		}, billId) {
			return new Promise((resolve, reject) => {
				withdraw({
						billId : billId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
