import util from '@/libs/util';
import router from '@/router';

import {
	saveEvaluateBillRemould,
	getEvaluateBillRemouldCreateInit,
	getEvaluateBillRemouldEditInit,
	getEvaluateBillRemouldView,
	deleteEvaluateBillRemould
} from '@api/storagemanage/EvaluateBillRemould';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getEvaluateBillRemouldCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillRemouldCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateBillRemouldEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillRemouldEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveEvaluateBillRemould({}, EvaluateBillRemould) {
			return new Promise((resolve, reject) => {
				saveEvaluateBillRemould(EvaluateBillRemould)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateBillRemouldView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillRemouldView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateBillRemould({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateBillRemould({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
