/**
 * 注册、登录、注销
 * */
import util from '@/libs/util';
import router from '@/router';
import {
	TreeDataInit,
	GetTreeDataList,
	GetDataList,
	AddOrEditData,
	editPageInitReq,
	deteleRecordReq,
} from '@api/storagemanage/dictionary';
import {
	Message,
	Notice
} from 'view-design';

export default {
	namespaced: true,
	state: {
		// 初始化信息
		initInfo: {
			configItems: {
				'外出类型': []
			}
		},

		parentInitParams: {
			"entity": {
				"deletedState": 0,
				"depth": 0,
				"groupType": "",
				"id": "",
				"lastUpdateDate": "",
				"nodeType": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"parentId": "",
				"remark": "",
				"saasCompany": "",
				"theIsEnabled": 0,
				"theIsSys": 0,
				"treeCode": "",
				"treeName": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"fuzzyMatching": ""
			},
			"pageIndex": 1,
			"pageSize": 10,
			"selectColumns": []
		},

		//左边树查询条件
		parentParams: {
			"entity": {
				"groupType": "",
				"id": "",
				"lastUpdateDate": "",
				"nodeType": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"parentId": "",
				"remark": "",
				"saasCompany": "",
				"treeCode": "",
				"treeName": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": false,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"fuzzyMatching": ""
			},
			"pageIndex": 1,
			"pageSize": 10000,
			"selectColumns": []
		},

		//右边列表查询条件
		childParams: {
			"entity": {
				"groupType": "",
				"id": "",
				"lastUpdateDate": "",
				"nodeType": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"parentId": "",
				"remark": "",
				"saasCompany": "",
				"treeCode": "",
				"treeName": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"fuzzyMatching": ""
			},
			"pageIndex": 1,
			"pageSize": 10,
			"selectColumns": []
		},

		//查询接口返回的数据
		listTreeDataObj: {
			entityResponse: {
				totalKeys: [],
			},
		},

		//新增参数
		addData: {},

		//编辑参数
		editData: {},
	},
	actions: {
		pageInit({
			state,
			dispatch
		}) {
			return new Promise((resolve, reject) => {
				TreeDataInit(state.parentInitParams)
					.then(async res => {
						state.initInfo = res
						// 结束
						resolve(state.initInfo);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getTreeData({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				GetTreeDataList(state.parentParams)
					.then(async res => {
						// state.listTreeDataObj = res
						resolve(res.entities);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getListData({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				GetDataList(state.childParams)
					.then(async res => {
						// state.listTreeDataObj = res
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		detailPageInit({
			state,
			dispatch
		}, info) {
			return new Promise((resolve, reject) => {
				addPageInitReq({})
					.then(async res => {
						state.addData.billCreaterId = res.entityResponse.billCreaterId; //创建人id
						state.addData.billCreaterName = res.entityResponse.billCreaterName; //创建人姓名
						state.addData.deptName = res.entityResponse.deptName; //部门名称
						state.addData.deptCode = res.entityResponse.deptCode;
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		editPageInit({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				editPageInitReq({
						id: id
					})
					.then(async res => {
						state.editInitInfo = res
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		deteleRecord({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				deteleRecordReq({
						id: id
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		submitData({
			state,
			dispatch
		}, info) {
			state.addData.outTime = formatTime(state.addData.outTime, 'YYYY-MM-DD HH:mm:ss')
			state.addData.inTime = formatTime(state.addData.inTime, 'YYYY-MM-DD HH:mm:ss')
			// Message.error({
			// 	content: 'outTime:'+state.addData.outTime+'inTime:'+state.addData.inTime,
			// 	duration: 3000
			// });
			return new Promise((resolve, reject) => {
				AddOrEditData(state.addData)
					.then(async res => {
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		submitDataEdit({
			state,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				AddOrEditData(data)
					.then(async res => {
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
	},
	mutations: {
		updateParentReqParams(state, obj) {
			state.parentParams['entity'][obj.key] = obj.value;
		},
		updateChildReqParams(state, obj) {
			state.childParams['entity'][obj.key] = obj.value;
		},
		resetChildReqParams(state, obj) {
			state.childParams = {
				"entity": {
					"groupType": "",
					"id": "",
					"lastUpdateDate": "",
					"nodeType": "",
					"operatorId": "",
					"operatorLongName": "",
					"operatorName": "",
					"operatorTime": "",
					"parentId": "",
					"remark": "",
					"saasCompany": "",
					"treeCode": "",
					"treeName": ""
				},
				"isOrder": true,
				"isOrderAsc": true,
				"isPage": true,
				"orderColumn": "",
				"outerWhereSubjoin": {
					"fuzzyMatching": ""
				},
				"pageIndex": 1,
				"pageSize": 10,
				"selectColumns": []
			}
		},
	}
};
