/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-24T09:40:19.582
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveKhmDataChangesApply,
	getKhmDataChangesApplyCreateInit,
	getKhmDataChangesApplyEditInit,
	getKhmDataChangesApplyView,
	deleteKhmDataChangesApply,
	startWorkflow
} from '@api/rentmanage/KhmDataChangesApply';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getKhmDataChangesApplyCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getKhmDataChangesApplyCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getKhmDataChangesApplyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getKhmDataChangesApplyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveKhmDataChangesApply({}, KhmDataChangesApply) {
			return new Promise((resolve, reject) => {
				saveKhmDataChangesApply(KhmDataChangesApply)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getKhmDataChangesApplyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getKhmDataChangesApplyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		startWorkflow({}, KhmDataChangesApply) {
			return new Promise((resolve, reject) => {
				startWorkflow(KhmDataChangesApply)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteKhmDataChangesApply({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteKhmDataChangesApply({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
