/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-02T18:37:11.294
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listInvoiceToolsHouse,
	listInvoiceToolsHouseInit,
	listInvoiceToolsHouseByAuthorizations,
	deleteInvoiceToolsHouse,
	messageNoticeByLastYear,
	downloadResult
	
} from '@api/smallapplication/InvoiceToolsHouse';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listInvoiceToolsHouse({
			state
		}) {
			return new Promise((resolve, reject) => {
				listInvoiceToolsHouse(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listInvoiceToolsHouseByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listInvoiceToolsHouseByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listInvoiceToolsHouseInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listInvoiceToolsHouseInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteInvoiceToolsHouse({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteInvoiceToolsHouse({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		downloadResult({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				downloadResult()
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		messageNoticeByLastYear({
			state
		}) {
			return new Promise((resolve, reject) => {
				messageNoticeByLastYear()
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		
	}
};
