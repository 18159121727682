/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-15T17:10:25.448
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinanceRentApply,
	listFinanceRentApplyInit,
	listFinanceRentApplyByAuthorizations,
	deleteFinanceRentApply
	
} from '@api/storagemanage/FinanceRentApply';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFinanceRentApply({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinanceRentApply(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinanceRentApplyByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinanceRentApplyByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinanceRentApplyInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinanceRentApplyInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinanceRentApply({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinanceRentApply({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
