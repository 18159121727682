import util from '@/libs/util';
import router from '@/router';
import dayjs from 'dayjs';
import {
	startWorkflowApi,
	listTodoWorkitemInstanceBySelf,
	listHaveDoneWorkitemInstanceBySelf,
	todoListActiveWorkflow,
	listStartWorkitemInstanceBySelf,
	processTracesList,
	submitWorkflowApi,
	tempStorageWorkflowApi,
	rejectWorkflowApi,
	restartWorkflowApi
} from '@api/storagemanage/flowCenterApi';
import {
	Message,
	Notice
} from 'view-design';

import {
	Modal
} from 'view-design';

export default {
	namespaced: true,
	state: {
		type: '1',
		advanceQuery: {
			entity: {
				approveContent: '',
				approveCreaterId: '',
				approveCreaterName: '',
				approveEffect: '',
				approveFinishTime: '',
				approveFinisherId: '',
				approveFinisherName: '',
				approveStartTime: '',
				approveState: '',
				approveTime: '',
				approverId: '',
				approverName: '',
				billCode: '',
				billCreateDeptId: '',
				billCreateDeptName: '',
				billCreateTime: '',
				billCreaterId: '',
				billCreaterName: '',
				billId: '',
				billTitle: '',
				billUrl: '',
				bizType: '',
				createTime: '',
				createrId: '',
				createrName: '',
				descript: '',
				exclusiveTime: '',
				operatorId: '',
				operatorLongName: '',
				operatorName: '',
				operatorTime: '',
				preWorkitemInstanceId: '',
				receiveTime: '',
				receiverId: '',
				receiverName: '',
				workflowId: '',
				workflowInstanceId: '',
				workitemId: '',
				workitemInstanceId: '',
				workitemTitle: ''
			},
			innerWhere: {
				saasCompany: '',
				userId: ''
			},
			isOrder: false,
			isOrderAsc: true,
			isPage: true,
			orderColumn: '',
			outerWhereSubjoin: {
				fuzzyMatching: ''
			},
			pageIndex: 1,
			pageSize: 10,
			selectColumns: []
		},
		// 流程表单参数
		flowBillParams: {
			approveState: '0',
			approveTime: '',
			approverId: '',
			approverName: '',
			billCode: '',
			billCreateDeptId: '',
			billCreateDeptName: '',
			billCreateTime: '',
			billCreaterId: '',
			billCreaterName: '',
			billId: '',
			billTitle: '',
			billUrl: 'houseSaveDelegation-editForm',
			bizType: '',
			deleteState: '0',
			descript: '',
			lastUpdateDate: '',
			operatorId: '',
			operatorLongName: '',
			operatorName: '',
			operatorTime: '',
			saasCompany: ''
		},
		workflowInstanceId: '',
		workitemInstanceId: '',
		flowBizType: '',
		workitemId: '',
		nextWorkitemAndUsers: {
			nextWorkitemAndUserList: [{
				workitemId: '',
				workitemUsers: [{
					mainDeptId: '',
					mainDeptName: '',
					sassCompany: '',
					userCode: '',
					userId: '',
					userLongName: '',
					userName: ''
				}]
			}]
		},
		//查询接口返回的数据
		listDataObj: {
			entities: {},
			isPage: true,
			pageIndex: 1,
			pageSize: 10,
			total: 0
		},
		todoListActiveWorkflowReturnData: { //从待办列表进入激活一个流程返回数据结构

		},
		flowDataObj: { // 发起流程返回的数据
			nextWorkitems: []
		},
		processTracesList: {} //流程痕迹列表

	},
	actions: {
		startFlowAction({
			dispatch,
			state,
		}, id) {
			return new Promise((resolve, reject) => {
				state.flowBillParams.billId = id;
				startWorkflowApi(state.flowBillParams)
					.then(async res => {
						// 结束
						console.log('res err: ', res);
						state.flowDataObj.nextWorkitems = res.entity.nextWorkitems;
						if (res.entity !== undefined && res.entity !== null && res.entity.currentDraftWorkitemInstance !== undefined &&
							res.entity.currentDraftWorkitemInstance !== null) {
							state.workflowInstanceId = res.entity.currentDraftWorkitemInstance.workflowInstanceId;
							state.workitemInstanceId = res.entity.currentDraftWorkitemInstance.workitemInstanceId;
							state.workitemId = res.entity.currentDraftWorkitemInstance.workitemId;
						}
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listTodoWorkitemInstanceBySelf({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				// state.advanceQuery.pageIndex=state.listDataObj.pageIndex;
				listTodoWorkitemInstanceBySelf(state.advanceQuery)
					.then(async res => {
						// 结束
						state.listDataObj = res;
						// state.listDataObj.pageIndex=state.advanceQuery.pageIndex;
						// state.advanceQuery.entity = state.advanceQuery.entity;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listHaveDoneWorkitemInstanceBySelf({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {

				// state.advanceQuery.pageIndex = state.listDataObj.pageIndex;
				listHaveDoneWorkitemInstanceBySelf(state.advanceQuery)
					.then(async res => {
						// 结束
						state.listDataObj = res;
						// state.advanceQuery.pageIndex = state.listDataObj.pageIndex;
						// state.advanceQuery.entity = state.advanceQuery.entity;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		todoListActiveWorkflow({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				let workflowInstanceId = data.workflowInstanceId;
				let workitemInstanceId = data.workitemInstanceId;
				state.advanceQuery.pageIndex = state.listDataObj.pageIndex;
				todoListActiveWorkflow(data, {
						workflowInstanceId,
						workitemInstanceId
					})
					.then(async res => {
						state.todoListActiveWorkflowReturnData = res;
						state.advanceQuery.pageIndex = state.listDataObj.pageIndex;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listStartWorkitemInstanceBySelf({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				// state.advanceQuery.pageIndex = state.listDataObj.pageIndex;
				//state.advanceQuery.entity.approveState = "9999";
				listStartWorkitemInstanceBySelf(state.advanceQuery)
					.then(async res => {
						state.todoListActiveWorkflowReturnData = res;
						// state.advanceQuery.pageIndex = state.listDataObj.pageIndex;
						// state.advanceQuery.entity = state.advanceQuery.entity;
						//state.advanceQuery.entity.approveState = "9999";

						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		processTracesList({
			dispatch,
			state,
		}, workflowInstanceId) {
			return new Promise((resolve, reject) => {
				processTracesList({
						workflowInstanceId
					})
					.then(async res => {
						state.processTracesList = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		submitFlowAction({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				let workflowInstanceId = data.workflowInstanceId;
				let workitemInstanceId = data.workitemInstanceId;
				let approveContent = data.approveContent;
				let approveEffect = data.approveEffect;
				submitWorkflowApi(data, {
						workflowInstanceId,
						workitemInstanceId,
						approveContent,
						approveEffect
					})
					.then(async res => {
						// 结束
						console.log('res err: ', res);
						resolve(state.flowDataObj);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		tempStorageWorkflow({
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				let workflowInstanceId = data.workflowInstanceId;
				let workitemInstanceId = data.workitemInstanceId;
				let approveContent = data.approveContent;
				let approveEffect = data.approveEffect;
				tempStorageWorkflowApi(data.compoundBillAndNextWorkitemAndUser, {
						workflowInstanceId,
						workitemInstanceId,
						approveContent,
						approveEffect
					})
					.then(async res => {
						// 结束
						console.log('res err: ', res);
						resolve(state.flowDataObj);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		restartFlowAction({ //作废流程
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				let workflowInstanceId = data.workflowInstanceId;
				restartWorkflowApi(data, {
						workflowInstanceId
					})
					.then(async res => {
						resolve(state.flowDataObj);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		rejectWorkflowAction({ //作废流程
			dispatch,
			state,
		}, data) {
			return new Promise((resolve, reject) => {
				let workflowInstanceId = data.workflowInstanceId;
				rejectWorkflowApi(data, {
						workflowInstanceId
					})
					.then(async res => {
						resolve(state.flowDataObj);
					})
					.catch(err => {
						reject(err);
					})
			})
		}

	}
};
