/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-21T17:44:56.196
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveReportWorkflowCount,
	getReportWorkflowCountCreateInit,
	getReportWorkflowCountEditInit,
	getReportWorkflowCountView,
	deleteReportWorkflowCount
} from '@api/report/ReportWorkflowCount';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listReportWorkflowItemCountInit,
listReportWorkflowItemCountByAuthorizations,
deleteReportWorkflowItemCount
} from '@api/report/ReportWorkflowItemCount';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getReportWorkflowCountCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getReportWorkflowCountCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportWorkflowCountEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportWorkflowCountEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveReportWorkflowCount({}, ReportWorkflowCount) {
			return new Promise((resolve, reject) => {
				saveReportWorkflowCount(ReportWorkflowCount)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportWorkflowCountView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportWorkflowCountView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteReportWorkflowCount({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteReportWorkflowCount({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
