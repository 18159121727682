/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-10T12:05:16.707
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancePayApply,
	getFinancePayApplyCreateInit,
	getFinancePayApplyEditInit,
	getFinancePayApplyView,
	deleteFinancePayApply,
	saveFinancePayApplyBo,
	saveFinanceOffPay,
} from '@api/storagemanage/FinancePayApply';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listFinancePayApplyItemInit,
listFinancePayApplyItemByAuthorizations,
deleteFinancePayApplyItem
} from '@api/storagemanage/FinancePayApplyItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
		FinancePayApplyItems:[],
	},

	actions: {
		getFinancePayApplyCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancePayApplyCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancePayApplyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancePayApplyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancePayApply({}, FinancePayApply) {
			return new Promise((resolve, reject) => {
				saveFinancePayApply(FinancePayApply)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		saveFinancePayApplyBo({}, data) {
			return new Promise((resolve, reject) => {
				saveFinancePayApplyBo(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		getFinancePayApplyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancePayApplyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancePayApply({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancePayApply({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		saveFinanceOffPay({
			state
		}, data) {
			return new Promise((resolve, reject) => {
				saveFinanceOffPay(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
