/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-04-28T10:23:25.515
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveBuilding,
	getBuildingCreateInit,
	getBuildingEditInit,
	getBuildingView,
	deleteBuilding,
	getStreet
} from '@api/storagemanage/Building';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listBlockInit,
listBlockByAuthorizations,
deleteBlock
} from '@api/storagemanage/Block';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getBuildingCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getBuildingCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getBuildingEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getBuildingEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveBuilding({}, Building) {
			return new Promise((resolve, reject) => {
				saveBuilding(Building)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getBuildingView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getBuildingView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteBuilding({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteBuilding({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		getStreet({
			state
		}, data) {
			return new Promise((resolve, reject) => {
				getStreet(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
