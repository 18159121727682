/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-16T15:24:16.905
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-RentTakeLookRecord-components-RentTakeLookHouse-'

export default 
{
path: '/rentmanage/renttakelookrecord/components/renttakelookhouse',
name: 'rentmanage-renttakelookrecord-components-renttakelookhouse',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}RentTakeLookHouse-index',

meta:{
...meta,
title: '带看物业'
},

component: () => import('@/pages/rentmanage/renttakelookrecord/components/renttakelookhouse/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}RentTakeLookHouse-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '带看物业编辑'
},

component: () => import('@/pages/rentmanage/renttakelookrecord/components/renttakelookhouse/detail-edit') ,
},
{
	path: 'indexTableListStatistics',
	name: '${pre}RentTakeLookHouse-indexTableListStatistics',
	meta:{
	...meta,
	title: '带看统计详情'
	},
	component: () => import('@/pages/rentmanage/renttakelookrecord/components/renttakelookhouse/index-table-list-statistics') ,
}, 
{
path: 'detail-edit',
name: '${pre}RentTakeLookHousedetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '带看物业新增'
},

component: () => import('@/pages/rentmanage/renttakelookrecord/components/renttakelookhouse/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}RentTakeLookHouseView',
auth: [
'hidden'
],

meta:{
...meta,
title: '带看物业详情查看'
},

component: () => import('@/pages/rentmanage/renttakelookrecord/components/renttakelookhouse/detail-view') ,
},
]
};
