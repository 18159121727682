/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-06T11:29:37.874
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialRentContractItemUnfinishedInit(data){return request({
url: '/report/api/reportFinancialRentContractItemUnfinished/listReportFinancialRentContractItemUnfinishedInit',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractItemUnfinishedByAuthorizations(data){return request({
url: '/report/api/reportFinancialRentContractItemUnfinished/listReportFinancialRentContractItemUnfinishedByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportFinancialRentContractItemUnfinished(data){return request({
url: '/report/api/reportFinancialRentContractItemUnfinished/listReportFinancialRentContractItemUnfinished',
method: 'post',
data:data,
});
}


export function getReportFinancialRentContractItemUnfinishedCreateInit(){return request({
url: '/report/api/reportFinancialRentContractItemUnfinished/getReportFinancialRentContractItemUnfinishedCreateInit',
method: 'post',
});
}


export function getReportFinancialRentContractItemUnfinishedEditInit(params){return request({
url: '/report/api/reportFinancialRentContractItemUnfinished/getReportFinancialRentContractItemUnfinishedEditInit',
method: 'post',
params:params,
});
}


export function getReportFinancialRentContractItemUnfinishedView(params){return request({
url: '/report/api/reportFinancialRentContractItemUnfinished/getReportFinancialRentContractItemUnfinishedView',
method: 'post',
params:params,
});
}


export function saveReportFinancialRentContractItemUnfinished(data){return request({
url: '/report/api/reportFinancialRentContractItemUnfinished/saveReportFinancialRentContractItemUnfinished',
method: 'post',
data:data,
});
}


export function deleteReportFinancialRentContractItemUnfinished(params){return request({
url: '/report/api/reportFinancialRentContractItemUnfinished/deleteReportFinancialRentContractItemUnfinished',
method: 'post',
params:params,
});
}


