import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: false
};

const pre = '/smallapplication/';

export default {
    path: `${pre}publishAdvice`,
    name: 'publishAdvice',
	meta: {
		...meta,
		title: '员工心声',
		closable: false
	},
	component: () => import('@/pages/smallapplication/publishAdvice')
};
