/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-04-29T08:55:39.217
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listHouseCycleDetailInit(data){return request({
url: '/house/api/houseCycleDetail/listHouseCycleDetailInit',
method: 'post',
data:data,
});
}


export function listHouseCycleDetailByAuthorizations(data){return request({
url: '/house/api/houseCycleDetail/listHouseCycleDetailByAuthorizations',
method: 'post',
data:data,
});
}


export function listHouseCycleDetail(data){return request({
url: '/house/api/houseCycleDetail/listHouseCycleDetail',
method: 'post',
data:data,
});
}


export function getHouseCycleDetailCreateInit(){return request({
url: '/house/api/houseCycleDetail/getHouseCycleDetailCreateInit',
method: 'post',
});
}


export function getHouseCycleDetailEditInit(params){return request({
url: '/house/api/houseCycleDetail/getHouseCycleDetailEditInit',
method: 'post',
params:params,
});
}


export function getHouseCycleDetailView(params){return request({
url: '/house/api/houseCycleDetail/getHouseCycleDetailView',
method: 'post',
params:params,
});
}


export function saveHouseCycleDetail(data){return request({
url: '/house/api/houseCycleDetail/saveHouseCycleDetail',
method: 'post',
data:data,
});
}


export function deleteHouseCycleDetail(params){return request({
url: '/house/api/houseCycleDetail/deleteHouseCycleDetail',
method: 'post',
params:params,
});
}

export function listHouseCycleSteps(data){return request({
url: '/house/api/houseCycleDetail/listHouseCycleSteps',
method: 'post',
data:data,
});
}

