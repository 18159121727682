/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-04-28T09:57:01.374
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveUnit,
	getUnitCreateInit,
	getUnitEditInit,
	getUnitView,
	deleteUnit
} from '@api/storagemanage/Unit';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getUnitCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getUnitCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getUnitEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getUnitEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveUnit({}, Unit) {
			return new Promise((resolve, reject) => {
				saveUnit(Unit)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getUnitView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getUnitView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteUnit({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteUnit({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
