/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-09T15:06:38.74
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancePayment,
	getFinancePaymentCreateInit,
	getFinancePaymentEditInit,
	getFinancePaymentView,
	deleteFinancePayment
} from '@api/storagemanage/FinancePayment';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listFinancePaymentItemInit,
listFinancePaymentItemByAuthorizations,
deleteFinancePaymentItem
} from '@api/storagemanage/FinancePaymentItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancePaymentCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancePaymentCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancePaymentEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancePaymentEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancePayment({}, FinancePayment) {
			return new Promise((resolve, reject) => {
				saveFinancePayment(FinancePayment)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancePaymentView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancePaymentView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancePayment({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancePayment({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
