/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:57.913
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getEvaluationMicroRemakeProblemEditInit,
	getEvaluationMicroRemakeProblemView
} from '@api/lcf/EvaluationMicroRemakeProblem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},

	actions: {
		getEvaluationMicroRemakeProblemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluationMicroRemakeProblemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluationMicroRemakeProblemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluationMicroRemakeProblemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
