/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-16T16:45:54.883
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listKhmDataAuthApply,
	listKhmDataAuthApplyInit,
	listKhmDataAuthApplyByAuthorizations,
	deleteKhmDataAuthApply
	
} from '@api/rentmanage/KhmDataAuthApply';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listKhmDataAuthApply({
			state
		}) {
			return new Promise((resolve, reject) => {
				listKhmDataAuthApply(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listKhmDataAuthApplyByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listKhmDataAuthApplyByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listKhmDataAuthApplyInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listKhmDataAuthApplyInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteKhmDataAuthApply({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteKhmDataAuthApply({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
