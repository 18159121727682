/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-24T15:57:51.822
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseTypeTagInit(data){return request({
url: '/rentmanage/api/rentHouseTypeTag/listRentHouseTypeTagInit',
method: 'post',
data:data,
});
}


export function listRentHouseTypeTagByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseTypeTag/listRentHouseTypeTagByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseTypeTag(data){return request({
url: '/rentmanage/api/rentHouseTypeTag/listRentHouseTypeTag',
method: 'post',
data:data,
});
}


export function getRentHouseTypeTagCreateInit(){return request({
url: '/rentmanage/api/rentHouseTypeTag/getRentHouseTypeTagCreateInit',
method: 'post',
});
}


export function getRentHouseTypeTagEditInit(params){return request({
url: '/rentmanage/api/rentHouseTypeTag/getRentHouseTypeTagEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseTypeTagView(params){return request({
url: '/rentmanage/api/rentHouseTypeTag/getRentHouseTypeTagView',
method: 'post',
params:params,
});
}


export function saveRentHouseTypeTag(data){return request({
url: '/rentmanage/api/rentHouseTypeTag/saveRentHouseTypeTag',
method: 'post',
data:data,
});
}


export function deleteRentHouseTypeTag(params){return request({
url: '/rentmanage/api/rentHouseTypeTag/deleteRentHouseTypeTag',
method: 'post',
params:params,
});
}


