/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-09T14:50:23.951
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'storagemanage-stockpileintentionowner-'

export default {
	path: '/storagemanage/stockpileintentionowner',
	name: 'storagemanage-stockpileintentionowner',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}stockpileintentionowner-index',

			meta: {
				...meta,
				title: '跟进单(记录/反续跟进提醒)'
			},

			component: () => import('@/pages/storagemanage/stockpileintentionowner/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}stockpileintentionowner-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '跟进单(记录/反续跟进提醒)编辑'
			},

			component: () => import('@/pages/storagemanage/stockpileintentionowner/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}stockpileintentionownerdetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '跟进单(记录/反续跟进提醒)新增'
			},

			component: () => import('@/pages/storagemanage/stockpileintentionowner/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}stockpileintentionownerView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '跟进单(记录/反续跟进提醒)详情查看'
			},

			component: () => import('@/pages/storagemanage/stockpileintentionowner/detail-view'),
		},
	]
};
