/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-16T19:11:22.048
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseBigInit(data){return request({
url: '/rentmanage/api/rentHouseBig/listRentHouseBigInit',
method: 'post',
data:data,
});
}

export function listRentHouseBigBySQL(data){return request({
	url: '/rentmanage/api/rentHouseBig/listRentHouseBigBySQL',
	method: 'post',
	data:data,
	});
	}

export function listRentHouseBigBySQLLock(data){return request({
	url: '/rentmanage/api/rentHouseBig/listRentHouseBigBySQLLock',
	method: 'post',
	data:data,
	});
	}

export function listRentHouseBigByChannelSQL(data){return request({
	url: '/rentmanage/api/rentHouseBig/listRentHouseBigByChannelSQL',
	method: 'post',
	data:data,
	});
}

export function listRentHouseBigByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseBig/listRentHouseBigByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseBig(data){return request({
url: '/rentmanage/api/rentHouseBig/listRentHouseBig',
method: 'post',
data:data,
});
}


export function getRentHouseBigCreateInit(){return request({
url: '/rentmanage/api/rentHouseBig/getRentHouseBigCreateInit',
method: 'post',
});
}


export function getRentHouseBigEditInit(params){return request({
url: '/rentmanage/api/rentHouseBig/getRentHouseBigEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseBigView(params){return request({
url: '/rentmanage/api/rentHouseBig/getRentHouseBigView',
method: 'post',
params:params,
});
}

export function getFindRoomView(params){return request({
	url: '/rentmanage/api/rentHouseBig/getFindRoomView',
	method: 'post',
	params:params,
	});
}


export function saveRentHouseBig(data){return request({
url: '/rentmanage/api/rentHouseBig/saveRentHouseBig',
method: 'post',
data:data,
});
}


export function deleteRentHouseBig(params){return request({
url: '/rentmanage/api/rentHouseBig/deleteRentHouseBig',
method: 'post',
params:params,
});
}

export function wxListRentLeaseContractHousekeeper(params){return request({
	url: '/rentmanage/api/rentHouseBig/wxListRentLeaseContractHousekeeper',
	method: 'post',
	params:params
	});
}

export function cancelReservation(data){return request({
	url: '/rentmanage/api/rentHouseHold/cancelReservation',
	method: 'post',
	data:data,
	});
	}

export function rentalApplicationApi(data){return request({
	url: '/rentmanage/api/rentHouseBig/rentalApplicationApi',
	method: 'post',
	data:data,
	});
	}

export function saveKeyInfo(data){return request({
	url: '/rentmanage/api/rentHouseBig/saveKeyInfo',
	method: 'post',
	data:data,
	});
	}

export function downloadRenthousebigFocusTemplate(data) {
	return request({
		url: '/rentmanage/api/rentHouseBig/downloadRenthousebigFocusTemplate',
		method: 'post',
		data: data,
		responseType: 'blob'
	});
}

export function synchronizeHkDataApi(data){return request({
	url: '/rentmanage/api/rentTakeLookRecord/synchronizeHkData',
	method: 'post',
	data:data,
	});
	}
