/*
 * @Author: your name
 * @Date: 2021-04-07 14:30:16
 * @LastEditTime: 2021-04-21 14:58:08
 * @LastEditors: co
 * @Description: In User Settings Edit
 * @FilePath: \giant_find\iview-admin-pro-template\src\store\modules\rentmanage\modules\findroom\index.js
 */
/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-16T19:11:22.063
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFindRoomBySQL, listFindRoomCountByPoint, getRentTrafficLinePlatform, getRentTrafficLine, listRentTagInit
} from '@api/rentmanage/FindRoom';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFindRoomBySQL({ state }) {
			return new Promise((resolve, reject) => {
				listFindRoomBySQL(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		// 获取地铁线路
		getRentTrafficLine({ state }, obj) {
			return new Promise((resolve, reject) => {
				getRentTrafficLine(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		// 获取地铁站台
		getRentTrafficLinePlatform({ state }, obj) {
			return new Promise((resolve, reject) => {
				getRentTrafficLinePlatform(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		// 获取房源特色标签
		listRentTagInit({ state }) {
			return new Promise((resolve, reject) => {
				listRentTagInit(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		listFindRoomCountByPoint({ state }, obj) {
			return new Promise((resolve, reject) => {
				listFindRoomCountByPoint(obj)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
