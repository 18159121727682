/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-17T15:34:14.745
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listPublishOperationLog,
	listPublishOperationLogInit,
	listPublishOperationLogByAuthorizations,
	deletePublishOperationLog,
	retry
	
} from '@api/house/PublishOperationLog';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listPublishOperationLog({
			state
		}) {
			return new Promise((resolve, reject) => {
				listPublishOperationLog(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listPublishOperationLogByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listPublishOperationLogByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listPublishOperationLogInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listPublishOperationLogInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deletePublishOperationLog({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deletePublishOperationLog({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		retry({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				retry({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
