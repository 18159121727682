/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-01T18:25:53.532
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'systemmanage-OrganizationManage-'

export default 
{
path: '/systemmanage/organizationmanage',
name: 'systemmanage-OrganizationManage',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}OrganizationManage-index',

meta:{
...meta,
title: '组织管理'
},

component: () => import('@/pages/systemmanage/organizationmanage/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}OrganizationManage-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '组织管理编辑'
},

component: () => import('@/pages/systemmanage/organizationmanage/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}OrganizationManagedetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '组织管理新增'
},

component: () => import('@/pages/systemmanage/organizationmanage/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}OrganizationManageView',
auth: [
'hidden'
],

meta:{
...meta,
title: '组织管理详情查看'
},

component: () => import('@/pages/systemmanage/organizationmanage/detail-view') ,
},
]
};
