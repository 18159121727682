/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-11T17:16:49.184
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listRentLegalEntityInit(data) {
    return request({
        url: '/rentmanage/api/rentLegalEntity/listRentLegalEntityInit',
        method: 'post',
        data: data,
    });
}


export function listRentLegalEntityByAuthorizations(data) {
    return request({
        url: '/rentmanage/api/rentLegalEntity/listRentLegalEntityByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listRentLegalEntity(data) {
    return request({
        url: '/rentmanage/api/rentLegalEntity/listRentLegalEntity',
        method: 'post',
        data: data,
    });
}


export function getRentLegalEntityCreateInit() {
    return request({
        url: '/rentmanage/api/rentLegalEntity/getRentLegalEntityCreateInit',
        method: 'post',
    });
}


export function getRentLegalEntityEditInit(params) {
    return request({
        url: '/rentmanage/api/rentLegalEntity/getRentLegalEntityEditInit',
        method: 'post',
        params: params,
    });
}


export function getRentLegalEntityView(params) {
    return request({
        url: '/rentmanage/api/rentLegalEntity/getRentLegalEntityView',
        method: 'post',
        params: params,
    });
}


export function saveRentLegalEntity(data) {
    return request({
        url: '/rentmanage/api/rentLegalEntity/saveRentLegalEntity',
        method: 'post',
        data: data,
    });
}


export function deleteRentLegalEntity(params) {
    return request({
        url: '/rentmanage/api/rentLegalEntity/deleteRentLegalEntity',
        method: 'post',
        params: params,
    });
}

export function authenticationLegalentity(params) {
    return request({
        url: '/rentmanage/api/rentLegalEntity/authenticationLegalentity',
        method: 'get',
        params: params,
    });
}
