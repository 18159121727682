import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	name: 'khm-1',
	redirect: {
		// name: `${pre}houseSaveFollowManagement-list`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'ownerInfo',
		name: `${pre}ownerInfo`,
		meta: {
			...meta,
			title: '跟进情况',
			closable: true
		},
		component: () => import('@/pages/storagemanage/components/ownerInfo')
	}, ]
};
