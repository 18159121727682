/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-02T19:10:02.088
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentRepairWorkOrderAssign,
	getRentRepairWorkOrderAssignCreateInit,
	getRentRepairWorkOrderAssignEditInit,
	getRentRepairWorkOrderAssignView,
	deleteRentRepairWorkOrderAssign
} from '@api/rentmanage/RentRepairWorkOrderAssign';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentRepairWorkOrderAssignCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairWorkOrderAssignCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentRepairWorkOrderAssignEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairWorkOrderAssignEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentRepairWorkOrderAssign({}, RentRepairWorkOrderAssign) {
			return new Promise((resolve, reject) => {
				saveRentRepairWorkOrderAssign(RentRepairWorkOrderAssign)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentRepairWorkOrderAssignView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentRepairWorkOrderAssignView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentRepairWorkOrderAssign({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentRepairWorkOrderAssign({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
