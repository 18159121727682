/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-02T18:37:11.292
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'smallapplication-InvoiceToolsHouse-'

export default 
{
path: '/smallapplication/invoicetoolshouse',
name: 'smallapplication-InvoiceToolsHouse',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}InvoiceToolsHouse-index',

meta:{
...meta,
title: '可开票房源信息'
},

component: () => import('@/pages/smallapplication/invoicetoolshouse/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}InvoiceToolsHouse-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '可开票房源信息编辑'
},

component: () => import('@/pages/smallapplication/invoicetoolshouse/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}InvoiceToolsHousedetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '可开票房源信息新增'
},

component: () => import('@/pages/smallapplication/invoicetoolshouse/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}InvoiceToolsHouseView',
auth: [
'hidden'
],

meta:{
...meta,
title: '可开票房源信息详情查看'
},

component: () => import('@/pages/smallapplication/invoicetoolshouse/detail-view') ,
},
]
};
