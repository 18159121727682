/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-15T11:07:07.068
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listCardOperationLogInit(data){return request({
url: '/rentmanage/api/cardOperationLog/listCardOperationLogInit',
method: 'post',
data:data,
});
}


export function listCardOperationLogByAuthorizations(data){return request({
url: '/rentmanage/api/cardOperationLog/listCardOperationLogByAuthorizations',
method: 'post',
data:data,
});
}


export function listCardOperationLog(data){return request({
url: '/rentmanage/api/cardOperationLog/listCardOperationLog',
method: 'post',
data:data,
});
}


export function getCardOperationLogCreateInit(){return request({
url: '/rentmanage/api/cardOperationLog/getCardOperationLogCreateInit',
method: 'post',
});
}


export function getCardOperationLogEditInit(params){return request({
url: '/rentmanage/api/cardOperationLog/getCardOperationLogEditInit',
method: 'post',
params:params,
});
}


export function getCardOperationLogView(params){return request({
url: '/rentmanage/api/cardOperationLog/getCardOperationLogView',
method: 'post',
params:params,
});
}


export function saveCardOperationLog(data){return request({
url: '/rentmanage/api/cardOperationLog/saveCardOperationLog',
method: 'post',
data:data,
});
}


export function deleteCardOperationLog(params){return request({
url: '/rentmanage/api/cardOperationLog/deleteCardOperationLog',
method: 'post',
params:params,
});
}


