/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-25T09:40:19.443
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseTypePicInit(data){return request({
url: '/rentmanage/api/rentHouseTypePic/listRentHouseTypePicInit',
method: 'post',
data:data,
});
}


export function listRentHouseTypePicByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseTypePic/listRentHouseTypePicByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseTypePic(data){return request({
url: '/rentmanage/api/rentHouseTypePic/listRentHouseTypePic',
method: 'post',
data:data,
});
}


export function getRentHouseTypePicCreateInit(){return request({
url: '/rentmanage/api/rentHouseTypePic/getRentHouseTypePicCreateInit',
method: 'post',
});
}


export function getRentHouseTypePicEditInit(params){return request({
url: '/rentmanage/api/rentHouseTypePic/getRentHouseTypePicEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseTypePicView(params){return request({
url: '/rentmanage/api/rentHouseTypePic/getRentHouseTypePicView',
method: 'post',
params:params,
});
}


export function saveRentHouseTypePic(data){return request({
url: '/rentmanage/api/rentHouseTypePic/saveRentHouseTypePic',
method: 'post',
data:data,
});
}


export function deleteRentHouseTypePic(params){return request({
url: '/rentmanage/api/rentHouseTypePic/deleteRentHouseTypePic',
method: 'post',
params:params,
});
}


