/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-17T17:56:04.338
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getRentIssueHouseApplyEditInit,
	getRentIssueHouseApplyView
} from '@api/rentmanage/RentIssueHouseApply';

import {
listRentIssueHouseApplyItemInit,
listRentIssueHouseApplyItemByAuthorizations,
deleteRentIssueHouseApplyItem
} from '@api/rentmanage/RentIssueHouseApplyItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getRentIssueHouseApplyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentIssueHouseApplyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentIssueHouseApplyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentIssueHouseApplyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
