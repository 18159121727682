/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-08T19:23:44.948
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialClaimCancellationApplyItemInit(data){return request({
url: '/financial/api/financialClaimCancellationApplyItem/listFinancialClaimCancellationApplyItemInit',
method: 'post',
data:data,
});
}


export function listFinancialClaimCancellationApplyItemByAuthorizations(data){return request({
url: '/financial/api/financialClaimCancellationApplyItem/listFinancialClaimCancellationApplyItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialClaimCancellationApplyItem(data){return request({
url: '/financial/api/financialClaimCancellationApplyItem/listFinancialClaimCancellationApplyItem',
method: 'post',
data:data,
});
}


export function getFinancialClaimCancellationApplyItemCreateInit(){return request({
url: '/financial/api/financialClaimCancellationApplyItem/getFinancialClaimCancellationApplyItemCreateInit',
method: 'post',
});
}


export function getFinancialClaimCancellationApplyItemEditInit(params){return request({
url: '/financial/api/financialClaimCancellationApplyItem/getFinancialClaimCancellationApplyItemEditInit',
method: 'post',
params:params,
});
}


export function getFinancialClaimCancellationApplyItemView(params){return request({
url: '/financial/api/financialClaimCancellationApplyItem/getFinancialClaimCancellationApplyItemView',
method: 'post',
params:params,
});
}


export function saveFinancialClaimCancellationApplyItem(data){return request({
url: '/financial/api/financialClaimCancellationApplyItem/saveFinancialClaimCancellationApplyItem',
method: 'post',
data:data,
});
}


export function deleteFinancialClaimCancellationApplyItem(params){return request({
url: '/financial/api/financialClaimCancellationApplyItem/deleteFinancialClaimCancellationApplyItem',
method: 'post',
params:params,
});
}


