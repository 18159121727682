/**
 * 注册、登录、注销
 * */
import util from '@/libs/util';
import router from '@/router';
import {
	getPdf,
	sendSignMobileCodeReq,
	sign,
	getFaceEsignInfo,
	verificationResultReq
} from '@api/mobile/tenantChangePropertyDeliverysignature';
import {
	formatTime
} from '@/plugins/utils';
import {
	Message,
	Notice
} from 'view-design';

export default {
	namespaced: true,
	state: {
		docNum: "",
		base64String: "",
		billId: "",
		mobileNo: "",
		billType: "",
		accountId: "",
		imgsrc: "",
	},
	actions: {
		getPdf({
			state,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				getPdf(data)
					.then(async res => {
						state.initInfo = res;
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		sendSignMobileCodeReq({
			state,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				sendSignMobileCodeReq(data)
					.then(async res => {
						state.initInfo = res;
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		sign({
			state,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				sign(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getFaceEsignInfo({
			state,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				getFaceEsignInfo(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		verificationResultReq({
			state,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				verificationResultReq(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},



	},
	mutations: {}
};
