/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-04T20:25:05.157
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-houseduplicateremoval-'

export default 
{
path: '/storagemanage/houseduplicateremoval',
name: 'storagemanage-houseduplicateremoval',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}houseduplicateremoval-index',

meta:{
...meta,
title: '去重管理'
},

component: () => import('@/pages/storagemanage/houseduplicateremoval/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}houseduplicateremoval-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '去重管理编辑'
},

component: () => import('@/pages/storagemanage/houseduplicateremoval/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}houseduplicateremovaldetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '去重管理编辑'
},

component: () => import('@/pages/storagemanage/houseduplicateremoval/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}houseduplicateremovalView',
auth: [
'hidden'
],

meta:{
...meta,
title: '去重管理详情查看'
},

component: () => import('@/pages/storagemanage/houseduplicateremoval/detail-view') ,
},
]
};
