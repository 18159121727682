import Vue from 'vue'
import VueRouter from 'vue-router'
import {LoadingBar} from 'view-design'

import store from '@/store/index'
import Setting from '@/setting'
import storage from '@/libs/vendors/storage'
import { title, jumpSSOLogin } from '@/libs/util'
import routes from './routes'

Vue.use(VueRouter)
const router = new VueRouter({ mode: Setting.routerMode, routes })
router.beforeEach(async (to, from, next) => {
  if (Setting.showProgressBar && to.meta.progressBar !== false) LoadingBar.start()
  if (storage.get('platform') === 'h5') return next()
  if (to.query.accessToken) {
    // 权限校验
    if (!['2', '3'].includes(to.query.userType)) return next({name: '403'})
    await store.dispatch('admin/account/login2', to.query)
  }
  // 判断是否需要登录才可以进入
  if (to.meta.auth && !storage.has('token')) return jumpSSOLogin({ to })
  if (!store.getters['admin/user/token'] && storage.has('user')) await store.dispatch('admin/account/autoLogin', storage.get('user', {}))
  // 多页控制 打开新的页面
  await store.dispatch('admin/page/open', to)
  // 更改标题
  title({title: to.meta.title})
  next()
})
router.afterEach(to => {
  if (Setting.showProgressBar && to.meta.progressBar !== false) LoadingBar.finish()
})
export default router
