/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-07-15T10:05:28.372
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveEnergyApplication,
	getEnergyApplicationCreateInit,
	getEnergyApplicationEditInit,
	getEnergyApplicationView,
	deleteEnergyApplication,
	downloadRentTemplate
} from '@api/rentmanage/EnergyApplication';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	mutations:{
		
	},
	actions: {
		getEnergyApplicationCreateInit({
			commit,state
		},params) {
			return new Promise((resolve, reject) => {
				getEnergyApplicationCreateInit(params)
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						
						// debugger
						
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEnergyApplicationEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEnergyApplicationEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveEnergyApplication({}, EnergyApplication) {
			return new Promise((resolve, reject) => {
				saveEnergyApplication(EnergyApplication)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getEnergyApplicationView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEnergyApplicationView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteEnergyApplication({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEnergyApplication({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		async downloadRentTemplate({commit},data){
			let res = await downloadRentTemplate(data)
			return res
			// commit('setDownloadRentTemplate',res)
		}
	}
};
