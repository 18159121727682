import request from '@/plugins/request';

export function listStockpileIntentionHouseInit(data){return request({
url: '/storagemanage/api/stockpileIntentionHouse/listStockpileIntentionHouseInit',
method: 'post',
data:data,
});
}


export function listStockpileIntentionHouseByAuthorizations(data){return request({
url: '/storagemanage/api/stockpileIntentionHouse/listStockpileIntentionHouseByAuthorizations',
method: 'post',
data:data,
});
}


export function listStockpileIntentionHouse(data){return request({
url: '/storagemanage/api/stockpileIntentionHouse/listStockpileIntentionHouse',
method: 'post',
data:data,
});
}


export function getStockpileIntentionHouseCreateInit(){return request({
url: '/storagemanage/api/stockpileIntentionHouse/getStockpileIntentionHouseCreateInit',
method: 'post',
});
}


export function getStockpileIntentionHouseEditInit(params){return request({
url: '/storagemanage/api/stockpileIntentionHouse/getStockpileIntentionHouseEditInit',
method: 'post',
params:params,
});
}


export function getStockpileIntentionHouseView(params){return request({
url: '/storagemanage/api/stockpileIntentionHouse/getStockpileIntentionHouseView',
method: 'post',
params:params,
});
}


export function saveStockpileIntentionHouse(data){return request({
url: '/storagemanage/api/stockpileIntentionHouse/saveStockpileIntentionHouse',
method: 'post',
data:data,
});
}


export function deleteStockpileIntentionHouse(params){return request({
url: '/storagemanage/api/stockpileIntentionHouse/deleteStockpileIntentionHouse',
method: 'post',
params:params,
});
}




