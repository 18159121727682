/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-24T10:10:29.723
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-khmdemandapply-'

export default 
{
path: '/rentmanage/khmdemandapply',
name: 'rentmanage-khmdemandapply',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}khmdemandapply-index',

meta:{
...meta,
title: '需求管理'
},

component: () => import('@/pages/rentmanage/khmdemandapply/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}khmdemandapply-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '需求管理编辑'
},

component: () => import('@/pages/rentmanage/khmdemandapply/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}khmdemandapplydetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '需求管理新增'
},

component: () => import('@/pages/rentmanage/khmdemandapply/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}khmdemandapplyView',
auth: [
'hidden'
],

meta:{
...meta,
title: '需求管理详情查看'
},

component: () => import('@/pages/rentmanage/khmdemandapply/detail-view') ,
},
]
};
