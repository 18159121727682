/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-09T14:50:23.954
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listStockpileIntentionOwner,
	listStockpileIntentionOwnerInit,
	listStockpileIntentionOwnerByAuthorizations,
	deleteStockpileIntentionOwner
	
} from '@api/storagemanage/StockpileIntentionOwner';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listStockpileIntentionOwner({
			state
		}) {
			return new Promise((resolve, reject) => {
				listStockpileIntentionOwner(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listStockpileIntentionOwnerByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listStockpileIntentionOwnerByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listStockpileIntentionOwnerInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listStockpileIntentionOwnerInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteStockpileIntentionOwner({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteStockpileIntentionOwner({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
