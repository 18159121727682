/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-14T19:43:30.579
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentTrafficLine,
	listRentTrafficLineInit,
	listRentTrafficLineByAuthorizations,
	deleteRentTrafficLine
	
} from '@api/rentmanage/RentTrafficLine';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentTrafficLine({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTrafficLine(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentTrafficLineByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTrafficLineByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentTrafficLineInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentTrafficLineInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentTrafficLine({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentTrafficLine({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
