/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-14T20:19:04.261
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listManagementAreaUserInit(data){return request({
url: '/storagemanage/api/managementAreaUser/listManagementAreaUserInit',
method: 'post',
data:data,
});
}


export function listManagementAreaUserByAuthorizations(data){return request({
url: '/storagemanage/api/managementAreaUser/listManagementAreaUserByAuthorizations',
method: 'post',
data:data,
});
}


export function listManagementAreaUser(data){return request({
url: '/storagemanage/api/managementAreaUser/listManagementAreaUser',
method: 'post',
data:data,
});
}


export function getManagementAreaUserCreateInit(){return request({
url: '/storagemanage/api/managementAreaUser/getManagementAreaUserCreateInit',
method: 'post',
});
}


export function getManagementAreaUserEditInit(params){return request({
url: '/storagemanage/api/managementAreaUser/getManagementAreaUserEditInit',
method: 'post',
params:params,
});
}


export function getManagementAreaUserView(params){return request({
url: '/storagemanage/api/managementAreaUser/getManagementAreaUserView',
method: 'post',
params:params,
});
}


export function saveManagementAreaUser(data){return request({
url: '/storagemanage/api/managementAreaUser/saveManagementAreaUser',
method: 'post',
data:data,
});
}


export function saveOrgusermanageToAreaUser(data){return request({
url: '/storagemanage/api/managementAreaUser/saveOrgusermanageToAreaUser',
method: 'post',
data:data,
});
}


export function deleteManagementAreaUser(params){return request({
url: '/storagemanage/api/managementAreaUser/deleteManagementAreaUser',
method: 'post',
params:params,
});
}


