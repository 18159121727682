/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-24T16:23:29.578
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialPaymentItemInit(data){return request({
url: '/financial/api/financialPaymentItem/listFinancialPaymentItemInit',
method: 'post',
data:data,
});
}


export function listFinancialPaymentItemByAuthorizations(data){return request({
url: '/financial/api/financialPaymentItem/listFinancialPaymentItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialPaymentItem(data){return request({
url: '/financial/api/financialPaymentItem/listFinancialPaymentItem',
method: 'post',
data:data,
});
}


export function getFinancialPaymentItemCreateInit(){return request({
url: '/financial/api/financialPaymentItem/getFinancialPaymentItemCreateInit',
method: 'post',
});
}


export function getFinancialPaymentItemEditInit(params){return request({
url: '/financial/api/financialPaymentItem/getFinancialPaymentItemEditInit',
method: 'post',
params:params,
});
}


export function getFinancialPaymentItemView(params){return request({
url: '/financial/api/financialPaymentItem/getFinancialPaymentItemView',
method: 'post',
params:params,
});
}


export function saveFinancialPaymentItem(data){return request({
url: '/financial/api/financialPaymentItem/saveFinancialPaymentItem',
method: 'post',
data:data,
});
}


export function deleteFinancialPaymentItem(params){return request({
url: '/financial/api/financialPaymentItem/deleteFinancialPaymentItem',
method: 'post',
params:params,
});
}


