/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-10T15:01:42.744
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-RentHouseOnline-'

export default 
{
path: '/rentmanage/renthouseonline',
name: 'rentmanage-RentHouseOnline',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}RentHouseOnline-index',

meta:{
...meta,
title: '上线管理'
},

component: () => import('@/pages/rentmanage/renthouseonline/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}RentHouseOnline-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '上线管理编辑'
},

component: () => import('@/pages/rentmanage/renthouseonline/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}RentHouseOnlinedetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '上线管理新增'
},

component: () => import('@/pages/rentmanage/renthouseonline/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}RentHouseOnlineView',
auth: [
'hidden'
],

meta:{
...meta,
title: '上线管理详情查看'
},

component: () => import('@/pages/rentmanage/renthouseonline/detail-view') ,
},
]
};
