/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-24T14:53:18.857
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseReformInit(data){return request({
url: '/rentmanage/api/rentHouseReform/listRentHouseReformInit',
method: 'post',
data:data,
});
}


export function listRentHouseReformByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseReform/listRentHouseReformByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseReform(data){return request({
url: '/rentmanage/api/rentHouseReform/listRentHouseReform',
method: 'post',
data:data,
});
}


export function getRentHouseReformCreateInit(){return request({
url: '/rentmanage/api/rentHouseReform/getRentHouseReformCreateInit',
method: 'post',
});
}


export function getRentHouseReformEditInit(params){return request({
url: '/rentmanage/api/rentHouseReform/getRentHouseReformEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseReformView(params){return request({
url: '/rentmanage/api/rentHouseReform/getRentHouseReformView',
method: 'post',
params:params,
});
}


export function saveRentHouseReform(data){return request({
url: '/rentmanage/api/rentHouseReform/saveRentHouseReform',
method: 'post',
data:data,
});
}


export function deleteRentHouseReform(params){return request({
url: '/rentmanage/api/rentHouseReform/deleteRentHouseReform',
method: 'post',
params:params,
});
}


