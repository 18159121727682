/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-05-16T17:00:11.453
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveEvaluateAppointmentFinalRejection,
	getEvaluateAppointmentFinalRejectionCreateInit,
	getEvaluateAppointmentFinalRejectionEditInit,
	getEvaluateAppointmentFinalRejectionView,
	deleteEvaluateAppointmentFinalRejection
} from '@api/storagemanage/EvaluateAppointmentFinalRejection';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getEvaluateAppointmentFinalRejectionCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateAppointmentFinalRejectionCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateAppointmentFinalRejectionEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateAppointmentFinalRejectionEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveEvaluateAppointmentFinalRejection({}, EvaluateAppointmentFinalRejection) {
			return new Promise((resolve, reject) => {
				saveEvaluateAppointmentFinalRejection(EvaluateAppointmentFinalRejection)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateAppointmentFinalRejectionView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateAppointmentFinalRejectionView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteEvaluateAppointmentFinalRejection({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteEvaluateAppointmentFinalRejection({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
