/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-06-07 18:58:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-30 14:55:03
 * @FilePath: \iview-admin-pro-template\src\api\report\ReportHouseCount.js
 */
import request from '@/plugins/request';
// 根据项目类型和区域ID查询项目
export function getProjectInfo(params) {
    return request({
        url: '/rentmanage/api/rentHouseBig/getProjectInfo',
        method: 'post',
        params: params,
    });
}
// 根据项目取得管家
export function getHousekeeperListInfo(params) {
    return request({
        url: '/rentmanage/api/rentHouseBig/getHousekeeperListInfo',
        method: 'post',
        params: params,
    });
}
// 楼盘接口
export function listBuilding(data) {
    return request({
        url: '/storagemanage/api/building/listBuilding',
        method: 'post',
        data: data,
    });
}

// 主板
export function findHomeReportDisplayInfo(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/findHomeReportDisplayInfo',
        method: 'post',
        data: data,
    });
}
export function findHomeReportDisplayInfoStatistics(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/findHomeReportDisplayInfoStatistics',
        method: 'post',
        data: data,
    });
}
export function findHomeReportDisplayInfoRenewal(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/findHomeReportDisplayInfoRenewal',
        method: 'post',
        data: data,
    });
}
export function findHomeReportDisplayInfoPayment(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/findHomeReportDisplayInfoPayment',
        method: 'post',
        data: data,
    });
}
export function findHomeReportDisplayInfoPutOnShelf(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/findHomeReportDisplayInfoPutOnShelf',
        method: 'post',
        data: data,
    });
}

// 详情
export function findHomeReportDisplayInfoDetails(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/findHomeReportDisplayInfoDetails',
        method: 'post',
        data: data,
    });
}

// 导出文档
export function downloadFindHomeReportDisplayInfo(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/downloadFindHomeReportDisplayInfo',
        method: 'post',
        data: data,
        responseType: 'blob'
    });
}

// 导出文档
export function downloadFindSingleHomeReportDisplayInfo(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/downloadFindSingleHomeReportDisplayInfo',
        method: 'post',
        data: data,
        responseType: 'blob'
    });
}

export function listBigArea(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/listBigArea',
        method: 'post',
        data: data,
    });
}
// 查询单个状态的详情信息
export function findSingleHomeReportDisplayInfo(data) {
    return request({
        url: '/rentmanage/api/rentHouseBig/findSingleHomeReportDisplayInfo',
        method: 'post',
        data: data,
    });
}


