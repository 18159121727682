/*
 * @Descripttion:
 * @version:
 * @Author: co
 * @Date: 2021-05-06 17:20:09
 * @LastEditors: co
 * @LastEditTime: 2021-05-24 14:31:02
 * @FilePath: \giant_find\iview-admin-pro-template\src\plugins\utils\initsqllist.js
 */
// 初始化官网房源列表查询条件
export function initSQL() {
    this.AdvanceQuery.entity = {}
    this.AdvanceQuery.outerWhereSubjoin = {}
    this.AdvanceQuery.isOrder = false
    this.AdvanceQuery.isOrderAsc = false
    this.AdvanceQuery.orderColumn = 'lastUpdateDate'
    this.AdvanceQuery.pageIndex = 1
    this.AdvanceQuery.pageSize = 30
}

export function initPhoneSQL() {
    this.AdvanceQuery.entity = {}
    this.AdvanceQuery.outerWhereSubjoin = {}
    this.AdvanceQuery.isOrder = false
    this.AdvanceQuery.isOrderAsc = false
    this.AdvanceQuery.orderColumn = 'lastUpdateDate'
    this.AdvanceQuery.pageIndex = 1
    this.AdvanceQuery.pageSize = 10
}