/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-20T12:07:15.718
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listOrgUserManage,
	listOrgUserManageInit,
	listOrgUserManageByAuthorizations,
	deleteOrgUserManage,
	getAuthRoleByUserid,
	saveUserAuthRole,
	resetPassword,
	
} from '@api/systemmanage/OrgUserManage';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listOrgUserManage({
			state
		}) {
			return new Promise((resolve, reject) => {
				listOrgUserManage(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listOrgUserManageByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listOrgUserManageByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listOrgUserManageInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listOrgUserManageInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteOrgUserManage({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteOrgUserManage({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getAuthRoleByUserid({
			state
		}, {userid}) {
			return new Promise((resolve, reject) => {
				getAuthRoleByUserid({
						userid
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		saveUserAuthRole({}, data) {
			return new Promise((resolve, reject) => {
				saveUserAuthRole(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		resetPassword({}, data) {
			return new Promise((resolve, reject) => {
				resetPassword(data)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
