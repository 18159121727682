/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-03T10:29:11.598
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentPublishChannel,
	getRentPublishChannelCreateInit,
	getRentPublishChannelEditInit,
	getRentPublishChannelView,
	deleteRentPublishChannel
} from '@api/rentmanage/RentPublishChannel';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentPublishChannelConfigInit,
listRentPublishChannelConfigByAuthorizations,
deleteRentPublishChannelConfig
} from '@api/rentmanage/RentPublishChannelConfig';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentPublishChannelCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentPublishChannelCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentPublishChannelEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPublishChannelEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentPublishChannel({}, RentPublishChannel) {
			return new Promise((resolve, reject) => {
				saveRentPublishChannel(RentPublishChannel)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentPublishChannelView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPublishChannelView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentPublishChannel({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentPublishChannel({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
