/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-07-02T17:43:04.084
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getReportFinancialRentContractItemPaymentMonthEditInit,
	getReportFinancialRentContractItemPaymentMonthView
} from '@api/report/ReportFinancialRentContractItemPaymentMonth';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},
	actions: {
		getReportFinancialRentContractItemPaymentMonthEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemPaymentMonthEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getReportFinancialRentContractItemPaymentMonthView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getReportFinancialRentContractItemPaymentMonthView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
