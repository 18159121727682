/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-17T17:56:04.45
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listRentIssueHouseApplyItem,
	listRentIssueHouseApplyItemInit,
	listRentIssueHouseApplyItemByAuthorizations,
	deleteRentIssueHouseApplyItem
} from '@api/rentmanage/RentIssueHouseApplyItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listRentIssueHouseApplyItem({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentIssueHouseApplyItem(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentIssueHouseApplyItemByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentIssueHouseApplyItemByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listRentIssueHouseApplyItemInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listRentIssueHouseApplyItemInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentIssueHouseApplyItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentIssueHouseApplyItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
