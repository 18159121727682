/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-04T20:25:05.135
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listHouseDuplicateRemovalInit(data){return request({
url: '/storagemanage/api/houseDuplicateRemoval/listHouseDuplicateRemovalInit',
method: 'post',
data:data,
});
}

export function listHouseDuplicateRemovalInitBySQL(data){return request({
	url: '/storagemanage/api/houseDuplicateRemoval/listHouseDuplicateRemovalInitBySQL',
	method: 'post',
	data:data,
	});
	}

export function listHouseDuplicateRemovalByAuthorizations(data){return request({
url: '/storagemanage/api/houseDuplicateRemoval/listHouseDuplicateRemovalByAuthorizations',
method: 'post',
data:data,
});
}


export function listHouseDuplicateRemoval(data){return request({
url: '/storagemanage/api/houseDuplicateRemoval/listHouseDuplicateRemoval',
method: 'post',
data:data,
});
}


export function getHouseDuplicateRemovalCreateInit(){return request({
url: '/storagemanage/api/houseDuplicateRemoval/getHouseDuplicateRemovalCreateInit',
method: 'post',
});
}


export function getHouseDuplicateRemovalEditInit(params){return request({
url: '/storagemanage/api/houseDuplicateRemoval/getHouseDuplicateRemovalEditInit',
method: 'post',
params:params,
});
}


export function getHouseDuplicateRemovalView(params){return request({
url: '/storagemanage/api/houseDuplicateRemoval/getHouseDuplicateRemovalView',
method: 'post',
params:params,
});
}


export function saveHouseDuplicateRemoval(data){return request({
url: '/storagemanage/api/houseDuplicateRemoval/saveHouseDuplicateRemoval',
method: 'post',
data:data,
});
}


export function deleteHouseDuplicateRemoval(params){return request({
url: '/storagemanage/api/houseDuplicateRemoval/deleteHouseDuplicateRemoval',
method: 'post',
params:params,
});
}

export function repetitionSubmit(data){return request({
	url: '/storagemanage/api/houseDuplicateRemoval/repetitionSubmit',
	method: 'post',
	data:data,
	});
}


