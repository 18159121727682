/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-20T20:38:25.774
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listDroomPropertyDelivery,
	listDroomPropertyDeliveryInit,
	listDroomPropertyDeliveryByAuthorizations,
	deleteDroomPropertyDelivery,
    confirmPropertyDelivery,
    confirmConfigure,
	
} from '@api/storagemanage/DroomPropertyDelivery';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
        reqParams: {
            entity:{
               "custName": "",
               "telephone": "",
               "address": "",
               "agentName": "",
               "roomFullName": "",
               "houseNum": "",
               "status": "",
               "startTime": "",
               "endTime": "",
               "isOrder": true,
               "isOrderAsc": true,
               "isPage": true,
               "orderColumn": "",
               "pageIndex": 1,
               "pageSize": 10,
               "saasCompany": "",
               "selectColumns": [] 
            }
        },
        
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listDroomPropertyDelivery({
			state
		}) {
			return new Promise((resolve, reject) => {
				listDroomPropertyDelivery(state.reqParams)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listDroomPropertyDeliveryByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listDroomPropertyDeliveryByAuthorizations(state.reqParams)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listDroomPropertyDeliveryInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listDroomPropertyDeliveryInit(state.reqParams)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteDroomPropertyDelivery({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteDroomPropertyDelivery({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
        
        confirmPropertyDelivery({
        	dispatch,
        	state,
        }, id) {
        	return new Promise((resolve, reject) => {
        		confirmPropertyDelivery({
        				id : id
        			})
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
        
        confirmConfigure({
        	dispatch,
        	state,
        }, id) {
        	return new Promise((resolve, reject) => {
        		confirmConfigure({
        				id : id
        			})
        			.then(async res => {
        				resolve(res);
        			})
        			.catch(err => {
        				reject(err);
        			})
        	})
        },
        
	}
};
