/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-14T15:25:43.711
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import util from '@/libs/util';
import router from '@/router';
import {
	getRentLeaseContractEditInit,
	getRentLeaseContractView
} from '@api/rentmanage/RentLeaseContract';

import {
	listRentLeaseContractReceiptAgreementInit,
	listRentLeaseContractReceiptAgreementByAuthorizations,
	deleteRentLeaseContractReceiptAgreement
} from '@api/rentmanage/RentLeaseContractReceiptAgreement';

import {
	listRentLeaseContractReceiptPlanInit,
	listRentLeaseContractReceiptPlanByAuthorizations,
	deleteRentLeaseContractReceiptPlan
} from '@api/rentmanage/RentLeaseContractReceiptPlan';

import {
	listRentLeaseContractSaleInit,
	listRentLeaseContractSaleByAuthorizations,
	deleteRentLeaseContractSale
} from '@api/rentmanage/RentLeaseContractSale';

import {
	listRentLeaseContractTenantInit,
	listRentLeaseContractTenantByAuthorizations,
	deleteRentLeaseContractTenant
} from '@api/rentmanage/RentLeaseContractTenant';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery)
	},
	actions: {
		getRentLeaseContractEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentLeaseContractView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentLeaseContractView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entity);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
