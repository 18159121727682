export default

{
	path: "#",
	header: "home",
	icon: "md-speedometer",
	title: "旧存房服务",
	children: [{
		path: `/lcf/houseinfo/index`,
		title: '房源信息',
		header: null,
		icon:null
	}, ]

}

;
