/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T10:58:26.246
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancialEvidenceItem,
	getFinancialEvidenceItemCreateInit,
	getFinancialEvidenceItemEditInit,
	getFinancialEvidenceItemView,
	deleteFinancialEvidenceItem
} from '@api/financial/FinancialEvidenceItem';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listFinancialEvidenceDetailInit,
listFinancialEvidenceDetailByAuthorizations,
deleteFinancialEvidenceDetail
} from '@api/financial/FinancialEvidenceDetail';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialEvidenceItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialEvidenceItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialEvidenceItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialEvidenceItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialEvidenceItem({}, FinancialEvidenceItem) {
			return new Promise((resolve, reject) => {
				saveFinancialEvidenceItem(FinancialEvidenceItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialEvidenceItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialEvidenceItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialEvidenceItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialEvidenceItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
