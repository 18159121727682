/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-07T09:38:28.037
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveCCBSyncLog,
	getCCBSyncLogCreateInit,
	getCCBSyncLogEditInit,
	getCCBSyncLogView,
	deleteCCBSyncLog
} from '@api/house/CCBSyncLog';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getCCBSyncLogCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getCCBSyncLogCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getCCBSyncLogEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getCCBSyncLogEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveCCBSyncLog({}, CCBSyncLog) {
			return new Promise((resolve, reject) => {
				saveCCBSyncLog(CCBSyncLog)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getCCBSyncLogView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getCCBSyncLogView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteCCBSyncLog({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteCCBSyncLog({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
