/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-09T17:19:06.568
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportRentContractInit(data){return request({
url: '/report/api/reportRentContract/listReportRentContractInit',
method: 'post',
data:data,
});
}


export function listReportRentContractByAuthorizations(data){return request({
url: '/report/api/reportRentContract/listReportRentContractByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportRentContract(data){return request({
url: '/report/api/reportRentContract/listReportRentContract',
method: 'post',
data:data,
});
}


export function getReportRentContractCreateInit(){return request({
url: '/report/api/reportRentContract/getReportRentContractCreateInit',
method: 'post',
});
}


export function getReportRentContractEditInit(params){return request({
url: '/report/api/reportRentContract/getReportRentContractEditInit',
method: 'post',
params:params,
});
}


export function getReportRentContractView(params){return request({
url: '/report/api/reportRentContract/getReportRentContractView',
method: 'post',
params:params,
});
}


export function saveReportRentContract(data){return request({
url: '/report/api/reportRentContract/saveReportRentContract',
method: 'post',
data:data,
});
}


export function deleteReportRentContract(params){return request({
url: '/report/api/reportRentContract/deleteReportRentContract',
method: 'post',
params:params,
});
}


