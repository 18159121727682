/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-10T15:01:42.729
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseOnlineInit(data){return request({
url: '/rentmanage/api/rentHouseOnline/listRentHouseOnlineInit',
method: 'post',
data:data,
});
}


export function listRentHouseOnlineByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseOnline/listRentHouseOnlineByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseOnline(data){return request({
url: '/rentmanage/api/rentHouseOnline/listRentHouseOnline',
method: 'post',
data:data,
});
}


export function getRentHouseOnlineCreateInit(){return request({
url: '/rentmanage/api/rentHouseOnline/getRentHouseOnlineCreateInit',
method: 'post',
});
}


export function getRentHouseOnlineEditInit(params){return request({
url: '/rentmanage/api/rentHouseOnline/getRentHouseOnlineEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseOnlineView(params){return request({
url: '/rentmanage/api/rentHouseOnline/getRentHouseOnlineView',
method: 'post',
params:params,
});
}


export function saveRentHouseOnline(data){return request({
url: '/rentmanage/api/rentHouseOnline/saveRentHouseOnline',
method: 'post',
data:data,
});
}


export function deleteRentHouseOnline(params){return request({
url: '/rentmanage/api/rentHouseOnline/deleteRentHouseOnline',
method: 'post',
params:params,
});
}

export function startWorkflow(data){return request({
	url: '/rentmanage/api/rentHouseOnline/startWorkflow',
	method: 'post',
	data:data,
	});
	}
