import util from '@/libs/util';
import router from '@/router';
import dayjs from 'dayjs';
import {
	
} from '@api/storagemanage/payManagement';
import {
	Message,
	Notice
} from 'view-design';

import {
	Modal
} from 'view-design';

export default {
		namespaced: true,
		state: {
			// 初始化信息
			initInfo: {
				configItems: {
					'单据状态': []
				}
			},
			//查询条件
			reqParams: {
				billNo: "",
				applyBillNo: "",
				payerName: "",
				startTime:"",
				endTime: "",
				status: "",
				isPage: true,
				pageIndex: 1,
				pageSize: 10,
			},
			//查询接口返回的数据
			listDataObj: {
				entities: [],
				total:0,
			},
		},
		actions: {
			getList({
				dispatch,
				state,
			}) {
				return new Promise((resolve, reject) => {
					// // 开始请求登录接口
					// listDataReq(state.reqParams)
					// 	.then(async res => {
					// 		// 结束
					// 		state.listDataObj.entities = res.entities;
					// 		state.listDataObj.total = res.total;
					// 		console.log('res err: ', res);
					// 		console.log('err11: ', state.listDataObj);
					// 		resolve(state.listDataObj);
						
					// 	})
					// 	.catch(err => {
					// 		reject(err);
					// 	})
				})
			},
	
			pageInit({
				state,
				dispatch
			}, info) {
				return new Promise((resolve, reject) => {
					// listDataInitReq({})
					// 	.then(async res => {
					// 		state.initInfo = res
					// 		// 结束
					// 		resolve(state.initInfo);
					// 	})
					// 	.catch(err => {
					// 		// console.log('err: ', err);
					// 		reject(err);
					// 	})
				})
			},
		}
}