/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-10T11:30:43.719
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: 'ContractSubjectManage'
};

const pre = 'storagemanage-contractsubjectmanage-'

export default {
    path: '/storagemanage/contractsubjectmanage',
    name: 'storagemanage-contractsubjectmanage',
    redirect: {
        name: '${pre}-index'
    },

    meta: {
        ...meta,
    },

    component: BasicLayout,
    children: [{
            path: 'index',
            name: '${pre}contractsubjectmanage-index',

            meta: {
                ...meta,
                title: '存房签约主体'
            },

            component: () => import('@/pages/storagemanage/contractsubjectmanage/index'),
        },
        {
            path: 'detail-edit/:id',
            name: '${pre}contractsubjectmanage-detail-edit',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '合同主体表编辑'
            },

            component: () => import('@/pages/storagemanage/contractsubjectmanage/detail-edit'),
        },
        {
            path: 'detail-edit',
            name: '${pre}contractsubjectmanagedetail-create',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '合同主体表新增'
            },

            component: () => import('@/pages/storagemanage/contractsubjectmanage/detail-edit'),
        },
        {
            path: 'detail-view/:id',
            name: '${pre}contractsubjectmanageView',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '合同主体表详情查看'
            },

            component: () => import('@/pages/storagemanage/contractsubjectmanage/detail-view'),
        },
    ]
};
