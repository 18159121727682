/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-25T16:40:15.4
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportFinancialPaymentPostponeInit(data){return request({
url: '/report/api/reportFinancialPaymentPostpone/listReportFinancialPaymentPostponeInit',
method: 'post',
data:data,
});
}


export function listReportFinancialPaymentPostponeByAuthorizations(data){return request({
url: '/report/api/reportFinancialPaymentPostpone/listReportFinancialPaymentPostponeByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportFinancialPaymentPostpone(data){return request({
url: '/report/api/reportFinancialPaymentPostpone/listReportFinancialPaymentPostpone',
method: 'post',
data:data,
});
}


export function getReportFinancialPaymentPostponeCreateInit(){return request({
url: '/report/api/reportFinancialPaymentPostpone/getReportFinancialPaymentPostponeCreateInit',
method: 'post',
});
}


export function getReportFinancialPaymentPostponeEditInit(params){return request({
url: '/report/api/reportFinancialPaymentPostpone/getReportFinancialPaymentPostponeEditInit',
method: 'post',
params:params,
});
}


export function getReportFinancialPaymentPostponeView(params){return request({
url: '/report/api/reportFinancialPaymentPostpone/getReportFinancialPaymentPostponeView',
method: 'post',
params:params,
});
}


export function saveReportFinancialPaymentPostpone(data){return request({
url: '/report/api/reportFinancialPaymentPostpone/saveReportFinancialPaymentPostpone',
method: 'post',
data:data,
});
}


export function deleteReportFinancialPaymentPostpone(params){return request({
url: '/report/api/reportFinancialPaymentPostpone/deleteReportFinancialPaymentPostpone',
method: 'post',
params:params,
});
}


