/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T15:03:08.744
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listAssignedBillItemInit(data){return request({
url: '/storagemanage/api/assignedBillItem/listAssignedBillItemInit',
method: 'post',
data:data,
});
}


export function listAssignedBillItemByAuthorizations(data){return request({
url: '/storagemanage/api/assignedBillItem/listAssignedBillItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listAssignedBillItem(data){return request({
url: '/storagemanage/api/assignedBillItem/listAssignedBillItem',
method: 'post',
data:data,
});
}


export function getAssignedBillItemCreateInit(){return request({
url: '/storagemanage/api/assignedBillItem/getAssignedBillItemCreateInit',
method: 'post',
});
}


export function getAssignedBillItemEditInit(params){return request({
url: '/storagemanage/api/assignedBillItem/getAssignedBillItemEditInit',
method: 'post',
params:params,
});
}


export function getAssignedBillItemView(params){return request({
url: '/storagemanage/api/assignedBillItem/getAssignedBillItemView',
method: 'post',
params:params,
});
}


export function saveAssignedBillItem(data){return request({
url: '/storagemanage/api/assignedBillItem/saveAssignedBillItem',
method: 'post',
data:data,
});
}


export function deleteAssignedBillItem(params){return request({
url: '/storagemanage/api/assignedBillItem/deleteAssignedBillItem',
method: 'post',
params:params,
});
}


