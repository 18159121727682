import request from '@/plugins/request';

export function pageInit(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContract/wxListRentLeaseContractInit',
		method: 'post',
        params: params
	});
}

export function pageInitSigned(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContract/wxListRentLeaseContractInitSigned',
		method: 'post',
        params: params,
	});
}

export function getRentLeaseContractView(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContract/wxGetRentLeaseContractView',
		method: 'post',
        params: params
	});
}

export function sendLeaseContractPdf(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContract/wxSendLeaseContractPdf',
		method: 'post',
        params: params,
	});
}

export function onlineSigning(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContract/wxOnlineSigning',
		method: 'post',
        params: params,
	});
}

