/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-28T14:19:44.654
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFinancialCollectionCoupontInit(data){return request({
url: '/financial/api/financialCollectionCoupont/listFinancialCollectionCoupontInit',
method: 'post',
data:data,
});
}


export function listFinancialCollectionCoupontByAuthorizations(data){return request({
url: '/financial/api/financialCollectionCoupont/listFinancialCollectionCoupontByAuthorizations',
method: 'post',
data:data,
});
}


export function listFinancialCollectionCoupont(data){return request({
url: '/financial/api/financialCollectionCoupont/listFinancialCollectionCoupont',
method: 'post',
data:data,
});
}


export function getFinancialCollectionCoupontCreateInit(){return request({
url: '/financial/api/financialCollectionCoupont/getFinancialCollectionCoupontCreateInit',
method: 'post',
});
}


export function getFinancialCollectionCoupontEditInit(params){return request({
url: '/financial/api/financialCollectionCoupont/getFinancialCollectionCoupontEditInit',
method: 'post',
params:params,
});
}


export function getFinancialCollectionCoupontView(params){return request({
url: '/financial/api/financialCollectionCoupont/getFinancialCollectionCoupontView',
method: 'post',
params:params,
});
}


export function saveFinancialCollectionCoupont(data){return request({
url: '/financial/api/financialCollectionCoupont/saveFinancialCollectionCoupont',
method: 'post',
data:data,
});
}


export function deleteFinancialCollectionCoupont(params){return request({
url: '/financial/api/financialCollectionCoupont/deleteFinancialCollectionCoupont',
method: 'post',
params:params,
});
}


