/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T10:40:36.056
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancialEvidence,
	getFinancialEvidenceCreateInit,
	getFinancialEvidenceEditInit,
	getFinancialEvidenceView,
	deleteFinancialEvidence
} from '@api/financial/FinancialEvidence';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listFinancialEvidenceItemInit,
listFinancialEvidenceItemByAuthorizations,
deleteFinancialEvidenceItem
} from '@api/financial/FinancialEvidenceItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialEvidenceCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialEvidenceCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialEvidenceEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialEvidenceEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialEvidence({}, FinancialEvidence) {
			return new Promise((resolve, reject) => {
				saveFinancialEvidence(FinancialEvidence)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialEvidenceView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialEvidenceView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialEvidence({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialEvidence({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
