/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-15T17:10:25.452
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinanceRentApply,
	getFinanceRentApplyCreateInit,
	getFinanceRentApplyEditInit,
	getFinanceRentApplyView,
	deleteFinanceRentApply

} from '@api/storagemanage/FinanceRentApply';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getFinanceRentApplyCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinanceRentApplyCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinanceRentApplyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinanceRentApplyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinanceRentApply({}, FinanceRentApply) {
			return new Promise((resolve, reject) => {
				saveFinanceRentApply(FinanceRentApply)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getFinanceRentApplyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinanceRentApplyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		
		
		deleteFinanceRentApply({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinanceRentApply({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
