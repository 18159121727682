/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T21:12:24.339
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listFollowBillInit(data){return request({
url: '/storagemanage/api/followBill/listFollowBillInit',
method: 'post',
data:data,
});
}


export function listFollowBillByAuthorizations(data){return request({
url: '/storagemanage/api/followBill/listFollowBillByAuthorizations',
method: 'post',
data:data,
});
}


export function listFollowBill(data){return request({
url: '/storagemanage/api/followBill/listFollowBill',
method: 'post',
data:data,
});
}


export function getFollowBillCreateInit(){return request({
url: '/storagemanage/api/followBill/getFollowBillCreateInit',
method: 'post',
});
}


export function getFollowBillEditInit(params){return request({
url: '/storagemanage/api/followBill/getFollowBillEditInit',
method: 'post',
params:params,
});
}


export function getFollowBillView(params){return request({
url: '/storagemanage/api/followBill/getFollowBillView',
method: 'post',
params:params,
});
}


export function saveFollowBill(data){return request({
url: '/storagemanage/api/followBill/saveFollowBill',
method: 'post',
data:data,
});
}


export function deleteFollowBill(params){return request({
url: '/storagemanage/api/followBill/deleteFollowBill',
method: 'post',
params:params,
});
}


