import Cookies from 'js-cookie';
import Setting from '@/setting'

const api = Cookies.withAttributes()
export default {
    set: (name = '', value = '', cookieSetting = {}) => api.set(`${process.env.VUE_APP_NODE_ENV}-admin-${name}`, value, Object.assign({expires: Setting.cookiesExpires}, cookieSetting)),
    get: (name = '') => api.get(`${process.env.VUE_APP_NODE_ENV}-admin-${name}`),
    getAll: () => api.get(),
    remove: (name = '') => api.remove(`${process.env.VUE_APP_NODE_ENV}-admin-${name}`)
}
