/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-06-29T16:17:47.672
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportfinancialrentcontractitempaymentcollection-'

export default 
{
path: '/report/reportfinancialrentcontractitempaymentcollection',
name: 'report-reportfinancialrentcontractitempaymentcollection',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportfinancialrentcontractitempaymentcollection-index',

meta:{
...meta,
title: '应收追缴报表'
},

component: () => import('@/pages/report/reportfinancialrentcontractitempaymentcollection/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportfinancialrentcontractitempaymentcollection-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '应收追缴报表编辑'
},

component: () => import('@/pages/report/reportfinancialrentcontractitempaymentcollection/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportfinancialrentcontractitempaymentcollectiondetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '应收追缴报表新增'
},

component: () => import('@/pages/report/reportfinancialrentcontractitempaymentcollection/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportfinancialrentcontractitempaymentcollectionView',
auth: [
'hidden'
],

meta:{
...meta,
title: '应收追缴报表详情查看'
},

component: () => import('@/pages/report/reportfinancialrentcontractitempaymentcollection/detail-view') ,
},
]
};
