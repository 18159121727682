/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T19:13:41.318
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentConsumeMaterialUserInit(data){return request({
url: '/rentmanage/api/rentConsumeMaterialUser/listRentConsumeMaterialUserInit',
method: 'post',
data:data,
});
}


export function listRentConsumeMaterialUserByAuthorizations(data){return request({
url: '/rentmanage/api/rentConsumeMaterialUser/listRentConsumeMaterialUserByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentConsumeMaterialUser(data){return request({
url: '/rentmanage/api/rentConsumeMaterialUser/listRentConsumeMaterialUser',
method: 'post',
data:data,
});
}


export function getRentConsumeMaterialUserCreateInit(){return request({
url: '/rentmanage/api/rentConsumeMaterialUser/getRentConsumeMaterialUserCreateInit',
method: 'post',
});
}


export function getRentConsumeMaterialUserEditInit(params){return request({
url: '/rentmanage/api/rentConsumeMaterialUser/getRentConsumeMaterialUserEditInit',
method: 'post',
params:params,
});
}


export function getRentConsumeMaterialUserView(params){return request({
url: '/rentmanage/api/rentConsumeMaterialUser/getRentConsumeMaterialUserView',
method: 'post',
params:params,
});
}


export function saveRentConsumeMaterialUser(data){return request({
url: '/rentmanage/api/rentConsumeMaterialUser/saveRentConsumeMaterialUser',
method: 'post',
data:data,
});
}


export function deleteRentConsumeMaterialUser(params){return request({
url: '/rentmanage/api/rentConsumeMaterialUser/deleteRentConsumeMaterialUser',
method: 'post',
params:params,
});
}


