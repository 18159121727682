/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-05-16T17:00:11.446
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listEvaluateAppointmentFinalRejectionInit(data){return request({
url: '/storagemanage/api/evaluateAppointmentFinalRejection/listEvaluateAppointmentFinalRejectionInit',
method: 'post',
data:data,
});
}


export function listEvaluateAppointmentFinalRejectionByAuthorizations(data){return request({
url: '/storagemanage/api/evaluateAppointmentFinalRejection/listEvaluateAppointmentFinalRejectionByAuthorizations',
method: 'post',
data:data,
});
}


export function listEvaluateAppointmentFinalRejection(data){return request({
url: '/storagemanage/api/evaluateAppointmentFinalRejection/listEvaluateAppointmentFinalRejection',
method: 'post',
data:data,
});
}


export function getEvaluateAppointmentFinalRejectionCreateInit(){return request({
url: '/storagemanage/api/evaluateAppointmentFinalRejection/getEvaluateAppointmentFinalRejectionCreateInit',
method: 'post',
});
}


export function getEvaluateAppointmentFinalRejectionEditInit(params){return request({
url: '/storagemanage/api/evaluateAppointmentFinalRejection/getEvaluateAppointmentFinalRejectionEditInit',
method: 'post',
params:params,
});
}


export function getEvaluateAppointmentFinalRejectionView(params){return request({
url: '/storagemanage/api/evaluateAppointmentFinalRejection/getEvaluateAppointmentFinalRejectionView',
method: 'post',
params:params,
});
}


export function saveEvaluateAppointmentFinalRejection(data){return request({
url: '/storagemanage/api/evaluateAppointmentFinalRejection/saveEvaluateAppointmentFinalRejection',
method: 'post',
data:data,
});
}


export function deleteEvaluateAppointmentFinalRejection(params){return request({
url: '/storagemanage/api/evaluateAppointmentFinalRejection/deleteEvaluateAppointmentFinalRejection',
method: 'post',
params:params,
});
}


