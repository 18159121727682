/*
 * @Author: co
 * @Date: 2021-06-30 10:12:40
 */
const state = {
  propertydeliveryProcessObj: {
    costAmount: '',
    lastReformDeliveryStartDate: '',
    lastReformDeliveryDate: '',
  },
}

const mutations = {
  setPropertydeliveryProcessObj: (state, data) => {
    state.propertydeliveryProcessObj = data
  },
}

const actions = {
  setPropertydeliveryProcessObj: ({ commit }, data) => {
    commit('setPropertydeliveryProcessObj', data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}