/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-18T17:50:47.994
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveKhmPayDeductionApply,
	getKhmPayDeductionApplyCreateInit,
	getKhmPayDeductionApplyEditInit,
	getKhmPayDeductionApplyView,
	deleteKhmPayDeductionApply,
	startWorkflow
} from '@api/storagemanage/KhmPayDeductionApply';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listkhmPayDeductionApplyItemInit,
listkhmPayDeductionApplyItemByAuthorizations,
deletekhmPayDeductionApplyItem
} from '@api/storagemanage/khmPayDeductionApplyItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getKhmPayDeductionApplyCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getKhmPayDeductionApplyCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		startWorkflow({}, KhmPayDeductionApply) {
			return new Promise((resolve, reject) => {
				startWorkflow(KhmPayDeductionApply)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getKhmPayDeductionApplyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getKhmPayDeductionApplyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveKhmPayDeductionApply({}, KhmPayDeductionApply) {
			return new Promise((resolve, reject) => {
				saveKhmPayDeductionApply(KhmPayDeductionApply)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getKhmPayDeductionApplyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getKhmPayDeductionApplyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteKhmPayDeductionApply({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteKhmPayDeductionApply({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
