import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	redirect: {
    name: `${pre}systemSetting-dictionary-list`
  },
	meta,
	component: BasicLayout,
	children: [
		{
			path: 'systemSetting-dictionary-list',
			name: `${pre}systemSetting-dictionary-list`,
			meta: {
				...meta,
				title: '树状数据字典',
				closable: true
			},
			component: () => import('@/pages/storagemanage/systemSetting/dictionary')
		},
		{
			path: 'systemSetting-dictionaryPj-list',
			name: `${pre}systemSetting-dictionaryPj-list`,
			meta: {
				...meta,
				title: '平级数据字典',
				closable: true
			},
			component: () => import('@/pages/storagemanage/systemSetting/dictionaryPj')
		},
	]
};
