/**
 * 注册、登录、注销
 * */
import util from '@/libs/util';
import router from '@/router';
import {
	pageInit,
	pageInitSigned,
    getRentLeaseContractView,
    sendLeaseContractPdf,
    onlineSigning,
} from '@api/mobile/rentContract';
import {
	formatTime
} from '@/plugins/utils';
import {
	Message,
	Notice
} from 'view-design';

export default {
	namespaced: true,
	state: {
		pageInitParams: {
			"tenantId": ""
		},

	},
	actions: {
        pageInit({
        	dispatch,
        	state,
        },{
			tenantId
		}) {
        	return new Promise((resolve, reject) => {
        		pageInit({
        			tenantId
        		})
        			.then(async res => {
        				// state.listTreeDataObj = res
        				resolve(res);
        			})
        			.catch(err => {
        				// console.log('err: ', err);
        				reject(err);
        			})
        	})
        },
        pageInitSigned({
        	dispatch,
        	state,
        },{
			tenantId
		}) {
        	return new Promise((resolve, reject) => {
        		pageInitSigned({
        			tenantId
        		})
        			.then(async res => {
        				// state.listTreeDataObj = res
        				resolve(res);
        			})
        			.catch(err => {
        				// console.log('err: ', err);
        				reject(err);
        			})
        	})
        },
        getRentLeaseContractView({
        	dispatch,
        	state,
        },{
			id
		}) {
        	return new Promise((resolve, reject) => {
        		getRentLeaseContractView({
        			id
        		})
        			.then(async res => {
        				// state.listTreeDataObj = res
        				resolve(res);
        			})
        			.catch(err => {
        				// console.log('err: ', err);
        				reject(err);
        			})
        	})
        },
        
        sendLeaseContractPdf({
        	dispatch,
        	state,
        },{
        	id,type,tenantId
        }) {
        	return new Promise((resolve, reject) => {
        		sendLeaseContractPdf({
        			id,type,tenantId
        		})
        			.then(async res => {
        				// state.listTreeDataObj = res
        				resolve(res);
        			})
        			.catch(err => {
        				// console.log('err: ', err);
        				reject(err);
        			})
        	})
        },
        
        onlineSigning({
        	dispatch,
        	state,
        },{
        	billId
        }) {
        	return new Promise((resolve, reject) => {
        		onlineSigning({
        			billId
        		})
        			.then(async res => {
        				// state.listTreeDataObj = res
        				resolve(res);
        			})
        			.catch(err => {
        				// console.log('err: ', err);
        				reject(err);
        			})
        	})
        },
        
    },
    
	mutations: {
        
    }
};
