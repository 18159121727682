/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:58.282
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listVacancyPeriodInfoInit(data){return request({
url: '/lcf/api/vacancyPeriodInfo/listVacancyPeriodInfoInit',
method: 'post',
data:data,
});
}


export function listVacancyPeriodInfoByAuthorizations(data){return request({
url: '/lcf/api/vacancyPeriodInfo/listVacancyPeriodInfoByAuthorizations',
method: 'post',
data:data,
});
}


export function listVacancyPeriodInfo(data){return request({
url: '/lcf/api/vacancyPeriodInfo/listVacancyPeriodInfo',
method: 'post',
data:data,
});
}


export function getVacancyPeriodInfoCreateInit(){return request({
url: '/lcf/api/vacancyPeriodInfo/getVacancyPeriodInfoCreateInit',
method: 'post',
});
}


export function getVacancyPeriodInfoEditInit(params){return request({
url: '/lcf/api/vacancyPeriodInfo/getVacancyPeriodInfoEditInit',
method: 'post',
params:params,
});
}


export function getVacancyPeriodInfoView(params){return request({
url: '/lcf/api/vacancyPeriodInfo/getVacancyPeriodInfoView',
method: 'post',
params:params,
});
}


export function saveVacancyPeriodInfo(data){return request({
url: '/lcf/api/vacancyPeriodInfo/saveVacancyPeriodInfo',
method: 'post',
data:data,
});
}


export function deleteVacancyPeriodInfo(params){return request({
url: '/lcf/api/vacancyPeriodInfo/deleteVacancyPeriodInfo',
method: 'post',
params:params,
});
}


