/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-12-17T18:26:38.249
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listReportFinancialRentContractDetailCollection,
	listReportFinancialRentContractDetailCollectionInit,
	listReportFinancialRentContractDetailCollectionByAuthorizations,
	deleteReportFinancialRentContractDetailCollection,
	countReportFinancialRentContractDetailCollection
} from '@api/report/ReportFinancialRentContractDetailCollection';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		Entities: cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery: cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listReportFinancialRentContractDetailCollection({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialRentContractDetailCollection(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportFinancialRentContractDetailCollectionByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialRentContractDetailCollectionByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities = res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listReportFinancialRentContractDetailCollectionInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listReportFinancialRentContractDetailCollectionInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		countReportFinancialRentContractDetailCollection({
			state
		}) {
			return new Promise((resolve, reject) => {
				countReportFinancialRentContractDetailCollection(state.AdvanceQuery)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteReportFinancialRentContractDetailCollection({
			state
		}, { id }) {
			return new Promise((resolve, reject) => {
				deleteReportFinancialRentContractDetailCollection({
					id
				})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
