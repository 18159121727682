/**
 * 注册、登录、注销
 * */
import util from '@/libs/util';
import router from '@/router';
import {
	pageInit,
	pageInitHistory,
	GetDataList,
	getListDataHistory,
	addPageInit,
	AddOrEditData,
	editPageInitReq,
	getEvaluteDetail,
	submitHouseRateReq,
	calculateReq,
	getProfitDetail,
	deleteProfit,
	restartProfit,
	startSignProfit,
	getArea,
	getAreaById,
	deteleRecordReq,
	getFirstYearRent,
} from '@api/storagemanage/profitReport';
import {
	formatTime
} from '@/plugins/utils';
import {
	Message,
	Notice
} from 'view-design';

export default {
	namespaced: true,
	state: {
		reqParams: {
            entity:{
            "address": "",
            "buildingName": "",
            "deleteState": "",
            "endTime": "",
            "evaluationStatus": "",
            "houseNum": "",
            "isOrder": true,
            "isOrderAsc": true,
            "isPage": true,
            "orderColumn": "",
            "ownerName": "",
            "pageIndex": 1,
            "pageSize": 10,
            "saasCompany": "",
            "selectColumns": [],
            "startTime": ""
            }
		},
		//列表初始信息
		initInfo: {
			configItems: {}
		},
		reqParamsHistory: {
			"address": "",
			"buildingName": "",
			"deleteState": "",
			"endTime": "",
			"evaluationStatus": "",
			"houseNum": "",
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"ownerName": "",
			"pageIndex": 1,
			"pageSize": 10,
			"saasCompany": "",
			"selectColumns": [],
			"startTime": ""
		},
		//历史列表初始信息
		initInfoHistory: {
			configItems: {}
		},
		pageInitParams: {
            entity:{
                "address": "",
                "buildingName": "",
                "deleteState": "",
                "endTime": "",
                "evaluationStatus": "",
                "houseNum": "",
                "isOrder": true,
                "isOrderAsc": true,
                "isPage": true,
                "orderColumn": "",
                "ownerName": "",
                "pageIndex": 1,
                "pageSize": 10,
                "saasCompany": "",
                "selectColumns": [],
                "startTime": "",
                }
		},

		pageInitParamsHistory: {
			"address": "",
			"buildingName": "",
			"deleteState": "",
			"endTime": "",
			"evaluationStatus": "",
			"houseNum": "",
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"ownerName": "",
			"pageIndex": 1,
			"pageSize": 10,
			"saasCompany": "",
			"selectColumns": [],
			"startTime": ""
		},

		getAreaParams: {
			"entity": {
				"areaLevel": "",
				"areaName": "",
				"areaNum": "",
				"createId": "",
				"createName": "",
				"createTime": "",
				"deleteState": 0,
				"descript": "",
				"id": "",
				"isDirectly": "",
				"lastUpdateDate": "",
				"latitude": "",
				"longitude": "",
				"operatorId": "",
				"operatorLongName": "",
				"operatorName": "",
				"operatorTime": "",
				"parentId": "",
				"saasCompany": "",
				"serialNo": "",
				"shortName": "",
				"tmpId": "",
				"tmpLongName": "",
				"tmpOldId": "",
				"tmpOldParentId": "",
				"tmpParentId": ""
			},
			"isOrder": true,
			"isOrderAsc": true,
			"isPage": true,
			"orderColumn": "",
			"outerWhereSubjoin": {
				"endDate": "",
				"fuzzyMatching": "",
				"startDate": ""
			},
			"pageIndex": 0,
			"pageSize": 0,
			"selectColumns": []
		},
	},
	actions: {
		pageInit({
			state,
			dispatch
		}) {
			return new Promise((resolve, reject) => {
				pageInit(state.pageInitParams)
					.then(async res => {
						state.initInfo = res;
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		pageInitHistory({
			state,
			dispatch
		}) {
			return new Promise((resolve, reject) => {
				pageInitHistory(state.pageInitParamsHistory)
					.then(async res => {
						state.initInfoHistory = res;
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getListData({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				GetDataList(state.reqParams)
					.then(async res => {
						// state.listTreeDataObj = res
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getListDataHistory({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				getListDataHistory(state.reqParamsHistory)
					.then(async res => {
						// state.listTreeDataObj = res
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		addPageInit({
			state,
			dispatch
		}, info) {
			return new Promise((resolve, reject) => {
				addPageInit({})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		deleteProfit({
			state,
			dispatch
		}, billId) {
			return new Promise((resolve, reject) => {
				deleteProfit({
						billId: billId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		restartProfit({
			state,
			dispatch
		}, billId) {
			return new Promise((resolve, reject) => {
				restartProfit({
						billId: billId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		startSignProfit({
			state,
			dispatch
		}, billId) {
			return new Promise((resolve, reject) => {
				startSignProfit({
						billId: billId
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		editPageInit({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				editPageInitReq({
						id: id
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getEvaluteDetail({
			state,
			dispatch
		}, {
			billId,
			sourceBillId
		}) {
			return new Promise((resolve, reject) => {
				getEvaluteDetail({
						billId: billId,
						sourceBillId: sourceBillId,
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		submitHouseRateReq({
			state,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				submitHouseRateReq(data)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		calculateReq({
			state,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				calculateReq(data)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getProfitDetail({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				getProfitDetail({
						id: id
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getArea({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				getArea(state.getAreaParams)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		getAreaById({
			state,
			dispatch
		}, params) {
			return new Promise((resolve, reject) => {
				getAreaById(params)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		deteleRecord({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				deteleRecordReq({
						id: id
					})
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		submitDataEdit({
			state,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				AddOrEditData(data)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
		
		getFirstYearRent({
			state,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				getFirstYearRent(data)
					.then(async res => {
						// 结束
						resolve(res);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
	},
	mutations: {
		updateParentReqParams(state, obj) {
			state.parentParams['entity'][obj.key] = obj.value;
		},
		updateChildReqParams(state, obj) {
			state.childParams['entity'][obj.key] = obj.value;
		},
		resetChildReqParams(state, obj) {
			state.childParams = {
				"entity": {
					"groupType": "",
					"id": "",
					"lastUpdateDate": "",
					"nodeType": "",
					"operatorId": "",
					"operatorLongName": "",
					"operatorName": "",
					"operatorTime": "",
					"parentId": "",
					"remark": "",
					"saasCompany": "",
					"treeCode": "",
					"treeName": ""
				},
				"isOrder": true,
				"isOrderAsc": true,
				"isPage": true,
				"orderColumn": "",
				"outerWhereSubjoin": {
					"fuzzyMatching": ""
				},
				"pageIndex": 1,
				"pageSize": 10,
				"selectColumns": []
			}
		},
	}
};
