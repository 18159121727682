/*
 * @Descripttion: 
 * @version: 
 * @Author: co
 * @Date: 2021-06-04 16:55:28
 * @LastEditors: co
 * @LastEditTime: 2021-06-11 11:48:18
 * @FilePath: \iview-admin-pro-template\src\router\modules\report\ReportHouseCount.js
 */
/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-12-30T17:33:20.305
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true,
	cache: true
};

const pre = 'report-reporthousecount-'

export default {
	path: '/report/reporthousecount',
	name: 'report-reporthousecount',
	redirect: {
		name: `${pre}-index`
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
		path: 'index',
		name: `${pre}index`,

		meta: {
			...meta,
			title: '数据看板'
		},

		component: () => import('@/pages/report/reporthousecount/index'),
	},
	{
		path: 'detail-edit/:id',
		name: `${pre}reporthousecount-detail-edit`,
		auth: [
			'hidden'
		],

		meta: {
			...meta,
			title: '卡券报表编辑'
		},

		component: () => import('@/pages/report/reporthousecount/detail-edit'),
	},
	{
		path: 'detail-edit',
		name: `${pre}reportcardusecountdetail-create`,
		auth: [
			'hidden'
		],

		meta: {
			...meta,
			title: '卡券报表新增'
		},

		component: () => import('@/pages/report/reporthousecount/detail-edit'),
	},
	{
		path: 'occupancy-detail-view',
		name: `${pre}reportcardusecountView-occupancy`,
		auth: [
			'hidden'
		],

		meta: {
			...meta,
			title: '出租率报表详情查看'
		},

		component: () => import('@/pages/report/reporthousecount/occupancy-detail-view'),
	},
	{
		path: 'collection-detail-view',
		name: `${pre}reportcardusecountView-collection`,
		auth: [
			'hidden'
		],

		meta: {
			...meta,
			title: '回款率报表详情查看'
		},

		component: () => import('@/pages/report/reporthousecount/collection-detail-view'),
	},
	{
		path: 'renewal-detail-view',
		name: `${pre}reportcardusecountView-renewal`,
		auth: [
			'hidden'
		],

		meta: {
			...meta,
			title: '续约率报表详情查看'
		},

		component: () => import('@/pages/report/reporthousecount/renewal-detail-view'),
	},
	]
};
