/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-14T19:21:18.49
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportStorageIntentionInit(data){return request({
url: '/report/api/reportStorageIntention/listReportStorageIntentionInit',
method: 'post',
data:data,
});
}


export function listReportStorageIntentionByAuthorizations(data){return request({
url: '/report/api/reportStorageIntention/listReportStorageIntentionByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportStorageIntention(data){return request({
url: '/report/api/reportStorageIntention/listReportStorageIntention',
method: 'post',
data:data,
});
}


export function getReportStorageIntentionCreateInit(){return request({
url: '/report/api/reportStorageIntention/getReportStorageIntentionCreateInit',
method: 'post',
});
}


export function getReportStorageIntentionEditInit(params){return request({
url: '/report/api/reportStorageIntention/getReportStorageIntentionEditInit',
method: 'post',
params:params,
});
}


export function getReportStorageIntentionView(params){return request({
url: '/report/api/reportStorageIntention/getReportStorageIntentionView',
method: 'post',
params:params,
});
}


export function saveReportStorageIntention(data){return request({
url: '/report/api/reportStorageIntention/saveReportStorageIntention',
method: 'post',
data:data,
});
}


export function deleteReportStorageIntention(params){return request({
url: '/report/api/reportStorageIntention/deleteReportStorageIntention',
method: 'post',
params:params,
});
}


