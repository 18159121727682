/**
 * 注册、登录、注销
 * */
import util from '@/libs/util';
import router from '@/router';
import dayjs from 'dayjs';
import {
	GetDataList,
	pageInitReq,
	AddOrEditData,
	addPageInitReq,
	editPageInitReq
} from '@api/notification';
import {
	Message,
	Notice
} from 'view-design';

import {
	Modal
} from 'view-design';

export default {
	namespaced: true,
	state: {
		// 初始化信息
		initInfo: {
			configItems: {
				'外出类型': []
			}
		},
		editInitInfo: {
			configItems: {
				'外出类型': []
			}
		},
		//查询条件
		reqParams: {
			publishState: 1,
			isPage: true,
			pageIndex: 1,
			pageSize: 10,
		},
		//查询接口返回的数据
		listDataObj: {
			entities: [],
			total:0,
		},

		//新增参数
		addData: {
			"approveState": "",
			"approveTime": "",
			"approverId": "",
			"approverName": "",
			"billCode": "",
			"billCreateTime": "",
			"billCreaterId": "",
			"billCreaterName": "",
			"billId": "",
			"billTitle": "",
			"billUrl": "",
			"bizType": "",
			"deleteState": 0,
			"deptCode": "",
			"deptName": "",
			"descript": "",
			"employeePost": "",
			"inTime": "",
			"lastUpdateDate": "",
			"operatorId": "",
			"operatorLongName": "",
			"operatorName": "",
			"operatorTime": "",
			"outAddress": "",
			"outContent": "",
			"outTime": "",
			"outType": "",
			"saasCompany": "",
		},

		//编辑参数
		editData: {
			"approveState": "",
			"approveTime": "",
			"approverId": "",
			"approverName": "",
			"billCode": "",
			"billCreateTime": "",
			"billCreaterId": "",
			"billCreaterName": "",
			"billId": "",
			"billTitle": "",
			"billUrl": "",
			"bizType": "",
			"deleteState": 0,
			"deptCode": "",
			"deptName": "",
			"descript": "",
			"employeePost": "",
			"inTime": "",
			"lastUpdateDate": "",
			"operatorId": "",
			"operatorLongName": "",
			"operatorName": "",
			"operatorTime": "",
			"outAddress": "",
			"outContent": "",
			"outTime": "",
			"outType": "",
			"saasCompany": "",
		},
	},
	actions: {
		/**
		 * @description 登录
		 * @param {Object} param context
		 * @param {Object} param userCode {String} 用户账号
		 * @param {Object} param password {String} 密码
		 * @param {Object} param route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
		 */
		getList({
			dispatch,
			state,
		}) {
			return new Promise((resolve, reject) => {
				// 开始请求登录接口
				GetDataList(state.reqParams)
					.then(async res => {
						// 结束
						state.listDataObj.entities = state.listDataObj.entities.concat(res.entities)
						state.listDataObj.total = res.total
						resolve(res.entityResponse);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		pageInit({
			state,
			dispatch
		}, info) {
			return new Promise((resolve, reject) => {
				pageInitReq({})
					.then(async res => {
						state.initInfo = res
						// 结束
						resolve(state.initInfo);
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		detailPageInit({
			state,
			dispatch
		}, info) {
			return new Promise((resolve, reject) => {
				addPageInitReq({})
					.then(async res => {
						state.addData.billCreaterId = res.entityResponse.billCreaterId; //创建人id
						state.addData.billCreaterName = res.entityResponse.billCreaterName; //创建人姓名
						state.addData.deptName = res.entityResponse.deptName; //部门名称
						state.addData.deptCode = res.entityResponse.deptCode;
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		editPageInit({
			state,
			dispatch
		}, id) {
			return new Promise((resolve, reject) => {
				editPageInitReq({
						id: id
					})
					.then(async res => {
						state.editInitInfo = res
						state.editData = res.entityResponse
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		submitData({
			state,
			dispatch
		}, info) {
			state.addData.outTime = dayjs(state.addData.outTime).format('YYYY-MM-DD HH:mm:ss')
			state.addData.inTime = dayjs(state.addData.inTime).format('YYYY-MM-DD HH:mm:ss')
			// Message.error({
			// 	content: 'outTime:'+state.addData.outTime+'inTime:'+state.addData.inTime,
			// 	duration: 3000
			// });
			return new Promise((resolve, reject) => {
				AddOrEditData(state.addData)
					.then(async res => {
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},

		submitDataEdit({
			state,
			dispatch
		}, info) {
			state.editData.outTime = dayjs(state.editData.outTime).format('YYYY-MM-DD HH:mm:ss')
			state.editData.inTime = dayjs(state.editData.inTime).format('YYYY-MM-DD HH:mm:ss')
			// Message.error({
			// 	content: 'outTime:'+state.editData.outTime+'inTime:'+state.editData.inTime,
			// 	duration: 3000
			// });
			return new Promise((resolve, reject) => {
				AddOrEditData(state.editData)
					.then(async res => {
						// 结束
						resolve();
					})
					.catch(err => {
						// console.log('err: ', err);
						reject(err);
					})
			})
		},
	},
	mutations: {
		updateReqParams(state, obj) {
			state.reqParams[obj.key] = obj.value;
			console.log(state.reqParams,"reqParamsreqParams")
		},
	}
};
