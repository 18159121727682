/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-20T16:51:35.106
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinancialPayApply,
	listFinancialPayApplyInit,
	listFinancialPayApplyByAuthorizations,
	deleteFinancialPayApply
	
} from '@api/rentmanage/FinancialPayApply';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFinancialPayApply({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialPayApply(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialPayApplyByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialPayApplyByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialPayApplyInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialPayApplyInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialPayApply({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialPayApply({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
