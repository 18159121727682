/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T21:12:24.393
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFollowBill,
	getFollowBillCreateInit,
	getFollowBillEditInit,
	getFollowBillView,
	deleteFollowBill
} from '@api/storagemanage/FollowBill';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getFollowBillCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFollowBillCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFollowBillEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFollowBillEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFollowBill({state}, FollowBill) {
			return new Promise((resolve, reject) => {
				saveFollowBill(FollowBill)
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entity);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getFollowBillView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFollowBillView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteFollowBill({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFollowBill({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
