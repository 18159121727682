/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-08T17:39:58.077
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listContractSubject,
	listContractSubjectInit,
	deleteContractSubject,
	listContractSubjectAggregateRootInit,//变更 新增
	listContractSubjectAggregateRoot,//变更 新增
	deleteContractSubjectAggregateRootItem //变更 新增
	
} from '@api/storagemanage/ARContractSubject';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		//变更 新增
		listContractSubjectAggregateRoot({
			state
		}) {
			return new Promise((resolve, reject) => {
				listContractSubjectAggregateRoot(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities.rowFunctionAuthorization = res.entity.rowFunctionAuthorization;
						state.CompoundEntities.entities.entities = res.entity.listContractSubject;
						state.CompoundEntities.entities.total = res.entity.total;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		//变更 新增
		listContractSubjectAggregateRootInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listContractSubjectAggregateRootInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntitiesDictionary.rowFunctionAuthorization = res.entityResponse.rowFunctionAuthorization;
						state.CompoundEntitiesDictionary.entities = res.entityResponse.listContractSubject;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		//变更 新增
		deleteContractSubjectAggregateRootItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteContractSubjectAggregateRootItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		listContractSubject({
			state
		}) {
			return new Promise((resolve, reject) => {
				listContractSubject(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities.rowFunctionAuthorization = res.entity.rowFunctionAuthorization;
						state.CompoundEntities.entities.entities = res.entity.listContractSubject;
						state.CompoundEntities.entities.total = res.entity.total;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		listContractSubjectInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listContractSubjectInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntitiesDictionary.rowFunctionAuthorization = res.entityResponse.rowFunctionAuthorization;
						state.CompoundEntitiesDictionary.entities = res.entityResponse.listContractSubject;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		deleteContractSubject({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteContractSubject({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		

	}
};
