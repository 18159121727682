import BasicLayout from '@/layouts/basic-layout';
////第一步：配置菜单-->第二步：配置存房委托指派商家页面相关的路由-->第三步：注入路由-->第四步：做页面
const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	name: '指派商家',
	redirect: {
		name: `${pre}houseSaveDelegation-list`
	},
	meta,
	component: BasicLayout,
	children: [{
		path: 'houseSaveDelegation-list',
		name: `${pre}houseSaveDelegation-list`,
		meta: {
			...meta,
			title: '指派商家',
			closable: true
		},
		component: () => import('@/pages/storagemanage/houseSaveDelegation/index')
	}
	]
};
