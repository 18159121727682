/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-04T19:46:29.324
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listHouseRepeatPlanConditionInit(data){return request({
url: '/storagemanage/api/houseRepeatPlanCondition/listHouseRepeatPlanConditionInit',
method: 'post',
data:data,
});
}


export function listHouseRepeatPlanConditionByAuthorizations(data){return request({
url: '/storagemanage/api/houseRepeatPlanCondition/listHouseRepeatPlanConditionByAuthorizations',
method: 'post',
data:data,
});
}


export function listHouseRepeatPlanCondition(data){return request({
url: '/storagemanage/api/houseRepeatPlanCondition/listHouseRepeatPlanCondition',
method: 'post',
data:data,
});
}


export function getHouseRepeatPlanConditionCreateInit(){return request({
url: '/storagemanage/api/houseRepeatPlanCondition/getHouseRepeatPlanConditionCreateInit',
method: 'post',
});
}


export function getHouseRepeatPlanConditionEditInit(params){return request({
url: '/storagemanage/api/houseRepeatPlanCondition/getHouseRepeatPlanConditionEditInit',
method: 'post',
params:params,
});
}


export function getHouseRepeatPlanConditionView(params){return request({
url: '/storagemanage/api/houseRepeatPlanCondition/getHouseRepeatPlanConditionView',
method: 'post',
params:params,
});
}


export function saveHouseRepeatPlanCondition(data){return request({
url: '/storagemanage/api/houseRepeatPlanCondition/saveHouseRepeatPlanCondition',
method: 'post',
data:data,
});
}


export function deleteHouseRepeatPlanCondition(params){return request({
url: '/storagemanage/api/houseRepeatPlanCondition/deleteHouseRepeatPlanCondition',
method: 'post',
params:params,
});
}


