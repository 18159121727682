/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T15:54:15.786
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentChannelApplyItemInit(data){return request({
url: '/rentmanage/api/rentChannelApplyItem/listRentChannelApplyItemInit',
method: 'post',
data:data,
});
}
export function listRentChannelApplyItemSQLInit(data){return request({
	url: '/rentmanage/api/rentChannelApplyItem/listRentChannelApplyItemSQLInit',
	method: 'post',
	data:data,
	});
}
export function listRentChannelApplyItemByAuthorizations(data){return request({
url: '/rentmanage/api/rentChannelApplyItem/listRentChannelApplyItemByAuthorizations',
method: 'post',
data:data,
});
}

export function adminDownloadRentChannelApplyItemTemplate(data){return request({
	url: '/rentmanage/api/rentChannelApplyItem/adminDownloadRentChannelApplyItemTemplate',
	method: 'post',
	data:data, 
	responseType: 'blob'
	});
	}


export function listRentChannelApplyItem(data){return request({
url: '/rentmanage/api/rentChannelApplyItem/listRentChannelApplyItem',
method: 'post',
data:data,
});
}


export function getRentChannelApplyItemCreateInit(){return request({
url: '/rentmanage/api/rentChannelApplyItem/getRentChannelApplyItemCreateInit',
method: 'post',
});
}


export function getRentChannelApplyItemEditInit(params){return request({
url: '/rentmanage/api/rentChannelApplyItem/getRentChannelApplyItemEditInit',
method: 'post',
params:params,
});
}


export function getRentChannelApplyItemView(params){return request({
url: '/rentmanage/api/rentChannelApplyItem/getRentChannelApplyItemView',
method: 'post',
params:params,
});
}


export function saveRentChannelApplyItem(data){return request({
url: '/rentmanage/api/rentChannelApplyItem/saveRentChannelApplyItem',
method: 'post',
data:data,
});
}


export function deleteRentChannelApplyItem(params){return request({
url: '/rentmanage/api/rentChannelApplyItem/deleteRentChannelApplyItem',
method: 'post',
params:params,
});
}

export function toCancelDistribution(params){return request({
	url: '/rentmanage/api/rentChannelApplyItem/toCancelDistribution',
	method: 'post',
	params:params,
	});
	}


export function downloadTemplate(data) {
	return request({
		url: '/rentmanage/api/rentChannelApplyItem/downloadRentChannelApplyItemTemplate',
		method: 'post',
		data: data,
		responseType: 'blob'
	});
}
