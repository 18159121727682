/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-26T09:20:14.984
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHousePutawayItemInit(data){return request({
url: '/rentmanage/api/rentHousePutawayItem/listRentHousePutawayItemInit',
method: 'post',
data:data,
});
}


export function listRentHousePutawayItemByAuthorizations(data){return request({
url: '/rentmanage/api/rentHousePutawayItem/listRentHousePutawayItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHousePutawayItem(data){return request({
url: '/rentmanage/api/rentHousePutawayItem/listRentHousePutawayItem',
method: 'post',
data:data,
});
}


export function getRentHousePutawayItemCreateInit(){return request({
url: '/rentmanage/api/rentHousePutawayItem/getRentHousePutawayItemCreateInit',
method: 'post',
});
}


export function getRentHousePutawayItemEditInit(params){return request({
url: '/rentmanage/api/rentHousePutawayItem/getRentHousePutawayItemEditInit',
method: 'post',
params:params,
});
}


export function getRentHousePutawayItemView(params){return request({
url: '/rentmanage/api/rentHousePutawayItem/getRentHousePutawayItemView',
method: 'post',
params:params,
});
}


export function saveRentHousePutawayItem(data){return request({
url: '/rentmanage/api/rentHousePutawayItem/saveRentHousePutawayItem',
method: 'post',
data:data,
});
}


export function deleteRentHousePutawayItem(params){return request({
url: '/rentmanage/api/rentHousePutawayItem/deleteRentHousePutawayItem',
method: 'post',
params:params,
});
}

export function checkRentHousePutawayHouseRentInfo(params){return request({
	url: '/rentmanage/api/rentHousePutawayItem/checkRentHousePutawayHouseRentInfo',
	method: 'post',
	params:params,
});
}
export function downloadRentHousePutAwayTemplate(data) {
	return request({
		url: '/rentmanage/api/rentHousePutawayItem/downloadRentHousePutAwayTemplate',
		method: 'post',
		data: data,
		responseType: 'blob'
	});
}