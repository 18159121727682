/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-04T20:25:05.166
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveHouseDuplicateRemoval,
	getHouseDuplicateRemovalCreateInit,
	getHouseDuplicateRemovalEditInit,
	getHouseDuplicateRemovalView,
	deleteHouseDuplicateRemoval
} from '@api/storagemanage/HouseDuplicateRemoval';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getHouseDuplicateRemovalCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getHouseDuplicateRemovalCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getHouseDuplicateRemovalEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseDuplicateRemovalEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveHouseDuplicateRemoval({}, HouseDuplicateRemoval) {
			return new Promise((resolve, reject) => {
				saveHouseDuplicateRemoval(HouseDuplicateRemoval)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getHouseDuplicateRemovalView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getHouseDuplicateRemovalView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteHouseDuplicateRemoval({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteHouseDuplicateRemoval({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
