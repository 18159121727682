import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-conattachment-'

export default 
{
path: '/storagemanage/conattachment',
name: 'storagemanage-conattachment',
redirect: {
name: '${pre}-index'
}
,

meta,

component: BasicLayout ,
children:
[

{
path: 'index',
name: '${pre}conattachment-index',

meta:{
...meta,
title: '合同附件表'
},

component: () => import('@/pages/storagemanage/conattachment/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}conattachment-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '合同附件表编辑'
},

component: () => import('@/pages/storagemanage/conattachment/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}conattachmentdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '合同附件表新增'
},

component: () => import('@/pages/storagemanage/conattachment/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}conattachmentView',
auth: [
'hidden'
],

meta:{
...meta,
title: '合同附件表详情查看'
},

component: () => import('@/pages/storagemanage/conattachment/detail-view') ,
},
]

}
;
