/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-26T09:20:14.854
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHousePutawayInit(data) {
	return request({
		url: '/rentmanage/api/rentHousePutaway/listRentHousePutawayInit',
		method: 'post',
		data: data,
	});
}


export function listRentHousePutawayByAuthorizations(data) {
	return request({
		url: '/rentmanage/api/rentHousePutaway/listRentHousePutawayByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listRentHousePutaway(data) {
	return request({
		url: '/rentmanage/api/rentHousePutaway/listRentHousePutaway',
		method: 'post',
		data: data,
	});
}


export function getRentHousePutawayCreateInit() {
	return request({
		url: '/rentmanage/api/rentHousePutaway/getRentHousePutawayCreateInit',
		method: 'post',
	});
}

export function checkRentHousePutawayHouseRentInfo(params) {
	return request({
		url: '/rentmanage/api/rentHousePutawayItem/checkRentHousePutawayHouseRentInfo',
		method: 'post',
		params: params,
	});
}

export function batchSaveRentHousePutawayItem(data) {
	return request({
		url: '/rentmanage/api/rentHousePutawayItem/batchSaveRentHousePutawayItem',
		method: 'post',
		data: data,
	});
}


export function getRentHousePutawayEditInit(params) {
	return request({
		url: '/rentmanage/api/rentHousePutaway/getRentHousePutawayEditInit',
		method: 'post',
		params: params,
	});
}


export function getRentHousePutawayView(params) {
	return request({
		url: '/rentmanage/api/rentHousePutaway/getRentHousePutawayView',
		method: 'post',
		params: params,
	});
}


export function saveRentHousePutaway(data) {
	return request({
		url: '/rentmanage/api/rentHousePutaway/saveRentHousePutaway',
		method: 'post',
		data: data,
	});
}

export function reissueRentHousePutaway(data) {
	return request({
		url: '/rentmanage/api/rentHousePutaway/reissueRentHousePutaway',
		method: 'post',
		data: data,
	});
}


export function deleteRentHousePutaway(params) {
	return request({
		url: '/rentmanage/api/rentHousePutaway/deleteRentHousePutaway',
		method: 'post',
		params: params,
	});
}

export function startWorkflow(data) {
	return request({
		url: '/rentmanage/api/rentHousePutaway/startWorkflow',
		method: 'post',
		data: data,
	});
}
