/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T19:13:41.29
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentConsumeMaterialCompanyInit(data){return request({
url: '/rentmanage/api/rentConsumeMaterialCompany/listRentConsumeMaterialCompanyInit',
method: 'post',
data:data,
});
}


export function listRentConsumeMaterialCompanyByAuthorizations(data){return request({
url: '/rentmanage/api/rentConsumeMaterialCompany/listRentConsumeMaterialCompanyByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentConsumeMaterialCompany(data){return request({
url: '/rentmanage/api/rentConsumeMaterialCompany/listRentConsumeMaterialCompany',
method: 'post',
data:data,
});
}


export function getRentConsumeMaterialCompanyCreateInit(){return request({
url: '/rentmanage/api/rentConsumeMaterialCompany/getRentConsumeMaterialCompanyCreateInit',
method: 'post',
});
}


export function getRentConsumeMaterialCompanyEditInit(params){return request({
url: '/rentmanage/api/rentConsumeMaterialCompany/getRentConsumeMaterialCompanyEditInit',
method: 'post',
params:params,
});
}


export function getRentConsumeMaterialCompanyView(params){return request({
url: '/rentmanage/api/rentConsumeMaterialCompany/getRentConsumeMaterialCompanyView',
method: 'post',
params:params,
});
}


export function saveRentConsumeMaterialCompany(data){return request({
url: '/rentmanage/api/rentConsumeMaterialCompany/saveRentConsumeMaterialCompany',
method: 'post',
data:data,
});
}


export function deleteRentConsumeMaterialCompany(params){return request({
url: '/rentmanage/api/rentConsumeMaterialCompany/deleteRentConsumeMaterialCompany',
method: 'post',
params:params,
});
}


