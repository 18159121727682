/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-05T19:13:41.121
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentRepairFeedbackDetailsInit(data){return request({
url: '/rentmanage/api/rentRepairFeedbackDetails/listRentRepairFeedbackDetailsInit',
method: 'post',
data:data,
});
}

export function listRentRepairFeedbackDetailsInitBySQL(data){return request({
	url: '/rentmanage/api/rentRepairFeedbackDetails/listRentRepairFeedbackDetailsInitBySQL',
	method: 'post',
	data:data,
	});
	}


export function listRentRepairFeedbackDetailsByAuthorizations(data){return request({
url: '/rentmanage/api/rentRepairFeedbackDetails/listRentRepairFeedbackDetailsByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentRepairFeedbackDetails(data){return request({
url: '/rentmanage/api/rentRepairFeedbackDetails/listRentRepairFeedbackDetails',
method: 'post',
data:data,
});
}


export function getRentRepairFeedbackDetailsCreateInit(params){return request({
url: '/rentmanage/api/rentRepairFeedbackDetails/getRentRepairFeedbackDetailsCreateInit',
method: 'post',
params:params,
});
}


export function getRentRepairFeedbackDetailsEditInit(params){return request({
url: '/rentmanage/api/rentRepairFeedbackDetails/getRentRepairFeedbackDetailsEditInit',
method: 'post',
params:params,
});
}


export function getRentRepairFeedbackDetailsView(params){return request({
url: '/rentmanage/api/rentRepairFeedbackDetails/getRentRepairFeedbackDetailsView',
method: 'post',
params:params,
});
}


export function saveRentRepairFeedbackDetails(data){return request({
url: '/rentmanage/api/rentRepairFeedbackDetails/saveRentRepairFeedbackDetails',
method: 'post',
data:data,
});
}


export function deleteRentRepairFeedbackDetails(params){return request({
url: '/rentmanage/api/rentRepairFeedbackDetails/deleteRentRepairFeedbackDetails',
method: 'post',
params:params,
});
}


export function getRentRepairFeedbackDetailsByOrderAndAssign(data){return request({
	url: '/rentmanage/api/rentRepairFeedbackDetails/getRentRepairFeedbackDetailsByOrderAndAssign',
	method: 'post',
	data:data,
	});
	}

export function startWorkflow(data){return request({
	url: '/rentmanage/api/rentRepairFeedbackDetails/startWorkflow',
	method: 'post',
	data:data,
	});
	}