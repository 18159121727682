/**
 * 注册、登录、注销
 * */
import util from '@/libs/util';
import router from '@/router';
import {
	getHousePassWord,
} from '@api/mobile/smartLocks';
import {
	formatTime
} from '@/plugins/utils';
import {
	Message,
	Notice
} from 'view-design';

export default {
	namespaced: true,
	state: {
		pageInitParams: {
			"tenantId": ""
		},

	},
	actions: {
        getHousePassWord({
        	dispatch,
        	state,
        },{
			billId
		}) {
        	return new Promise((resolve, reject) => {
        		getHousePassWord({
        			billId
        		})
        			.then(async res => {
        				// state.listTreeDataObj = res
        				resolve(res);
        			})
        			.catch(err => {
        				// console.log('err: ', err);
        				reject(err);
        			})
        	})
        },
        
    },
    
	mutations: {
        
    }
};
