/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-22T15:12:16.177
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentPlaceOrderRetreatInit(data){return request({
url: '/rentmanage/api/rentPlaceOrderRetreat/listRentPlaceOrderRetreatInit',
method: 'post',
data:data,
});
}


export function listRentPlaceOrderRetreatByAuthorizations(data){return request({
url: '/rentmanage/api/rentPlaceOrderRetreat/listRentPlaceOrderRetreatByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentPlaceOrderRetreat(data){return request({
url: '/rentmanage/api/rentPlaceOrderRetreat/listRentPlaceOrderRetreat',
method: 'post',
data:data,
});
}


export function getRentPlaceOrderRetreatCreateInit(){return request({
url: '/rentmanage/api/rentPlaceOrderRetreat/getRentPlaceOrderRetreatCreateInit',
method: 'post',
});
}


export function getRentPlaceOrderRetreatEditInit(params){return request({
url: '/rentmanage/api/rentPlaceOrderRetreat/getRentPlaceOrderRetreatEditInit',
method: 'post',
params:params,
});
}


export function getRentPlaceOrderRetreatView(params){return request({
url: '/rentmanage/api/rentPlaceOrderRetreat/getRentPlaceOrderRetreatView',
method: 'post',
params:params,
});
}


export function saveRentPlaceOrderRetreat(data){return request({
url: '/rentmanage/api/rentPlaceOrderRetreat/saveRentPlaceOrderRetreat',
method: 'post',
data:data,
});
}


export function deleteRentPlaceOrderRetreat(params){return request({
url: '/rentmanage/api/rentPlaceOrderRetreat/deleteRentPlaceOrderRetreat',
method: 'post',
params:params,
});
}


export function startWorkflow(data){return request({
	url: '/rentmanage/api/rentPlaceOrderRetreat/startWorkflow',
	method: 'post',
	data:data,
	});
	}
