/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-26T09:20:14.992
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentHousePutawayItem,
	getRentHousePutawayItemCreateInit,
	getRentHousePutawayItemEditInit,
	getRentHousePutawayItemView,
	deleteRentHousePutawayItem,
	checkRentHousePutawayHouseRentInfo
} from '@api/rentmanage/RentHousePutawayItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentHousePutawayItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHousePutawayItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHousePutawayItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHousePutawayItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHousePutawayItem({}, RentHousePutawayItem) {
			return new Promise((resolve, reject) => {
				saveRentHousePutawayItem(RentHousePutawayItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		checkRentHousePutawayHouseRentInfo({
			state
		}, obj) {
			return new Promise((resolve, reject) => {
				checkRentHousePutawayHouseRentInfo({
					houseRentId:obj.houseRentId,
					putawayChannels:obj.putawayChannels
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentHousePutawayItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHousePutawayItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHousePutawayItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHousePutawayItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
