/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-12T10:12:57.971
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportfinancialtransfer-'

export default 
{
path: '/report/reportfinancialtransfer',
name: 'report-reportfinancialtransfer',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportfinancialtransfer-index',

meta:{
...meta,
title: '银行流水认领情况'
},

component: () => import('@/pages/report/reportfinancialtransfer/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportfinancialtransfer-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '银行流水认领情况编辑'
},

component: () => import('@/pages/report/reportfinancialtransfer/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportfinancialtransferdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '银行流水认领情况新增'
},

component: () => import('@/pages/report/reportfinancialtransfer/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportfinancialtransferView',
auth: [
'hidden'
],

meta:{
...meta,
title: '银行流水认领情况详情查看'
},

component: () => import('@/pages/report/reportfinancialtransfer/detail-view') ,
},
]
};
