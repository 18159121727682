/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-07-02T16:29:31.194
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'report-reportfinancialrentcontractdetailcollectionmonth-'

export default 
{
path: '/report/reportfinancialrentcontractdetailcollectionmonth',
name: 'report-reportfinancialrentcontractdetailcollectionmonth',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}reportfinancialrentcontractdetailcollectionmonth-index',

meta:{
...meta,
title: '实收报表（月）'
},

component: () => import('@/pages/report/reportfinancialrentcontractdetailcollectionmonth/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}reportfinancialrentcontractdetailcollectionmonth-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '实收报表（月）编辑'
},

component: () => import('@/pages/report/reportfinancialrentcontractdetailcollectionmonth/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}reportfinancialrentcontractdetailcollectionmonthdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '实收报表（月）新增'
},

component: () => import('@/pages/report/reportfinancialrentcontractdetailcollectionmonth/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}reportfinancialrentcontractdetailcollectionmonthView',
auth: [
'hidden'
],

meta:{
...meta,
title: '实收报表（月）详情查看'
},

component: () => import('@/pages/report/reportfinancialrentcontractdetailcollectionmonth/detail-view') ,
},
]
};
