import request from '@/plugins/request';

export function TreeDataInit(data) {
	return request({
		url: '/datadictionarymanage/api/configItemManage/queryConfigItemManageListInit',
		method: 'post',
		data: data
	});
}

export function GetTreeDataList(data) {
	return request({
		url: '/datadictionarymanage/api/configItemManage/queryConfigItemManageList',
		method: 'post',
		data: data
	});
}

export function GetDataList(data) {
	return request({
		url: '/datadictionarymanage/api/configItemManage/listConfigItemManage',
		method: 'post',
		data: data
	});
}

export function AddOrEditData(data) {
	return request({
		url: '/datadictionarymanage/api/configItemManage/saveConfigItemManage',
		method: 'post',
		data: data
	});
}

export function addPageInitReq(data) {
	return request({
		url: '/datadictionarymanage/api/configItemManage/getConfigItemManageCreateInit',
		method: 'post',
		params: data
	});
}

export function editPageInitReq(data) {
	return request({
		url: '/datadictionarymanage/api/configItemManage/getConfigItemManageEditInit',
		method: 'post',
		params: data
	});
}

export function getEditDataReq(data) {
	return request({
		url: '/datadictionarymanage/api/configItemManage/listConfigItemManage',
		method: 'post',
		data: data
	});
}


export function deteleRecordReq(data) {
	return request({
		url: '/datadictionarymanage/api/configItemManage/deleteConfigItemManage',
		method: 'post',
		params: data
	});
}

export function deteleTreeRecordReq(data) {
	return request({
		url: '/datadictionarymanage/api/configItemManage/deleteConfigItemManageByGroupType',
		method: 'post',
		params: data
	});
}
