import request from '@/plugins/request';

export function listEvaluateBillRemouldInit(data){return request({
url: '/storagemanage/api/evaluateBillRemould/listEvaluateBillRemouldInit',
method: 'post',
data:data,
});
}


export function listEvaluateBillRemouldByAuthorizations(data){return request({
url: '/storagemanage/api/evaluateBillRemould/listEvaluateBillRemouldByAuthorizations',
method: 'post',
data:data,
});
}


export function listEvaluateBillRemould(data){return request({
url: '/storagemanage/api/evaluateBillRemould/listEvaluateBillRemould',
method: 'post',
data:data,
});
}


export function getEvaluateBillRemouldCreateInit(){return request({
url: '/storagemanage/api/evaluateBillRemould/getEvaluateBillRemouldCreateInit',
method: 'post',
});
}


export function getEvaluateBillRemouldEditInit(params){return request({
url: '/storagemanage/api/evaluateBillRemould/getEvaluateBillRemouldEditInit',
method: 'post',
params:params,
});
}


export function getEvaluateBillRemouldView(params){return request({
url: '/storagemanage/api/evaluateBillRemould/getEvaluateBillRemouldView',
method: 'post',
params:params,
});
}


export function saveEvaluateBillRemould(data){return request({
url: '/storagemanage/api/evaluateBillRemould/saveEvaluateBillRemould',
method: 'post',
data:data,
});
}


export function deleteEvaluateBillRemould(params){return request({
url: '/storagemanage/api/evaluateBillRemould/deleteEvaluateBillRemould',
method: 'post',
params:params,
});
}


export function downloadRemouldTemplate(data) {
	return request({
		url: '/storagemanage/api/evaluateBillRemould/downloadRemouldTemplate',
		method: 'post',
		data: data,
		responseType: 'blob'
	});
}
