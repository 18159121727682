/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-04T19:46:29.221
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listHouseRepeatPlan,
	listHouseRepeatPlanInit,
	listHouseRepeatPlanByAuthorizations,
	deleteHouseRepeatPlan
	
} from '@api/storagemanage/HouseRepeatPlan';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listHouseRepeatPlan({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHouseRepeatPlan(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listHouseRepeatPlanByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHouseRepeatPlanByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listHouseRepeatPlanInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listHouseRepeatPlanInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteHouseRepeatPlan({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteHouseRepeatPlan({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

	}
};
