/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-16T10:11:30.186
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentBusinessArea,
	getRentBusinessAreaCreateInit,
	getRentBusinessAreaEditInit,
	getRentBusinessAreaView,
	deleteRentBusinessArea
} from '@api/rentmanage/RentBusinessArea';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentBusinessAreaCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentBusinessAreaCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentBusinessAreaEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentBusinessAreaEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentBusinessArea({}, RentBusinessArea) {
			return new Promise((resolve, reject) => {
				saveRentBusinessArea(RentBusinessArea)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentBusinessAreaView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentBusinessAreaView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentBusinessArea({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentBusinessArea({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
