/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-12-30T17:33:20.305
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'report-reportcardusecount-'

export default {
	path: '/report/reportcardusecount',
	name: 'report-reportcardusecount',
	redirect: {
		name: '${pre}-index'
	},

	meta: {
		...meta,
	},

	component: BasicLayout,
	children: [{
			path: 'index',
			name: '${pre}reportcardusecount-index',

			meta: {
				...meta,
				title: '卡券报表'
			},

			component: () => import('@/pages/report/reportcardusecount/index'),
		},
		{
			path: 'detail-edit/:id',
			name: '${pre}reportcardusecount-detail-edit',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '卡券报表编辑'
			},

			component: () => import('@/pages/report/reportcardusecount/detail-edit'),
		},
		{
			path: 'detail-edit',
			name: '${pre}reportcardusecountdetail-create',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '卡券报表新增'
			},

			component: () => import('@/pages/report/reportcardusecount/detail-edit'),
		},
		{
			path: 'detail-view/:id',
			name: '${pre}reportcardusecountView',
			auth: [
				'hidden'
			],

			meta: {
				...meta,
				title: '卡券报表详情查看'
			},

			component: () => import('@/pages/report/reportcardusecount/detail-view'),
		},
	]
};
