/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-14T19:43:31.616
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentTrafficLinePlatform,
	getRentTrafficLinePlatformCreateInit,
	getRentTrafficLinePlatformEditInit,
	getRentTrafficLinePlatformView,
	deleteRentTrafficLinePlatform
} from '@api/rentmanage/RentTrafficLinePlatform';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentTrafficLinePlatformCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentTrafficLinePlatformCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentTrafficLinePlatformEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentTrafficLinePlatformEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentTrafficLinePlatform({}, RentTrafficLinePlatform) {
			return new Promise((resolve, reject) => {
				saveRentTrafficLinePlatform(RentTrafficLinePlatform)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentTrafficLinePlatformView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentTrafficLinePlatformView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentTrafficLinePlatform({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentTrafficLinePlatform({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
