/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-20T19:56:11.03
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentHouseAirCheckItem,
	getRentHouseAirCheckItemCreateInit,
	getRentHouseAirCheckItemEditInit,
	getRentHouseAirCheckItemView,
	deleteRentHouseAirCheckItem
} from '@api/rentmanage/RentHouseAirCheckItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentHouseAirCheckItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseAirCheckItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseAirCheckItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseAirCheckItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseAirCheckItem({}, RentHouseAirCheckItem) {
			return new Promise((resolve, reject) => {
				saveRentHouseAirCheckItem(RentHouseAirCheckItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseAirCheckItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseAirCheckItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseAirCheckItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseAirCheckItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
