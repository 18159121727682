/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:58.309
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	getVacancyPeriodInfoEditInit,
	getVacancyPeriodInfoView
} from '@api/lcf/VacancyPeriodInfo';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},

	actions: {
		getVacancyPeriodInfoEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getVacancyPeriodInfoEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getVacancyPeriodInfoView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getVacancyPeriodInfoView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
