/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-12-01T19:13:36.197
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listConDeputeContractBackSettlementInit(data) {
	return request({
		url: '/storagemanage/api/conDeputeContractBackSettlement/listConDeputeContractBackSettlementInit',
		method: 'post',
		data: data,
	});
}


export function listConDeputeContractBackSettlementByAuthorizations(data) {
	return request({
		url: '/storagemanage/api/conDeputeContractBackSettlement/listConDeputeContractBackSettlementByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listConDeputeContractBackSettlement(data) {
	return request({
		url: '/storagemanage/api/conDeputeContractBackSettlement/listConDeputeContractBackSettlement',
		method: 'post',
		data: data,
	});
}


export function getConDeputeContractBackSettlementCreateInit(data) {
	return request({
		url: '/storagemanage/api/conDeputeContractBackSettlement/getConDeputeContractBackSettlementCreateInit',
		method: 'post',
		data: data,
	});
}


export function getConDeputeContractBackSettlementEditInit(params) {
	return request({
		url: '/storagemanage/api/conDeputeContractBackSettlement/getConDeputeContractBackSettlementEditInit',
		method: 'post',
		params: params,
	});
}


export function getConDeputeContractBackSettlementView(params) {
	return request({
		url: '/storagemanage/api/conDeputeContractBackSettlement/getConDeputeContractBackSettlementView',
		method: 'post',
		params: params,
	});
}


export function saveConDeputeContractBackSettlement(data) {
	return request({
		url: '/storagemanage/api/conDeputeContractBackSettlement/saveConDeputeContractBackSettlement',
		method: 'post',
		data: data,
	});
}


export function deleteConDeputeContractBackSettlement(params) {
	return request({
		url: '/storagemanage/api/conDeputeContractBackSettlement/deleteConDeputeContractBackSettlement',
		method: 'post',
		params: params,
	});
}

export function sendReplyByLetter(params) {
	return request({
		url: '/storagemanage/api/conDeputeContractBackSettlement/sendReplyByLetter',
		method: 'post',
		params: params,
	});
}

export function sendReleaseAgreement(params) {
	return request({
		url: '/storagemanage/api/conDeputeContractBackSettlement/sendReleaseAgreement',
		method: 'post',
		params: params,
	});
}

export function confirmDelivery(params) {
	return request({
		url: '/storagemanage/api/conDeputeContractBackSettlement/confirmDelivery',
		method: 'post',
		params: params,
	});
}