<template>
  <Form
    ref="form"
    :label-width="labelWidth"
    :model="storePage.AdvanceQuery"
    :rules="rules"
    :labelPosition="labelPosition"
  >

    <Row
      :gutter="24"
      type="flex"
      justify="start"
    >

      <Col
        :xl="8"
        :lg="12"
        :md="12"
        :sm="24"
        :xs="24"
      >

        <FormItem
          label="用户类型: "
          prop="userType"
          label-for="userType"
        >

          <Select
            v-model="storePage.AdvanceQuery.entity.userType"
            placeholder="请选择用户类型"
            disabled
            element-id="userType"
          >

            <Option
              :value="item.itemCode"
              v-for="item in getConfigItem('user_type')"
            >
              {{ item.itemName }}
            </Option>

          </Select>

        </FormItem>

      </Col>

      <Col
        :xl="8"
        :lg="12"
        :md="12"
        :sm="24"
        :xs="24"
      >

        <FormItem
          label="用户名字: "
          prop="userName"
          label-for="userName"
        >

          <Input
            type="text"
            v-model="storePage.AdvanceQuery.entity.userName"
            placeholder="请输入用户名字"
            element-id="userName"
          >
          </Input>

        </FormItem>

      </Col>

      <template v-if="collapse">

        <Col
          :xl="8"
          :lg="12"
          :md="12"
          :sm="24"
          :xs="24"
        >

          <FormItem
            label="用户帐户: "
            prop="userCode"
            label-for="userCode"
          >

            <Input
              type="text"
              v-model="storePage.AdvanceQuery.entity.userCode"
              placeholder="请输入用户帐户"
              element-id="userCode"
            >
            </Input>

          </FormItem>

        </Col>

        <Col
          :xl="8"
          :lg="12"
          :md="12"
          :sm="24"
          :xs="24"
        >

          <FormItem
            label="手机号码: "
            prop="userMobile"
            label-for="userMobile"
          >

            <Input
              type="text"
              v-model="storePage.AdvanceQuery.entity.userMobile"
              placeholder="请输入手机号码"
              element-id="userMobile"
            >
            </Input>

          </FormItem>

        </Col>

      </template>

      <Col
        v-bind="grid"
        class="ivu-text-right"
      >
        <FormItem>
          <Button
            type="primary"
            @click="handleSubmit"
          >查询
          </Button>
          <Button
            class="ivu-ml-8"
            @click="handleReset"
          >重置
          </Button>
          <a
            v-font="14"
            class="ivu-ml-16"
            @click="collapse = !collapse"
          >
            <template v-if="!collapse">
              展开
              <Icon type="ios-arrow-down"/>
            </template>
            <template v-else>
              收起
              <Icon type="ios-arrow-up"/>
            </template>
          </a>
        </FormItem>
      </Col>

    </Row>

  </Form>
</template>
<script>
  import { mapState } from 'vuex'

  export default {
    watch: {
      isMobile: {
        handler (value) {
          if (value) this.collapse = false
        },
        immediate: true,
      },
    },
    computed: {
      ...mapState('admin/layout', ['isMobile']),

      storePage () {
        return this.$store.state.systemmanage['orgusermanage/index']
      },

      labelWidth () {
        return this.isMobile ? undefined : 130
      },

      labelPosition () {
        return this.isMobile ? 'top' : 'right'
      }
    },
    methods: {
      handleSubmit () {
        this.storePage.AdvanceQuery.pageIndex = 1
        this.transferDatetime(this.storePage.AdvanceQuery)
        this.$emit('on-submit', this.data)
      },

      handleReset () {
        this.$store.state.systemmanage[
          'orgusermanage/index'
          ].AdvanceQuery.entity = {}
        this.$store.state.systemmanage[
          'orgusermanage/index'
          ].AdvanceQuery.outerWhereSubjoin.daterange = ''
        this.$store.state.systemmanage[
          'orgusermanage/index'
          ].AdvanceQuery.outerWhereSubjoin.startDate = ''
        this.$store.state.systemmanage[
          'orgusermanage/index'
          ].AdvanceQuery.outerWhereSubjoin.endDate = ''
        this.$store.state.systemmanage[
          'orgusermanage/index'
          ].AdvanceQuery.outerWhereSubjoin.fuzzyMatching = ''
        this.$refs.form.resetFields()
        this.$emit('on-reset')
      },

      getConfigItem (key) {
        this.$nextTick(() => {
          this.storePage.AdvanceQuery.entity.userType = '2'
        })
        return this.$store.state.systemmanage['orgusermanage/index']
          .CompoundEntitiesDictionary.configItems[key]
      },

      transferDatetime (object) {
        if (
          object.outerWhereSubjoin.daterange[0] != '' &&
          object.outerWhereSubjoin.daterange[0] != null
        ) {
          object.outerWhereSubjoin.startDate = this.$Date(
            object.outerWhereSubjoin.daterange[0]
          ).format('YYYY-MM-DD 00:00:00')
          object.outerWhereSubjoin.endDate = this.$Date(
            object.outerWhereSubjoin.daterange[1]
          ).format('YYYY-MM-DD 23:59:59')
        } else {
          object.outerWhereSubjoin.startDate = ''
          object.outerWhereSubjoin.endDate = ''
        }
      }
    },
    mounted () {
      this.getConfigItem('user_type')
    },
    data () {
      return {
        grid: {
          xl: 8,
          lg: 8,
          md: 12,
          sm: 24,
          xs: 24
        },
        collapse: true,
        data: {},
        rules: {}
      }
    },
  }
</script>
