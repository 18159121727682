/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-12T11:12:57.648
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveChannel,
	getChannelCreateInit,
	getChannelEditInit,
	getChannelView,
	deleteChannel
} from '@api/rentmanage/Channel';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getChannelCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getChannelCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getChannelEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getChannelEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveChannel({}, Channel) {
			return new Promise((resolve, reject) => {
				saveChannel(Channel)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getChannelView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getChannelView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteChannel({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteChannel({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
