/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-10T15:01:42.841
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentHouseOnlineItemInit(data){return request({
url: '/rentmanage/api/rentHouseOnlineItem/listRentHouseOnlineItemInit',
method: 'post',
data:data,
});
}


export function listRentHouseOnlineItemByAuthorizations(data){return request({
url: '/rentmanage/api/rentHouseOnlineItem/listRentHouseOnlineItemByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentHouseOnlineItem(data){return request({
url: '/rentmanage/api/rentHouseOnlineItem/listRentHouseOnlineItem',
method: 'post',
data:data,
});
}


export function getRentHouseOnlineItemCreateInit(){return request({
url: '/rentmanage/api/rentHouseOnlineItem/getRentHouseOnlineItemCreateInit',
method: 'post',
});
}


export function getRentHouseOnlineItemEditInit(params){return request({
url: '/rentmanage/api/rentHouseOnlineItem/getRentHouseOnlineItemEditInit',
method: 'post',
params:params,
});
}


export function getRentHouseOnlineItemView(params){return request({
url: '/rentmanage/api/rentHouseOnlineItem/getRentHouseOnlineItemView',
method: 'post',
params:params,
});
}


export function saveRentHouseOnlineItem(data){return request({
url: '/rentmanage/api/rentHouseOnlineItem/saveRentHouseOnlineItem',
method: 'post',
data:data,
});
}


export function deleteRentHouseOnlineItem(params){return request({
url: '/rentmanage/api/rentHouseOnlineItem/deleteRentHouseOnlineItem',
method: 'post',
params:params,
});
}


export function downloadRentHouseOnlineItemTemplate(data) {
	return request({
		url: '/rentmanage/api/rentHouseOnlineItem/downloadRentHouseOnlineItemTemplate',
		method: 'post',
		data: data,
		responseType: 'blob'
	});
}