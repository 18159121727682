/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-07-05T16:23:43.373
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listRentPropertyDeliveryItemInit(data) {
    return request({
        url: '/rentmanage/api/rentPropertyDeliveryItem/listRentPropertyDeliveryItemInit',
        method: 'post',
        data: data,
    });
}


export function listRentPropertyDeliveryItemByAuthorizations(data) {
    return request({
        url: '/rentmanage/api/rentPropertyDeliveryItem/listRentPropertyDeliveryItemByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listRentPropertyDeliveryItem(data) {
    return request({
        url: '/rentmanage/api/rentPropertyDeliveryItem/listRentPropertyDeliveryItem',
        method: 'post',
        data: data,
    });
}


export function getRentPropertyDeliveryItemCreateInit() {
    return request({
        url: '/rentmanage/api/rentPropertyDeliveryItem/getRentPropertyDeliveryItemCreateInit',
        method: 'post',
    });
}


export function getRentPropertyDeliveryItemEditInit(params) {
    return request({
        url: '/rentmanage/api/rentPropertyDeliveryItem/getRentPropertyDeliveryItemEditInit',
        method: 'post',
        params: params,
    });
}


export function getRentPropertyDeliveryItemView(params) {
    return request({
        url: '/rentmanage/api/rentPropertyDeliveryItem/getRentPropertyDeliveryItemView',
        method: 'post',
        params: params,
    });
}


export function saveRentPropertyDeliveryItem(data) {
    return request({
        url: '/rentmanage/api/rentPropertyDeliveryItem/saveRentPropertyDeliveryItem',
        method: 'post',
        data: data,
    });
}


export function deleteRentPropertyDeliveryItem(params) {
    return request({
        url: '/rentmanage/api/rentPropertyDeliveryItem/deleteRentPropertyDeliveryItem',
        method: 'post',
        params: params,
    });
}

export function downloadTemplate(data) {
	return request({
		url: '/rentmanage/api/rentPropertyDeliveryItem/downloadTemplate',
		method: 'post',
		data: data,
		responseType: 'blob'
	});
}

export function saveRentPropertyDeliveryItemList(data) {
    return request({
        url: '/rentmanage/api/rentPropertyDeliveryItem/saveRentPropertyDeliveryItemList',
        method: 'post',
        data: data,
    });
}


export function downloadRentTemplate(data) {
	return request({
		url: '/rentmanage/api/rentPropertyDeliveryItem/downloadRentTemplate',
		method: 'post',
		data: data,
		responseType: 'blob'
	});
}

