/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-04-25T22:13:39.984
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listHourseEntrustInit(data){return request({
url: '/storagemanage/api/hourseEntrust/listHourseEntrustInit',
method: 'post',
data:data,
});
}


export function listHourseEntrustByAuthorizations(data){return request({
url: '/storagemanage/api/hourseEntrust/listHourseEntrustByAuthorizations',
method: 'post',
data:data,
});
}


export function listHourseEntrust(data){return request({
url: '/storagemanage/api/hourseEntrust/listHourseEntrust',
method: 'post',
data:data,
});
}


export function getHourseEntrustCreateInit(){return request({
url: '/storagemanage/api/hourseEntrust/getHourseEntrustCreateInit',
method: 'post',
});
}


export function getHourseEntrustEditInit(params){return request({
url: '/storagemanage/api/hourseEntrust/getHourseEntrustEditInit',
method: 'post',
params:params,
});
}


export function getHourseEntrustView(params){return request({
url: '/storagemanage/api/hourseEntrust/getHourseEntrustView',
method: 'post',
params:params,
});
}


export function saveHourseEntrust(data){return request({
url: '/storagemanage/api/hourseEntrust/saveHourseEntrust',
method: 'post',
data:data,
});
}


export function deleteHourseEntrust(params){return request({
url: '/storagemanage/api/hourseEntrust/deleteHourseEntrust',
method: 'post',
params:params,
});
}


