/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-16T15:24:16.783
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listRentTakeLookRecordInit(data) {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/listRentTakeLookRecordInit',
		method: 'post',
		data: data,
	});
}

export function listRentTakeLookRecordStatistics(data) {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/listRentTakeLookRecordStatistics',
		method: 'post',
		data: data,
	});
}

export function listRentTakeLookRecordByAuthorizations(data) {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/listRentTakeLookRecordByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listRentTakeLookRecord(data) {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/listRentTakeLookRecord',
		method: 'post',
		data: data,
	});
}


export function getRentTakeLookRecordCreateInit() {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/getRentTakeLookRecordCreateInit',
		method: 'post',
	});
}


export function getRentTakeLookRecordEditInit(params) {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/getRentTakeLookRecordEditInit',
		method: 'post',
		params: params,
	});
}


export function getRentTakeLookRecordView(params) {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/getRentTakeLookRecordView',
		method: 'post',
		params: params,
	});
}


export function saveRentTakeLookRecord(data) {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/saveRentTakeLookRecord',
		method: 'post',
		data: data,
	});
}


export function deleteRentTakeLookRecord(params) {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/deleteRentTakeLookRecord',
		method: 'post',
		params: params,
	});
}

export function cancelRentTakeLookRecord(params) {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/cancelRentTakeLookRecord',
		method: 'post',
		params: params,
	});
}

export function startWorkflow(data) {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/startWorkflow',
		method: 'post',
		data: data,
	});
}

export function sendTempPwd(data) {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/addTempPwd',
		method: 'post',
		data: data,
	});
}

export function getCustomerCDKEY(data) {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/getCustomerCDKEY',
		method: 'post',
		data: data,
	});
}

export function getCycleKEY(params) {
	return request({
		url: '/rentmanage/api/rentTakeLookRecord/getCyclePassword',
		method: 'get',
		params: params,
	});
}

export function toAcceptTakeLookRecord(params) {
    return request({
        url: '/rentmanage/api/rentTakeLookRecord/toAcceptTakeLookRecord',
        method: 'get',
        params: params,
    });
}

