/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-10T12:05:16.885
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listFinancePayApplyItemInit(data) {
	return request({
		url: '/storagemanage/api/financePayApplyItem/listFinancePayApplyItemInit',
		method: 'post',
		data: data,
	});
}


export function listFinancePayApplyItemByAuthorizations(data) {
	return request({
		url: '/storagemanage/api/financePayApplyItem/listFinancePayApplyItemByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listFinancePayApplyItem(data) {
	return request({
		url: '/storagemanage/api/financePayApplyItem/listFinancePayApplyItem',
		method: 'post',
		data: data,
	});
}


export function getFinancePayApplyItemCreateInit() {
	return request({
		url: '/storagemanage/api/financePayApplyItem/getFinancePayApplyItemCreateInit',
		method: 'post',
	});
}


export function getFinancePayApplyItemEditInit(params) {
	return request({
		url: '/storagemanage/api/financePayApplyItem/getFinancePayApplyItemEditInit',
		method: 'post',
		params: params,
	});
}


export function getFinancePayApplyItemView(params) {
	return request({
		url: '/storagemanage/api/financePayApplyItem/getFinancePayApplyItemView',
		method: 'post',
		params: params,
	});
}


export function saveFinancePayApplyItem(data) {
	return request({
		url: '/storagemanage/api/financePayApplyItem/saveFinancePayApplyItem',
		method: 'post',
		data: data,
	});
}


export function deleteFinancePayApplyItem(params) {
	return request({
		url: '/storagemanage/api/financePayApplyItem/deleteFinancePayApplyItem',
		method: 'post',
		params: params,
	});
}

export function downloadPayApplyItemExcel(params) {
	return request({
		url: '/storagemanage/api/financePayApplyItem/downloadPayApplyItemExcel',
		method: 'post',
		params: params,
		responseType: 'blob'
	});
}

export function updateFinancePayApplyItem(data) {
	return request({
		url: '/storagemanage/api/financePayApplyItem/updateFinancePayApplyItem',
		method: 'post',
		data: data,
	});
}

export function downloadPrintPayApplyItemExcel(params) {
	return request({
		url: '/storagemanage/api/financePayApplyItem/downloadPrintPayApplyItemExcel',
		method: 'post',
		params: params,
		responseType: 'blob'
	});
}
