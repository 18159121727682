import request from '@/plugins/request';

export function listEvaluateBillHouseInit(data){return request({
url: '/storagemanage/api/evaluateBillHouse/listEvaluateBillHouseInit',
method: 'post',
data:data,
});
}


export function listEvaluateBillHouseByAuthorizations(data){return request({
url: '/storagemanage/api/evaluateBillHouse/listEvaluateBillHouseByAuthorizations',
method: 'post',
data:data,
});
}


export function listEvaluateBillHouse(data){return request({
url: '/storagemanage/api/evaluateBillHouse/listEvaluateBillHouse',
method: 'post',
data:data,
});
}


export function getEvaluateBillHouseCreateInit(){return request({
url: '/storagemanage/api/evaluateBillHouse/getEvaluateBillHouseCreateInit',
method: 'post',
});
}


export function getEvaluateBillHouseEditInit(params){return request({
url: '/storagemanage/api/evaluateBillHouse/getEvaluateBillHouseEditInit',
method: 'post',
params:params,
});
}


export function getEvaluateBillHouseView(params){return request({
url: '/storagemanage/api/evaluateBillHouse/getEvaluateBillHouseView',
method: 'post',
params:params,
});
}


export function saveEvaluateBillHouse(data){return request({
url: '/storagemanage/api/evaluateBillHouse/saveEvaluateBillHouse',
method: 'post',
data:data,
});
}


export function deleteEvaluateBillHouse(params){return request({
url: '/storagemanage/api/evaluateBillHouse/deleteEvaluateBillHouse',
method: 'post',
params:params,
});
}


