/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T17:27:30.233
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-RentPurchaseOrder-'

export default 
{
path: '/rentmanage/rentpurchaseorder',
name: 'rentmanage-RentPurchaseOrder',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}RentPurchaseOrder-index',

meta:{
...meta,
title: '进货管理'
},

component: () => import('@/pages/rentmanage/rentpurchaseorder/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}RentPurchaseOrder-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '进货管理编辑'
},

component: () => import('@/pages/rentmanage/rentpurchaseorder/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}RentPurchaseOrderdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '进货管理新增'
},

component: () => import('@/pages/rentmanage/rentpurchaseorder/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}RentPurchaseOrderView',
auth: [
'hidden'
],

meta:{
...meta,
title: '进货管理详情查看'
},

component: () => import('@/pages/rentmanage/rentpurchaseorder/detail-view') ,
},
]
};
