/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-09T14:42:50.598
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentHouseSalesPriceItem,
	getRentHouseSalesPriceItemCreateInit,
	getRentHouseSalesPriceItemEditInit,
	getRentHouseSalesPriceItemView,
	deleteRentHouseSalesPriceItem,
	priceCalculationCostByHouseId
} from '@api/rentmanage/RentHouseSalesPriceItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentHouseSalesPriceItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseSalesPriceItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		priceCalculationCostByHouseId({
			state
		}, {
			houseId
		}) {
			return new Promise((resolve, reject) => {
				priceCalculationCostByHouseId({
					houseId
					})
					.then(async res => { 
						resolve(res.entity);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		
		getRentHouseSalesPriceItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseSalesPriceItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseSalesPriceItem({}, RentHouseSalesPriceItem) {
			return new Promise((resolve, reject) => {
				saveRentHouseSalesPriceItem(RentHouseSalesPriceItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseSalesPriceItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseSalesPriceItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseSalesPriceItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseSalesPriceItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
