/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-05-24T14:53:19.019
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentHouseReform,
	getRentHouseReformCreateInit,
	getRentHouseReformEditInit,
	getRentHouseReformView,
	deleteRentHouseReform
} from '@api/rentmanage/RentHouseReform';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
listRentHouseReformItemInit,
listRentHouseReformItemByAuthorizations,
deleteRentHouseReformItem
} from '@api/rentmanage/RentHouseReformItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentHouseReformCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseReformCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseReformEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseReformEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentHouseReform({}, RentHouseReform) {
			return new Promise((resolve, reject) => {
				saveRentHouseReform(RentHouseReform)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentHouseReformView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentHouseReformView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentHouseReform({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentHouseReform({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
