/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-05-17T10:46:25.402
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: true
};

const pre = 'storagemanage-evaluateappointmentkeeprecord-'

export default {
    path: '/storagemanage/evaluateappointmentkeeprecord',
    name: 'storagemanage-evaluateappointmentkeeprecord',
    redirect: {
        name: '${pre}-index'
    },

    meta: {
        ...meta,
    },

    component: BasicLayout,
    children: [{
        path: 'table-index',
        name: `${pre}table-index`,

        meta: {
            ...meta,
            title: '预约备案',
            cache: true
        },

        component: () => import('@/pages/storagemanage/evaluateappointmentkeeprecord/table-index'),
    }, {
        path: 'index',
        name: '${pre}evaluateappointmentkeeprecord-index',

        meta: {
            ...meta,
            title: '预约备案查看'
        },

        component: () => import('@/pages/storagemanage/evaluateappointmentkeeprecord/index'),
    },
        {
            path: 'detail-edit/:id',
            name: '${pre}evaluateappointmentkeeprecord-detail-edit',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '预约备案编辑'
            },

            component: () => import('@/pages/storagemanage/evaluateappointmentkeeprecord/detail-edit'),
        },
        {
            path: 'detail-edit',
            name: '${pre}evaluateappointmentkeeprecorddetail-create',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '预约备案编辑',
                cache: true,
            },

            component: () => import('@/pages/storagemanage/evaluateappointmentkeeprecord/detail-edit'),
        },
        {
            path: 'detail-view/:id',
            name: '${pre}evaluateappointmentkeeprecordView',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '预约备案详情查看'
            },

            component: () => import('@/pages/storagemanage/evaluateappointmentkeeprecord/detail-view'),
        },
    ]
};
