/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-04-28T10:23:25.496
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'storagemanage-Building-'

export default 
{
path: '/storagemanage/building',
name: 'storagemanage-Building',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}Building-index',

meta:{
...meta,
title: '楼盘字典'
},

component: () => import('@/pages/storagemanage/building/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}Building-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '楼盘字典编辑'
},

component: () => import('@/pages/storagemanage/building/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}Buildingdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '楼盘字典新增'
},

component: () => import('@/pages/storagemanage/building/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}BuildingView',
auth: [
'hidden'
],

meta:{
...meta,
title: '楼盘字典详情查看'
},

component: () => import('@/pages/storagemanage/building/detail-view') ,
},
]
};
