/*
 * @Descripttion:
 * @version:
 * @Author: co
 * @Date: 2021-04-07 14:30:16
 * @LastEditors: co
 * @LastEditTime: 2021-05-06 17:20:38
 * @FilePath: \giant_find\iview-admin-pro-template\src\plugins\utils\RespCommonDef.js
 */
const RespCommonDef = {
	//查询条件
	AdvanceQuery: {
		entity: {},
		outerWhereSubjoin: {
			fuzzyMatching: "",
			daterange: [],
			startDate: "",
			endDate: ""
		},
		isPage: true,
		isOrder: false,
		isOrderAsc: false,
		pageIndex: 1,
		pageSize: 10,
	},
	//新增或编辑初使化返回
	CompoundEntityDictionary: {
		configItems: [],
		configRanges: [],
		configTrees: [],
		pageFunctionAuthorizations: [],//功能权限
		entityResponse: []
	},
	//列表初使化接口返回
	CompoundEntitiesDictionary: {
		configItems: [],
		configRanges: [],
		configTrees: [],
		pageFunctionAuthorizations: [{
			rangeAuthorizations: []
		}], //功能权限
		rowFunctionAuthorization: [],
		entities: [],
	},
	//列表接口返回
	CompoundEntities: {
		rowFunctionAuthorization: [],
		pageFunctionAuthorizations: [],
		entities: [{
			entities: [],
			total: 0
		}],
	},
	Entities: {
		entities: [],
		total: 0
	},
	CompoundEntity: {
		pageFunctionAuthorizations: [],
		entityResponse: []
	},
	Count: {
		effectCount: 0
	},
	Entity: {
		entity: [],
		isEffect: false
	},
	EntityCount: {
		entity: [],
		isEffect: false,
		effectCount: 0
	},
	Row: {
		row: [],
		isEffect: false
	},
	RowAuthorization: {
		rowId: [],
		accessCodes: []
	},
	Rows: {
		total: 0,
		rows: []
	}
};

export default RespCommonDef;
