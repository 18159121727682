/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:58.157
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listHouseLeaseContractInit(data){return request({
url: '/lcf/api/houseLeaseContract/listHouseLeaseContractInit',
method: 'post',
data:data,
});
}


export function listHouseLeaseContractByAuthorizations(data){return request({
url: '/lcf/api/houseLeaseContract/listHouseLeaseContractByAuthorizations',
method: 'post',
data:data,
});
}


export function listHouseLeaseContract(data){return request({
url: '/lcf/api/houseLeaseContract/listHouseLeaseContract',
method: 'post',
data:data,
});
}


export function getHouseLeaseContractCreateInit(){return request({
url: '/lcf/api/houseLeaseContract/getHouseLeaseContractCreateInit',
method: 'post',
});
}


export function getHouseLeaseContractEditInit(params){return request({
url: '/lcf/api/houseLeaseContract/getHouseLeaseContractEditInit',
method: 'post',
params:params,
});
}


export function getHouseLeaseContractView(params){return request({
url: '/lcf/api/houseLeaseContract/getHouseLeaseContractView',
method: 'post',
params:params,
});
}


export function saveHouseLeaseContract(data){return request({
url: '/lcf/api/houseLeaseContract/saveHouseLeaseContract',
method: 'post',
data:data,
});
}


export function deleteHouseLeaseContract(params){return request({
url: '/lcf/api/houseLeaseContract/deleteHouseLeaseContract',
method: 'post',
params:params,
});
}


