import util from '@/libs/util';
import router from '@/router';
import {
	getEvaluateBillOwnerEditInit,
	getEvaluateBillOwnerView
} from '@api/storagemanage/EvaluateBillOwner';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary)
	},

	actions: {
		getEvaluateBillOwnerEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillOwnerEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res; 
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getEvaluateBillOwnerView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getEvaluateBillOwnerView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
	}
};
