/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-03-08T19:23:44.97
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveFinancialClaimCancellationApplyItem,
	getFinancialClaimCancellationApplyItemCreateInit,
	getFinancialClaimCancellationApplyItemEditInit,
	getFinancialClaimCancellationApplyItemView,
	deleteFinancialClaimCancellationApplyItem
} from '@api/financial/FinancialClaimCancellationApplyItem';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialClaimCancellationApplyItemCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getFinancialClaimCancellationApplyItemCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialClaimCancellationApplyItemEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialClaimCancellationApplyItemEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialClaimCancellationApplyItem({}, FinancialClaimCancellationApplyItem) {
			return new Promise((resolve, reject) => {
				saveFinancialClaimCancellationApplyItem(FinancialClaimCancellationApplyItem)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialClaimCancellationApplyItemView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialClaimCancellationApplyItemView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialClaimCancellationApplyItem({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialClaimCancellationApplyItem({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
