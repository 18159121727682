/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-22T09:43:56.839
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'lcf-HouseInfo-'

export default 
{
path: '/lcf/houseinfo',
name: 'lcf-HouseInfo',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}HouseInfo-index',

meta:{
...meta,
title: '房源信息'
},

component: () => import('@/pages/lcf/houseinfo/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}HouseInfo-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源信息编辑'
},

component: () => import('@/pages/lcf/houseinfo/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}HouseInfodetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源信息新增'
},

component: () => import('@/pages/lcf/houseinfo/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}HouseInfoView',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源信息详情查看'
},

component: () => import('@/pages/lcf/houseinfo/detail-view') ,
},
]
};
