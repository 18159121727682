/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-16T15:24:16.902
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentTakeLookHouseInit(data){return request({
url: '/rentmanage/api/rentTakeLookHouse/listRentTakeLookHouseInit',
method: 'post',
data:data,
});
}


export function listRentTakeLookHouseByAuthorizations(data){return request({
url: '/rentmanage/api/rentTakeLookHouse/listRentTakeLookHouseByAuthorizations',
method: 'post',
data:data,
});
}


export function listRentTakeLookHouse(data){return request({
url: '/rentmanage/api/rentTakeLookHouse/listRentTakeLookHouse',
method: 'post',
data:data,
});
}


export function getRentTakeLookHouseCreateInit(){return request({
url: '/rentmanage/api/rentTakeLookHouse/getRentTakeLookHouseCreateInit',
method: 'post',
});
}


export function getRentTakeLookHouseEditInit(params){return request({
url: '/rentmanage/api/rentTakeLookHouse/getRentTakeLookHouseEditInit',
method: 'post',
params:params,
});
}


export function getRentTakeLookHouseView(params){return request({
url: '/rentmanage/api/rentTakeLookHouse/getRentTakeLookHouseView',
method: 'post',
params:params,
});
}


export function saveRentTakeLookHouse(data){return request({
url: '/rentmanage/api/rentTakeLookHouse/saveRentTakeLookHouse',
method: 'post',
data:data,
});
}


export function deleteRentTakeLookHouse(params){return request({
url: '/rentmanage/api/rentTakeLookHouse/deleteRentTakeLookHouse',
method: 'post',
params:params,
});
}


