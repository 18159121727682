/**
 * @version GENERATOR_VERSION::20200408
 * @DateTime 2020-04-10T12:05:16.658
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listFinancePayApplyInit(data) {
    return request({
        url: '/storagemanage/api/financePayApply/listFinancePayApplyInit',
        method: 'post',
        data: data,
    });
}


export function listFinancePayApplyByAuthorizations(data) {
    return request({
        url: '/storagemanage/api/financePayApply/listFinancePayApplyByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listFinancePayApply(data) {
    return request({
        url: '/storagemanage/api/financePayApply/listFinancePayApply',
        method: 'post',
        data: data,
    });
}


export function getFinancePayApplyCreateInit() {
    return request({
        url: '/storagemanage/api/financePayApply/getFinancePayApplyCreateInit',
        method: 'post',
    });
}


export function getFinancePayApplyEditInit(params) {
    return request({
        url: '/storagemanage/api/financePayApply/getFinancePayApplyEditInit',
        method: 'post',
        params: params,
    });
}


export function getFinancePayApplyView(params) {
    return request({
        url: '/storagemanage/api/financePayApply/getFinancePayApplyView',
        method: 'post',
        params: params,
    });
}


export function saveFinancePayApply(data) {
    return request({
        url: '/storagemanage/api/financePayApply/saveFinancePayApply',
        method: 'post',
        data: data,
    });
}

export function saveFinancePayApplyBo(data) {
    return request({
        url: '/storagemanage/api/financePayApply/saveFinancePayApplyBo',
        method: 'post',
        data: data,
    });
}


export function deleteFinancePayApply(params) {
    return request({
        url: '/storagemanage/api/financePayApply/deleteFinancePayApply',
        method: 'post',
        params: params,
    });
}

export function withdraw(params) {
    return request({
        url: '/storagemanage/api/financePayApply/withdraw',
        method: 'post',
        params: params,
    });
}

export function saveFinanceOffPay(params) {
    return request({
        url: '/storagemanage/api/financePayApply/offlinePayment',
        method: 'post',
        data: params,
    });
}
