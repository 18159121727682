/**
* @version GENERATOR_VERSION::20200422
* @DateTime 2020-05-07T10:19:45.918
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listCCBFinBillInit(data){return request({
url: '/house/api/cCBFinBill/listCCBFinBillInit',
method: 'post',
data:data,
});
}


export function listCCBFinBillByAuthorizations(data){return request({
url: '/house/api/cCBFinBill/listCCBFinBillByAuthorizations',
method: 'post',
data:data,
});
}


export function listCCBFinBill(data){return request({
url: '/house/api/cCBFinBill/listCCBFinBill',
method: 'post',
data:data,
});
}


export function getCCBFinBillCreateInit(){return request({
url: '/house/api/cCBFinBill/getCCBFinBillCreateInit',
method: 'post',
});
}


export function getCCBFinBillEditInit(params){return request({
url: '/house/api/cCBFinBill/getCCBFinBillEditInit',
method: 'post',
params:params,
});
}


export function getCCBFinBillView(params){return request({
url: '/house/api/cCBFinBill/getCCBFinBillView',
method: 'post',
params:params,
});
}


export function saveCCBFinBill(data){return request({
url: '/house/api/cCBFinBill/saveCCBFinBill',
method: 'post',
data:data,
});
}


export function deleteCCBFinBill(params){return request({
url: '/house/api/cCBFinBill/deleteCCBFinBill',
method: 'post',
params:params,
});
}


