/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-06-14T15:25:43.95
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import request from '@/plugins/request';

export function listRentLeaseContractSaleInit(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContractSale/listRentLeaseContractSaleInit',
		method: 'post',
		data: data,
	});
}


export function listRentLeaseContractSaleByAuthorizations(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContractSale/listRentLeaseContractSaleByAuthorizations',
		method: 'post',
		data: data,
	});
}


export function listRentLeaseContractSale(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContractSale/listRentLeaseContractSale',
		method: 'post',
		data: data,
	});
}


export function getRentLeaseContractSaleCreateInit(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContractSale/getRentLeaseContractSaleCreateInit',
		method: 'post',
		params: params,
	});
}


export function getRentLeaseContractSaleEditInit(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContractSale/getRentLeaseContractSaleEditInit',
		method: 'post',
		params: params,
	});
}


export function getRentLeaseContractSaleView(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContractSale/getRentLeaseContractSaleView',
		method: 'post',
		params: params,
	});
}


export function saveRentLeaseContractSale(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContractSale/saveRentLeaseContractSale',
		method: 'post',
		data: data,
	});
}


export function deleteRentLeaseContractSale(params) {
	return request({
		url: '/rentmanage/api/rentLeaseContractSale/deleteRentLeaseContractSale',
		method: 'post',
		params: params,
	});
}

export function getSuperiorUserInfo(data) {
	return request({
		url: '/rentmanage/api/rentLeaseContractSale/getSuperiorUserInfo',
		method: 'post',
		data: data,
	});
}
