/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-28T14:14:36.58
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	listFinancialCollection,
	listFinancialCollectionInit,
	listFinancialCollectionByAuthorizations,
	deleteFinancialCollection,
	cancellationFinancialCollection
	
} from '@api/financial/FinancialCollection';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {	
		// 初始化信息
		CompoundEntitiesDictionary: cloneObj(RespCommonDef.CompoundEntitiesDictionary),
		//查询接口返回的数据
		CompoundEntities: cloneObj(RespCommonDef.CompoundEntities),
	    Entities:cloneObj(RespCommonDef.Entities),
		//查询条件
		AdvanceQuery:cloneObj(RespCommonDef.AdvanceQuery),
	},

	actions: {
		listFinancialCollection({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialCollection(state.AdvanceQuery)
					.then(async res => {
						state.Entities = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialCollectionByAuthorizations({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialCollectionByAuthorizations(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntities =res
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		listFinancialCollectionInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				listFinancialCollectionInit(state.AdvanceQuery)
					.then(async res => {
						state.CompoundEntitiesDictionary = res;
						state.CompoundEntities.rowFunctionAuthorization = res.rowFunctionAuthorization;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialCollection({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteFinancialCollection({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		cancellationFinancialCollection({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				cancellationFinancialCollection({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		

	}
};
