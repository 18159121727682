/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-22T14:43:04.506
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveRentPlaceOrder,
	getRentPlaceOrderCreateInit,
	getRentPlaceOrderEditInit,
	getRentPlaceOrderView,
	deleteRentPlaceOrder,
	transferHousePlaceOrder
} from '@api/rentmanage/RentPlaceOrder';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getRentPlaceOrderCreateInit({
			state
		},{projectType}) {
			return new Promise((resolve, reject) => {
				getRentPlaceOrderCreateInit({projectType})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentPlaceOrderEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPlaceOrderEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		transferHousePlaceOrder({}, RentPlaceOrder) {
			return new Promise((resolve, reject) => {
				transferHousePlaceOrder(RentPlaceOrder)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		saveRentPlaceOrder({}, RentPlaceOrder) {
			return new Promise((resolve, reject) => {
				saveRentPlaceOrder(RentPlaceOrder)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getRentPlaceOrderView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPlaceOrderView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteRentPlaceOrder({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentPlaceOrder({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
