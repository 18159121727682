/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-04-16T10:11:30.144
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentBusinessAreaInit(data) {
    return request({
        url: '/rentmanage/api/rentBusinessArea/listRentBusinessAreaInit',
        method: 'post',
        data: data,
    });
}


export function listRentBusinessAreaByAuthorizations(data) {
    return request({
        url: '/rentmanage/api/rentBusinessArea/listRentBusinessAreaByAuthorizations',
        method: 'post',
        data: data,
    });
}


export function listRentBusinessArea(data) {
    return request({
        url: '/rentmanage/api/rentBusinessArea/listRentBusinessArea',
        method: 'post',
        data: data,
    });
}


export function getRentBusinessAreaCreateInit() {
    return request({
        url: '/rentmanage/api/rentBusinessArea/getRentBusinessAreaCreateInit',
        method: 'post',
    });
}


export function getRentBusinessAreaEditInit(params) {
    return request({
        url: '/rentmanage/api/rentBusinessArea/getRentBusinessAreaEditInit',
        method: 'post',
        params: params,
    });
}


export function getRentBusinessAreaView(params) {
    return request({
        url: '/rentmanage/api/rentBusinessArea/getRentBusinessAreaView',
        method: 'post',
        params: params,
    });
}


export function saveRentBusinessArea(data) {
    return request({
        url: '/rentmanage/api/rentBusinessArea/saveRentBusinessArea',
        method: 'post',
        data: data,
    });
}


export function deleteRentBusinessArea(params) {
    return request({
        url: '/rentmanage/api/rentBusinessArea/deleteRentBusinessArea',
        method: 'post',
        params: params,
    });
}

export function getRentBusinessArea(params) {
    return request({
        url: 'rentmanage/api/rentBusinessArea/listRentBusinessAreaById',
        method: 'post',
        params: params,
    });
}


