/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-11-09T18:33:19.366
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listReportRentContractRequestCheckoutLeaseInit(data){return request({
url: '/report/api/reportRentContractRequestCheckoutLease/listReportRentContractRequestCheckoutLeaseInit',
method: 'post',
data:data,
});
}


export function listReportRentContractRequestCheckoutLeaseByAuthorizations(data){return request({
url: '/report/api/reportRentContractRequestCheckoutLease/listReportRentContractRequestCheckoutLeaseByAuthorizations',
method: 'post',
data:data,
});
}


export function listReportRentContractRequestCheckoutLease(data){return request({
url: '/report/api/reportRentContractRequestCheckoutLease/listReportRentContractRequestCheckoutLease',
method: 'post',
data:data,
});
}


export function getReportRentContractRequestCheckoutLeaseCreateInit(){return request({
url: '/report/api/reportRentContractRequestCheckoutLease/getReportRentContractRequestCheckoutLeaseCreateInit',
method: 'post',
});
}


export function getReportRentContractRequestCheckoutLeaseEditInit(params){return request({
url: '/report/api/reportRentContractRequestCheckoutLease/getReportRentContractRequestCheckoutLeaseEditInit',
method: 'post',
params:params,
});
}


export function getReportRentContractRequestCheckoutLeaseView(params){return request({
url: '/report/api/reportRentContractRequestCheckoutLease/getReportRentContractRequestCheckoutLeaseView',
method: 'post',
params:params,
});
}


export function saveReportRentContractRequestCheckoutLease(data){return request({
url: '/report/api/reportRentContractRequestCheckoutLease/saveReportRentContractRequestCheckoutLease',
method: 'post',
data:data,
});
}


export function deleteReportRentContractRequestCheckoutLease(params){return request({
url: '/report/api/reportRentContractRequestCheckoutLease/deleteReportRentContractRequestCheckoutLease',
method: 'post',
params:params,
});
}


