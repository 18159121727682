/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-07-14T17:27:30.64
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';

import {
	saveRentPurchaseOrderDetailed,
	getRentPurchaseOrderDetailedCreateInit,
	getRentPurchaseOrderDetailedEditInit,
	getRentPurchaseOrderDetailedView,
	deleteRentPurchaseOrderDetailed
} from '@api/rentmanage/RentPurchaseOrderDetailed';

import {
	cloneObj
} from '@/plugins/utils';

import RespCommonDef from '@/plugins/utils/RespCommonDef';

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getRentPurchaseOrderDetailedCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getRentPurchaseOrderDetailedCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentPurchaseOrderDetailedEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPurchaseOrderDetailedEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveRentPurchaseOrderDetailed({}, RentPurchaseOrderDetailed) {
			return new Promise((resolve, reject) => {
				saveRentPurchaseOrderDetailed(RentPurchaseOrderDetailed)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getRentPurchaseOrderDetailedView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getRentPurchaseOrderDetailedView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteRentPurchaseOrderDetailed({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteRentPurchaseOrderDetailed({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
