/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2021-02-24T09:31:31.167
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
auth: true
};

const pre = 'rentmanage-renthousetag-'

export default 
{
path: '/rentmanage/renthousetag',
name: 'rentmanage-renthousetag',
redirect: {
name: '${pre}-index'
}
,

meta:{
...meta,
},

component: BasicLayout ,
children:
[
{
path: 'index',
name: '${pre}renthousetag-index',

meta:{
...meta,
title: '房源标签'
},

component: () => import('@/pages/rentmanage/renthousetag/index') ,
},
{
path: 'detail-edit/:id',
name: '${pre}renthousetag-detail-edit',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源标签编辑'
},

component: () => import('@/pages/rentmanage/renthousetag/detail-edit') ,
},
{
path: 'detail-edit',
name: '${pre}renthousetagdetail-create',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源标签新增'
},

component: () => import('@/pages/rentmanage/renthousetag/detail-edit') ,
},
{
path: 'detail-view/:id',
name: '${pre}renthousetagView',
auth: [
'hidden'
],

meta:{
...meta,
title: '房源标签详情查看'
},

component: () => import('@/pages/rentmanage/renthousetag/detail-view') ,
},
]
};
