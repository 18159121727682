<template>
  <!-- 查看流程图 -->
  <JFDrawer destroy-on-close title="查看流程图" v-model="visible">
    <a :href="flowPicUrl" title="点击查看大图" target="_blank">
      <img class="img-fluid" :src="flowPicUrl">
    </a>
    <template slot="footer">
      <Button type="primary"
              :long="isMobile"
              @click="visible = false">关闭</Button>
    </template>
  </JFDrawer>
</template>
<script>
  import { mapState } from 'vuex'

  export default {
    name: 'FlowPic',
    computed: {
      ...mapState('admin/layout', ['isMobile']),
    },
    methods: {
      init (flowPicUrl) {
        this.visible = true
        this.flowPicUrl = flowPicUrl
      }
    },
    data () {
      return {
        visible: false,
        flowPicUrl: ''
      }
    },
  }
</script>

<style scoped lang="less">
  .img-fluid {
    display: block;
    max-width: 100%;
    height: auto;
  }
</style>
