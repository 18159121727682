/**
* @version GENERATOR_VERSION::20200504
* @DateTime 2020-06-22T14:43:04.482
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import request from '@/plugins/request';

export function listRentPlaceOrderInit(data){return request({
url: '/rentmanage/api/rentPlaceOrder/listRentPlaceOrderInit',
method: 'post',
data:data,
});
}


export function listRentPlaceOrderByAuthorizations(data){return request({
url: '/rentmanage/api/rentPlaceOrder/listRentPlaceOrderByAuthorizations',
method: 'post',
data:data
});
}

export function listCompanyRentPlaceOrderBySQL(data){return request({
	url: '/rentmanage/api/rentPlaceOrder/listCompanyRentPlaceOrderBySQL',
	method: 'post',
	data:data,
	});
}

export function listRentPlaceOrder(data){return request({
url: '/rentmanage/api/rentPlaceOrder/listRentPlaceOrder',
method: 'post',
data:data,
});
}


export function getRentPlaceOrderCreateInit(params){return request({
url: '/rentmanage/api/rentPlaceOrder/getRentPlaceOrderCreateInit',
method: 'post',
params:params,
});
}


export function getRentPlaceOrderEditInit(params){return request({
url: '/rentmanage/api/rentPlaceOrder/getRentPlaceOrderEditInit',
method: 'post',
params:params,
});
}


export function getRentPlaceOrderView(params){return request({
url: '/rentmanage/api/rentPlaceOrder/getRentPlaceOrderView',
method: 'post',
params:params,
});
}


export function saveRentPlaceOrder(data){return request({
url: '/rentmanage/api/rentPlaceOrder/saveRentPlaceOrder',
method: 'post',
data:data,
});
}

export function transferHousePlaceOrder(data){return request({
	url: '/rentmanage/api/rentPlaceOrder/transferHousePlaceOrder',
	method: 'post',
	data:data,
	});
}

export function deleteRentPlaceOrder(params){return request({
url: '/rentmanage/api/rentPlaceOrder/deleteRentPlaceOrder',
method: 'post',
params:params,
});
}

export function getRentPlaceOrderInfoByHouseId(params){return request({
	url: '/rentmanage/api/rentPlaceOrder/getRentPlaceOrderInfoByHouseId',
	method: 'post',
	params:params,
	});
	}

export function cancelRentPlaceOrder(params){return request({
	url: '/rentmanage/api/rentPlaceOrder/cancelRentPlaceOrder',
	method: 'post',
	params:params,
	});
}

export function sentRentSign(params){return request({
	url: '/rentmanage/api/rentPlaceOrder/sentRentSign',
	method: 'post',
	params:params,
	});
}
