/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2020-07-05T16:23:43.242
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import BasicLayout from '@/layouts/basic-layout';

const meta = {
    auth: true
};

const pre = 'rentmanage-RentPropertyDelivery-'

export default {
    path: '/rentmanage/rentpropertydelivery',
    name: 'rentmanage-RentPropertyDelivery',
    redirect: {
        name: '${pre}-index'
    },

    meta: {
        ...meta,
    },

    component: BasicLayout,
    children: [{
            path: 'index',
            name: '${pre}RentPropertyDelivery-index',

            meta: {
                ...meta,
                title: '物业交付单'
            },

            component: () => import('@/pages/rentmanage/rentpropertydelivery/index'),
        },
        {
            path: 'detail-edit/:id',
            name: '${pre}RentPropertyDelivery-detail-edit',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '物业交付单编辑'
            },

            component: () => import('@/pages/rentmanage/rentpropertydelivery/detail-edit'),
        },
        {
            path: 'detail-edit',
            name: '${pre}RentPropertyDeliverydetail-create',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '物业交付单新增'
            },

            component: () => import('@/pages/rentmanage/rentpropertydelivery/detail-edit'),
        },
        {
            path: 'detail-view/:id',
            name: '${pre}RentPropertyDeliveryView',
            auth: [
                'hidden'
            ],

            meta: {
                ...meta,
                title: '物业交付单详情查看'
            },

            component: () => import('@/pages/rentmanage/rentpropertydelivery/detail-view'),
        },
    ]
};
