/**
* @version GENERATOR_VERSION::20200408
* @DateTime 2020-04-14T20:19:04.282
* @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
**/
import util from '@/libs/util';
import router from '@/router';
import {
	saveManagementAreaUser,
	getManagementAreaUserCreateInit,
	getManagementAreaUserEditInit,
	getManagementAreaUserView,
	deleteManagementAreaUser
} from '@api/storagemanage/ManagementAreaUser';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

export default {
	namespaced: true,
	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},
	actions: {
		getManagementAreaUserCreateInit({
			state
		}) {
			return new Promise((resolve, reject) => {
				getManagementAreaUserCreateInit({})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getManagementAreaUserEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getManagementAreaUserEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveManagementAreaUser({}, ManagementAreaUser) {
			return new Promise((resolve, reject) => {
				saveManagementAreaUser(ManagementAreaUser)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		getManagementAreaUserView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getManagementAreaUserView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},
		deleteManagementAreaUser({
			state
		}, {id}) {
			return new Promise((resolve, reject) => {
				deleteManagementAreaUser({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
