/**
 * @version GENERATOR_VERSION::20200504
 * @DateTime 2021-03-08T19:23:44.68
 * @Author <a href=\"mailto:bigmouthz@foxmail.com\">bigmouthz</a>
 **/
import util from '@/libs/util';
import router from '@/router';
import {
	saveFinancialClaimCancellationApply,
	getFinancialClaimCancellationApplyCreateInit,
	getFinancialClaimCancellationApplyEditInit,
	getFinancialClaimCancellationApplyView,
	deleteFinancialClaimCancellationApply
} from '@api/financial/FinancialClaimCancellationApply';

import {
	cloneObj
} from '@/plugins/utils'

import RespCommonDef from '@/plugins/utils/RespCommonDef'

import {
	listFinancialClaimCancellationApplyItemInit,
	listFinancialClaimCancellationApplyItemByAuthorizations,
	deleteFinancialClaimCancellationApplyItem
} from '@api/financial/FinancialClaimCancellationApplyItem';

export default {
	namespaced: true,

	state: {
		CompoundEntityDictionary: cloneObj(RespCommonDef.CompoundEntityDictionary),
		CompoundEntityDictionaryAdd: cloneObj(RespCommonDef.CompoundEntityDictionary),
	},

	actions: {
		getFinancialClaimCancellationApplyCreateInit({
			state
		},{
			referId
		}) {
			return new Promise((resolve, reject) => {
				getFinancialClaimCancellationApplyCreateInit({referId})
					.then(async res => {
						state.CompoundEntityDictionaryAdd = res;
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialClaimCancellationApplyEditInit({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialClaimCancellationApplyEditInit({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},


		saveFinancialClaimCancellationApply({}, FinancialClaimCancellationApply) {
			return new Promise((resolve, reject) => {
				saveFinancialClaimCancellationApply(FinancialClaimCancellationApply)
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		getFinancialClaimCancellationApplyView({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				getFinancialClaimCancellationApplyView({
						id
					})
					.then(async res => {
						state.CompoundEntityDictionary = res;
						resolve(res.entityResponse);
					})
					.catch(err => {
						reject(err);
					})
			})
		},

		deleteFinancialClaimCancellationApply({
			state
		}, {
			id
		}) {
			return new Promise((resolve, reject) => {
				deleteFinancialClaimCancellationApply({
						id
					})
					.then(async res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					})
			})
		}
	}
};
