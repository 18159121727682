import BasicLayout from '@/layouts/basic-layout';

const meta = {
	auth: true
};

const pre = 'khm-';

export default {
	path: '/khm',
	name: 'khm-7',
	redirect: {
		name: `${pre}rentApply-list`
	},
	meta,
	component: BasicLayout,
	children: [
		{
			path: 'rentApply-list',
			name: `${pre}rentApply-list`,
			meta: {
				...meta,
				title: '租金申请',
				closable: true
			},
			component: () => import('@/pages/storagemanage/rentApply')
		},
		{
			path: 'rentApply-edit',
			name: `${pre}rentApply-edit`,
			meta: {
				...meta,
				title: '租金申请编辑',
				closable: true
			},
			component: () => import('@/pages/storagemanage/rentApply/edit')
		},
	]
};
